import { Stack } from '@mui/material';
import { Text, ChipButton } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useOfferFooterValues } from './helpers/utils';
import { Offer } from '../../../../task/TaskDrawer/TaskPredefinedForm/predefinedForms/shared/schemas';
import { useFormatCurrency, useFormatPercentage } from '@verticeone/utils/formatting';
import { FC } from 'react';

type OfferFooterItemProps = {
  title: string;
  value: string;
  variant?: 'default' | 'saving' | 'additional';
  percentageChipValue?: number;
  percentageSignDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
};

const getFooterItemColor = (variant: 'default' | 'saving' | 'additional'): string => {
  switch (variant) {
    case 'saving':
      return 'success';
    case 'additional':
      return 'text3';
    default:
      return 'text1';
  }
};

const OfferFooterItem = ({
  title,
  value,
  variant = 'default',
  percentageChipValue,
  percentageSignDisplay = 'auto',
}: OfferFooterItemProps) => {
  const formatPercentage = useFormatPercentage();
  const isDefault = variant === 'default';
  const isAdditional = variant === 'additional';

  return (
    <Stack gap={3} direction={'row'}>
      <Text variant="heading" size={isDefault ? 'S' : 'XS'} color={isAdditional ? 'text3' : 'text1'} width={'50%'}>
        {title}
      </Text>
      <Stack direction="row" width={'50%'} gap={1} alignItems="end">
        <Text
          variant="heading"
          size={variant === 'default' ? 'S' : 'XS'}
          color={getFooterItemColor(variant)}
          textAlign={{ xs: 'right', md: 'left' }}
        >
          {value}
        </Text>
        {percentageChipValue !== undefined && (
          <ChipButton size="S" variant="outline" isActive={false} color="success" disabled={variant === 'additional'}>
            {formatPercentage(percentageChipValue, { signDisplay: percentageSignDisplay })}
          </ChipButton>
        )}
      </Stack>
    </Stack>
  );
};

type BaselineOfferFooterProps = {
  baselineOffer: Offer;
};

export const BaselineOfferFooter: FC<BaselineOfferFooterProps> = ({ baselineOffer }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { getOfferFooterValues } = useOfferFooterValues({ offer: baselineOffer });

  const { annualCost, totalCost } = getOfferFooterValues();

  return (
    <Stack py={4} px={6} gap={1}>
      <OfferFooterItem
        title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.OFFER_COST')}
        value={formatCurrency(totalCost, { currency: baselineOffer.baseCurrency })}
        variant="additional"
      />

      <OfferFooterItem
        title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.ANNUAL_COST')}
        value={formatCurrency(annualCost, { currency: baselineOffer.baseCurrency })}
      />
    </Stack>
  );
};

type NegotiatedOfferFooterProps = {
  offer: Offer;
  baselineOffer: Offer;
};

export const NegotiatedOfferFooter: FC<NegotiatedOfferFooterProps> = ({ offer, baselineOffer }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const { getOfferFooterValues: getBaselineOfferFooterValues } = useOfferFooterValues({ offer: baselineOffer });
  const { getOfferFooterValues } = useOfferFooterValues({ offer });

  const {
    annualCost: baselineAnnualCost,
    totalCost: baselineTotalCost,
    offerTermInMonths: baselineTermInMonths,
  } = getBaselineOfferFooterValues();
  const { annualCost, totalCost, concessionSavings, offerTermInMonths } = getOfferFooterValues();

  const baseAnnualSavings = baselineAnnualCost - annualCost;
  const shouldShowTotalSavings = concessionSavings > 0 || baseAnnualSavings > 0;

  const totalAnnualSavings = concessionSavings + baseAnnualSavings;
  const totalAnnualSavingsPercentage =
    !!totalAnnualSavings && !!baselineAnnualCost ? totalAnnualSavings / baselineAnnualCost : 0;

  const totalOfferSavings = (totalAnnualSavings / 12) * offerTermInMonths;
  const totalOfferSavingsPercentage =
    !!totalOfferSavings && !!totalCost ? totalOfferSavings / (totalOfferSavings + totalCost) : 0;

  const shouldShowDiffVsBaseline =
    baselineOffer.rollingFrequency === offer.rollingFrequency && baselineTermInMonths === offerTermInMonths;
  const diffVsBaseline =
    baselineOffer.rollingFrequency === offer.rollingFrequency ? totalCost - baselineTotalCost : undefined;
  const diffVsBaselinePercentage = diffVsBaseline ? diffVsBaseline / baselineTotalCost : undefined;

  return (
    <Stack py={4} px={6} gap={1}>
      <OfferFooterItem
        title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.OFFER_COST')}
        value={formatCurrency(totalCost, { currency: offer.baseCurrency })}
        variant="additional"
      />

      {shouldShowDiffVsBaseline && diffVsBaseline !== undefined && (
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.DIFF_VS_BASELINE')}
          value={formatCurrency(diffVsBaseline, { currency: offer.baseCurrency, plusSign: true })}
          variant="additional"
          percentageChipValue={diffVsBaselinePercentage}
          percentageSignDisplay="exceptZero"
        />
      )}

      <OfferFooterItem
        title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.TOTAL_OFFER_SAVINGS')}
        value={formatCurrency(totalOfferSavings, { currency: offer.baseCurrency })}
        variant="additional"
        percentageChipValue={totalOfferSavingsPercentage}
      />

      <OfferFooterItem
        title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.ANNUAL_COST')}
        value={formatCurrency(annualCost, { currency: offer.baseCurrency })}
      />

      {!!concessionSavings && (
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.CONCESSION_SAVINGS')}
          value={formatCurrency(concessionSavings, { currency: offer.baseCurrency })}
          variant="saving"
        />
      )}
      {shouldShowTotalSavings && (
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.TOTAL_ANNUAL_SAVINGS')}
          value={formatCurrency(totalAnnualSavings, { currency: offer.baseCurrency })}
          percentageChipValue={totalAnnualSavingsPercentage}
          variant="saving"
        />
      )}
    </Stack>
  );
};
