import { FC, useId } from 'react';
import { Stack, styled } from '@mui/material';
import { Text, IconWrapper, Button, Tooltip } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { EditUserTaskFormData } from '../schema';
import { QueryBuilder } from '../../EditGatewayForm/QueryBuilder/QueryBuilder';
import type { Property } from '../../../types';
import { TaskAssigneeSelect } from './TaskAssigneeSelect';
import { AddFieldButton } from '../../../../../../forms/dynamicForms/DynamicFormBuilder/components/AddFieldButton';

const ConditionalAssignmentContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.input.color1,
  padding: theme.spacing(4),
  borderRadius: 12,
}));

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.neutral.color2,
  opacity: 0.4,
}));

type ConditionalAssignmentProps = {
  requestProperties: Property[];
  globalUDFProperties: Property[];
  onAddUser: () => void;
  allowContractOwnerAssignment?: boolean;
};

export const ConditionalAssignment: FC<ConditionalAssignmentProps> = ({
  requestProperties,
  globalUDFProperties,
  onAddUser,
  allowContractOwnerAssignment,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.CONDITIONAL_ASSIGNEES',
  });
  const id = useId();

  const { setValue, getValues } = useFormContext<EditUserTaskFormData>();
  const { fields, append, remove } = useFieldArray<EditUserTaskFormData>({
    name: 'conditionalAssignees',
  });

  return (
    <Stack gap={2}>
      {fields.map((field, index) => (
        <ConditionalAssignmentContainer gap={6} key={field.id}>
          <Stack alignItems="center" justifyContent="space-between" direction="row">
            <Stack alignItems="center" direction="row" gap={1}>
              <Text variant="label" size="XXS" color="text3">
                {t('TITLE')}
              </Text>
              <Tooltip content={t('HELP.CONDITIONAL')} size="S">
                <StyledIconWrapper icon={InfoOutlinedIcon} size="XS" />
              </Tooltip>
            </Stack>
            <Button sx={{ padding: '0px !important' }} onClick={() => remove(index)} size="XXS" variant="plain">
              {t('ACTIONS.DELETE')}
            </Button>
          </Stack>
          <Stack gap={3}>
            <QueryBuilder
              initialCondition={getValues(`conditionalAssignees.${index}.condition`)}
              onConditionChange={(newCondition) => {
                setValue(`conditionalAssignees.${index}.condition`, newCondition);
              }}
              requestProperties={requestProperties}
              globalUDFProperties={globalUDFProperties}
            />
            <Stack direction="row" alignItems="center" gap={2}>
              <Text variant="caption" size="S" color="text2">
                {t('THEN_ASSIGN')}
              </Text>
              <TaskAssigneeSelect
                id={`${id}-${index}`}
                onAddUser={onAddUser}
                allowContractOwnerAssignment={allowContractOwnerAssignment}
                name={`conditionalAssignees.${index}.assignees`}
              />
            </Stack>
          </Stack>
        </ConditionalAssignmentContainer>
      ))}
      <AddFieldButton
        label={t('ACTIONS.ADD')}
        onClick={() =>
          append({
            condition: '',
            assignees: [],
          })
        }
      />
    </Stack>
  );
};
