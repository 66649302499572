import { FC } from 'react';
import { IconButton } from '@verticeone/design-system';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { ActionProps, findPath, prepareRuleGroup } from 'react-querybuilder';
import { isQueryValid, isEmptyRule } from '../utils';
import { DEFAULT_QUERY } from '../constants';

export const AddRuleAction: FC<ActionProps> = ({ handleOnClick, schema, path }) => {
  const query = schema.getQuery();
  const isRootQueryWithNoRules = query && query.rules.length === 0;

  const ruleQuery = query ? findPath(path, query) : null;

  const isAddButtonHidden = !isRootQueryWithNoRules && (!ruleQuery || !isQueryValid(ruleQuery));

  if (isAddButtonHidden) {
    return null;
  }

  return <IconButton icon={AddOutlined} size="S" variant="outline" color="neutral" onClick={handleOnClick} />;
};

export const AddGroupAction = () => null;

/*
 * Use this to test the builder for the nested conditions
    const AddGroupAction: FC<ActionProps> = ({ handleOnClick }) => {
      return (
        <IconButton
          icon={SubdirectoryArrowRightOutlined}
          size="S"
          variant="outline"
          color="neutral"
          onClick={handleOnClick}
        />
      );
    };
*/

export const RemoveGroupAction: FC<ActionProps> = ({ handleOnClick }) => {
  return <IconButton icon={DeleteOutlined} size="S" variant="outline" color="neutral" onClick={handleOnClick} />;
};

export const RemoveRuleAction: FC<ActionProps> = (props) => {
  const { handleOnClick, schema, context } = props;
  const query = schema.getQuery();

  const isLastRuleInRootQuery = query?.rules?.length === 1;
  const isLastAndEmptyRuleInRootQuery = isLastRuleInRootQuery && isEmptyRule(query.rules[0]);

  const isRemoveButtonHidden = isLastAndEmptyRuleInRootQuery && !context.isConditionRemovalEnabled;

  if (isRemoveButtonHidden) {
    return null;
  }

  const handleButtonClick = () => {
    if (isLastRuleInRootQuery && !context.isConditionRemovalEnabled) {
      schema?.dispatchQuery(prepareRuleGroup(DEFAULT_QUERY));
      return;
    }

    handleOnClick();
  };

  return <IconButton icon={DeleteOutlined} size="S" variant="plain" color="neutral" onClick={handleButtonClick} />;
};
