import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardCloseButton, CardHeaderActions } from '@verticeone/design-system';
import { DrawerHeader, DrawerHeaderTitle } from '@verticeone/design-system';
import { useDataSource } from '../../../DataSourceProvider';

const BuySPDrawerHeader = () => {
  const {
    buyDrawer: { close },
    datasource,
  } = useDataSource();
  const { t } = useTranslation();

  return (
    <DrawerHeader noDivider>
      <DrawerHeaderTitle tag="h1" text={t('CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.TITLE', { product: datasource })} />
      <CardHeaderActions>
        <CardCloseButton onClick={close} />
      </CardHeaderActions>
    </DrawerHeader>
  );
};

export default BuySPDrawerHeader;
