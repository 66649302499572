export class Urn {
  protocol: string;
  partition: string;
  system: string;
  accountId: string;
  service: string;
  resourceName: string | null;
  resourceId: string | null;

  constructor(
    protocol: string,
    partition: string,
    system: string,
    accountId: string,
    service: string,
    resourceName: string | null = null,
    resourceId: string | null = null
  ) {
    this.protocol = protocol;
    this.partition = partition;
    this.system = system;
    this.accountId = accountId;
    this.service = service;
    this.resourceName = resourceName;
    this.resourceId = resourceId;
  }

  toString(): string {
    return (
      `${this.protocol}:${this.partition}:${this.system}:${this.accountId}:${this.service}` +
      (this.resourceName && this.resourceId ? `:${this.resourceName}/${this.resourceId}` : '')
    );
  }
}

export function parse(val: string): Urn {
  if (val.startsWith('urn~')) {
    const [abbrev, ...rest] = val.split(':');
    const abbrevIndex = parseInt(abbrev.split('~')[1], 10);
    if (abbrevIndex >= 4) {
      throw new Error('Invalid URN');
    }

    val = ['urn', 'verticeone', 'vertice'].slice(0, abbrevIndex).join(':') + ':' + rest.join(':');
  }

  const [protocol, partition, system, accountId, service, ...rest] = val.split(':');

  const [resourceName, ...path] = rest.join(':').split('/').filter(Boolean);
  const resourceId = path.join('/') || null;

  return new Urn(protocol, partition, system, accountId, service, resourceName, resourceId);
}

export function stdUrn(val: string): string {
  const urn = parse(val);
  return urn.toString();
}
