import { Box, Stack } from '@mui/material';
import { Button } from '@verticeone/design-system';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@verticeone/design-system';
import { ContractListContext } from '../../../../contexts/ContractListContext';
import { Filter } from '../types';
import useSavedFiltersConfiguration from '../hooks/useSavedFiltersConfiguration';
import { FilterSelect } from './FilterSelect';
import isEqual from 'lodash/isEqual';

export type FilterVisibilityConfigurationFormProps = {
  onCancel?: () => void;
  onSaved?: () => void;
};

export const FilterVisibilityConfigurationForm = ({ onCancel, onSaved }: FilterVisibilityConfigurationFormProps) => {
  const { t } = useTranslation();
  const { activeFilters } = useContext(ContractListContext);
  const [selectedFilters, setSelectedFilters] = useState<Filter[] | undefined>(activeFilters);
  const { saveFilterConfiguration } = useSavedFiltersConfiguration();
  const disableSave = useMemo(
    () => isEqual(new Set(activeFilters), new Set(selectedFilters)),
    [activeFilters, selectedFilters]
  );

  const handleSave = useCallback(async () => {
    void saveFilterConfiguration(selectedFilters ?? []);
    if (onSaved) {
      onSaved();
    }
  }, [onSaved, selectedFilters, saveFilterConfiguration]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  return (
    <Stack py={4} gap={4} width={480}>
      <Box px={4}>
        <FilterSelect values={selectedFilters} onSelectionChanged={setSelectedFilters} />
      </Box>
      <Divider />
      <Stack direction="row" px={4} gap={2}>
        <Button fullWidth color="primary" onClick={handleCancel}>
          {t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.CANCEL')}
        </Button>
        <Button fullWidth variant="solid" color="primary" onClick={handleSave} disabled={disableSave}>
          {t('ENTITIES.FILTERS.CUSTOMIZE_FILTERS.APPLY')}
        </Button>
      </Stack>
    </Stack>
  );
};
