import { useTranslation } from 'react-i18next';

import type { Property } from '../../types';
import type { OriginsWithProperties } from '../../VariableSelector/types';
import { countProperties, groupVariablesByOrigin } from '../../VariableSelector/utils';

const sortPropertiesByKind = (properties: Property[]) => {
  const requestProperties = [];
  const taskProperties = [];
  const restProperties = [];

  for (const property of properties) {
    if (property.origin.kind === 'workflow input') {
      requestProperties.push(property);
    } else if (property.origin.kind === 'task') {
      taskProperties.push(property);
    } else {
      restProperties.push(property);
    }
  }

  return { requestProperties, taskProperties, restProperties };
};

export type VariablesGroup = {
  originsWithProperties: OriginsWithProperties;
  count: number;
  title: string;
  id: string;
};

export const useWorkflowVariablesGroups = ({
  allRequestProperties,
  globalUDFProperties,
}: {
  allRequestProperties: Property[];
  globalUDFProperties: Property[];
}): VariablesGroup[] => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR' });

  const { requestProperties, taskProperties, restProperties } = sortPropertiesByKind(allRequestProperties);

  const variablesGroups: VariablesGroup[] = [];

  if (requestProperties.length) {
    variablesGroups.push({
      originsWithProperties: groupVariablesByOrigin({ properties: requestProperties }),
      count: countProperties(requestProperties),
      title: t('LIST.REQUEST_VARIABLES'),
      id: 'workflow.request',
    });
  }

  if (taskProperties.length) {
    variablesGroups.push({
      originsWithProperties: groupVariablesByOrigin({ properties: taskProperties }),
      count: countProperties(taskProperties),
      title: t('LIST.TASK_VARIABLES'),
      id: 'workflow.request.task',
    });
  }

  if (restProperties.length) {
    variablesGroups.push({
      originsWithProperties: groupVariablesByOrigin({ properties: restProperties }),
      count: countProperties(restProperties),
      title: t('LIST.EDGE_VARIABLES'),
      id: 'workflow.request.edge',
    });
  }

  if (globalUDFProperties.length) {
    variablesGroups.push({
      originsWithProperties: groupVariablesByOrigin({ properties: globalUDFProperties, hideOrigin: true }),
      count: countProperties(globalUDFProperties),
      title: t('LIST.GLOBAL_VARIABLES'),
      id: 'workflow.global',
    });
  }

  return variablesGroups;
};
