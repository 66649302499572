import { FC, PropsWithChildren } from 'react';
import { Stack } from '@mui/material';

export const EditorDrawerScrollContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack flexGrow={1} width="100%" minHeight={0}>
      <Stack
        direction="column"
        p={6}
        justifyContent="space-between"
        overflow="auto"
        height="100%"
        maxHeight="100%"
        width="100%"
        gap={12}
      >
        {children}
      </Stack>
    </Stack>
  );
};
