import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Buttons, Header, InnerScrollable, Content } from '@vertice/core/src/components/Dialog/Components';
import { AddExistingContractFormData, AddExistingContractFormEntry } from '../../types';
import FormVendorSelectField from '@vertice/core/src/modules/vendor/formFields/FormVendorSelectField';
import { FormVendorSelectFieldComponentType } from '@vertice/core/src/modules/vendor/formFields/FormVendorSelectField/FormVendorSelectField';

const useSelectVendorWizard = () => {
  const { goToStep } = useWizard();
  const goToNext = useCallback(() => goToStep('contract_type'), [goToStep]);
  return { goToNext };
};

const SelectVendor: React.FC<WizardStepProps> = () => {
  const { goToNext } = useSelectVendorWizard();
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<AddExistingContractFormData>();
  const vendor = watch('vendor');
  const prevVendorRef = useRef(vendor);

  // Auto-proceed when vendor is selected/changed
  useEffect(() => {
    if (vendor && vendor !== prevVendorRef.current) {
      prevVendorRef.current = vendor;
      setValue('products', []);
      goToNext();
    }
  }, [goToNext, setValue, vendor]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.EXISTING_CONTRACT_SELECT_VENDOR.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_SELECT_VENDOR.HEADING_SUBTITLE')}
      />
      <InnerScrollable>
        <AddExistingContractFormEntry<FormVendorSelectFieldComponentType<AddExistingContractFormData>>
          key={vendor ? vendor.id : null}
          name="vendor"
          label={t('ENTITIES.CONTRACT.LABELS.VENDOR')}
          required
          width={12}
          component={FormVendorSelectField}
          componentProps={{
            placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.VENDOR'),
            inlineEnabled: true,
          }}
        />
      </InnerScrollable>
      {vendor && vendor.type !== 'INLINE' && (
        <Buttons
          primary={{
            onClick: goToNext,
            title: t('DIALOG.BUTTONS.NEXT'),
          }}
        />
      )}
    </Content>
  );
};

export default SelectVendor;
