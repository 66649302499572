import { ArrayPath, Control, FieldArray, FieldArrayPath, FieldValues, useFieldArray } from 'react-hook-form';
import { useCallback } from 'react';
import { DynamicFormField } from '../../types';

export type UseDynamicFormBuilderFieldsProps<FormDataType extends FieldValues = never> = {
  control: Control<FormDataType, any>;
  fieldPath: FieldArrayPath<FormDataType>;
};

export type UseDynamicFormBuilderFieldsReturn<T extends DynamicFormField> = {
  fields: (T & { id: string })[];
  addField: (newField: T) => void;
  updateField: (id: string, updatedField: T) => void;
  removeField: (id: string) => void;
};

export const useDynamicFormBuilderFields = <FormDataType extends FieldValues, T extends DynamicFormField>({
  control,
  fieldPath,
}: UseDynamicFormBuilderFieldsProps<FormDataType>): UseDynamicFormBuilderFieldsReturn<T> => {
  const { fields, append, remove, update } = useFieldArray<FormDataType>({ control, name: fieldPath });

  const addField = useCallback(
    (newField: T) => {
      append(newField as FieldArray<FormDataType, ArrayPath<FormDataType>>);
    },
    [append]
  );

  const updateField = useCallback(
    (id: string, updatedField: T) => {
      const fieldIndex = fields.findIndex((field) => field.id === id);
      update(fieldIndex, updatedField as FieldArray<FormDataType, ArrayPath<FormDataType>>);
    },
    [fields, update]
  );

  const removeField = useCallback(
    (id: string) => {
      const fieldIndex = fields.findIndex((field) => field.id === id);
      remove(fieldIndex);
    },
    [fields, remove]
  );

  return {
    fields: fields as (T & { id: string })[],
    addField,
    updateField,
    removeField,
  };
};
