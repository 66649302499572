import React from 'react';
import { Stack } from '@mui/material';
import { Trans } from 'react-i18next';
import type { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-pro';
import type { OptimizationRow } from '../../../dataSource/useOptimizations';

import { RecommendationStatus } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { ChipButton } from '@verticeone/design-system';
import { STATUS_CHIP } from '../../../constants';

type StatusColumnProps = GridRenderCellParams<OptimizationRow, RecommendationStatus, any, GridTreeNodeWithRender>;

const StatusColumn = ({ row, value }: StatusColumnProps) => (
  <Stack gap={2} alignItems="flex-end">
    <ChipButton isActive={false} size="S" variant="ghost" color={STATUS_CHIP[value as RecommendationStatus].color}>
      <Trans key={row.code} i18nKey={STATUS_CHIP[value as RecommendationStatus].label} />
    </ChipButton>
  </Stack>
);

export default StatusColumn;
