import { FieldDef, FieldType } from '../types';
import { getCheckboxJsonSchema, getCheckboxPresenterDefault, getCheckboxPresenterSchema } from './utils';
import { CheckboxFieldType } from './types';
import { CheckboxFieldBuilderCard } from './CheckboxFieldBuilderCard';
import { CheckboxFieldPresenter } from './CheckboxFieldPresenter';
import { CheckboxFieldBuilderForm } from './CheckboxFieldBuilderForm';

export const fieldDef: FieldDef<CheckboxFieldType> = {
  type: FieldType.CHECKBOX,
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.CHECKBOX',
  builderCard: CheckboxFieldBuilderCard,
  builderForm: CheckboxFieldBuilderForm,
  presenter: CheckboxFieldPresenter,
  getPresenterDefault: getCheckboxPresenterDefault,
  getPresenterSchema: getCheckboxPresenterSchema,
  getJsonSchema: getCheckboxJsonSchema,
};
