import { type ChangeEvent, useEffect, useState } from 'react';
import { type GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { InputAdornment, TextField } from '@verticeone/design-system';
import { calculateSPCosts } from '../../../../../dataSources/utils';
import { useFormatCost } from '../../../utils';
import { OfferingData } from '../../../../../types';
import { useDisabledRow } from './utils';

const pattern = /^\d+(\.\d{0,3})?$/;

type EditableHourlyCommitmentCellProps = GridCellParams<OfferingData>;

const EditableHourlyCommitmentCell = ({ id, value, field, row }: EditableHourlyCommitmentCellProps) => {
  const apiRef = useGridApiContext();
  const [localValue, setLocalValue] = useState<string | null>(Number(value)?.toFixed(3) || '');
  const formatCost = useFormatCost();
  const { isDisabled } = useDisabledRow(row.offeringId);

  useEffect(() => {
    setLocalValue(Number(value)?.toFixed(3) || '');
  }, [value]);

  if (isDisabled) {
    return formatCost(row.currencyCode, Number(value), 3);
  }

  const handleValueChange = async (newValue: string) => {
    let numericValue = !newValue || isNaN(+newValue) ? 0 : +newValue;
    let stringValue = newValue;
    if (numericValue < 0.001) {
      numericValue = 0.001;
      stringValue = '0.001';
    } else if (numericValue > 5000) {
      numericValue = 5000;
      stringValue = '5000';
    }

    const { upfrontCost, totalCost, monthlyCost } = calculateSPCosts({
      durationSeconds: row.durationSeconds,
      paymentOption: row.paymentOption,
      upfrontCost: row.upfrontCost,
      hourlyCommitment: numericValue,
    });

    await apiRef.current.setEditCellValue({ id, field: 'upfrontCost', value: upfrontCost });
    await apiRef.current.setEditCellValue({ id, field, value: numericValue });

    apiRef.current.updateRows([
      { offeringId: id, totalCost, monthlyCost, upfrontCost, hourlyCommitment: numericValue },
    ]);

    setLocalValue(stringValue);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || pattern.test(e.target.value)) {
      setLocalValue(e.target.value ? e.target.value : null);
    }
  };

  const handleBlur = async () => {
    await handleValueChange(localValue?.toString() || '');
  };

  return (
    <TextField
      type="text"
      value={localValue === null ? '' : localValue}
      variant="outlined"
      hiddenLabel
      onChange={handleChange}
      onBlur={handleBlur}
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      InputProps={{
        inputProps: {
          min: 0.001,
          max: 5000,
          step: 0.001,
          inputMode: 'decimal',
          pattern: '\\d+(\\.\\d{0,3})?',
        },
      }}
    />
  );
};

export default EditableHourlyCommitmentCell;
