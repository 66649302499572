import { DateRangePicker } from '@verticeone/design-system';
import dayjs from 'dayjs';
import { ContractYear } from '../useContractListData';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { useDebouncedCallback } from 'use-debounce';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const DateRangePickerEditCell = (props: GridRenderEditCellParams<Partial<ContractYear>>) => {
  const { id, field, row, api } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID.GRID.SNACKBAR' });
  const { enqueueSnackbar } = useSnackbar();

  const correctDateRange = useDebouncedCallback((startDate: string | null, endDate: string | null) => {
    if (row.startDate === null || row.endDate === null) {
      return;
    }

    const newStartDate = startDate ? dayjs(startDate).startOf('month') : null;
    const formattedStartDate = newStartDate?.format('YYYY-MM-DD');
    const formattedEndDate = newStartDate
      ? newStartDate.add(11, 'months').endOf('month').format('YYYY-MM-DD')
      : endDate;

    if (formattedStartDate !== row.startDate || formattedEndDate !== row.endDate) {
      void api.updateRows([{ id, startDate: formattedStartDate, endDate: formattedEndDate }]);
      void api.setEditCellValue({
        id,
        field,
        value: [formattedStartDate, formattedEndDate].filter((i) => !!i).join('_'),
      });

      enqueueSnackbar({
        variant: 'info',
        message: t('DATE_RANGE_ADJUSTED_INFO'),
      });
    }
  }, 500);

  return (
    <DateRangePicker
      dateFrom={row.startDate}
      dateTo={row.endDate}
      onChange={(startDate, endDate) => {
        void api.updateRows([{ id, startDate, endDate }]);
        void api.setEditCellValue({
          id,
          field,
          value: [startDate, endDate].filter((i) => !!i).join('_'),
        });
        correctDateRange(startDate, endDate);
      }}
    />
  );
};

export default DateRangePickerEditCell;
