import { FormControlLabel } from '@mui/material';
import { Text, ToggleSwitch } from '@verticeone/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicFormField } from '../../../types';
import { useDynamicFormBuilderContext } from '../../DynamicFormBuilderContextProvider';

export type RequiredSwitchProps = {
  field: DynamicFormField & { id: string };
};

export const RequiredSwitch = ({ field }: RequiredSwitchProps) => {
  const { t } = useTranslation();
  const { color, updateField } = useDynamicFormBuilderContext();

  const onRequiredChange = (event: unknown, newValue: boolean) => {
    updateField(field.id, {
      ...field,
      metadata: {
        ...field.metadata,
        required: newValue,
      },
    });
  };

  return (
    <FormControlLabel
      control={
        <ToggleSwitch
          checked={field.metadata.required}
          onChange={onRequiredChange}
          size="XS"
          color={color}
          sx={{ mr: '6px' }}
        />
      }
      label={
        <Text variant="button-bold" size="XS">
          {t('DYNAMIC_FORM_BUILDER.REQUIRED_FIELD')}
        </Text>
      }
      labelPlacement="end"
    />
  );
};
