import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system';
import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system';
import { Tabs } from '@verticeone/design-system';
import { Tab } from '@verticeone/design-system';
import { generatePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import CloudAccountsTab from './tabs/CloudAccountsTab';
import CloudTagsTab from './tabs/CloudTagsTab';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import { toConstantCase } from '@verticeone/utils/strings';
import { CloudSubFeature } from '../../../modules/cloud/hooks/useCloudFeature';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import PageNotFound from '@vertice/core/src/modules/notFound/PageNotFound';
import { useCloudContext } from '@vertice/dashboard/src/modules/cloud/CloudContext';
import { TabsBottomLineWrapper } from '@verticeone/design-system';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { endOfMonth, format, startOfDay, startOfMonth, subMonths } from 'date-fns';
import { DATE_FORMAT } from './tabs/BaseCloudTab/constants';
import { CloudAnalyticsProvider } from './CloudAnalyticsContext';
import DateRangePicker from './components/DateRangePicker/DateRangePicker';
import { AnalyticsTabType } from './types';

type TabDef = {
  id: AnalyticsTabType;
  component: React.ComponentType;

  /**
   * If provided, tab is visible only if this function returns true.
   * If not provided, tab is always visible.
   * @param subFeatures Info about what sub-features are enabled/disabled. Can be useful to make the decision.
   */
  getIsTabVisible?: (subFeatures: Record<CloudSubFeature, boolean | string>) => boolean;
};

const TABS: TabDef[] = [
  {
    id: 'accounts',
    component: CloudAccountsTab,
  },
  {
    id: 'tags',
    component: CloudTagsTab,
  },
];

const getTabLabelKey = (tabId: string) => `CLOUD.CLOUD_ANALYTICS.${toConstantCase(tabId)}_TAB`;

const Content = () => {
  const { subFeatures } = useCloudContext();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { activeTab: activeTabId } = useParams();
  const navigate = useNavigate();

  const visibleTabs = TABS.filter(({ getIsTabVisible }) => (getIsTabVisible ? getIsTabVisible(subFeatures) : true));
  if (visibleTabs.length === 0) {
    return <PageNotFound />;
  }

  const activeTab = visibleTabs.find(({ id }) => id === activeTabId ?? '');
  if (!activeTab) {
    return <Navigate replace={true} to={generatePath(ROUTES.CLOUD_ANALYTICS_TAB, { activeTab: visibleTabs[0].id })} />;
  }

  const dataGenerationDayOfMonth = 8;
  const today = startOfDay(new Date());
  const startDate = today.getDate() <= dataGenerationDayOfMonth ? subMonths(today, 1) : today;

  const previousMonth = subMonths(startDate, 1);
  const previousMonthEnd = endOfMonth(previousMonth);
  const previousMonthStart = startOfMonth(previousMonth);

  const previous2Month = subMonths(startDate, 2);
  const periodTwoEnd = endOfMonth(previous2Month);
  const periodTwoStart = startOfMonth(previous2Month);

  const period = {
    periodOneStart: format(previousMonthStart, DATE_FORMAT),
    periodOneEnd: format(previousMonthEnd, DATE_FORMAT),
    periodTwoStart: format(periodTwoStart, DATE_FORMAT),
    periodTwoEnd: format(periodTwoEnd, DATE_FORMAT),
  };

  return (
    <CloudAnalyticsProvider period={period}>
      <Stack gap={8} py={10}>
        <Stack gap={1} px={10}>
          <Breadcrumbs variant="slash" size="S">
            <BreadcrumbLink>{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
            <BreadcrumbLink>{t('CLOUD.CLOUD_ANALYTICS.TITLE')}</BreadcrumbLink>
            <BreadcrumbLink disabled>{t(getTabLabelKey(activeTab.id))}</BreadcrumbLink>
          </Breadcrumbs>
          <Text variant="heading" size="M" color={palette.text.color1} tag="h1">
            {t('CLOUD.CLOUD_ANALYTICS.TITLE')}
          </Text>
        </Stack>
        <Stack gap={1} px={10}>
          <DateRangePicker />
        </Stack>
        <TabsBottomLineWrapper paddingX={10}>
          <Tabs
            variant="outlined"
            value={activeTab.id}
            onChange={(_, value) => navigate(`../${value}`, { relative: 'path' })}
            color={AWS_BRAND_COLOR}
          >
            {visibleTabs.map(({ id }) => (
              <Tab key={id} value={id} label={t(getTabLabelKey(id))} />
            ))}
          </Tabs>
        </TabsBottomLineWrapper>

        <Box px={10} position="relative">
          <activeTab.component />
        </Box>
      </Stack>
    </CloudAnalyticsProvider>
  );
};

const CloudAnalytics = () => (
  <LoadableAWSPageWrapper>
    <Content />
  </LoadableAWSPageWrapper>
);

export default CloudAnalytics;
