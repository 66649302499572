import { FileMetaData } from '@vertice/slices';
import {
  AdvancedLinearApproximationCostModel,
  ContractModel2,
  ContractPartContractualProducts,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { RenewalProduct } from './hooks/useCreateRenewalRequest';
import {
  getProductsFromItems,
  isAdvancedLinearApproximationModel,
} from '../saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';

export const getFiles = (files: Array<FileMetaData>) => files.filter(({ type }) => type === 'FILE');

/**
 * Use this function to extract product items from Advanced Linear Approximation cost model
 */
export const mapCostModelProductsToRenewalProducts = (costModel?: ContractModel2): RenewalProduct[] => {
  if (isAdvancedLinearApproximationModel(costModel)) {
    const advancedCostModel = costModel?.model as AdvancedLinearApproximationCostModel;
    return getProductsFromItems(advancedCostModel?.items).map((prod) => ({
      id: prod.id ?? undefined,
      name: prod.name ?? undefined,
      productId: prod.productId ?? undefined,
      numberOfLicences: prod.numberOfLicences ?? undefined,
      licenseType: prod.licenseType ?? undefined,
      totalCost: prod.totalCost ?? undefined,
      licenseCost: prod.licenseCost ?? undefined,
      allocationSpan: prod.allocationSpan ?? undefined,
    }));
  }

  return [];
};

/**
 * Use this function to extract product items from contract.parts.contractual.products property used for legacy cost model
 */
export const mapContractProductsToRenewalProducts = (products?: ContractPartContractualProducts): RenewalProduct[] => {
  return (
    products?.map((product) => ({
      id: product.productId ?? undefined,
      name: product.productName ?? undefined,
      productId: product.productId ?? undefined,
      annualCost: product.annualCost ?? undefined,
      numberOfLicences: product.licenseCount ?? undefined,
      licenseType: product.licenseType ?? undefined,
    })) ?? []
  );
};

/**
 * Get only last part of urn. It's without version, so it can be used even if some insignificant change appear.
 * @param urn Something like 'urn:verticeone:vertice::services:form/core/jira/createTicket/v1'
 * @return Something like 'form/core/jira/createTicket'
 */
export const getLastPartDiversioned = (urn?: string) => {
  if (!urn) return null;

  const versionedKey = urn.split(':').slice(-1)[0];
  const regex = /^(.*)\/v1$/; // catch part without version
  const match = versionedKey.match(regex);
  if (match) {
    return match[1];
  }

  return null;
};
