import { useTranslation } from 'react-i18next';
import { Stack, styled, useTheme } from '@mui/material';
import { Banner } from '@vertice/core/src/components/Banner/Banner';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import React, { forwardRef, MouseEvent } from 'react';
import { IconWrapper } from '@verticeone/design-system';
import { svgIconClasses } from '@mui/material/SvgIcon';
import type { CustomizedProps, DesignSystemSize } from '@verticeone/design-system';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import type { SvgIconComponent } from '@mui/icons-material';

const StyledBanner = styled(Banner)(({ theme }) => ({
  width: '100%',
  height: 64,
  position: 'relative',
  padding: '16px',
}));

const StyledBackgroundIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.core.bg,
  justifyContent: 'center',
  alignItems: 'center',
  [`& .${svgIconClasses.root}`]: {
    width: 60,
    height: 60,
  },
}));

export type BaseIconProps = CustomizedProps & SvgIconProps;

type BackgroundIconWrapperProps = {
  size?: DesignSystemSize;
  icon: SvgIconComponent;
} & BaseIconProps;

const BackgroundIconWrapper = forwardRef<SVGSVGElement, BackgroundIconWrapperProps>(
  ({ icon: Icon, ...otherProps }, ref) => (
    <StyledBackgroundIconWrapper>
      <Icon {...otherProps} ref={ref} />
    </StyledBackgroundIconWrapper>
  )
);

const StyledOpacityWrapper = styled('div')<{ opacity: number; alignItems: string }>(({ opacity, alignItems }) => ({
  width: 80,
  alignSelf: 'stretch',
  padding: 10,
  justifyContent: 'flex-start',
  alignItems: alignItems,
  gap: 10,
  display: 'flex',
  '& > div': {
    width: 60,
    height: 60,
    opacity: opacity,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

type OpacityBackgroundIconWrapperProps = {
  opacity: number;
  alignItems?: string;
  icon: SvgIconComponent;
} & BaseIconProps;

const OpacityBackgroundIconWrapper = ({
  opacity = 1,
  alignItems = 'flex-start',
  icon: Icon,
  ...otherProps
}: OpacityBackgroundIconWrapperProps) => (
  <StyledOpacityWrapper opacity={opacity} alignItems={alignItems}>
    <BackgroundIconWrapper icon={Icon} {...otherProps} />
  </StyledOpacityWrapper>
);

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.tertiary.color4,
  justifyContent: 'center',
  alignItems: 'center',
}));

type RioBannerProps = {
  onClick: (evt: MouseEvent) => void;
};
const RioBanner = ({ onClick }: RioBannerProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RESERVED_INSTANCES.RIO_BANNER' });
  const { palette } = useTheme();

  return (
    <StyledBanner color={palette.tertiary.color2}>
      <Stack zIndex={100} direction="row" justifyContent="space-between" alignItems="center" gap={1} flexGrow={1}>
        <Stack direction="row" gap={1.5} alignItems="center">
          <StyledIconWrapper size="XL" icon={InsightsOutlinedIcon} />
          <Text variant="heading" size="XS" color={palette.core.bg}>
            {t('LABEL')}
          </Text>
        </Stack>
        <Button variant="ghost" size="XS" color="tertiary" onClick={onClick}>
          {t('BUTTON')}
        </Button>
      </Stack>
      <div
        style={{
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          position: 'absolute',
          background: 'linear-gradient(90deg, transparent 0%, rgba(15, 23, 41, 0.8) 40% 80%, transparent 100%)',
        }}
      >
        <div
          style={{
            width: 4860,
            height: 1620,
            left: -1350,
            top: -270,
            position: 'absolute',
            boxShadow: '512px 512px 512px ',
            filter: 'blur(512px)',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 512,
            display: 'none', // display: 'inline-flex',
          }}
        >
          <div
            style={{
              flex: '1 1 0',
              alignSelf: 'stretch',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              display: 'flex',
            }}
          >
            <div
              style={{
                flex: '1 1 0',
                alignSelf: 'stretch',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <div style={{ flex: '1 1 0', alignSelf: 'stretch', background: '#E543E5' }}></div>
            </div>
          </div>
          <div
            style={{
              flex: '1 1 0',
              alignSelf: 'stretch',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              display: 'flex',
            }}
          >
            <div
              style={{
                flex: '1 1 0',
                alignSelf: 'stretch',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <div style={{ flex: '1 1 0', alignSelf: 'stretch', background: '#E543E5' }}></div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            position: 'absolute',
            mixBlendMode: 'soft-light',
            background: 'rgba(15, 23, 41, 0.80)',
            display: 'none',
          }}
        />
      </div>
      <div
        style={{
          height: 100,
          right: 220,
          top: -15,
          position: 'absolute',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 15,
          display: 'inline-flex',
        }}
      >
        <OpacityBackgroundIconWrapper icon={InsightsOutlinedIcon} opacity={0.1} />
        <OpacityBackgroundIconWrapper alignItems="flex-end" icon={InsightsOutlinedIcon} opacity={0.2} />
        <OpacityBackgroundIconWrapper icon={InsightsOutlinedIcon} opacity={0.3} />
        <OpacityBackgroundIconWrapper alignItems="flex-end" icon={InsightsOutlinedIcon} opacity={0.4} />
        <OpacityBackgroundIconWrapper icon={InsightsOutlinedIcon} opacity={0.5} />
      </div>
    </StyledBanner>
  );
};

export default RioBanner;
