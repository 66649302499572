import React from 'react';
import { NoData } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { ReportProblemTwoTone } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';

const StyledReportProblemIcon = styled(ReportProblemTwoTone)(({ theme }) => ({
  color: theme.palette.error.color2,
}));

const EmptyStateError = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EMPTY_STATE' });

  return (
    <Stack minHeight="200px" direction="row" alignItems="center" justifyContent="center">
      <NoData
        icon={<StyledReportProblemIcon />}
        header={t('ERROR_LOADING_DATA_TITLE')}
        content={t('ERROR_LOADING_DATA_TEXT')}
      />
    </Stack>
  );
};

export default EmptyStateError;
