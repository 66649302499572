import * as z from 'zod';

const edgeSchema = z.object({
  id: z.string(),
  name: z.string(),
  condition: z.string().optional(),
  conditionType: z.enum(['JsonLogic', 'JMESPathExpression', 'DefaultFlow', 'Expression', 'FEELExpression', 'Script']),
});

export const editGatewayFormSchema = z
  .object({
    gatewayType: z.enum(['Exclusive', 'Join', 'Fork']),
    edges: z.array(edgeSchema),
  })
  .superRefine((data, ctx) => {
    if (data.gatewayType === 'Exclusive') {
      data.edges.forEach((edge, index) => {
        if (!edge.condition && edge.conditionType !== 'DefaultFlow') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Condition is required when gatewayType is Exclusive',
            path: ['edges', index, 'condition'],
          });
        }
      });
    }
  });

export type EditGatewayFormData = z.infer<typeof editGatewayFormSchema>;
