import React from 'react';
import { Check, Close, Remove, SvgIconComponent } from '@mui/icons-material';
import { styled } from '@mui/material';
import { DesignSystemColor } from '@verticeone/design-system';
import { rgba } from 'polished';

export const BulletType = {
  check: 'check',
  minus: 'minus',
  cross: 'cross',
} as const;

export type TypeOfBulletType = keyof typeof BulletType;

interface IconBulletProps {
  type?: TypeOfBulletType;
  size?: number;
}

const IconWrapper = styled('div')<{ $color: DesignSystemColor }>(({ $color, theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: rgba(palette[$color].color4, 0.4), // Temporary, before we get a proper design for icon wrapper with color
  color: palette[$color].color2,
}));

const variants = {
  [BulletType.check]: { icon: Check, color: 'success' },
  [BulletType.minus]: { icon: Remove, color: 'secondary' },
  [BulletType.cross]: { icon: Close, color: 'error' },
} as Record<TypeOfBulletType, { icon: SvgIconComponent; color: DesignSystemColor }>;

const IconBullet: React.FC<IconBulletProps> = ({ type = BulletType.check, size = 24 }) => {
  const iconSize = Math.round(size - (2 * size) / 6);

  const Icon = variants[type].icon;

  return (
    <IconWrapper $color={variants[type].color} style={{ width: size, height: size }}>
      <Icon color="inherit" sx={{ display: 'block', margin: 'auto', fontSize: `${iconSize}px` }} />
    </IconWrapper>
  );
};

export default IconBullet;
