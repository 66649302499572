import { FC } from 'react';
import { Text, EllipsisText, IconWrapper } from '@verticeone/design-system';
import { Request } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useTranslation } from 'react-i18next';
import { RequestRow } from '../../types';
import { Stack, styled } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { VerticeVIcon, SubProcess } from '@vertice/assets';
import { Tooltip } from '@verticeone/design-system';
import { RequestStatusChip } from '../../../components/RequestStatusChip';
import { ShowOnRowHover } from '../ShowOnRowHover';

type RequestStatusProps = {
  status: Request['status'];
  activeTasks?: RequestRow['activeTasks'];
  completedTasks?: RequestRow['completedTasks'];
  customValue?: string;
};

const StyledSubProcessIcon = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.secondary.main,
  borderRadius: 5,
  marginLeft: theme.spacing(2),
}));

export const RequestStatusCell: FC<RequestStatusProps> = ({ status, activeTasks, completedTasks, customValue }) => {
  if (status === 'ACTIVE' && activeTasks?.length) {
    return <ActiveRequestStatus activeTasks={activeTasks} completedTasks={completedTasks} />;
  }

  return <RequestStatusChip status={status} customValue={customValue} />;
};

type RequestTasksProps = {
  tasks: Required<RequestRow>['activeTasks'];
};

const RequestTasks: FC<RequestTasksProps> = ({ tasks }) => {
  return (
    <Stack overflow="hidden" alignItems="start">
      {tasks.map(({ name }) => (
        <EllipsisText key={name} variant="body-regular" size="S" color="text4">
          {name}
        </EllipsisText>
      ))}
    </Stack>
  );
};

type ActiveRequestStatusProps = {
  activeTasks: Required<RequestRow>['activeTasks'];
  completedTasks: RequestRow['completedTasks'];
};

export const ActiveRequestStatus: FC<ActiveRequestStatusProps> = ({ activeTasks, completedTasks }) => {
  const { t } = useTranslation();

  const singleTask = activeTasks.length === 1;
  const task = activeTasks[0];

  return (
    <Tooltip
      size="S"
      title={t(`ENTITIES.WORKFLOW_REQUEST.LABELS.ACTIVE_TASKS`)}
      placement="bottom"
      content={<RequestTasks tasks={activeTasks} />}
      disableHoverListener={singleTask}
    >
      <Stack overflow="hidden" gap={1}>
        <Stack direction="row" alignItems="center">
          <EllipsisText variant="body-regular" size="S" color="text1">
            {task.name}
          </EllipsisText>

          {task.managed && (
            <Stack px={2}>
              <VerticeVIcon />
            </Stack>
          )}

          {!task.managed && task.type === 'SERVICE' && (
            <Tooltip
              placement="bottom"
              title={t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.ACTIVE_SUB_PROCESS')}
              size="S"
            >
              <Stack>
                <StyledSubProcessIcon size="XL" icon={SubProcess} />
              </Stack>
            </Tooltip>
          )}

          {!singleTask && (
            <Text variant="body-regular" size="S" color="text1" px={1}>
              {`(+${activeTasks.length - 1})`}
            </Text>
          )}
        </Stack>
        <ShowOnRowHover>
          {completedTasks !== undefined && (
            <Text variant="caption" size="XS" color="text3">
              {t(`ENTITIES.WORKFLOW_REQUEST.LABELS.COMPLETED_TASKS`, { count: completedTasks })}
            </Text>
          )}
        </ShowOnRowHover>
      </Stack>
    </Tooltip>
  );
};
