import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogHeader, DialogText, TextField } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { useTranslation } from 'react-i18next';

type RenameRequestDialogProps = {
  originalName: string;
  isDialogOpen: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
};

export const RenameRequestDialog = ({ originalName, isDialogOpen, onClose, onSave }: RenameRequestDialogProps) => {
  const [name, setName] = useState(originalName);
  const { t } = useTranslation();

  return (
    <Dialog size="M" open={isDialogOpen} onClose={onClose}>
      <DialogHeader>
        <DialogText size="L" variant="body-bold">
          {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.DIALOGS.RENAME_REQUEST.TITLE')}
        </DialogText>
      </DialogHeader>

      <Stack px={6}>
        <TextField
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          label="Request Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Stack>

      <DialogActions>
        <Stack direction="row" gap={2}>
          <Button color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} onClick={onClose}>
            {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.DIALOGS.RENAME_REQUEST.CANCEL')}
          </Button>
          <Button color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} variant="solid" onClick={() => onSave(name)}>
            {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.DIALOGS.RENAME_REQUEST.RENAME')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
