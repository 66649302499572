import { useMemo } from 'react';

import { useDataSource } from '../../../../../../DataSourceProvider';
import { useTableContext } from '../TableContext';
import { OfferingData } from '../../../../../types';

export const useModalData = () => {
  const { datasource } = useDataSource();
  const { apiRef } = useTableContext();
  const offerings = apiRef && (Array.from(apiRef?.current?.getRowModels().values()) as Array<OfferingData>);

  return useMemo(
    () => ({
      product: datasource,
      details: offerings ?? [],
    }),
    [datasource, offerings]
  );
};
