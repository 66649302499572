import type { Property, PropertyId } from '../types';
import type { OriginsWithProperties, VariablesGroup } from './types';

export const hasObjectKeys = (obj: Record<string, unknown>) => Object.keys(obj).length > 0;

export const normalize = (value: string) =>
  value
    .trim()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();

const propertyMatches = (property: Property, what: string) => {
  const { origin, typeLabel, label } = property;

  return (
    normalize(label).includes(what) ||
    normalize(typeLabel?.[0] ?? '').includes(what) ||
    normalize(origin.label ?? '').includes(what)
  );
};

const recursiveSearch = ({ in: properties, what }: { in: Property[]; what: string }) => {
  const results: Property[] = [];

  for (const prop of properties) {
    let isMatch = propertyMatches(prop, what);
    let matchedChildren: Property[] = [];

    if ('properties' in prop && Array.isArray(prop.properties)) {
      matchedChildren = recursiveSearch({ in: prop.properties, what });
      if (matchedChildren.length > 0) {
        isMatch = true;
      }
    }

    if (isMatch) {
      const newProp = { ...prop, ...(matchedChildren.length > 0 ? { properties: matchedChildren } : {}) };
      results.push(newProp);
    }
  }

  return results;
};

export const filterVariablesGroups = ({
  variablesGroups,
  searchValue,
  groupId = 'all',
}: {
  variablesGroups: VariablesGroup[];
  searchValue?: string;
  groupId?: string;
}) => {
  if (!searchValue && groupId === 'all') {
    return variablesGroups;
  }

  const variablesGroupsToSearchIn =
    groupId === 'all' ? variablesGroups : variablesGroups.filter((group) => group.id.includes(groupId));

  if (!searchValue) {
    return variablesGroupsToSearchIn;
  }

  const normalizedSearchValue = normalize(searchValue);

  const filteredVariablesGroups: VariablesGroup[] = [];

  for (const variableGroup of variablesGroupsToSearchIn) {
    const filteredOriginsWithProperties: OriginsWithProperties = {};

    for (const originWithProperties of Object.values(variableGroup.originsWithProperties)) {
      const filteredProperties = recursiveSearch({ in: originWithProperties.properties, what: normalizedSearchValue });

      if (filteredProperties.length > 0) {
        filteredOriginsWithProperties[originWithProperties.origin.id] = {
          ...originWithProperties,
          properties: filteredProperties,
        };
      }
    }

    if (hasObjectKeys(filteredOriginsWithProperties)) {
      filteredVariablesGroups.push({
        ...variableGroup,
        originsWithProperties: filteredOriginsWithProperties,
      });
    }
  }

  return filteredVariablesGroups;
};

export const countProperties = (properties: Property[]) => {
  let count = 0;
  for (const property of properties) {
    count += 1;
    if ('properties' in property && Array.isArray(property.properties)) {
      count += countProperties(property.properties);
    }
  }
  return count;
};

export const groupVariablesByOrigin = ({
  properties,
  hideOrigin = false,
}: {
  properties: Property[];
  hideOrigin?: boolean;
}) => {
  return properties.reduce<OriginsWithProperties>((originWithProperties, property) => {
    const originId = property.origin.id;

    if (!originWithProperties[originId]) {
      originWithProperties[originId] = {
        origin: property.origin,
        properties: [property],
        hideOrigin,
      };

      return originWithProperties;
    }

    originWithProperties[originId].properties.push(property);

    return originWithProperties;
  }, {});
};

export const findPropertyById = (properties: Property[], propertyId: PropertyId): Property | null => {
  for (const property of properties) {
    if (property.id === propertyId) {
      return property;
    }

    if ('properties' in property && Array.isArray(property.properties)) {
      const foundProperty = findPropertyById(property.properties, propertyId);
      if (foundProperty) {
        return foundProperty;
      }
    }
  }

  return null;
};
