import { useNavigate } from 'react-router-dom';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useDeactivateAccountIntegrationMutation, useRevokeAccountIntegrationMutation } from '@vertice/slices';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { isValidIntegrationResponse } from 'pages/Preferences/Integrations/utils';
import { JIRA_PROVIDER_ID } from '../constants';

export const useDeactivateIntegration = () => {
  const { accountId } = useAccountContext();
  const navigate = useNavigate();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const [deactivateAccountIntegration, { isLoading: isIntegrationDeactivationInProgress }] =
    useDeactivateAccountIntegrationMutation();
  const [revokeAccountIntegration, { isLoading: isIntegrationRevokeInProgress }] =
    useRevokeAccountIntegrationMutation();

  const deactivateIntegration = async () => {
    const response = await deactivateAccountIntegration({
      accountId,
      integrationId: JIRA_PROVIDER_ID,
    });

    if (isValidIntegrationResponse(response)) {
      await revokeAccountIntegration({ accountId, integrationId: JIRA_PROVIDER_ID });
      navigate(generatePath(routes.PREFERENCES.TAB.ABSOLUTE_PATH, { activeTab: 'integrations' }));
    }
  };

  return { deactivateIntegration, isIntegrationDeactivationInProgress, isIntegrationRevokeInProgress };
};
