import { useId } from 'react';
import { Stack, useTheme } from '@mui/material';
import { Placeholder } from '@verticeone/design-system';
import { CommentTwoTone } from '@mui/icons-material';

export const RequestSkeletonCell = () => {
  const theme = useTheme();

  return (
    <Stack pl={5} pr={4} gap={4} direction="row" alignItems="center" width="-webkit-fill-available">
      <Stack position="relative">
        <Placeholder height={40} width={40} variant="rounded" />
        <Placeholder
          height={20}
          width={20}
          variant="circular"
          sx={{
            position: 'absolute',
            bottom: '-25%',
            right: '-25%',
            border: `1px solid ${theme.palette.neutral.color3}`,
          }}
        />
      </Stack>
      <Placeholder height={24} width="100%" variant="rounded" />
    </Stack>
  );
};

export const BaseSkeletonCell = () => {
  return (
    <Stack width="-webkit-fill-available">
      <Placeholder width="100%" height={21} variant="rounded" />
    </Stack>
  );
};

export const AssigneesSkeletonCell = () => {
  const id = useId();
  const theme = useTheme();

  return (
    <Stack position="relative" direction="row" justifyContent="center" alignItems="center">
      {Array.from({ length: 4 }).map((_, index) => (
        <Placeholder
          height={30}
          width={30}
          variant="circular"
          key={[id, index].join()}
          sx={{
            border: `2px solid ${theme.palette.neutral.color3}`,
            transform: `translateX(${index * -20}%)`,
          }}
        />
      ))}
    </Stack>
  );
};

export const ActionsSkeletonCell = () => {
  const { palette } = useTheme();

  return (
    <Stack direction="row" gap={2}>
      <Stack position="relative" height={28} width={28}>
        <CommentTwoTone htmlColor={palette.core.color3} sx={{ height: 28, width: 28 }} />
        <Placeholder
          height={20}
          width={20}
          variant="circular"
          sx={{ position: 'absolute', top: '-25%', right: '-25%', border: `1px solid ${palette.neutral.color3}` }}
        />
      </Stack>
    </Stack>
  );
};
