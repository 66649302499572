import React from 'react';
import { Stack } from '@mui/material';
import type { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-pro';

import type { OptimizationRow } from '../../../dataSource/useOptimizations';
import EffortChip from '../Components/EffortChip';

type EffortColumnProps = GridRenderCellParams<OptimizationRow, any, any, GridTreeNodeWithRender>;

const EffortColumn = (params: EffortColumnProps) => (
  <Stack direction="row" gap={2} flex={1} alignItems="center" justifyContent="space-between">
    <EffortChip key={params.row.effort} effort={params.row.effort} />
  </Stack>
);

export default EffortColumn;
