import React from 'react';
import { FormProductList } from '../shared/formFields/FormProductList';
import { Grid } from '@verticeone/design-system';
import { SelectVendorPanel } from '../shared/formFields/SelectVendorPanel';
import { TargetSignDateFormEntry } from '../shared/formFields/TargetSignDateFormEntry';
import { AdditionalNotesFormEntry } from '../shared/formFields/AdditionalNotesFormEntry';

export const PurchaseIntakeSimpleForm = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <SelectVendorPanel vendorName="vendor" />
      </Grid>
      <Grid item xs={12}>
        <FormProductList />
      </Grid>
      <TargetSignDateFormEntry />
      <AdditionalNotesFormEntry />
    </Grid>
  );
};
