import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Card, CardHeader, CardHeaderDescription, CardHeaderTitle } from '@verticeone/design-system';
import { CustomFieldsList } from '@vertice/core/src/modules/saas/contract/components/CustomFieldsList';

const Customization = () => {
  const { t } = useTranslation();

  return (
    <Stack px={10} spacing={4}>
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle text={t('PREFERENCES.CUSTOMIZATION.TITLE')} />
          <CardHeaderDescription>{t('PREFERENCES.CUSTOMIZATION.DESCRIPTION')}</CardHeaderDescription>
        </CardHeader>
        <CustomFieldsList noBorder />
      </Card>
    </Stack>
  );
};

export default Customization;
