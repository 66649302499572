import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';

import { createTypedTaskFormEntry } from '../shared/formFields/TaskFormEntry';
import { AdditionalNotesFormEntry } from '../shared/formFields/AdditionalNotesFormEntry';
import { FormCardWrapper } from '../shared/formSections/FormCardWrapper';
import { SecurityQuestion } from './formFields/SecurityQuestion';
import { FormData } from './schema';

const SecurityQuestionnaireFormEntry = createTypedTaskFormEntry<FormData>();

export const SecurityQuestionnaireForm = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <FormCardWrapper gap={6}>
        <SecurityQuestionnaireFormEntry
          name="vendorWebsite"
          label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.VENDOR_WEBSITE')}
          component={FormTextField}
          width={6}
        />
        <SecurityQuestionnaireFormEntry
          name="vendorContact.name"
          label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.VENDOR_CONTACT')}
          component={FormTextField}
          width={6}
        />
        <SecurityQuestionnaireFormEntry
          name="vendorContact.email"
          label={t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.VENDOR_CONTACT_EMAIL')}
          component={FormTextField}
          width={12}
        />
        <SecurityQuestion fieldName="dataSharedWithVendor" questionNumber={1} />
      </FormCardWrapper>

      <FormCardWrapper>
        <Text variant="caption" size="S" color="text1">
          {t('INTAKE_FORM.SECURITY_QUESTIONNAIRE.TITLE_1')}
        </Text>
        <SecurityQuestion fieldName="dataSharingDetails.customerPiiData" questionNumber={2} />
        <SecurityQuestion fieldName="dataSharingDetails.customerAccountAuthData" questionNumber={3} />
        <SecurityQuestion fieldName="dataSharingDetails.employeeContacts" questionNumber={4} />
        <SecurityQuestion fieldName="dataSharingDetails.employeeSocialSecurityNumbers" questionNumber={5} />
        <SecurityQuestion fieldName="dataSharingDetails.employeeBankAccounts" questionNumber={6} />
        <SecurityQuestion fieldName="dataSharingDetails.acquisitionPlans" questionNumber={7} />
        <SecurityQuestion fieldName="dataSharingDetails.sourceCode" questionNumber={8} />
        <SecurityQuestion fieldName="dataSharingDetails.publishedResearch" questionNumber={9} />
        <SecurityQuestion fieldName="dataSharingDetails.pressReleases" questionNumber={10} />
        <SecurityQuestion fieldName="dataSharingDetails.accountingData" questionNumber={11} />
        <SecurityQuestion fieldName="dataSharingDetails.customerCreditCardData" questionNumber={12} />
        <SecurityQuestion fieldName="dataSharingDetails.customerMedicalRecords" questionNumber={13} />
      </FormCardWrapper>

      <FormCardWrapper>
        <SecurityQuestion fieldName="additionalIntegration" questionNumber={14} />
        <AdditionalNotesFormEntry />
      </FormCardWrapper>
    </Stack>
  );
};
