import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { testProps } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { LoadableComponent } from '@verticeone/design-system';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import useOptimizations from '../../dataSource/useOptimizations';

type StatsItemProps = {
  title: string;
  value: number;
};

const StatsItem = ({ title, value, ...otherProps }: StatsItemProps) => {
  const { palette } = useTheme();

  return (
    <Stack direction="column" flex={1} {...otherProps} bgcolor={palette.core.bg} px={6} py={0.5}>
      <Text variant="body-bold" size="S" color="text2">
        {title}
      </Text>
      <Text variant="heading" size="M" color="text1">
        {value}
      </Text>
    </Stack>
  );
};

const ScoreBarStats = () => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.SCORE_BAR' });
  const { data, isFetching } = useOptimizations();

  return (
    <Stack bgcolor={palette.core.bg} flex={1} pt={6} {...testProps('recommendations-overview')}>
      <LoadableComponent isLoading={isFetching} color={AWS_BRAND_COLOR}>
        <Stack direction="row" gap={0.25} bgcolor={palette.core.color3}>
          <StatsItem
            title={t('TOTAL_RECOMMENDATIONS')}
            value={data?.summary?.recsTotal ?? 0}
            {...testProps('totalRecommendations')}
          />
          <StatsItem title={t('TO_DO')} value={data?.summary?.recsBreaching ?? 0} {...testProps('toDo')} />
          <StatsItem title={t('WONT_DO')} value={data?.summary?.recsCancelled ?? 0} {...testProps('wontDo')} />
          <StatsItem
            title={t('WELL_OPTIMIZED')}
            value={data?.summary?.recsNonBreaching ?? 0}
            {...testProps('wellOptimized')}
          />
        </Stack>
      </LoadableComponent>
    </Stack>
  );
};

export default ScoreBarStats;
