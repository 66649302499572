import React, { FC } from 'react';
import { Stack } from '@mui/material';
import VendorLogo from '../../../../../../components/VendorLogo/VendorLogo';
import { Text } from '@verticeone/design-system';
import { ChipButton } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isContractInProgress, isContractPurchase } from '../../../computed';

export type VendorCellProps = {
  viewOnContract: ViewOnContract;
  showContractId?: boolean;
};

export const vendorCellValueGetter = (viewOnContract: ViewOnContract) =>
  viewOnContract.contract.parts.contractual?.vendor?.vendorName;

export const VendorCell: FC<VendorCellProps> = ({ viewOnContract, showContractId = false }: VendorCellProps) => {
  const { t } = useTranslation();

  const vendor = viewOnContract.contract.parts.contractual?.vendor;
  const vendorId = vendor?.vendorId || undefined;
  const vendorName = vendor?.vendorName || undefined;
  const products = viewOnContract.contract.parts.contractual?.products;
  const productNames = products
    ?.filter((product) => !!product.productName)
    .map((product) => product.productName as string);

  const isPurchaseInProgress =
    isContractPurchase(viewOnContract.contract) && isContractInProgress(viewOnContract.contract);

  const showProducts = productNames && productNames.length > 0;

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <VendorLogo vendorId={vendorId} />
      <Stack gap={0.5}>
        <Text variant="body-regular">{vendorName}</Text>
        {showProducts && (
          <Text variant="caption" size="S" color="text3">
            {productNames.join(', ')}
          </Text>
        )}
      </Stack>
      {showContractId && (
        <Text variant="body-regular" size="XS" color="text3">
          {viewOnContract.contract.record.contractId}
        </Text>
      )}
      {isPurchaseInProgress && (
        <ChipButton variant="solid" size="S" color="tertiary" sx={{ marginLeft: 2 }} isActive={false}>
          {t('COMMON.NEW')}
        </ChipButton>
      )}
    </Stack>
  );
};

export default VendorCell;
