import { FC } from 'react';
import { Stack, SxProps } from '@mui/material';
import { Button, Text } from '@verticeone/design-system';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type MissingSetupProps = {
  title: string;
  description: string;
  setupLink: string;
  sx?: SxProps;
};

export const MissingSetup: FC<MissingSetupProps> = ({ title, description, setupLink, sx = {} }) => {
  const { t } = useTranslation();
  return (
    <Stack gap={10} alignItems="start" sx={sx}>
      <Stack gap={2}>
        <Text variant="body-bold" size="S">
          {title}
        </Text>
        <Text variant="body-regular" size="XS" color="neutral2">
          {description}
        </Text>
      </Stack>
      <Button variant="solid" color="neutral" component={Link} to={setupLink}>
        {t('INTELLIGENT_WORKFLOWS.INTEGRATION.GO_TO_SETUP')}
      </Button>
    </Stack>
  );
};
