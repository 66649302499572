import { FieldValues, useFormContext } from 'react-hook-form';
import { Text } from '@verticeone/design-system';
import React from 'react';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material';
import { CommonFormFieldProps } from '@vertice/core/src/modules/forms/types';
import { AccountUser } from '@vertice/slices/src/openapi/codegen/accountAPI';
import { getFullName } from '@verticeone/utils/formatting';
import { isObject, isArray } from 'lodash';

export type UserReadOnlyListFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  emptyValue?: string;
  getUserData: (value: { userId?: string }) => AccountUser | undefined;
};

export type UserReadOnlyListFieldType<FormDataType extends FieldValues> = (
  props: UserReadOnlyListFieldProps<FormDataType>
) => JSX.Element;

const StyledRow = styled(Stack)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.core.color1,
  padding: '4px',
  gap: 4,
}));

const UserReadOnlyListField = <FormDataType extends FieldValues = never>({
  id,
  name,
  emptyValue = '-',
  getUserData,
}: UserReadOnlyListFieldProps<FormDataType>) => {
  const { watch } = useFormContext<FormDataType>();
  const watchedValue = watch(name);
  const selectedValues = (
    watchedValue && isObject(watchedValue) && !isArray(watchedValue) ? [watchedValue] : watchedValue
  ) as { userId: string }[] | undefined;

  if (!selectedValues || selectedValues.length === 0) {
    return (
      <StyledRow>
        <Text id={id} variant="body-regular" color="text3">
          {emptyValue}
        </Text>
      </StyledRow>
    );
  }

  return (
    <Stack gap={2}>
      {selectedValues.map((value) => {
        const user = getUserData(value);
        return (
          <StyledRow direction="row" key={value.userId}>
            <Text id={id} variant="body-regular">
              {getFullName(user) || user?.email!}
            </Text>
            <Text id={id} variant="body-regular" color="text3">
              {user?.accountUserProperties?.jobTitle}
            </Text>
          </StyledRow>
        );
      })}
    </Stack>
  );
};

export default UserReadOnlyListField;
