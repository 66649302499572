import { FieldCard } from '../../DynamicFormBuilder/components/FieldCard/FieldCard';
import FieldCardContent from '../../DynamicFormBuilder/components/FieldCard/FieldCardContent';
import { DateFieldType } from './types';

export type DateFieldBuilderCardProps = {
  field: DateFieldType & { id: string };
};

export const DateFieldBuilderCard = (props: DateFieldBuilderCardProps) => {
  return (
    <FieldCard>
      <FieldCardContent {...props} />
    </FieldCard>
  );
};
