import { FieldCard } from '../../DynamicFormBuilder/components/FieldCard/FieldCard';
import FieldCardContent from '../../DynamicFormBuilder/components/FieldCard/FieldCardContent';
import { NumberFieldType } from './types';

export type NumberFieldBuilderCardProps = {
  field: NumberFieldType & { id: string };
};

export const NumberFieldBuilderCard = (props: NumberFieldBuilderCardProps) => {
  return (
    <FieldCard>
      <FieldCardContent {...props} />
    </FieldCard>
  );
};
