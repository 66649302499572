import { useNavigate } from 'react-router-dom';
import { NoData, NoDataButton } from '@verticeone/design-system';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHasEnabledSSOProvider } from './hooks';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { DataInOrderIcon } from '@vertice/assets';
import { HealthFilter } from '../UsageDetail/utils';

type NoRowsProps = {
  filter?: HealthFilter;
  unhealthyApplicationsCount?: number;
  /** Router path to the Integrations page. Used as call-to-action if no applications are integrated. */
  integrationsHref?: string;
};

type ContentBoxProps = {
  text: string;
};

const ContentBox = ({ text }: ContentBoxProps) => (
  <Box width="30rem" textAlign="center">
    {text}
  </Box>
);

export const ApplicationGridNoRows = ({ filter, unhealthyApplicationsCount, integrationsHref }: NoRowsProps) => {
  const { t } = useTranslation();
  const { someProviderEnabled } = useHasEnabledSSOProvider();
  const navigate = useNavigate();
  const translateKey = someProviderEnabled ? 'ESTIMATION_IN_PROGRESS' : 'NO_PROVIDER';

  // if there are no unhealthy applications and we are filtering by unhealthy applications, we show the happy content
  if (filter === HealthFilter.UNHEALTHY) {
    return (
      <Box height={1}>
        <NoData
          header={t('APPLICATIONS.LIST.ISSUES.HEADER')}
          icon={<DataInOrderIcon width={200} height={200} />}
          content={<ContentBox text={t('APPLICATIONS.LIST.ISSUES.CONTENT')} />}
        />
      </Box>
    );
  }

  // when filter is on "healthy" but there are some unhealthy, we mention the unhealthy
  if (filter === HealthFilter.HEALTHY && unhealthyApplicationsCount) {
    return (
      <Box height={1}>
        <NoData
          header={t('APPLICATIONS.LIST.ALL_UNHEALTHY.HEADER')}
          content={<ContentBox text={t('APPLICATIONS.LIST.ALL_UNHEALTHY.CONTENT')} />}
        />
      </Box>
    );
  }

  // there are really no apps => depending whether the there's some active provider show the message
  return (
    <Box height={1}>
      <NoData
        header={t(`APPLICATIONS.LIST.${translateKey}.HEADER`)}
        content={<ContentBox text={t(`APPLICATIONS.LIST.${translateKey}.CONTENT`)} />}
        button={
          !someProviderEnabled &&
          integrationsHref && (
            <NoDataButton
              text={t(`APPLICATIONS.LIST.${translateKey}.BUTTON`)}
              onClick={() => navigate(integrationsHref)}
            />
          )
        }
      />
    </Box>
  );
};
