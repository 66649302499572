import {
  userAPICodegen,
  GetUsersPublicInformationApiResponse,
  GetUsersPublicInformationApiArg,
} from '@vertice/slices/src/openapi/codegen/userAPI';

export const enhancedUserAPI = userAPICodegen
  .enhanceEndpoints({
    endpoints: {
      updateUserSettings: {
        onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
          const result = await queryFulfilled;
          dispatch(userAPICodegen.util.updateQueryData('getUserSettings', { userId: arg.userId }, () => result.data));
        },
      },
      adminUpdateUserSettings: {
        onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
          const result = await queryFulfilled;
          dispatch(userAPICodegen.util.updateQueryData('getUserSettings', { userId: arg.userId }, () => result.data));
        },
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      listUsersPublicInformation: build.query<
        GetUsersPublicInformationApiResponse,
        GetUsersPublicInformationApiArg['body']
      >({
        queryFn: async (arg, _, __, baseQuery) => {
          const queryResponse = await baseQuery({
            url: `/batch/users/public`,
            body: arg,
            method: 'POST',
          });
          return { data: queryResponse.data as GetUsersPublicInformationApiResponse };
        },
      }),
    }),
  });

export const { useListUsersPublicInformationQuery } = enhancedUserAPI;
