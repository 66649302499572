import React, { FC, useCallback } from 'react';
import { CommonIntakeFormFormData } from '../types';
import { useFormContext, useWatch } from 'react-hook-form';
import { ProductList } from '@vertice/core/src/modules/saas/contract/components/ProductList';
import { useProductExtraColumnBuilder } from '@vertice/core/src/modules/saas/contract/components/ProductList/hooks/useProductExtraColumnBuilder';
import { ProductItem, ProductListColumn } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';
import { isNotNil } from '@verticeone/utils/validation';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { CostModelProductList } from '@vertice/core/src/modules/saas/contract/components/CostModelProductList';
import { AddExistingContractFormData } from '../AddExistingContractWizard/types';

type IntakeProductListProps = {
  showAnnualCost?: boolean;
  showLicenseType?: boolean;
};
export const IntakeProductList: FC<IntakeProductListProps> = ({ showAnnualCost, showLicenseType = false }) => {
  const { isFeatureEnabled } = useAccountContext();
  const advancedCostModels = isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS);
  const { setValue } = useFormContext<CommonIntakeFormFormData>();

  const products = useWatch<CommonIntakeFormFormData, 'products'>({ name: 'products' });
  const contractCurrency = useWatch<CommonIntakeFormFormData, 'contractCurrency'>({ name: 'contractCurrency' });
  const vendor = useWatch<CommonIntakeFormFormData, 'vendor'>({ name: 'vendor' });
  const rollingFrequency = useWatch<AddExistingContractFormData, 'rollingFrequency'>({ name: 'rollingFrequency' });

  const setProducts = useCallback(
    (productsToSet: ProductItem[]) => {
      setValue('products', productsToSet);
    },
    [setValue]
  );

  const { buildColumn } = useProductExtraColumnBuilder();

  if (advancedCostModels) {
    return (
      <CostModelProductList
        withActions
        selectedProducts={products || []}
        setSelectedProducts={setProducts}
        currency={contractCurrency}
        vendorId={vendor?.id ?? undefined}
        rollingFrequency={rollingFrequency ? rollingFrequency : 'NO'}
      />
    );
  }

  return (
    <ProductList
      withActions
      extraColumns={[
        showLicenseType ? buildColumn(ProductListColumn.LICENSE_TYPE) : null,
        showAnnualCost ? buildColumn(ProductListColumn.ANNUAL_COST) : null,
        buildColumn(ProductListColumn.NUMBER_OF_LICENSES),
      ].filter(isNotNil)}
      selectedProducts={products || []}
      setSelectedProducts={setProducts}
      currency={contractCurrency}
      vendorId={vendor?.id ?? undefined}
    />
  );
};
