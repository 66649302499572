import React from 'react';
import { Box, CircularProgress, Stack, useTheme } from '@mui/material';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
import { Text } from '@verticeone/design-system';
import { TextFieldCaption } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { Vendor } from '@vertice/core/src/modules/vendor/types';

type SelectedVendorPanelProps = {
  showProducts?: boolean;
};

export const SelectedVendorPanel: React.FC<SelectedVendorPanelProps> = ({ showProducts = true }) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const vendor: Vendor | null = useWatch({ name: 'vendor' });

  if (!vendor) {
    return null;
  }

  return (
    <Box bgcolor={palette.core.color1} p={4}>
      <Stack direction="column" spacing={2}>
        <TextFieldCaption label={t('INTAKE_FORM.VENDOR_SELECTION.SELECTED_VENDOR')} size="XS" />
        <Stack direction="row" spacing={2} alignItems="center">
          <Box padding={1} bgcolor={palette.core.bg} borderRadius={2}>
            <VendorLogo vendorId={vendor.id ?? undefined} />
          </Box>
          <Box flexGrow={1}>
            <Stack>
              <Text variant="body-regular" size="M">
                {vendor.name ? vendor.name : <CircularProgress size={16} />}
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
