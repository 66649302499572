import React from 'react';
import { Text } from '@verticeone/design-system';
import { useDataGridContext } from '@verticeone/design-system';
import { HEADER_SIZES } from '@verticeone/design-system';
import { GridHeaderCell, GridHeaderCellProps } from '@verticeone/design-system';
import { GridValidRowModel } from '@mui/x-data-grid';

export const GridHeaderCellWithSubtitle = <T extends GridValidRowModel = any>(props: GridHeaderCellProps<T>) => {
  const { subtitle } = props;
  const { headerSize } = useDataGridContext();
  const size = HEADER_SIZES[headerSize].fontSize;

  return (
    <GridHeaderCell
      {...props}
      subtitle={
        <Text variant="body-regular" size={size} color="neutral1">
          {subtitle}
        </Text>
      }
    />
  );
};
