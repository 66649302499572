import { useDataSource } from '../../../DataSourceProvider';
import { useMonitoringLatestQuery } from '../../dataSources/useMonitoringLatestQuery';

export const useRecommendationData = () => {
  const { datasource } = useDataSource();
  const { data, isFetching } = useMonitoringLatestQuery(datasource);

  return {
    isFetching,
    data: {
      hourlyComputedUsage: data?.to_cover_hourly_recommendation ?? 0,
      resourceCostPerHour: data?.resource_cost_per_hour ?? 0,
      savings12Months: data?.annual_savings_from ?? 0,
      savings36Months: data?.three_years_savings_to ?? 0,
      annualOnDemandCost: data?.annual_on_demand_cost ?? 0,
      annualResourceCost: data?.annual_resource_cost ?? 0,
      annualOptimizedCost: data?.annual_optimized_cost_from ?? 0,
      annualSavings: data?.annual_savings_from ?? 0,
      uncoverableCost: data?.uncoverable_cost ? data.uncoverable_cost : 0,
    },
  };
};
