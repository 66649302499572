import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SimpleOption } from '@verticeone/design-system';

export type UseRollingFrequencyOptionsReturn = SimpleOption[];

const useRollingFrequencyOptions = (): UseRollingFrequencyOptionsReturn => {
  const { t } = useTranslation();

  const rollFrequencyOptions = useMemo(
    () => [
      {
        value: 'NO',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_NO'),
      },
      {
        value: 'MONTHLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_MONTHLY'),
      },
      {
        value: 'QUARTERLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_QUARTERLY'),
      },
      {
        value: 'BIANNUALLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_BIANNUALLY'),
      },
      {
        value: 'ANNUALLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_ANNUALLY'),
      },
    ],
    [t]
  );

  return rollFrequencyOptions;
};

export default useRollingFrequencyOptions;
