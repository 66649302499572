import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@verticeone/design-system';
import React from 'react';
import SkeletonForCell from '../../applications/components/SkeletonForCell';
import { SxProps } from '@mui/material';

const COLUMNS_COUNT = 3;
const ROWS_COUNT = 4;
const NotificationsPreferencesSkeleton = ({ sx, noBorder }: { sx?: SxProps; noBorder?: boolean }) => {
  return (
    <TableContainer sx={sx} noBorder={noBorder} noBorderRadius={noBorder}>
      <Table>
        <TableHead>
          <TableRow>
            {Array.from({ length: COLUMNS_COUNT }).map((_, index) => (
              <TableCell key={index}>
                <SkeletonForCell />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: ROWS_COUNT }).map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {Array.from({ length: COLUMNS_COUNT }).map((_v, colIndex) => (
                <TableCell key={colIndex}>
                  <SkeletonForCell />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NotificationsPreferencesSkeleton;
