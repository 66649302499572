import { useLazyGetContractQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { RenewalFormData } from './types';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useLazyGetVendorByIdQuery } from '@vertice/slices';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { pickBy, isNil } from 'lodash';
import { getFeDeadline } from '@vertice/core/src/modules/saas/contract/components/Deadline/utils';
import { Vendor } from '@vertice/core/src/modules/vendor/types';
import { apiVendorToSelectItem } from '@vertice/core/src/modules/saas/contract/transformations';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { FormDataProductItem } from '../types';
import { getProductsWithoutAnnualCost, getProductsWithoutLicenseAndTotalCosts } from '../utils/convertors';
import { mapContractProductsToFormDataProductItem, mapCostModelProductsToFormDataProductItem } from './utils';

// Load contract data and set as defaults for the form
export const useLoadExistingContractV2Data = (
  defaultValues: RenewalFormData,
  setValues: (values: RenewalFormData) => void
) => {
  const { accountId, isFeatureEnabled } = useAccountContext();
  const advancedCostModels = isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS);

  const { contractId } = useParams() as { contractId?: string };

  const [getContract] = useLazyGetContractQuery();
  const [getVendor] = useLazyGetVendorByIdQuery();

  useEffect(() => {
    async function setDefaultValues() {
      if (contractId === undefined) return;
      const contractData = await getContract({ accountId, contractId: contractId! }).unwrap();

      if (contractData !== undefined) {
        const contract = contractData.viewOnContract.contract;
        const departmentId = contract.classification?.departmentId ?? null;
        const annualCost = contract.parts.overview?.effectiveAnnualCost;
        const currency = contract.parts.contractual?.financial?.baseCurrency;
        const contractStage = contract.materialized?.simplifiedStage;
        const deadline = getFeDeadline(contract)?.deadlineDate;
        const signatory = contract.parts.contractual?.signatory;
        const products: FormDataProductItem[] = advancedCostModels
          ? mapCostModelProductsToFormDataProductItem(contract.parts.model?.cost?.configuration?.negotiated)
          : mapContractProductsToFormDataProductItem(contract.parts.contractual?.products);

        const vendor = contract.parts.contractual?.vendor;
        const vendorOption: Vendor | null = apiVendorToSelectItem(vendor);
        if (vendorOption && vendorOption.type === 'PREDEFINED' && !vendorOption.name) {
          const vendorData = await getVendor({ vendorId: vendorOption.id }).unwrap();
          vendorOption.name = vendorData.name;
        }

        const external = contract.workflow?.externalDocuments;

        if (vendorOption) {
          setValues({
            ...defaultValues,
            departmentId,
            totalAnnualCost: annualCost ?? null,
            contractCurrency: currency ?? DEFAULT_CURRENCY,
            vendor: vendorOption,
            existingContractId: contractData.viewOnContract.contract.record.contractId,
            existingContractStage: contractStage, // used to display old contract info in header
            existingContractDeadline: deadline ?? undefined, // used to display old contract info in header
            existingContractCurrency: currency ?? DEFAULT_CURRENCY, // used to display old contract info in header
            signingEntity: signatory?.signingEntity?.name ?? '',
            products: advancedCostModels
              ? getProductsWithoutLicenseAndTotalCosts(products)
              : getProductsWithoutAnnualCost(products),
            ...pickBy(
              {
                legalReviewRequired: external?.legalReviewRequired,
                securityReviewRequired: external?.securityReviewRequired,
                piiExpectedToBeHeld: external?.piiExpected,
                discussMultiYearDeals: external?.multiYear,
                discussCaseStudies: external?.caseStudies,
                discussPlannedGrowth: external?.plannedGrowth,
                discussCompetitors: external?.discussCompetitors,
                contactVendorDirectly: external?.contactVendorDirectly,
                signatoryName: signatory?.signer?.name,
                signatoryEmail: signatory?.signer?.email,
                vendorContactName: vendor?.vendorContact?.name,
                vendorContactEmail: vendor?.vendorContact?.email,
              },
              (value) => !isNil(value)
            ),
          });
        }
      }
    }

    void setDefaultValues();
  }, [getContract, getVendor, defaultValues, setValues, accountId, contractId, advancedCostModels]);
};
