import { FC } from 'react';

import VendorLogo from '@vertice/core/src/components/VendorLogo';
import { Stack } from '@mui/material';
import { DesignSystemSize, WorkflowServiceLogo } from '@verticeone/design-system';
import { Vendor } from '../../../../../vendor/types';

const SIZE_MAP: Record<DesignSystemSize, number> = {
  XXS: 28,
  XS: 32,
  S: 36,
  M: 40,
  L: 48,
  XL: 56,
};

const PADDING = 6;

export const RequestLogo: FC<{ vendor?: Vendor; isChild: boolean; size?: DesignSystemSize }> = ({
  vendor,
  isChild,
  size = 'M',
}) => {
  return (
    <Stack alignItems="center" justifyContent="center">
      {isChild ? (
        <WorkflowServiceLogo width={SIZE_MAP[size] - PADDING * 2} />
      ) : (
        <VendorLogo vendorId={vendor?.type === 'PREDEFINED' ? vendor.id : undefined} size={SIZE_MAP[size]} />
      )}
    </Stack>
  );
};
