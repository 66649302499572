import { Request, useTerminateRequestMutation } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import React, { FC, useState } from 'react';
import { Dialog, DialogActions, DialogHeader, DialogContent, DialogText } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Button } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { ToggleSwitch } from '@verticeone/design-system';
import { useSnackbar } from 'notistack';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';

import { COMMON_BUTTON_PROPS } from '../../../constants';

type Props = {
  request: Request;
  isOpened: boolean;
  onClose: () => void;
};

export const TerminateRequestDialog: FC<Props> = ({ request, isOpened, onClose }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL',
  });

  const { accountId } = useAccountContext();
  const [terminateRequest] = useTerminateRequestMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [isProtectionEnabled, setIsProtectionEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onClose();
    setIsProtectionEnabled(true);
  };

  const handleTerminateButtonClick = async () => {
    try {
      setIsLoading(true);
      await terminateRequest({
        accountId,
        requestId: parseRequestRef(request.ref).requestId,
      });
      enqueueSnackbar(t('SNACK_BARS.TERMINATE_REQUEST.SUCCESS'), {
        variant: 'success',
      });
      handleClose();
    } catch (e) {
      enqueueSnackbar(t('SNACK_BARS.TERMINATE_REQUEST.SUCCESS'), {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog size="M" open={isOpened} setOpen={handleClose} onClose={handleClose}>
      <DialogHeader>{t('DIALOGS.TERMINATE_REQUEST.TITLE')}</DialogHeader>
      <DialogContent>
        <DialogText variant="body-regular" align="center">
          {t('DIALOGS.TERMINATE_REQUEST.CONTENT')}
        </DialogText>
        <Stack justifyContent="center" alignItems="center" direction="row" gap={2}>
          <ToggleSwitch checked={!isProtectionEnabled} onChange={() => setIsProtectionEnabled(!isProtectionEnabled)} />
          <Text variant="caption">{t('DIALOGS.TERMINATE_REQUEST.PROTECTION')}</Text>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleTerminateButtonClick}
          disabled={isProtectionEnabled || isLoading}
          variant="solid"
          isLoading={isLoading}
          {...COMMON_BUTTON_PROPS}
        >
          {t('ACTIONS.TERMINATE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
