import { useTranslation, Trans } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Text } from '@verticeone/design-system';
import {
  useConnectAccountIntegrationMutation,
  useRevokeAccountIntegrationMutation,
  useTestAccountIntegrationMutation,
} from '@vertice/slices';
import { IntegrationAccordion, IntegrationInput } from '@vertice/components';
import { Button } from '@verticeone/design-system';
import { useSnackbar } from 'notistack';
import { STEPS, OKTA_INTEGRATION_ID } from './common';
import styles from './Step.module.scss';
import { TestIntegrationParams, WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';

type CollectStepProps = WizardStepProps & {
  accountId: string;
  apiToken: string;
  setApiToken: (token: string) => void;
  authServerURL: string;
  setAuthServerURL: (url: string) => void;
  testingInProgress: boolean;
  setTestingInProgress: (testingInProgress: boolean) => void;
  integrationParams: TestIntegrationParams;
};

export const CollectStep = ({
  setStep,
  accountId,
  apiToken,
  setApiToken,
  authServerURL,
  setAuthServerURL,
  testingInProgress,
  setTestingInProgress,
  integrationParams,
}: CollectStepProps) => {
  const { t } = useTranslation();
  const [testAccountIntegrationMutation] = useTestAccountIntegrationMutation();
  const [connectAccountIntegrationMutation] = useConnectAccountIntegrationMutation();
  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();
  const { enqueueSnackbar } = useSnackbar();

  const onBack = () => setStep(STEPS.DATA_SOURCES);
  const onTestConnection = () => {
    // validate inputs
    if (!apiToken || !authServerURL) {
      enqueueSnackbar(t('PREFERENCES.INTEGRATIONS.OKTA.USAGE.MISSING_REQUIRED_FIELDS'), {});
      return;
    }

    // revoke & connect & test
    setTestingInProgress(true);
    void revokeAccountIntegration({
      accountId,
      integrationId: OKTA_INTEGRATION_ID,
    }).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      connectAccountIntegrationMutation({
        accountId,
        integrationId: OKTA_INTEGRATION_ID,
        integrationConnectionInput: {
          parameters: {
            secret: {
              apiToken,
              authServerURL,
            },
            users: integrationParams.users === true,
          },
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          testAccountIntegrationMutation({
            accountId,
            integrationId: OKTA_INTEGRATION_ID,
            testName: 'connection',
          });
        })
        .then(() => {
          setStep(STEPS.CHECK_CONNECTION);
        });
    });
  };

  const header = (
    <Text variant="heading" size="M">
      {t('PREFERENCES.INTEGRATIONS.OKTA.USAGE.GENERATE_API_KEY_AND_SERVER')}
    </Text>
  );

  const content = (
    <Stack gap="32px">
      <Stack gap="8px">
        <IntegrationInput
          setValue={(changeEvent) => {
            setApiToken(changeEvent.target.value);
          }}
          value={apiToken}
          label={t('PREFERENCES.INTEGRATIONS.OKTA.USAGE.OKTA_API_KEY')}
          dataTestId="oktaApiKey"
        />

        <IntegrationAccordion
          summary={t('PREFERENCES.INTEGRATIONS.OKTA.USAGE.HOW_TO_FIND_OKTA_KEY')}
          details={
            <ul className={styles['bullet-list']}>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.OKTA.USAGE.OKTA_KEY_INSTRUCTION_1">
                  Navigate to the <Typography variant="body-bold-m">API Menu</Typography> within{' '}
                  <Typography variant="body-bold-m">Security</Typography>.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.OKTA.USAGE.OKTA_KEY_INSTRUCTION_2">
                  Select the <Typography variant="body-bold-m">Token</Typography> tab and create a new token.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.OKTA.USAGE.OKTA_KEY_INSTRUCTION_3">
                  Assign the name <Typography variant="body-bold-m">“Vertice”</Typography> to the token.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.OKTA.USAGE.OKTA_KEY_INSTRUCTION_4">
                  Obtain your <Typography variant="body-bold-m">API Key</Typography> and paste it into the field.
                </Trans>
              </li>
            </ul>
          }
        />
      </Stack>
      <Stack gap="8px">
        <IntegrationInput
          setValue={(changeEvent) => {
            setAuthServerURL(changeEvent.target.value);
          }}
          value={authServerURL}
          label={t('PREFERENCES.INTEGRATIONS.OKTA.USAGE.OKTA_AUTH_SERVER')}
          dataTestId="oktaAuthServer"
        />

        <IntegrationAccordion
          summary={t('PREFERENCES.INTEGRATIONS.OKTA.USAGE.HOW_TO_FIND_OKTA_AUTH_SERVER')}
          details={
            <ul className={styles['bullet-list']}>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.OKTA.USAGE.AUTH_SERVER_INSTRUCTION_1">
                  Navigate to the tab named <Typography variant="body-bold-m">Authorization Servers</Typography>.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.OKTA.USAGE.AUTH_SERVER_INSTRUCTION_2">
                  Copy the first part of the URL up until <Typography variant="body-bold-m">“.com”</Typography>
                </Trans>
              </li>
              <li>{t('PREFERENCES.INTEGRATIONS.OKTA.USAGE.AUTH_SERVER_INSTRUCTION_3')}</li>
            </ul>
          }
        />
      </Stack>
    </Stack>
  );

  return (
    <WizardStepTemplate
      header={header}
      content={content}
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button
          variant="solid"
          color="primary"
          size="S"
          isCaption
          onClick={onTestConnection}
          disabled={testingInProgress}
        >
          {t('PREFERENCES.INTEGRATIONS.TEST_CONNECTION')}
        </Button>,
      ]}
    />
  );
};
