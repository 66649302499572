import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IntegrationSuccess } from '@vertice/assets';
import { Button } from '@verticeone/design-system';
import { AWS_STEPS, AWSStepProps } from '../AWSSetupWizard';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import IntegrationStatus from 'pages/Preferences/Integrations/components/IntegrationStatus';

const AWSVerified = ({ setStep, backValue }: AWSStepProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onBack = () => setStep(backValue || AWS_STEPS.COST_AND_USAGE);
  const onFinish = () => navigate(-1);

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus
          icon={<IntegrationSuccess />}
          title={t('PREFERENCES.INTEGRATIONS.AWS.VERIFIED.TITLE')}
          text={t('PREFERENCES.INTEGRATIONS.AWS.VERIFIED.DESC')}
        />
      }
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption onClick={onFinish}>
          {t('COMMON.FINISH')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};

export default AWSVerified;
