import { createContext, PropsWithChildren, useContext } from 'react';
import type { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import type { SerializedError } from '@reduxjs/toolkit';
import type { InstanceData } from './InstanceProvider';
import type { ShoppingCartItemType } from './ShoppingCartProvider';
import type { OfferingsData } from './OfferingsProvider';
import { type SpListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

export type GetOfferingsHandler = (filter: Record<string, any>) => Promise<{
  isFetching: boolean;
  data?: SpListOfferingsQuery;
  error?: ErrorResponse | SerializedError;
}>;

export type OfferingsParserHandler<Item extends InstanceData> = (
  data: SpListOfferingsQuery | undefined,
  item: Item,
  cartItems?: ShoppingCartItemType[] | null,
  effectiveDate?: string
) => Array<OfferingsData>;

type GetOfferingsHandlerContextData = {
  getOfferings: GetOfferingsHandler;
  offeringsParser: OfferingsParserHandler<InstanceData>;
};

type GetOfferingsHandlerProviderProps = PropsWithChildren & {
  getOfferings: GetOfferingsHandler;
  offeringsParser: OfferingsParserHandler<InstanceData>;
};

const GetOfferingsHandlerContext = createContext<GetOfferingsHandlerContextData>({} as GetOfferingsHandlerContextData);

const GetOfferingsHandlerProvider = ({ children, ...value }: GetOfferingsHandlerProviderProps) => (
  <GetOfferingsHandlerContext.Provider value={value}>{children}</GetOfferingsHandlerContext.Provider>
);

export const useGetOfferingsHandler = () => useContext(GetOfferingsHandlerContext);

export default GetOfferingsHandlerProvider;
