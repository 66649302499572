import React, { ReactNode } from 'react';
import { ShortcutColor } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { ChipButton } from '@verticeone/design-system';

type LargeValueProps = {
  id?: string;
  primaryColor?: ShortcutColor;
  primaryValue: ReactNode;
  primaryTestId?: string;
  chipValue?: ReactNode;
  chipTestId?: string;
  secondaryValue?: ReactNode;
  secondaryTestId?: string;
  size?: 'S' | 'M';
};

const LargeValue = ({
  id,
  primaryColor = 'text1',
  primaryTestId,
  primaryValue,
  chipTestId,
  chipValue,
  secondaryValue,
  secondaryTestId,
  size = 'M',
}: LargeValueProps) => (
  <Stack gap={1}>
    <Stack direction="row" alignItems="center" gap={2}>
      <Text size={size} variant="heading" color={primaryColor} id={id} testId={primaryTestId}>
        {primaryValue ?? '-'}
      </Text>
      {chipValue && (
        <ChipButton
          size={size === 'M' ? 'S' : 'XS'}
          isActive={false}
          color="success"
          variant="ghost"
          testId={chipTestId}
          sx={{ flexShrink: 0 }}
        >
          {chipValue}
        </ChipButton>
      )}
    </Stack>
    {secondaryValue && (
      <Text size={size} variant="body-regular" color="text3" testId={secondaryTestId}>
        {secondaryValue}
      </Text>
    )}
  </Stack>
);

export default LargeValue;
