import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { Link } from 'react-router-dom';
import React from 'react';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { useTranslation } from 'react-i18next';
import { useContractContext } from '../../ContractContext';

export type NavigationCellProps = {
  contract: Contract;
};

const NavigationCell = ({ contract }: NavigationCellProps) => {
  const { t } = useTranslation();
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const currentContractId = contractContext.fetchedContract.contract.record.contractId;

  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();
  const getContractPath = (contractId: string) => generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, { contractId });

  return contract.record.contractId === currentContractId ? (
    <Text variant="body-bold" size="S" color="text3" sx={{ whiteSpace: 'break-spaces', textAlign: 'center' }}>
      {t('ENTITIES.CONTRACT.PLACEHOLDERS.CURRENT_CONTRACT')}
    </Text>
  ) : (
    <Button component={Link} color="primary" variant="ghost" to={getContractPath(contract.record.contractId)}>
      {t('ENTITIES.CONTRACT.ACTIONS.VIEW')}
    </Button>
  );
};

export default NavigationCell;
