import React from 'react';
import { useController } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@verticeone/design-system';
import { FormControlLabel } from '@verticeone/design-system';

export type ScopeReductionOverrideCheckboxFieldProps = React.ComponentProps<typeof Checkbox> & {
  name: string;
  required?: boolean;
};

export const ScopeReductionOverrideCheckboxField = ({
  required,
  name,
  ...otherProps
}: ScopeReductionOverrideCheckboxFieldProps) => {
  const { t } = useTranslation();
  const {
    field: { ref, value, onChange, ...field },
  } = useController({ name, rules: { required } });

  return (
    <FormControlLabel
      checked={value ?? false}
      size="XXS"
      control={
        <Checkbox
          inputRef={ref}
          size="XXS"
          {...field}
          onChange={async (event) => {
            onChange(event.target.checked);
          }}
          {...otherProps}
        />
      }
      label={
        <Stack direction="column">
          <Text variant="caption" size="XS">
            {t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION_SAVINGS_OVERRIDE')}
          </Text>
        </Stack>
      }
    />
  );
};
