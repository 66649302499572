import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress, Stack, useTheme } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GetJiraWebhookConfigApiResponse, useGetJiraWebhookConfigQuery } from '@vertice/slices';
import { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { Button, Text, TextFieldCaption } from '@verticeone/design-system';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { createTypedFormEntry } from '../../../../../forms/fields/FormEntry';
import FormSelectField2 from '../../../../../forms/fields/FormSelectField2';
import { DEFINITION_VERSION } from '../../../../definitions/constants';
import { isIOMappingConfiguration, updateTaskIOMapping } from '../../../../definitions/taskDefinition';
import { FieldMapping, TaskIOMappingConfiguration } from '../../../../definitionsTypes';
import { EditServiceTaskForm } from '../../forms/EditServiceTask/EditServiceTaskForm/EditServiceTaskForm';
import { PropertyOrigin } from '../../types';
import { FooterLink } from '../JiraCommon/FooterLink';
import { MissingSetup } from '../JiraCommon/MissingSetup';
import { JiraFieldOption, WorkflowVariables } from '../JiraCommon/types';
import { applyPrefixToVariables, TICKET_ID_NAME } from '../JiraCommon/utils';
import { VariableMapper, VariableMapperProps } from '../JiraCommon/VariableMapper/VariableMapper';
import { VariableMapperLoader } from '../JiraCommon/VariableMapperLoader';
import { EditServiceTaskBase } from '../types';
import { JiraSyncTaskFormData, jiraSyncTaskFormSchema } from './formSchema';
import { getAvailableCreateJiraTasks } from './utils';

const JiraSyncFormEntry = createTypedFormEntry<JiraSyncTaskFormData>();

type JiraSyncFormProps = Pick<EditServiceTaskBase, 'task' | 'saveTask' | 'onDirty' | 'close'> & {
  taskService: ServiceCatalogResource;
  workflowVariables: WorkflowVariables;
  defaultValues: JiraSyncTaskFormData;
  onTaskChange: (taskId?: string) => void;
  variableMapperProps: Omit<
    VariableMapperProps<JiraSyncTaskFormData>,
    'formFieldName' | 'requiredToFieldsCount' | 'otherVariablesTitle'
  >;
  isLoadingIntegration: boolean;
  isFetchingIssueTypeFields: boolean;
  hasReferencedIssueType: boolean; // To show section after referenced task is selected (and through it selected issue type)
  completeCriteriaProps: {
    isLoading: boolean;
    availableStatuses: JiraFieldOption[];
  };
  noReferenceIssuePlaceholder: string;
  synchronisationWebhookData?: GetJiraWebhookConfigApiResponse;
  syncWebhookQuery: ReturnType<typeof useGetJiraWebhookConfigQuery>;
};

export const JiraSyncForm: FC<JiraSyncFormProps> = ({
  task,
  defaultValues,
  saveTask,
  onDirty,
  close,
  onTaskChange,
  variableMapperProps,
  isLoadingIntegration,
  isFetchingIssueTypeFields,
  workflowVariables,
  hasReferencedIssueType,
  completeCriteriaProps,
  syncWebhookQuery,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  const { data: syncWebhookData, isLoading: syncWebhookIsLoading } = syncWebhookQuery;

  const allVariables = useMemo(() => [...workflowVariables.global, ...workflowVariables.request], [workflowVariables]);

  const { createJiraTasks, createJiraTicketsByTasks } = getAvailableCreateJiraTasks(allVariables);

  /*** Setup form ***/
  const formHandlers = useForm<JiraSyncTaskFormData>({
    resolver: zodResolver(jiraSyncTaskFormSchema),
    defaultValues: defaultValues,
  });

  const taskId = formHandlers.watch('taskId');
  useEffect(() => onTaskChange(taskId), [taskId, onTaskChange]);

  const onSubmit = (data: JiraSyncTaskFormData) => {
    if (!task) return;

    const inputFields = applyPrefixToVariables(data.variables, 'to', 'jiraFields.').map((mapperVariable) => {
      const inputField: FieldMapping = {
        name: mapperVariable.from,
        value: mapperVariable.to,
        mappingType: 'JMESPathExpression',
      };
      return inputField;
    });

    if (data.taskId) {
      inputFields.push({
        name: TICKET_ID_NAME,
        value: createJiraTicketsByTasks[data.taskId].id,
        mappingType: 'JMESPathExpression',
      });
    }

    if (data.filterStatus) {
      inputFields.push({
        name: 'filter.status',
        value: `\`${JSON.stringify(data.filterStatus)}\``,
        mappingType: 'JMESPathExpression',
      });
    }

    // Re-save, if already existed, or create some ticketId for first time
    inputFields.push();

    const ioMappingConfigurations = (task.task.configurations ?? []).filter((v) =>
      isIOMappingConfiguration(v)
    ) as TaskIOMappingConfiguration[];
    let outputFields = ioMappingConfigurations.length ? ioMappingConfigurations[0].mapping.outputFields : [];

    const newTask = updateTaskIOMapping(task, {
      name: data.name,
      description: data.description,
      ioMappingConfigurations: [
        {
          kind: 'ProcessEngine:TaskIOMapping',
          version: DEFINITION_VERSION,
          mapping: {
            inputFields,
            outputFields,
          },
        },
      ],
    });

    saveTask(newTask);
  };

  return (
    <Stack height="100%">
      <FormProvider {...formHandlers}>
        <form style={{ height: '100%' }} onSubmit={formHandlers.handleSubmit(onSubmit)}>
          <Stack justifyContent="space-between" height="100%">
            <Stack gap={4} px={6} height="100%">
              <EditServiceTaskForm onDirty={onDirty} />

              {syncWebhookIsLoading ? (
                <Stack alignItems="center" mt={8}>
                  <CircularProgress />
                </Stack>
              ) : !syncWebhookData?.isValid ? (
                <MissingSetup
                  sx={{ mt: 4 }}
                  title={t('INTELLIGENT_WORKFLOWS.JIRA.SYNC_NOT_SETUP.TITLE')}
                  description={t('INTELLIGENT_WORKFLOWS.JIRA.SYNC_NOT_SETUP.DESCRIPTION')}
                  setupLink={generatePath(routes.PREFERENCES.TAB.JIRA.PATH, { activeTab: 'integrations' })}
                />
              ) : (
                <>
                  <Stack gap={2}>
                    <Stack>
                      <TextFieldCaption
                        htmlFor="jira-create-task-select"
                        label={t('INTELLIGENT_WORKFLOWS.JIRA.SELECTED_TASK.LABEL')}
                        size="XS"
                      />
                      <Text variant="body-regular" size="S" color="text3">
                        {t('INTELLIGENT_WORKFLOWS.JIRA.SELECTED_TASK.INFO')}
                      </Text>
                    </Stack>
                    <JiraSyncFormEntry
                      width={4}
                      name={'taskId'}
                      component={FormSelectField2}
                      componentProps={{
                        id: 'jira-create-task-select',
                        size: 'S',
                        placeholder: t('INTELLIGENT_WORKFLOWS.JIRA.SELECTED_TASK.PLACEHOLDER'),
                        getOptionValue: (option: PropertyOrigin) => option.id,
                        getOptionLabel: (option: PropertyOrigin) => option.label,
                        options: createJiraTasks,
                        boxValue: (value: PropertyOrigin['id']) =>
                          value ? createJiraTasks.find((a) => a.id === value) : undefined,
                        unboxValue: (selectValue: PropertyOrigin) => selectValue?.id,
                      }}
                    />
                  </Stack>

                  {!!taskId &&
                    (!hasReferencedIssueType ? (
                      <Text variant="body-regular" size="XS" color="inactive" sx={{ mt: 8 }}>
                        {t('INTELLIGENT_WORKFLOWS.JIRA.NO_ISSUE_TYPE')}
                      </Text>
                    ) : (
                      <>
                        {isLoadingIntegration || isFetchingIssueTypeFields ? (
                          <VariableMapperLoader />
                        ) : (
                          <VariableMapper<JiraSyncTaskFormData>
                            formFieldName="variables"
                            requiredToFieldsCount={0}
                            otherVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER.JIRA')}
                            {...variableMapperProps}
                          />
                        )}

                        <Stack gap={2}>
                          <Stack>
                            <TextFieldCaption
                              htmlFor="jira-create-task-select"
                              label={t('INTELLIGENT_WORKFLOWS.JIRA.COMPLETE_CRITERIA.LABEL')}
                              size="XS"
                            />
                            <Text variant="body-regular" size="S" color="text3">
                              {t('INTELLIGENT_WORKFLOWS.JIRA.COMPLETE_CRITERIA.INFO')}
                            </Text>
                          </Stack>
                          <JiraSyncFormEntry
                            width={4}
                            name={'filterStatus'}
                            component={FormSelectField2}
                            componentProps={{
                              id: 'jira-status-select',
                              size: 'S',
                              isMulti: true,
                              isLoading: completeCriteriaProps.isLoading,
                              placeholder: t('INTELLIGENT_WORKFLOWS.JIRA.COMPLETE_CRITERIA.PLACEHOLDER'),
                              getOptionValue: (option: JiraFieldOption) => option.id,
                              getOptionLabel: (option: JiraFieldOption) => option.name,
                              options: completeCriteriaProps.availableStatuses,
                              boxValue: (value: JiraFieldOption['id'][]) =>
                                value
                                  ? completeCriteriaProps.availableStatuses?.filter((a) => value.includes(a.id))
                                  : undefined,
                              unboxValue: (selectValue: JiraFieldOption[]) => selectValue?.map((item) => item.id),
                            }}
                          />
                        </Stack>
                      </>
                    ))}
                </>
              )}
            </Stack>
            <FooterLink />
            <Stack direction="row" gap={1} p={6} borderTop={`1px solid ${palette.core.color3}`}>
              <Button variant="outline" size="S" color="secondary" fullWidth onClick={close}>
                {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.DISCARD')}
              </Button>
              <Button variant="solid" size="S" color="secondary" fullWidth type="submit">
                {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.APPLY')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};
