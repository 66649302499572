import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Text } from '@verticeone/design-system';

import { DataGridColDef } from '@verticeone/design-system';
import { WorkflowVersionRow } from './types';
import { dateTimeFormatterOptions, useFormatDate } from '@verticeone/utils/formatting';
import { ActivateButton } from './components/ActivateButton';
import { EditButton } from './components/EditButton';
import { DraftActionsButton } from './components/DraftActionsButton';
import { useLocalizeVersionName } from './utils';

export const useDraftVersionColumns = (): DataGridColDef<WorkflowVersionRow>[] => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const localizeVersionName = useLocalizeVersionName();

  return useMemo(() => {
    const columns: DataGridColDef<WorkflowVersionRow>[] = [
      {
        field: 'workflowName',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.NAME_COLUMN'),
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Stack gap={2} direction="row">
            <Text variant="body-bold">{localizeVersionName(row.versionName)}</Text>
          </Stack>
        ),
        valueGetter: ({ row }) => row.updatedAt, // value for sorting
      },
      {
        field: 'createdAt',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.CREATED_AT_COLUMN'),
        renderCell: ({ row }) => formatDate(row.createdAt, { formatterOptions: dateTimeFormatterOptions }),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'updatedAt',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.LAST_UPDATED_COLUMN'),
        renderCell: ({ row }) => formatDate(row.updatedAt, { formatterOptions: dateTimeFormatterOptions }),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'actions',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.ACTIONS_COLUMN'),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Stack direction="row" gap={1}>
            <ActivateButton row={row} />
            <EditButton row={row} />
            <DraftActionsButton row={row} />
          </Stack>
        ),
        renderSkeletonCell: () => null,
      },
    ];
    return columns;
  }, [localizeVersionName, formatDate, t]);
};
