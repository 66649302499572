import { Stack } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { get } from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';

import { usePredefinedFormDef } from './usePredefinedFormDef';
import { useTaskContext } from '../TaskContext';
import { PagesProvider } from './pages/PagesContext';

type TaskDrawerFormResultProps = {
  formConfig: { [p: string]: any };
};

export const TaskPredefinedFormResult: FC<TaskDrawerFormResultProps> = ({ formConfig }) => {
  const formDef = usePredefinedFormDef(formConfig);
  const taskContextData = useTaskContext();

  const getTaskDataValue = useCallback(
    (path: string) =>
      get(taskContextData.taskOverview?.task?.result, path) ?? get(taskContextData.taskOverview?.task?.input, path),
    [taskContextData.taskOverview?.task?.result, taskContextData.taskOverview?.task?.input]
  );

  const formMethods = useForm({
    defaultValues: formDef?.getDefaultValues?.(getTaskDataValue, taskContextData),
  });

  return (
    <FormProvider {...formMethods}>
      <PagesProvider>
        <Stack width="100%">
          <ErrorBoundary FallbackComponent={() => null}>{formDef && <formDef.component />}</ErrorBoundary>
        </Stack>
      </PagesProvider>
    </FormProvider>
  );
};
