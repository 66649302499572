import { Text } from '@verticeone/design-system';
import { JournalItemContentComponent } from './types';
import { Card } from '@verticeone/design-system';
import { Trans } from 'react-i18next';
import React from 'react';

type DeltaChange = {
  from: string;
  to: string;
};

const getDelta = (delta: any[]): DeltaChange | undefined => {
  for (const deltaItem of delta) {
    const [operation, target, changes] = deltaItem;
    if (operation === 'change' && target === 'Name') {
      const [from, to] = changes;
      return {
        from,
        to,
      };
    }
  }
};

export const RenamedRequestJournalItem: JournalItemContentComponent = ({ item }) => {
  if (item.delta === undefined) {
    return;
  }
  const delta = getDelta(item.delta);
  return (
    <Card padding={4}>
      <Text variant="body-regular" color="text1">
        <Trans
          i18nKey={'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.JOURNAL.RENAME_MESSAGE'}
          components={{ bold: <b /> }}
          values={{ currentName: delta?.from, newName: delta?.to }}
        />
      </Text>
    </Card>
  );
};
