import { FC, PropsWithChildren, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Divider } from '@verticeone/design-system';

import type { EditUserTaskFormData } from './schema';
import type { ProcessDefinition, VerticeFormConfiguration } from '../../../../definitionsTypes';
import type { NodeId, Property } from '../../types';
import { TaskProperties } from './TaskProperties';
import { TaskFields } from './TaskFields';
import { TaskAssignee } from './TaskAssignee/TaskAssignee';
import { DynamicFormBuilder } from '../../../../../forms/dynamicForms/DynamicFormBuilder';
import { isApprovalFormUrn, isGenericFormUrn } from './utils';
import { GENERIC_FORM_FIELD_LIMIT, INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { useVariablesAvailableInNode } from '../../useVariablesAvailableInNode';
import { VariableSelectorFormEntry } from '../GenericForm/VariableSelectorFormEntry';

type EditUserTaskFormProps = {
  formConfiguration?: VerticeFormConfiguration;
  onDirty: () => void;
  onAddUser: () => void;
  taskName?: string;
  allowContractOwnerAssignment?: boolean;
  nodeId: NodeId;
  processDefinition: ProcessDefinition;
  workflowServiceRef?: string;
  isAdvancedWorkflowDefinitionEditModeEnabled?: boolean;
};

const CustomFormBuilder = (
  props: PropsWithChildren & { requestProperties: Property[]; globalUDFProperties: Property[] }
) => {
  const { children, requestProperties, globalUDFProperties } = props;
  return (
    <Stack gap={4}>
      {children}
      <VariableSelectorFormEntry requestProperties={requestProperties} globalUDFProperties={globalUDFProperties} />
    </Stack>
  );
};

export const EditUserTaskForm: FC<EditUserTaskFormProps> = ({
  formConfiguration,
  taskName,
  onDirty,
  onAddUser,
  allowContractOwnerAssignment,
  nodeId,
  processDefinition,
  workflowServiceRef,
  isAdvancedWorkflowDefinitionEditModeEnabled = false,
}) => {
  const { formState } = useFormContext<EditUserTaskFormData>();

  useEffect(() => {
    if (formState.isDirty) {
      onDirty();
    }
  }, [formState.isDirty, onDirty]);

  const isSimpleApproval = formConfiguration && isApprovalFormUrn(formConfiguration.formUrn);
  const isGenericForm = formConfiguration && isGenericFormUrn(formConfiguration.formUrn);

  const { requestProperties, globalUDFProperties } = useVariablesAvailableInNode({
    nodeId,
    processDefinition,
    workflowServiceRef,
  });

  return (
    <Stack gap={12} direction={'column'} width={'100%'}>
      <TaskProperties />
      <TaskAssignee
        processDefinition={processDefinition}
        onAddUser={onAddUser}
        nodeId={nodeId}
        workflowServiceRef={workflowServiceRef}
        allowContractOwnerAssignment={allowContractOwnerAssignment}
        isConditionalAssignmentEnabled={isAdvancedWorkflowDefinitionEditModeEnabled}
      />
      {isSimpleApproval && <TaskFields taskName={taskName} />}
      {isGenericForm && (
        <Stack gap={8}>
          <Divider />
          <DynamicFormBuilder
            maxFieldCount={GENERIC_FORM_FIELD_LIMIT}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            fieldPath="genericFormFields"
            customFormBuilder={({ children }) => (
              <CustomFormBuilder
                children={children}
                requestProperties={requestProperties}
                globalUDFProperties={globalUDFProperties}
              />
            )}
          />
        </Stack>
      )}
    </Stack>
  );
};
