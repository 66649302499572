import { keyBy, range, sortBy } from 'lodash';
import { format, subDays } from 'date-fns';
import { DATE_FORMAT } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

export const generatePreviousDaysData = <DataType extends { date: string }>(
  data: DataType[],
  startDate: Date,
  period: number,
  defaultData: Omit<DataType, 'date'>
): DataType[] => {
  const dataByDate = keyBy(data, (p) => format(new Date(p.date), DATE_FORMAT));

  return sortBy(
    range(period).map((i) => {
      const dayStartStr = format(subDays(startDate, i), DATE_FORMAT);

      return (
        dataByDate[dayStartStr] ?? {
          date: format(new Date(dayStartStr), DATE_FORMAT),
          ...defaultData,
        }
      );
    }),
    ({ date }) => new Date(date)
  );
};
