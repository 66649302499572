import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid-pro';

import { PageHeader } from '@verticeone/design-system';
import { IntelligentWorkflowsLayout } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';

import { FilterPanel } from './components/filter/FilterPanel';
import { useRequestListStatusFilters } from './components/filter/useRequestListStatusFilters';
import { CreateRequestDialog } from './components/CreateRequestDialog/CreateRequestDialog';
import { useGridRequestDetails } from './useRequestDetails';
import { useRequestsColumns } from './useRequestsColumns';
import { useRequestFilter } from './components/filter/useRequestFilter';
import { RequestGrid } from './RequestsGrid';
import { UsersContextProvider } from '../../../../contexts/UsersContext';

const BaseRequestsListPage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get('search') || '';
  const { isUserRestrictedUser } = useLoggedUserAccountRoles();

  const myRequestsOnly =
    searchParams.get('myRequestsOnly') === 'true' || // If user is admin or power user, they should by defeault see all requests
    (searchParams.get('myRequestsOnly') === null && isUserRestrictedUser); // If user is normal user, they should see their requests only by default

  const currentPage = parseInt(searchParams.get('page') || '0');
  const pageSize = parseInt(searchParams.get('pageSize') || '25');
  const paginationModel = useMemo(() => ({ pageSize, page: currentPage }), [currentPage, pageSize]);

  const { statusFilters, activeStatusFilter, setActiveStatusFilter } = useRequestListStatusFilters();

  const columns = useRequestsColumns();
  const { requests, requestIds, isLoadingRequests, onGridStateChanged } = useGridRequestDetails();
  const { filteredRequests, searchQueryRequests } = useRequestFilter(requests, {
    activeStatusFilter,
    searchQuery,
    myRequestsOnly,
  });

  const [createRequestDialogOpen, setCreateRequestDialogOpen] = useState(false);

  const onSearchQueryChange = useCallback(
    (query: string) => {
      setSearchParams((oldParams) => {
        oldParams.set('search', query);
        oldParams.set('page', '0');
        return oldParams;
      });
    },
    [setSearchParams]
  );

  const onMyRequestsOnlyChange = useCallback(
    (value: boolean) => {
      setSearchParams((oldParams) => {
        oldParams.set('myRequestsOnly', value.toString());
        oldParams.set('page', '0');
        return oldParams;
      });
    },
    [setSearchParams]
  );

  const setPaginationModel = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setSearchParams((oldParams) => {
        oldParams.set('page', newPaginationModel.page.toString());
        oldParams.set('pageSize', newPaginationModel.pageSize.toString());
        return oldParams;
      });
    },
    [setSearchParams]
  );

  return (
    <IntelligentWorkflowsLayout
      actions={
        <PageHeader.PrimaryAction onClick={() => setCreateRequestDialogOpen(true)}>
          {t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.CREATE_REQUEST_BUTTON')}
        </PageHeader.PrimaryAction>
      }
    >
      <Stack gap={8}>
        <FilterPanel
          statusFilters={statusFilters}
          activeStatusFilter={activeStatusFilter}
          onStatusFilterClick={setActiveStatusFilter}
          searchQuery={searchQuery}
          onSearchQueryChange={onSearchQueryChange}
          myRequestsOnly={myRequestsOnly}
          onMyRequestsOnlyChange={onMyRequestsOnlyChange}
          requests={filteredRequests}
          isLoading={isLoadingRequests}
        />
        <RequestGrid
          requests={searchQueryRequests}
          requestIds={requestIds}
          columns={columns}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          onStateChange={onGridStateChanged}
          pageSize={pageSize}
          page={currentPage}
          loading={isLoadingRequests}
          defaultSortModel={[{ field: 'keyDate', sort: 'asc' }]}
        />
      </Stack>
      <CreateRequestDialog
        open={createRequestDialogOpen}
        setOpen={(open) => setCreateRequestDialogOpen(open)}
        key={createRequestDialogOpen ? 'open' : 'closed'}
      />
    </IntelligentWorkflowsLayout>
  );
};

export const RequestsListPage = () => (
  <UsersContextProvider>
    <BaseRequestsListPage />
  </UsersContextProvider>
);
