/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
  BigInt: any;
  Double: any;
  Long: any;
};

export type AccountSettings = {
  __typename?: 'AccountSettings';
  excludedFromCco?: Maybe<Scalars['Boolean']>;
};

export type AccountSettingsParameters = {
  excludedFromCco?: InputMaybe<Scalars['Boolean']>;
};

export type AccountValue = {
  __typename?: 'AccountValue';
  id: Scalars['String'];
  name: Scalars['String'];
  probeErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
  probeStatus?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<AccountSettings>;
  tags?: Maybe<Array<KeyValueType>>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Availability = {
  __typename?: 'Availability';
  frequency?: Maybe<Scalars['String']>;
  initialCutOff?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
};

export type AwsAccountParameters = {
  accountSettings: AccountSettingsParameters;
  awsAccountId: Scalars['String'];
};

export type BaseQueryParameters = {
  accountId: Scalars['String'];
};

export type BinnedTestResult = {
  __typename?: 'BinnedTestResult';
  bin?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  cost: Scalars['Float'];
  summary: TestResultSummary;
  usage?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['String']>;
};

export type BooleanTestResult = {
  __typename?: 'BooleanTestResult';
  code: Scalars['String'];
  cost: Scalars['Float'];
  summary: TestResultSummary;
  usage?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['String']>;
};

export type CsvResult = {
  __typename?: 'CSVResult';
  result: Scalars['String'];
};

export type CacheData = {
  __typename?: 'CacheData';
  compressed?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  request?: Maybe<Scalars['String']>;
  retentionDate?: Maybe<Scalars['AWSTimestamp']>;
};

export type CacheQueryResult = CacheResult | ErroredQueryResult;

export type CacheResult = {
  __typename?: 'CacheResult';
  items?: Maybe<Array<Maybe<CacheData>>>;
};

export type CheckDataPoint = {
  __typename?: 'CheckDataPoint';
  time: Scalars['String'];
  values: Array<CheckValues>;
};

export type CheckQueryParameters = {
  accountId: Scalars['String'];
  code: Scalars['String'];
  startDate?: InputMaybe<Scalars['AWSDate']>;
};

export type CheckQueryResult = CheckResult | DeferredQueryResult | ErroredQueryResult;

export type CheckResult = {
  __typename?: 'CheckResult';
  hashcode: Scalars['String'];
  result?: Maybe<Array<Maybe<CheckDataPoint>>>;
};

export type CheckSpecification = {
  accountId: Scalars['String'];
  checkCode?: InputMaybe<Scalars['String']>;
  dataSourceName?: InputMaybe<Scalars['String']>;
  invokeEvaluation?: InputMaybe<Scalars['Boolean']>;
};

export type CheckValues = {
  __typename?: 'CheckValues';
  checkResult: MonitoringValueCheckResult;
  code: Scalars['String'];
  dateEnd: Scalars['String'];
  dateStart: Scalars['String'];
};

export enum ContractStatus {
  Active = 'ACTIVE',
  All = 'ALL',
  Inactive = 'INACTIVE',
}

export type CostAllocationSpan = {
  __typename?: 'CostAllocationSpan';
  commitment: Scalars['Float'];
  discount: Scalars['Float'];
  endDate: Scalars['AWSDate'];
  name: Scalars['ID'];
  spanLength?: Maybe<Scalars['Int']>;
  startDate: Scalars['AWSDate'];
};

export type CostAllocationSpanInput = {
  commitment: Scalars['Float'];
  discount: Scalars['Float'];
  endDate: Scalars['AWSDate'];
  name: Scalars['ID'];
  spanLength?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['AWSDate'];
};

export type CostModel = {
  __typename?: 'CostModel';
  costAllocationSpans: Array<CostAllocationSpan>;
  endDate: Scalars['AWSDate'];
  marketplaceSpendCap?: Maybe<Scalars['Float']>;
  startDate: Scalars['AWSDate'];
};

export type CostModelContainer = {
  __typename?: 'CostModelContainer';
  costModel?: Maybe<CostModel>;
  id: Scalars['ID'];
};

export type CostModelInput = {
  costAllocationSpans: Array<CostAllocationSpanInput>;
  endDate: Scalars['AWSDate'];
  marketplaceSpendCap?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['AWSDate'];
};

export type CostUsageQueryParameters = {
  accountId: Scalars['String'];
  dimensions?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['AWSDate']>;
  precision: ForecastPrecisionType;
  recordType?: InputMaybe<Array<Scalars['String']>>;
  recordTypeExclude?: InputMaybe<Scalars['Boolean']>;
  refresh?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['AWSDate'];
  unit: ForecastUnit;
};

export type DataSource = {
  __typename?: 'DataSource';
  availability: DataSourceAvailability;
  code: Scalars['String'];
  intervals?: Maybe<Array<Maybe<Interval>>>;
  name: Scalars['String'];
  reconciledAt?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tasks?: Maybe<Array<Maybe<DataSourceTask>>>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  version: Scalars['String'];
};

export type DataSourceAvailability = {
  __typename?: 'DataSourceAvailability';
  frequency?: Maybe<Scalars['String']>;
  granularity?: Maybe<Scalars['String']>;
  initialCutoff?: Maybe<Scalars['String']>;
  prerequisites?: Maybe<Array<Maybe<DataSourcePrerequisite>>>;
};

export type DataSourceCondition = {
  __typename?: 'DataSourceCondition';
  code?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
};

export type DataSourcePrerequisite = {
  __typename?: 'DataSourcePrerequisite';
  condition?: Maybe<Scalars['String']>;
  datasource?: Maybe<DataSourceCondition>;
  type?: Maybe<Scalars['String']>;
};

export type DataSourceQueryParameters = {
  accountId: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
};

export type DataSourceQueryResult = DataSourceResult | ErroredQueryResult;

export type DataSourceResult = {
  __typename?: 'DataSourceResult';
  items?: Maybe<Array<DataSource>>;
};

export type DataSourceSpecification = {
  accountId: Scalars['String'];
  dataSourceName: Scalars['String'];
  includeDependent?: InputMaybe<Scalars['Boolean']>;
  reconcile?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['String'];
};

export type DataSourceTask = {
  __typename?: 'DataSourceTask';
  createdAt: Scalars['String'];
  errorData?: Maybe<ErrorData>;
  id: Scalars['String'];
  jobId?: Maybe<Scalars['String']>;
  range?: Maybe<Interval>;
  rescheduledAt?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
};

export type DataTable = {
  __typename?: 'DataTable';
  availableColumns?: Maybe<Array<Scalars['String']>>;
  columns: Array<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  dataTypes: Array<Scalars['String']>;
};

export type DataTableResult = {
  __typename?: 'DataTableResult';
  link?: Maybe<Scalars['String']>;
  table?: Maybe<DataTable>;
};

export type DataTableResultTableArgs = {
  columns: Array<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Order>;
  orderColumns?: InputMaybe<Array<Scalars['String']>>;
};

export type DeferredQueryResult = {
  __typename?: 'DeferredQueryResult';
  hashcode: Scalars['String'];
};

export type DeletedContractResult = EdpContractMemento | ErroredQueryResult;

export type EdpContract = {
  __typename?: 'EDPContract';
  costModel: CostModel;
  identifier: Scalars['ID'];
};

export type EdpContractFacade = {
  __typename?: 'EDPContractFacade';
  contractId: Scalars['ID'];
  costModelContainer: CostModelContainer;
};

export type EdpContractInput = {
  accountId: Scalars['ID'];
  costModel: CostModelInput;
};

export type EdpContractListing = {
  __typename?: 'EDPContractListing';
  items?: Maybe<Array<Maybe<EdpContractFacade>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type EdpContractListingResult = EdpContractListing | ErroredQueryResult;

export type EdpContractMemento = {
  __typename?: 'EDPContractMemento';
  identifier: Scalars['ID'];
};

export type EdpContractResult = EdpContract | EdpValidationError | ErroredQueryResult;

export type EdpContractsQueryParams = {
  accountId: Scalars['String'];
  contractStatus?: InputMaybe<ContractStatus>;
};

export type EdpValidationError = {
  __typename?: 'EDPValidationError';
  code: Scalars['String'];
  context?: Maybe<Scalars['AWSJSON']>;
  fieldName: Scalars['String'];
};

export type ErrorData = {
  __typename?: 'ErrorData';
  cause?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  maxAttempts?: Maybe<Scalars['Int']>;
  remainingAttempts?: Maybe<Scalars['Int']>;
  retryable?: Maybe<Scalars['Boolean']>;
};

export type ErroredQueryResult = {
  __typename?: 'ErroredQueryResult';
  error: Scalars['String'];
};

export type EventDetails = {
  __typename?: 'EventDetails';
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export enum ExecutionType {
  Immediate = 'IMMEDIATE',
  Scheduled = 'SCHEDULED',
}

export type ExistingEdpContractInput = {
  accountId: Scalars['ID'];
  costModel: CostModelInput;
  identifier: Scalars['ID'];
};

export type ExistingEdpContractMemento = {
  accountId: Scalars['ID'];
  identifier: Scalars['ID'];
};

export type ForecastDataPoint = {
  __typename?: 'ForecastDataPoint';
  time: Scalars['String'];
  values: Array<ForecastValue>;
};

export enum ForecastPrecisionType {
  Day = 'DAY',
  Month = 'MONTH',
}

export type ForecastQueryParameters = {
  accountId: Scalars['String'];
  precision: ForecastPrecisionType;
  predictionIntervalLevel?: InputMaybe<Scalars['Int']>;
  refresh?: InputMaybe<Scalars['Boolean']>;
  unit: ForecastUnit;
};

export type ForecastQueryResult = ErroredQueryResult | ForecastResult;

export type ForecastResult = {
  __typename?: 'ForecastResult';
  hashcode: Scalars['String'];
  result?: Maybe<Array<Maybe<ForecastDataPoint>>>;
};

export enum ForecastUnit {
  AmortizedCost = 'AMORTIZED_COST',
  BlendedCost = 'BLENDED_COST',
  NetAmortizedCost = 'NET_AMORTIZED_COST',
  NetUnblendedCost = 'NET_UNBLENDED_COST',
  NormalizedUsageAmount = 'NORMALIZED_USAGE_AMOUNT',
  UnblendedCost = 'UNBLENDED_COST',
  UsageQuantity = 'USAGE_QUANTITY',
}

export type ForecastValue = {
  __typename?: 'ForecastValue';
  lowerBound?: Maybe<Scalars['Float']>;
  mean: Scalars['Float'];
  upperBound?: Maybe<Scalars['Float']>;
};

export enum Frequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Never = 'NEVER',
  Once = 'ONCE',
  Weekly = 'WEEKLY',
}

export type IntegrationQueryResult = ErroredQueryResult | IntegrationResult;

export type IntegrationResult = {
  __typename?: 'IntegrationResult';
  id?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['AWSJSON']>;
  status?: Maybe<Scalars['String']>;
};

export type Interval = {
  __typename?: 'Interval';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type KeyValueType = {
  __typename?: 'KeyValueType';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum LimitedDataSourceNames {
  AwsOrganizationsExplorerReport = 'AWS_ORGANIZATIONS_EXPLORER_REPORT',
}

export type ListCurQueryResult = ErroredQueryResult | ListCurResult;

export type ListCurResult = {
  __typename?: 'ListCURResult';
  items: Array<Maybe<PartitionResult>>;
};

export type MetricQueryParameters = {
  accountId: Scalars['String'];
  dimensions?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['AWSDate']>;
  filter?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  metric: Scalars['String'];
  precision?: InputMaybe<PrecisionType>;
  refresh?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['AWSDate']>;
  units?: InputMaybe<Array<Scalars['String']>>;
};

export type MetricQueryResult = DeferredQueryResult | ErroredQueryResult | MetricSeriesResult;

export type MetricSeriesResult = {
  __typename?: 'MetricSeriesResult';
  hashcode: Scalars['String'];
  result?: Maybe<Array<Maybe<TimeSeriesDataPoint>>>;
};

export type MonitoringLatestResults =
  | BinnedTestResult
  | BooleanTestResult
  | ErroredQueryResult
  | MonitoringValueCheckResult
  | MulticlassBinnedTestResult;

export type MonitoringQueryParameters = {
  accountId: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  monitoringType: MonitoringType;
};

export type MonitoringQueryResult = ErroredQueryResult | MonitoringResult;

export type MonitoringResult = {
  __typename?: 'MonitoringResult';
  items?: Maybe<Array<MonitoringValue>>;
};

export enum MonitoringType {
  Check = 'CHECK',
  Test = 'TEST',
}

export type MonitoringValue = {
  __typename?: 'MonitoringValue';
  breaching?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  dateEnd?: Maybe<Scalars['String']>;
  dateResolution?: Maybe<Scalars['String']>;
  dateStart?: Maybe<Scalars['String']>;
  resultPartitions?: Maybe<Array<Maybe<Scalars['String']>>>;
  results?: Maybe<MonitoringLatestResults>;
  tags?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  taskFulfilledAt?: Maybe<Scalars['String']>;
};

export type MonitoringValueCheckResult = {
  __typename?: 'MonitoringValueCheckResult';
  columns: Array<Maybe<Scalars['String']>>;
  data: Array<Maybe<Array<Maybe<Scalars['String']>>>>;
  dataTypes: Array<Maybe<Scalars['String']>>;
};

export type MulticlassBinnedTestResult = {
  __typename?: 'MulticlassBinnedTestResult';
  code: Scalars['String'];
  cost: Scalars['Float'];
  dataViews?: Maybe<Array<TestResultDataView>>;
  labelCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  labelTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  labels?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  summary: TestResultSummary;
  usage?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  buyReservation?: Maybe<RiPurchaseResult>;
  buySavingsPlan?: Maybe<SpPurchaseResult>;
  cacheInvalidation?: Maybe<PutEventResult>;
  createContract?: Maybe<EdpContractResult>;
  deleteContract?: Maybe<DeletedContractResult>;
  deleteQueuedReservation?: Maybe<RiDeleteResult>;
  deleteQueuedSavingsPlan?: Maybe<SpDeleteResult>;
  evaluateRecommendations?: Maybe<PutEventResult>;
  refreshDataSource?: Maybe<PutEventResult>;
  restartCheck?: Maybe<PutEventResult>;
  restartChecks?: Maybe<PutEventResult>;
  restartDataSource?: Maybe<PutEventResult>;
  updateContract?: Maybe<EdpContractResult>;
  updateSettings?: Maybe<PutEventResult>;
};

export type MutationBuyReservationArgs = {
  params?: InputMaybe<RiPurchaseParameters>;
};

export type MutationBuySavingsPlanArgs = {
  params?: InputMaybe<SpPurchaseParameters>;
};

export type MutationCacheInvalidationArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type MutationCreateContractArgs = {
  params?: InputMaybe<EdpContractInput>;
};

export type MutationDeleteContractArgs = {
  params?: InputMaybe<ExistingEdpContractMemento>;
};

export type MutationDeleteQueuedReservationArgs = {
  params?: InputMaybe<RiDeleteReservationParameters>;
};

export type MutationDeleteQueuedSavingsPlanArgs = {
  params?: InputMaybe<SpDeleteParameters>;
};

export type MutationEvaluateRecommendationsArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type MutationRefreshDataSourceArgs = {
  params?: InputMaybe<RefreshQueryParameters>;
};

export type MutationRestartCheckArgs = {
  params?: InputMaybe<CheckSpecification>;
};

export type MutationRestartChecksArgs = {
  params?: InputMaybe<RestartChecksParameters>;
};

export type MutationRestartDataSourceArgs = {
  params?: InputMaybe<DataSourceSpecification>;
};

export type MutationUpdateContractArgs = {
  params?: InputMaybe<ExistingEdpContractInput>;
};

export type MutationUpdateSettingsArgs = {
  params?: InputMaybe<SettingsUpdateParameters>;
};

export type OptimizationRecommendation = {
  __typename?: 'OptimizationRecommendation';
  breaching: Scalars['Boolean'];
  categories: Array<Scalars['String']>;
  code: Scalars['String'];
  effort?: Maybe<RecommendationEffort>;
  isObfuscated: Scalars['Boolean'];
  products: Array<Scalars['String']>;
  proposedCost?: Maybe<Scalars['Float']>;
  resourceCost: Scalars['Float'];
  saving: Scalars['Float'];
  status?: Maybe<RecommendationStatus>;
  tags?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  tests: Array<OptimizationTest>;
};

export type OptimizationSaving = {
  __typename?: 'OptimizationSaving';
  category: OptimizationSavingCategory;
  count: Scalars['Int'];
  saving: Scalars['Float'];
};

export enum OptimizationSavingCategory {
  Cancelled = 'CANCELLED',
  HighEffort = 'HIGH_EFFORT',
  LowEffort = 'LOW_EFFORT',
  MediumEffort = 'MEDIUM_EFFORT',
  Total = 'TOTAL',
}

export type OptimizationSummary = {
  __typename?: 'OptimizationSummary';
  estimatedSavings: Array<Maybe<OptimizationSaving>>;
  recsBreaching: Scalars['Int'];
  recsCancelled: Scalars['Int'];
  recsNonBreaching: Scalars['Int'];
  recsTotal: Scalars['Int'];
};

export type OptimizationTest = {
  __typename?: 'OptimizationTest';
  breaching: Scalars['Boolean'];
  code: Scalars['String'];
};

export type OptimizationsQueryResult = ErroredQueryResult | OptimizationsResult;

export type OptimizationsResult = {
  __typename?: 'OptimizationsResult';
  recs: Array<OptimizationRecommendation>;
  summary: OptimizationSummary;
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum OrgResourceType {
  Account = 'ACCOUNT',
  ManagementAccount = 'MANAGEMENT_ACCOUNT',
  OrganizationalUnit = 'ORGANIZATIONAL_UNIT',
  Root = 'ROOT',
}

export type PartitionResult = {
  __typename?: 'PartitionResult';
  files?: Maybe<Scalars['Int']>;
  partitions: Array<Scalars['String']>;
  rows?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Long']>;
};

export type PoliciesResult = {
  __typename?: 'PoliciesResult';
  action: Scalars['String'];
  decision: Scalars['String'];
  group: Scalars['String'];
  resource: Scalars['String'];
};

export enum PrecisionType {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type PriceListQueryParameters = {
  accountId: Scalars['String'];
  filter?: InputMaybe<Scalars['String']>;
  pricelist: Scalars['String'];
};

export type PriceListQueryResult = DeferredQueryResult | ErroredQueryResult | PriceListResult;

export type PriceListResult = {
  __typename?: 'PriceListResult';
  hashcode: Scalars['String'];
  result?: Maybe<TableType>;
};

export type ProbeCurResult = {
  __typename?: 'ProbeCURResult';
  items?: Maybe<Array<Maybe<ProbeResultType>>>;
  result?: Maybe<Scalars['Boolean']>;
};

export type ProbeQueryParameters = {
  accountId: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  roleArn?: InputMaybe<Scalars['String']>;
  s3Bucket?: InputMaybe<Scalars['String']>;
  s3Prefix?: InputMaybe<Scalars['String']>;
};

export type ProbeQueryResult = ErroredQueryResult | ProbeCurResult | ProbeRoleResult;

export type ProbeResultType = {
  __typename?: 'ProbeResultType';
  code: Scalars['String'];
  description: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export type ProbeRoleResult = {
  __typename?: 'ProbeRoleResult';
  result: Array<Maybe<PoliciesResult>>;
};

export type ProposedCostsValue = {
  __typename?: 'ProposedCostsValue';
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
};

export type PurchasesParameters = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  purchaseId?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type PurchasesUnionResult = ErroredQueryResult | SpriPurchasesResult;

export type PutEventDetails = {
  __typename?: 'PutEventDetails';
  entries?: Maybe<Array<Maybe<EventDetails>>>;
  failedEntry?: Maybe<Scalars['Int']>;
};

export type PutEventResult = ErroredQueryResult | PutEventDetails;

export type Query = {
  __typename?: 'Query';
  athenaQuery?: Maybe<MetricQueryResult>;
  athenaViewQuery?: Maybe<ViewResult>;
  cacheQuery?: Maybe<CacheQueryResult>;
  checkQuery?: Maybe<CheckQueryResult>;
  costUsageQuery?: Maybe<MetricQueryResult>;
  dataSourceQuery?: Maybe<DataSourceQueryResult>;
  forecastQuery?: Maybe<ForecastQueryResult>;
  integrationQuery?: Maybe<IntegrationQueryResult>;
  listCURQuery?: Maybe<ListCurQueryResult>;
  listEDPContracts?: Maybe<EdpContractListingResult>;
  monitoringLatestQuery?: Maybe<MonitoringQueryResult>;
  optimizationsQuery?: Maybe<OptimizationsQueryResult>;
  priceListQuery?: Maybe<PriceListQueryResult>;
  probeCURQuery?: Maybe<ProbeQueryResult>;
  probeRoleQuery?: Maybe<ProbeQueryResult>;
  recommendationsQuery?: Maybe<RecommendationsQueryResult>;
  resourceQuery?: Maybe<ResourceQueryUnionResult>;
  riListOfferingsQuery?: Maybe<RiListOfferingsUnionResult>;
  riPurchasePermissionQuery?: Maybe<RiPurchasePermissionUnionResult>;
  spListOfferingsQuery?: Maybe<SpListOfferingsUnionResult>;
  spPurchasePermissionQuery?: Maybe<SpPurchasePermissionUnionResult>;
  spriPurchasesQuery?: Maybe<PurchasesUnionResult>;
  testDataTableQuery?: Maybe<DataTableResult>;
  testResultsQuery?: Maybe<TestQueryResult>;
};

export type QueryAthenaQueryArgs = {
  params?: InputMaybe<MetricQueryParameters>;
};

export type QueryAthenaViewQueryArgs = {
  params?: InputMaybe<ViewQueryParameters>;
};

export type QueryCacheQueryArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type QueryCheckQueryArgs = {
  params?: InputMaybe<CheckQueryParameters>;
};

export type QueryCostUsageQueryArgs = {
  params?: InputMaybe<CostUsageQueryParameters>;
};

export type QueryDataSourceQueryArgs = {
  params?: InputMaybe<DataSourceQueryParameters>;
};

export type QueryForecastQueryArgs = {
  params?: InputMaybe<ForecastQueryParameters>;
};

export type QueryIntegrationQueryArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type QueryListCurQueryArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type QueryListEdpContractsArgs = {
  params?: InputMaybe<EdpContractsQueryParams>;
};

export type QueryMonitoringLatestQueryArgs = {
  params?: InputMaybe<MonitoringQueryParameters>;
};

export type QueryOptimizationsQueryArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type QueryPriceListQueryArgs = {
  params?: InputMaybe<PriceListQueryParameters>;
};

export type QueryProbeCurQueryArgs = {
  params?: InputMaybe<ProbeQueryParameters>;
};

export type QueryProbeRoleQueryArgs = {
  params?: InputMaybe<ProbeQueryParameters>;
};

export type QueryRecommendationsQueryArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type QueryResourceQueryArgs = {
  params?: InputMaybe<ResourceQueryParameters>;
};

export type QueryRiListOfferingsQueryArgs = {
  params?: InputMaybe<RiOfferingParameters>;
};

export type QueryRiPurchasePermissionQueryArgs = {
  params?: InputMaybe<RiPurchasePermissionParameters>;
};

export type QuerySpListOfferingsQueryArgs = {
  params?: InputMaybe<SpOfferingParameters>;
};

export type QuerySpPurchasePermissionQueryArgs = {
  params?: InputMaybe<SpPurchasePermissionParameters>;
};

export type QuerySpriPurchasesQueryArgs = {
  params?: InputMaybe<PurchasesParameters>;
};

export type QueryTestDataTableQueryArgs = {
  params?: InputMaybe<TestResultsTableParameters>;
};

export type QueryTestResultsQueryArgs = {
  params?: InputMaybe<TestResultsQueryParameters>;
};

export type RiDeleteReservation = {
  purchaseOrderId: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
};

export type RiDeleteReservationParameters = {
  accountId: Scalars['String'];
  deletions: Array<RiDeleteReservation>;
};

export type RiDeleteResult = ErroredQueryResult | ReservedInstanceDeleteResult;

export type RiInstanceType = {
  __typename?: 'RIInstanceType';
  count: Scalars['Int'];
  product?: Maybe<Scalars['String']>;
  tenancy?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RiListOfferingsUnionResult = ErroredQueryResult | RiOfferingsEc2Result | RiOfferingsRdsResult;

export type RiOfferingEc2 = {
  __typename?: 'RIOfferingEC2';
  currencyCode: Scalars['String'];
  duration: Scalars['Int'];
  fixedPrice: Scalars['Float'];
  instanceTenancy: Scalars['String'];
  instanceType: Scalars['String'];
  marketplace: Scalars['Boolean'];
  offeringClass: Scalars['String'];
  offeringType: Scalars['String'];
  productDescription: Scalars['String'];
  recurringCharges?: Maybe<Array<RiRecurringPrice>>;
  reservedInstancesOfferingId: Scalars['String'];
  scope: Scalars['String'];
  usagePrice: Scalars['Float'];
};

export type RiOfferingParameters = {
  accountId: Scalars['String'];
  filter?: InputMaybe<RiOfferingsFilter>;
  region: Scalars['String'];
  service: Scalars['String'];
};

export type RiOfferingRds = {
  __typename?: 'RIOfferingRDS';
  currencyCode: Scalars['String'];
  dbInstanceClass: Scalars['String'];
  duration: Scalars['Int'];
  fixedPrice: Scalars['Float'];
  multiAZ: Scalars['Boolean'];
  offeringType: Scalars['String'];
  productDescription: Scalars['String'];
  recurringCharges?: Maybe<Array<RiRecurringPrice>>;
  reservedDBInstancesOfferingId: Scalars['String'];
  usagePrice: Scalars['Float'];
};

export type RiOfferingType = {
  __typename?: 'RIOfferingType';
  class?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type RiOfferingsEc2Result = {
  __typename?: 'RIOfferingsEC2Result';
  items?: Maybe<Array<RiOfferingEc2>>;
};

export type RiOfferingsFilter = {
  duration?: InputMaybe<Scalars['Int']>;
  includeMarketplace?: InputMaybe<Scalars['Boolean']>;
  instanceFamily: Scalars['String'];
  instanceSize: Scalars['String'];
  multiAZ?: InputMaybe<Scalars['Boolean']>;
  offeringClass?: InputMaybe<Scalars['String']>;
  offeringType?: InputMaybe<Scalars['String']>;
  productDescription: Scalars['String'];
};

export type RiOfferingsRdsResult = {
  __typename?: 'RIOfferingsRDSResult';
  items?: Maybe<Array<RiOfferingRds>>;
};

export type RiPriceType = {
  __typename?: 'RIPriceType';
  fixed?: Maybe<Scalars['Float']>;
  recurring?: Maybe<Array<RiRecurringPrice>>;
  usage?: Maybe<Scalars['Float']>;
};

export type RiPurchaseParameters = {
  accountId: Scalars['String'];
  purchases: Array<RiPurchaseReservation>;
};

export type RiPurchasePermissionParameters = {
  accountId: Scalars['String'];
  action: SpriAction;
  region: Scalars['String'];
  service: Scalars['String'];
};

export type RiPurchasePermissionResult = {
  __typename?: 'RIPurchasePermissionResult';
  action: SpriAction;
  allowed: Scalars['Boolean'];
  service: Scalars['String'];
};

export type RiPurchasePermissionUnionResult = ErroredQueryResult | RiPurchasePermissionResult;

export type RiPurchaseReservation = {
  instanceCount: Scalars['Int'];
  offeringId: Scalars['String'];
  purchaseExecutionTime: Scalars['AWSDateTime'];
  region: Scalars['String'];
  service: Scalars['String'];
};

export type RiPurchaseResult = ErroredQueryResult | ReservedInstancePurchaseResult;

export type RiRecurringPrice = {
  __typename?: 'RIRecurringPrice';
  amount?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['String']>;
};

export type RiResult = {
  __typename?: 'RIResult';
  currencyCode?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  instance?: Maybe<RiInstanceType>;
  multiAZ?: Maybe<Scalars['Boolean']>;
  offering?: Maybe<RiOfferingType>;
  price?: Maybe<RiPriceType>;
  region?: Maybe<Scalars['String']>;
  retentionDate?: Maybe<Scalars['AWSTimestamp']>;
  scope?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['AWSDateTime']>;
  state?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<KeyValueType>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum RecommendationEffort {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export type RecommendationSettingParameters = {
  status?: InputMaybe<RecommendationStatus>;
};

export type RecommendationSettings = {
  code: Scalars['String'];
  recommendationSettings: RecommendationSettingParameters;
};

export enum RecommendationStatus {
  Cancelled = 'CANCELLED',
  Discovered = 'DISCOVERED',
  EmptyResults = 'EMPTY_RESULTS',
  Fulfilled = 'FULFILLED',
  Inprogress = 'INPROGRESS',
}

export type RecommendationValue = {
  __typename?: 'RecommendationValue';
  breaching: Scalars['Boolean'];
  categories: Array<Maybe<Scalars['String']>>;
  code: Scalars['String'];
  effort?: Maybe<RecommendationEffort>;
  empty?: Maybe<Scalars['Boolean']>;
  frequency?: Maybe<Frequency>;
  fulfilledAt?: Maybe<Scalars['String']>;
  products: Array<Maybe<Scalars['String']>>;
  proposedCost?: Maybe<Scalars['Float']>;
  proposedCosts?: Maybe<ProposedCostsValue>;
  resourceCost: Scalars['Float'];
  status?: Maybe<RecommendationStatus>;
  tags?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  testResults?: Maybe<Array<MonitoringValue>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type RecommendationsQueryResult = ErroredQueryResult | RecommendationsResult;

export type RecommendationsResult = {
  __typename?: 'RecommendationsResult';
  items: Array<Maybe<RecommendationValue>>;
};

export type RefreshQueryParameters = {
  accountId: Scalars['String'];
  dataSourceName: LimitedDataSourceNames;
  startDate: Scalars['AWSDate'];
};

export type ReservedInstanceDelete = {
  __typename?: 'ReservedInstanceDelete';
  purchaseOrderId: Scalars['String'];
  status: Scalars['String'];
};

export type ReservedInstanceDeleteResult = {
  __typename?: 'ReservedInstanceDeleteResult';
  items: Array<Maybe<ReservedInstanceDelete>>;
};

export type ReservedInstancePurchase = {
  __typename?: 'ReservedInstancePurchase';
  dueDate: Scalars['String'];
  offeringId: Scalars['String'];
  purchaseOrderId: Scalars['String'];
  status: Scalars['String'];
};

export type ReservedInstancePurchaseResult = {
  __typename?: 'ReservedInstancePurchaseResult';
  items: Array<Maybe<ReservedInstancePurchase>>;
};

export enum ResourceCode {
  OrganizationResource = 'ORGANIZATION_RESOURCE',
  RiEc2 = 'RI_EC2',
  RiRds = 'RI_RDS',
}

export type ResourceItem = {
  __typename?: 'ResourceItem';
  accountId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  resource: ResourceUnion;
};

export type ResourceQueryFilter = {
  endDate?: InputMaybe<Scalars['String']>;
  excludeDeleted?: InputMaybe<Scalars['Boolean']>;
  orgResourceTypes?: InputMaybe<Array<OrgResourceType>>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type ResourceQueryParameters = {
  accountId: Scalars['String'];
  code: ResourceCode;
  filter?: InputMaybe<ResourceQueryFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type ResourceQueryResult = {
  __typename?: 'ResourceQueryResult';
  items?: Maybe<Array<Maybe<ResourceItem>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ResourceQueryUnionResult = ErroredQueryResult | ResourceQueryResult;

export type ResourceUnion = AccountValue | RiResult;

export type RestartChecksParameters = {
  accountId: Scalars['String'];
  executionType?: InputMaybe<ExecutionType>;
  invokeEvaluation?: InputMaybe<Scalars['Boolean']>;
};

export type SpDeleteParameters = {
  accountId: Scalars['String'];
  deletions: Array<SpDeleteSavingsPlan>;
};

export type SpDeleteResult = ErroredQueryResult | SavingsPlanDeleteResult;

export type SpDeleteSavingsPlan = {
  purchaseOrderId: Scalars['String'];
  savingsPlanType: Scalars['String'];
};

export type SpInstanceType = {
  __typename?: 'SPInstanceType';
  ec2InstanceFamily?: Maybe<Scalars['String']>;
  ec2Region?: Maybe<Scalars['String']>;
};

export type SpListOfferingsUnionResult = ErroredQueryResult | SpOfferingsResult;

export type SpOfferingParameters = {
  accountId: Scalars['String'];
  filter?: InputMaybe<SpOfferingsFilter>;
};

export type SpOfferingType = {
  __typename?: 'SPOfferingType';
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  type: Scalars['String'];
};

export type SpOfferingsFilter = {
  durations: Array<Scalars['Int']>;
  ec2InstanceFamilies?: InputMaybe<Array<Scalars['String']>>;
  ec2Regions?: InputMaybe<Array<Scalars['String']>>;
  offeringTypes: Array<Scalars['String']>;
  savingsPlanTypes: Array<Scalars['String']>;
};

export type SpOfferingsResult = {
  __typename?: 'SPOfferingsResult';
  items: Array<Maybe<SavingsPlanOffering>>;
};

export type SpPriceType = {
  __typename?: 'SPPriceType';
  commitment: Scalars['Float'];
  recurringPaymentAmount?: Maybe<Scalars['Float']>;
  upfrontPaymentAmount?: Maybe<Scalars['Int']>;
};

export type SpPurchaseParameters = {
  accountId: Scalars['String'];
  purchases: Array<SpPurchaseSavingsPlan>;
};

export type SpPurchasePermissionParameters = {
  accountId: Scalars['String'];
  action: SpriAction;
};

export type SpPurchasePermissionResult = {
  __typename?: 'SPPurchasePermissionResult';
  action: SpriAction;
  allowed: Scalars['Boolean'];
};

export type SpPurchasePermissionUnionResult = ErroredQueryResult | SpPurchasePermissionResult;

export type SpPurchaseResult = ErroredQueryResult | SavingsPlanPurchaseResult;

export type SpPurchaseSavingsPlan = {
  commitment: Scalars['Float'];
  purchaseExecutionTime: Scalars['AWSDateTime'];
  savingsPlanOfferingId: Scalars['String'];
  savingsPlanType: Scalars['String'];
  upfrontPaymentAmount?: InputMaybe<Scalars['Int']>;
};

export enum SpriAction {
  Offerings = 'OFFERINGS',
  Purchase = 'PURCHASE',
}

export type SpriPurchase = {
  __typename?: 'SPRIPurchase';
  accountId: Scalars['String'];
  awsAccountId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  details?: Maybe<SpriResultUnion>;
  id: Scalars['String'];
  purchasePlannedAt: Scalars['String'];
  purchasedAt?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type SpriPurchasesResult = {
  __typename?: 'SPRIPurchasesResult';
  items?: Maybe<Array<Maybe<SpriPurchase>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type SpriResultUnion = RiResult | SpResult;

export type SpResult = {
  __typename?: 'SPResult';
  currencyCode?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  instance?: Maybe<SpInstanceType>;
  offering?: Maybe<SpOfferingType>;
  price?: Maybe<SpPriceType>;
  region?: Maybe<Scalars['String']>;
  retentionDate?: Maybe<Scalars['AWSTimestamp']>;
  service?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['AWSDateTime']>;
  state?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<KeyValueType>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type SavingsPlanDelete = {
  __typename?: 'SavingsPlanDelete';
  purchaseOrderId: Scalars['String'];
  status: Scalars['String'];
};

export type SavingsPlanDeleteResult = {
  __typename?: 'SavingsPlanDeleteResult';
  items: Array<Maybe<SavingsPlanDelete>>;
};

export type SavingsPlanOffering = {
  __typename?: 'SavingsPlanOffering';
  currency: Scalars['String'];
  description: Scalars['String'];
  durationSeconds: Scalars['Int'];
  ec2InstanceFamily?: Maybe<Scalars['String']>;
  ec2Region?: Maybe<Scalars['String']>;
  offeringId: Scalars['String'];
  operation?: Maybe<Scalars['String']>;
  paymentOption: Scalars['String'];
  planType: Scalars['String'];
  productTypes: Array<Scalars['String']>;
  serviceCode: Scalars['String'];
  usageType: Scalars['String'];
};

export type SavingsPlanPurchase = {
  __typename?: 'SavingsPlanPurchase';
  offeringId: Scalars['String'];
  purchaseExecutionTime: Scalars['String'];
  purchaseOrderId: Scalars['String'];
  status: Scalars['String'];
};

export type SavingsPlanPurchaseResult = {
  __typename?: 'SavingsPlanPurchaseResult';
  items: Array<Maybe<SavingsPlanPurchase>>;
};

export type SettingsUpdateParameters = {
  accountId: Scalars['String'];
  accounts?: InputMaybe<Array<InputMaybe<AwsAccountParameters>>>;
  recommendations?: InputMaybe<Array<InputMaybe<RecommendationSettings>>>;
};

export type TableType = {
  __typename?: 'TableType';
  columns: Array<Scalars['String']>;
  data: Array<Maybe<Array<Maybe<Scalars['String']>>>>;
  dataTypes?: Maybe<Array<Scalars['String']>>;
};

export type TestDataPoint = {
  __typename?: 'TestDataPoint';
  resultDetails: TestResultDetails;
  time: Scalars['String'];
};

export type TestQueryResult = DeferredQueryResult | ErroredQueryResult | TestResult;

export type TestResult = {
  __typename?: 'TestResult';
  hashcode: Scalars['String'];
  result?: Maybe<Array<Maybe<TestDataPoint>>>;
};

export type TestResultDataView = {
  __typename?: 'TestResultDataView';
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TestResultDetails = BinnedTestResult | BooleanTestResult | MulticlassBinnedTestResult;

export type TestResultSummary = {
  __typename?: 'TestResultSummary';
  tooltip?: Maybe<TestResultTooltip>;
  type: Scalars['String'];
  value: Scalars['String'];
};

export type TestResultTooltip = {
  __typename?: 'TestResultTooltip';
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TestResultsQueryParameters = {
  accountId: Scalars['String'];
  code: Scalars['String'];
  startDate?: InputMaybe<Scalars['AWSDate']>;
};

export type TestResultsTableParameters = {
  accountId: Scalars['String'];
  code: Scalars['String'];
  data_view_name?: InputMaybe<Scalars['String']>;
};

export type TimeSeriesDataPoint = {
  __typename?: 'TimeSeriesDataPoint';
  time: Scalars['String'];
  values: Array<TimeSeriesDataValue>;
};

export type TimeSeriesDataValue = {
  __typename?: 'TimeSeriesDataValue';
  dimensions: Array<Scalars['String']>;
  units: Array<Scalars['String']>;
  values: Array<Scalars['Float']>;
};

export type ViewQueryParameters = {
  accountId: Scalars['String'];
  name: Scalars['String'];
  parameters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ViewResult = CsvResult | DataTableResult | DeferredQueryResult | ErroredQueryResult;

export type EdpEligibleSpendQueryVariables = Exact<{
  accountId: Scalars['String'];
  dateRangeFrom: Scalars['String'];
}>;

export type EdpEligibleSpendQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string | null> | null> | null;
          dataTypes: Array<string>;
        } | null;
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type EdpSpendBreakdownQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type EdpSpendBreakdownQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string | null> | null> | null;
          dataTypes: Array<string>;
        } | null;
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type EdpConfigurationQueryVariables = Exact<{
  accountId: Scalars['String'];
  startDate: Scalars['String'];
}>;

export type EdpConfigurationQuery = {
  __typename?: 'Query';
  listEDPContracts?:
    | {
        __typename: 'EDPContractListing';
        items?: Array<{
          __typename?: 'EDPContractFacade';
          costModelContainer: {
            __typename?: 'CostModelContainer';
            costModel?: {
              __typename?: 'CostModel';
              endDate: any;
              costAllocationSpans: Array<{ __typename?: 'CostAllocationSpan'; startDate: any; endDate: any }>;
            } | null;
          };
        } | null> | null;
      }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: { __typename?: 'DataTable'; data?: Array<Array<string | null> | null> | null } | null;
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type EdpTotalEligibleSpendQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type EdpTotalEligibleSpendQuery = {
  __typename?: 'Query';
  commitment?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string | null> | null> | null;
          dataTypes: Array<string>;
        } | null;
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
  spend?:
    | { __typename?: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string | null> | null> | null;
          dataTypes: Array<string>;
        } | null;
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type EdpContractCreateMutationVariables = Exact<{
  accountId: Scalars['ID'];
  costModel: CostModelInput;
}>;

export type EdpContractCreateMutation = {
  __typename?: 'Mutation';
  createContract?:
    | {
        __typename: 'EDPContract';
        costModel: {
          __typename?: 'CostModel';
          startDate: any;
          endDate: any;
          costAllocationSpans: Array<{
            __typename?: 'CostAllocationSpan';
            startDate: any;
            endDate: any;
            commitment: number;
            discount: number;
          }>;
        };
      }
    | { __typename: 'EDPValidationError'; context?: any | null; code: string; fieldName: string }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type EdpContractDeleteMutationVariables = Exact<{
  accountId: Scalars['ID'];
  identifier: Scalars['ID'];
}>;

export type EdpContractDeleteMutation = {
  __typename?: 'Mutation';
  deleteContract?:
    | { __typename?: 'EDPContractMemento'; identifier: string }
    | { __typename?: 'ErroredQueryResult'; error: string }
    | null;
};

export type EdpContractListingQueryVariables = Exact<{
  accountId: Scalars['String'];
  contractStatus?: InputMaybe<ContractStatus>;
}>;

export type EdpContractListingQuery = {
  __typename?: 'Query';
  listEDPContracts?:
    | {
        __typename: 'EDPContractListing';
        items?: Array<{
          __typename?: 'EDPContractFacade';
          contractId: string;
          costModelContainer: {
            __typename?: 'CostModelContainer';
            id: string;
            costModel?: {
              __typename?: 'CostModel';
              startDate: any;
              endDate: any;
              costAllocationSpans: Array<{
                __typename?: 'CostAllocationSpan';
                startDate: any;
                endDate: any;
                name: string;
                commitment: number;
                discount: number;
              }>;
            } | null;
          };
        } | null> | null;
      }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type EdpContractUpdateMutationVariables = Exact<{
  accountId: Scalars['ID'];
  identifier: Scalars['ID'];
  costModel: CostModelInput;
}>;

export type EdpContractUpdateMutation = {
  __typename?: 'Mutation';
  updateContract?:
    | {
        __typename: 'EDPContract';
        identifier: string;
        costModel: {
          __typename?: 'CostModel';
          startDate: any;
          endDate: any;
          costAllocationSpans: Array<{
            __typename?: 'CostAllocationSpan';
            startDate: any;
            endDate: any;
            commitment: number;
            discount: number;
          }>;
        };
      }
    | { __typename: 'EDPValidationError'; context?: any | null; code: string; fieldName: string }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type EdpSavingsQueryQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type EdpSavingsQueryQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename?: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string | null> | null> | null;
          dataTypes: Array<string>;
        } | null;
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type EdpCumulativeSpendQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type EdpCumulativeSpendQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename?: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string | null> | null> | null;
          dataTypes: Array<string>;
        } | null;
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type SpendVsCommitmentChartQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type SpendVsCommitmentChartQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename?: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string | null> | null> | null;
          dataTypes: Array<string>;
        } | null;
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type UpdateRecommendationsMutationVariables = Exact<{
  accountId: Scalars['String'];
  recommendations?: InputMaybe<Array<InputMaybe<RecommendationSettings>> | InputMaybe<RecommendationSettings>>;
}>;

export type UpdateRecommendationsMutation = {
  __typename?: 'Mutation';
  updateSettings?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename?: 'PutEventDetails';
        entries?: Array<{ __typename?: 'EventDetails'; eventId?: string | null } | null> | null;
      }
    | null;
};

export type OptimizationsListQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type OptimizationsListQuery = {
  __typename?: 'Query';
  optimizationsQuery?:
    | { __typename?: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'OptimizationsResult';
        summary: {
          __typename?: 'OptimizationSummary';
          recsBreaching: number;
          recsCancelled: number;
          recsNonBreaching: number;
          recsTotal: number;
          estimatedSavings: Array<{
            __typename?: 'OptimizationSaving';
            category: OptimizationSavingCategory;
            count: number;
            saving: number;
          } | null>;
        };
        recs: Array<{
          __typename?: 'OptimizationRecommendation';
          breaching: boolean;
          categories: Array<string>;
          isObfuscated: boolean;
          code: string;
          effort?: RecommendationEffort | null;
          products: Array<string>;
          proposedCost?: number | null;
          resourceCost: number;
          saving: number;
          status?: RecommendationStatus | null;
          tags?: Array<Array<string | null> | null> | null;
          tests: Array<{ __typename?: 'OptimizationTest'; code: string }>;
        }>;
      }
    | null;
};

export type TestResultsQueryVariables = Exact<{
  accountId: Scalars['String'];
  code: Scalars['String'];
}>;

export type TestResultsQuery = {
  __typename?: 'Query';
  monitoringLatestQuery?:
    | { __typename: 'ErroredQueryResult' }
    | {
        __typename: 'MonitoringResult';
        items?: Array<{
          __typename?: 'MonitoringValue';
          results?:
            | {
                __typename: 'BinnedTestResult';
                summary: { __typename?: 'TestResultSummary' } & {
                  ' $fragmentRefs'?: { TestResultSummaryFragmentFragment: TestResultSummaryFragmentFragment };
                };
              }
            | {
                __typename: 'BooleanTestResult';
                summary: { __typename?: 'TestResultSummary' } & {
                  ' $fragmentRefs'?: { TestResultSummaryFragmentFragment: TestResultSummaryFragmentFragment };
                };
              }
            | { __typename: 'ErroredQueryResult' }
            | { __typename?: 'MonitoringValueCheckResult' }
            | {
                __typename: 'MulticlassBinnedTestResult';
                summary: { __typename?: 'TestResultSummary' } & {
                  ' $fragmentRefs'?: { TestResultSummaryFragmentFragment: TestResultSummaryFragmentFragment };
                };
              }
            | null;
        }> | null;
      }
    | null;
};

export type TestResultSummaryFragmentFragment = {
  __typename?: 'TestResultSummary';
  type: string;
  value: string;
  tooltip?: {
    __typename?: 'TestResultTooltip';
    data?: Array<Array<string | null> | null> | null;
    types?: Array<string | null> | null;
  } | null;
} & { ' $fragmentName'?: 'TestResultSummaryFragmentFragment' };

export type SpPurchaseMutationVariables = Exact<{
  accountId: Scalars['String'];
  purchases: Array<SpPurchaseSavingsPlan> | SpPurchaseSavingsPlan;
}>;

export type SpPurchaseMutation = {
  __typename?: 'Mutation';
  buySavingsPlan?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'SavingsPlanPurchaseResult';
        items: Array<{
          __typename?: 'SavingsPlanPurchase';
          offeringId: string;
          status: string;
          purchaseOrderId: string;
          purchaseExecutionTime: string;
        } | null>;
      }
    | null;
};

export type CancelSpReservationMutationVariables = Exact<{
  accountId: Scalars['String'];
  deletions: Array<SpDeleteSavingsPlan> | SpDeleteSavingsPlan;
}>;

export type CancelSpReservationMutation = {
  __typename?: 'Mutation';
  deleteQueuedSavingsPlan?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'SavingsPlanDeleteResult';
        items: Array<{ __typename?: 'SavingsPlanDelete'; purchaseOrderId: string; status: string } | null>;
      }
    | null;
};

export type MonitoringLatestQueryQueryVariables = Exact<{
  accountId: Scalars['String'];
  checkCode: Scalars['String'];
}>;

export type MonitoringLatestQueryQuery = {
  __typename?: 'Query';
  monitoringLatestQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'MonitoringResult';
        items?: Array<{
          __typename?: 'MonitoringValue';
          code: string;
          results?:
            | { __typename: 'BinnedTestResult' }
            | { __typename: 'BooleanTestResult' }
            | { __typename: 'ErroredQueryResult' }
            | {
                __typename: 'MonitoringValueCheckResult';
                columns: Array<string | null>;
                data: Array<Array<string | null> | null>;
                dataTypes: Array<string | null>;
              }
            | { __typename: 'MulticlassBinnedTestResult' }
            | null;
        }> | null;
      }
    | null;
};

export type SpPermissionCheckQueryVariables = Exact<{
  accountId: Scalars['String'];
  action: SpriAction;
}>;

export type SpPermissionCheckQuery = {
  __typename?: 'Query';
  spPurchasePermissionQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | { __typename: 'SPPurchasePermissionResult'; action: SpriAction; allowed: boolean }
    | null;
};

export type ComputeSprioDailyQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type ComputeSprioDailyQuery = {
  __typename?: 'Query';
  summaryQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'MonitoringResult';
        items?: Array<{
          __typename?: 'MonitoringValue';
          code: string;
          results?:
            | { __typename: 'BinnedTestResult' }
            | { __typename: 'BooleanTestResult' }
            | { __typename: 'ErroredQueryResult' }
            | {
                __typename: 'MonitoringValueCheckResult';
                columns: Array<string | null>;
                data: Array<Array<string | null> | null>;
                dataTypes: Array<string | null>;
              }
            | { __typename: 'MulticlassBinnedTestResult' }
            | null;
        }> | null;
      }
    | null;
  dailyUsageQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string | null> | null> | null;
          dataTypes: Array<string>;
        } | null;
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export type SageMakerSprioDailyQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type SageMakerSprioDailyQuery = {
  __typename?: 'Query';
  summaryQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'MonitoringResult';
        items?: Array<{
          __typename?: 'MonitoringValue';
          code: string;
          results?:
            | { __typename: 'BinnedTestResult' }
            | { __typename: 'BooleanTestResult' }
            | { __typename: 'ErroredQueryResult' }
            | {
                __typename: 'MonitoringValueCheckResult';
                columns: Array<string | null>;
                data: Array<Array<string | null> | null>;
                dataTypes: Array<string | null>;
              }
            | { __typename: 'MulticlassBinnedTestResult' }
            | null;
        }> | null;
      }
    | null;
  dailyUsageQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string | null> | null> | null;
          dataTypes: Array<string>;
        } | null;
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | null;
};

export const TestResultSummaryFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TestResultSummaryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TestResultSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tooltip' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'types' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TestResultSummaryFragmentFragment, unknown>;
export const EdpEligibleSpendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EDPEligibleSpend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeFrom' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'athenaViewQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'StringValue', value: 'cco_view_edp_eligibility_v1', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'StringValue', value: '{accountId}', block: false },
                          { kind: 'Variable', name: { kind: 'Name', value: 'dateRangeFrom' } },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataTableResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'table' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'StringValue', value: 'aws_infra_spend', block: false },
                                { kind: 'StringValue', value: 'aws_marketplace_spend', block: false },
                                { kind: 'StringValue', value: 'eligible_marketplace_spend', block: false },
                                { kind: 'StringValue', value: 'non_eligible_marketplace_spend', block: false },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EdpEligibleSpendQuery, EdpEligibleSpendQueryVariables>;
export const EdpSpendBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EDPSpendBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'athenaViewQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'StringValue', value: 'cco_view_edp_commitment_insight_v1', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'StringValue', value: '{accountId}', block: false }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataTableResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'table' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'StringValue', value: 'updated_at', block: false },
                                { kind: 'StringValue', value: 'aws_infra_spend', block: false },
                                { kind: 'StringValue', value: 'aws_marketplace_spend', block: false },
                                { kind: 'StringValue', value: 'eligible_marketplace_spend', block: false },
                                { kind: 'StringValue', value: 'non_eligible_marketplace_spend', block: false },
                                { kind: 'StringValue', value: 'commitment', block: false },
                                { kind: 'StringValue', value: 'total_edp_eligible_spend', block: false },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EdpSpendBreakdownQuery, EdpSpendBreakdownQueryVariables>;
export const EdpConfigurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EDPConfiguration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listEDPContracts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contractStatus' },
                      value: { kind: 'EnumValue', value: 'ACTIVE' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EDPContractListing' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EDPContractFacade' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'costModelContainer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'CostModelContainer' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'costModel' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'costAllocationSpans' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                                          { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'athenaViewQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'StringValue', value: 'cco_view_edp_eligibility_v1', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'StringValue', value: '{accountId}', block: false },
                          { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataTableResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'table' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'StringValue', value: 'aws_infra_spend', block: false },
                                { kind: 'StringValue', value: 'eligible_marketplace_spend', block: false },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EdpConfigurationQuery, EdpConfigurationQueryVariables>;
export const EdpTotalEligibleSpendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EDPTotalEligibleSpend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'commitment' },
            name: { kind: 'Name', value: 'athenaViewQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'StringValue', value: 'cco_view_edp_commitment_insight_v1', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'StringValue', value: '{accountId}', block: false }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataTableResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'table' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'StringValue', value: 'aws_infra_spend', block: false },
                                { kind: 'StringValue', value: 'eligible_marketplace_spend', block: false },
                                { kind: 'StringValue', value: 'commitment', block: false },
                                { kind: 'StringValue', value: 'total_edp_eligible_spend', block: false },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeferredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'spend' },
            name: { kind: 'Name', value: 'athenaViewQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'StringValue', value: 'cco_view_edp_cum_sum_spend_v1', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'StringValue', value: '{accountId}', block: false }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataTableResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'table' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'StringValue', value: 'edp_month_start', block: false },
                                { kind: 'StringValue', value: 'edp_discount_cumm_spend', block: false },
                                { kind: 'StringValue', value: 'is_forecast', block: false },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeferredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EdpTotalEligibleSpendQuery, EdpTotalEligibleSpendQueryVariables>;
export const EdpContractCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EDPContractCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'costModel' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CostModelInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'costModel' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'costModel' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EDPContract' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costModel' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'costAllocationSpans' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'commitment' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EDPValidationError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EdpContractCreateMutation, EdpContractCreateMutationVariables>;
export const EdpContractDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EDPContractDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identifier' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'identifier' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'identifier' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EDPContractMemento' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'identifier' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EdpContractDeleteMutation, EdpContractDeleteMutationVariables>;
export const EdpContractListingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EDPContractListing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contractStatus' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContractStatus' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listEDPContracts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contractStatus' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contractStatus' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EDPContractListing' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'contractId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'costModelContainer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'costModel' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'costAllocationSpans' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'commitment' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EdpContractListingQuery, EdpContractListingQueryVariables>;
export const EdpContractUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EDPContractUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identifier' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'costModel' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CostModelInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'identifier' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'identifier' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'costModel' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'costModel' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EDPContract' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'costModel' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'costAllocationSpans' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'commitment' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EDPValidationError' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EdpContractUpdateMutation, EdpContractUpdateMutationVariables>;
export const EdpSavingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EDPSavingsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'athenaViewQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'StringValue', value: 'cco_view_edp_cum_sum_spend_v1', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'StringValue', value: '{accountId}', block: false }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataTableResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'table' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'StringValue', value: 'edp_month_start', block: false },
                                { kind: 'StringValue', value: 'total_edp_discount', block: false },
                                { kind: 'StringValue', value: 'edp_discount_cumm_spend', block: false },
                                { kind: 'StringValue', value: 'zero_based_edp_discount_cumm_spend', block: false },
                                { kind: 'StringValue', value: 'is_forecast', block: false },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeferredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EdpSavingsQueryQuery, EdpSavingsQueryQueryVariables>;
export const EdpCumulativeSpendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EDPCumulativeSpend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'athenaViewQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'StringValue', value: 'cco_view_edp_cum_sum_spend_v1', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'StringValue', value: '{accountId}', block: false }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataTableResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'table' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'StringValue', value: 'edp_month_start', block: false },
                                { kind: 'StringValue', value: 'spend_current_month', block: false },
                                { kind: 'StringValue', value: 'forecasted_spend', block: false },
                                { kind: 'StringValue', value: 'cumulative_month_spend', block: false },
                                { kind: 'StringValue', value: 'cumulative_forecasted_spend', block: false },
                                { kind: 'StringValue', value: 'zero_based_cumulative_month_spend', block: false },
                                { kind: 'StringValue', value: 'is_forecast', block: false },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeferredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EdpCumulativeSpendQuery, EdpCumulativeSpendQueryVariables>;
export const SpendVsCommitmentChartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SpendVsCommitmentChart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'athenaViewQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'StringValue', value: 'cco_view_edp_cum_sum_spend_v1', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'StringValue', value: '{accountId}', block: false }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataTableResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'table' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'StringValue', value: 'edp_month_start', block: false },
                                { kind: 'StringValue', value: 'forecasted_spend', block: false },
                                { kind: 'StringValue', value: 'aws_infra_spend', block: false },
                                { kind: 'StringValue', value: 'eligible_marketplace_spend', block: false },
                                { kind: 'StringValue', value: 'ineligible_marketplace_spend', block: false },
                                { kind: 'StringValue', value: 'cumulative_month_spend', block: false },
                                { kind: 'StringValue', value: 'cumulative_forecasted_spend', block: false },
                                { kind: 'StringValue', value: 'is_forecast', block: false },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeferredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SpendVsCommitmentChartQuery, SpendVsCommitmentChartQueryVariables>;
export const UpdateRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRecommendations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recommendations' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecommendationSettings' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recommendations' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'recommendations' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PutEventDetails' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'entries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EventDetails' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'eventId' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRecommendationsMutation, UpdateRecommendationsMutationVariables>;
export const OptimizationsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OptimizationsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'optimizationsQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OptimizationsResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'summary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'recsBreaching' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recsCancelled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recsNonBreaching' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recsTotal' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'estimatedSavings' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'saving' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'breaching' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isObfuscated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'effort' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'products' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'proposedCost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'resourceCost' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'saving' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tests' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OptimizationsListQuery, OptimizationsListQueryVariables>;
export const TestResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TestResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monitoringLatestQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'code' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'monitoringType' },
                      value: { kind: 'EnumValue', value: 'TEST' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MonitoringResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'results' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ErroredQueryResult' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'MulticlassBinnedTestResult' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'summary' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TestResultSummaryFragment' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'BooleanTestResult' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'summary' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TestResultSummaryFragment' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'BinnedTestResult' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'summary' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TestResultSummaryFragment' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TestResultSummaryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TestResultSummary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tooltip' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'types' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TestResultsQuery, TestResultsQueryVariables>;
export const SpPurchaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SPPurchase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'purchases' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'SPPurchaseSavingsPlan' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buySavingsPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'purchases' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'purchases' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SavingsPlanPurchaseResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'offeringId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'purchaseExecutionTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SpPurchaseMutation, SpPurchaseMutationVariables>;
export const CancelSpReservationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelSPReservation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deletions' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'SPDeleteSavingsPlan' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteQueuedSavingsPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deletions' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'deletions' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SavingsPlanDeleteResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelSpReservationMutation, CancelSpReservationMutationVariables>;
export const MonitoringLatestQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MonitoringLatestQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monitoringLatestQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'monitoringType' },
                      value: { kind: 'EnumValue', value: 'CHECK' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'code' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'checkCode' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MonitoringResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'results' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'MonitoringValueCheckResult' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MonitoringLatestQueryQuery, MonitoringLatestQueryQueryVariables>;
export const SpPermissionCheckDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SPPermissionCheck' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SPRIAction' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spPurchasePermissionQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'action' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SPPurchasePermissionResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'allowed' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SpPermissionCheckQuery, SpPermissionCheckQueryVariables>;
export const ComputeSprioDailyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ComputeSPRIODaily' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'summaryQuery' },
            name: { kind: 'Name', value: 'monitoringLatestQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'monitoringType' },
                      value: { kind: 'EnumValue', value: 'CHECK' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'code' },
                      value: { kind: 'StringValue', value: 'COMPUTE_SP_COVERAGE_LAST_DAYS', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MonitoringResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'results' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'MonitoringValueCheckResult' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dailyUsageQuery' },
            name: { kind: 'Name', value: 'athenaViewQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'StringValue', value: 'cco_view_sprio_compute_daily_usage_v1', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'StringValue', value: '{accountId}', block: false }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataTableResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'table' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'StringValue', value: 'timestamp', block: false },
                                { kind: 'StringValue', value: 'ec2_covered_cost', block: false },
                                { kind: 'StringValue', value: 'ec2_on_demand_cost', block: false },
                                { kind: 'StringValue', value: 'lambda_sp_covered_cost', block: false },
                                { kind: 'StringValue', value: 'lambda_on_demand_cost', block: false },
                                { kind: 'StringValue', value: 'fargate_sp_covered_cost', block: false },
                                { kind: 'StringValue', value: 'fargate_on_demand_cost', block: false },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeferredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ComputeSprioDailyQuery, ComputeSprioDailyQueryVariables>;
export const SageMakerSprioDailyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SageMakerSPRIODaily' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'summaryQuery' },
            name: { kind: 'Name', value: 'monitoringLatestQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'monitoringType' },
                      value: { kind: 'EnumValue', value: 'CHECK' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'code' },
                      value: { kind: 'StringValue', value: 'SAGE_MAKER_COVERAGE_LAST_DAYS', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MonitoringResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'results' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'MonitoringValueCheckResult' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'dailyUsageQuery' },
            name: { kind: 'Name', value: 'athenaViewQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'params' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'accountId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'StringValue', value: 'cco_view_sprio_sage_maker_daily_usage_v1', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameters' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'StringValue', value: '{accountId}', block: false }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataTableResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'table' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'columns' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'StringValue', value: 'timestamp', block: false },
                                { kind: 'StringValue', value: 'sp_covered_cost', block: false },
                                { kind: 'StringValue', value: 'on_demand_cost', block: false },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dataTypes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ErroredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeferredQueryResult' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SageMakerSprioDailyQuery, SageMakerSprioDailyQueryVariables>;
