import { Stack } from '@mui/material';
import { FieldCardInfo } from './FieldCardInfo';
import { FieldCardActions } from './FieldCardActions';
import { DynamicFormField } from '../../../types';

export type FieldCardContentProps<F> = {
  field: F & { id: string };
};

const FieldCardContent = <F extends DynamicFormField>(props: FieldCardContentProps<F>) => {
  return (
    <Stack direction="row" justifyContent="space-between" gap={4}>
      <FieldCardInfo {...props} />
      <FieldCardActions {...props} />
    </Stack>
  );
};

export default FieldCardContent;
