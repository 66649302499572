import { GridFooterContainer } from '@mui/x-data-grid-pro';
import { Box, Stack } from '@mui/material';
import { Button } from '@verticeone/design-system';
import { AddOutlined } from '@mui/icons-material';
import React from 'react';
import { DesignSystemColor } from '@verticeone/design-system';

export type AddItemFooterProps = {
  disabled: boolean;
  label: string;
  onClick: () => void;
  color?: DesignSystemColor;
};

export const AddItemFooter = ({ disabled, label, onClick, color = 'primary' }: AddItemFooterProps) => (
  <GridFooterContainer>
    <Box pl={3}>
      <Button color={color} variant="plain" onClick={onClick} disabled={disabled} customized>
        <Stack direction="row" gap={1} alignItems="center">
          <AddOutlined sx={{ width: 16, height: 16 }} />
          {label}
        </Stack>
      </Button>
    </Box>
  </GridFooterContainer>
);
