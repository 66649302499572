import { DynamicFormField, DynamicFormSchema, DynamicFormPresenterConfig } from '../../types';
import { FieldValues } from 'react-hook-form';
import { fieldDefsByType } from '../../fields/fields';

const mapFieldToDefaultValue =
  (config: DynamicFormPresenterConfig, defaultValues?: FieldValues) => (dynamicField: DynamicFormField) => {
    const getPresenterDefault = fieldDefsByType[dynamicField.type]?.getPresenterDefault;
    return getPresenterDefault
      ? getPresenterDefault({
          field: dynamicField,
          defaultValues,
          config,
        })
      : {};
  };

export function createDefaultValues(
  form: DynamicFormSchema,
  config: DynamicFormPresenterConfig,
  defaultValues?: FieldValues
): FieldValues {
  const schemas = form.fields.map(mapFieldToDefaultValue(config, defaultValues));

  return schemas.reduce((prev, curr) => ({ ...prev, ...curr }), {});
}
