import { useCallback, useState } from 'react';
import { v1 as uuid } from 'uuid';
import { isNotNil } from '@verticeone/utils/validation';
import { ProductItem, Tier } from '../types';
import { orderBy } from 'lodash';

export const CUSTOM_PREFIX = 'custom';

export const definedTiers = [
  { id: 'predefined_advanced', label: 'Advanced' },
  { id: 'predefined_advanced_plus', label: 'Advanced Plus' },
  { id: 'predefined_advantage', label: 'Advantage' },
  { id: 'predefined_apps_for_business', label: 'Apps for Business' },
  { id: 'predefined_basic', label: 'Basic' },
  { id: 'predefined_bronze', label: 'Bronze' },
  { id: 'predefined_business', label: 'Business' },
  { id: 'predefined_business_asterisk', label: 'Business *' },
  { id: 'predefined_business_basic', label: 'Business Basic' },
  { id: 'predefined_business_critical', label: 'Business Critical' },
  { id: 'predefined_business_plus', label: 'Business Plus' },
  { id: 'predefined_business_premium', label: 'Business Premium' },
  { id: 'predefined_business_pro', label: 'Business Pro' },
  { id: 'predefined_business_standard', label: 'Business Standard' },
  { id: 'predefined_business_starter', label: 'Business Starter' },
  { id: 'predefined_career', label: 'Career' },
  { id: 'predefined_core', label: 'Core' },
  { id: 'predefined_corporate', label: 'Corporate' },
  { id: 'predefined_deluxe', label: 'Deluxe' },
  { id: 'predefined_developer', label: 'Developer' },
  { id: 'predefined_diamond', label: 'Diamond' },
  { id: 'predefined_e1', label: 'E1' },
  { id: 'predefined_e3', label: 'E3' },
  { id: 'predefined_e5', label: 'E5' },
  { id: 'predefined_elite', label: 'Elite' },
  { id: 'predefined_elite_plus', label: 'Elite+' },
  { id: 'predefined_enterprise', label: 'Enterprise' },
  { id: 'predefined_enterprise_essentials', label: 'Enterprise Essentials' },
  { id: 'predefined_enterprise_essentials_plus', label: 'Enterprise Essentials Plus' },
  { id: 'predefined_enterprise_grid', label: 'Enterprise Grid' },
  { id: 'predefined_enterprise_plus', label: 'Enterprise Plus' },
  { id: 'predefined_enterprise_pro', label: 'Enterprise Pro' },
  { id: 'predefined_enterprise_select', label: 'Enterprise Select' },
  { id: 'predefined_enterprise_standard', label: 'Enterprise Standard' },
  { id: 'predefined_essential', label: 'Essential' },
  { id: 'predefined_essentials', label: 'Essentials' },
  { id: 'predefined_essentials_starter', label: 'Essentials Starter' },
  { id: 'predefined_expert', label: 'Expert' },
  { id: 'predefined_f1', label: 'F1' },
  { id: 'predefined_f3', label: 'F3' },
  { id: 'predefined_families', label: 'Families' },
  { id: 'predefined_free', label: 'Free' },
  { id: 'predefined_gold', label: 'Gold' },
  { id: 'predefined_growth', label: 'Growth' },
  { id: 'predefined_guru', label: 'Guru' },
  { id: 'predefined_lite', label: 'Lite' },
  { id: 'predefined_none', label: 'None' },
  { id: 'predefined_organization', label: 'Organization' },
  { id: 'predefined_personal', label: 'Personal' },
  { id: 'predefined_personal_pro', label: 'Personal Pro' },
  { id: 'predefined_platinum', label: 'Platinum' },
  { id: 'predefined_plus', label: 'Plus' },
  { id: 'predefined_premier', label: 'Premier' },
  { id: 'predefined_premium', label: 'Premium' },
  { id: 'predefined_pro', label: 'Pro' },
  { id: 'predefined_professional', label: 'Professional' },
  { id: 'predefined_scale', label: 'Scale' },
  { id: 'predefined_silver', label: 'Silver' },
  { id: 'predefined_standard', label: 'Standard' },
  { id: 'predefined_starter', label: 'Starter' },
  { id: 'predefined_startup', label: 'Startup' },
  { id: 'predefined_team', label: 'Team' },
  { id: 'predefined_team_pro', label: 'Team Pro' },
  { id: 'predefined_teams', label: 'Teams' },
  { id: 'predefined_ultimate', label: 'Ultimate' },
  { id: 'predefined_unlimited', label: 'Unlimited' },
];

export type UseTiersProps = {
  selectedProducts: ProductItem[];
};

export type UseTiersReturn = {
  tiers: Tier[];
  addTier: (label: string) => Tier;
};

export const useTiers = ({ selectedProducts }: UseTiersProps): UseTiersReturn => {
  const extractedCustomTiers = selectedProducts
    .map((item) => item.licenseType)
    .filter(isNotNil)
    .filter((licenseType) => licenseType.id.startsWith(CUSTOM_PREFIX));
  const [customTiers, setCustomTiers] = useState<Tier[]>(extractedCustomTiers);

  const addTier = useCallback(
    (label: string) => {
      // Sanitize the label and check if it already exists
      const normalizedLabel = label.trim().replace(/\s\s+/g, ' ');
      const existingTier = customTiers.find((customTier) => customTier.label === normalizedLabel);

      if (existingTier) {
        return existingTier;
      } else {
        const newTier = { label: normalizedLabel, id: `${CUSTOM_PREFIX}_${uuid()}` };
        setCustomTiers([...customTiers, newTier]);
        return newTier;
      }
    },
    [customTiers]
  );

  return {
    tiers: orderBy([...definedTiers, ...customTiers], [(tier) => tier.label?.toLowerCase()]),
    addTier,
  };
};
