import { useState } from 'react';
import { Stack } from '@mui/material';
import { StageIndicator } from '@vertice/core/src/modules/saas/contract/components/StageIndicator/StageIndicator';
import { Button } from '@verticeone/design-system';
import { InvertedThemeProvider } from '@verticeone/design-system';
import { CardHeaderActions } from '@verticeone/design-system';
import { PROCEED_FULFILL_BODY, useFulfillUserTask } from '@vertice/core/src/hooks/workflows/useFulfillUserTask';
import { parseRequestRef, parseUserTaskRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useTranslation } from 'react-i18next';
import { useFirstActiveContractTaskForUser } from '@vertice/core/src/hooks/workflows/useFirstActiveContractTaskForUser';
import { useWaitForRequestStage } from '@vertice/core/src/modules/saas/contract/utils/useWaitForRequestStage';
import useInvalidateContracts from '@vertice/core/src/modules/saas/contract/hooks/useInvalidateContracts';
import { hasFeDeadlineOrRollingFrequency } from '@vertice/core/src/modules/saas/contract/components/Deadline/utils';
import DeadlineWithLabel from '@vertice/core/src/modules/saas/contract/components/Deadline/DeadlineWithLabel';
import SubmitButton from './SubmitButton';
import { useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import useFormUpdateActions from '@vertice/core/src/modules/saas/contract/hooks/useFormUpdateActions';
import { useUserContractPermissions } from '@vertice/core/src/modules/saas/contract/hooks/useUserContractPermissions';
import {
  ContractTab,
  useContractTabNavigationContext,
} from '@vertice/core/src/modules/saas/contract/ContractTabNavigationContext';

const EditButtons = () => {
  const { t } = useTranslation();
  const contractContext = useContractContext('DONT_REQUIRE_FETCHED');
  const { handleCancel } = useFormUpdateActions(contractContext);

  if (contractContext.editMode.isEditing) {
    return (
      <>
        <Button color="transparent" variant="outline" size="M" onClick={handleCancel}>
          {t('ENTITIES.CONTRACT.ACTIONS.CANCEL')}
        </Button>
        <SubmitButton color="transparent" />
      </>
    );
  }

  return (
    <InvertedThemeProvider>
      <Button variant="solid" color="neutral" size="M" onClick={contractContext.editMode.enable}>
        {t('ENTITIES.CONTRACT.ACTIONS.EDIT')}
      </Button>
    </InvertedThemeProvider>
  );
};

const PurchaseRenewalHeaderActions = () => {
  const { t } = useTranslation();
  const tabNavigationContext = useContractTabNavigationContext();
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const {
    fetchedContract: { contract },
    accessVia: { accountId },
  } = contractContext;

  const { userCanEditContract } = useUserContractPermissions(contract.record.contractId);

  const { firstTask, refetchTasks, isFetchingTasks } = useFirstActiveContractTaskForUser(
    accountId,
    contract.record.contractId
  );
  const waitForRequestStage = useWaitForRequestStage();

  const invalidateContracts = useInvalidateContracts();
  const { fulfillUserTask } = useFulfillUserTask();

  // UI status used for approval progress indication
  const [approvalStatus, setApprovalStatus] = useState<'IDLE' | 'APPROVING'>('IDLE');

  const isEditing = contractContext.editMode.isEditing;
  const showApprovalButtons = !isFetchingTasks && firstTask?.input?.stage === 'APPROVAL' && !isEditing;

  const handleApprove = () => {
    if (firstTask && !!contract.record.managedBy) {
      const { taskId, accountId: taskAccountId } = parseUserTaskRef(firstTask.ref);
      const { requestId } = parseRequestRef(contract.record.managedBy);
      fulfillUserTask(taskAccountId, taskId, PROCEED_FULFILL_BODY);
      setApprovalStatus('APPROVING');
      void waitForRequestStage(taskAccountId, requestId, 'CONTRACTING').then(() => {
        void refetchTasks();
        invalidateContracts();
      });
    }
  };

  const onSeeDocuments = () => {
    tabNavigationContext.setTabId(ContractTab.DOCUMENTS, { scrollIntoView: true });
  };

  return (
    <CardHeaderActions>
      <Stack direction="row" gap={5} alignItems="center">
        <StageIndicator contract={contract} />
        {hasFeDeadlineOrRollingFrequency(contract) && <DeadlineWithLabel contract={contract} />}

        <Stack gap={2} direction="row">
          {showApprovalButtons && (
            <>
              <Button color="transparent" variant="outline" onClick={onSeeDocuments}>
                {t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.SEE_DOCUMENTS_BUTTON')}
              </Button>
              <InvertedThemeProvider>
                <Button
                  color="tertiary"
                  variant="solid"
                  isLoading={approvalStatus === 'APPROVING'}
                  onClick={handleApprove}
                  disabled={approvalStatus !== 'IDLE'}
                >
                  {t('ENTITIES.CONTRACT.ACTIONS.APPROVE')}
                </Button>
              </InvertedThemeProvider>
            </>
          )}
          {userCanEditContract && <EditButtons />}
        </Stack>
      </Stack>
    </CardHeaderActions>
  );
};

export default PurchaseRenewalHeaderActions;
