import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionSummary, AccordionDetails } from '@verticeone/design-system';

const accordionProps = {
  defaultExpanded: true,
  variant: 'label' as const,
  size: 'XS' as const,
};

type CollapsibleSectionProps = PropsWithChildren & {
  titleTranslationKey: string;
};

export const CollapsibleSection: FC<CollapsibleSectionProps> = ({ titleTranslationKey, children }) => {
  const { t } = useTranslation();

  return (
    <Accordion {...accordionProps}>
      <AccordionSummary title={t(titleTranslationKey)} />
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
