import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShoppingCartOutlined } from '@mui/icons-material';

import { Button } from '@verticeone/design-system';
import { IconWrapper } from '@verticeone/design-system';

import { useShoppingCart } from '../../../../providers/ShoppingCartProvider';
import { OfferingData } from '../../../../../types';

type AddToCartButtonProps = {
  offering: OfferingData;
  tKey: string;
};

const AddToCartButton = ({ offering, tKey }: AddToCartButtonProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: tKey });
  const { addToCart, cartItems } = useShoppingCart();

  const cartItem = cartItems.find((item) => item.offeringId === offering.offeringId);

  return (
    <Button disabled={!!cartItem} variant="ghost" color="neutral" fullWidth onClick={() => addToCart(offering)}>
      {t('ACTIONS.ADD')}
      <IconWrapper icon={ShoppingCartOutlined} />
    </Button>
  );
};

export default AddToCartButton;
