import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system';
import { IntegrationSetupDialog } from 'pages/Preferences/Integrations/components/IntegrationSetupDialog';
import { useDeactivateIntegration } from '../hooks/useDeactivateIntegration';
import { JiraSetupStep, StepContentProps } from '../types';

type DisconnectButtonProps = StepContentProps;

export const DisconnectButton = ({ activeStep }: DisconnectButtonProps) => {
  const { t } = useTranslation();

  const [isDisconnectDialogOpened, setIsDisconnectDialogOpened] = useState(false);
  const { deactivateIntegration, isIntegrationDeactivationInProgress, isIntegrationRevokeInProgress } =
    useDeactivateIntegration();

  const isSuccessStep = activeStep === JiraSetupStep.SUCCESS;

  if (!isSuccessStep) return null;

  return (
    <>
      <Button variant="solid" size="M" color="neutral" onClick={() => setIsDisconnectDialogOpened(true)}>
        {t('PREFERENCES.INTEGRATIONS.JIRA.DISCONNECT_BUTTON')}
      </Button>
      <IntegrationSetupDialog
        header={t('PREFERENCES.INTEGRATIONS.JIRA.DISCONNECT_DIALOG.HEADER')}
        content={t('PREFERENCES.INTEGRATIONS.JIRA.DISCONNECT_DIALOG.CONTENT')}
        buttonLabel={t('PREFERENCES.INTEGRATIONS.JIRA.DISCONNECT_DIALOG.BUTTON')}
        isLoading={isIntegrationDeactivationInProgress || isIntegrationRevokeInProgress}
        isOpened={isDisconnectDialogOpened}
        onSubmit={deactivateIntegration}
        onClose={() => setIsDisconnectDialogOpened(false)}
      />
    </>
  );
};
