import React from 'react';
import { Stack } from '@mui/material';

import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { Loader } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';

import { useInstanceList } from './providers/InstanceListProvider';
import { useTranslation } from 'react-i18next';

type InstanceListProps = {
  isLoading: boolean;
  children: React.ReactNode;
};

const InstanceList = ({ isLoading, children }: InstanceListProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE' });
  const { pagination } = useInstanceList();

  if (isLoading) {
    return <Loader color={AWS_BRAND_COLOR} />;
  }

  return (
    <Stack gap={5}>
      <Stack gap={4}>{children}</Stack>
      <Stack display="inline-block" margin="auto">
        {pagination.isVisible && (
          <Button testId="load-more" color="tertiary" variant="solid" size="S" onClick={pagination.loadNextPage}>
            {t('LOAD_MORE')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default InstanceList;
