import { FC, useMemo, useState } from 'react';

import { VariableChip } from '../../../../VariableSelector/VariableChip';
import { VariablesDialog } from '../../../../VariableSelector/VariablesDialog';
import type { Property } from '../../../../types';
import { JiraIssueField } from '../../types';
import {
  transformJiraToWorkflowLikeVariable,
  transformJiraToWorkflowLikeVariables,
  transformWorkflowLikeToJiraVariable,
} from '../../utils';
import { useJiraVariablesGroups } from './useJiraVariableGroups';

type JiraVariableSelectorProps = {
  variables: JiraIssueField[];
  selectedVariable?: JiraIssueField;
  onSelectedVariableChange: (variable?: JiraIssueField) => void;
  isVariableTypeDisplayed?: boolean;
  isDisabled?: boolean;
  typeFilter?: string;
};

export const JiraVariableSelector: FC<JiraVariableSelectorProps> = ({
  variables,
  selectedVariable,
  onSelectedVariableChange,
  isVariableTypeDisplayed,
  isDisabled,
  typeFilter,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const selectedWorkflowLikeVariable = useMemo(
    () => transformJiraToWorkflowLikeVariable(selectedVariable),
    [selectedVariable]
  );

  const workflowLikeVariables = transformJiraToWorkflowLikeVariables(variables);

  const variablesGroups = useJiraVariablesGroups({ variables: workflowLikeVariables });

  const handleVariableClick = (variable: Property) => {
    onSelectedVariableChange(transformWorkflowLikeToJiraVariable(variable));
    setIsDialogOpen(false);
  };

  return (
    <>
      <VariableChip
        label={selectedWorkflowLikeVariable?.label}
        typeLabel={isVariableTypeDisplayed ? selectedWorkflowLikeVariable?.typeLabel?.[0] : undefined}
        onClick={() => setIsDialogOpen(true)}
        withIcon
        isDisabled={isDisabled}
      />
      <VariablesDialog
        variablesGroups={variablesGroups}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onVariableClick={handleVariableClick}
        typeFilter={typeFilter}
      />
    </>
  );
};
