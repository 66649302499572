import React from 'react';
import { Stack } from '@mui/material';
import { DeleteOutlined, SettingsOutlined } from '@mui/icons-material';
import { IconButton } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useDynamicFormBuilderContext } from '../../DynamicFormBuilderContextProvider';
import { DynamicFormField } from '../../../types';
import { RequiredSwitch } from './RequiredSwitch';
import { createUpdatedField } from '../../../fields/utils';

type FieldCardActionsProps<F> = {
  field: F & { id: string };
};

export const FieldCardActions = <F extends DynamicFormField>({ field }: FieldCardActionsProps<F>) => {
  const { t } = useTranslation();
  const { setDialogState, updateField, removeField } = useDynamicFormBuilderContext();

  const onUpdateClick = () => {
    const { id, ...fieldWithoutId } = field;
    setDialogState({
      mode: 'update',
      fieldType: field.type,
      defaultValues: fieldWithoutId,
      onSave: (values: F) => {
        updateField(field.id, createUpdatedField<F>(fieldWithoutId as unknown as F, values));
      },
    });
  };

  return (
    <Stack direction="row" gap={1} alignItems="center" flexShrink={0}>
      <RequiredSwitch field={field} />
      <IconButton
        icon={SettingsOutlined}
        size="S"
        color="neutral"
        variant="plain"
        onClick={onUpdateClick}
        aria-label={t('DYNAMIC_FORM_BUILDER.UPDATE_FIELD')}
      />
      <IconButton
        icon={DeleteOutlined}
        size="S"
        color="neutral"
        variant="plain"
        onClick={() => removeField(field.id)}
        aria-label={t('DYNAMIC_FORM_BUILDER.REMOVE_FIELD')}
      />
    </Stack>
  );
};
