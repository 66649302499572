import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, CircularProgress, Grid, MenuItem, Stack, styled, useTheme } from '@mui/material';
import {
  ExpandLessOutlined,
  ExpandMoreOutlined,
  TrendingDownTwoTone,
  TrendingFlatTwoTone,
  TrendingUpTwoTone,
} from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import FormSelectField from '@vertice/core/src/modules/forms/fields/FormSelectField';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { FormToggleButtonField } from '@vertice/core/src/modules/forms/fields/FormToggleButtonField';
import { Text } from '@verticeone/design-system';
import { IconButton } from '@verticeone/design-system';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons, Divider } from '@vertice/core/src/components/Dialog/Components';
import { IntakeProductList } from '../../SharedComponents/IntakeProductList';
import { SOONEST_TARGET_SIGN_DATE } from '../../constants';
import { useFormDepartments } from '../../sharedHooks/useFormDepartments';
import SelectedContractPanel from '../../SharedComponents/SelectedContractPanel';
import dayjs from 'dayjs';
import { SharedRenewalFormData, SharedRenewalFormEntry } from '../types';
import { isNil } from 'lodash';
import { currencies } from '@verticeone/utils/currency';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

const currenciesWithEmptyValue = ['', ...currencies];

const useUploadExistingContractWizard = () => {
  const wizard = useWizard();

  return {
    goToNext: () => wizard.goToStep('additional_requirements'),
  };
};

const StyledList = styled('ul')({
  paddingLeft: '16px',
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const ConfirmRenewalRequirements: React.FC<WizardStepProps> = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { isFeatureEnabled } = useAccountContext();
  const advancedCostModels = isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const toggleAccordion = useCallback(() => setAccordionExpanded((prev) => !prev), [setAccordionExpanded]);

  const { goToNext } = useUploadExistingContractWizard();

  const { trigger, watch, formState } = useFormContext<SharedRenewalFormData>();
  const formData = watch();
  const { contractCurrency, vendor, departmentId, products, existingContractId, renewalType } = formData;

  const departments = useFormDepartments({ setDefaultValue: false, onlyOwnedDepartments: false });

  const nextEnabled = useMemo(() => formState.isValid, [formState]);
  useEffect(() => {
    void trigger(['contractCurrency', 'renewalType']);
  }, [trigger, contractCurrency, renewalType]);

  if (!products || !existingContractId) {
    return (
      <Content>
        <Stack direction="row" justifyContent="center">
          <CircularProgress sx={{ width: '100%' }} />
        </Stack>
      </Content>
    );
  }

  const isAdditionalNotesRequired = renewalType === 'UPGRADE' || renewalType === 'DOWNGRADE';

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.EXISTING_CONTRACT_CONFIRM_RENEWAL_REQ.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_CONFIRM_RENEWAL_REQ.HEADING_SUBTITLE')}
      />
      <SelectedContractPanel />
      <InnerScrollable>
        <FormSection>
          {!isNil(vendor) && vendor.type !== 'INLINE' && (
            <Grid item xs={12}>
              <IntakeProductList />
            </Grid>
          )}
          <SharedRenewalFormEntry
            name="departmentId"
            label={t('ENTITIES.CONTRACT.LABELS.DEPARTMENT')}
            component={FormSelectField}
            disabled
            componentProps={{
              placeholder: t('ENTITIES.DEPARTMENT.PLACEHOLDERS.DEPARTMENT'),
            }}
          >
            {departments ? (
              departments.map((department) => (
                <MenuItem key={department.departmentId} value={department.departmentId}>
                  {department.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem key="loading" value={departmentId ?? ''} />
            )}
          </SharedRenewalFormEntry>
          <SharedRenewalFormEntry
            name="contractCurrency"
            required
            label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_CURRENCY')}
            component={FormSelectField}
          >
            {currenciesWithEmptyValue.map((c) => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
          </SharedRenewalFormEntry>
          <SharedRenewalFormEntry
            name="targetSignDate"
            tooltip={{
              title: t('ENTITIES.CONTRACT.TOOLTIPS.TARGET_SIGN_DATE'),
            }}
            label={t('ENTITIES.CONTRACT.LABELS.TARGET_SIGN_DATE')}
            component={FormDateField}
            componentProps={{
              minDate: SOONEST_TARGET_SIGN_DATE,
              validate: {
                isAfterOrExactly30DaysFromNow: (value) => {
                  if (value && dayjs(value).isBefore(SOONEST_TARGET_SIGN_DATE)) {
                    return t('ENTITIES.CONTRACT.TOOLTIPS.TARGET_SIGN_DATE');
                  }
                  return true;
                },
              },
            }}
            width={advancedCostModels ? 6 : 12}
          />
          <SharedRenewalFormEntry
            name="renewalType"
            label={t('ENTITIES.CONTRACT.LABELS.RENEWAL_TYPE')}
            width={12}
            component={FormToggleButtonField}
            required
            componentProps={{
              buttonHeight: 64,
              compact: true,
              options: [
                {
                  title: t('ENTITIES.CONTRACT.LABELS.RENEWAL_TYPE_UPGRADE'),
                  value: 'UPGRADE',
                  icon: TrendingUpTwoTone,
                },
                {
                  title: t('ENTITIES.CONTRACT.LABELS.RENEWAL_TYPE_FLAT'),
                  value: 'FLAT',
                  icon: TrendingFlatTwoTone,
                },
                {
                  title: t('ENTITIES.CONTRACT.LABELS.RENEWAL_TYPE_DOWNGRADE'),
                  value: 'DOWNGRADE',
                  icon: TrendingDownTwoTone,
                },
              ],
            }}
          />
          <Grid item xs={12}>
            <Box border="solid 1px" borderRadius={2} borderColor={palette.core.color3}>
              <Stack direction="row" alignItems="center">
                <Text variant="button-bold" size="M" padding={4} flexGrow={1}>
                  {t('INTAKE_FORM.EXISTING_CONTRACT_CONFIRM_RENEWAL_REQ.ACCORDION.LABEL')}
                </Text>
                <Box padding={2}>
                  <IconButton
                    size="M"
                    variant="ghost"
                    icon={accordionExpanded ? ExpandLessOutlined : ExpandMoreOutlined}
                    onClick={toggleAccordion}
                  />
                </Box>
              </Stack>
              {accordionExpanded && (
                <>
                  <Divider />
                  <Box padding={4}>
                    <StyledList>
                      {[1, 2, 3].map((i) => (
                        <li key={i}>
                          <Text variant="body-regular" size="M">
                            <Trans
                              i18nKey={`INTAKE_FORM.EXISTING_CONTRACT_CONFIRM_RENEWAL_REQ.ACCORDION.POINT_${i}`}
                              components={{ bold: <Text variant="body-bold" size="M" /> }}
                            />
                          </Text>
                        </li>
                      ))}
                    </StyledList>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <SharedRenewalFormEntry
            name="additionalNotes"
            label={t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_NOTES')}
            component={FormTextField}
            required={isAdditionalNotesRequired}
            width={12}
            componentProps={{
              placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.RENEWAL_ADDITIONAL_NOTES'),
              multiline: true,
              rows: 4,
            }}
          />
        </FormSection>
      </InnerScrollable>
      <Buttons
        primary={{
          onClick: goToNext,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !nextEnabled,
        }}
      />
    </Content>
  );
};

export default ConfirmRenewalRequirements;
