import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { GenericTaskIcon } from '../../../../../components/icons/Icons';
import { Placeholder } from '@verticeone/design-system';

type ActiveTasksProps = {
  count: number;
  isLoading?: boolean;
};

export const ActiveTasks: FC<ActiveTasksProps> = ({ count, isLoading = false }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS' });

  return (
    <Stack p={4} flex={1} direction="row" alignItems="center" gap={0.5}>
      <GenericTaskIcon />
      {isLoading ? (
        <Placeholder width={100} height={14} variant="rounded" />
      ) : (
        <Text variant="body-regular" size="S" color="text1">
          {t('ACTIVE_TASKS_COUNT', {
            count,
          })}
        </Text>
      )}
    </Stack>
  );
};
