import { PropsWithChildren, ReactNode, forwardRef, FC, ReactElement } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text, TextProps, Tooltip } from '@verticeone/design-system';

export const CardLine = forwardRef<HTMLDivElement, PropsWithChildren>((props, ref) => (
  <Stack direction="row" alignItems="center" gap={2} {...props} ref={ref} />
));

type CardValueProps = Omit<TextProps, 'variant' | 'size'> & {
  children: ReactNode;
};
export const CardValue = forwardRef<HTMLDivElement, CardValueProps>(
  ({ children, color = 'text2', ...restProps }, ref) => {
    return (
      <Text variant="body-bold" size="XS" color={color} {...restProps} ref={ref}>
        {children}
      </Text>
    );
  }
);

type CardTooltipProps = {
  children: ReactElement;
  translationKey: string;
};
export const CardTooltip: FC<CardTooltipProps> = ({ translationKey, children }) => {
  const { t } = useTranslation();
  return (
    <Tooltip content={t(translationKey)} size="S" minified placement="bottom">
      {children}
    </Tooltip>
  );
};
