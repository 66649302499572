import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyAllOutlined } from '@mui/icons-material';

import { IconButton } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { WorkflowVersionRow } from '../types';
import { useDuplicateWorkflowVersion } from './hooks/useDuplicateWorkflowVersion';

type DuplicateButtonProps = {
  row: WorkflowVersionRow;
};

export const DuplicateButton: FC<DuplicateButtonProps> = ({ row }) => {
  const { t } = useTranslation();

  const { duplicateWorkflow, isDuplicatingWorkflow } = useDuplicateWorkflowVersion(row);
  const isDisabled = isDuplicatingWorkflow;

  const Button = (
    <IconButton
      icon={CopyAllOutlined}
      variant="outline"
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      onClick={() => duplicateWorkflow()}
      isLoading={isDuplicatingWorkflow}
      disabled={isDisabled}
    />
  );

  // disabled button cannot have a tooltip
  return isDisabled ? (
    Button
  ) : (
    <Tooltip title={t('ENTITIES.WORKFLOW_VERSION.ACTIONS.DUPLICATE')} minified size="S">
      {Button}
    </Tooltip>
  );
};
