import { Text } from '@verticeone/design-system';
import { RequestPageCard } from './RequestPageCard';
import { Request } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

const Label = ({ variant }: { variant: 'createdBy' | 'renewedBy' | 'default' }) => {
  const { t } = useTranslation();

  if (variant === 'default') return null;

  return (
    <Text variant="body-bold" color="text1">
      {variant === 'createdBy' && t('ENTITIES.WORKFLOW_REQUEST.LABELS.CREATED_BY')}
      {variant === 'renewedBy' && t('ENTITIES.WORKFLOW_REQUEST.LABELS.RENEWED_BY')}
    </Text>
  );
};

export const RequestCardWithTitle = ({
  request,
  variant = 'default',
}: {
  request: Request;
  variant?: 'createdBy' | 'renewedBy' | 'default';
}) => (
  <Stack gap={2} key={request?.ref}>
    <Label variant={variant} />
    <RequestPageCard request={request!} />
  </Stack>
);
