import { z, ZodObject, ZodRawShape } from 'zod';
import { NumberFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';

export function getNumberPresenterSchema(field: NumberFieldType): ZodObject<ZodRawShape> {
  let schema = z.number();

  return z.object({
    [field.name]: field.metadata.required ? schema : schema.nullish(),
  });
}

export function getNumberPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<NumberFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
}

export function getNumberJsonSchema(field: NumberFieldType) {
  return {
    required: [field.name],
    properties: {
      [field.name]: {
        type: field.metadata.required ? 'number' : ['number', 'null'],
      },
    },
  };
}
