import { ElementType, FC } from 'react';
import { DateFieldType } from './types';
import { Stack } from '@mui/material';
import FormDateField from '../../../fields/FormDateField';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';

import { DynamicFormPresenterConfig } from '../../types';

type DateFieldPresenterProps = {
  field: DateFieldType;
  config: DynamicFormPresenterConfig;
};

export const DateFieldPresenter: FC<DateFieldPresenterProps> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, ElementType>
        name={field.name}
        component={FormDateField}
        description={description}
        label={label}
        required={required}
        disabled={config.mode === 'readOnly'}
      />
    </Stack>
  );
};
