import React, { useMemo } from 'react';
import { useAuthorizer } from '../AuthorizationContext';
import { LoadableComponent } from '@verticeone/design-system';
import { AuthRequestProps } from '../reducer';

type PassThroughProps = {
  passThrough: true;
  children: (isAllowed: boolean) => React.ReactNode;
};

type NormalProps = {
  passThrough?: false;
  children: React.ReactNode;
};

type BaseCanProps = {
  not?: boolean;
};

type SingleCan = {
  do: string;
  on: string;
};

type AnyCan = {
  anyOf: Array<SingleCan>;
};

type AllCan = {
  allOf: Array<SingleCan>;
};

type CanProps = BaseCanProps & (SingleCan | AnyCan | AllCan) & (NormalProps | PassThroughProps);

const transformToAuthorizeObject = (data: SingleCan | AnyCan | AllCan): AuthRequestProps | Array<AuthRequestProps> => {
  if ('anyOf' in data) {
    return data.anyOf.map((item) => ({ action: item.do, object: item.on, id: `${item.on}-${item.do}` }));
  }
  if ('allOf' in data) {
    return data.allOf.map((item) => ({ action: item.do, object: item.on, id: `${item.on}-${item.do}` }));
  }

  return {
    action: data.do,
    object: data.on,
    id: `${data.on}-${data.do}`,
  };
};

const Can = ({ children, passThrough, not = false, ...otherProps }: CanProps) => {
  const data = useAuthorizer([transformToAuthorizeObject(otherProps)].flat());
  const { isLoading, isAllowed } = useMemo(() => {
    if ('anyOf' in otherProps) {
      return {
        isAllowed: data.some((item) => item.isAllowed),
        isLoading: data.some((item) => item.isLoading),
      };
    }

    if ('allOf' in otherProps) {
      return {
        isAllowed: data.every((item) => item.isAllowed),
        isLoading: data.some((item) => item.isLoading),
      };
    }

    return {
      isAllowed: data.every((item) => item.isAllowed),
      isLoading: data.every((item) => item.isLoading),
    };
  }, [data, otherProps]);

  return (
    <LoadableComponent isLoading={isLoading}>
      {passThrough && children(not ? !isAllowed : isAllowed)}
      {!passThrough && (not ? !isAllowed : isAllowed) && children}
    </LoadableComponent>
  );
};

export default Can;
