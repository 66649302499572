import { createRequiredContext } from '@verticeone/design-system';
import { useCallback } from 'react';
import { getFullName as getFullNameFn } from '@verticeone/utils/formatting';
import { useRelevantUsersById, UsersByIdReturn } from '../hooks/useRelevantUsersById';

export type UsersContextType = {
  usersById: UsersByIdReturn;
  getFullName: (userId: string, usersById?: UsersByIdReturn) => string;
};

const { UsersContextProvider: RawUsersContextProvider, useUsersContext } = createRequiredContext<
  UsersContextType,
  'Users'
>('Users');

const UsersContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { usersById } = useRelevantUsersById({ skip: false });

  const getFullName = useCallback(
    (userId: string, additionalUsersById?: UsersByIdReturn) => {
      const user = usersById[userId] ?? additionalUsersById?.[userId];
      return getFullNameFn(user);
    },
    [usersById]
  );

  return <RawUsersContextProvider value={{ usersById, getFullName }}>{children}</RawUsersContextProvider>;
};

export { useUsersContext, UsersContextProvider };
