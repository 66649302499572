import { CardHeader } from '@verticeone/design-system';
import { CardHeaderProps } from '@verticeone/design-system';
import { styled } from '@mui/material';
import { FC } from 'react';
import { InvertedThemeProvider } from '@verticeone/design-system';
import { StyledCardHeaderTitleIcon } from '@verticeone/design-system';
import { StyledCardHeaderActions } from '@verticeone/design-system';

type HighlightedCardHeaderProps = CardHeaderProps & {
  height?: number | 'auto';
};

const StyledCardHeader = styled<FC<HighlightedCardHeaderProps>>(CardHeader)(({ theme: { palette }, height = 110 }) => ({
  background: palette.gradient.purpleToBlue,
  height,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  [`& ${StyledCardHeaderTitleIcon}`]: {
    backgroundColor: 'transparent',
    marginRight: 2,
    svg: {
      fill: 'white',
    },
  },
  [`& ${StyledCardHeaderActions}`]: {
    position: 'relative',
    top: 'unset',
    right: 'unset',
  },
}));

const HighlightedCardHeader: FC<HighlightedCardHeaderProps> = ({ children, ...restProps }) => {
  return (
    <StyledCardHeader {...restProps}>
      <InvertedThemeProvider>{children}</InvertedThemeProvider>
    </StyledCardHeader>
  );
};

export default HighlightedCardHeader;
