import { Stack } from '@mui/material';
import { ToggleSwitch } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { testProps } from '@verticeone/design-system';
import { useGraph } from '../providers/GraphProvider';
import { useTranslation } from 'react-i18next';

const GraphHeader = () => {
  const { isOnDemandUsageOnly, toggleOnDemandUsageOnly, forceOnDemandUsageOnly } = useGraph();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.GRAPH_HERDER' });
  const toggleOnDemandUsageOnlyId = 'toggle-on-demand-usage-only';

  return (
    <Stack direction="row" justifyContent="flex-end" px={4} pt={4}>
      <Stack gap={2} alignItems="center" direction="row">
        <ToggleSwitch
          id={toggleOnDemandUsageOnlyId}
          size="S"
          color="tertiary"
          checked={isOnDemandUsageOnly}
          onChange={toggleOnDemandUsageOnly}
          disabled={forceOnDemandUsageOnly}
          {...testProps(toggleOnDemandUsageOnlyId, 'switch')}
        />
        <Text
          sx={{
            cursor: 'pointer',
          }}
          variant="body-regular"
          tag="label"
          htmlFor={toggleOnDemandUsageOnlyId}
        >
          {t('TOGGLE')}
        </Text>
      </Stack>
    </Stack>
  );
};

export default GraphHeader;
