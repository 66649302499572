import { FC, useState } from 'react';

import type { Property } from '../../types';
import { VariableChip } from '../../VariableSelector/VariableChip';
import { VariablesDialog } from '../../VariableSelector/VariablesDialog';
import { useWorkflowVariablesGroups } from './useWorkflowVariablesGroups';

type WorkflowVariableSelectorProps = {
  globalUDFProperties: Property[];
  allRequestProperties: Property[];
  isDisabled?: boolean;
  selectedVariable?: Property;
  onSelectedVariableChange: (property: Property) => void;
  isVariableTypeDisplayed?: boolean;
  typeFilter?: string;
  positionFromTheRight?: number;
  addPropertyDefaults?: Pick<Property, 'origin' | 'type' | 'typeLabel'>;
  onAddLocalVariable?: (property: Property) => void;
};

export const WorkflowVariableSelector: FC<WorkflowVariableSelectorProps> = ({
  globalUDFProperties,
  allRequestProperties,
  isDisabled,
  selectedVariable,
  onSelectedVariableChange,
  isVariableTypeDisplayed = true,
  typeFilter,
  positionFromTheRight,
  addPropertyDefaults,
  onAddLocalVariable,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const variablesGroups = useWorkflowVariablesGroups({
    globalUDFProperties,
    allRequestProperties,
  });

  const handleVariableClick = (variable: Property) => {
    onSelectedVariableChange(variable);
    setIsDialogOpen(false);
  };

  return (
    <>
      <VariableChip
        label={selectedVariable?.label}
        typeLabel={isVariableTypeDisplayed ? selectedVariable?.typeLabel?.[0] : undefined}
        onClick={() => setIsDialogOpen(true)}
        withIcon
        isDisabled={isDisabled}
      />
      <VariablesDialog
        selectedPropertyId={selectedVariable?.id}
        variablesGroups={variablesGroups}
        typeFilter={typeFilter}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onVariableClick={handleVariableClick}
        positionFromTheRight={positionFromTheRight}
        addPropertyDefaults={addPropertyDefaults}
        onAddLocalVariable={onAddLocalVariable}
      />
    </>
  );
};
