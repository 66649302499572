import { ElementType, FC } from 'react';
import { CheckboxFieldType } from './types';
import { Stack } from '@mui/material';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';
import FormChecklistItemField, { FormChecklistItemFieldProps } from '../../../fields/FormChecklistItemField';

import { DynamicFormPresenterConfig } from '../../types';

type CheckboxFieldPresenterProps = {
  field: CheckboxFieldType;
  config: DynamicFormPresenterConfig;
};

export const CheckboxFieldPresenter: FC<CheckboxFieldPresenterProps> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, ElementType<FormChecklistItemFieldProps>>
        name={field.name}
        component={FormChecklistItemField}
        componentProps={{ label, size: 'S', description }}
        required={required}
        disabled={config.mode === 'readOnly'}
      />
    </Stack>
  );
};
