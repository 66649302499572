import { ReactNode, useState } from 'react';
import { SelectColor } from '@verticeone/design-system';
import { DynamicFormField } from '../types';
import { CustomFormBuilderProps, FieldDialogState } from './types';
import { FieldArrayPath, FieldValues, useFormContext } from 'react-hook-form';
import { useDynamicFormBuilderFields } from './hooks/useDynamicFormBuilderFields';
import { createRequiredContext } from '@verticeone/utils/contexts';

type DynamicFormBuilderContextProviderProps<FormDataType extends FieldValues = never> = {
  children: string | JSX.Element | JSX.Element[];
  fieldPath: FieldArrayPath<FormDataType>;
  color?: SelectColor;
  customFormBuilder?: (props: CustomFormBuilderProps) => ReactNode;
};

type DynamicFormBuilderContextType<F extends DynamicFormField> = {
  customFormBuilder: (props: CustomFormBuilderProps) => ReactNode;
  color: SelectColor;
  dialogState?: FieldDialogState<F>;
  setDialogState: (dialogState?: FieldDialogState<F>) => void;
  fields: (F & { id: string })[];
  addField: (newField: F) => void;
  updateField: (id: string, updatedField: F) => void;
  removeField: (id: string) => void;
};

const { DynamicFormBuilderContextProvider: RawDynamicFormBuilderContextProvider, useDynamicFormBuilderContext } =
  createRequiredContext<DynamicFormBuilderContextType<any>, 'DynamicFormBuilder'>('DynamicFormBuilder');

export { useDynamicFormBuilderContext };

export const DynamicFormBuilderContextProvider = <FormDataType extends FieldValues, F extends DynamicFormField>({
  children,
  fieldPath,
  color = 'primary',
  customFormBuilder = (props: CustomFormBuilderProps) => props.children,
}: DynamicFormBuilderContextProviderProps<FormDataType>) => {
  const { control } = useFormContext<FormDataType>();
  const { fields, addField, updateField, removeField } = useDynamicFormBuilderFields<FormDataType, F>({
    control,
    fieldPath,
  });

  const [dialogState, setDialogState] = useState<FieldDialogState<F>>();
  const context: DynamicFormBuilderContextType<F> = {
    customFormBuilder,
    color,
    dialogState,
    setDialogState,
    fields,
    addField,
    updateField,
    removeField,
  };

  return <RawDynamicFormBuilderContextProvider value={context}>{children}</RawDynamicFormBuilderContextProvider>;
};
