import { ZodObject, ZodRawShape, z } from 'zod';
import { ShortTextFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';

export function getShortTextPresenterSchema(field: ShortTextFieldType): ZodObject<ZodRawShape> {
  let schema = z.string();

  return z.object({
    [field.name]: field.metadata.required ? schema.min(1) : schema.optional(),
  });
}

export function getShortTextPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<ShortTextFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: '' };
}

export function getShortTextJsonSchema(field: ShortTextFieldType) {
  return {
    required: [field.name],
    properties: {
      [field.name]: {
        type: field.metadata.required ? 'string' : ['string', 'null'],
      },
    },
  };
}
