import { createRequiredContext } from '@verticeone/design-system';
import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { PredefinedWidgetDef } from './widgets/shared/types';

export type RequestContextData = {
  requestId: string;
  request?: Request;
  parentRequest?: Request;
  parentRequestPath?: string;
  isLoading: boolean;
  workflowVersion?: WorkflowVersion;
  isExecutedByWorkflow?: boolean;
  allowVerticeServiceNavigation?: boolean;
  /**
   * Additional widgets which could be rendered in the request detail page
   */
  additionalWidgets?: PredefinedWidgetDef[];
  applicationIdentifier: 'CFA' | 'IAT';
  sourceRef?: string;
  requestorRef?: string;
};

const { RequestContextProvider, useRequestContext } = createRequiredContext<RequestContextData, 'Request'>('Request');
export { RequestContextProvider, useRequestContext };
