import React, { FC, useState, ReactElement, cloneElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridValueGetterParams,
  GridRowParams,
  MuiEvent,
  GridEventListener,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ConfirmationDialog } from '@vertice/components';
import { GridIconButton } from '@verticeone/design-system';
import { FieldItem, FieldType } from './types';
import { randomId } from '@mui/x-data-grid-generator';
import { useCustomFieldsListContext } from './CustomFieldsListContextProvider';
import { normalizeFieldLabel } from './utils';
import useFieldTypes from './useFieldTypes';
import { isNotNil } from '@verticeone/utils/validation';
import { useSnackbar } from 'notistack';

interface WithActionsProps {
  children: ReactElement;
  excludeDeleteAction?: boolean;
}

type CannotPerformActionContext = {
  action?: string;
  reason?: string;
};

const WithActions: FC<WithActionsProps> = ({ children, excludeDeleteAction, ...rest }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { fields, isSaveInProgress, addField, updateField } = useCustomFieldsListContext();
  const [tmpFields, setTmpFields] = useState<FieldItem[]>([]);
  const fieldIds = fields?.map((field: FieldItem) => field.id) || [];
  const fieldsToUse = [...(fields || []), ...tmpFields.filter((field) => !fieldIds.includes(field.id))];
  const { availableFieldTypes } = useFieldTypes({ excluded: fields.map((field) => field.fieldType).filter(isNotNil) });

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [cannotPerformAction, setCannotPerformAction] = useState<CannotPerformActionContext | undefined>();

  const onAddRow = () => {
    const id = randomId();
    setTmpFields((oldRows) => [
      ...oldRows,
      { id, isVisible: true, fieldType: null, label: '', options: undefined, isNew: true },
    ]);
    setRowModesModel((currentModel) => ({
      ...currentModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'fieldType' },
    }));
  };

  const onRowEditStart = (params: GridRowParams, event: MuiEvent<SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const onRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (newRow.label) {
      const normalizedLabel = normalizeFieldLabel(newRow.label);
      const fieldData = {
        id: newRow.id,
        fieldType: newRow.fieldType,
        label: normalizedLabel,
        isVisible: newRow.isVisible,
        options: newRow.options,
      };

      if (newRow.isNew) {
        await addField(fieldData);
        enqueueSnackbar(t('ENTITIES.CONTRACT_CUSTOMIZATION.CUSTOM_FIELD_ADDED', { label: normalizedLabel }), {
          variant: 'success',
        });
      } else {
        await updateField(fieldData);
        enqueueSnackbar(t('ENTITIES.CONTRACT_CUSTOMIZATION.CUSTOM_FIELD_UPDATED', { label: normalizedLabel }), {
          variant: 'success',
        });
      }
    }
    setTmpFields([]);
    return newRow;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setTmpFields([]);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const ActionsCell = (params: GridValueGetterParams<FieldItem, FieldItem>) => {
    const apiRef = useGridApiContext();
    const isRowInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
    const isRowBeingProcessed = params.row.isBeingProcessed;
    const save = () => {
      const updatedRow = apiRef.current.getRowWithUpdatedValues(params.row.id, 'label');

      const fieldType = updatedRow.fieldType;
      if (!fieldType) {
        setCannotPerformAction({
          action: t('ENTITIES.CONTRACT_CUSTOMIZATION.CANNOT_PERFORM_ACTION_DIALOG.SAVE_FIELD'),
          reason: t('ENTITIES.CONTRACT_CUSTOMIZATION.CANNOT_PERFORM_ACTION_DIALOG.TYPE_MISSING'),
        });
        return;
      }

      const fieldLabel = normalizeFieldLabel(updatedRow.label);
      if (!fieldLabel) {
        setCannotPerformAction({
          action: t('ENTITIES.CONTRACT_CUSTOMIZATION.CANNOT_PERFORM_ACTION_DIALOG.SAVE_FIELD'),
          reason: t('ENTITIES.CONTRACT_CUSTOMIZATION.CANNOT_PERFORM_ACTION_DIALOG.LABEL_MISSING'),
        });
        return;
      }

      const isDropdown = fieldType.type === FieldType.DROPDOWN;
      const noOptions = !updatedRow.options || updatedRow.options.length === 0;
      if (isDropdown && noOptions) {
        setCannotPerformAction({
          action: t('ENTITIES.CONTRACT_CUSTOMIZATION.CANNOT_PERFORM_ACTION_DIALOG.SAVE_FIELD'),
          reason: t('ENTITIES.CONTRACT_CUSTOMIZATION.CANNOT_PERFORM_ACTION_DIALOG.DROPDOWN_OPTIONS_MISSING'),
        });
        return;
      }

      const labelExists = fields.some((field: FieldItem) => field.label === fieldLabel && field.id !== updatedRow.id);
      if (labelExists) {
        setCannotPerformAction({
          action: t('ENTITIES.CONTRACT_CUSTOMIZATION.CANNOT_PERFORM_ACTION_DIALOG.SAVE_FIELD'),
          reason: t('ENTITIES.CONTRACT_CUSTOMIZATION.CANNOT_PERFORM_ACTION_DIALOG.LABEL_DUPLICATED', {
            name: fieldLabel,
          }),
        });
        return;
      }

      setRowModesModel({ ...rowModesModel, [params.row.id]: { mode: GridRowModes.View } });
    };

    return (
      <Stack direction="row" alignItems="center" gap={1}>
        {isRowInEditMode ? (
          <>
            <GridIconButton
              variant="ghost"
              color="success"
              onClick={save}
              disabled={isSaveInProgress}
              icon={CheckOutlined}
              aria-label={t('ENTITIES.CONTRACT_CUSTOMIZATION.SAVE_CHANGES')}
            />
            <GridIconButton
              variant="ghost"
              color="error"
              onClick={handleCancelClick(params.row.id)}
              icon={CloseOutlined}
              aria-label={t('ENTITIES.CONTRACT_CUSTOMIZATION.DISCARD_CHANGES')}
            />
          </>
        ) : (
          <>
            <GridIconButton
              variant="ghost"
              onClick={handleEditClick(params.row.id)}
              icon={EditOutlined}
              disabled={isRowBeingProcessed}
              isLoading={isRowBeingProcessed}
              aria-label={t('ENTITIES.CONTRACT_CUSTOMIZATION.EDIT_FIELD')}
            />
          </>
        )}
      </Stack>
    );
  };

  const actionsColumn = {
    field: 'actions',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: ActionsCell,
    width: 84,
    align: 'center',
  };

  const addButtonDisabled = !!tmpFields.length || availableFieldTypes.length === 0;

  return (
    <>
      {cloneElement(children, {
        extraColumns: [actionsColumn],
        dataGridExtraProps: {
          rows: fieldsToUse,
          hideFooter: false,
          editMode: 'row',
          isCellEditable: () => true,
          rowModesModel,
          onRowEditStart,
          onRowEditStop,
          processRowUpdate,
          slotProps: {
            footer: { disabledButton: addButtonDisabled, onAddClick: onAddRow },
          },
        },
        ...rest,
      })}
      <ConfirmationDialog
        isOpen={!!cannotPerformAction}
        headerText={cannotPerformAction?.action}
        bodySecondaryText={cannotPerformAction?.reason}
        primaryButtonText={t('ENTITIES.CONTRACT_CUSTOMIZATION.CANNOT_PERFORM_ACTION_DIALOG.OK')}
        primaryButtonAction={() => setCannotPerformAction(undefined)}
      />
    </>
  );
};

export default WithActions;
