import { Select } from '@verticeone/design-system';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { FieldType } from '../../fields/types';
import { useTranslation } from 'react-i18next';
import { fieldDefs } from '../../fields/fields';
import { useDynamicFormBuilderContext } from '../DynamicFormBuilderContextProvider';

type FieldTypeItem = {
  label: string;
  type: FieldType;
};

type FieldTypeSelectProps = {
  onChange: (newValue?: FieldType) => void;
};

export const FieldTypeSelect = ({ onChange }: FieldTypeSelectProps) => {
  const { t } = useTranslation();
  const { color } = useDynamicFormBuilderContext();

  const fieldOptions = useMemo(
    () =>
      fieldDefs.map((field) => ({
        type: field.type,
        label: t(field.typeNameI18nKey),
      })),
    [t]
  );

  const onChangeHandler = (newValue: FieldTypeItem | null) => {
    onChange(newValue?.type);
  };

  return (
    <Box minWidth={256}>
      <Select<FieldTypeItem, false>
        variant="outlined"
        size="S"
        color={color}
        isClearable={false}
        options={fieldOptions}
        getOptionLabel={({ label }) => label}
        getOptionValue={({ type }) => type}
        placeholder={t('DYNAMIC_FORM_BUILDER.SELECT_FIELD_TYPE')}
        onChange={onChangeHandler}
        testId={'field-type-select'}
      />
    </Box>
  );
};
