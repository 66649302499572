import { FC } from 'react';
import type { FullOption } from 'react-querybuilder';
import { useGetServiceCatalogXTypeDatasourceQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { Select, TextField } from '@verticeone/design-system';

import { useAccountContext } from '../../../../../../../../contexts/AccountContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../constants';
import { Skeleton } from '@mui/material';

type DatasourceItem = {
  id: string | object;
  title: string;
};

type SimpleDatasourceItem = Omit<DatasourceItem, 'id'> & {
  id: string;
};

type XTypeDatasourceAsyncSelectProps = {
  xTypeUrn: string;
  onChange: (value?: string) => void;
  value?: string;
};

const isSimpleDatasourceItem = (item: DatasourceItem): item is SimpleDatasourceItem => {
  return typeof item.id === 'string';
};

export const XTypeDatasourceAsyncSelect: FC<XTypeDatasourceAsyncSelectProps> = ({ xTypeUrn, onChange, value }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading } = useGetServiceCatalogXTypeDatasourceQuery({
    resourceUrn: xTypeUrn,
    accountId,
  });

  if (isLoading) {
    return <Skeleton variant="rounded" height="90%" width="100%" />;
  }

  const options = (data?.items || []).filter(isSimpleDatasourceItem).map((item) => ({
    value: item.id,
    name: item.id,
    label: item.title,
  }));

  if (!options.length) {
    /*
     * This should never happen, but just in case adding TextField here as fallback.
     * When options are missing for XType it is still to be implemented on the BE side.
     */
    return (
      <TextField
        variant="outlined"
        size="S"
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        type="text"
      />
    );
  }

  const selectedOption = options?.find((option) => option.value === value);

  return (
    <Select<FullOption<string>, false>
      variant="outlined"
      size="S"
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      value={selectedOption}
      onChange={(newValue) => onChange(newValue?.value)}
      options={options}
    />
  );
};
