import { FieldType } from '../fields/types';
import { DynamicFormField } from '../types';
import { ReactNode } from 'react';

export enum FormBuilderMode {
  DEFAULT = 'Default',
  FIELD_TYPE_SELECT = 'FieldTypeSelect',
}

export type FieldDialogState<F extends DynamicFormField> = {
  mode: 'create' | 'update';
  fieldType: FieldType;
  defaultValues?: F;
  onSave?: (values: F) => void;
};

export type CustomFormBuilderProps = {
  fieldType: FieldType;
  children: ReactNode | null;
};
