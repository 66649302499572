import React, { ElementType } from 'react';
import FormEntry, {
  ComponentPropsMinusTheAutoFilledOnes,
  FormEntryProps,
} from '@vertice/core/src/modules/forms/fields/FormEntry';
import { DynamicFormField } from '../../../types';

export type FieldDialogFormEntryProps<Component extends ElementType> = FormEntryProps<DynamicFormField, Component> & {
  component?: Component;
  componentProps?: ComponentPropsMinusTheAutoFilledOnes<Component>;
};

export const FieldDialogFormEntry = <Component extends ElementType>({
  name,
  component,
  componentProps,
  disabled = false,
  ...otherProps
}: FieldDialogFormEntryProps<Component>) => {
  return (
    <FormEntry<DynamicFormField, Component>
      name={name}
      component={component}
      componentProps={componentProps!}
      disabled={disabled}
      {...otherProps}
    />
  );
};
