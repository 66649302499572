import { ElementType, FC } from 'react';
import { MoneyFieldType } from './types';
import { Stack } from '@mui/material';
import { FormMoneyField } from '../../../fields/FormMoneyField';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';

import { DynamicFormPresenterConfig } from '../../types';

type MoneyFieldPresenterProps = {
  field: MoneyFieldType;
  config: DynamicFormPresenterConfig;
};

export const MoneyFieldPresenter: FC<MoneyFieldPresenterProps> = (props) => {
  const { field, config } = props;
  const { label, required } = field.metadata;

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, ElementType>
        name={field.name}
        component={FormMoneyField}
        label={label}
        required={required}
        disabled={config.mode === 'readOnly'}
        componentProps={{
          allowsFloat: true,
        }}
      />
    </Stack>
  );
};
