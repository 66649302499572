import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useHierarchyGraphData from './useHierarchyGraphData';
import HierarchyGraph from './HierarchyGraph';
import { Card, CardHeader, CardHeaderDescription, CardHeaderTitle } from '@verticeone/design-system';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';

const CloudHierarchyCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { data, ...loadingStatus } = useHierarchyGraphData();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('HIERARCHY.TITLE')} tag="h2" />
        <CardHeaderDescription>{t('HIERARCHY.DESCRIPTION')}</CardHeaderDescription>
      </CardHeader>
      <Box bgcolor={palette.core.color1}>
        <LoadableContentWrapper {...loadingStatus}>
          <HierarchyGraph data={data!} />
        </LoadableContentWrapper>
      </Box>
    </Card>
  );
};

export default CloudHierarchyCard;
