import React from 'react';
import { Stack, styled } from '@mui/material';

import { CardHeader } from '@verticeone/design-system';
import { ifExpression } from '@verticeone/design-system';

import { useInstanceData } from './providers/InstanceProvider';

type OptimizeCardHeaderProps = {
  children: React.ReactNode;
};

type WithIsActiveProp = {
  $isActive: boolean;
};

const StyledCardHeader = styled(CardHeader)<WithIsActiveProp>(({ theme, $isActive }) => ({
  ...ifExpression($isActive, {
    backgroundColor: theme.palette.tertiary.color4,
  }),
}));

const OptimizeCardHeader = ({ children }: OptimizeCardHeaderProps) => {
  const { managedByVertice } = useInstanceData();

  return (
    <StyledCardHeader size="S" density="medium" $isActive={managedByVertice.isActive}>
      <Stack direction="row" justifyContent="space-between">
        {children}
        {/* Out of scope, will be in next PR
        <Stack direction="row" gap={4}>
          <Stack direction="row" gap={2} alignItems="center">
            <CloudIcon />
            <Text size="S" color="tertiary1" variant="body-bold">
              Active for 8 Months
            </Text>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <ToggleSwitch color="tertiary" checked={isActive} onChange={() => setIsActive((prev) => !prev)} />
            <Text size="S" color={isActive ? 'tertiary1' : 'neutral1'} variant="body-bold">
              Managed by Vertice
            </Text>
          </Stack>
        </Stack>
        */}
      </Stack>
    </StyledCardHeader>
  );
};

export default OptimizeCardHeader;
