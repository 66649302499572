import { Button, ButtonProps } from '@verticeone/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-hook-form';
import { ContractFormData } from '@vertice/core/src/modules/saas/contract/types';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';

// We keep this as a separate component, because it subscribes to the whole formState and we don't want the root
// useForm to subscribe to it (it would cause re-renders of the whole form)
const SubmitButton = (propsOverride: ButtonProps) => {
  const { t } = useTranslation();
  const formState = useFormState<ContractFormData>();
  const submitButtonProps = getPropsForSubmitButton(formState);
  return (
    <Button
      type="submit"
      color="primary"
      variant="solid"
      isLoading={submitButtonProps.isLoading}
      disabled={submitButtonProps.disabled}
      {...propsOverride}
    >
      {t('ENTITIES.CONTRACT.ACTIONS.SAVE')}
    </Button>
  );
};

export default SubmitButton;
