import { Definitions, Task, TaskDefinition } from '../../../../definitionsTypes';
import { isProcessDefinition } from '@vertice/core/src/modules/intelligentWorkflows/pocWorkflowSchema';
import { useTranslation } from 'react-i18next';
import { getFullName } from '@verticeone/utils/formatting';
import { parseUserRef } from '../../../../../../hooks/workflows/refUtils';
import { isAssignmentConfiguration } from '../../../../definitions/taskDefinition';
import { EXPRESSION_ALIASES } from '../../../../task/ExpressionAliases';
import { useCallback } from 'react';
import { UsersByIdReturn } from '../../../../../../hooks/useRelevantUsersById';

export const findTaskDefinition = (
  definitions: Definitions | undefined,
  taskId: string
): TaskDefinition | undefined => {
  if (!definitions) {
    return undefined;
  }

  const processDefinition = definitions.definitions.find(isProcessDefinition);

  if (!processDefinition) {
    return undefined;
  }

  return processDefinition.process.tasks?.find((task) => task.task.id.includes(taskId));
};

export const useTaskDefinitionAssigneeNames = () => {
  const { t } = useTranslation();

  const getTaskDefinitionAssigneeNames = useCallback(
    (task: Task | undefined, usersById: UsersByIdReturn): string[] | null => {
      if (!task) {
        return null;
      }

      const assignmentConfigurations = task.configurations?.filter(isAssignmentConfiguration) ?? [];

      if (!assignmentConfigurations) {
        return null;
      }

      const result: string[] = [];

      assignmentConfigurations.forEach((assignmentConfiguration) => {
        if (assignmentConfiguration.type === 'REQUEST_OWNER') {
          result.push(t(`INTELLIGENT_WORKFLOWS.WORKFLOW_SCHEMA.DYNAMIC_USERS.REQUEST_OWNER`));
        } else if (assignmentConfiguration.type === 'EXPRESSION') {
          assignmentConfiguration.assignment?.forEach((a) => {
            const expressionAlias = EXPRESSION_ALIASES.find(
              ({ assignmentItem: aliasAssignment }) => aliasAssignment === a
            );
            if (expressionAlias) {
              result.push(t(`INTELLIGENT_WORKFLOWS.WORKFLOW_SCHEMA.DYNAMIC_USERS.${expressionAlias.code}`));
            } else {
              result.push(t('ENTITIES.WORKFLOW_TASK.LABELS.BY_EXPRESSION'));
            }
          });
        } else if (assignmentConfiguration.type === 'STATIC') {
          result.push(
            ...(assignmentConfiguration.assignment?.map(
              (assignee: string) => getFullName(usersById[parseUserRef(assignee).userId]) || assignee
            ) || [])
          );
        }
      });

      return result.sort((a, b) => a.localeCompare(b));
    },
    [t]
  );

  return { getTaskDefinitionAssigneeNames };
};
