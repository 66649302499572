import React from 'react';
import useOptimizations from '../dataSource/useOptimizations';

type LoadableOptimizationProps = {
  children: React.ReactNode;
};

const LoadableOptimization = ({ children }: LoadableOptimizationProps) => {
  const { isFetching } = useOptimizations();

  if (!isFetching) {
    return <>{children}</>;
  }
};

export default LoadableOptimization;
