import { DataGridColDef } from '@verticeone/design-system';

export type ProductItem = {
  id: string;
  name: string;
  productId: string;
  annualCost?: number | null;
  numberOfLicences?: number | null;
  licenseType?: Tier | null;
};

export type Tier = {
  id: string;
  label?: string | null;
};

export enum ProductListColumn {
  LICENSE_TYPE = 'licenseType',
  ANNUAL_COST = 'annualCost',
  NUMBER_OF_LICENSES = 'numberOfLicences',
}

export type ProductColDef = DataGridColDef<ProductItem>;

export type ReducedColor = 'primary' | 'secondary' | 'tertiary' | 'neutral';
