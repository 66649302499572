import { createContext, useContext, useState, PropsWithChildren } from 'react';

type GraphContextType = {
  isOnDemandUsageOnly: boolean;
  toggleOnDemandUsageOnly: () => void;
  shoppingCartUnits?: number;
  forceOnDemandUsageOnly: boolean;
};

const GraphContext = createContext<GraphContextType | undefined>(undefined);

type GraphProviderProps = PropsWithChildren & {
  shoppingCartUnits?: number;
  forceOnDemandUsageOnly?: boolean;
};

export const GraphProvider = ({ children, shoppingCartUnits, forceOnDemandUsageOnly = false }: GraphProviderProps) => {
  const [isOnDemandUsageOnly, setIsOnDemandUsageOnly] = useState(forceOnDemandUsageOnly);

  const toggleOnDemandUsageOnly = () => {
    setIsOnDemandUsageOnly((prev) => !prev);
  };

  return (
    <GraphContext.Provider
      value={{ isOnDemandUsageOnly, toggleOnDemandUsageOnly, shoppingCartUnits, forceOnDemandUsageOnly }}
    >
      {children}
    </GraphContext.Provider>
  );
};

export const useGraph = () => {
  const context = useContext(GraphContext);
  if (!context) {
    throw new Error('useGraph must be used within an GraphProvider');
  }
  return context;
};
