import { ElementType, FC } from 'react';
import { ShortTextFieldType } from './types';
import { Stack } from '@mui/material';
import FormTextField from '../../../fields/FormTextField';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';

import { DynamicFormPresenterConfig } from '../../types';

type ShortTextFieldPresenterProps = {
  field: ShortTextFieldType;
  config: DynamicFormPresenterConfig;
};

export const ShortTextFieldPresenter: FC<ShortTextFieldPresenterProps> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, ElementType>
        name={field.name}
        component={FormTextField}
        label={label}
        description={description}
        required={required}
        disabled={config.mode === 'readOnly'}
      />
    </Stack>
  );
};
