import type { VerticeFormConfiguration } from '../../definitionsTypes';
import { parseFormUrn } from './forms/GenericForm/utils/createNewGenericFormUrn';
import {
  ServiceCatalogResource,
  useGetServiceCatalogResourceQuery,
} from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { isGenericFormUrn } from './forms/EditUserTaskForm/utils';

export type UseFormDefinitionForTaskParams = {
  formConfiguration?: VerticeFormConfiguration;
};

const useGenericFormDefinition = ({
  formConfiguration,
}: UseFormDefinitionForTaskParams): ServiceCatalogResource | undefined => {
  const isGenericForm = formConfiguration ? isGenericFormUrn(formConfiguration?.formUrn) : false;
  const { accountId } = formConfiguration ? parseFormUrn(formConfiguration.formUrn) : { accountId: null };

  const { data: formDefinition } = useGetServiceCatalogResourceQuery(
    {
      accountId: accountId ?? 'GLOBAL',
      resourceUrn: formConfiguration!.formUrn,
    },
    { skip: !formConfiguration || !isGenericForm }
  );

  return formDefinition;
};

export default useGenericFormDefinition;
