import { Text } from '@verticeone/design-system';
import Deadline from './Deadline';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isPlannedForExpiration } from '../../computed';

export type DeadlineWithLabelProps = {
  contract: Contract;
};

const DeadlineWithLabel = ({ contract }: DeadlineWithLabelProps) => {
  const { t } = useTranslation();
  return (
    <Stack gap={0.5}>
      <Text variant="label" size="XS" color="text1">
        {t(
          isPlannedForExpiration(contract) ? 'ENTITIES.CONTRACT.LABELS.EXPIRES_ON' : 'ENTITIES.CONTRACT.LABELS.DEADLINE'
        )}
      </Text>
      <Deadline contract={contract} />
    </Stack>
  );
};

export default DeadlineWithLabel;
