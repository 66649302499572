import { useMemo } from 'react';
import { useCloudContext } from '../../../../modules/cloud/CloudContext';

const useDataVisibility = () => {
  const { subFeatures } = useCloudContext();
  const canViewFullVersion = useMemo(() => !subFeatures.activeSpendAudit, [subFeatures]);

  return { canViewFullVersion };
};

export default useDataVisibility;
