/* eslint-disable react/no-this-in-sfc */
import React, { useMemo, useRef } from 'react';
import Highcharts, { PointOptionsObject, SeriesPieDataLabelsOptionsObject, SeriesPieOptions } from 'highcharts';
import injectHighchartsTrendline from 'highcharts/indicators/trendline';
import injectHighchartsIndicators from 'highcharts/indicators/indicators';
import injectHighchartsDrilldown from 'highcharts/modules/drilldown';
import injectHighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { Box, Fade, Stack, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { pieChartLabelFormatter } from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useDivergentColorPalette } from '@verticeone/design-system';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { SpendByServiceLineData } from './useSpendByServiceLineData';
import { Point, WithDrilldown } from '@vertice/core/src/components/charts/chart-lib-agnostic/types';
import useResponsivePieSize from '@vertice/core/src/components/charts/highcharts-specific/plugins/useResponsivePieSize';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import getLargestNPoints from '@vertice/core/src/components/charts/chart-lib-agnostic/utils/getLargestNPoints';
import { useGetColoredItems } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import { useTranslation } from 'react-i18next';
import { useWatchDrilldownSerieId } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useWatchDrilldownSerieId';
import { PointWithState } from '@vertice/core/src/components/charts/highcharts-specific/types';
import getHighchartsSerieAndDrilldown from '@vertice/core/src/components/charts/highcharts-specific/utils/getHighchartsSerieAndDrilldown';
import extractLegendItemsFromPoints from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromPoints';
import TopSmallNonClickableCaption from '@vertice/core/src/components/charts/components/TopSmallNonClickableCaption';
import { AWS_DEFAULT_CURRENCY_DECIMAL_PLACES } from '../../constants';

injectHighchartsMore(Highcharts);
injectHighchartsIndicators(Highcharts);
injectHighchartsTrendline(Highcharts);
injectHighchartsDrilldown(Highcharts);

type SpendByServiceLineProps = {
  data: SpendByServiceLineData;
};

// When you redraw a chart with an opened drilldown (say because of useResponsivePieSize's window resize),
// the drilldown gets broken (bug in Highcharts). The following parameters prevent that:
const HIGHCHARTS_UPDATE_ARGS = [/* redraw */ false, /* oneToOne */ false, /* animation */ false] as const;

const SpendByServiceLineGraph = ({ data: { costByServiceLine, currency } }: SpendByServiceLineProps) => {
  const { palette, typography } = useTheme();
  const { locale } = useLocaleContext();
  const { t } = useTranslation();

  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const graphColors = useDivergentColorPalette();
  const getColoredItems = useGetColoredItems();

  const labelFormatter = useMemo(
    () => pieChartLabelFormatter(palette, typography, locale, currency, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES),
    [currency, locale, palette, typography]
  );

  const applyStyledHighcharts = useStyledHighcharts();
  const applyResponsivePieSize = useResponsivePieSize({ containerRef });
  const { applyWatchDrilldownSerieId, drilldownSerieId } = useWatchDrilldownSerieId();

  const dataLabelsOptions: SeriesPieDataLabelsOptionsObject = useMemo(
    () => ({
      enabled: true,
      crop: false,
      y: -10,
      useHTML: true,
      connectorPadding: 5,
      padding: 5,
      style: {
        textAlign: 'center',
      },
      formatter() {
        return labelFormatter(this.point as PointWithState);
      },
    }),
    [labelFormatter]
  );

  // All source data, limited, colored, drilldowns included
  const { mainSeriePoints, drilldownSeries } = useMemo(() => {
    const largestNPoints = getLargestNPoints(costByServiceLine, graphColors.length, t('CHARTS.OTHER'), (p) => p);
    const mainAndDrilldownSeries = getHighchartsSerieAndDrilldown<
      WithDrilldown<Point>,
      SeriesPieOptions & { data?: PointOptionsObject[] }
    >(
      largestNPoints,
      ({ name, y }) => ({ name, y }),
      (p) => ({ type: 'pie', dataLabels: dataLabelsOptions })
    );

    return {
      mainSeriePoints: getColoredItems(mainAndDrilldownSeries.mainSeriePoints),
      drilldownSeries: mainAndDrilldownSeries.drilldownSeries.map((s) => ({
        ...s,
        data: getColoredItems(s.data ?? []),
      })),
    };
  }, [t, costByServiceLine, dataLabelsOptions, getColoredItems, graphColors.length]);

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyResponsivePieSize,
        applyWatchDrilldownSerieId,
        mergeOptions({
          chart: {
            type: 'pie',
            plotBackgroundColor: palette.core.bg,
            backgroundColor: palette.core.bg,
            plotBorderWidth: 0,
            height: 366,
            spacing: [24, 0, 36, 0], // custom data labels need more space on top and bottom to fit in the chart area
          },
          tooltip: { enabled: false },
          plotOptions: {
            series: {
              point: {
                events: {
                  mouseOver() {
                    const point = this;
                    point.series.points.forEach((p: any) => {
                      if (p === point) {
                        p.dataLabel?.attr({ text: labelFormatter(p) });
                      } else {
                        p.setState('inactive');
                      }
                    });
                  },
                  mouseOut() {
                    const point = this;
                    point.series.points.forEach((p: any) => {
                      if (p === point) {
                        p.setState('normal');
                        p.dataLabel?.attr({ text: labelFormatter(p) });
                      }
                    });
                  },
                },
              },
            },
          },
          series: [
            {
              id: 'costByServiceLine',
              type: 'pie',
              data: mainSeriePoints,
              dataLabels: dataLabelsOptions,
            },
          ],
          drilldown: {
            series: drilldownSeries,
          },
        }),
      ]),
    [
      mainSeriePoints,
      drilldownSeries,
      dataLabelsOptions,
      applyResponsivePieSize,
      applyStyledHighcharts,
      applyWatchDrilldownSerieId,
      labelFormatter,
      palette,
    ]
  );

  return (
    <Stack ref={containerRef} px={6}>
      <Box position="relative">
        <HighchartsReact highcharts={Highcharts} options={options} updateArgs={HIGHCHARTS_UPDATE_ARGS} />
        <Fade in={!drilldownSerieId}>
          <TopSmallNonClickableCaption>{t('CHARTS.CLICK_TO_DRILLDOWN')}</TopSmallNonClickableCaption>
        </Fade>
      </Box>
      <Legend
        items={extractLegendItemsFromPoints(
          (drilldownSerieId ? drilldownSeries.find(({ id }) => id === drilldownSerieId)?.data : mainSeriePoints) ?? []
        )}
      />
    </Stack>
  );
};

export default SpendByServiceLineGraph;
