import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system';
import { Link, Link as RouterLink } from 'react-router-dom';
import { Text } from '@verticeone/design-system';
import VendorLogo from '@vertice/core/src/components/VendorLogo/VendorLogo';
import { Skeleton, useTheme } from '@mui/material';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { Button } from '@verticeone/design-system';
import { useListVendorProductsQuery } from '@vertice/slices';
import useBenchmarking from '../../modules/saas/vendor/hooks/useBenchmarking';
import { RequestBenchmarkingDataButton } from '../../modules/saas/vendor/buttons/RequestBenchmarkingDataButton';
import { RequestPurchaseButton } from '../../modules/saas/vendor/buttons/RequestPurchaseButton';
import useIntakeForm from '../../hooks/useIntakeForm';

export type VendorPageHeaderProps = {
  vendorId: string;
};

const VendorPageHeader = ({ vendorId }: VendorPageHeaderProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();
  const { isLoading: isIntakeFormLoading, userCanAddExistingContract } = useIntakeForm();
  const { benchmarkingStage0 } = useBenchmarking();

  const { data: vendorData, isLoading: isLoadingVendorData } = useListVendorProductsQuery({
    vendorId,
    benchmarkedOnly: !!benchmarkingStage0,
  });
  const numberOfProducts = vendorData?.products?.length ?? 0;
  const vendorName = vendorData?.vendor?.name;
  const vendorCategory = vendorData?.vendor?.category;

  const showAddExistingButton = !isLoadingVendorData && !isIntakeFormLoading && userCanAddExistingContract;
  const showBenchmarkingButton = benchmarkingStage0 && !isLoadingVendorData && numberOfProducts === 0;
  const showPurchaseButton = !isLoadingVendorData && !isIntakeFormLoading;

  return (
    <>
      <Stack gap={1} p={8}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('SAAS.NAVBAR.SAAS')}</BreadcrumbLink>
          <BreadcrumbLink component={RouterLink} to={generatePath(routes.VENDORS.PATH)}>
            {t('SAAS.NAVBAR.VENDORS')}
          </BreadcrumbLink>
          <BreadcrumbLink disabled>{vendorName ?? <Skeleton width="6em" />}</BreadcrumbLink>
        </Breadcrumbs>

        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
          <Stack direction="row" alignItems="center" gap={2}>
            <VendorLogo vendorId={vendorId} size={48} bg={palette.core.bg} />
            <Stack>
              {vendorName ? (
                <Text variant="heading" size="M" tag="h1">
                  {vendorName}
                </Text>
              ) : (
                <Skeleton width={150} height={30} />
              )}
              {vendorCategory ? (
                <Text variant="body-regular" size="M" color="text3">
                  {vendorCategory}
                </Text>
              ) : (
                <Skeleton width={80} />
              )}
            </Stack>
          </Stack>
          <Stack direction="row" gap={2}>
            {showAddExistingButton && (
              <Button
                component={Link}
                to={generatePath(routes.VENDORS.VENDOR.ADD_EXISTING.ABSOLUTE_PATH, { id: vendorId })}
                variant="outline"
              >
                {t('VENDOR.ADD_EXISTING')}
              </Button>
            )}
            {showBenchmarkingButton && <RequestBenchmarkingDataButton vendorId={vendorId} buttonSize="M" />}
            {showPurchaseButton && <RequestPurchaseButton vendorId={vendorId} vendorName={vendorName} />}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default VendorPageHeader;
