import React, { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Link, Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { IntegrationAccordion, IntegrationInput } from '@vertice/components';
import { API_URLS } from '@vertice/slices';
import { Button } from '@verticeone/design-system';
import { AWS_STEPS, AWSStepProps } from '../AWSSetupWizard';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import { splitByWords } from 'pages/Preferences/Integrations/utils';
import styles from './AWSStep.module.scss';
import useAWSTestIntegration from './useAWSTestIntegration';

interface AWSCostAndUsageDetailsProps extends AWSStepProps {
  reportName: string;
  s3BucketName: string;
  roleArn: string;
  setReportName: (value: string) => void;
  sets3BucketName: (value: string) => void;
  setRoleArn: (value: string) => void;
}

const AWSCostAndUsageDetails = ({
  setStep,
  reportName,
  s3BucketName,
  roleArn,
  setReportName,
  sets3BucketName,
  setRoleArn,
}: AWSCostAndUsageDetailsProps) => {
  const { t } = useTranslation();

  const [checkingParams, setCheckingParams] = useState(false);

  const onRoleArnChange = (e: ChangeEvent<HTMLInputElement>) => setRoleArn(e.target.value);
  const onReportNameChange = (e: ChangeEvent<HTMLInputElement>) => setReportName(e.target.value);
  const ons3BucketNameChange = (e: ChangeEvent<HTMLInputElement>) => sets3BucketName(e.target.value);

  const onBack = () => setStep(AWS_STEPS.PERMISSION);
  const onAccountIntegrationSuccess = () => setStep(AWS_STEPS.CONNECTION_VERIFIED);
  const onAccountIntegrationError = () => setStep(AWS_STEPS.CONNECTION_ERROR);
  const setCheckError = (v: boolean) => {
    if (v) {
      setStep(AWS_STEPS.CONNECTION_ERROR);
    }
  };

  const { onCheckAWSKeys } = useAWSTestIntegration({
    params: {
      reportName,
      s3BucketArn: 'arn:aws:s3:::' + s3BucketName,
      roleArn,
    },
    setCheckingParams,
    setCheckError,
    onAccountIntegrationError,
    onAccountIntegrationSuccess,
  });

  const listItems = Array.from(Array(6).keys());

  const linkHighlight = [
    {
      text: 'see our guide',
      url: API_URLS.OPTIMIZATION_GUIDE,
    },
  ];

  const highlightLink = (originalString: string, highlightedWords: { text: string; url: string }[]) => {
    const splitWords = splitByWords(
      originalString,
      highlightedWords.map((word) => word.text)
    );
    return (
      <Box>
        {splitWords.map((word: string, index: number) => {
          const foundHighlight = highlightedWords.find((highlight) => highlight.text === word);
          return foundHighlight ? (
            <Link key={index} href={foundHighlight.url} target="blank" underline="always" variant="link-m">
              {word}
            </Link>
          ) : (
            <Text key={index} variant="body-regular" size="M" color="text1">
              {word}
            </Text>
          );
        })}
      </Box>
    );
  };

  const header = (
    <Text variant="heading" size="M">
      {t('PREFERENCES.INTEGRATIONS.AWS.COST_AND_USAGE.TITLE')}
    </Text>
  );

  const content = (
    <Stack gap="32px">
      <IntegrationInput
        setValue={onRoleArnChange}
        value={roleArn}
        placeholder="arn:aws:iam::875445787275:role/vertice/GovernanceRole"
        label={t('PREFERENCES.INTEGRATIONS.AWS.COST_AND_USAGE.ROLE_ARN')}
      />

      <IntegrationInput
        setValue={ons3BucketNameChange}
        value={s3BucketName}
        placeholder="my-billing-bucket"
        label={t('PREFERENCES.INTEGRATIONS.AWS.COST_AND_USAGE.S3_BUCKET_NAME')}
      />

      <IntegrationInput
        setValue={onReportNameChange}
        value={reportName}
        placeholder="directoryName/VerticeCloudOptimization"
        label={t('PREFERENCES.INTEGRATIONS.AWS.COST_AND_USAGE.S3_PATH_PREFIX')}
      />

      <IntegrationAccordion
        summary={t('PREFERENCES.INTEGRATIONS.AWS.COST_AND_USAGE.HOW_TO_SET_UP')}
        details={
          <Stack gap="8px">
            <Text variant="body-regular" size="M" color="text1">
              {t('PREFERENCES.INTEGRATIONS.AWS.COST_AND_USAGE.BLOCK_1')}
            </Text>

            <ul className={styles['bullet-list']}>
              {listItems.map((i) => (
                <li key={`list-item-${i}`}>
                  <Text variant="body-regular" size="M" color="text1">
                    <Trans
                      i18nKey={`PREFERENCES.INTEGRATIONS.AWS.COST_AND_USAGE.LIST_ITEM_${i + 1}`}
                      components={{ bold: <Text variant="body-bold" size="M" /> }}
                    />
                  </Text>
                </li>
              ))}
            </ul>
            {highlightLink(t('PREFERENCES.INTEGRATIONS.AWS.COST_AND_USAGE.BLOCK_2'), linkHighlight)}
          </Stack>
        }
      />
    </Stack>
  );

  return (
    <WizardStepTemplate
      header={header}
      content={content}
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button
          variant="solid"
          color="primary"
          size="S"
          isCaption
          onClick={onCheckAWSKeys}
          disabled={!reportName || !s3BucketName || !roleArn}
          isLoading={checkingParams}
        >
          {t('COMMON.NEXT')}
        </Button>,
      ]}
    />
  );
};
export default AWSCostAndUsageDetails;
