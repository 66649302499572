import { FieldCard } from '../../DynamicFormBuilder/components/FieldCard/FieldCard';
import FieldCardContent from '../../DynamicFormBuilder/components/FieldCard/FieldCardContent';
import { CheckboxFieldType } from './types';

export type CheckboxFieldBuilderCardProps = {
  field: CheckboxFieldType & { id: string };
};

export const CheckboxFieldBuilderCard = (props: CheckboxFieldBuilderCardProps) => {
  return (
    <FieldCard>
      <FieldCardContent {...props} />
    </FieldCard>
  );
};
