import React, { MouseEvent, useState } from 'react';
import { IconButton, useTheme } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';
import useCancelOrTerminateContract from '@vertice/core/src/modules/saas/contract/hooks/useCancelOrTerminateContract';
import useContractDelete from '@vertice/core/src/modules/saas/contract/useContract/useContractDelete';
import { useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { Button } from '@verticeone/design-system';
import { canContractBeDeleted } from '@vertice/core/src/modules/saas/contract/computed';
import { DeleteContractDialog } from '@vertice/core/src/modules/saas/contract/components/DeleteContractDialog';
import { TerminateContractDialog } from '@vertice/core/src/modules/saas/contract/components/TerminateContractDialog';
import { Menu, MenuItem } from '@verticeone/design-system';

const ContractSummaryCardHeaderMenu = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const deleteContract = useContractDelete();

  const fetchedContract = contractContext.fetchedContract;
  const contract = fetchedContract.contract;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpened = Boolean(anchorEl);

  const openMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  // Delete contract
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const handleDeleteContractMenuItemClick = () => {
    setDeleteDialogOpened(true);
    closeMenu();
  };
  const submitDeleteDialog = () => {
    void deleteContract(contract);
    setDeleteDialogOpened(false);
  };

  // Contract Termination
  const { isTerminable, saveContractWithCancellationProperties, saveInProgress } = useCancelOrTerminateContract({
    contractContext,
  });
  const [terminationDialogOpened, setTerminationDialogOpened] = useState(false);
  const handleTerminateContractMenuItemClick = () => {
    setTerminationDialogOpened(true);
    closeMenu();
  };
  const submitTerminationDialog = () => {
    saveContractWithCancellationProperties({ reason: 'OTHER', note: 'Contract terminated' });
    setTerminationDialogOpened(false);
  };

  const isDeletable = canContractBeDeleted(contract);

  // We show terminate action in the menu if delete action is allowed, otherwise as separate button when applicable
  return (
    <>
      {isDeletable ? (
        <>
          <IconButton onClick={openMenu}>
            <MoreHorizIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={menuOpened} onClose={closeMenu}>
            {isTerminable && (
              <MenuItem onClick={handleTerminateContractMenuItemClick} disabled={saveInProgress}>
                {t('SAAS.CONTRACT_DETAIL_CARD.ACTIONS.TERMINATE_CONTRACT')}
              </MenuItem>
            )}
            <MenuItem onClick={handleDeleteContractMenuItemClick} sx={{ color: palette.error.color1 }}>
              {t('SAAS.CONTRACT_DETAIL_CARD.ACTIONS.DELETE_CONTRACT')}
            </MenuItem>
          </Menu>
        </>
      ) : (
        <>
          {isTerminable && (
            <Button
              variant="ghost"
              color="neutral"
              isLoading={saveInProgress}
              disabled={saveInProgress}
              size="M"
              onClick={() => setTerminationDialogOpened(true)}
            >
              {t('ENTITIES.CONTRACT.ACTIONS.TERMINATE')}
            </Button>
          )}
        </>
      )}
      <DeleteContractDialog
        isOpened={deleteDialogOpened}
        onClose={() => setDeleteDialogOpened(false)}
        onSubmit={submitDeleteDialog}
      />
      <TerminateContractDialog
        isOpened={terminationDialogOpened}
        onClose={() => setTerminationDialogOpened(false)}
        onSubmit={submitTerminationDialog}
      />
    </>
  );
};

export default ContractSummaryCardHeaderMenu;
