import { GenericMultiselectFilter } from './GenericMultiselectFilter';
import {
  CustomContractFieldDropdownOption,
  CustomContractFieldDropdownType,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Filter } from '../types';
import { useCallback } from 'react';
import { SimpleOption } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';

export type CustomDropdownFieldFilterProps = {
  config: CustomContractFieldDropdownType & { filterName: Filter };
};

const CustomDropdownFieldFilter = ({ config }: CustomDropdownFieldFilterProps) => {
  const { t } = useTranslation();
  const mapToCustomSelectOption = useCallback(
    (option: CustomContractFieldDropdownOption): SimpleOption => ({
      label: `${option.label}${option.enabled ? '' : ` (${t('ENTITIES.CONTRACT_CUSTOMIZATION.DISABLED')})`}`,
      value: option.id,
    }),
    [t]
  );

  return (
    <GenericMultiselectFilter
      name={config.filterName}
      placeholder={config.label}
      testId={`${config.filterName}-filter`}
      options={config.options?.map(mapToCustomSelectOption) ?? []}
    />
  );
};

export default CustomDropdownFieldFilter;
