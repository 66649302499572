import React from 'react';
import { rgba } from 'polished';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipInfoTrigger } from '@verticeone/design-system';

const permissionsList = [
  'savingsplans:DescribeSavingsPlans',
  'savingsplans:DescribeSavingsPlansOffering',
  'savingsplans:PurchaseSavingsPlan',
];

const StyledTooltipInfoTrigger = styled(TooltipInfoTrigger)(({ theme }) => ({
  color: rgba(theme.palette.text.color2, 0.4),
}));

type PermissionsListProps = {
  permissions: string[];
};

const PermissionsList = ({ permissions }: PermissionsListProps) => (
  <ul>
    {permissions.map((permission, index) => (
      <li key={index}>{permission}</li>
    ))}
  </ul>
);

const MissingPermissionTooltip = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.FOOTER.TOOLTIP' });

  return (
    <Tooltip size="S" title={t('TITLE')} content={<PermissionsList permissions={permissionsList} />}>
      <StyledTooltipInfoTrigger />
    </Tooltip>
  );
};

export default MissingPermissionTooltip;
