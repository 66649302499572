import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { MAX_DISCOUNT, MIN_DISCOUNT } from './constants';
import { TextField } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';

const DiscountEditCell = ({ id, field, api, value }: GridRenderEditCellParams) => {
  const [localValue, setLocalValue] = React.useState(!isNaN(Number(value)) ? value * 100 : 0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(parseFloat(e.target.value));
  };

  const handleBlur = () => {
    // Normalize the value to be between 0 and 100 with 2 decimal places
    const newValue = Math.max(MIN_DISCOUNT, Math.min(MAX_DISCOUNT, Number(localValue.toFixed(2))));
    const normalizedValue = newValue / 100;
    void api.updateRows([{ id, discount: normalizedValue }]);
    void api.setEditCellValue({ id, field, value: normalizedValue });
    setLocalValue(newValue);
  };

  return (
    <TextField
      sx={{ width: '100%', input: { padding: '10px !important', textAlign: 'right' } }}
      type="number"
      variant="ghost"
      hiddenLabel
      placeholder="0"
      endAdornment={
        <Stack paddingRight={4}>
          <Text variant="body-regular" size="M" color="text3">
            %
          </Text>
        </Stack>
      }
      value={localValue}
      onChange={handleChange}
      onBlurCapture={handleBlur}
    />
  );
};

export default DiscountEditCell;
