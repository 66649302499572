import React from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import CardTitle from '../CardTitle';

import { Card, CardHeader } from '@verticeone/design-system';
import ActivityFrequencyGraph from './ActivityFrequencyGraph';

type ActivityFrequencyProps = {
  isLoading: boolean;
  application?: ApplicationWithMeasureReports;
};

const ActivityFrequency = ({ isLoading, application }: ActivityFrequencyProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader>
        <CardTitle header={t('APPLICATIONS.USAGE.ACTIVITY_FREQUENCY.HEADER')}>
          {t('APPLICATIONS.USAGE.ACTIVITY_FREQUENCY.SUB_HEADER')}
        </CardTitle>
      </CardHeader>
      <ActivityFrequencyGraph activityFrequency={application?.measureReports?.activityFrequencyLast56d || []} />
    </Card>
  );
};

export default ActivityFrequency;
