import { Button } from '@verticeone/design-system';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-hook-form';
import { ContractFormData } from '../../../../../saas/contract/types';
import { useDynamicFormBuilderContext } from '../../DynamicFormBuilderContextProvider';

export const FieldDialogSubmitButton = () => {
  const { t } = useTranslation();
  const formState = useFormState<ContractFormData>();
  const { isSubmitting, isDirty, isValidating, isValid } = formState;
  const { color } = useDynamicFormBuilderContext();

  const disabled = useMemo(
    () => isSubmitting || !isDirty || isValidating || !isValid,
    [isSubmitting, isDirty, isValidating, isValid]
  );

  return (
    <Button
      form="field-dialog-form"
      type="submit"
      variant="solid"
      color={color}
      isLoading={isSubmitting}
      disabled={disabled}
    >
      {t('DIALOG.BUTTONS.SAVE')}
    </Button>
  );
};
