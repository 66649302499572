import { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { NodeProps, Node } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { rgba } from 'polished';
import { Text, IconWrapper } from '@verticeone/design-system';

import { NodeControls } from './NodeControls';
import { WorkflowGatewayNode } from '../../model/types';
import { GatewayIconMap } from '../../../components/icons/Icons';
import { useWorkflowRendererContext } from '../WorkflowRendererContext';

const StyledGatewayNode = styled(Stack)<{ $passed?: boolean }>(({ theme: { palette }, $passed }) => ({
  width: 34,
  height: 34,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  span: {
    color: palette.text.color2,
    position: 'absolute',
    top: 30,
    width: 100,
  },
  zIndex: 1,
  // diamond shape
  '&:before': {
    content: '""',
    position: 'absolute',
    border: 'none',
    inset: '16%',
    transform: 'rotateZ(45deg)',
    background: $passed ? palette.success.color4 : palette.secondary.color4,
    zIndex: 3,
  },
  // gradient border in diamond shape
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: '16%',
    padding: '2px',
    background: $passed
      ? palette.success.color3
      : `linear-gradient(135deg, ${rgba(palette.secondary.color4, 0.9)} 0%, ${rgba(palette.text.color1, 0.1)} 100%)`,
    transform: 'rotateZ(45deg) scale(1.1)',
    zIndex: 2,
  },
}));

const StyledGatewayIcon = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.neutral.color2,
  zIndex: 4,
}));

export const GatewayNodeComponent: FC<NodeProps<Node<WorkflowGatewayNode>>> = (props) => {
  const Icon = GatewayIconMap[props.data.gatewayType];

  const { isEditor } = useWorkflowRendererContext();

  return (
    <NodeControls
      {...props}
      content={
        <StyledGatewayNode $passed={props.data.state?.passed}>
          {isEditor && <StyledGatewayIcon icon={Icon} />}
          <Text variant="heading" size="XS">
            {props.data.name}
          </Text>
        </StyledGatewayNode>
      }
    />
  );
};
