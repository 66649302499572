import { useGetServiceCatalogResourceQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { FC, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { Loader } from '@verticeone/design-system';
import { parseFormUrn } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/forms/GenericForm/utils/createNewGenericFormUrn';
import { TaskCustomFormResult } from './TaskCustomFormResult';
import { useDynamicFormPresenterConfig } from '@vertice/core/src/modules/forms/dynamicForms/DynamicFormPresenter/hooks/useDynamicFormPresenterConfig';

export type TaskCustomFormResultSectionProps = {
  formUrn: string;
  result?: FieldValues;
};

export const TaskCustomFormResultSection: FC<TaskCustomFormResultSectionProps> = (props) => {
  const { formUrn, result } = props;

  const { accountId } = parseFormUrn(formUrn);

  const { data: serviceCatalogResource, isLoading: isServiceCatalogResourceLoading } =
    useGetServiceCatalogResourceQuery({
      accountId: accountId ?? 'GLOBAL',
      resourceUrn: formUrn,
    });

  const formConfigQuery = useDynamicFormPresenterConfig('readOnly');

  const formSchema = useMemo(() => {
    if (!serviceCatalogResource) return null;
    return serviceCatalogResource.definition.FormUIModel.Layout;
  }, [serviceCatalogResource]);

  if (isServiceCatalogResourceLoading || formConfigQuery.isLoading) return <Loader />;

  return (
    serviceCatalogResource &&
    result && <TaskCustomFormResult formSchema={formSchema} config={formConfigQuery.data} defaultValues={result} />
  );
};
