import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';

const texts = [
  'INITIATING_REQUEST',
  'FETCHING_PRODUCT_DETAILS',
  'CALCULATING_BENCHMARK_DATA',
  'PREPARING_WORKFLOW_TASKS',
  'RETRICULATING_SPLINES',
];

const useTranslatedTexts = ({ vendorName }: { vendorName: string }) => {
  const { t } = useTranslation();
  return useMemo(
    () => texts.map((text) => t(`INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.PROGRESS_TEXTS.${text}`, { vendorName })),
    [t, vendorName]
  );
};

type ProgressTextProps = {
  vendorName: string;
};

export const ProgressText = ({ vendorName }: ProgressTextProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const translatedTexts = useTranslatedTexts({ vendorName });
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={currentIndex}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <Text variant="body-regular" color="text3">
          {translatedTexts[currentIndex % translatedTexts.length]}
        </Text>
      </motion.div>
    </AnimatePresence>
  );
};
