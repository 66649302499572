import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { Text } from '@verticeone/design-system';
import {
  useConnectAccountIntegrationMutation,
  useRevokeAccountIntegrationMutation,
  useTestAccountIntegrationMutation,
} from '@vertice/slices';
import { IntegrationAccordion, IntegrationInput } from '@vertice/components';
import { Button } from '@verticeone/design-system';
import { STEPS, ENTRA_INTEGRATION_ID } from './common';
import styles from './Step.module.scss';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import { WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';

type CollectStepProps = WizardStepProps & {
  accountId: string;
  tenantId: string;
  setTenantId: (url: string) => void;
  clientId: string;
  setClientId: (clientId: string) => void;
  clientSecret: string;
  setClientSecret: (clientSecret: string) => void;
  testingInProgress: boolean;
  setTestingInProgress: (testingInProgress: boolean) => void;
};

export const CollectStep = ({
  setStep,
  accountId,
  tenantId,
  setTenantId,
  clientId,
  setClientId,
  clientSecret,
  setClientSecret,
  testingInProgress,
  setTestingInProgress,
}: CollectStepProps) => {
  const { t } = useTranslation();
  const [testAccountIntegrationMutation] = useTestAccountIntegrationMutation();
  const [connectAccountIntegrationMutation] = useConnectAccountIntegrationMutation();
  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();

  const onBack = () => setStep(STEPS.START);
  const onTestConnection = () => {
    // revoke & connect & test
    setTestingInProgress(true);
    void revokeAccountIntegration({
      accountId,
      integrationId: ENTRA_INTEGRATION_ID,
    }).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      connectAccountIntegrationMutation({
        accountId,
        integrationId: ENTRA_INTEGRATION_ID,
        integrationConnectionInput: {
          parameters: {
            secret: {
              tenantId,
              clientId,
              clientSecret,
            },
          },
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          testAccountIntegrationMutation({
            accountId,
            integrationId: ENTRA_INTEGRATION_ID,
            testName: 'connection',
          });
        })
        .then(() => {
          setStep(STEPS.CHECK_CONNECTION);
        });
    });
  };

  const header = (
    <Text variant="heading" size="M">
      {t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.GENERATE_DIRECTORY_ID_AND_API_TOKEN')}
    </Text>
  );

  const rightsTable = (
    <Stack className={styles.rights} direction="column" gap="6px">
      <Stack direction="row" gap="16px">
        <Text className={styles['rights--first-column']} variant="body-regular" size="XS">
          Application.Read.All
        </Text>
        <Text className={styles['rights--second-column']} variant="body-regular" size="XS">
          Application
        </Text>
        <Text className={styles['rights--third-column']} variant="body-regular" size="XS">
          Read all applications
        </Text>
      </Stack>
      <Stack direction="row" gap="16px">
        <Text className={styles['rights--first-column']} variant="body-regular" size="XS">
          AuditLog.Read.All
        </Text>
        <Text className={styles['rights--second-column']} variant="body-regular" size="XS">
          Application
        </Text>
        <Text className={styles['rights--third-column']} variant="body-regular" size="XS">
          Read all audit log data
        </Text>
      </Stack>
      <Stack direction="row" gap="16px">
        <Text className={styles['rights--first-column']} variant="body-regular" size="XS">
          Reports.Read.All
        </Text>
        <Text className={styles['rights--second-column']} variant="body-regular" size="XS">
          Application
        </Text>
        <Text className={styles['rights--third-column']} variant="body-regular" size="XS">
          Read all usage reports
        </Text>
      </Stack>
      <Stack direction="row" gap="16px">
        <Text className={styles['rights--first-column']} variant="body-regular" size="XS">
          User.Read
        </Text>
        <Text className={styles['rights--second-column']} variant="body-regular" size="XS">
          Delegated
        </Text>
        <Text className={styles['rights--third-column']} variant="body-regular" size="XS">
          Sign in and read user profile
        </Text>
      </Stack>
      <Stack direction="row" gap="16px">
        <Text className={styles['rights--first-column']} variant="body-regular" size="XS">
          User.Read.All
        </Text>
        <Text className={styles['rights--second-column']} variant="body-regular" size="XS">
          Delegated
        </Text>
        <Text className={styles['rights--third-column']} variant="body-regular" size="XS">
          Read all users&apos; full profiles
        </Text>
      </Stack>
      <Stack direction="row" gap="16px">
        <Text className={styles['rights--first-column']} variant="body-regular" size="XS">
          User.Read.All
        </Text>
        <Text className={styles['rights--second-column']} variant="body-regular" size="XS">
          Application
        </Text>
        <Text className={styles['rights--third-column']} variant="body-regular" size="XS">
          Read all users&apos; full profiles
        </Text>
      </Stack>
    </Stack>
  );

  const content = (
    <Stack direction="column" gap="32px">
      <Stack gap="20px">
        <IntegrationInput
          setValue={(changeEvent) => {
            setTenantId(changeEvent.target.value);
          }}
          value={tenantId}
          label={t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.DIRECTORY_ID')}
          dataTestId="entraDirectoryId"
        />
        <IntegrationInput
          setValue={(changeEvent) => {
            setClientId(changeEvent.target.value);
          }}
          value={clientId}
          label={t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.CLIENT_ID')}
          dataTestId="entraClientId"
        />

        <IntegrationAccordion
          summary={t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.HOW_TO_FIND_DIRECTORY_ID')}
          details={
            <ul className={styles['bullet-list']}>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.ENTRA.USAGE.DIRECTORY_ID_INSTRUCTION_1">
                  Navigate to the <Typography variant="body-bold-m">App Registrations</Typography> within the{' '}
                  <Typography variant="body-bold-m">Manage</Typography> menu.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.ENTRA.USAGE.DIRECTORY_ID_INSTRUCTION_2">
                  Create a new app called <Typography variant="body-bold-m">“Vertice”</Typography>.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.ENTRA.USAGE.DIRECTORY_ID_INSTRUCTION_3">
                  Click <Typography variant="body-bold-m">Overview</Typography> to access your new app.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.ENTRA.USAGE.DIRECTORY_ID_INSTRUCTION_4">
                  Obtain your <Typography variant="body-bold-m">Directory (Tenant) ID</Typography> and your
                  <Typography variant="body-bold-m">Client ID</Typography>
                  and paste into the fields above.
                </Trans>
              </li>
            </ul>
          }
        />
      </Stack>
      <Stack gap="8px">
        <IntegrationInput
          setValue={(changeEvent) => {
            setClientSecret(changeEvent.target.value);
          }}
          value={clientSecret}
          label={t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.API_TOKEN')}
          dataTestId="entraClientSecret"
        />
        <IntegrationAccordion
          defaultExpanded
          summary={t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.HOW_TO_SET_ENTRA_ACCESS_RIGHTS')}
          details={
            <ul className={styles['bullet-list']}>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.ENTRA.USAGE.API_TOKEN_INSTRUCTION_1">
                  Navigate to <Typography variant="body-bold-m">API Permissions</Typography>
                </Trans>
              </li>
              <li>
                <Box>
                  {t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.API_TOKEN_INSTRUCTION_2')}
                  {rightsTable}
                </Box>
              </li>
              <li>
                <Trans i18nKey="PREFERENCES.INTEGRATIONS.ENTRA.USAGE.API_TOKEN_INSTRUCTION_3">
                  Obtain your <Typography variant="body-bold-m">API Token</Typography> and paste into the field.
                </Trans>
              </li>
            </ul>
          }
        />
      </Stack>
    </Stack>
  );

  return (
    <WizardStepTemplate
      header={header}
      content={content}
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button
          variant="solid"
          color="primary"
          size="S"
          isCaption
          onClick={onTestConnection}
          disabled={testingInProgress}
        >
          {t('PREFERENCES.INTEGRATIONS.TEST_CONNECTION')}
        </Button>,
      ]}
    />
  );
};
