import { useTranslation } from 'react-i18next';
import { CardCloseButton, CardHeaderActions } from '@verticeone/design-system';
import { DrawerHeader, DrawerHeaderDescription, DrawerHeaderTitle } from '@verticeone/design-system';
import { useComparisonDrawer } from './ComparisonDrawerContext';
import { Placeholder } from '@verticeone/design-system';
import React from 'react';
import { Stack } from '@mui/material';
import { testProps } from '@verticeone/design-system';

type ComparisonDrawerHeaderProps = {
  isLoading: boolean;
  columnsMapping: Record<number, string | number>;
};

const ComparisonDrawerHeader = ({ isLoading, columnsMapping }: ComparisonDrawerHeaderProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.COMPARISON_DRAWER' });
  const { close } = useComparisonDrawer();
  const items = isLoading ? (
    <Placeholder width="80px" height={28} component="span" />
  ) : (
    Object.values(columnsMapping).join(', ')
  );

  return (
    <DrawerHeader noDivider>
      <DrawerHeaderTitle tag="h1" text={t('TITLE')} />
      <DrawerHeaderDescription>
        <Stack direction="row" {...testProps('compared-items')}>
          {items}
        </Stack>
      </DrawerHeaderDescription>
      <CardHeaderActions>
        <CardCloseButton onClick={close} />
      </CardHeaderActions>
    </DrawerHeader>
  );
};

export default ComparisonDrawerHeader;
