import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { CostBreakdownTooltip } from './index';
import { useFormatCost } from '../../../utils';

type TotalCostCellProps = GridRenderCellParams & {
  tKey: string;
};

const TotalCostCell = ({ value, row, tKey }: TotalCostCellProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: tKey });
  const formatCost = useFormatCost();

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Text variant="body-regular" size="M">
        {formatCost(row.currencyCode, value)}
      </Text>
      <CostBreakdownTooltip
        title={t('ITEM_TOOLTIP.TOTAL_COST.TITLE')}
        items={[
          {
            label: t('ITEM_TOOLTIP.TOTAL_COST.UPFRONT_PAYMENT'),
            value: formatCost(row.currencyCode, row.upfrontCost),
          },
          {
            label: t('ITEM_TOOLTIP.TOTAL_COST.MONTHLY_PAYMENT'),
            value: formatCost(row.currencyCode, row.monthlyCost),
          },
          {
            label: t('ITEM_TOOLTIP.TOTAL_COST.TOTAL_COST'),
            value: formatCost(row.currencyCode, row.totalCost),
          },
        ]}
      />
    </Stack>
  );
};

export default TotalCostCell;
