import React from 'react';
import { Stack } from '@mui/material';
import { GridRenderEditCellParams, MuiEvent } from '@mui/x-data-grid-pro';
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { DeleteOutline } from '@mui/icons-material';
import { Text } from '@verticeone/design-system';
import { DataGrid } from '@verticeone/design-system';
import { useFormatCurrency, useFormatDateRange, useFormatPercentage } from '@verticeone/utils/formatting';
import { TextField } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';

import { useContractGridContext } from './ContractContext';
import { ContractYear } from '../useContractListData';
import { useTranslation } from 'react-i18next';
import { MAX_COMMITMENT, MIN_COMMITMENT } from './constants';
import DateRangePickerEditCell from './DateRangePickerEditCell';
import DiscountEditCell from './DiscountEditCell';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

type ContractListGridProps = {
  data: Array<ContractYear>;
};

const ContractListGrid = ({ data }: ContractListGridProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID.GRID' });
  const { apiRef, isEditing, actions } = useContractGridContext();
  const formatDateRange = useFormatDateRange();
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();

  const handlePreventDefault = (_: any, event: MuiEvent) => {
    event.defaultMuiPrevented = true;
  };

  const tableColumns: Array<GridColDef<Partial<ContractYear>>> = [
    {
      field: 'year',
      headerName: t('COLUMN.YEAR'),
      editable: true,
      flex: 2,
      renderCell: ({ row }) => (
        <Stack gap={1} direction="row">
          {row.name && (
            <Text variant="body-regular" size="M" color="text1">
              {row.name}
            </Text>
          )}
          {row.startDate && row.endDate && (
            <Text variant="body-regular" size="M" color="text2">
              (
              {formatDateRange(row.startDate, row.endDate, {
                formatterOptions: { month: 'short', day: 'numeric', year: 'numeric' },
              })}
              )
            </Text>
          )}
        </Stack>
      ),
      renderEditCell: (params: GridRenderEditCellParams<Partial<ContractYear>>) => (
        <DateRangePickerEditCell {...params} />
      ),
      valueGetter: ({ row }) => [row.startDate, row.endDate].filter((i) => !!i).join('_'),
    },
    {
      field: 'commitment',
      headerName: t('COLUMN.COMMITMENT'),
      editable: true,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => (
        <Text variant="body-regular" size="M" color="text1">
          {formatCurrency(value ?? 0, { maximumFractionDigits: 0, currency: AWS_DEFAULT_CURRENCY })}
        </Text>
      ),
      renderEditCell: ({ id, field, api, value }) => (
        <TextField
          sx={{ width: '100%', input: { padding: '10px !important', textAlign: 'right' } }}
          type="number"
          variant="ghost"
          hiddenLabel
          value={!isNaN(Number(value)) ? Math.floor(value) : ''}
          placeholder="0"
          endAdornment={
            <Stack paddingRight={4}>
              <Text variant="body-regular" size="M" color="text3">
                $
              </Text>
            </Stack>
          }
          onChange={(e) => {
            const newValue = Math.max(MIN_COMMITMENT, Math.min(MAX_COMMITMENT, parseInt(e.target.value)));
            void api.updateRows([{ id, commitment: newValue }]);
            void api.setEditCellValue({ id, field, value: newValue });
          }}
        />
      ),
      valueGetter: ({ value }) => value ?? '',
    },
    {
      field: 'discount',
      headerName: t('COLUMN.DISCOUNT'),
      editable: true,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ value }) => (
        <Text variant="body-regular" size="M" color="text1">
          {formatPercentage(value ?? 0, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </Text>
      ),
      renderEditCell: (params) => <DiscountEditCell {...params} />,
      valueGetter: ({ value }) => value ?? '',
    },
    {
      field: 'delete',
      headerName: '',
      width: 70,
      align: 'center',
      renderCell: ({ row }) => (
        <Button size="M" color="neutral" onClick={() => actions.removeRow(row.id!)}>
          <DeleteOutline />
        </Button>
      ),
    },
  ];

  return (
    <DataGrid
      noBorder
      noBorderRadius
      color="neutral"
      editMode="row"
      initialState={{
        sorting: {
          sortModel: [{ field: 'startDate', sort: 'desc' }],
        },
      }}
      apiRef={apiRef}
      columns={tableColumns.filter(({ field }) => (isEditing ? true : field !== 'delete'))}
      rows={data}
      onRowEditStop={handlePreventDefault}
      onCellDoubleClick={handlePreventDefault}
    />
  );
};

export default ContractListGrid;
