import React from 'react';
import { isNil } from 'lodash';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { ExpandableText } from '@vertice/core/src/components/ExpandableText';
import { Placeholder } from '@verticeone/design-system';

const ExpandableTextPlaceholder = () => {
  return (
    <Stack gap={1}>
      <Placeholder width="100%" />
      <Placeholder width="60%" />
    </Stack>
  );
};

type InfoFieldProps = {
  label: string;
  value?: string | number | null;
  isLoading?: boolean;
  expandable?: boolean;
};

export const InfoField = ({ label, value, expandable, isLoading }: InfoFieldProps) => {
  if (!isLoading && isNil(value)) {
    return null;
  }

  return (
    <Stack gap={0.5}>
      <Text variant="caption" size="XS" color="text2">
        {label}
      </Text>
      {expandable ? (
        isLoading ? (
          <ExpandableTextPlaceholder />
        ) : (
          <ExpandableText defaultLineClamp={4}>{value}</ExpandableText>
        )
      ) : isLoading ? (
        <Placeholder width="80%" />
      ) : (
        <Text variant="body-regular" size="S">
          {value}
        </Text>
      )}
    </Stack>
  );
};
