import { type ChangeEvent, useEffect, useState } from 'react';
import { type GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { InputAdornment, TextField } from '@verticeone/design-system';
import { calculateSPCosts, MAX_COST_PERCENTAGE, MIN_COST_PERCENTAGE } from '../../../../../dataSources/utils';
import { useFormatCost } from '../../../utils';
import { OfferingData } from '../../../../../types';
import { useDisabledRow } from './utils';

const pattern = /^[0-9]*$/;

type EditableUpfrontCostCellProps = GridCellParams<OfferingData>;

const EditableUpfrontCostCell = ({ id, value, field, row }: EditableUpfrontCostCellProps) => {
  const apiRef = useGridApiContext();
  const [localValue, setLocalValue] = useState<number | null>(Number(value));
  const formatCost = useFormatCost();
  const { isDisabled } = useDisabledRow(row.offeringId);

  const minCost = Math.ceil(row.totalCost * MIN_COST_PERCENTAGE);
  const maxCost = Math.floor(row.totalCost * MAX_COST_PERCENTAGE);

  useEffect(() => {
    setLocalValue(Number(value));
  }, [value]);

  if (row.paymentOption !== 'Partial Upfront' || isDisabled) {
    return formatCost(row.currencyCode, Number(value));
  }

  const handleValueChange = async (newValue: string) => {
    let numericValue = !newValue || isNaN(+newValue) ? 0 : +newValue;
    if (numericValue < minCost) {
      numericValue = minCost;
    } else if (numericValue > maxCost) {
      numericValue = maxCost;
    }

    const { totalCost, monthlyCost } = calculateSPCosts({
      hourlyCommitment: row.hourlyCommitment,
      durationSeconds: row.durationSeconds,
      paymentOption: row.paymentOption,
      upfrontCost: numericValue,
    });

    await apiRef.current.setEditCellValue({ id, field, value: numericValue });

    apiRef.current.updateRows([{ offeringId: id, totalCost, monthlyCost }]);

    setLocalValue(numericValue);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || pattern.test(e.target.value)) {
      setLocalValue(e.target.value ? Number(e.target.value) : null);
    }
  };

  const handleBlur = async () => {
    await handleValueChange(localValue?.toString() || '');
  };

  return (
    <TextField
      type="text"
      value={localValue === null ? '' : localValue}
      variant="outlined"
      hiddenLabel
      onChange={handleChange}
      onBlur={handleBlur}
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      InputProps={{
        inputProps: {
          min: minCost,
          max: maxCost,
          inputMode: 'numeric',
          pattern: '[0-9]*',
        },
      }}
    />
  );
};

export default EditableUpfrontCostCell;
