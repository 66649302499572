import React from 'react';
import { Stack, type StackProps } from '@mui/material';
import { Loader } from '@verticeone/design-system';
import { AWS_BRAND_COLOR } from '../../../../../../../modules/cloud/constants';

const AwsLoader = (stackProps: StackProps) => (
  <Stack {...stackProps} minHeight="200px" justifyContent="center">
    <Loader color={AWS_BRAND_COLOR} />
  </Stack>
);

export default AwsLoader;
