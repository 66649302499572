import { FieldDef, FieldType } from '../types';
import { getNumberJsonSchema, getNumberPresenterDefault, getNumberPresenterSchema } from './utils';
import { NumberFieldType } from './types';
import { NumberFieldBuilderCard } from './NumberFieldBuilderCard';
import { NumberFieldPresenter } from './NumberFieldPresenter';
import { NumberFieldBuilderForm } from './NumberFieldBuilderForm';

export const fieldDef: FieldDef<NumberFieldType> = {
  type: FieldType.NUMBER,
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.NUMBER',
  builderCard: NumberFieldBuilderCard,
  builderForm: NumberFieldBuilderForm,
  presenter: NumberFieldPresenter,
  getPresenterDefault: getNumberPresenterDefault,
  getPresenterSchema: getNumberPresenterSchema,
  getJsonSchema: getNumberJsonSchema,
};
