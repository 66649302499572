import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { TEST_USER_CALENDLY_URL } from '../../components/CalendlyButton/constants';
import { Loader } from '@verticeone/design-system';
import { Account, useGetAccountQuery, useGetUserPublicInformationQuery } from '@vertice/slices';
import { AuthType, useAuthentication } from '@verticeone/auth/src';
import { Text } from '@verticeone/design-system';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { getFullName } from '@verticeone/utils/formatting';
import { Link } from '@verticeone/design-system';

const LazyCalendlyButton = lazy(() => import('../../components/CalendlyButton/CalendlyButton'));

const AccountManagerSection = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { accountId } = useAccountContext();

  const { data: accountDetails, isLoading: isCompanyDataLoading } = useGetAccountQuery(
    { accountId: accountId! },
    { skip: !accountId }
  ) as { data: Account; isLoading: boolean };

  const { type: authType } = useAuthentication();

  const {
    data: managerProfile,
    isUninitialized: isManagerProfileUninitialized,
    isLoading: isManagerProfileLoading,
  } = useGetUserPublicInformationQuery(
    { userId: (accountDetails as Account)?.associatedSuccessManager! },
    { skip: !accountDetails || !(accountDetails as Account)?.associatedSuccessManager }
  );

  const calendlyUrl =
    window._configEnv.REACT_APP_PROFILE === 'production'
      ? managerProfile?.userProperties?.calendlyUrl
      : managerProfile?.userProperties?.calendlyUrl || TEST_USER_CALENDLY_URL;

  const loading = isCompanyDataLoading || isManagerProfileLoading || isManagerProfileUninitialized;

  return (
    <Stack gap={8}>
      <Text variant="heading" size="S">
        {t('KNOWLEDGE_BASE.YOUR_CSM')}
      </Text>
      <Stack
        direction="row"
        gap={8}
        alignItems="center"
        justifyContent="center"
        minHeight={88}
        px={8}
        py={6}
        bgcolor={palette.core.color1}
        borderRadius={2}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <Stack width={410}>
              <Text variant="heading" size="S">
                {getFullName(managerProfile)}
              </Text>
              {managerProfile?.email && (
                <Link
                  color="primary"
                  size="S"
                  href={'mailto:' + managerProfile.email}
                  sx={{ overflowWrap: 'break-word' }}
                >
                  {managerProfile.email}
                </Link>
              )}
            </Stack>
            <Text variant="body-regular" size="M" color="text2">
              {t('KNOWLEDGE_BASE.AM_TEXT')}
            </Text>
            {calendlyUrl && authType !== AuthType.ASSUME_ROLE && (
              <Suspense fallback={null}>
                <LazyCalendlyButton calendlyUrl={calendlyUrl} sx={{ flexShrink: 0 }}>
                  {t('KNOWLEDGE_BASE.SCHEDULE_CALL')}
                </LazyCalendlyButton>
              </Suspense>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default AccountManagerSection;
