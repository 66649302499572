import React, { ReactNode } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@verticeone/design-system';
import { Product } from '@vertice/slices';
import { Placeholder } from '@verticeone/design-system';
import { useVendorProductsContext } from '../VendorProductsContextProvider';
import { keyBy } from 'lodash';
import { Stack } from '@mui/material';
import { ProductItem } from '../types';

type ProductCellProps = GridCellParams<any, Product> & {
  productCellDecorator?: (row: ProductItem) => ReactNode;
};

const ProductCell = (props: ProductCellProps) => {
  const { vendorProducts } = useVendorProductsContext();
  const vendorProductsMap = keyBy(vendorProducts, 'id');
  const decorator = props.productCellDecorator?.(props.row);

  const productName = vendorProductsMap[props.row.productId]?.name;
  if (productName === undefined) {
    return <Placeholder width={100} />;
  }
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Text variant="body-regular">{productName}</Text>
      {decorator}
    </Stack>
  );
};

export default ProductCell;
