import { useCallback, useMemo } from 'react';
import { camelCase } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { removeNullables } from '@verticeone/utils/objects';

export type OptimizationRecommendationsTableState = {
  expandedItems: string[];
};

enum SearchParamKey {
  EXPANDED = 'expanded',
}

const useQueryParams = () => {
  const [queryParams, setQueryParams] = useSearchParams();

  const updateQueryParams = (searchParamsToUpdate: { [key: string]: string }) => {
    // below we convert iterator queryParams into plain object
    const queryParamsObj = Object.fromEntries(queryParams.entries());

    return setQueryParams(removeNullables({ ...queryParamsObj, ...searchParamsToUpdate }));
  };

  return { queryParams, updateQueryParams };
};

/**
 * Stores the state of the OptimizationRecommendationsTable in the URL query params
 * @param paramPrefix optional prefix for the query params if you need multiple instances of the store
 * @example
 * const { state, onStateChange } = useOptimizationSearchParamsStateStore();
 * // => Stores params like `expandedRecommendations=VR-1,VR-2,VR-3`
 * const { state, onStateChange } = useOptimizationSearchParamsStateStore('fooBar');
 * // => Stores params like `fooBarExpandedRecommendations=VR-1,VR-2,VR-3`
 */
const useOptimizationSearchParamsStateStore = (paramPrefix = '') => {
  const { queryParams, updateQueryParams } = useQueryParams();
  const makeParamKey = useCallback((name: string) => camelCase(`${paramPrefix} ${name}`), [paramPrefix]);

  const state = useMemo(
    () => ({
      expanded: queryParams.get(makeParamKey(SearchParamKey.EXPANDED))?.split(',') ?? [],
    }),
    [makeParamKey, queryParams]
  );

  const onStateChange = useCallback(
    (newState: OptimizationRecommendationsTableState) => {
      updateQueryParams({
        [makeParamKey(SearchParamKey.EXPANDED)]: newState.expandedItems.join(','),
      });
    },
    [makeParamKey, updateQueryParams]
  );

  const toggleItem = useCallback(
    (item: string) => {
      onStateChange({
        expandedItems: state.expanded.includes(item)
          ? state.expanded.filter((rec) => rec !== item)
          : [...state.expanded, item],
      });
    },
    [onStateChange, state]
  );

  return useMemo(
    () => ({
      state,
      onStateChange,
      toggleItem,
    }),
    [onStateChange, state, toggleItem]
  );
};

export default useOptimizationSearchParamsStateStore;
