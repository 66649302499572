import {
  AdvancedLinearApproximationCostModel,
  ContractModel2,
  ContractPartContractualProducts,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import {
  getProductsFromItems,
  isAdvancedLinearApproximationModel,
} from '@vertice/core/src/modules/saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';
import { isNotNil } from '@verticeone/utils/validation';

/**
 * Use this function to extract product items from Advanced Linear Approximation cost model
 */
export const mapCostModelProductsToFormDataProductItem = (costModel?: ContractModel2) => {
  if (isAdvancedLinearApproximationModel(costModel)) {
    const advancedCostModel = costModel?.model as AdvancedLinearApproximationCostModel;
    return getProductsFromItems(advancedCostModel?.items);
  }

  return [];
};

/**
 * Use this function to extract product items from contract.parts.contractual.products property used for legacy cost model
 */
export const mapContractProductsToFormDataProductItem = (products?: ContractPartContractualProducts) => {
  return (
    products
      ?.filter((product) => isNotNil(product.productId))
      .map((product) => ({
        id: product.productId!,
        productId: product.productId!,
        name: product.productName ?? '',
        numberOfLicences: product.licenseCount ?? null,
        annualCost: product.annualCost ?? null,
      })) ?? []
  );
};
