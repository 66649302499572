import React from 'react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { Text } from '@verticeone/design-system';

import { useRecommendationData } from './RecommendationProvider';
import LastEvaluated from '../../../components/LastEvaluated/LastEvaluated';

const Header = () => {
  const { item } = useRecommendationData();
  const { t } = useTranslation();
  const { t: tRecommendations } = useTranslation('cloudRecommendations', { keyPrefix: item.code });
  const {
    item: { updatedAt },
  } = useRecommendationData();

  const scope = item.tags?.map(([tagName, value]) => (tagName === 'Scope' ? [value] : [])).flat();

  return (
    <Stack gap={1}>
      <Breadcrumbs variant="slash" size="S">
        <BreadcrumbLink>{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
        <BreadcrumbLink component={Link} to={ROUTES.CLOUD_OPTIMIZATION}>
          {t('CLOUD.CLOUD_OPTIMIZATION.BREADCRUMBS_TITLE')}
        </BreadcrumbLink>
        <BreadcrumbLink disabled>{item.code}</BreadcrumbLink>
      </Breadcrumbs>
      <Stack gap={2}>
        <Text variant="heading" size="M" color="text1" tag="h1">
          {tRecommendations('NAME')}
        </Text>
        <Stack justifyContent="space-between" direction="row">
          <Text variant="body-regular" size="M" color="text2">
            {scope}
          </Text>
          <LastEvaluated updatedAt={updatedAt ? new Date(updatedAt) : null} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
