import React from 'react';
import { useTranslation } from 'react-i18next';
import type { GridColDef } from '@mui/x-data-grid-pro';

import { useTermFormatter, useFormatCost } from '../../utils';
import { RemoveFromCardButton } from '../components/columns';
import type { OfferingsData } from '../../../providers/OfferingsProvider';
import { GridHeaderCell } from '@verticeone/design-system';
import dayjs from 'dayjs';
import TotalCostCell from '../components/columns/TotalCostCell';

export const useColumns = (tKey: string): Array<GridColDef<OfferingsData>> => {
  const { t } = useTranslation(undefined, { keyPrefix: tKey });
  const formatTerm = useTermFormatter();
  const formatCost = useFormatCost();

  return [
    {
      field: 'planType',
      headerName: t('COLUMNS.RESOURCE'),
      flex: 1,
    },
    {
      field: 'term',
      headerName: t('COLUMNS.TERM'),
      flex: 1,
      renderCell: ({ value }) => formatTerm(value),
    },
    {
      field: 'paymentOption',
      headerName: t('COLUMNS.PAYMENT_OPTION'),
      flex: 1,
    },
    {
      field: 'upfrontCost',
      headerName: t('COLUMNS.UPFRONT_COST'),
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value, row }) => formatCost(row.currencyCode, value),
    },
    {
      field: 'hourlyCommitment',
      headerName: t('COLUMNS.HOURLY_COMMITMENT.LABEL'),
      headerAlign: 'right',
      align: 'right',
      renderHeader: (params) => (
        <GridHeaderCell
          {...params}
          tooltip={{
            title: t('COLUMNS.HOURLY_COMMITMENT.TOOLTIP'),
          }}
        />
      ),
      renderCell: ({ value, row }) => formatCost(row.currencyCode, value, 3),
      flex: 1,
    },
    {
      field: 'totalCost',
      headerName: t('COLUMNS.TOTAL_COST'),
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => <TotalCostCell {...params} tKey={tKey} />,
    },
    {
      field: 'startDate',
      headerName: t('COLUMNS.START_DATE'),
      editable: true,
      valueFormatter: ({ value }) => dayjs(value).format('MMM DD, YYYY'),
      flex: 1,
    },
    {
      field: 'executeImmediately',
      editable: true,
    },
    {
      field: 'action',
      sortable: false,
      headerName: '',
      width: 120,
      disableColumnMenu: true,
      renderCell: ({ row }) => <RemoveFromCardButton tKey={tKey} id={row.offeringId} />,
    },
  ];
};
