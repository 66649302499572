import React from 'react';
import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloudHierarchyCard from '../../../modules/cloud/cards/CloudHierarchyCard/CloudHierarchyCard';
import OverviewStatsCard from '../../../modules/cloud/cards/OverviewStatsCard/OverviewStatsCard';
import { Breadcrumbs, BreadcrumbLink } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { Card } from '@verticeone/design-system';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import EstimatedSavingsCard from '../../../modules/cloud/cards/EstimatedSavingsCard/EstimatedSavingsCard';
import { useCloudContext } from '../../../modules/cloud/CloudContext';
import SavingsOverTimeGraph from './SavingsOverTimeGraph';

const Content = () => {
  const { t } = useTranslation();
  const { subFeatures } = useCloudContext();

  return (
    <Stack gap={8} p={10}>
      <Stack gap={1}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
          <BreadcrumbLink disabled>
            {t(subFeatures.activeSpendAudit ? 'CLOUD.NAVBAR.SPEND_AUDIT' : 'CLOUD.NAVBAR.OVERVIEW')}
          </BreadcrumbLink>
        </Breadcrumbs>
        <Text variant="heading" size="M" tag="h1">
          {t('CLOUD.CLOUD_OVERVIEW.TITLE')}
        </Text>
      </Stack>
      <Grid container rowSpacing={4} columnSpacing={4}>
        <Grid item sm={12}>
          <Card>
            <OverviewStatsCard />
            <SavingsOverTimeGraph />
          </Card>
        </Grid>
        <Grid item sm={12}>
          <CloudHierarchyCard />
        </Grid>
        <Grid item sm={12}>
          <EstimatedSavingsCard />
        </Grid>
      </Grid>
    </Stack>
  );
};

const CloudOverview = () => (
  <LoadableAWSPageWrapper>
    <Content />
  </LoadableAWSPageWrapper>
);

export default CloudOverview;
