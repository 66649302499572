import { Stack, styled } from '@mui/material';
import { FC } from 'react';
import { RuleGroup as ReactQueryBuilderRuleGroup, type RuleGroupProps } from 'react-querybuilder';

type RuleGroupContainerProps = {
  $groupLevel?: number;
};

const RuleGroupContainer = styled(Stack)<RuleGroupContainerProps>(({ $groupLevel = 0, theme }) => ({
  paddingLeft: $groupLevel * 17,
  '.betweenRules': {
    paddingLeft: 17,
  },
  '.ruleGroup': {
    flexDirection: 'column-reverse',
    backgroundColor: 'unset',
    padding: $groupLevel ? 8 : 0,
    rowGap: 12,
    border: $groupLevel ? `1px solid ${theme.palette.secondary.color3}` : 'none',
  },
  '.ruleGroup-body': {
    rowGap: '12px !important',
  },
  '.ruleGroup-header': {
    paddingLeft: 19,
  },
  '.ruleGroup-header:empty': {
    display: 'none',
  },
}));

export const RuleGroup: FC<RuleGroupProps> = (props) => {
  return (
    <RuleGroupContainer $groupLevel={props.path.length}>
      <ReactQueryBuilderRuleGroup {...props} />
    </RuleGroupContainer>
  );
};
