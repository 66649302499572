import { useMemo } from 'react';
import { useListServiceCatalogQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';

import type { ServicesSchema, ServiceSchema } from './types';
import { useAccountContext } from '../../../../contexts/AccountContext';
import {
  isVerticeService,
  isUDFService,
  convertJsonSchemaToSchema,
  getSchemaFromFunctionDefinition,
  isXTypeServiceCatalogResource,
} from './utils';

export const useServicesSchemas = (): ServicesSchema => {
  const { accountId } = useAccountContext();
  const { data: accountServiceCatalogData } = useListServiceCatalogQuery({ accountId, includeAllVersions: true });
  const { data: globalServiceCatalogData } = useListServiceCatalogQuery({
    accountId: 'GLOBAL',
    includeAllVersions: true,
  });

  return useMemo(() => {
    if (!accountServiceCatalogData?.items || !globalServiceCatalogData?.items) {
      return {
        account: {},
        global: {},
      };
    }

    const xTypeServiceCatalogResources = globalServiceCatalogData.items.filter(isXTypeServiceCatalogResource);

    const account = accountServiceCatalogData.items.reduce<ServiceSchema>((schemas, service) => {
      if (service.urn) {
        if (
          service.definition?.ServiceProvider?.Interface?.Output?.JsonSchema &&
          service.definition?.ServiceProvider?.Interface?.Input?.JsonSchema
        ) {
          schemas[service.urn] = {
            output: convertJsonSchemaToSchema({
              jsonSchema: service.definition.ServiceProvider.Interface.Output.JsonSchema,
              origin: { id: service.urn, label: service.name, kind: 'workflow input' },
              xTypeServiceCatalogResources,
            }),
            input: convertJsonSchemaToSchema({
              jsonSchema: service.definition.ServiceProvider.Interface.Input.JsonSchema,
              origin: { id: service.urn, label: service.name, kind: 'workflow input' },
              xTypeServiceCatalogResources,
            }),
          };

          return schemas;
        }

        if (service.definition?.Interface?.Output?.JsonSchema && service.definition?.Interface?.Input?.JsonSchema) {
          schemas[service.urn] = {
            output: convertJsonSchemaToSchema({
              jsonSchema: service.definition.Interface.Output.JsonSchema,
              origin: { id: service.urn, label: service.name, kind: 'task' },
              xTypeServiceCatalogResources,
            }),
            input: convertJsonSchemaToSchema({
              jsonSchema: service.definition.Interface.Input.JsonSchema,
              origin: { id: service.urn, label: service.name, kind: 'task' },
              xTypeServiceCatalogResources,
            }),
          };
        }
      }
      return schemas;
    }, {});

    const global = globalServiceCatalogData.items.reduce<ServiceSchema>((schemas, service) => {
      if (
        service.urn &&
        isUDFService(service.kind) &&
        service.definition?.FunctionProvider?.Interface?.Output?.JsonSchema &&
        service.definition?.FunctionProvider?.Interface?.Input?.JsonSchema
      ) {
        schemas[service.urn] = {
          output: getSchemaFromFunctionDefinition({
            udfServiceCatalogResource: service,
            xTypeServiceCatalogResources,
          }),
          input: convertJsonSchemaToSchema({
            jsonSchema: service.definition.FunctionProvider.Interface.Input.JsonSchema,
            origin: { id: service.urn, label: service.name, kind: 'udf' },
            xTypeServiceCatalogResources,
          }),
        };
      }

      if (
        service.urn &&
        isVerticeService(service.kind) &&
        service.definition?.ServiceProvider?.Interface?.Output?.JsonSchema &&
        service.definition?.ServiceProvider?.Interface?.Input?.JsonSchema
      ) {
        schemas[service.urn] = {
          output: convertJsonSchemaToSchema({
            jsonSchema: service.definition.ServiceProvider.Interface.Output.JsonSchema,
            origin: { id: service.urn, label: service.name, kind: 'vertice task' },
            xTypeServiceCatalogResources,
          }),
          input: convertJsonSchemaToSchema({
            jsonSchema: service.definition.ServiceProvider.Interface.Input.JsonSchema,
            origin: { id: service.urn, label: service.name, kind: 'vertice task' },
            xTypeServiceCatalogResources,
          }),
        };
      }

      return schemas;
    }, {});

    return {
      account,
      global,
    };
  }, [accountServiceCatalogData, globalServiceCatalogData]);
};
