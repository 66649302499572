import { useQuery } from '@tanstack/react-query';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { SpriAction } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';

const SPPermissionCheckQuery = graphql(`
  query SPPermissionCheck($accountId: String!, $action: SPRIAction!) {
    spPurchasePermissionQuery(params: { accountId: $accountId, action: $action }) {
      __typename
      ... on SPPurchasePermissionResult {
        action
        allowed
      }
      ... on ErroredQueryResult {
        error
      }
    }
  }
`);

type SPPurchasePermissionQueryParams = {
  action: SpriAction;
};

export const useSPPurchasePermissionQuery = ({ action }: SPPurchasePermissionQueryParams) => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['SPPurchasePermissionQuery', action],
    queryFn: () => fetchCloudOptimization(SPPermissionCheckQuery, { accountId, action }),
    enabled: !!accountId,
    select: (data) => {
      const permissionQueryData = data?.spPurchasePermissionQuery;
      return permissionQueryData?.__typename === 'SPPurchasePermissionResult' && permissionQueryData?.allowed;
    },
  });
};
