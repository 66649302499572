import { getTextVariantStyle } from '@verticeone/design-system';
import { PatternObject } from 'highcharts';
import { LABEL_VISIBILITY_THRESHOLD } from './constants';

type SeriesConfig = {
  id: string;
  value?: number;
  color: string | PatternObject;
  name: string;
  hoverColor: string | PatternObject;
  labelColor: string;
};

const getPercentage = (value?: number) => (value ? Math.round(value * 100) : 0);
const getOpacity = (value?: number) => ((value ?? 0) <= LABEL_VISIBILITY_THRESHOLD ? 0 : 1);

export const createSeriesConfig = ({ id, value, name, color, hoverColor, labelColor }: SeriesConfig) => ({
  id,
  type: 'bar' as const,
  isVisible: !!value,
  data: [getPercentage(value)],
  color,
  name,
  custom: {
    originalValue: value,
  },
  dataLabels: [
    {
      style: {
        ...(getTextVariantStyle({ variant: 'body-bold', size: 'S' }) as any),
        color: labelColor,
        opacity: getOpacity(value),
      },
    },
  ],
  states: {
    hover: {
      color: hoverColor,
    },
  },
});
