import { servicesAPI as api } from '../../api/servicesAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>({
      query: () => ({ url: `/version` }),
    }),
    listServiceCatalog: build.query<ListServiceCatalogApiResponse, ListServiceCatalogApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/serviceCatalog`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
          includeAllVersions: queryArg.includeAllVersions,
        },
      }),
    }),
    getServiceCatalogResource: build.query<GetServiceCatalogResourceApiResponse, GetServiceCatalogResourceApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/serviceCatalog/resource`,
        params: {
          resourceUrn: queryArg.resourceUrn,
        },
      }),
    }),
    updateServiceCatalogServiceResource: build.mutation<
      UpdateServiceCatalogServiceResourceApiResponse,
      UpdateServiceCatalogServiceResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/serviceCatalog/service`,
        method: 'POST',
        body: queryArg.updateServiceCatalogResourceService,
        params: {
          resourceUrn: queryArg.resourceUrn,
        },
      }),
    }),
    getServiceCatalogXTypeDatasource: build.query<
      GetServiceCatalogXTypeDatasourceApiResponse,
      GetServiceCatalogXTypeDatasourceApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/serviceCatalog/xtype/datasource`,
        params: {
          resourceUrn: queryArg.resourceUrn,
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
        },
      }),
    }),
    deployServiceCatalogServiceResource: build.mutation<
      DeployServiceCatalogServiceResourceApiResponse,
      DeployServiceCatalogServiceResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/serviceCatalog/deploy`,
        method: 'POST',
        body: queryArg.deployServiceCatalogResourceService,
      }),
    }),
    deployServiceCatalogFormResource: build.mutation<
      DeployServiceCatalogFormResourceApiResponse,
      DeployServiceCatalogFormResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/serviceCatalog/form/deploy`,
        method: 'POST',
        body: queryArg.deployServiceCatalogFormResourceService,
      }),
    }),
    listRequests: build.query<ListRequestsApiResponse, ListRequestsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
        },
      }),
    }),
    createRequest: build.mutation<CreateRequestApiResponse, CreateRequestApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests`,
        method: 'POST',
        body: queryArg.createRequest,
      }),
    }),
    updateRequestsOwner: build.mutation<UpdateRequestsOwnerApiResponse, UpdateRequestsOwnerApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests/updateOwner`,
        method: 'PUT',
        body: queryArg.updateRequestsOwner,
      }),
    }),
    updateRequestName: build.mutation<UpdateRequestNameApiResponse, UpdateRequestNameApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}/updateName`,
        method: 'PATCH',
        body: queryArg.updateRequestName,
      }),
    }),
    getRequest: build.query<GetRequestApiResponse, GetRequestApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}` }),
    }),
    getRequestJournal: build.query<GetRequestJournalApiResponse, GetRequestJournalApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}/journal`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
        },
      }),
    }),
    terminateRequest: build.mutation<TerminateRequestApiResponse, TerminateRequestApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests/${queryArg.requestId}/terminate`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as servicesAPICodegen };
export type GetApiVersionApiResponse = unknown;
export type GetApiVersionApiArg = void;
export type ListServiceCatalogApiResponse = /** status 200 List of ServiceCatalog items */ {
  items?: ServiceCatalogResource[];
  /** Token to get the next page of results.
    Example: `523jn5324nmnfklj3`
     */
  nextToken?: string;
};
export type ListServiceCatalogApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
  includeAllVersions?: boolean;
};
export type GetServiceCatalogResourceApiResponse = /** status 200 Workflow Instance */ ServiceCatalogResource;
export type GetServiceCatalogResourceApiArg = {
  accountId: string;
  resourceUrn: string;
};
export type UpdateServiceCatalogServiceResourceApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type UpdateServiceCatalogServiceResourceApiArg = {
  accountId: string;
  resourceUrn: string;
  /** Update service catalog service resource */
  updateServiceCatalogResourceService: UpdateServiceCatalogResourceService;
};
export type GetServiceCatalogXTypeDatasourceApiResponse = /** status 200 Service catalog xtype datasource response */ {
  items: {
    title: string;
    id: string | object;
  }[];
  /** Token to get the next page of results.
   */
  nextToken?: string | null;
};
export type GetServiceCatalogXTypeDatasourceApiArg = {
  accountId: string;
  resourceUrn: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type DeployServiceCatalogServiceResourceApiResponse =
  /** status 200 Object reference urns */ ObjectRefResponse[];
export type DeployServiceCatalogServiceResourceApiArg = {
  accountId: string;
  /** Deploy service catalog resources */
  deployServiceCatalogResourceService: DeployServiceCatalogResourceService;
};
export type DeployServiceCatalogFormResourceApiResponse = /** status 200 Object reference urns */ ObjectRefResponse[];
export type DeployServiceCatalogFormResourceApiArg = {
  accountId: string;
  /** Deploy service catalog form resources */
  deployServiceCatalogFormResourceService: DeployServiceCatalogFormResourceService;
};
export type ListRequestsApiResponse = /** status 200 Request list response */ {
  items: Request[];
  nextToken?: PaginationToken;
};
export type ListRequestsApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type CreateRequestApiResponse = /** status 201 Object reference urn */ {
  ref: string;
};
export type CreateRequestApiArg = {
  accountId: string;
  /** Request create request */
  createRequest: CreateRequest;
};
export type UpdateRequestsOwnerApiResponse = /** status 200 Object reference urns */ ObjectRefResponse[];
export type UpdateRequestsOwnerApiArg = {
  accountId: string;
  /** Request update request */
  updateRequestsOwner: UpdateRequestsOwner;
};
export type UpdateRequestNameApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type UpdateRequestNameApiArg = {
  accountId: string;
  requestId: string;
  /** Request update request name */
  updateRequestName: UpdateRequestName;
};
export type GetRequestApiResponse = /** status 200 Request detail response */ {
  request: Request;
};
export type GetRequestApiArg = {
  accountId: string;
  requestId: string;
};
export type GetRequestJournalApiResponse = /** status 200 Request journal response */ {
  items: JournalItem[];
  nextToken?: PaginationToken;
};
export type GetRequestJournalApiArg = {
  accountId: string;
  requestId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type TerminateRequestApiResponse = /** status 200 Empty Response */ object;
export type TerminateRequestApiArg = {
  accountId: string;
  requestId: string;
};
export type ServiceCatalogResourceDefinition = {
  [key: string]: any;
};
export type ResourceThumbnail = {
  Type: 'PREDEFINED';
  Id: string;
};
export type ServiceCatalogResource = {
  name: string;
  status: string;
  kind: string;
  urn: string;
  managedBy?: string;
  definition: ServiceCatalogResourceDefinition;
  thumbnail?: ResourceThumbnail;
  category?: string;
};
export type UpdateServiceCatalogResourceService = {
  /** Custom name for the service */
  name?: string;
  /** Handler URN.
    Example: `urn:verticeone:vertice:123456:vertice-workflows:workflow/default_triage_workflow`
     */
  handlerUrn: string;
};
export type ObjectRefResponse = any;
export type DeployServiceCatalogResourceService = {
  /** Service catalog resource definition */
  definition: string;
  definitionType: 'application/json' | 'application/yaml';
};
export type ResourceThumbnail1 = {
  Type: 'PREDEFINED';
  Id: string;
};
export type ResourceDefinition = {
  /** Type/Kind of given manifest
   */
  Kind: string;
  /** Ordinal version of given manifest
    Version must support lexicographic ordering so it's unambiguously apparent if version is greater
    or lower than another one (based on the semantics).
     */
  Version: string;
  /** Category is a high-level classification of the resource.
    It can be used to group resources in the catalog.
     */
  Category?: string;
  /** Vertice Unique Resource Name
    This URN uniquely identifies/addresses Vertice resources.
     */
  ResourceUrn: string;
  Thumbnail?: ResourceThumbnail1;
};
export type DocumentationType = 'text/markdown';
export type ResourceMetadata = {
  /** Name of the resource
   */
  Name: string;
  /** Description of the resource
   */
  Description?: string;
  /** Provides human readable, structured documentation that can be used to understand the purpose and
    usage of the resource.
     */
  Documentation?: {
    Type?: DocumentationType;
    Public?: string;
    Internal?: string;
  };
};
export type NonNegativeInteger = number;
export type NonNegativeIntegerDefault0 = number;
export type SchemaArray = Root[];
export type StringArray = string[];
export type SimpleTypes = 'array' | 'boolean' | 'integer' | 'null' | 'number' | 'object' | 'string';
export type Root =
  | boolean
  | {
      title?: string;
      description?: string;
      default?: any | null;
      readOnly?: boolean;
      writeOnly?: boolean;
      examples?: (any | null)[];
      multipleOf?: number;
      maximum?: number;
      exclusiveMaximum?: number;
      minimum?: number;
      exclusiveMinimum?: number;
      maxLength?: NonNegativeInteger;
      minLength?: NonNegativeIntegerDefault0;
      pattern?: string;
      additionalItems?: Root;
      items?: Root | SchemaArray;
      maxItems?: NonNegativeInteger;
      minItems?: NonNegativeIntegerDefault0;
      uniqueItems?: boolean;
      contains?: Root;
      maxProperties?: NonNegativeInteger;
      minProperties?: NonNegativeIntegerDefault0;
      required?: StringArray;
      additionalProperties?: Root;
      definitions?: {
        [key: string]: Root;
      };
      properties?: {
        [key: string]: Root;
      };
      dependencies?: {
        [key: string]: Root | StringArray;
      };
      enum?: any;
      type?: SimpleTypes | SimpleTypes[];
      format?: string;
      contentEncoding?: string;
      if?: Root;
      then?: Root;
      else?: Root;
      allOf?: SchemaArray;
      anyOf?: SchemaArray;
      oneOf?: SchemaArray;
      not?: Root;
      'x-type'?: string;
      'x-enabled'?: boolean;
    };
export type FormSchema = {
  /** JSON schema of the form
   */
  Schema: {
    JsonSchema: Root;
  };
};
export type Input = {
  JsonSchema: Root;
};
export type Output = {
  Documents?: {
    /** Copy created documents to the parent request
     */
    copy?: boolean;
  };
  JsonSchema: Root;
};
export type InvocationInterface = {
  Input?: Input;
  Output?: Output;
};
export type FormInterface = {
  Interface: InvocationInterface;
};
export type FieldType = 'SHORT_TEXT' | 'DROPDOWN' | 'MONEY' | 'NUMBER' | 'DATE' | 'CHECKBOX';
export type BaseFieldSchema = {
  type: FieldType;
  name: string;
};
export type MetaDataCommon = {
  label?: string;
  description?: string;
  required?: boolean;
};
export type ShortTextFieldSchema = BaseFieldSchema & {
  type?: 'SHORT_TEXT';
  metadata?: MetaDataCommon;
};
export type MetaDataDropDown = MetaDataCommon & {
  values?: string[];
};
export type DropDownFieldSchema = BaseFieldSchema & {
  type?: 'DROPDOWN';
  metadata?: MetaDataDropDown;
};
export type NumberFieldSchema = BaseFieldSchema & {
  type?: 'NUMBER';
  metadata?: MetaDataCommon;
};
export type DateFieldSchema = BaseFieldSchema & {
  type?: 'DATE';
  metadata?: MetaDataCommon;
};
export type CheckboxFieldSchema = BaseFieldSchema & {
  type?: 'CHECKBOX';
  metadata?: MetaDataCommon;
};
export type MoneyFieldSchema = BaseFieldSchema & {
  type?: 'MONEY';
  metadata?: MetaDataCommon;
};
export type FieldSchema =
  | ShortTextFieldSchema
  | DropDownFieldSchema
  | NumberFieldSchema
  | DateFieldSchema
  | CheckboxFieldSchema
  | MoneyFieldSchema;
export type LayoutLanguageSchema = {
  fields?: FieldSchema[];
};
export type FormUiModel = {
  FormUIModel?: {
    Kind: string;
    Version: string;
    Layout: LayoutLanguageSchema;
  };
};
export type Form = ResourceMetadata & (FormSchema | FormInterface) & FormUiModel;
export type FormManifest = {
  Form?: Form;
};
export type FormDefinition = ResourceDefinition & FormManifest;
export type ServiceCatalogFormResourceDefinition = {
  Resources?: FormDefinition[];
  [key: string]: any;
};
export type DeployServiceCatalogFormResourceService = {
  definition: ServiceCatalogFormResourceDefinition;
  definitionType: 'application/json' | 'application/yaml';
};
export type DateTime = string;
export type Request = {
  /** Request reference urn */
  ref: string;
  /** Parent request reference urn */
  parentRequestRef?: string | null;
  parentRequestName?: string | null;
  serviceRef: string;
  serviceName?: string;
  name: string;
  status: 'PENDING' | 'ACTIVE' | 'COMPLETED' | 'CANCELLED' | 'TERMINATED' | 'FAILED';
  errorMessage?: string;
  input: {
    [key: string]: any;
  };
  /** Reserved properties for Vertice platform */
  verticeReserved?: {
    [key: string]: any;
  };
  /** Widget data as defined in the service's widgets configuration */
  widgetData?: {
    [key: string]: any;
  };
  /** Request owner */
  owner?: string | null;
  /** Requestor reference urn */
  requestorRef: string;
  /** Source reference urn */
  sourceRef: string;
  /** Ref of underlying execution instance, e.g. `workflowInstanceRef` */
  executionRef?: string | null;
  result?: {
    [key: string]: any;
  };
  resultType?: string;
  createdAt: DateTime;
  createdBy: string | null;
  closedAt?: DateTime;
  updatedAt?: DateTime;
  changedBy?: string | null;
};
export type PaginationToken = string | null;
export type CreateRequest = {
  /** Request reference urn that the source originates from - fill if request is a child request for a parent request */
  requestRef?: string;
  /** Urn of the service being requested */
  serviceRef: string;
  /** Request name */
  name?: string;
  input: {
    [key: string]: any;
  };
  /** Request owner. Gets propagated to verticeReserved.request.owner (and back if not set) */
  owner?: string;
  /** Requestor reference urn */
  sourceRef: string;
  /** Requestor principal */
  requestor?: string;
};
export type UpdateRequestsOwner = {
  /** Request reference urns */
  requestRefs: string[];
  /** Request owner. Gets propagated to verticeReserved.request.owner (and back if not set) */
  owner: string;
};
export type UpdateRequestName = {
  name: string;
};
export type JournalItem = {
  actor: string;
  intents: string[];
  delta: string;
  timestamp: DateTime;
};
export const {
  useGetApiVersionQuery,
  useLazyGetApiVersionQuery,
  useListServiceCatalogQuery,
  useLazyListServiceCatalogQuery,
  useGetServiceCatalogResourceQuery,
  useLazyGetServiceCatalogResourceQuery,
  useUpdateServiceCatalogServiceResourceMutation,
  useGetServiceCatalogXTypeDatasourceQuery,
  useLazyGetServiceCatalogXTypeDatasourceQuery,
  useDeployServiceCatalogServiceResourceMutation,
  useDeployServiceCatalogFormResourceMutation,
  useListRequestsQuery,
  useLazyListRequestsQuery,
  useCreateRequestMutation,
  useUpdateRequestsOwnerMutation,
  useUpdateRequestNameMutation,
  useGetRequestQuery,
  useLazyGetRequestQuery,
  useGetRequestJournalQuery,
  useLazyGetRequestJournalQuery,
  useTerminateRequestMutation,
} = injectedRtkApi;
