import React, { FC } from 'react';
import { createRequiredContext } from '@verticeone/design-system';
import useDepartmentListData from './useDepartmentListData';
import { DepartmentItem, User } from './types';

type DepartmentListContextProviderProps = {
  children: string | JSX.Element | JSX.Element[];
};

type DepartmentListContextType = {
  departments: DepartmentItem[];
  userMap: Record<string, User>;
  isLoading: boolean;
  addDepartment: (newDepartment: DepartmentItem) => void;
  updateDepartment: (updatedDepartment: DepartmentItem) => void;
  removeDepartment: (id: string) => void;
};

const { DepartmentListContextProvider: RawDepartmentListContextProvider, useDepartmentListContext } =
  createRequiredContext<DepartmentListContextType, 'DepartmentList'>('DepartmentList');

export { useDepartmentListContext };

const DepartmentListContextProvider: FC<DepartmentListContextProviderProps> = ({ children }) => {
  const {
    data: departments,
    userMap,
    isLoading,
    addDepartment,
    updateDepartment,
    removeDepartment,
  } = useDepartmentListData();

  const context: DepartmentListContextType = {
    departments: departments || [],
    userMap,
    isLoading,
    addDepartment,
    updateDepartment,
    removeDepartment,
  };

  return <RawDepartmentListContextProvider value={context}>{children}</RawDepartmentListContextProvider>;
};

export default DepartmentListContextProvider;
