import { Stack, styled } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { DeadlineOriginTooltip, TooltipContent } from './DeadlineOriginTooltip';
import { useFormatDate } from '@verticeone/utils/formatting';
import { FC } from 'react';
import { getFeDeadline, hasFeDeadlineOrRollingFrequency } from './utils';
import RollingFrequency from './RollingFrequency';
import AdditionalInfo from './AdditionalInfo';
import { isPlannedForExpiration } from '../../computed';

type DeadlineProps = {
  contract: Contract;
};

const StyledStack = styled(Stack)({
  [`&:hover ${TooltipContent}`]: {
    opacity: 1,
  },
});

export const MAIN_VALUE_TEST_ID = 'deadline-main-value';
export const SECOND_LINE_TEST_ID = 'deadline-second-line';

const Deadline: FC<DeadlineProps> = ({ contract }) => {
  const deadline = getFeDeadline(contract);
  const markedForExpiration = isPlannedForExpiration(contract);
  const formatDate = useFormatDate();

  return hasFeDeadlineOrRollingFrequency(contract) ? (
    <StyledStack direction="column">
      {/* If a deadline date is specified, it always has priority over showing roll frequency. */}
      {deadline?.deadlineDate ? (
        <>
          <Stack direction="row" alignItems="center" gap={1}>
            <Text testId={MAIN_VALUE_TEST_ID} variant="body-regular" size="M" color="text1">
              {formatDate(deadline?.deadlineDate)}
            </Text>
            {!markedForExpiration && <DeadlineOriginTooltip origin={deadline?.origin} />}
          </Stack>
          <AdditionalInfo date={deadline?.deadlineDate} endDate={markedForExpiration} />
        </>
      ) : (
        <RollingFrequency contract={contract} testId={MAIN_VALUE_TEST_ID} />
      )}
    </StyledStack>
  ) : (
    <Text testId={MAIN_VALUE_TEST_ID} variant="body-regular" size="M" color="inactive2">
      —
    </Text>
  );
};

export default Deadline;
