import {
  isRuleGroupType,
  isRuleGroupTypeIC,
  type RuleGroupType,
  type RuleGroupTypeAny,
  type RuleGroupTypeIC,
  type RuleType,
} from 'react-querybuilder';

export const isQueryValid = (query: RuleGroupTypeAny | RuleType): boolean => {
  if (isRuleGroupType(query) || isRuleGroupTypeIC(query)) {
    const { rules } = query;

    if (!rules.length) {
      return false;
    }

    return rules.every((rule) => {
      if (isRuleGroupType(rule)) {
        return isQueryValid(rule);
      }

      if (isRuleGroupTypeIC(rule)) {
        return isQueryValid(rule);
      }

      if (typeof rule === 'string') {
        return rule !== '';
      }

      return rule.field !== '' && rule.value !== '' && rule.operator !== '';
    });
  }

  return query.field !== '' && query.value !== '' && query.operator !== '';
};

export const isEmptyRule = (rule: RuleGroupType | RuleType | RuleGroupTypeIC) => {
  return !isRuleGroupType(rule) && !isRuleGroupTypeIC(rule) && !rule.field && !rule.operator && !rule.value;
};
