import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';

import { ToggleSwitch } from '@verticeone/design-system';
import { testProps } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { useCloudOptimization } from '../CloudOptimizationContext';

const TogglePeriod = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS' });
  const { isAnnualPeriod, setIsAnnualPeriod } = useCloudOptimization();
  const togglePeriodId = useId();

  return (
    <Stack gap={2} alignItems="center" direction="row">
      <ToggleSwitch
        id={togglePeriodId}
        size="S"
        color="tertiary"
        checked={isAnnualPeriod}
        onChange={() => setIsAnnualPeriod(!isAnnualPeriod)}
        {...testProps('monthly-annual', 'switch')}
      />
      <Text variant="body-regular" tag="label" htmlFor={togglePeriodId}>
        {t('FILTER.PERIOD')}
      </Text>
    </Stack>
  );
};

export default TogglePeriod;
