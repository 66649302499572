import { Product, useGetProductByIdQuery, useListVendorProductsQuery } from '@vertice/slices';
import { useMemo } from 'react';
import useGetBenchmarkData, {
  BenchmarkData,
  BenchmarkingPeriods,
} from '../shared/pricingBenchmark/useGetBenchmarkData';
import { useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';

export type UseGetBenchmarkDetailRowDataParams = {
  vendorId: string;
  productId: string;
};

export type UseGetBenchmarkDetailRowDataReturn = {
  data?: {
    period: BenchmarkingPeriods;
    product: Product;
    benchmarkData: BenchmarkData;
  };
  isLoading: boolean;
};

const period = BenchmarkingPeriods.MONTHLY;

export const useGetBenchmarkDetailRowData = ({
  vendorId,
  productId,
}: UseGetBenchmarkDetailRowDataParams): UseGetBenchmarkDetailRowDataReturn => {
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const contractId = contractContext.fetchedContract.contract.record.contractId;
  const { data: benchmarkedVendorProducts, isLoading: isLoadingBenchmarkedProducts } = useListVendorProductsQuery({
    vendorId,
    benchmarkedOnly: true,
  });

  const hasBenchmarkingData = useMemo(
    () => benchmarkedVendorProducts?.products?.some((prod) => prod.id === productId),
    [benchmarkedVendorProducts?.products, productId]
  );

  const { data: product, isLoading: isLoadingProduct } = useGetProductByIdQuery(
    { vendorId, productId },
    { skip: !hasBenchmarkingData }
  );
  const { data: benchmarkData, isLoading: isLoadingBenchmarkData } = useGetBenchmarkData({
    vendorId,
    productId,
    contractId,
    period,
    skip: !hasBenchmarkingData,
  });

  return {
    data:
      hasBenchmarkingData && benchmarkData && product
        ? {
            period,
            product,
            benchmarkData,
          }
        : undefined,
    isLoading: isLoadingBenchmarkedProducts || isLoadingProduct || isLoadingBenchmarkData,
  };
};
