import { useFormatDate } from '@verticeone/utils/formatting';
import { Text } from '@verticeone/design-system';
import AdditionalInfo from '../../Deadline/AdditionalInfo';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import {
  hasCompletedNonCancelledRenewal,
  isCancelledContract,
  isContractLiveOrNotYetLive,
  isPlannedForExpiration,
} from '../../../computed';
import { Stack } from '@mui/material';

export const renewalDateValueGetter = ({ contract }: ViewOnContract): string | undefined => {
  if (isContractLiveOrNotYetLive(contract)) {
    return contract.parts.contractual?.lifecycle?.renewalDate;
  } else if (isCancelledContract(contract)) {
    return undefined;
  }
  return contract.materialized?.prev?.renewalDate;
};

export type RenewalDateCellProps = {
  viewOnContract: ViewOnContract;
};

export const RenewalDateCell = ({ viewOnContract }: RenewalDateCellProps) => {
  const date = renewalDateValueGetter(viewOnContract);
  const markedForExpiration = isPlannedForExpiration(viewOnContract.contract);
  const hasCompletedRenewal = hasCompletedNonCancelledRenewal(viewOnContract.contract);

  const formatDate = useFormatDate();
  return date ? (
    <Stack>
      <Text variant="body-regular">{formatDate(date)}</Text>
      <AdditionalInfo date={date} endDate={markedForExpiration || hasCompletedRenewal} />
    </Stack>
  ) : (
    <Text variant="body-regular" size="M" color="inactive2">
      —
    </Text>
  );
};
