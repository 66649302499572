import { FC } from 'react';
import { DynamicFormPresenterConfig, DynamicFormSchema } from '@vertice/core/src/modules/forms/dynamicForms';
import { FieldValues, FormProvider } from 'react-hook-form';
import { Stack } from '@mui/material';
import { mapFieldToComponent } from '@vertice/core/src/modules/forms/dynamicForms/DynamicFormPresenter/DynamicFormPresenter';
import { useDynamicFormPresenterForm } from '@vertice/core/src/modules/forms/dynamicForms/DynamicFormPresenter/hooks/useDynamicFormPresenterForm';

export type TaskCustomFormResultProps = {
  formSchema: DynamicFormSchema;
  config: DynamicFormPresenterConfig;
  defaultValues?: FieldValues;
};

export const TaskCustomFormResult: FC<TaskCustomFormResultProps> = (props) => {
  const { formSchema, config, defaultValues } = props;
  const form = useDynamicFormPresenterForm({ formSchema, config, defaultValues });

  return (
    <FormProvider {...form}>
      <Stack component="form" width="100%">
        {formSchema.fields.map(mapFieldToComponent(config))}
      </Stack>
    </FormProvider>
  );
};
