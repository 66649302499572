import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import { Card, CardHeader, CardHeaderTitle } from '@verticeone/design-system';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { Tabs } from '@verticeone/design-system';
import { Tab } from '@verticeone/design-system';
import { TabsBottomLineWrapper } from '@verticeone/design-system';
import { useState } from 'react';
import CumulativeSumTab from './CumulativeSumTab/CumulativeSumTab';
import SpendVsCommitmentTab from './SpendVsCommitmentTab/SpendVsCommitmentTab';
import CurrentTermSubtitle from '../CurrentTermSubtitle';

const Layout = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_SPEND_TREND' });
  const { palette } = useTheme();
  const visibleTabs = [
    { id: 'SPEND_VS_COMMITMENT', component: SpendVsCommitmentTab },
    { id: 'CUMULATIVE_SUM', component: CumulativeSumTab },
  ];
  const [activeTab, setActiveTab] = useState(visibleTabs[0]);

  return (
    <Card>
      <CardHeader size="S" noDivider>
        <CardHeaderTitle text={t('TITLE')} tag="h2" />
        <CurrentTermSubtitle />
      </CardHeader>
      <TabsBottomLineWrapper paddingX={6}>
        <Tabs
          variant="outlined"
          value={activeTab.id}
          onChange={(_, value) => setActiveTab(visibleTabs.find(({ id }) => id === value)!)}
          color={AWS_BRAND_COLOR}
        >
          {visibleTabs.map(({ id }) => (
            <Tab key={id} value={id} label={t(`TABS.${id}`)} testId={id.toLowerCase()} />
          ))}
        </Tabs>
      </TabsBottomLineWrapper>
      <Box bgcolor={palette.core.color1} py={6}>
        <activeTab.component />
      </Box>
    </Card>
  );
};

export default Layout;
