import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { USER_ROLES, UserRoleType } from '@vertice/core/src/constants/userRoles';
import IconBullet, { BulletType, TypeOfBulletType } from '../IconBullet';
import { Text } from '@verticeone/design-system';
import { RadioSwitch } from '@verticeone/design-system';
import { FormControlLabel } from '@verticeone/design-system';

interface RoleSelectorWithRolesProps {
  roles: UserRoleType[];
  selected?: UserRoleType | null;
  disabled?: boolean;
  onRoleChanged?: (value: UserRoleType) => void;
}

const RoleSelectorWithRoles: React.FC<RoleSelectorWithRolesProps> = ({
  roles,
  selected,
  disabled = false,
  onRoleChanged,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const handleChange = (value: string) => () => {
    if (!disabled && onRoleChanged) {
      onRoleChanged(value as UserRoleType);
    }
  };

  const adminDetails = [
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_1') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_2') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_3') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_4') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_5') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_6') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.DESCRIPTION_7') },
  ];
  const powerUserDetails = [
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.DESCRIPTION_1') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.DESCRIPTION_2') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.DESCRIPTION_3') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.DESCRIPTION_4') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.DESCRIPTION_5') },
  ];
  const userDetails = [
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.USER.DESCRIPTION_1') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.USER.DESCRIPTION_2') },
    { icon: BulletType.check, text: t('PREFERENCES.NEW_USER.ROLES_V2.USER.DESCRIPTION_3') },
  ];

  const roleDetails = {
    [USER_ROLES.admin]: adminDetails,
    [USER_ROLES.powerUser]: powerUserDetails,
    [USER_ROLES.user]: userDetails,
  };

  const roleTitles = {
    [USER_ROLES.admin]: t('PREFERENCES.NEW_USER.ROLES_V2.ADMIN.TITLE'),
    [USER_ROLES.powerUser]: t('PREFERENCES.NEW_USER.ROLES_V2.POWER_USER.TITLE'),
    [USER_ROLES.user]: t('PREFERENCES.NEW_USER.ROLES_V2.USER.TITLE'),
  };

  return (
    <Stack gap={1.5}>
      {roles.map((role: UserRoleType) => (
        <Stack key={role}>
          <Stack bgcolor={palette.input.color1} borderRadius={2} px={2.5} py={0.5}>
            <FormControlLabel
              sx={{ gap: 0.5 }}
              value={role}
              onChange={handleChange(role)}
              onClick={(event) => {
                event.stopPropagation();
              }}
              data-test-id="roleLabel"
              disabled={disabled}
              control={<RadioSwitch checked={selected === role} name="role" disabled={disabled} />}
              label={
                <Text variant="button-bold" size="M" color={disabled ? 'text3' : 'text2'}>
                  {roleTitles[role]}
                </Text>
              }
            />
          </Stack>
          <Stack component="ul" gap={1.5} m={0} p={2.5} sx={{ listStyle: 'none' }}>
            {role &&
              roleDetails[role].map((detail, index) => (
                <Stack component="li" direction="row" key={role + index} alignItems="center" gap={2.5}>
                  <IconBullet type={detail.icon as TypeOfBulletType} size={24} />
                  <Text variant="body-regular" size="M" color="text3">
                    {detail.text}
                  </Text>
                </Stack>
              ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default RoleSelectorWithRoles;
