import { fieldDef as checkboxFieldDef } from './CheckboxField/fieldDef';
import { fieldDef as dateFieldDef } from './DateField/fieldDef';
import { fieldDef as shortTextFieldDef } from './ShortTextField/fieldDef';
import { fieldDef as moneyFieldDef } from './MoneyField/fieldDef';
import { fieldDef as numberFieldDef } from './NumberField/fieldDef';
import { keyBy, Dictionary } from 'lodash';
import { FieldDef } from './types';

export const fieldDefs = [checkboxFieldDef, dateFieldDef, shortTextFieldDef, moneyFieldDef, numberFieldDef];

export const fieldDefsByType: Dictionary<FieldDef<any>> = keyBy(fieldDefs, ({ type }) => type);
