import React, { MouseEvent } from 'react';
import { Tab } from '@verticeone/design-system';
import { Tabs } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderSubtitle,
  CardHeaderTitle,
} from '@verticeone/design-system';
import { Box, Stack, useTheme } from '@mui/material';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import useRDSReservedInstancesData from './useRDSReservedInstancesData';
import ReservedInstancesGraph from '../shared/ReservedInstancesGraph/ReservedInstancesGraph';
import RdsCoverageTable from '../shared/ReservedInstancesTable/RdsCoverageTable';
import { ProposedCostsValue } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { TabsBottomLineWrapper } from '@verticeone/design-system';
import { AWS_BRAND_COLOR } from '../../constants';

import RioBanner from '../shared/RioBanner/RioBanner';
import { useCloudContext } from '../../CloudContext';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';

enum ReservedInstancesCardTab {
  PERCENTAGE_COVERAGE = 'percentage_coverage',
}

type RDSReservedInstancesCardProps = {
  proposedCosts?: ProposedCostsValue;
};

const RDSReservedInstancesCard = ({ proposedCosts }: RDSReservedInstancesCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { data, ...loadingStatus } = useRDSReservedInstancesData();
  const { subFeatures } = useCloudContext();
  const isSprioEnabled = subFeatures.sprio && subFeatures.sprioEC2RI;
  const isRioBannerVisible = subFeatures.rio || isSprioEnabled;
  const navigate = useNavigate();

  const handleClick = (evt: MouseEvent) => {
    const route = isSprioEnabled ? ROUTES.CLOUD_RIO_RESERVED_INSTANCES_DETAIL : ROUTES.CLOUD_RIO_TAB_DETAIL;
    const path = generatePath(route, { activeTab: 'inventory', product: 'rds' });
    if (evt.ctrlKey || evt.metaKey) {
      // Open in new tab
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <Card>
        <CardHeader size="S" noDivider>
          <CardHeaderTitle text={t('RESERVED_INSTANCES.TITLE')} tag="h2" />
          <CardHeaderSubtitle text={t('LAST_X_MONTHS', { monthCount: 3 })} icon={CalendarTodayOutlined} />
          <CardHeaderDescription>{t('RESERVED_INSTANCES.DESCRIPTION', { type: 'RDS' })}</CardHeaderDescription>
        </CardHeader>
        <TabsBottomLineWrapper paddingX={6}>
          <Tabs variant="outlined" value={ReservedInstancesCardTab.PERCENTAGE_COVERAGE} color={AWS_BRAND_COLOR}>
            <Tab
              value={ReservedInstancesCardTab.PERCENTAGE_COVERAGE}
              label={t('RESERVED_INSTANCES.PERCENTAGE_COVERAGE')}
            />
          </Tabs>
        </TabsBottomLineWrapper>
        <Box bgcolor={palette.core.color1}>
          <LoadableContentWrapper {...loadingStatus}>
            <Stack gap={6}>
              <ReservedInstancesGraph target={data?.target!} values={data?.values!} />
              <RdsCoverageTable data={data?.groupedByRegion!} />
            </Stack>
          </LoadableContentWrapper>
        </Box>
      </Card>
      {isRioBannerVisible ? <RioBanner onClick={handleClick} /> : null}
    </>
  );
};

export default RDSReservedInstancesCard;
