import React, { FC, useState, ReactElement, cloneElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridValueGetterParams,
  GridRowParams,
  MuiEvent,
  GridEventListener,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ConfirmationDialog } from '@vertice/components';
import { IconButton } from '@verticeone/design-system';
import { ConcessionItem } from './types';
import { randomId } from '@mui/x-data-grid-generator';
import useConcessions from './useConcessions';
import { ConcessionStatus } from './constants';
import { isNil } from 'lodash';

interface WithActionsProps {
  children: ReactElement;
  excludeDeleteAction?: boolean;
  selectedConcessions: ConcessionItem[];
  setSelectedConcessions: (newProducts: ConcessionItem[]) => void;
}

const WithActions: FC<WithActionsProps> = ({
  children,
  excludeDeleteAction,
  selectedConcessions,
  setSelectedConcessions,
  ...rest
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CONCESSIONS_LIST' });

  const [tempoConcessions, setTempoConcessions] = useState<ConcessionItem[]>([]);
  const [activeConcessionId, setActiveConcessionId] = useState('');

  const { addConcession, updateConcession, removeConcession } = useConcessions({
    selectedConcessions,
    setSelectedConcessions,
  });
  const concessionsIds = selectedConcessions?.map((con: ConcessionItem) => con.id) || [];
  const concessionsToUse = [
    ...(selectedConcessions || []),
    ...tempoConcessions.filter((con) => !concessionsIds.includes(con.id)),
  ];

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [cannotAddConcessionOpen, setCannotAddConcessionOpen] = useState(false);

  const onAddRow = () => {
    const id = randomId();
    setTempoConcessions((oldRows) => [
      ...oldRows,
      { id, type: null, status: ConcessionStatus.InProgress, amount: null, description: null, isNew: true },
    ]);
    setRowModesModel((currentModel) => ({
      ...currentModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'type' },
    }));
  };

  const onRowEditStart = (params: GridRowParams, event: MuiEvent<SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const onRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (newRow.type) {
      const concessionData = {
        id: newRow.id,
        type: newRow.type,
        description: newRow.description,
        amount: newRow.amount,
        status: newRow.status,
      };

      if (newRow.isNew) {
        addConcession(concessionData);
      } else {
        updateConcession(concessionData);
      }
    }
    setTempoConcessions([]);
    return newRow;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setActiveConcessionId(id as string);
    setConfirmDeleteOpen(true);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setTempoConcessions([]);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleConfirmedDelete = async () => {
    if (activeConcessionId) {
      removeConcession(activeConcessionId);
      setActiveConcessionId('');
    }
    setConfirmDeleteOpen(false);
  };

  const handleCanceledDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const ActionsCell = (params: GridValueGetterParams<ConcessionItem, ConcessionItem>) => {
    const apiRef = useGridApiContext();
    const handleSaveClick = () => {
      const id = params.row.id;
      const updatedRow = apiRef.current.getRowWithUpdatedValues(id, 'id');
      if (updatedRow.type && !isNil(updatedRow.amount)) {
        // Save the row only if all mandatory fields are set
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      } else {
        setCannotAddConcessionOpen(true);
      }
    };

    const isRowInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;

    return (
      <Stack direction="row" alignItems="center" gap={1}>
        {isRowInEditMode ? (
          <>
            <IconButton variant="ghost" onClick={handleSaveClick} icon={CheckOutlined} aria-label={t('BUTTON.SAVE')} />
            <IconButton
              variant="ghost"
              onClick={handleCancelClick(params.row.id)}
              icon={CloseOutlined}
              aria-label={t('BUTTON.DISCARD')}
            />
          </>
        ) : (
          <>
            <IconButton
              variant="ghost"
              onClick={handleEditClick(params.row.id)}
              icon={EditOutlined}
              aria-label={t('BUTTON.EDIT')}
            />
            <IconButton
              variant="ghost"
              onClick={handleDeleteClick(params.row.id)}
              icon={DeleteOutlined}
              aria-label={t('BUTTON.DELETE')}
            />
          </>
        )}
      </Stack>
    );
  };

  const actionsColumn = {
    field: 'actions',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: ActionsCell,
    width: 84,
    align: 'center',
  };

  return (
    <>
      {cloneElement(children, {
        extraColumns: [actionsColumn],
        dataGridExtraProps: {
          rows: concessionsToUse,
          hideFooter: false,
          editMode: 'row',
          isCellEditable: () => true,
          rowModesModel,
          onRowEditStart,
          onRowEditStop,
          processRowUpdate,
          slotProps: {
            footer: { onAddClick: onAddRow, disabledButton: !!tempoConcessions.length },
          },
        },
        ...rest,
      })}
      <ConfirmationDialog
        isOpen={confirmDeleteOpen}
        headerText={t('DELETE_CONCESSION_DIALOG.HEADING')}
        bodySecondaryText={t('DELETE_CONCESSION_DIALOG.MESSAGE')}
        secondaryButtonText={t('DELETE_CONCESSION_DIALOG.CANCEL')}
        secondaryButtonAction={handleCanceledDelete}
        primaryButtonText={t('DELETE_CONCESSION_DIALOG.YES')}
        primaryButtonAction={handleConfirmedDelete}
      />
      <ConfirmationDialog
        isOpen={cannotAddConcessionOpen}
        bodySecondaryText={t('CANNOT_ADD_DIALOG.MESSAGE')}
        primaryButtonText={t('CANNOT_ADD_DIALOG.OK')}
        primaryButtonAction={() => setCannotAddConcessionOpen(false)}
      />
    </>
  );
};

export default WithActions;
