import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLoggedUser } from '@verticeone/auth/src';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { useRelevantUsersById } from '../../../../hooks/useRelevantUsersById';
import { useResolveUsersInfo } from '../../../../hooks/useResolveUsersInfo';
import { parseUserRef } from '../../../../hooks/workflows/refUtils';
import { Definitions } from '../../definitionsTypes';
import { useRequestTasks } from '../../request/RequestDetailPage/useRequestTasks';
import { TaskDrawer } from '../../task/TaskDrawer/TaskDrawer';
import { WorkflowModel } from '../model/types';
import { BaseWorkflowRenderer, WorkflowRendererProps } from './BaseWorkflowRenderer';
import { WorkflowRendererContextProvider } from './WorkflowRendererContext';

type WorkflowRendererFullProps = PropsWithChildren<
  WorkflowRendererProps & {
    requestId: string;
    allowVerticeServiceNavigation?: boolean;
    isEditor?: boolean;
    workflowDefinitions?: Definitions;
    model: WorkflowModel;
  }
>;

export const WorkflowRenderer: FC<WorkflowRendererFullProps> = ({
  children,
  model,
  workflowDefinitions,
  requestId,
  isEditor = false,
  allowVerticeServiceNavigation,
}) => {
  const { usersById: accountUsersById } = useRelevantUsersById({ includeLoggedVerticeUser: true });

  const { userId: loggedUserId } = useLoggedUser();
  const { accountId } = useAccountContext();
  const { allTasks } = useRequestTasks(requestId, true);

  const tasksAssignees = useMemo(() => {
    return allTasks.flatMap((task) => task.assignment.map((ref) => parseUserRef(ref).userId));
  }, [allTasks]);
  const { usersById } = useResolveUsersInfo(tasksAssignees, accountUsersById);

  const [drawerTaskId, setDrawerTaskId] = useState<string | undefined>(undefined);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const taskId = searchParams.get('taskId');
    if (taskId) {
      setDrawerTaskId(taskId);
      setSearchParams({}, { replace: true });
    }
  }, [searchParams, setDrawerTaskId, setSearchParams]);

  const onOpenTask = (taskId: string) => {
    setDrawerTaskId(taskId);
  };
  const drawerTask = useMemo(() => {
    return allTasks.find((row) => drawerTaskId && row.id.includes(drawerTaskId));
  }, [allTasks, drawerTaskId]);

  return (
    <>
      <WorkflowRendererContextProvider
        value={{
          model,
          usersById,
          isEditor,
          loggedUserId,
          accountId,
          onOpenTask,
          allowVerticeServiceNavigation,
          workflowDefinitions,
          drawerTaskId,
        }}
      >
        <BaseWorkflowRenderer>{children}</BaseWorkflowRenderer>
        {drawerTaskId && <TaskDrawer onClose={() => setDrawerTaskId(undefined)} taskRow={drawerTask} />}
      </WorkflowRendererContextProvider>
    </>
  );
};
