import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Text } from '@verticeone/design-system';
import {
  Oauth2ConnectionStatus,
  useConnectAccountIntegrationMutation,
  useGetIntegrationSchemaQuery,
  useInitiateOauth2AccountIntegrationMutation,
  useRevokeAccountIntegrationMutation,
} from '@vertice/slices';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DoneIcon from '@mui/icons-material/Done';
import { Button } from '@verticeone/design-system';
import { IntegrationAccordion, Loader } from '@vertice/components';
import { useSnackbar } from 'notistack';
import { GOOGLE_INTEGRATION_ID, StepProps, STEPS } from './common';
import styles from '../../../../common.module.scss';
import { BulletList } from 'pages/Preferences/Integrations/components/WizardSteps';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';

type AllowAccessStepProps = StepProps & {
  testingInProgress: boolean;
  setTestingInProgress: (testingInProgress: boolean) => void;
  setConnectionWindow: (testingInProgress: any) => void;
};

export const AllowAccess = ({ setStep, setConnectionWindow, accountId, testingInProgress }: AllowAccessStepProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const onBack = () => setStep(STEPS.SELECT_DATA_SOURCE);
  const [processingUrl, setProcessingUrl] = useState(false);
  const [callbackCopied, setCallbackCopied] = useState(false);
  const { data: integrationSchema, isLoading: isLoadingIntegrationSchema } = useGetIntegrationSchemaQuery({
    integrationId: GOOGLE_INTEGRATION_ID,
  });
  const [connectAccountIntegrationMutation] = useConnectAccountIntegrationMutation();
  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();
  const [initiateOauth2AccountIntegration] = useInitiateOauth2AccountIntegrationMutation();

  const copyToClipboard = (text?: string, setCallback?: (value: boolean) => void) => {
    if (!text || !setCallback) {
      return;
    }

    navigator.clipboard.writeText(text).then(
      () => {
        setCallback(true);
        setTimeout(() => setCallback(false), 2000);
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  const onTestConnection = () => {
    setProcessingUrl(true);
    const windowReference = window.open(undefined, '_blank');
    if (!windowReference) {
      enqueueSnackbar(t('PREFERENCES.INTEGRATIONS.GOOGLE.ERROR.NEW_WINDOW_OPEN_NOT_ALLOWED'), {
        variant: 'error',
      });
      return;
    }

    void revokeAccountIntegration({
      accountId,
      integrationId: GOOGLE_INTEGRATION_ID,
    }).then(async () => {
      await connectAccountIntegrationMutation({
        accountId,
        integrationId: GOOGLE_INTEGRATION_ID,
        integrationConnectionInput: {
          parameters: {
            secret: {},
          },
        },
      });

      const response = await initiateOauth2AccountIntegration({
        accountId,
        integrationId: GOOGLE_INTEGRATION_ID,
      });

      if ((response as { error: FetchBaseQueryError | SerializedError }).error) {
        windowReference.close();
        setStep(STEPS.CONNECTION_ERROR);
      } else {
        windowReference.location = (response as { data: Oauth2ConnectionStatus })?.data?.authorizationUrl as string;
        setConnectionWindow(windowReference);
        setProcessingUrl(false);
        setStep(STEPS.CHECK_CONNECTION);
      }
    });
  };

  const header = (
    <Stack>
      <Text variant="heading" size="M">
        {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.ALLOW_ACCESS')}
      </Text>
      <Text variant="body-regular" size="M" color="text2">
        {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.ALLOW_ACCESS_INTRO')}
      </Text>
    </Stack>
  );

  const content = (
    <Stack direction="column">
      <div className={styles['callback-url']}>
        <Text variant="caption" size="XS" color="text2">
          {t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.CLIENT_ID_FOR_GOOGLE')}
        </Text>
        <div className={styles['copy-to-clipboard']}>
          {isLoadingIntegrationSchema ? (
            <Loader />
          ) : (
            <div className={styles['copy-to-clipboard-top']}>
              <div className={styles['copy-to-clipboard-text']}>
                <Text variant="code" size="S">
                  {integrationSchema?.parameters?.client_id?.value}
                </Text>
              </div>
              <div
                className={styles['copy-to-clipboard-icon']}
                onClick={() => copyToClipboard(integrationSchema?.parameters?.client_id?.value, setCallbackCopied)}
              >
                {!callbackCopied && <CopyAllIcon />}
                {callbackCopied && <DoneIcon />}
              </div>
            </div>
          )}
        </div>
      </div>

      <IntegrationAccordion
        summary={t('PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.HOW_TO_ALLOW_IN_GOOGLE_WORKSPACE_ADMIN')}
        defaultExpanded
        details={
          <BulletList>
            <li>
              <Trans i18nKey="PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.ALLOW_ACCESS_INSTRUCTION_1">
                Navigate to your <Typography variant="body-bold-m">Google Workspace Admin Panel</Typography>
              </Trans>
            </li>
            <li>
              <Trans i18nKey="PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.ALLOW_ACCESS_INSTRUCTION_2">
                Navigate to <Typography variant="body-bold-m">App Access Control</Typography> under
                <Typography variant="body-bold-m">API Controls</Typography>.
              </Trans>
            </li>
            <li>
              <Trans i18nKey="PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.ALLOW_ACCESS_INSTRUCTION_3">
                Select <Typography variant="body-bold-m">View List</Typography> under
                <Typography variant="body-bold-m">Configured App</Typography>, and then
                <Typography variant="body-bold-m">Add App</Typography>
              </Trans>
            </li>
            <li>
              <Trans i18nKey="PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.ALLOW_ACCESS_INSTRUCTION_4">
                Select <Typography variant="body-bold-m">OAth App Name</Typography> or
                <Typography variant="body-bold-m">Client ID</Typography>.
              </Trans>
            </li>
            <li>
              <Trans i18nKey="PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.ALLOW_ACCESS_INSTRUCTION_5">
                Paste the above Client ID and search.
              </Trans>
            </li>
            <li>
              <Trans i18nKey="PREFERENCES.INTEGRATIONS.GOOGLE.USAGE.ALLOW_ACCESS_INSTRUCTION_6">
                Allow <Typography variant="body-bold-m">Trusted</Typography> app access and click
                <Typography variant="body-bold-m">Configure</Typography>.
              </Trans>
            </li>
          </BulletList>
        }
      />
    </Stack>
  );

  return (
    <WizardStepTemplate
      header={header}
      content={content}
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button
          variant="solid"
          color="primary"
          size="S"
          isCaption
          onClick={onTestConnection}
          disabled={testingInProgress}
          isLoading={processingUrl}
        >
          {t('PREFERENCES.INTEGRATIONS.TEST_CONNECTION')}
        </Button>,
      ]}
    />
  );
};
