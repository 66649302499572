import React, { FC, useCallback, useMemo } from 'react';
import { Stack } from '@mui/material';
import ProductsContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/ProductsContractFormEntry';
import useBenchmarking from '../../vendor/hooks/useBenchmarking';
import { PricingBenchmarkDetailRow } from '../../product/components/PricingBenchmarkDetailRow/PricingBenchmarkDetailRow';
import { useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { ProductItem } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { CostModelProductsContractFormEntry } from '@vertice/core/src/modules/saas/contract/components/form/CostModelProductsContractFormEntry';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { BenchmarkingProductCellDecorator } from '../components/BenchmarkingProductCellDecorator';

type ProductsTabProps = {
  showAnnualCost?: boolean;
};

const ProductsTab: FC<ProductsTabProps> = ({ showAnnualCost }) => {
  const { benchmarkingStage2 } = useBenchmarking();
  const { isFeatureEnabled } = useAccountContext();
  const advancedCostModels = isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS);
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const vendorId = contractContext.fetchedContract.contract.parts.contractual?.vendor?.vendorId;

  const benchmarkingDetailRowRenderer = useMemo(
    () =>
      benchmarkingStage2 && vendorId
        ? ({ row }: GridRowParams<ProductItem>) => (
            <PricingBenchmarkDetailRow vendorId={vendorId} productId={row.productId} />
          )
        : undefined,
    [benchmarkingStage2, vendorId]
  );

  const benchmarkingProductCellDecorator = useCallback(
    (row: ProductItem) => {
      return <BenchmarkingProductCellDecorator vendorId={vendorId} row={row} />;
    },
    [vendorId]
  );
  return (
    <Stack p={6}>
      {advancedCostModels ? (
        <CostModelProductsContractFormEntry
          detailRowRenderer={benchmarkingDetailRowRenderer}
          productCellDecorator={benchmarkingProductCellDecorator}
          name="costModel.negotiated.model.products"
          width={12}
        />
      ) : (
        <ProductsContractFormEntry
          showAnnualCost={showAnnualCost}
          detailRowRenderer={benchmarkingDetailRowRenderer}
          productCellDecorator={benchmarkingProductCellDecorator}
          name="parts.contractual.products"
          width={12}
        />
      )}
    </Stack>
  );
};

export default ProductsTab;
