import React, { useMemo } from 'react';
import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import { GridHeaderCell } from '@verticeone/design-system';
import { CostModelProductItem } from '../types';
import { useTranslation } from 'react-i18next';
import MultilineHeaderTooltip from './shared/MultilineHeaderTooltip';

export const LicenseCostHeaderCell = (params: GridColumnHeaderParams<CostModelProductItem, any, any>) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.COST_MODEL_PRODUCT_LIST.LICENSE_COST_IN_TERM_TOOLTIP',
  });
  const lines = useMemo(() => [t('LINE_1'), t('LINE_2')], [t]);

  return <GridHeaderCell {...params} tooltip={{ content: <MultilineHeaderTooltip lines={lines} /> }} />;
};
