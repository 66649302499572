import React from 'react';
import { Stack } from '@mui/material';
import { Card } from '@verticeone/design-system';
import { Divider } from '@verticeone/design-system';
import TableLayout from '../BaseCloudTab/Table/TableLayout';
import TableBreadcrumbs from '../BaseCloudTab/Table/TableBreadcrumbs';
import TableByLastNode from './Table/components/TableByLastNode';
import GraphByLastNode from './Graph/components/GraphByLastNode';
import StatsByLastNode from './Stats/StatsByLastNode';

const CloudAccountsTab = () => {
  return (
    <Stack gap={4}>
      <TableBreadcrumbs />
      <Card>
        <StatsByLastNode />
        <Divider />
        <Stack>
          <GraphByLastNode />
          <Divider />
          <TableLayout>
            <TableByLastNode />
          </TableLayout>
        </Stack>
      </Card>
    </Stack>
  );
};

export default CloudAccountsTab;
