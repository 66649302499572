import styled from '@mui/material/styles/styled';
import { Text, Tooltip, TooltipInfoTrigger } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import React from 'react';

type TooltipIconProps = {
  title: string;
  items: {
    label: string;
    value: string;
  }[];
};

const StyledTooltipInfoTrigger = styled(TooltipInfoTrigger)(({ theme }) => ({
  color: theme.palette.neutral.color1,
  opacity: 0.4,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 1,
  },
}));

const CostBreakdownTooltip = ({ title, items }: TooltipIconProps) => {
  const size = 'S';

  const content = (
    <Stack direction="column" gap={1}>
      {items.map((item, index) => (
        <Stack key={index} direction="row" justifyContent="space-between" gap={10}>
          <Text variant="body-regular" size={size}>
            {item.label}
          </Text>
          <Text variant="body-bold" size={size}>
            {item.value}
          </Text>
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Tooltip title={title} content={content} size={size}>
      <StyledTooltipInfoTrigger />
    </Tooltip>
  );
};

export default CostBreakdownTooltip;
