import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import { Text } from '@verticeone/design-system';
import { Card } from '@verticeone/design-system';

import { JournalItemContentComponent } from './types';
import { parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useResolveUsersContextInfo } from '../../../../../../../hooks/useResolveUsersInfo';

type DeltaChange = {
  oldOwner: string;
  newOwner: string;
};

const getDelta = (delta: any[]): DeltaChange | null => {
  for (const deltaItem of delta) {
    const [operation, target, changes] = deltaItem;
    if (operation === 'change' && target === 'Owner') {
      const [from, to] = changes;
      return {
        oldOwner: parseUserRef(from).userId,
        newOwner: parseUserRef(to).userId,
      };
    }
  }

  return null;
};

export const RequestOwnerChangedJournalItem: JournalItemContentComponent = ({ item }) => {
  const { delta, userIds } = useMemo(() => {
    const itemDelta = getDelta(item.delta);
    return {
      delta: itemDelta,
      userIds: itemDelta ? [itemDelta.newOwner, itemDelta.oldOwner] : [],
    };
  }, [item.delta]);

  const { getFullName } = useResolveUsersContextInfo(userIds);

  if (!delta) {
    return null;
  }

  const idToName = (userId: string) => getFullName(userId);
  return (
    <Card padding={4}>
      <Text variant="body-regular" color="text1">
        <Trans
          i18nKey={'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.JOURNAL.OWNER_CHANGE_MESSAGE'}
          components={{ bold: <b /> }}
          values={{
            oldOwner: idToName(delta.oldOwner),
            newOwner: idToName(delta.newOwner),
          }}
        />
      </Text>
    </Card>
  );
};
