import { useMemo, useState, FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@verticeone/design-system';

import type { ProcessDefinition, TaskDefinition } from '../../definitionsTypes';
import {
  getTaskAssignmentConfigurations,
  getTaskFormConfiguration,
  replaceConfigurationInTaskDefinition,
  updateTaskDefinition,
} from '../../definitions/taskDefinition';

import { EditUserTaskForm } from './forms/EditUserTaskForm/EditUserTaskForm';
import { EditUserTaskFormData, editUserTaskFormSchema } from './forms/EditUserTaskForm/schema';
import {
  getAssignmentConfigurationsFromAssignees,
  getAssignmentConfigurationsFromConditionalAssignees,
  getConditionalAssigneesFromAssignmentConfigurations,
  getDefaultAssigneesFromAssignmentConfigurations,
} from './forms/EditUserTaskForm/assignmentOptions';
import {
  EditorDrawer,
  EditorDrawerFooter,
  EditorDrawerHeader,
  EditorDrawerScrollContainer,
  EditorDrawerForm,
} from './EditorDrawer';
import useAddEditInviteContactModal from '../../../preferences/useAddEditInviteContactModal';
import { AvailableVariablesDialog } from './AvailableVariablesDialog';
import { IOMapping } from './forms/EditUserTaskForm/ioMapping';
import { isTaskIOMappingConfiguration } from '../../pocWorkflowSchema';
import { getObjectFromJMESPathExpression, toJMESPathExpression } from './utils';
import useGenericFormDefinition from './useGenericFormDefinition';
import { createNewGenericFormUrn, isArrayEqual } from './forms/GenericForm/utils';
import { useAccountContext } from '../../../../contexts/AccountContext';
import {
  DeployServiceCatalogServiceResourceApiResponse,
  useDeployServiceCatalogServiceResourceMutation,
} from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { createNewGenericFormDefinition } from './forms/GenericForm/utils/createNewGenericFormDefinition';
import useGetWorkflowInfoFromParams from './useGetWorkflowInfoFromParams';

export type EditorConfig = {
  allowContractOwnerAssignment: boolean;
};

type EditUserTaskDrawerProps = {
  isOpen: boolean;
  task?: TaskDefinition;
  close: VoidFunction;
  saveTask: (task: TaskDefinition) => void;
  onDirty: () => void;
  editorConfig: EditorConfig;
  processDefinition?: ProcessDefinition;
  workflowServiceRef?: string;
  isAdvancedWorkflowDefinitionEditModeEnabled?: boolean;
};

const EditUserTaskDrawerContent: FC<Omit<Required<EditUserTaskDrawerProps>, 'isOpen'>> = ({
  isAdvancedWorkflowDefinitionEditModeEnabled,
  onDirty,
  saveTask,
  task,
  close,
  workflowServiceRef,
  processDefinition,
  editorConfig,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR' });
  const { accountId } = useAccountContext();
  const { workflowId, workflowVersion } = useGetWorkflowInfoFromParams();

  const [isVariablesDialogOpen, setIsVariablesDialogOpen] = useState(false);

  const formConfiguration = getTaskFormConfiguration(task);
  const genericFormDefinition = useGenericFormDefinition({ formConfiguration });

  const [deployServiceCatalogFormResource] = useDeployServiceCatalogServiceResourceMutation();

  const values = useMemo<EditUserTaskFormData>(() => {
    if (task === undefined) {
      return {
        name: '',
        defaultAssignees: [],
        description: '',
        buttonLabels: undefined,
        formHeading: undefined,
        conditionalAssignees: [],
        genericFormFields: undefined,
      };
    }

    const assignmentConfigurations = getTaskAssignmentConfigurations(task);
    const ioMapping = task.task.configurations?.find(isTaskIOMappingConfiguration);

    const buttonLabelsParsedExpression = getObjectFromJMESPathExpression<EditUserTaskFormData['buttonLabels']>(
      ioMapping?.mapping.inputFields.find((field) => field.name === 'buttonLabels')?.value
    );
    const formHeadingParsedExpression = getObjectFromJMESPathExpression<EditUserTaskFormData['formHeading']>(
      ioMapping?.mapping.inputFields.find((field) => field.name === 'formHeading')?.value
    );

    return {
      name: task.task.name || '',
      defaultAssignees: getDefaultAssigneesFromAssignmentConfigurations(assignmentConfigurations),
      description: task.task.description || '',
      buttonLabels: buttonLabelsParsedExpression,
      formHeading: formHeadingParsedExpression,
      conditionalAssignees: getConditionalAssigneesFromAssignmentConfigurations(assignmentConfigurations),
      //TODO extract extraProperty (variable mapping) from the input mapping
      genericFormFields: genericFormDefinition?.definition?.FormUIModel?.Layout?.fields ?? [],
    };
  }, [task, genericFormDefinition]);

  const handlers = useForm<EditUserTaskFormData>({
    resolver: zodResolver(editUserTaskFormSchema),
    defaultValues: values,
    values,
  });

  const onSubmit = async (data: EditUserTaskFormData) => {
    const newDefaultAssignmentConfigurations = getAssignmentConfigurationsFromAssignees(data.defaultAssignees);
    const newConditionalAssigneesConfigurations = getAssignmentConfigurationsFromConditionalAssignees(
      data.conditionalAssignees
    );

    const ioMapping = task.task.configurations?.find(isTaskIOMappingConfiguration);

    const newMapping = new IOMapping(ioMapping);

    if (ioMapping) {
      if (data.buttonLabels) newMapping.addInputMapping('buttonLabels', toJMESPathExpression(data.buttonLabels));
      if (data.formHeading) newMapping.addInputMapping('formHeading', toJMESPathExpression(data.formHeading));
    }

    const taskWithIOMapping = replaceConfigurationInTaskDefinition(task, newMapping.getIOMappingConfiguration());

    let newFormConfiguration = formConfiguration;
    if (
      formConfiguration &&
      genericFormDefinition &&
      data.genericFormFields &&
      !isArrayEqual(data.genericFormFields, handlers.formState.defaultValues?.genericFormFields)
    ) {
      const newFormUrn = createNewGenericFormUrn(formConfiguration.formUrn, {
        accountId,
        workflowId,
        workflowVersion,
        taskId: task.task.id,
      });

      // Deploy new form into Service Catalog and update the formUrn
      const formDefinition = createNewGenericFormDefinition(genericFormDefinition, newFormUrn, data.genericFormFields);
      const deployResult = await deployServiceCatalogFormResource({
        accountId: accountId,
        deployServiceCatalogResourceService: {
          definition: JSON.stringify({
            Resources: [formDefinition],
          }),
          definitionType: 'application/json',
        },
      });

      // Update form URN in task definition
      if ((deployResult as { data: DeployServiceCatalogServiceResourceApiResponse }).data) {
        newFormConfiguration = { ...formConfiguration, formUrn: newFormUrn };
      }
    }

    const newTask = updateTaskDefinition(taskWithIOMapping, {
      name: data.name,
      description: data.description,
      assignmentConfigurations: [...newDefaultAssignmentConfigurations, ...newConditionalAssigneesConfigurations],
      formConfiguration: newFormConfiguration,
    });

    saveTask(newTask);
  };

  const { modal, handleAddRequest } = useAddEditInviteContactModal({
    adminMode: false,
    warningMessage: t('EDIT_USER_TASK.NEW_USER_WARNING'),
  });

  return (
    <>
      <FormProvider {...handlers}>
        <EditorDrawerForm onSubmit={handlers.handleSubmit(onSubmit)}>
          <EditorDrawerHeader title={task.task.name || ''} />
          <Stack direction="column" flexGrow={1} width="100%" minHeight={0}>
            <EditorDrawerScrollContainer>
              <EditUserTaskForm
                nodeId={task.task.id}
                processDefinition={processDefinition}
                workflowServiceRef={workflowServiceRef}
                onDirty={onDirty}
                onAddUser={handleAddRequest}
                taskName={task?.task.name}
                formConfiguration={formConfiguration}
                allowContractOwnerAssignment={editorConfig.allowContractOwnerAssignment}
                isAdvancedWorkflowDefinitionEditModeEnabled={isAdvancedWorkflowDefinitionEditModeEnabled}
              />
              {isAdvancedWorkflowDefinitionEditModeEnabled && (
                <Button
                  onClick={() => setIsVariablesDialogOpen(true)}
                  variant="outline"
                  color="secondary"
                  size="S"
                  fullWidth
                >
                  {t('EDIT_GATEWAY.DIALOG.AVAILABLE_VARIABLES')}
                </Button>
              )}
            </EditorDrawerScrollContainer>
            <EditorDrawerFooter onDiscard={close} />
          </Stack>
        </EditorDrawerForm>
      </FormProvider>
      {modal}
      {isVariablesDialogOpen && isAdvancedWorkflowDefinitionEditModeEnabled && (
        <AvailableVariablesDialog
          processDefinition={processDefinition}
          isOpened={isVariablesDialogOpen}
          onClose={() => setIsVariablesDialogOpen(false)}
          nodeId={task.task.id}
          workflowServiceRef={workflowServiceRef}
        />
      )}
    </>
  );
};

export const EditUserTaskDrawer = ({
  isOpen,
  task,
  close,
  saveTask,
  onDirty,
  editorConfig,
  processDefinition,
  workflowServiceRef,
  isAdvancedWorkflowDefinitionEditModeEnabled = false,
}: EditUserTaskDrawerProps) => {
  return (
    <EditorDrawer open={isOpen}>
      {task && processDefinition && workflowServiceRef && (
        <EditUserTaskDrawerContent
          close={close}
          saveTask={saveTask}
          onDirty={onDirty}
          editorConfig={editorConfig}
          task={task}
          isAdvancedWorkflowDefinitionEditModeEnabled={isAdvancedWorkflowDefinitionEditModeEnabled}
          workflowServiceRef={workflowServiceRef}
          processDefinition={processDefinition}
          key={task.task.id}
        />
      )}
    </EditorDrawer>
  );
};
