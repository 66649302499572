import type { Property } from '../../../../types';
import { useTranslation } from 'react-i18next';
import { countProperties } from '../../../../VariableSelector/utils';
import { VariablesGroup } from '../../../../forms/EditGatewayForm/useWorkflowVariablesGroups';
import { OriginsWithProperties } from '../../../../VariableSelector/types';

export const useJiraVariablesGroups = ({ variables }: { variables: Property[] }): VariablesGroup[] => {
  const { t } = useTranslation();

  const originsWithProperties: OriginsWithProperties = {
    jira: {
      origin: {
        id: 'jira-available',
        label: t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.PICKER_GROUPS.JIRA.AVAILABLE'),
        kind: 'task',
      },
      properties: variables,
    },
  };

  return [
    {
      originsWithProperties: originsWithProperties,
      count: countProperties(variables),
      title: t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.PICKER_GROUPS.JIRA.JIRA_VARIABLES'),
      id: 'workflow.request',
    },
  ];
};
