import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system';
import { ChipButton } from '@verticeone/design-system';

import { DataGridColDef } from '@verticeone/design-system';
import { dateTimeFormatterOptions, useFormatDate } from '@verticeone/utils/formatting';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { WorkflowVersionRow } from './types';
import { ActivateButton } from './components/ActivateButton';
import { PreviewButton } from './components/PreviewButton';
import { DuplicateButton } from './components/DuplicateButton';
import { useLocalizeVersionName } from './utils';

export const useDeployedVersionColumns = (): DataGridColDef<WorkflowVersionRow>[] => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const localizeVersionName = useLocalizeVersionName();

  return useMemo(() => {
    const columns: DataGridColDef<WorkflowVersionRow>[] = [
      {
        field: 'versionName',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.VERSION_COLUMN'),
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Stack gap={2} direction="row">
            <Text variant="body-bold">{localizeVersionName(row.versionName)}</Text>
            {row.status === 'ACTIVE' && (
              <ChipButton variant="ghost" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} size="M">
                {t('ENTITIES.WORKFLOW_VERSION.LIVE')}
              </ChipButton>
            )}
          </Stack>
        ),
        valueGetter: ({ row }) => (row.status === 'ACTIVE' ? '9' : row.updatedAt), // value for sorting - live version always on the top
      },
      {
        field: 'updatedAt',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.UPDATED_AT_COLUMN'),
        renderCell: ({ row }) => formatDate(row.updatedAt, { formatterOptions: dateTimeFormatterOptions }),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'lastInvokedAt',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.LAST_INVOKED_AT_COLUMN'),
        renderCell: ({ row }) => formatDate(row.lastInvokedAt, { formatterOptions: dateTimeFormatterOptions }),
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'activeCompletedCounts',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.REQUESTS_COLUMN'),
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => (
          <Stack direction="row" gap={1}>
            <Text variant="body-regular">
              {t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.ACTIVE_COUNT', { count: row.activeInstances })}
            </Text>
            <Text variant="body-regular" color="text3">
              /
            </Text>
            <Text variant="body-regular" color="text3">
              {t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.COMPLETE_COUNT', { count: row.completedInstances })}
            </Text>
          </Stack>
        ),
        flex: 1,
      },
      {
        field: 'actions',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.ACTIONS_COLUMN'),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderSkeletonCell: () => null,
        renderCell: ({ row }) => (
          <Stack direction="row" gap={1}>
            <ActivateButton row={row} />
            <PreviewButton row={row} />
            <DuplicateButton row={row} />
          </Stack>
        ),
      },
    ];
    return columns;
  }, [localizeVersionName, formatDate, t]);
};
