import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Link, Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { VerticeAwsIntegration } from '@vertice/assets';
import { Button } from '@verticeone/design-system';
import { AWS_STEPS, AWSStepProps } from '../AWSSetupWizard';
import { splitByWords } from 'pages/Preferences/Integrations/utils';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';

const AWSStartStep = ({ setStep }: AWSStepProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCancel = () => {
    navigate(-1);
  };
  const onNext = () => setStep(AWS_STEPS.PERMISSION);

  const highlightText = (originalString: string, highlightedWords: { text: string; url: string }[]) => {
    const splitWords = splitByWords(
      originalString,
      highlightedWords.map((word) => word.text)
    );
    return (
      <Box>
        {splitWords.map((word: string, index: number) => {
          const foundHighlight = highlightedWords.find((highlight) => highlight.text === word);
          const isLastHighlight = index === splitWords.lastIndexOf(word);

          return foundHighlight && isLastHighlight ? (
            <Link key={index} href={foundHighlight.url} target="blank" underline="always" variant="link-m">
              {word}
            </Link>
          ) : (
            <Text key={index} variant="body-regular" size="M" color="text1">
              {word}
            </Text>
          );
        })}
      </Box>
    );
  };

  const header = (
    <Stack gap="8px">
      <Text variant="heading" size="M">
        {t('PREFERENCES.INTEGRATIONS.AWS.START_STEP.TITLE')}
      </Text>
      <Text variant="body-regular" size="M" color="text2">
        {t('PREFERENCES.INTEGRATIONS.AWS.START_STEP.SUBTITLE')}
      </Text>
    </Stack>
  );

  const organizationsHL = [
    {
      text: t('PREFERENCES.INTEGRATIONS.AWS.START_STEP.BEST_PRACTICE_LINK'),
      url: 'https://aws.amazon.com/organizations/getting-started/best-practices/',
    },
    {
      text: t('PREFERENCES.INTEGRATIONS.AWS.START_STEP.ORGANIZATIONS_LINK'),
      url: 'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_introduction.html',
    },
  ];
  const billingHL = [
    {
      text: t('PREFERENCES.INTEGRATIONS.AWS.START_STEP.BILLING_PROCESS_LINK'),
      url: 'https://docs.aws.amazon.com/awsaccountbilling/latest/aboutv2/useconsolidatedbilling-procedure.html',
    },
  ];

  const content = (
    <Stack gap="8px">
      <Text variant="caption" size="XS" color="text2">
        {t('PREFERENCES.INTEGRATIONS.AWS.START_STEP.ORGANIZATIONS_PREREQUISITES')}
      </Text>
      {highlightText(t('PREFERENCES.INTEGRATIONS.AWS.START_STEP.ORGANIZATIONS_PREREQUISITES_DETAILS'), organizationsHL)}
      <Text variant="caption" size="XS" color="text2">
        {t('PREFERENCES.INTEGRATIONS.AWS.START_STEP.BILLING_PREREQUISITES')}
      </Text>
      {highlightText(t('PREFERENCES.INTEGRATIONS.AWS.START_STEP.BILLING_PREREQUISITES_DETAILS'), billingHL)}
    </Stack>
  );

  return (
    <WizardStepTemplate
      headerImage={<VerticeAwsIntegration />}
      header={header}
      content={content}
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onCancel}>
          {t('COMMON.CANCEL')}{' '}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption onClick={onNext}>
          {t('COMMON.NEXT')}
        </Button>,
      ]}
    />
  );
};
export default AWSStartStep;
