import type { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';

import type { Property, PropertyOrigin, PropertyType, ServiceUrn } from '../WorkflowEditor/types';
import {
  isXTypeOptionItems,
  JsonSchema,
  Schema,
  XTypeServiceCatalogResource,
  XTypeServiceCatalogResourceDefinition,
} from './types';

export const isUDFService = (kind: any) =>
  typeof kind === 'string' && kind === 'Vertice/ServiceCatalog/Function/FunctionDefinition';

export const isVerticeService = (kind: any) =>
  typeof kind === 'string' && kind === 'Vertice/ServiceCatalog/Service/ServiceDefinition';

export const isXTypeServiceCatalogResource = (
  serviceCatalogResource: ServiceCatalogResource
): serviceCatalogResource is XTypeServiceCatalogResource =>
  serviceCatalogResource.kind === 'Vertice/ServiceCatalog/XType/XTypeDefinition';

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const formatPropertyLabel = (propertyName: string): string => {
  const parts = propertyName.split(/(?=[A-Z])/);
  return parts.map(capitalizeFirstLetter).join(' ');
};

const isUdfPropertyId = (propertyId: string) => propertyId.startsWith('udf');

export const getPropertyId = (propertyName: string, parentPropertyId?: string): string => {
  if (!parentPropertyId) {
    return propertyName;
  }

  if (isUdfPropertyId(parentPropertyId)) {
    return `(${parentPropertyId}).${propertyName}`;
  }

  return `${parentPropertyId}.${propertyName}`;
};

export const PROPERTY_XTYPE_MAP = {
  requestId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/request\/id\/v\d+$/,
    isVisible: false,
  },
  departmentId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/department\/id\/v\d+$/,
    isVisible: true,
  },
  vendor: {
    regex: /urn:verticeone:vertice::services:schema\/saas\/vendor\/v\d+$/,
    isVisible: true,
  },
  vendorId: {
    regex: /urn:verticeone:vertice::services:schema\/saas\/vendor\/id\/v\d+$/,
    isVisible: false,
  },
  product: {
    regex: /urn:verticeone:vertice::services:schema\/core\/product\/v\d+$/,
    isVisible: true,
  },
  productId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/product\/id\/v\d+$/,
    isVisible: false,
  },
  contractId: {
    regex: /urn:verticeone:vertice::services:schema\/saas\/contract\/id\/v\d+$/,
    isVisible: false,
  },
  userId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/user\/id\/v\d+$/,
    isVisible: false,
  },
  accountId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/account\/id\/v\d+$/,
    isVisible: false,
  },
  money: {
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/v\d+$/,
    isVisible: true,
  },
  moneyAmount: {
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/amount\/v\d+$/,
    isVisible: true,
  },
  moneyCurrency: {
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/currency\/v\d+$/,
    isVisible: true,
  },
} as const;

const isPropertyWithXTypeVisible = (xTypeServiceCatalogResource: XTypeServiceCatalogResource) => {
  if (xTypeServiceCatalogResource.definition.Operators.Allowed.length === 0) {
    return false;
  }

  const xTypeDefinition = Object.entries(PROPERTY_XTYPE_MAP).find(([_, { regex }]) =>
    regex.test(xTypeServiceCatalogResource.urn)
  )?.[1];

  if (!xTypeDefinition) {
    // If there are no FE rules for given xType show the variable by default
    return true;
  }

  return xTypeDefinition.isVisible;
};

export const formatPropertyType = ({
  propertyType = [],
  format,
  isEnum,
}: {
  propertyType?: PropertyType[];
  format?: string;
  isEnum?: boolean;
}) => {
  if (isEnum) {
    return ['Dropdown'];
  }

  if (format) {
    return [capitalizeFirstLetter(format)];
  }

  return propertyType.map(capitalizeFirstLetter);
};

const getPropertyTypes = (schema: JsonSchema): PropertyType[] => {
  if (schema.type) {
    return Array.isArray(schema.type) ? schema.type : [schema.type];
  }

  return [];
};

const getIsPropertyVisible = ({ propertyName }: { propertyName: string }) => propertyName !== 'id';

const removeEmptyObjectAndArrayProperties = (property: Property) => {
  if (
    (property.type?.includes('object') || property.type?.includes('array')) &&
    (!('properties' in property) || !property.properties.length)
  ) {
    return false;
  }

  return true;
};

const getPropertyValuesFromXTypeDataSource = (datasource: XTypeServiceCatalogResourceDefinition['Datasource']) => {
  if (!datasource) {
    return undefined;
  }

  // The data source is provided by the XType Datasource Service and will be fetched asynchronously
  if (datasource.Provided) {
    return 'ProvidedAsyncByXTypeDataSource' as const;
  }

  const items = datasource.Items;

  if (!items || !isXTypeOptionItems(items)) {
    return undefined;
  }

  return items.map((item) => ({
    value: item.id,
    name: item.id,
    label: item.title,
  }));
};

const getPropertyValuesFromJsonSchemaEnum = (jsonSchema: JsonSchema) => {
  return jsonSchema.enum?.map((value) => ({
    value,
    name: value,
    label: value,
  }));
};

export const convertJsonSchemaToSchema = ({
  jsonSchema,
  origin,
  parentPropertyId,
  parentPropertyLabel,
  parentPropertyType,
  xTypeServiceCatalogResources,
}: {
  jsonSchema: JsonSchema;
  origin: PropertyOrigin;
  parentPropertyId?: string;
  parentPropertyLabel?: string;
  parentPropertyType?: PropertyType;
  xTypeServiceCatalogResources: XTypeServiceCatalogResource[];
}): Schema => {
  if (parentPropertyType === 'array' && parentPropertyId) {
    const propertyXType = jsonSchema['x-type'];
    const propertyId = (isUdfPropertyId(parentPropertyId) ? `(${parentPropertyId})` : parentPropertyId) + '[0]';
    const propertyLabel = jsonSchema.title || parentPropertyLabel ? `${parentPropertyLabel} - Item` : 'Item';

    const xTypeServiceCatalogResource = xTypeServiceCatalogResources.find(
      (xTypeResource) => xTypeResource.urn === propertyXType
    );

    if (xTypeServiceCatalogResource) {
      const { definition } = xTypeServiceCatalogResource;
      const { JsonSchema: xTypeJsonSchema } = definition.Schema;
      const propertyType = getPropertyTypes(xTypeJsonSchema);

      return {
        properties: [
          {
            id: propertyId,
            label: propertyLabel,
            xType: propertyXType,
            typeLabel: [definition.Name],
            format: xTypeJsonSchema.format,
            type: propertyType,
            values:
              getPropertyValuesFromXTypeDataSource(definition.Datasource) ||
              getPropertyValuesFromJsonSchemaEnum(jsonSchema),
            operators: definition.Operators.Allowed,
            origin,
            isVisible: isPropertyWithXTypeVisible(xTypeServiceCatalogResource),
            properties: jsonSchema.properties
              ? convertPropertiesToSchema({
                  properties: jsonSchema.properties,
                  origin,
                  parentPropertyId: propertyId,
                  xTypeServiceCatalogResources,
                }).properties
              : getNextPropertiesParsingStep({
                  propertyType: propertyType[0],
                  propertyLabel,
                  propertyId,
                  origin,
                  jsonSchema,
                  xTypeServiceCatalogResources,
                }),
          },
        ],
      };
    }

    const propertyType = getPropertyTypes(jsonSchema);
    const typeLabel = formatPropertyType({
      propertyType,
      format: jsonSchema.format,
      isEnum: !!jsonSchema.enum,
    });

    return {
      properties: [
        {
          id: propertyId,
          label: propertyLabel,
          type: propertyType,
          xType: propertyXType,
          format: jsonSchema.format,
          isVisible: getIsPropertyVisible({
            propertyName: propertyId,
          }),
          typeLabel: typeLabel,
          origin,
          values: getPropertyValuesFromJsonSchemaEnum(jsonSchema),
          properties: jsonSchema.properties
            ? convertPropertiesToSchema({
                properties: jsonSchema.properties,
                origin,
                parentPropertyId: propertyId,
                xTypeServiceCatalogResources,
              }).properties
            : getNextPropertiesParsingStep({
                propertyType: propertyType[0],
                propertyLabel,
                propertyId,
                origin,
                jsonSchema,
                xTypeServiceCatalogResources,
              }),
        },
      ],
    };
  }

  if (!jsonSchema.properties) {
    return { properties: [] };
  }

  return convertPropertiesToSchema({
    properties: jsonSchema.properties,
    origin,
    parentPropertyId,
    parentRequired: jsonSchema.required,
    xTypeServiceCatalogResources,
  });
};

const convertPropertiesToSchema = ({
  properties,
  origin,
  parentPropertyId,
  parentRequired,
  xTypeServiceCatalogResources,
}: {
  properties: Record<string, JsonSchema>;
  origin: PropertyOrigin;
  parentPropertyId?: string;
  parentRequired?: string[];
  xTypeServiceCatalogResources: XTypeServiceCatalogResource[];
}): Schema => {
  return {
    properties: Object.entries(properties)
      .map(([name, jsonSchema]) => {
        const propertyXType = jsonSchema['x-type'];
        const propertyId = getPropertyId(name, parentPropertyId);
        const propertyLabel = jsonSchema.title || formatPropertyLabel(name);
        const isPropertyRequired = !!parentRequired?.includes(name);

        const xTypeServiceCatalogResource = xTypeServiceCatalogResources.find(
          (xTypeResource) => xTypeResource.urn === propertyXType
        );

        if (xTypeServiceCatalogResource) {
          const { definition } = xTypeServiceCatalogResource;
          const { JsonSchema: xTypeJsonSchema } = definition.Schema;
          const propertyType = getPropertyTypes(xTypeJsonSchema);

          return {
            id: propertyId,
            label: propertyLabel,
            xType: propertyXType,
            typeLabel: [definition.Name],
            format: xTypeJsonSchema.format,
            type: propertyType,
            origin,
            isVisible: isPropertyWithXTypeVisible(xTypeServiceCatalogResource),
            required: isPropertyRequired,
            values:
              getPropertyValuesFromXTypeDataSource(definition.Datasource) ||
              getPropertyValuesFromJsonSchemaEnum(jsonSchema),
            operators: definition.Operators.Allowed,
            properties: getNextPropertiesParsingStep({
              propertyType: propertyType[0],
              propertyLabel,
              propertyId,
              origin,
              jsonSchema: xTypeJsonSchema,
              xTypeServiceCatalogResources,
            }),
          };
        }

        const propertyType = getPropertyTypes(jsonSchema);
        const typeLabel = formatPropertyType({
          propertyType,
          format: jsonSchema.format,
          isEnum: !!jsonSchema.enum,
        });

        return {
          id: propertyId,
          label: propertyLabel,
          type: propertyType,
          xType: propertyXType,
          format: jsonSchema.format,
          isVisible: getIsPropertyVisible({
            propertyName: name,
          }),
          typeLabel,
          origin,
          required: isPropertyRequired,
          values: getPropertyValuesFromJsonSchemaEnum(jsonSchema),
          properties: getNextPropertiesParsingStep({
            propertyType: propertyType[0],
            propertyLabel,
            propertyId,
            origin,
            jsonSchema,
            xTypeServiceCatalogResources,
          }),
        };
      })
      .filter((property) => property.isVisible)
      .filter(removeEmptyObjectAndArrayProperties),
  };
};

const getUdfInputFromInputJsonSchema = (service: ServiceCatalogResource): string => {
  const inputJsonSchema = service.definition?.FunctionProvider?.Interface?.Input?.JsonSchema;

  if (!inputJsonSchema) {
    return '';
  }

  if (inputJsonSchema.type === 'object' && inputJsonSchema.properties) {
    let udfInput = '{';

    Object.keys(inputJsonSchema.properties).forEach((key, index, array) => {
      if (index > 0 && index < array.length) {
        udfInput += ', ';
      }
      udfInput += `${key}: ${key}`;
    });

    return udfInput + '}';
  }

  return '';
};

const UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP: Record<ServiceUrn, string> = {
  'urn:verticeone:vertice::services:udf/saas/eligible-for-vertice-negotiation':
    'udf(`urn:verticeone:vertice::services:udf/saas/eligible-for-vertice-negotiation`, {approvedBudget: approvedBudget, budgetCurrency: contractCurrency})',
};

const removeVersionFromUrn = (urn: ServiceUrn) => urn.replace(/\/v\d+$/, '');

/*
 * UDFs function calls are not part of the service definition, so we need to manually map them.
 * If the UDF is not in the map, we need to generate the function call based on the input schema.
 * This is temporary until we have a better way to get the UDF function call.
 * It should be built by the user using the UI, but this is not part of this iteration.
 */
const getUdfPropertyId = (service: ServiceCatalogResource) => {
  const serviceUrn = service.urn;

  const serviceUrnWithoutVersion = removeVersionFromUrn(serviceUrn);

  if (serviceUrnWithoutVersion in UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP) {
    return UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP[serviceUrnWithoutVersion];
  }

  const inputJsonSchema = getUdfInputFromInputJsonSchema(service);

  if (!inputJsonSchema) {
    return '';
  }

  return `udf(\`${serviceUrnWithoutVersion}\`, ${inputJsonSchema})`;
};

export const getSchemaFromFunctionDefinition = ({
  udfServiceCatalogResource,
  xTypeServiceCatalogResources,
}: {
  udfServiceCatalogResource: ServiceCatalogResource;
  xTypeServiceCatalogResources: XTypeServiceCatalogResource[];
}): Schema => {
  const jsonSchema = udfServiceCatalogResource.definition?.FunctionProvider?.Interface?.Output?.JsonSchema;

  if (!jsonSchema) {
    return { properties: [] };
  }

  const propertyId = getUdfPropertyId(udfServiceCatalogResource);

  if (!propertyId) {
    return { properties: [] };
  }

  const isPropertyVisible = getIsPropertyVisible({
    propertyName: udfServiceCatalogResource.name,
  });

  if (!isPropertyVisible) {
    return { properties: [] };
  }

  const propertyXType = jsonSchema['x-type'];
  const propertyLabel = udfServiceCatalogResource.name;
  const propertyType = getPropertyTypes(jsonSchema);
  const origin: PropertyOrigin = {
    id: udfServiceCatalogResource.urn,
    kind: 'udf',
    label: udfServiceCatalogResource.name,
  };

  const propertyProperties = getNextPropertiesParsingStep({
    propertyType: propertyType[0],
    propertyLabel,
    propertyId,
    origin,
    jsonSchema,
    xTypeServiceCatalogResources,
  });

  if ((propertyType.includes('object') || propertyType.includes('array')) && propertyProperties.length === 0) {
    return { properties: [] };
  }

  const xTypeServiceCatalogResource = xTypeServiceCatalogResources.find(
    (xTypeResource) => xTypeResource.urn === propertyXType
  );

  if (xTypeServiceCatalogResource) {
    const { definition } = xTypeServiceCatalogResource;
    const { JsonSchema: xTypeJsonSchema } = definition.Schema;

    return {
      properties: [
        {
          id: propertyId,
          label: propertyLabel,
          xType: propertyXType,
          typeLabel: [definition.Name],
          format: xTypeJsonSchema.format,
          type: propertyType,
          origin,
          isVisible: isPropertyWithXTypeVisible(xTypeServiceCatalogResource),
          values:
            getPropertyValuesFromXTypeDataSource(definition.Datasource) ||
            getPropertyValuesFromJsonSchemaEnum(jsonSchema),
          operators: definition.Operators.Allowed,
          properties: propertyProperties,
        },
      ],
    };
  }

  const typeLabel = formatPropertyType({
    propertyType,
    format: jsonSchema.format,
    isEnum: !!jsonSchema.enum,
  });

  return {
    properties: [
      {
        id: propertyId,
        label: propertyLabel,
        type: propertyType,
        xType: propertyXType,
        format: jsonSchema.format,
        isVisible: isPropertyVisible,
        typeLabel,
        origin,
        values: getPropertyValuesFromJsonSchemaEnum(jsonSchema),
        properties: propertyProperties,
      },
    ],
  };
};

const getNextPropertiesParsingStep = ({
  propertyType,
  propertyLabel,
  propertyId,
  origin,
  jsonSchema,
  xTypeServiceCatalogResources,
}: {
  propertyType: PropertyType;
  jsonSchema: JsonSchema;
  origin: PropertyOrigin;
  propertyId: string;
  propertyLabel: string;
  xTypeServiceCatalogResources: XTypeServiceCatalogResource[];
}): Property[] => {
  const properties =
    propertyType === 'array' && jsonSchema.items
      ? convertJsonSchemaToSchema({
          jsonSchema: jsonSchema.items,
          origin,
          parentPropertyId: propertyId,
          parentPropertyLabel: propertyLabel,
          parentPropertyType: 'array',
          xTypeServiceCatalogResources,
        }).properties
      : propertyType === 'object'
      ? convertJsonSchemaToSchema({
          jsonSchema: jsonSchema,
          origin,
          parentPropertyId: propertyId,
          parentPropertyLabel: propertyLabel,
          parentPropertyType: 'object',
          xTypeServiceCatalogResources,
        }).properties
      : [];

  return properties.filter((property) => property.isVisible).filter(removeEmptyObjectAndArrayProperties);
};
