import { DataGrid, DataGridColDef, GridCellSkeleton } from '@verticeone/design-system';
import React, { useCallback, useMemo, useState } from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DepartmentCell } from '@vertice/core/src/modules/saas/contract/components/ContractList/Cells/DepartmentCell';
import { useExchangeToAccountCurrency } from '@vertice/core/src/modules/currency/useExchangeToAccountCurrency';
import useCompletedContractsData from './useCompletedContractsData';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import SavingsCell from './cells/SavingsCell';
import VendorCell from './cells/VendorCell';
import NegotiatedCostCell from './cells/NegotiatedCostCell';
import { NoData } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { Link as RouterLink } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/src';
import { IconWrapper } from '@verticeone/design-system';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import accountCurrencyComparator from './accountCurrencyComparator';
import TotalLabelCell from './cells/footer/TotalLabelCell';
import BoldMoneyCell from './cells/footer/BoldMoneyCell';
import { RowModel } from './types';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GridRowId } from '@mui/x-data-grid-pro';
import PreviousContractDetailRow from './PreviousContractDetailRow/PreviousContractDetailRow';
import { CustomDetailPanelToggle } from './CustomDetailPanelToggle';
import { GridHeaderCell } from '@verticeone/design-system';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '.expanded-column-cell': {
    backgroundColor: theme.palette.input.color2,
  },
  '.expanded-column-cell.MuiDataGrid-withBorderColor': {
    borderColor: theme.palette.core.color3,
  },
})) as typeof DataGrid;

const classes = {
  expandedColumnCell: 'expanded-column-cell',
};

export type CompletedContractsTableProps = {
  department?: string;
};

const CompletedContractsTable = ({ department }: CompletedContractsTableProps) => {
  const { t } = useTranslation();
  const { accountCurrency } = useExchangeToAccountCurrency();

  const { tableRows = [], footerRow, isLoading } = useCompletedContractsData({ department });
  const [expandedRows, setExpandedRows] = useState<GridRowId[]>([]);
  const [expandedColumns, setExpandedColumns] = useState(false);
  const formatCurrency = useFormatCurrency();
  const routes = useRoutes();

  const toggleColumnsVisibility = useCallback(() => {
    setExpandedColumns(!expandedColumns);
  }, [expandedColumns, setExpandedColumns]);

  const columns = useMemo((): DataGridColDef<RowModel>[] => {
    return [
      {
        field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
        headerName: '',
        renderCell: (params) => (params.row.isFooterRow ? <TotalLabelCell /> : <CustomDetailPanelToggle {...params} />),
        renderSkeletonCell: () => null,
        colSpan: ({ row }) => (row.isFooterRow ? 2 : undefined),
        disableColumnMenu: true,
        sortable: false,
        width: 1,
      },
      {
        field: 'vendor',
        headerName: t('ENTITIES.CONTRACT.LABELS.VENDOR'),
        renderCell: ({ row }) => (row.isFooterRow ? null : <VendorCell viewOnContract={row.viewOnContract} />),
        renderSkeletonCell: () => (
          <Stack direction="row" gap={2} alignItems="center">
            <GridCellSkeleton variant="rectangular" width="32px" height="32px" sx={{ borderRadius: 2 }} />
            <GridCellSkeleton width="200px" />
          </Stack>
        ),
        minWidth: 230,
        flex: 1,
      },
      {
        field: 'department',
        headerName: t('ENTITIES.CONTRACT.LABELS.DEPARTMENT'),
        renderCell: ({ row }) => !row.isFooterRow && <DepartmentCell viewOnContract={row.viewOnContract} />,
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'initialVendorProposal',
        headerName: t('ENTITIES.CONTRACT.LABELS.INITIAL_VENDOR_PROPOSAL'),
        width: 230,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderHeader: (params) => (
          <GridHeaderCell
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.INITIAL_VENDOR_PROPOSAL'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.INITIAL_VENDOR_PROPOSAL'),
            }}
            {...params}
          />
        ),
        sortComparator: accountCurrencyComparator,
        renderCell: ({ row }) =>
          row.isFooterRow ? (
            <BoldMoneyCell value={row.initialVendorProposal} currency={accountCurrency} />
          ) : row.initialVendorProposal ? (
            formatCurrency(row.initialVendorProposal.contractCurrencyValue, {
              currency: row.initialVendorProposal.contractCurrency,
              maximumFractionDigits: 0,
            })
          ) : null,
      },
      {
        field: 'negotiatedCost',
        headerName: t('ENTITIES.CONTRACT.LABELS.NEGOTIATED_COST'),
        width: 230,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderHeader: (params) => (
          <GridHeaderCell
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.NEGOTIATED_COST'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.NEGOTIATED_COST'),
            }}
            {...params}
          />
        ),
        sortComparator: accountCurrencyComparator,
        renderCell: ({ row }) =>
          row.isFooterRow ? (
            <BoldMoneyCell value={row.negotiatedCost} currency={accountCurrency} />
          ) : (
            <NegotiatedCostCell value={row.negotiatedCost} row={row.viewOnContract} />
          ),
      },
      {
        field: 'totalSavings',
        headerName: t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS'),
        width: 230,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderHeader: (params) => (
          <GridHeaderCell
            expandable={{
              isExpanded: expandedColumns,
              toggle: toggleColumnsVisibility,
            }}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.TOTAL_SAVINGS'),
            }}
            {...params}
          />
        ),
        sortComparator: accountCurrencyComparator,
        renderCell: ({ row }) =>
          row.isFooterRow ? (
            <SavingsCell
              savingsPercentage={row.totalSavingsPercentage}
              value={row.totalSavings}
              currency={accountCurrency}
              highlightIfSuccess
              bold
            />
          ) : (
            <SavingsCell
              savingsPercentage={row.totalSavings?.savingsPercentage}
              value={row.totalSavings?.contractCurrencyValue}
              currency={row.totalSavings?.contractCurrency}
              highlightIfSuccess
              bold
            />
          ),
      },

      // The expandable columns

      {
        field: 'savingsVsInitial',
        headerName: t('ENTITIES.CONTRACT.LABELS.SAVINGS_VS_INITIAL'),
        width: 180,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderHeader: (params) => (
          <GridHeaderCell
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SAVINGS_VS_INITIAL'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SAVINGS_VS_INITIAL'),
            }}
            {...params}
          />
        ),
        sortComparator: accountCurrencyComparator,
        headerClassName: classes.expandedColumnCell,
        cellClassName: classes.expandedColumnCell,
        renderCell: ({ row }) =>
          row.isFooterRow ? (
            <SavingsCell
              savingsPercentage={
                (row.initialVendorProposal ?? 0) === 0 ? 0 : (row.savingsVsInitial ?? 0) / row.initialVendorProposal
              }
              value={row.savingsVsInitial}
              currency={accountCurrency}
            />
          ) : (
            <SavingsCell
              savingsPercentage={
                (row.initialVendorProposal?.contractCurrencyValue ?? 0) === 0
                  ? 0
                  : (row.savingsVsInitial?.contractCurrencyValue ?? 0) /
                    row.initialVendorProposal!.contractCurrencyValue
              }
              value={row.savingsVsInitial?.contractCurrencyValue}
              currency={row.savingsVsInitial?.contractCurrency}
            />
          ),
      },
      {
        field: 'additionalSavings',
        headerName: t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_SAVINGS'),
        width: 180,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderHeader: (params) => (
          <GridHeaderCell
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_SAVINGS'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.ADDITIONAL_SAVINGS'),
            }}
            {...params}
          />
        ),
        sortComparator: accountCurrencyComparator,
        headerClassName: classes.expandedColumnCell,
        cellClassName: classes.expandedColumnCell,
        renderCell: ({ row }) =>
          row.isFooterRow ? (
            <BoldMoneyCell value={row.additionalSavings} currency={accountCurrency} />
          ) : (
            formatCurrency(row.additionalSavings?.contractCurrencyValue ?? 0, {
              currency: row.additionalSavings?.contractCurrency ?? DEFAULT_CURRENCY,
              maximumFractionDigits: 0,
            })
          ),
      },
      {
        field: 'futureSavings',
        headerName: t('ENTITIES.CONTRACT.LABELS.FUTURE_SAVINGS'),
        width: 180,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderHeader: (params) => (
          <GridHeaderCell
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.FUTURE_SAVINGS'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.FUTURE_SAVINGS'),
            }}
            {...params}
          />
        ),
        sortComparator: accountCurrencyComparator,
        headerClassName: classes.expandedColumnCell,
        cellClassName: classes.expandedColumnCell,
        renderCell: ({ row }) =>
          row.isFooterRow ? (
            <BoldMoneyCell value={row.futureSavings} currency={accountCurrency} />
          ) : (
            formatCurrency(row.futureSavings?.contractCurrencyValue ?? 0, {
              currency: row.futureSavings?.contractCurrency ?? DEFAULT_CURRENCY,
              maximumFractionDigits: 0,
            })
          ),
      },
      {
        field: 'scopeReduction',
        headerName: t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION'),
        width: 180,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderHeader: (params) => (
          <GridHeaderCell
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SCOPE_REDUCTION'),
            }}
            {...params}
          />
        ),
        sortComparator: accountCurrencyComparator,
        headerClassName: classes.expandedColumnCell,
        cellClassName: classes.expandedColumnCell,
        renderCell: ({ row }) =>
          row.isFooterRow ? (
            <BoldMoneyCell value={row.scopeReduction} currency={accountCurrency} />
          ) : (
            formatCurrency(row.scopeReduction?.contractCurrencyValue ?? 0, {
              currency: row.scopeReduction?.contractCurrency ?? DEFAULT_CURRENCY,
              maximumFractionDigits: 0,
            })
          ),
      },
    ];
  }, [accountCurrency, expandedColumns, formatCurrency, t, toggleColumnsVisibility]);

  return (
    <StyledDataGrid<RowModel>
      color="neutral"
      columns={columns}
      rows={tableRows}
      loading={isLoading}
      loadingStyle="skeleton"
      disableRowSelectionOnClick
      getRowHeight={() => 60}
      autoHeight
      noBorder
      noBorderRadius
      getDetailPanelContent={({ row }) => {
        if (row.isFooterRow || !row.viewOnContract?.contract.materialized?.prev) {
          return null;
        }

        return <PreviousContractDetailRow contract={row.viewOnContract.contract} />;
      }}
      getDetailPanelHeight={() => 'auto'}
      detailPanelExpandedRowIds={expandedRows}
      onDetailPanelExpandedRowIdsChange={setExpandedRows}
      noRowsOverlayHeight={250}
      multilineHeaders
      columnVisibilityModel={{
        savingsVsInitial: expandedColumns,
        additionalSavings: expandedColumns,
        futureSavings: expandedColumns,
        scopeReduction: expandedColumns,
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'totalSavings', sort: 'desc' }],
        },
      }}
      pinnedRows={
        footerRow && {
          bottom: [footerRow],
        }
      }
      disableVirtualization // caused issue in combination with Total row colspan when horizontally scrolled to the end of the table
      slots={{
        noRowsOverlay: () => (
          <NoData
            icon={<IconWrapper size="XL" color="error" icon={DocumentScannerOutlinedIcon} />}
            header={t('SAAS.SAVINGS.COMPLETED_CONTRACTS_CARD.NO_DATA.TITLE')}
            content={t('SAAS.SAVINGS.COMPLETED_CONTRACTS_CARD.NO_DATA.DESCRIPTION')}
            button={
              <Button component={RouterLink} to={routes.CONTRACTS.ABSOLUTE_PATH} variant="solid" color="neutral">
                {t('SAAS.SAVINGS.COMPLETED_CONTRACTS_CARD.NO_DATA.VISIT_CONTRACTS_PAGE')}
              </Button>
            }
          />
        ),
      }}
    />
  );
};

export default CompletedContractsTable;
