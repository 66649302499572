import { Box, Link, LinkProps, styled } from '@mui/material';
import { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { Link as RouterLink, To } from 'react-router-dom';

import { Text } from '@verticeone/design-system';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { JIRA_INTEGRATION_GUIDE_URL } from './constants';

type StyledLinkProps = LinkProps & { to?: To };

export const StyledLink = styled(
  forwardRef<HTMLAnchorElement, StyledLinkProps>((props: StyledLinkProps, ref) => (
    <Link {...props} ref={props.ref ?? ref} />
  ))
)(({ theme }) => ({
  color: theme.palette.text.color3,
  textDecorationColor: theme.palette.text.color3,
  '&:hover': {
    textDecoration: 'none',
  },
}));

export const FooterLink = () => {
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  return (
    <Box p={6}>
      <Text variant="body-regular" size="XS" color="inactive">
        <Trans
          i18nKey="INTELLIGENT_WORKFLOWS.JIRA.FOOTER"
          components={{
            a1: (
              <StyledLink
                component={RouterLink}
                to={generatePath(routes.PREFERENCES.TAB.JIRA.PATH, { activeTab: 'integrations' })}
              />
            ),
            a2: <StyledLink href={JIRA_INTEGRATION_GUIDE_URL} />,
          }}
        />
      </Text>
    </Box>
  );
};
