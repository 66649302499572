import { useGetRequestQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import {
  isCurrentRequest,
  isWorkflowInstanceRef,
  parseRequestRef,
  parseWorkflowInstanceRef,
  parseWorkflowVersionRef,
} from '@vertice/core/src/hooks/workflows/refUtils';
import { useGetWorkflowInstanceQuery } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useGetWorkflowVersionQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';

export const useRequestDetails = (requestId: string, accountId: string) => {
  const {
    data: requestData,
    isLoading: isLoadingRequest,
    refetch: refetchRequest,
  } = useGetRequestQuery({
    accountId,
    requestId,
  });

  useNotificationWebSocketSubscription({
    filter: isCurrentRequest(requestId),
    callback: () => {
      void refetchRequest();
    },
  });

  const request = requestData?.request;
  const parentRequestRef = (request as any)?.parentRequestRef;
  const parentRequestId = parentRequestRef ? parseRequestRef(parentRequestRef).requestId : undefined;
  const parentRequestAccountId = parentRequestRef ? parseRequestRef(parentRequestRef).accountId : undefined;

  const { data: requestParentData, isLoading: isLoadingParentRequest } = useGetRequestQuery(
    {
      accountId: parentRequestAccountId!,
      requestId: parentRequestId!,
    },
    { skip: !parentRequestId || !parentRequestAccountId }
  );
  const parentRequest = parentRequestId ? requestParentData?.request : undefined;

  const isExecutedByWorkflow = request?.executionRef ? isWorkflowInstanceRef(request?.executionRef) : false;
  const workflowInstanceId = request?.executionRef
    ? parseWorkflowInstanceRef(request?.executionRef).workflowInstanceId
    : undefined;

  const { data: instanceData, isLoading: isLoadingInstance } = useGetWorkflowInstanceQuery(
    {
      accountId,
      workflowInstanceId: workflowInstanceId!,
    },
    { skip: !isExecutedByWorkflow }
  );

  const workflowRef = instanceData?.workflowRef;
  const workflowVersionParams = workflowRef ? parseWorkflowVersionRef(workflowRef) : undefined;
  const { data: workflowVersionData, isLoading: isLoadingWorkflowVersion } = useGetWorkflowVersionQuery(
    { accountId: accountId!, ...workflowVersionParams! },
    { skip: !workflowVersionParams }
  );

  return {
    request,
    parentRequest,
    isLoading: isLoadingRequest || isLoadingParentRequest || isLoadingInstance || isLoadingWorkflowVersion,
    workflowVersion: workflowVersionData,
    isExecutedByWorkflow,
  };
};
