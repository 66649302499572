import { useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { FC, ReactNode } from 'react';

type FormFieldDescriptionProps = {
  children: ReactNode | ReactNode[];
};

export const FormFieldDescription: FC<FormFieldDescriptionProps> = (props) => {
  const { children } = props;
  const theme = useTheme();

  return (
    <Text color={theme.palette.text.color2} variant="body-regular" size="S">
      {children}
    </Text>
  );
};
