import { FieldDef, FieldType } from '../types';
import { ShortTextFieldType } from './types';
import { ShortTextFieldBuilderCard } from './ShortTextFieldBuilderCard';
import { ShortTextFieldPresenter } from './ShortTextFieldPresenter';
import { getShortTextJsonSchema, getShortTextPresenterDefault, getShortTextPresenterSchema } from './utils';
import { ShortTextFieldBuilderForm } from './ShortTextFieldBuilderForm';

export const fieldDef: FieldDef<ShortTextFieldType> = {
  type: FieldType.SHORT_TEXT,
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.SHORT_TEXT',
  builderCard: ShortTextFieldBuilderCard,
  builderForm: ShortTextFieldBuilderForm,
  presenter: ShortTextFieldPresenter,
  getPresenterDefault: getShortTextPresenterDefault,
  getPresenterSchema: getShortTextPresenterSchema,
  getJsonSchema: getShortTextJsonSchema,
};
