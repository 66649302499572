import { useGetAssignableUsers } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useGetAssignableUsers';
import { FieldValues, useFormContext, useWatch } from 'react-hook-form';
import React, { FC, useMemo } from 'react';
import { formatUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import UserSelect from '@vertice/core/src/modules/user/UserSelect';
import { CommonFormFieldProps } from '@vertice/core/src/modules/forms/types';

type RequestOwnerSelectFormEntryProps = CommonFormFieldProps<FieldValues>;

export const RequestOwnerSelectFormEntry: FC<RequestOwnerSelectFormEntryProps> = ({ name, disabled }) => {
  const selectedOwner = useWatch<FieldValues>({ name });
  const users = useMemo(() => (selectedOwner ? [selectedOwner] : []), [selectedOwner]);

  const { assignableUsers, isLoadingAssignableUsers, originalUsers: selectedUsers } = useGetAssignableUsers(users);
  const { setValue } = useFormContext<FieldValues>();

  const handleChange = (newUsers: any) => {
    if (newUsers) {
      setValue(name, formatUserRef(newUsers?.id), { shouldValidate: true });
    }
  };

  return (
    <UserSelect
      availableUsers={assignableUsers}
      selectedUsers={selectedUsers}
      isLoading={isLoadingAssignableUsers}
      onChange={handleChange}
      color="secondary"
      isDisabled={disabled}
    />
  );
};
