import { useShoppingCart } from '../../providers/ShoppingCartProvider';
import { calculateShoppingCartUnits } from '../utils';
import { ReactNode, useEffect } from 'react';
import { useOfferings } from '../../providers/OfferingsProvider';
import { GraphLayoutProps } from '../../Graph/GraphLayout';

type BuySPDrawerGraphProps = {
  chart: ({ shoppingCartUnits }: GraphLayoutProps) => ReactNode;
};

const BuySPDrawerGraph = ({ chart }: BuySPDrawerGraphProps) => {
  const { cartItems, filterByShoppingCart } = useShoppingCart();
  const { offerings } = useOfferings({ filterByShoppingCart });

  useEffect(() => {
    offerings.fetch();
  }, [offerings]);

  const recommendedOffering = offerings.data?.[0];

  const shoppingCartUnits = calculateShoppingCartUnits(filterByShoppingCart ? cartItems : [recommendedOffering]);

  const ChartComponent = chart;

  return <ChartComponent shoppingCartUnits={shoppingCartUnits} forceOnDemandUsageOnly={true} />;
};

export default BuySPDrawerGraph;
