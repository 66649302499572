import { createContext, useState, useContext, PropsWithChildren } from 'react';
import { OfferingData } from '../../types';

export type ShoppingCartItemType = OfferingData;

type ShoppingCartContextType = {
  cartItems: ShoppingCartItemType[];
  addToCart: (item: ShoppingCartItemType) => void;
  updateCart: (item: ShoppingCartItemType) => void;
  removeFromCart: (id: string) => void;
  filterByShoppingCart: boolean;
  setFilterByShoppingCart: (value: boolean) => void;
};

const ShoppingCartContext = createContext<ShoppingCartContextType>({} as ShoppingCartContextType);

export const ShoppingCartProvider = ({ children }: PropsWithChildren) => {
  const [cartItems, setCartItems] = useState<ShoppingCartItemType[]>([]);
  const [filterByShoppingCart, setFilterByShoppingCart] = useState<boolean>(false);

  const addToCart = (item: ShoppingCartItemType) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find((prevItem) => prevItem.offeringId === item.offeringId);

      if (existingItem) {
        return prevItems.map((prevItem) =>
          prevItem.offeringId === item.offeringId
            ? {
                ...prevItem,
                ...item,
              }
            : prevItem
        );
      } else {
        // add new item to cart
        return [...prevItems, item];
      }
    });
  };

  const updateCart = (item: ShoppingCartItemType) => {
    setCartItems((prevItems) =>
      prevItems.map((prevItem) => (prevItem.offeringId === item.offeringId ? item : prevItem))
    );
  };

  const removeFromCart = (id: string) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.offeringId !== id));
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        cartItems,
        addToCart,
        updateCart,
        removeFromCart,
        filterByShoppingCart,
        setFilterByShoppingCart,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};

export const useShoppingCart = () => useContext(ShoppingCartContext);
