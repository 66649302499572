import { useGetServiceCatalogResourceQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { FC, useCallback, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { Loader } from '@verticeone/design-system';
import { TaskCustomForm } from './TaskCustomForm';
import { parseFormUrn } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/forms/GenericForm/utils/createNewGenericFormUrn';
import { useDynamicFormPresenterConfig } from '@vertice/core/src/modules/forms/dynamicForms/DynamicFormPresenter/hooks/useDynamicFormPresenterConfig';

const emptyFormSchema = { fields: [] };

export type TaskCustomFormSectionProps = {
  formUrn: string;
  onSubmit: (values: FieldValues) => Promise<void>;
};

export const TaskCustomFormSection: FC<TaskCustomFormSectionProps> = (props) => {
  const { formUrn, onSubmit } = props;

  const { accountId } = parseFormUrn(formUrn);

  const { data: serviceCatalogResource, isLoading: isServiceCatalogResourceLoading } =
    useGetServiceCatalogResourceQuery({
      accountId: accountId ?? 'GLOBAL',
      resourceUrn: formUrn,
    });

  const formSchema = useMemo(() => {
    if (!serviceCatalogResource) return null;
    return serviceCatalogResource.definition?.FormUIModel?.Layout ?? emptyFormSchema;
  }, [serviceCatalogResource]);

  const handleSubmit = useCallback(
    async (values: FieldValues) => {
      await onSubmit(values);
    },
    [onSubmit]
  );

  const formConfigQuery = useDynamicFormPresenterConfig('readWrite');

  if (isServiceCatalogResourceLoading || formConfigQuery.isLoading) return <Loader />;

  return (
    serviceCatalogResource && (
      <TaskCustomForm formSchema={formSchema} config={formConfigQuery.data} onSubmit={handleSubmit} />
    )
  );
};
