import React from 'react';

import { Card } from '@verticeone/design-system';

import VendorDiligenceInsights from '@vertice/core/src/modules/vendor/VendorDiligenceInsights/VendorDiligenceInsights';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { WidgetProps } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/widgets/shared/types';

export const DiligenceInsightsSection = ({ widgetData }: WidgetProps) => {
  const { accountId } = useAccountContext();
  const requestVendorId = widgetData?.vendor?.id;

  return (
    <Card>
      <VendorDiligenceInsights vendorId={requestVendorId} accountId={accountId} />
    </Card>
  );
};
