import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material';
import {
  GridPaginationModel,
  GridRow,
  GridRowClassNameParams,
  GridRowHeightParams,
  GridRowModel,
} from '@mui/x-data-grid-pro';

import { DataGrid, DataGridColDef, DataGridProps } from '@verticeone/design-system';
import { NoData } from '@verticeone/design-system';

import { useRequestsNavigation } from '../hooks/useRequestsNavigation';
import { RequestRow } from './types';
import { useGroupingColumn } from './useRequestsColumns';
import { GridSortModel } from '@mui/x-data-grid-pro';

const StyledDataGrid = styled(DataGrid<RequestRow>)(({ theme }) => ({
  '& .MuiDataGrid-cell:first-child': {
    paddingLeft: 4,
  },
  '& .child-row': {
    backgroundColor: theme.palette.core.color1,
    '&, &:hover, &.Mui-hovered': {
      backgroundColor: theme.palette.core.color1,
    },
  },
  '.MuiDataGrid-cell--withRightBorder': {
    borderRight: 'none',
  },
}));

const ColumnLink = styled(Link)(() => {
  return {
    display: 'block',
    width: 'calc(100% + 16px)',
    textDecoration: 'unset',
    color: 'unset',
    marginLeft: -4,
    paddingLeft: 4,
    paddingBlock: 12,
    marginRight: -16,
  };
});

type RequestGridBaseProps = {
  requests: RequestRow[];
  requestIds: Set<unknown> | Set<string>;
  loading: boolean;
  pageSize: number;
  page: number;
  columns: DataGridColDef<RequestRow>[];
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  defaultSortModel: GridSortModel;
} & Pick<DataGridProps, 'noBorder' | 'noBorderRadius' | 'onStateChange'>;

export const RequestGrid: FC<RequestGridBaseProps> = ({
  requests,
  requestIds,
  loading,
  pageSize,
  page,
  columns,
  paginationModel,
  setPaginationModel,
  defaultSortModel,
  ...gripProps
}) => {
  const { t } = useTranslation();

  const groupingColumn = useGroupingColumn();

  const { generateRequestPath } = useRequestsNavigation();

  const rowIdGetter = (row: GridRowModel<RequestRow>) => row.requestId;
  const getTreeDataPath = useCallback(
    ({ parentId: parent, requestId }: GridRowModel<RequestRow>) =>
      parent && requestIds.has(parent) ? [parent, requestId] : [requestId],
    [requestIds]
  );

  const getRowClassName = useCallback(
    ({ row }: GridRowClassNameParams<RequestRow>) => {
      return row.parentId && requestIds.has(row.parentId) ? 'child-row' : '';
    },
    [requestIds]
  );
  const getRowHeight = useCallback(
    ({ model }: GridRowHeightParams) => {
      return model.parentId && requestIds.has(model.parentId) ? 60 : 74;
    },
    [requestIds]
  );

  return (
    <StyledDataGrid
      treeData
      defaultGroupingExpansionDepth={2}
      getRowClassName={getRowClassName}
      rows={requests}
      columns={columns}
      getRowId={rowIdGetter}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColumn}
      sortingMode="client"
      disableRowSelectionOnClick
      rowSelection={false}
      noRowsOverlayHeight={200}
      getRowHeight={getRowHeight}
      autoHeight
      pagination
      paginationMode="client"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      isGroupExpandedByDefault={() => false}
      slots={{
        row: (props) =>
          props.row.requestId ? (
            <ColumnLink to={generateRequestPath(props.row.requestId, props.row.isLegacy)} sx={{ padding: 0 }}>
              <GridRow {...props} />
            </ColumnLink>
          ) : (
            <GridRow {...props} />
          ),
        noRowsOverlay: () => <NoData header={t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.NO_REQUESTS')} />,
      }}
      initialState={{
        pagination: { paginationModel: { pageSize, page } },
        sorting: {
          sortModel: defaultSortModel,
        },
      }}
      color="neutral"
      loadingStyle="skeleton"
      loading={loading}
      {...gripProps}
    />
  );
};
