import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import CardTitle from '../CardTitle';
import { getDateRange } from './utils';
import ActiveUsersGraph from './ActiveUsersGraph';
import { Card, CardHeader } from '@verticeone/design-system';
import { getFromToDates } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import { ButtonGroup } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';

type ActiveUserProps = {
  application: ApplicationWithMeasureReports;
};

export type Period = '2MONTHS' | 'HALFYEAR' | 'YEAR';

const getKeyForPeriod = (period: Period) => {
  switch (period) {
    case '2MONTHS':
      return 0;
    case 'HALFYEAR':
      return 1;
    case 'YEAR':
      return 2;
  }
};

const getFromDate = (to: dayjs.Dayjs, period: Period) => {
  switch (period) {
    case '2MONTHS':
      return to.subtract(2 * 30, 'day');
    case 'HALFYEAR':
      return to.subtract(6, 'month');
    case 'YEAR':
      return to.subtract(12, 'month');
  }
};

const ActiveUser = ({ application }: ActiveUserProps) => {
  const { t } = useTranslation();
  const { dayAfterReportGenerated } = getFromToDates(application?.measureReports?.generatedAt);
  const [period, setPeriod] = useState<Period>('2MONTHS');
  const to = useMemo(() => dayjs(dayAfterReportGenerated), [dayAfterReportGenerated]);
  const from = useMemo(() => getFromDate(to, period), [to, period]);
  const dateRange = useMemo(() => getDateRange(from, to, period), [from, period, to]);
  const { palette } = useTheme();

  const lastIncompleteBin = useMemo(() => {
    const dataAvailableFromStr = application?.dataCollectionStart || '';
    if (!dataAvailableFromStr) return -1;
    const dataAvailableFrom = dayjs(dataAvailableFromStr);
    const firstAfter = dateRange.findIndex((date) => date.isAfter(dataAvailableFrom)) || 0;
    return firstAfter - 1;
  }, [application, dateRange]);

  const loginEvents =
    period === '2MONTHS'
      ? application?.measureReports?.uniqueUserAccessCountLast56dBin7dLoginEvent
      : application?.measureReports?.uniqueUserAccessCountLast12mBin1mLoginEvent;
  const authEvents =
    period === '2MONTHS'
      ? application?.measureReports?.uniqueUserAccessCountLast56dBin7dAuthEvent
      : application?.measureReports?.uniqueUserAccessCountLast12mBin1mAuthEvent;

  const filteredData = useMemo(
    () => ({
      labels: dateRange.map((date) => date.format('MMM DD')),
      logins: dateRange.map((key) =>
        parseInt(loginEvents?.find(({ binStart }) => binStart === key.format('YYYY-MM-DD'))?.value ?? '0', 10)
      ),

      authorizations: dateRange.map((key) =>
        parseInt(authEvents?.find(({ binStart }) => binStart === key.format('YYYY-MM-DD'))?.value ?? '0', 10)
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [application, period]
  );

  return (
    <Card>
      <CardHeader>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <CardTitle header={t('APPLICATIONS.USAGE.GRAPH.HEADER')} variant="body-bold-s"></CardTitle>
          <ButtonGroup size="XS" activeButtonKeys={getKeyForPeriod(period)} variant="outline">
            <Button onClick={() => setPeriod('2MONTHS')}>{t('APPLICATIONS.USAGE.GRAPH.LAST_2_MONTHS')}</Button>
            <Button onClick={() => setPeriod('HALFYEAR')}>{t('APPLICATIONS.USAGE.GRAPH.LAST_6_MONTHS')}</Button>
            <Button onClick={() => setPeriod('YEAR')}>{t('APPLICATIONS.USAGE.GRAPH.LAST_12_MONTHS')}</Button>
          </ButtonGroup>
        </Stack>
      </CardHeader>
      <Box py={4} sx={{ backgroundColor: palette.core.color1 }}>
        <ActiveUsersGraph
          logins={filteredData.logins}
          authorizations={filteredData.authorizations}
          labels={filteredData.labels}
          license={application?.license}
          lastIncompleteBin={lastIncompleteBin}
        />
      </Box>
    </Card>
  );
};

export default ActiveUser;
