import {
  gridDetailPanelExpandedRowIdsSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { GridStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { IconButton } from '@verticeone/design-system';
import { ChevronRightOutlined, ExpandMoreOutlined } from '@mui/icons-material';

export const CustomDetailPanelToggle = (props: Pick<GridRenderCellParams, 'id' | 'row' | 'api'>) => {
  const {
    id,
    api: { state },
  } = props;
  const apiRef = useGridApiContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTAKE_FORM.PRODUCTS_TABLE.TOGGLE_DETAIL' });

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  const isExpanded = gridDetailPanelExpandedRowIdsSelector(state as GridStatePro).includes(props.id);

  if (!hasDetail) {
    return null;
  }

  return (
    <IconButton
      icon={isExpanded ? ExpandMoreOutlined : ChevronRightOutlined}
      size="S"
      testId="detail-panel-toggle"
      tabIndex={-1}
      variant="plain"
      aria-label={isExpanded ? t('CLOSE') : t('OPEN')}
    />
  );
};
