import { FC, useState } from 'react';
import { Stack, styled } from '@mui/material';
import { EditOutlined, CheckOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Text, Button, ifExpression, IconButton } from '@verticeone/design-system';
import { type FieldArrayWithId, useFormContext, useFormState } from 'react-hook-form';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import FormTextArea from '@vertice/core/src/modules/forms/fields/FormTextArea';

import type { EditGatewayFormData } from './schema';
import type { NodeId, Property, ServiceUrn } from '../../types';
import type { Gateway, ProcessDefinition } from '../../../../definitionsTypes';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { QueryBuilder } from './QueryBuilder/QueryBuilder';
import { getEdgeLetter } from './utils';

type EdgeNameButtonProps = {
  $withIconOnHover: boolean;
};

const EdgeNameButton = styled(Button)<EdgeNameButtonProps>(({ $withIconOnHover }) => ({
  display: 'block',
  padding: '0px !important',
  ...ifExpression($withIconOnHover, {
    '& svg': {
      visibility: 'hidden',
    },
    '&:hover': {
      '& svg': {
        visibility: 'visible',
      },
    },
  }),
}));

type EdgeFieldContainerProps = {
  $withRedBorder?: boolean;
};

const EdgeFieldContainer = styled(Stack)<EdgeFieldContainerProps>(({ theme, $withRedBorder = false }) => ({
  backgroundColor: theme.palette.input.color1,
  padding: theme.spacing(4),
  borderRadius: 12,
  outline: $withRedBorder ? `2px solid ${theme.palette.error.color3}` : 'none',
}));

type EdgeFieldProps = {
  nodeId: NodeId;
  workflowServiceRef: ServiceUrn;
  processDefinition: ProcessDefinition;
  edge: FieldArrayWithId<EditGatewayFormData, 'edges'>;
  index: number;
  requestProperties: Property[];
  globalUDFProperties: Property[];
  gatewayType: Gateway['gatewayType'];
};

export const EdgeField: FC<EdgeFieldProps> = ({ edge, index, requestProperties, globalUDFProperties, gatewayType }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_GATEWAY' });

  const { setValue, watch } = useFormContext<EditGatewayFormData>();
  const formState = useFormState<EditGatewayFormData>();
  const hasConditionError = !!formState.errors.edges?.[index]?.condition;

  const [isEditingEdgeName, setIsEditingEdgeName] = useState(false);

  const isEdgeFromExclusiveGateway = gatewayType === 'Exclusive';
  const isEdgeFromJoinGateway = gatewayType === 'Join';
  const isEdgeFromForkGateway = gatewayType === 'Fork';

  const isDefaultEdge = edge.conditionType === 'DefaultFlow';
  const isJsonLogicCondition = edge.conditionType === 'JsonLogic';

  const edgeLabelBase = t('EDGES.LABEL', { letter: getEdgeLetter(index) });
  const edgeLabel =
    isDefaultEdge && isEdgeFromExclusiveGateway ? edgeLabelBase + ` ${t('EDGES.IF_ELSE')}` : edgeLabelBase;

  const isExpressionEditorDisplayed = !!requestProperties.length && !!globalUDFProperties.length;

  return (
    <EdgeFieldContainer
      $withRedBorder={hasConditionError}
      gap={isDefaultEdge && isEdgeFromExclusiveGateway ? 1 : 6}
      key={edge.id}
    >
      <Stack gap={1} alignItems="flex-start">
        <Text size="XXS" color="text3" variant="label">
          {edgeLabel}
        </Text>
        {isEditingEdgeName ? (
          <Stack direction="row" gap={2}>
            <FormTextField<EditGatewayFormData>
              id={`edge-name-text-field-${index}`}
              name={`edges.${index}.name`}
              size="S"
              color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
              placeholder={t('EDGES.PLACEHOLDER')}
            />
            <IconButton variant="outline" size="S" icon={CheckOutlined} onClick={() => setIsEditingEdgeName(false)} />
          </Stack>
        ) : (
          <EdgeNameButton
            $withIconOnHover={!!watch(`edges.${index}.name`)}
            size="S"
            onClick={() => setIsEditingEdgeName(true)}
            variant="plain"
          >
            {watch(`edges.${index}.name`) || t('EDGES.PLACEHOLDER')} <EditOutlined />
          </EdgeNameButton>
        )}
      </Stack>
      {!isEdgeFromJoinGateway && (
        <>
          {isDefaultEdge && isEdgeFromExclusiveGateway ? (
            <Text variant="body-regular" color="text3" size="S">
              {t('EDGES.DEFAULT_DESCRIPTION')}
            </Text>
          ) : (
            isExpressionEditorDisplayed && (
              <>
                {isDefaultEdge || isJsonLogicCondition ? (
                  <QueryBuilder
                    globalUDFProperties={globalUDFProperties}
                    requestProperties={requestProperties}
                    onConditionChange={(newCondition, newConditionType) => {
                      setValue(`edges.${index}.condition`, newCondition);
                      if (newConditionType) {
                        setValue(`edges.${index}.conditionType`, newConditionType);
                      }
                    }}
                    initialCondition={isDefaultEdge ? undefined : edge.condition}
                    isConditionRemovalEnabled={isEdgeFromForkGateway}
                  />
                ) : (
                  <FormTextArea<EditGatewayFormData>
                    id={`edge-condition-text-area-${index}`}
                    size="S"
                    label={edge.conditionType}
                    variant="outline"
                    color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                    name={`edges.${index}.condition`}
                  />
                )}
              </>
            )
          )}
        </>
      )}
    </EdgeFieldContainer>
  );
};
