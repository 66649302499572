export type SlackRequestor = {
  userName: string;
  linkToMessage: string;
};

export function isSlackRequestor(sourceRef?: string): boolean {
  return !!sourceRef?.split(':')[4]?.startsWith('integrations/slack');
}

export function isVerticeRequestor(sourceRef?: string): boolean {
  if (!sourceRef) return true;
  return sourceRef?.split(':')[4] === 'cfa';
}

export function parseSlackUser(requestorRef?: string, sourceRef?: string): SlackRequestor | undefined {
  // requestorRef = urn:verticeone:vertice:{account_id}:integrations/slack/{user_id}/{user_email}/{user_name_base64}
  // sourceRef = "urn:verticeone:vertice:{account_id}:integrations/slack/newRequestCommand/{channel_id}/{msg_ts::optional}/{msg_link_base64::optional}"

  const sourceId = sourceRef?.split(':')[4];
  const isFromSlack = sourceId?.startsWith('integrations/slack');
  const requestorParts = requestorRef?.split('/');

  if (!isFromSlack || !requestorParts) return;

  const slackUser = requestorParts[4] ? `${atob(requestorParts[4])} (${requestorParts[3]})` : requestorParts[3];
  const slackMsgLinkEncoded = sourceId?.split('/')?.[5];
  const slackMsgLink = slackMsgLinkEncoded ? atob(slackMsgLinkEncoded) : undefined;

  return {
    userName: slackUser,
    linkToMessage: slackMsgLink,
  } as SlackRequestor;
}
