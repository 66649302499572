import { useIssueIdentityTokenForRealmForSelfMutation } from '@vertice/slices';
import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import ErrorIcon from '@mui/icons-material/Error';
import { Loader } from '@verticeone/design-system';
import { Container } from '@mui/material';

const ZENDESK_JWT_TOKEN_URL = 'https://vertice.zendesk.com/access/jwt';

type LoginState = {
  state: string;
  jwtToken?: string;
};

const ErrorLoginState: LoginState = { state: 'ERROR' };

const ZendeskSSOLogin = () => {
  const [issueIdentityTokenForSelf] = useIssueIdentityTokenForRealmForSelfMutation();
  const [loginState, setLoginState] = useState<LoginState>({ state: 'STARTED' });
  const { accountId } = useAccountContext();
  const formRef = React.createRef<HTMLFormElement>();
  const [searchParams] = useSearchParams();

  const return_to = searchParams.get('return_to') || '';

  useEffect(() => {
    const doFederatedLogin = async () => {
      const tokenResponse = await issueIdentityTokenForSelf({ realm: 'zendesk-jwt-sso', accountId });
      if ('data' in tokenResponse) {
        const zendeskIdentityToken = tokenResponse.data.identityToken;
        setLoginState({ state: 'TOKEN_OBTAINED', jwtToken: zendeskIdentityToken });
      } else {
        throw new Error('Failed to issue Zendesk identity token');
      }
    };

    doFederatedLogin().catch((e) => {
      setLoginState(ErrorLoginState);
    });
  }, [accountId, issueIdentityTokenForSelf]);
  useEffect(() => {
    if (loginState.state === 'TOKEN_OBTAINED') {
      formRef.current?.submit();
    }
  }, [formRef, loginState]);

  switch (loginState.state) {
    case 'STARTED':
      return <Loader />;
    case 'TOKEN_OBTAINED':
      return (
        <form ref={formRef} action={`${ZENDESK_JWT_TOKEN_URL}?return_to=${return_to}`} method="POST">
          <input type="hidden" name="jwt" value={loginState.jwtToken} />
        </form>
      );
    case 'FINISHED':
      return <Navigate to="/dashboard" replace />;
    case 'ERROR':
      return (
        <Container>
          <ErrorIcon />
        </Container>
      );
  }
};
export default ZendeskSSOLogin;
