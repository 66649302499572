import { Stack } from '@mui/material';
import React, { useId } from 'react';
import { TextFieldCaption } from '@verticeone/design-system';
import FormControl from '@mui/material/FormControl';
import {
  useIsContractFieldEditable,
  useIsContractFieldRequired,
  useIsContractFieldVisible,
} from '../../hooks/fieldsRulesHooks';
import { useTranslation } from 'react-i18next';
import { ContractFormData, FormPlusComputedFieldPaths } from '../../types';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import { useWatch } from 'react-hook-form';
import LargeValue from '../LargeValue';
import { isContractExisting, isContractWithoutVertice } from '../../computed';
import useContractFormDataSelector from '../../hooks/useContractFormDataSelector';
import FormDecimalNumberField from '../../../../forms/fields/FormDecimalNumberField';

const ProposedCostContractFormEntry = () => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();
  const id = useId();

  const annualCostWithoutVertice = useWatch<ContractFormData>({ name: 'parts.overview.annualCostWithoutVertice' });
  const totalCostValueWithoutVertice = useWatch<ContractFormData>({
    name: 'parts.overview.totalCostValueWithoutVertice',
  });

  const modelAnnualCostWithoutVerticePath: FormPlusComputedFieldPaths = 'costModel.baseline.model.annualCost';
  const isModelAnnualCostWithoutVerticeVisible = useIsContractFieldVisible(modelAnnualCostWithoutVerticePath);
  const isModelAnnualCostWithoutVerticeRequired = useIsContractFieldRequired(modelAnnualCostWithoutVerticePath);
  const isModelAnnualCostWithoutVerticeEditable = useIsContractFieldEditable(modelAnnualCostWithoutVerticePath);

  const existingContract = useContractFormDataSelector(isContractExisting);
  const contractWithoutVertice = useContractFormDataSelector(isContractWithoutVertice);

  if (!isModelAnnualCostWithoutVerticeVisible) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption
          htmlFor={id}
          label={
            existingContract
              ? t('ENTITIES.CONTRACT.LABELS.ANNUAL_COST')
              : t('ENTITIES.CONTRACT.LABELS.INITIAL_VENDOR_PROPOSAL')
          }
          tooltip={
            existingContract
              ? undefined
              : {
                  title: t('ENTITIES.CONTRACT.LABELS.INITIAL_VENDOR_PROPOSAL'),
                  content: contractWithoutVertice
                    ? t('ENTITIES.CONTRACT.TOOLTIPS.INITIAL_VENDOR_PROPOSAL_WITHOUT_VERTICE')
                    : t('ENTITIES.CONTRACT.TOOLTIPS.INITIAL_VENDOR_PROPOSAL'),
                }
          }
          size="XS"
          required={isModelAnnualCostWithoutVerticeEditable && isModelAnnualCostWithoutVerticeRequired}
        />
        {isModelAnnualCostWithoutVerticeEditable ? (
          <FormDecimalNumberField
            id={id}
            name={modelAnnualCostWithoutVerticePath}
            required={isModelAnnualCostWithoutVerticeRequired}
          />
        ) : (
          <LargeValue
            id={id}
            primaryValue={formatContractCurrency(annualCostWithoutVertice)}
            primaryTestId={'annualCostWithoutVerticeValue'}
            secondaryValue={
              totalCostValueWithoutVertice
                ? t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(totalCostValueWithoutVertice) })
                : undefined
            }
            secondaryTestId={'totalCostValueWithoutVerticeValue'}
          />
        )}
      </Stack>
    </FormControl>
  );
};

export default ProposedCostContractFormEntry;
