import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Tooltip, Text } from '@verticeone/design-system';

import { RequestRow } from '../../types';
import TwoRowCell from './TwoRowCell';
import { ComponentProps } from 'react';
import { useFormatCurrency } from '@verticeone/utils/formatting';

type KeyCost = {
  label: string;
  value: {
    amount: number;
    currency: string;
  };
};

export const getKeyCostLabel = (label: string) => {
  switch (label) {
    case 'approvedBudget':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYCOSTS.APPROVED_BUDGET';
    case 'annualCost':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYCOSTS.ANNUAL_COST';
    case 'negotiatedAnnualCost':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYCOSTS.NEGOTIATED_ANNUAL_COST';
    case 'contractTotalCost':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYCOSTS.CONTRACT_TOTAL_COST';
    case 'contractAnnualCost':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYCOSTS.CONTRACT_ANNUAL_COST';
    case 'contractCost':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYCOSTS.CONTRACT_COST';
    default:
      return '-';
  }
};

export const getKeyCosts = (keyCosts?: RequestRow['keyCosts']): KeyCost[] => {
  if (!keyCosts) return [];

  const explicitOrder = [
    'contractCost',
    'contractAnnualCost',
    'contractTotalCost',
    'approvedBudget',
    'annualCost',
    'negotiatedAnnualCost',
  ];

  return Object.entries(keyCosts)
    .filter(([, value]) => value.hasOwnProperty('amount') && value.hasOwnProperty('currency'))
    .sort((a, b) => explicitOrder.indexOf(a[0]) - explicitOrder.indexOf(b[0]))
    .map(([name, value]) => ({
      label: getKeyCostLabel(name),
      value: value,
    }));
};

type KeyCostCellProps = {
  row: Omit<RequestRow, 'requestId' | 'status'> & { requestId?: string; status?: RequestRow['status'] };
};

export const KeyCostCell = ({ row }: KeyCostCellProps) => {
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation();
  const keyCosts = getKeyCosts(row.keyCosts);

  const isEmpty = keyCosts.length === 0;
  const { value: mainKeyCostValue, label: mainKeyCostLabel } = keyCosts[keyCosts.length - 1] ?? {
    value: 0,
    label: '-',
  };
  const formattedAmount = !isEmpty
    ? formatCurrency(mainKeyCostValue.amount, {
        currency: mainKeyCostValue.currency,
        maximumFractionDigits: 0,
      })
    : '-';

  const cell = <TwoRowCell subtitle={!isEmpty ? t(mainKeyCostLabel) : undefined} title={formattedAmount!} />;

  return (
    <Tooltip
      title={t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.KEY_COSTS')}
      size="S"
      placement="bottom"
      content={<KeyCostTooltipContent keyCosts={keyCosts} />}
      disableHoverListener={keyCosts.length <= 1}
    >
      <Stack width="100%">{cell}</Stack>
    </Tooltip>
  );
};

type KeyCostTooltipContentProps = {
  keyCosts: KeyCost[];
};

const KeyCostTooltipContent = ({ keyCosts }: KeyCostTooltipContentProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const rowTextProps: ComponentProps<typeof Text> = {
    variant: 'body-regular',
    color: 'text4',
    size: 'S',
  };

  if (!keyCosts || keyCosts.length === 0) return null;

  return (
    <Stack direction="column">
      {keyCosts.map(({ label, value }) => (
        <Stack direction="row" key={[label, value].join()}>
          <Text {...rowTextProps} width="150px">
            {t(label)}
          </Text>
          <Text {...rowTextProps} width="110px" align="right">
            {formatCurrency(value.amount, {
              currency: value.currency,
              maximumFractionDigits: 0,
            })}
          </Text>
        </Stack>
      ))}
    </Stack>
  );
};
