import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import React, { FC } from 'react';
import { Text } from '@verticeone/design-system';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import Stack from '@mui/material/Stack';
import { Tooltip } from '@verticeone/design-system';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { IconWrapper } from '@verticeone/design-system';
import { useTheme } from '@mui/material';
import { useExchangeCurrencyHint } from '@vertice/core/src/modules/currency/useGetExchangeCurrencyHint';
import { isNil } from 'lodash';
import { isContractRequirementsGathering } from '../../../computed';

export const ANNUAL_COST_TEST_ID = 'annual-cost-value';

export type ExchangeToAccountCurrencyFnType = (value: number, sourceCurrency: string) => number;

type AnnualCostCellProps = {
  viewOnContract: ViewOnContract;
  accountCurrency: string;
  exchangeCurrency: ExchangeToAccountCurrencyFnType;
};

export const annualCostCellValueGetter = (
  viewOnContract: ViewOnContract,
  exchange: ExchangeToAccountCurrencyFnType
): number => {
  const annualCost = viewOnContract.contract.parts.overview?.effectiveAnnualCost;

  if (isNil(annualCost) || isContractRequirementsGathering(viewOnContract.contract)) {
    return 0;
  }
  const contractCurrency = viewOnContract.contract.parts.contractual?.financial?.baseCurrency || DEFAULT_CURRENCY;
  return exchange(annualCost, contractCurrency);
};

export const AnnualCostCell: FC<AnnualCostCellProps> = ({ viewOnContract, accountCurrency, exchangeCurrency }) => {
  const { palette } = useTheme();
  const annualCost = viewOnContract.contract.parts.overview?.effectiveAnnualCost;
  const contractCurrency = viewOnContract.contract.parts.contractual?.financial?.baseCurrency || DEFAULT_CURRENCY;
  const formatCurrency = useFormatCurrency();
  const { getExchangeCurrencyHint } = useExchangeCurrencyHint();

  if (isNil(annualCost) || isContractRequirementsGathering(viewOnContract.contract)) {
    return (
      <Stack alignItems="flex-end">
        <Text testId={ANNUAL_COST_TEST_ID} variant="body-regular" size="M" color="inactive2">
          —
        </Text>
      </Stack>
    );
  }

  const format = (value: number, currency: string) => formatCurrency(value, { currency, maximumFractionDigits: 0 });

  return (
    <Stack alignItems="flex-end">
      <Text testId={ANNUAL_COST_TEST_ID} variant="body-regular" size="M" color="text1">
        {format(annualCost, contractCurrency)}
      </Text>

      {accountCurrency !== contractCurrency && (
        <Stack direction="row" gap={1} alignItems="center">
          <Text variant="caption" size="S" color="text3">
            {format(exchangeCurrency(annualCost, contractCurrency), accountCurrency)}
          </Text>
          <Tooltip content={getExchangeCurrencyHint([contractCurrency, accountCurrency])} size="M">
            <IconWrapper icon={InfoIcon} size="M" htmlColor={palette.text.color3} />
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};
