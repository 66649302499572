import { ExpandMoreOutlined } from '@mui/icons-material';
import { Button as MuiButton, Stack, styled } from '@mui/material';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconWrapper, Text } from '@verticeone/design-system';
import { capitalizeFirstLetter } from '../../WorkflowViewer/utils';
import { isExtendedProperty, OriginId, Property, PropertyId } from '../types';
import { highlightText } from './HighlightedText';
import type { OriginsWithProperties } from './types';
import { VariableChip } from './VariableChip';

type SectionId = PropertyId | OriginId;

type ExpandIconWrapperProps = {
  $isExpanded: boolean;
};

const ExpandIconWrapper = styled(IconWrapper)<ExpandIconWrapperProps>(({ $isExpanded, theme }) => ({
  transform: $isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)',
  transition: 'transform 0.2s',
  color: theme.palette.core.color6,
}));

const RequiredMark = () => {
  return (
    <Text sx={{ width: 6, height: 8 }} aria-hidden={true} size="XS" align="center" color="error2" variant="caption">
      *
    </Text>
  );
};

type VariableBaseContainerProps = {
  $withBackground?: boolean;
  $nestingLevel?: number;
};

const VariableBaseContainer = styled(Stack)<VariableBaseContainerProps>(
  ({ theme, $withBackground = false, $nestingLevel = 0 }) => ({
    position: 'relative',
    paddingLeft: $nestingLevel ? theme.spacing(4) : 0,
    zIndex: 0,
    marginTop: -1,
    marginBottom: -1,
    ...($withBackground && {
      '&::before': {
        content: '""',
        position: 'absolute',
        width: `calc(100% + ${theme.spacing(4 * ($nestingLevel + 1))})`,
        height: '100%',
        backgroundColor: theme.palette.secondary.color4,
        zIndex: -1,
        opacity: 0.5,
        left: `-${theme.spacing(4 * $nestingLevel)}`,
      },
    }),
  })
);

type VariableBaseProps = {
  property: Property;
  typeFilter?: string;
  highlightedLabel?: string;
  onClick: () => void;
};

const VariableBase: FC<VariableBaseProps> = ({ property, typeFilter, onClick, highlightedLabel }) => {
  const { label, typeLabel } = property;

  const hasVariableProperties = isExtendedProperty(property) && property.properties.length > 0;
  const finalTypeLabel = hasVariableProperties ? undefined : typeLabel?.[0];
  const isTypeFiltered = typeFilter !== undefined && typeFilter !== finalTypeLabel?.toLowerCase();

  return (
    <Stack sx={{ zIndex: 0 }} direction="row" alignItems="center">
      <VariableChip
        isDisabled={hasVariableProperties || isTypeFiltered}
        label={label}
        typeLabel={finalTypeLabel}
        isActive={false}
        onClick={onClick}
        highlightedLabel={highlightedLabel}
      />
      {isExtendedProperty(property) && property.required && <RequiredMark />}
    </Stack>
  );
};

const StyledSectionButton = styled(MuiButton)(({ theme }) => ({
  display: 'flex',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  color: theme.palette.inactive.color2,
}));

type SectionButtonProps = {
  label: string;
  onClick: () => void;
  isCollapsed: boolean;
  highlightedLabel?: string;
};

const SectionButton: FC<SectionButtonProps> = ({ label, onClick, isCollapsed, highlightedLabel }) => {
  const finalLabel = highlightedLabel ? highlightText(label, highlightedLabel) : label;

  return (
    <Stack display="block">
      <StyledSectionButton onClick={onClick}>
        <ExpandIconWrapper $isExpanded={!isCollapsed} icon={ExpandMoreOutlined} size="S" />
        <Text sx={{ padding: 1.5 }} variant="button-regular" size="S">
          {finalLabel}
        </Text>
      </StyledSectionButton>
    </Stack>
  );
};

type VariableProps = {
  property: Property;
  onPrimitiveVariableClick: (property: Property) => void;
  onVariableWithPropertiesClick: (sectionId: SectionId) => void;
  collapsedVariables: PropertyId[];
  highlightedLabel?: string;
  typeFilter?: string;
  selectedPropertyId?: PropertyId;
  nestingLevel?: number;
};

const Variable: FC<VariableProps> = ({
  property,
  onPrimitiveVariableClick,
  onVariableWithPropertiesClick,
  collapsedVariables,
  highlightedLabel,
  typeFilter,
  selectedPropertyId,
  nestingLevel = 0,
}) => {
  if (!isExtendedProperty(property) || property.properties.length === 0) {
    return (
      <VariableBaseContainer $withBackground={property.id === selectedPropertyId} $nestingLevel={nestingLevel}>
        <VariableBase
          highlightedLabel={highlightedLabel}
          typeFilter={typeFilter}
          onClick={() => onPrimitiveVariableClick(property)}
          property={property}
        />
      </VariableBaseContainer>
    );
  }

  const isPropertyCollapsed = collapsedVariables.includes(property.id);

  return (
    <VariableBaseContainer $nestingLevel={nestingLevel}>
      <SectionButton
        label={property.label}
        onClick={() => onVariableWithPropertiesClick(property.id)}
        isCollapsed={isPropertyCollapsed}
      />
      {!isPropertyCollapsed && (
        <>
          {property.properties.map((childProperty) => (
            <Variable
              highlightedLabel={highlightedLabel}
              collapsedVariables={collapsedVariables}
              onPrimitiveVariableClick={onPrimitiveVariableClick}
              onVariableWithPropertiesClick={onVariableWithPropertiesClick}
              key={`${childProperty.origin.id}-${childProperty.id}`}
              property={childProperty}
              typeFilter={typeFilter}
              selectedPropertyId={selectedPropertyId}
              nestingLevel={nestingLevel + 1}
            />
          ))}
        </>
      )}
    </VariableBaseContainer>
  );
};

type VariablesListProps = {
  title: string;
  originsWithProperties: OriginsWithProperties;
  typeFilter?: string;
  onVariableClick: (property: Property) => void;
  highlightedLabel?: string;
  selectedPropertyId?: PropertyId;
};

export const VariablesList: FC<VariablesListProps> = ({
  originsWithProperties,
  typeFilter,
  title,
  onVariableClick,
  highlightedLabel,
  selectedPropertyId,
}) => {
  const { t } = useTranslation();

  const [collapsedSections, setCollapsedSections] = useState<SectionId[]>([]);

  const handleSectionClick = (sectionId: SectionId) => {
    if (collapsedSections.includes(sectionId)) {
      setCollapsedSections((prevState) => prevState.filter((id) => id !== sectionId));
    } else {
      setCollapsedSections((prevState) => [...prevState, sectionId]);
    }
  };

  const handleButtonClick = () => {
    setCollapsedSections((prevState) => (prevState.length ? [] : Object.keys(originsWithProperties)));
  };

  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Text size="XS" color="text2" variant="caption">
          {title}
        </Text>
        <Button variant="plain" size="XS" onClick={handleButtonClick}>
          {t(
            `INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.ACTIONS.${
              collapsedSections.length ? 'EXPAND_ALL' : 'COLLAPSE_ALL'
            }`
          )}
        </Button>
      </Stack>
      <Stack>
        {Object.values(originsWithProperties).map(({ origin, properties, hideOrigin }) => (
          <Fragment key={origin.id}>
            {!hideOrigin && (
              <SectionButton
                highlightedLabel={highlightedLabel}
                label={origin.label || capitalizeFirstLetter(origin.kind)}
                onClick={() => handleSectionClick(origin.id)}
                isCollapsed={collapsedSections.includes(origin.id)}
              />
            )}
            {!collapsedSections.includes(origin.id) && (
              <>
                {properties.map((property) => (
                  <Variable
                    highlightedLabel={highlightedLabel}
                    typeFilter={typeFilter}
                    key={`${property.origin.id}-${property.id}`}
                    collapsedVariables={collapsedSections}
                    onVariableWithPropertiesClick={handleSectionClick}
                    onPrimitiveVariableClick={onVariableClick}
                    property={property}
                    selectedPropertyId={selectedPropertyId}
                    nestingLevel={!hideOrigin ? 1 : 0}
                  />
                ))}
              </>
            )}
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
