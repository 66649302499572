import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled, useTheme } from '@mui/material';
import { Divider } from '@verticeone/design-system';
import { SearchInput } from '@vertice/core/src/components/SearchInput';
import { useCloudAnalytics } from '../../../CloudAnalyticsContext';

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(4, 6),
}));

type TableLayoutProps = {
  children: React.ReactNode;
};

const TableLayout = ({ children }: TableLayoutProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.TABLE' });
  const { getLastNode, setSearch } = useCloudAnalytics();
  const lastNode = getLastNode();
  const { search } = lastNode;

  return (
    <Stack bgcolor={palette.core.color1}>
      <Actions>
        <SearchInput
          placeholder={t('ACTIONS.SEARCH_PLACEHOLDER')}
          value={search || ''}
          onSearch={setSearch}
          size="S"
          sx={{ width: '20vw' }}
          debounce={100}
        />
      </Actions>
      <Divider />
      {children}
    </Stack>
  );
};

export default TableLayout;
