import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@verticeone/design-system';
import type { GridRowModel } from '@mui/x-data-grid-pro';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { SPInventoryItem } from '../useSavingsPlanInventoryData';
import { getTableHeight, useDateFormat } from '../utils';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import RowDetailDrawer from './RowDetailDrawer';

const useSPInventoryTableColumns = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.SP_INVENTORY' });
  const dateFormatter = useDateFormat();
  const formatCurrency = useFormatCurrency();

  return [
    {
      field: 'savingsPlanId',
      headerName: t('COLUMN.SAVINGS_PLAN_ID'),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'accountId',
      headerName: t('COLUMN.ACCOUNT_ID'),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'termLengthYears',
      headerName: t('COLUMN.TERM_LENGTH'),
      renderCell: ({ value }) => t('VALUE.YEAR', { count: value }),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 140,
    },
    {
      field: 'paymentOption',
      headerName: t('COLUMN.PAYMENT_OPTION'),
      renderCell: ({ value }) => t(`VALUE.${value.toUpperCase()}`),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'ec2InstanceFamily',
      headerName: t('COLUMN.FAMILY'),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 90,
    },
    {
      field: 'ec2Region',
      headerName: t('COLUMN.REGION'),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'totalCommitment',
      headerName: t('COLUMN.TOTAL_COMMITMENT'),
      renderCell: ({ value }) => formatCurrency(value, { maximumFractionDigits: 0, currency: AWS_DEFAULT_CURRENCY }),
      align: 'right',
      headerAlign: 'right',
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'hourlyCommitment',
      headerName: t('COLUMN.HOURLY_COMMITMENT'),
      renderCell: ({ value }) => formatCurrency(value, { maximumFractionDigits: 3, currency: AWS_DEFAULT_CURRENCY }),
      align: 'right',
      headerAlign: 'right',
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'startDate',
      headerName: t('COLUMN.START_DATE'),
      renderCell: ({ value }) => dateFormatter(value, { dateStyle: 'medium' }),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      minWidth: 130,
    },
    {
      field: 'endDate',
      headerName: t('COLUMN.EXPIRATION_DATE'),
      renderCell: ({ value }) => dateFormatter(value, { dateStyle: 'medium' }),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      minWidth: 160,
    },
  ] as GridColDef<SPInventoryItem>[];
};

const HEADER_HEIGHT = 48;
const ITEM_ROW_HEIGHT = 60;

type SPInventoryTableProps = {
  data: SPInventoryItem[];
  visibleColumns?: { [key: string]: boolean };
};

const defaultVisibleColumns = {
  savingsPlanId: true,
  accountId: true,
  termLengthYears: true,
  paymentOption: true,
  totalCommitment: true,
  ec2InstanceFamily: false,
  ec2Region: false,
  hourlyCommitment: true,
  startDate: true,
  endDate: true,
};

const SPInventoryTable = ({ data, visibleColumns = defaultVisibleColumns }: SPInventoryTableProps) => {
  const [drawerData, setDrawerData] = useState<SPInventoryItem | null>(null);
  const columns = useSPInventoryTableColumns();
  const { palette } = useTheme();

  const getRowId = (row: GridRowModel) => row.savingsPlanId;

  const height = getTableHeight(data);

  return (
    <Stack>
      <Box bgcolor={palette.core.color1} height={height}>
        {drawerData && <RowDetailDrawer data={drawerData!} onClose={() => setDrawerData(null)} isOpen={true} />}
        <DataGrid
          rows={data || []}
          noBorder
          noBorderRadius
          columns={columns}
          getRowId={getRowId}
          sortingMode="client"
          hideFooter
          onRowClick={(props) => {
            setDrawerData(props.row);
          }}
          initialState={{
            columns: {
              columnVisibilityModel: visibleColumns,
            },
          }}
          getRowClassName={() => 'pointer'}
          rowHeight={ITEM_ROW_HEIGHT}
          columnHeaderHeight={HEADER_HEIGHT}
          showCellVerticalBorder
          showColumnVerticalBorder
          disableRowSelectionOnClick
          autoHeight={false}
          color={AWS_BRAND_COLOR}
        />
      </Box>
    </Stack>
  );
};

export default SPInventoryTable;
