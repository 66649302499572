import { Card, CardHeader } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { styled, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { IconWrapper } from '@verticeone/design-system';
import ChevronRightIcon from '@mui/icons-material/ArrowForwardIos';
import { CloudCategoryOutlineDashboardOverview } from '@vertice/assets';
import { NoData } from '@verticeone/design-system';
import { CancelOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CalculateCoverageReturnType } from '../../../../pages/Cloud/CloudRIOptimiser/tabs/CloudOptimizeTab/types';
import ScoreBar from './ScoreBar/ScoreBar';
import { AWS_BRAND_COLOR } from '../../constants';

const StyledCancelOutlined = styled(CancelOutlined)(({ theme }) => ({
  color: theme.palette.tertiary.color2,
}));

const Header = ({ title }: { title: string }) => {
  const { palette } = useTheme();

  return (
    <CardHeader>
      <Stack direction="row">
        <CloudCategoryOutlineDashboardOverview fill={palette.core.color5} />
        <Text variant="heading" size="S" tag="h2">
          {title}
        </Text>
      </Stack>
    </CardHeader>
  );
};

const DataUnavailable = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return (
    <Card testId={title}>
      <Header title={title} />
      <Stack padding={10}>
        <NoData icon={<StyledCancelOutlined />} header={t('CLOUD.RI_OPTIMIZATION.DATA_UNAVAILABLE')} />
      </Stack>
    </Card>
  );
};

type Props = {
  title: string;
  disabled?: boolean;
  onNavigate: () => void;
  showHiddenProducts: boolean;
  calculatedCoverage: CalculateCoverageReturnType;
};

export const OptimizeInstanceCard = ({
  title,
  disabled,
  onNavigate,
  calculatedCoverage,
  showHiddenProducts,
}: Props) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_OPTIMIZATION' });
  const { isEmpty, coverageColor, coverage, spCoverage, onDemandUsage, instancesTypesCount } = calculatedCoverage;

  if ((isEmpty || !!disabled) && !showHiddenProducts) {
    return null;
  }

  if (isEmpty) {
    return <DataUnavailable title={title} />;
  }

  const mapCoverageColor = {
    error2: palette.error.color2,
    secondary2: palette.secondary.color2,
  };

  return (
    <Card testId={title}>
      <Header title={title} />
      <Stack padding={6} gap={6}>
        <Stack gap={4}>
          <ScoreBar
            values={[
              {
                name: 'ri_coverage',
                value: coverage,
                textColor: mapCoverageColor[coverageColor],
                seriesColor: mapCoverageColor[coverageColor],
              },
              ...(spCoverage !== undefined
                ? [
                    {
                      name: 'sp_coverage',
                      value: spCoverage,
                      textColor: palette[AWS_BRAND_COLOR].color2,
                      seriesColor: palette[AWS_BRAND_COLOR].color2,
                    },
                  ]
                : []),
              {
                name: 'on_demand_usage',
                value: onDemandUsage,
                textColor: palette.text.color2,
                seriesColor: palette.core.color3,
              },
            ]}
          />
        </Stack>
        <Button
          variant="ghost"
          color="tertiary"
          size="S"
          testId="instances-types"
          disabled={disabled}
          onClick={onNavigate}
          sx={{ alignSelf: 'flex-start' }}
        >
          {instancesTypesCount > 1
            ? t('SEE_INSTANCES_TYPES.PLURAL', { count: instancesTypesCount })
            : t('SEE_INSTANCES_TYPES.SINGULAR')}
          <IconWrapper icon={ChevronRightIcon} size="S" />
        </Button>
      </Stack>
    </Card>
  );
};
