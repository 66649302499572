import dayjs from 'dayjs';

const URN_REGEX = /^urn:[^:]*:[^:]*:(?<accountId>[^:]*):[^:]*:(?<formPath>.+?)$/;

type FormUrnValues = {
  accountId: string | null;
  formPath: string;
};

export type GenericFormUrnCreatorConfig = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
  taskId: string;
};

/**
 * Takes old form URN and if URN is shared template, generate new URN specific for customer,
 * else it update version of the new updated form.
 *
 * Function recognize shared template, when accoundId is empty in URN.
 */
export function createNewGenericFormUrn(oldUrn: string, config: GenericFormUrnCreatorConfig): string {
  const { accountId, workflowId, workflowVersion, taskId } = config;

  const newFormVersion = dayjs().unix();
  const workflowTimestamp = dayjs(workflowVersion).unix();
  const urnValues = parseFormUrn(oldUrn);

  const isSharedTemplate = urnValues.accountId === null;

  let formPath = isSharedTemplate ? urnValues.formPath : trimFormPathSuffix(urnValues.formPath);
  const suffix = `${workflowId}/${workflowTimestamp}/${taskId}/v${newFormVersion}`;

  return `urn:verticeone:vertice:${accountId}:services:${formPath}/${suffix}`;
}

export function parseFormUrn(urn: string): FormUrnValues {
  const groups = URN_REGEX.exec(urn)?.groups;

  if (!groups) throw new Error('Invalid Form URN');

  return {
    formPath: groups.formPath,
    accountId: groups.accountId || null,
  };
}

/**
 * Remove suffix identifier
 *
 * Before:
 *
 * `/form/core/generic/purchase/requirementsGathering/v1/{workflowId}/{workflowVersion}/{takdId}/{formVersion}`
 *
 * After:
 *
 * `/form/core/generic/purchase/requirementsGathering/v1`
 */
export function trimFormPathSuffix(formPath: string): string {
  const parts = formPath.split('/');

  return parts.slice(0, -4).join('/');
}
