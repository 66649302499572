import { createContext, useContext, useMemo } from 'react';
import { isNil } from 'lodash';
import { useParams } from 'react-router-dom';
import {
  type ProposedCostsValue,
  RecommendationStatus,
  useOptimizationRecommendationsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { Loader } from '@verticeone/design-system';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type RecommendationRaw = {
  code: string;
  breaching: boolean;
  categories: Array<string>;
  empty?: boolean;
  status: RecommendationStatus;
  frequency: 'DAILY' | 'MONTHLY' | 'NEVER' | 'ONCE' | 'WEEKLY';
  fulfilledAt: string | null;
  updatedAt: string | null;
  products: string[];
  tags: Array<Array<string>> | null;
  resourceCost: number;
  proposedCost: number;
  proposedCosts: ProposedCostsValue | null;
  testResults: Array<{ code: string }>;
};

export type Recommendation = {
  saving: number;
} & RecommendationRaw;

type RecommendationProviderProps = {
  children: React.ReactNode;
};

type RecommendationContextData = {
  isFetching: boolean;
  item: Recommendation;
  refetch: () => void;
};

const RecommendationContext = createContext<RecommendationContextData>({} as RecommendationContextData);

const RecommendationProvider = ({ children }: RecommendationProviderProps) => {
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { code: recommendationCode } = useParams();
  const { accountId } = useAccountContext();

  const {
    data: rawData,
    currentData,
    refetch,
    isFetching: isFetchingRecommendations,
  } = useOptimizationRecommendationsQuery({ accountId: accountId! }, { skip: !accountId });

  const isFetching = !currentData && isFetchingRecommendations;

  const item: Recommendation | undefined = useMemo(() => {
    if (rawData?.recommendationsQuery?.__typename !== 'RecommendationsResult') {
      return;
    }

    const rawItem = (rawData.recommendationsQuery.items as Array<RecommendationRaw>).find(
      (recommendation) => recommendation.code === recommendationCode
    );

    if (!rawItem) {
      return;
    }

    return {
      ...rawItem,
      saving: isNil(rawItem.proposedCost) ? 0 : Math.max(0, rawItem.resourceCost - rawItem.proposedCost),
    };
  }, [rawData, recommendationCode]);

  if (isFetching) {
    return <Loader color={AWS_BRAND_COLOR} />;
  }

  if (!item) {
    navigate(routes.CLOUD.OPTIMIZATION, null, { replace: true });
    return null;
  }

  return (
    <RecommendationContext.Provider value={{ item, isFetching, refetch }}>{children}</RecommendationContext.Provider>
  );
};

export const useRecommendationData = () => useContext(RecommendationContext);

export default RecommendationProvider;
