import dayjs from 'dayjs';

import type { SpListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import type { ShoppingCartItemType } from '../components/providers/ShoppingCartProvider';
import { ComputeDataColumn, GenericDataColumn } from './useMonitoringLatestQuery';
import { OfferingData } from '../types';

export const MIN_COST_PERCENTAGE = 0.5;
export const MAX_COST_PERCENTAGE = 0.99;

export const calculateUpfrontCost = (totalCost: number, upfrontCost: number, paymentOption: string): number => {
  if (paymentOption === 'All Upfront') {
    return totalCost;
  } else if (paymentOption === 'Partial Upfront') {
    return Math.min(
      Math.ceil(Math.max(upfrontCost, totalCost * MIN_COST_PERCENTAGE)),
      Math.floor(totalCost * MAX_COST_PERCENTAGE)
    );
  } else {
    return 0;
  }
};

export const calculateSPCosts = ({
  hourlyCommitment,
  upfrontCost = 0,
  durationSeconds,
  paymentOption,
}: {
  hourlyCommitment: number;
  upfrontCost?: number;
  durationSeconds: number;
  paymentOption: string;
}) => {
  const term = Math.round(dayjs.duration(durationSeconds * 1000).asMonths());
  const totalCost = hourlyCommitment * (durationSeconds / 60 / 60);
  const calculatedUpfrontCost = calculateUpfrontCost(totalCost, upfrontCost, paymentOption);
  const monthlyCost = (totalCost - calculatedUpfrontCost) / term;

  return {
    totalCost,
    upfrontCost: calculatedUpfrontCost,
    monthlyCost,
  };
};

export const parseSpOfferings = (
  data: SpListOfferingsQuery | undefined,
  instance: Record<ComputeDataColumn, number> | Record<GenericDataColumn, number>,
  cartItems?: ShoppingCartItemType[] | null,
  effectiveDate?: string
): OfferingData[] => {
  if (
    data?.spListOfferingsQuery?.__typename !== 'SPOfferingsResult' ||
    data?.spListOfferingsQuery?.items === undefined ||
    data?.spListOfferingsQuery?.items?.length === 0
  ) {
    return [];
  }

  if (!!cartItems) {
    return cartItems;
  }

  const items = data?.spListOfferingsQuery?.items;

  return items.map(({ offeringId, durationSeconds, planType, paymentOption, currency }) => {
    const term = Math.round(dayjs.duration(durationSeconds * 1000).asMonths());
    const expectedSavings = instance.annual_savings_from * (term / 12);

    const hourlyCommitment = instance.to_cover_hourly_recommendation;

    const { totalCost, upfrontCost, monthlyCost } = calculateSPCosts({
      hourlyCommitment,
      durationSeconds,
      paymentOption,
    });

    const startDate = effectiveDate || dayjs().add(72, 'hour').format('YYYY-MM-DD');

    return {
      offeringId,
      term,
      durationSeconds,
      planType,
      hourlyCommitment,
      paymentOption,
      totalCost,
      expectedSavings,
      upfrontCost,
      monthlyCost,
      currencyCode: currency,
      startDate,
      executeImmediately: 0,
    };
  });
};
