import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import { Card, CardHeader, CardHeaderTitle } from '@verticeone/design-system';
import { LoadableComponent } from '@verticeone/design-system';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useSavingsData } from './useSavingsData';
import SavingsGraph from './SavingsGraph';
import CurrentTermSubtitle from '../CurrentTermSubtitle';

const Layout = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { palette } = useTheme();
  const { data, isFetching } = useSavingsData();
  const isLoading = Boolean(isFetching || data?.isSavingsDataLoading);

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('EDP_SAVINGS.TITLE')} tag="h2" />
        <CurrentTermSubtitle />
      </CardHeader>
      <Box bgcolor={palette.core.color1} py={6}>
        <LoadableComponent isLoading={isLoading} color={AWS_BRAND_COLOR}>
          <SavingsGraph data={data!} />
        </LoadableComponent>
      </Box>
    </Card>
  );
};

export default Layout;
