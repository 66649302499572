import { useParams } from 'react-router-dom';

export type UseGetWorkflowInfoFromParamsReturn = {
  workflowId: string;
  workflowVersion: string;
};

const useGetWorkflowInfoFromParams = (): UseGetWorkflowInfoFromParamsReturn => {
  const params = useParams() as { workflowId: string; versionId: string };

  if (!params.workflowId || !params.versionId) {
    throw new Error('Workflow info could not be obtained from URL params.');
  }

  return {
    workflowId: params.workflowId,
    workflowVersion: params.versionId,
  };
};

export default useGetWorkflowInfoFromParams;
