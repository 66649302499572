import { styled } from '@mui/material';
import { Card } from '@verticeone/design-system';

const Tile = styled(Card)(({ theme }) => ({
  padding: theme.spacing(6),
  gap: theme.spacing(6),
  flex: '1 1 auto',
}));

export default Tile;
