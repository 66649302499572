import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { addHours } from 'date-fns';
import useAwsSpRioSubFeatures, { SPKeys } from '@vertice/dashboard/src/pages/Cloud/CloudRIO/useAwsSpRioSubFeatures';

export type DataSource = 'SageMaker' | 'Compute';

type DataSourceProviderProps = PropsWithChildren & {
  datasource: DataSource;
  effectiveDate?: string;
};

type DataSourceProviderData = {
  datasource: DataSource;
  effectiveDate?: string;
  buyDrawer: {
    isOpened: boolean;
    open: () => void;
    close: () => void;
  };
};

const DataSourceContext = createContext<DataSourceProviderData>({} as DataSourceProviderData);

const DataSourceProvider = ({ datasource, children }: DataSourceProviderProps) => {
  const [buyDrawerInstance, setBuyDrawerInstance] = useState<boolean>(false);
  const [effectiveDate, setEffectiveDate] = useState<string | undefined>();
  const { isFetching: isAwsRioSubFeaturesFetching, data: awsRioSubFeaturesData } = useAwsSpRioSubFeatures();
  const productKey = datasource.toLowerCase() as SPKeys;

  useEffect(() => {
    if (!effectiveDate && !isAwsRioSubFeaturesFetching && awsRioSubFeaturesData?.sp[productKey]?.purchaseDelay) {
      setEffectiveDate(addHours(new Date(), awsRioSubFeaturesData?.sp[productKey]?.purchaseDelay)?.toISOString());
    }
  }, [isAwsRioSubFeaturesFetching, awsRioSubFeaturesData, effectiveDate, datasource, productKey, setEffectiveDate]);

  return (
    <DataSourceContext.Provider
      value={{
        datasource,
        effectiveDate,
        buyDrawer: {
          isOpened: buyDrawerInstance,
          close: () => setBuyDrawerInstance(false),
          open: () => setBuyDrawerInstance(true),
        },
      }}
    >
      {children}
    </DataSourceContext.Provider>
  );
};

export const useDataSource = () => useContext(DataSourceContext);

export default DataSourceProvider;
