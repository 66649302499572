import React from 'react';
import { GridSelect } from '@verticeone/design-system';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import useUserSelectOptions from './useUserSelectOptions';
import { User } from '../types';

const WatchersEditCell = ({ id, field, value, row }: GridRenderCellParams) => {
  const apiRef = useGridApiContext();
  const filteredUsers = useUserSelectOptions({ userIdsToExclude: row.owner ? [row.owner.userId] : undefined });

  return (
    // TODO: Use our unified @vertice/core/src/modules/user/UserSelect instead
    <GridSelect
      value={value ?? []}
      options={filteredUsers}
      isMulti={true}
      getOptionValue={(option) => (option as User).userId}
      getOptionLabel={(option) => (option as User).name}
      onChange={(newValue) => {
        void apiRef.current.setEditCellValue({ id, field, value: newValue });
      }}
    />
  );
};

export default WatchersEditCell;
