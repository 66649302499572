import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShoppingCartOutlined } from '@mui/icons-material';
import { Badge, badgeClasses, styled } from '@mui/material';

import { Button } from '@verticeone/design-system';
import { IconWrapper } from '@verticeone/design-system';

import { useShoppingCart } from '../../../../providers/ShoppingCartProvider';

type AddToCartButtonProps = {
  id: string;
  tKey: string;
  quantity: number;
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  top: 4,
  [`.${badgeClasses.badge}`]: {
    backgroundColor: theme.palette.tertiary.color2,
  },
}));

const AddToCartButton = ({ id, tKey, quantity }: AddToCartButtonProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: tKey });
  const { addToCart, cartItems } = useShoppingCart();

  const cartItem = cartItems.find((item) => item.id === id);

  return (
    <StyledBadge badgeContent={cartItem?.quantity} color="primary">
      <Button variant="ghost" color="neutral" fullWidth onClick={() => addToCart({ id, quantity })}>
        {t('ACTIONS.ADD')}
        <IconWrapper icon={ShoppingCartOutlined} />
      </Button>
    </StyledBadge>
  );
};

export default AddToCartButton;
