import { z, ZodObject, ZodRawShape } from 'zod';
import { DateFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';

export function getDatePresenterSchema(field: DateFieldType): ZodObject<ZodRawShape> {
  let schema = z.string().date();

  return z.object({
    [field.name]: field.metadata.required ? schema : schema.nullish(),
  });
}

export function getDatePresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<DateFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
}

export function getDateJsonSchema(field: DateFieldType) {
  return {
    required: [field.name],
    properties: {
      [field.name]: {
        type: field.metadata.required ? 'string' : ['string', 'null'],
        format: 'date',
      },
    },
  };
}
