import { FieldCard } from '../../DynamicFormBuilder/components/FieldCard/FieldCard';
import FieldCardContent from '../../DynamicFormBuilder/components/FieldCard/FieldCardContent';
import { MoneyFieldType } from './types';

export type MoneyFieldBuilderCardProps = {
  field: MoneyFieldType & Record<'id', string>;
};

export const MoneyFieldBuilderCard = (props: MoneyFieldBuilderCardProps) => {
  return (
    <FieldCard>
      <FieldCardContent {...props} />
    </FieldCard>
  );
};
