import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@verticeone/design-system';
import DefaultColumn from './DefaultColumn';

const ObfuscatedCellRenderer = ({ value }: GridRenderCellParams) => (
  <Text
    variant="body-regular"
    size="M"
    title={value}
    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', filter: 'blur(6px)' }}
  >
    {value}
  </Text>
);

const ObfuscatedColumn = (props: GridRenderCellParams) => {
  return props.row.isVisible ? <DefaultColumn {...props} /> : <ObfuscatedCellRenderer {...props} />;
};

export default ObfuscatedColumn;
