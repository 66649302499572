import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { formatCurrency } from '@verticeone/utils/formatting';
import { ShoppingCartItemType } from '../providers/ShoppingCartProvider';

export const calculateShoppingCartUnits = (cartItems: ShoppingCartItemType[]) => {
  return cartItems.reduce((total, cartItem) => total + 24 * cartItem?.hourlyCommitment, 0);
};

export const useTermFormatter = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.UNITS' });

  return (value: number) => {
    const isYear = !(value % 6);
    if (isYear) {
      return t('YEAR', { count: value / 12 });
    }
    return t('MONTH', { count: value });
  };
};

export const useFormatCost = () => {
  const { locale } = useLocaleContext();

  return (currencyCode: string, cost: number, maximumFractionDigits = 2) =>
    formatCurrency(cost, {
      currency: currencyCode,
      locale,
      maximumFractionDigits,
    });
};
