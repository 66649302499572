import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@verticeone/design-system';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useRecommendationData } from './useRecommendationData';

const AlternativeRecommendationAlert = () => {
  const { data } = useRecommendationData();
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.VERTICE_RECOMMENDATION_ALERT' });

  return (
    <Alert
      color="tertiary"
      size="M"
      subtitle={t('ALTERNATIVE_DESCRIPTION', {
        savings12Months: formatCurrency((data?.savings36Months ?? 0) / 3, {
          currency: AWS_DEFAULT_CURRENCY,
          maximumFractionDigits: 2,
        }),
        savings36Months: formatCurrency(data?.savings36Months ?? 0, {
          currency: AWS_DEFAULT_CURRENCY,
          maximumFractionDigits: 2,
        }),
      })}
      testId="alternative-recommendation-alert"
      variant="ghost"
    />
  );
};

export default AlternativeRecommendationAlert;
