import FormTextArea from '../../../../fields/FormTextArea';
import { FieldDialogFormEntry } from '../../../DynamicFormBuilder/components/FieldDialog/FieldDialogFormEntry';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDynamicFormBuilderContext } from '../../../DynamicFormBuilder/DynamicFormBuilderContextProvider';

export const DescriptionFieldDialogFormEntry = () => {
  const { t } = useTranslation();
  const { color } = useDynamicFormBuilderContext();

  return (
    <FieldDialogFormEntry
      name="metadata.description"
      label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.HELPTEXT')}
      component={FormTextArea}
      componentProps={{
        color: color,
        size: 'M',
        variant: 'outline',
      }}
    />
  );
};
