import React, { ChangeEvent } from 'react';
import { Box, Stack, styled, useTheme } from '@mui/material';
import { LegendItemData } from './types';
import ColorSquare from '../ColorSquare';
import { Text } from '@verticeone/design-system';

const StyledAnchor = styled(Stack)({
  cursor: 'pointer',
});

type LegendItemProps = LegendItemData & {
  selected?: boolean;
  onSelectionChange?: (selected: boolean) => void;
};

const LegendItem = ({
  color = 'transparent',
  outlined,
  custom,
  label,
  value,
  selected,
  onSelectionChange,
}: LegendItemProps) => {
  const { palette } = useTheme();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onSelectionChange) {
      onSelectionChange(event.target.checked);
    }
  };

  return onSelectionChange ? (
    <StyledAnchor direction="row" alignItems="center" gap="7px" onClick={() => onSelectionChange(!selected)}>
      {custom ? (
        <Box sx={{ opacity: selected ? 1 : 0.4 }}>{custom}</Box>
      ) : (
        <ColorSquare color={selected ? color : palette.inactive.color2} outlined={outlined} />
      )}
      <Text
        color="text1"
        onChange={onChange}
        variant="body-regular"
        size="S"
        sx={{ flex: 1, wordBreak: 'break-word', textDecoration: selected ? 'none' : 'line-through' }}
      >
        {label}
      </Text>
      <Text variant="body-bold" size="S" color={selected ? 'text1' : 'inactive1'}>
        {value}
      </Text>
    </StyledAnchor>
  ) : (
    <Stack direction="row" alignItems="center" gap="7px">
      {custom ? custom : <ColorSquare color={color} outlined={outlined} />}
      <Text color="text1" onChange={onChange} variant="body-regular" size="S" sx={{ flex: 1, wordBreak: 'break-word' }}>
        {label}
      </Text>
      <Text variant="body-bold" size="S" color="text1">
        {value}
      </Text>
    </Stack>
  );
};

export default LegendItem;
