import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import { keyBy } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { SingleValue } from 'react-select';
import { z } from 'zod';

import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import { GoodFormProps, useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { Button, SimpleOption } from '@verticeone/design-system';
import { EDIT_MODE_DISABLED, FORM_FIELD_WIDTH } from '../constants';
import { EditCardProps, JiraProject, JiraSetupStep, StepContentProps } from '../types';

const projectFormDataSchema = z.object({
  jiraProject: z.string(),
});

export type ProjectFormData = z.infer<typeof projectFormDataSchema>;
type ProjectFormProps = GoodFormProps<ProjectFormData> &
  Pick<StepContentProps, 'activeStep'> &
  EditCardProps & {
    projects: JiraProject[];
    isLoadingProjects: boolean;
    isLoading: boolean;
    isDisabled: boolean;
  };

const ProjectFormEntry = createTypedFormEntry<ProjectFormData>();

export const ProjectForm = ({
  defaultValues,
  onSubmit,
  activeStep,
  projects,
  isLoadingProjects,
  isLoading,
  cardEditMode,
  setCardEditMode,
  isDisabled,
}: ProjectFormProps) => {
  const formMethods = useForm<ProjectFormData>({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(projectFormDataSchema),
  });
  const goodFormMethods = useGoodFormUtils(formMethods);
  const submitButtonProps = getPropsForSubmitButton(formMethods.formState);

  const options = projects.map<SimpleOption>((project) => ({
    value: project.id,
    label: project.name,
  }));
  const projectsMap = keyBy(options, 'value');

  const isSuccessStep = activeStep === JiraSetupStep.SUCCESS;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={goodFormMethods.handleSubmit(onSubmit)}>
        <Stack gap={8} maxWidth={FORM_FIELD_WIDTH}>
          <ProjectFormEntry
            component={FormSelectField2}
            componentProps={{
              options,
              boxValue: (value: string) => projectsMap[value],
              unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
              isClearable: false,
              isLoading: isLoadingProjects,
              placeholder: t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_FORM.FIELD_1_PLACEHOLDER'),
            }}
            disabled={isDisabled}
            label={t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_FORM.FIELD_1_LABEL')}
            name="jiraProject"
            required
            tooltip={{ content: t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_FORM.FIELD_1_TOOLTIP') }}
          />
          {(cardEditMode.project || !isSuccessStep) && !cardEditMode.connection && (
            <Stack direction="row" gap={2}>
              {isSuccessStep && (
                <Button variant="outline" size="M" color="neutral" onClick={() => setCardEditMode(EDIT_MODE_DISABLED)}>
                  {t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_FORM.DISMISS_BUTTON')}
                </Button>
              )}
              <Button
                variant="solid"
                size="M"
                color="primary"
                type="submit"
                isLoading={submitButtonProps.isLoading || isLoading}
                disabled={submitButtonProps.disabled || isLoading}
                sx={{ alignSelf: 'flex-start' }}
              >
                {t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_FORM.SUBMIT_BUTTON')}
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
