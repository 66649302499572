export const getEdgeLetter = (n: number) => {
  n += 1;
  let result = '';
  while (n > 0) {
    n -= 1;
    const remainder = n % 26;
    n = Math.floor(n / 26);
    result = String.fromCharCode(65 + remainder) + result;
  }
  return result;
};
