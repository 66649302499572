import React, { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import useTheme from '@mui/material/styles/useTheme';
import { IconButton } from '@verticeone/design-system';
import { Text, Menu, MenuItem } from '@verticeone/design-system';
import PricingDataRequestDialog from '../../buttons/RequestBenchmarkingDataButton/PricingDataRequestDialog';
import { useRequestBenchmarkingData } from '../../hooks/useRequestBenchmarkingData';
import { useGetVendorByIdQuery } from '@vertice/slices';

export type ProductsPricingMenuProps = {
  vendorId?: string;
};

export const ProductsPricingMenu = ({ vendorId }: ProductsPricingMenuProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { triggerRequest, isRequestInProgress } = useRequestBenchmarkingData({ vendorId });
  const { data: vendor } = useGetVendorByIdQuery({ vendorId: vendorId! }, { skip: !vendorId });

  // Kebab menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);

  // Request benchmarking dialog
  const [openedDialog, setOpenedDialog] = useState<boolean>(false);
  const openDialog = () => setOpenedDialog(true);
  const closeDialog = () => setOpenedDialog(false);

  const onMenuItemClick = () => {
    openDialog();
    closeMenu();
  };

  const onRequestDialogSubmitted = async (note: string) => {
    await triggerRequest(note);
    closeDialog();
  };

  return !vendor?.isLimited ? (
    <>
      <IconButton
        icon={MoreVertIcon}
        onClick={openMenu}
        variant="ghost"
        size="S"
        aria-label={t('VENDOR.PRODUCTS_PRICING.ACTION_MENU')}
      />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem startIcon={LeaderboardOutlinedIcon} iconColor={palette.text.color3} onClick={onMenuItemClick}>
          <Text variant="body-regular" size="S" color="text1">
            {t('VENDOR.PRODUCTS_PRICING.REQUEST_BENCHMARKING')}
          </Text>
        </MenuItem>
      </Menu>
      <PricingDataRequestDialog
        open={openedDialog}
        onClose={closeDialog}
        onSuccess={onRequestDialogSubmitted}
        disabled={isRequestInProgress}
      />
    </>
  ) : null;
};
