import React from 'react';
import { Stack } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Text, TextProps } from '@verticeone/design-system';

export const ScopeReductionSavingsTooltip = () => {
  const { t } = useTranslation();

  const textProps = {
    size: 'S',
    variant: 'body-regular',
    color: 'text4',
  } satisfies TextProps;

  return (
    <Stack gap={2}>
      <Text {...textProps}>{t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_1')}</Text>
      <Text {...textProps}>
        <Trans
          i18nKey="ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_2"
          components={{ u: <Text {...textProps} sx={{ textDecoration: 'underline' }} /> }}
        />
      </Text>
      <Text {...textProps}>{t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_3')}</Text>
      <Text {...textProps}>{t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_SCOPE_REDUCTION_SAVINGS.LINE_4')}</Text>
    </Stack>
  );
};
