import { contractApi as api } from '../../api/contractAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    importContracts: build.mutation<ImportContractsApiResponse, ImportContractsApiArg>({
      query: (queryArg) => ({ url: `/${queryArg.accountId}/contracts/import`, method: 'POST', body: queryArg.body }),
    }),
    bulkImportStatus: build.mutation<BulkImportStatusApiResponse, BulkImportStatusApiArg>({
      query: (queryArg) => ({
        url: `/${queryArg.accountId}/contracts/import/status`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as contractApiCodegen };
export type ImportContractsApiResponse = /** status 202 ...
 */ {
  executionId: string;
};
export type ImportContractsApiArg = {
  accountId: UuidIdentifier;
  /** ...
   */
  body: Blob;
};
export type BulkImportStatusApiResponse = /** status 200 ...
 */ CsvImportStatus;
export type BulkImportStatusApiArg = {
  accountId: UuidIdentifier;
  /** ...
   */
  body: {
    executionId?: string;
  };
};
export type UuidIdentifier = string;
export type CsvImportStatus = {
  /** State of a CSV import request.
   */
  status: 'RUNNING' | 'SUCCEEDED' | 'FAILED';
  /** List of contracts (by vendor name) for which the import failed.
   */
  failedContracts?: string[];
};
export const { useImportContractsMutation, useBulkImportStatusMutation } = injectedRtkApi;
