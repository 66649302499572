import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumberedOutlined';

import { Card } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { IconWrapper } from '@verticeone/design-system';

import { useRecommendationData } from './RecommendationProvider';

const Instructions = () => {
  const { palette } = useTheme();
  const {
    item: { empty, code },
  } = useRecommendationData();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_RECOMMENDATIONS' });
  const { t: tRecommendations } = useTranslation('cloudRecommendations', { keyPrefix: code });
  const content = tRecommendations('INSTRUCTIONS', { defaultValue: 'NOT_FOUND' });

  if (content === 'NOT_FOUND' || empty) {
    return null;
  }

  return (
    <Card padding={6} gap={6}>
      <Text variant="heading" size="S" color="text1" tag="h2">
        <Stack direction="row" gap="7px" alignItems="center">
          <IconWrapper icon={FormatListNumberedIcon} size="XS" htmlColor={palette.inactive.color2} />
          {t('USER_INSTRUCTIONS')}
        </Stack>
      </Text>
      <Text variant="body-regular" size="M" color="text2" maxWidth="900px" whiteSpace="pre-line">
        {content}
      </Text>
    </Card>
  );
};

export default Instructions;
