import FormNumberField from '@vertice/core/src/modules/forms/fields/FormNumberField';
import React from 'react';
import { TaskFormEntry } from './TaskFormEntry';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

export const DesiredContractLengthFormEntry = () => {
  const { t } = useTranslation();
  const desiredContractLength = useWatch({ name: 'desiredContractLength' });
  return (
    <TaskFormEntry<any, typeof FormNumberField>
      name="desiredContractLength"
      label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_LENGTH')}
      component={FormNumberField}
      componentProps={{
        minValue: 0,
        unit: t('UNITS.MONTH', { count: desiredContractLength || 0 }),
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      }}
      width={6}
    />
  );
};
