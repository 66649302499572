import React, { useCallback } from 'react';
import { FieldPathByValue, FieldValues, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { RadioGroup, Stack } from '@mui/material';

import { FormData, SelectionStatus } from './schema';
import { useTaskFormContext } from '../shared/TaskFormContext';
import { Offer as OfferDetail } from '../../../../../request/RequestDetailPage/widgets/Offer/Offer';
import { Offer } from '../shared/schemas';
import { useOfferItemMapping } from '../../../../../request/RequestDetailPage/widgets/Offer/helpers/itemMapping';
import { Checkbox } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system';
import { Divider } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { Card } from '@verticeone/design-system';
import { TaskFormControlLabel } from '../shared/formFields/TaskFormControlLabel';
import { RadioSwitch } from '@verticeone/design-system';

export const FinalOfferForm = () => {
  const { readOnly } = useTaskFormContext();
  const { t } = useTranslation();

  const baselineOffer = useWatch<FormData, 'baselineOffer'>({ name: 'baselineOffer' });
  const finalOfferId = useWatch<FormData, 'finalOffer.id'>({ name: 'finalOffer.id' });
  const status = useWatch<FormData, 'status'>({ name: 'status' });
  const { setValue } = useFormContext<FormData>();
  const disableRejection = useWatch<FormData, 'disableRejection'>({ name: 'disableRejection' });

  const { fields } = useFieldArray<FormData, 'negotiationOffers.instances'>({
    name: 'negotiationOffers.instances',
  });

  const setFinalOffer = useCallback(
    (offer?: Offer) => {
      if (offer) {
        setValue('status', 'ACCEPTED');
      }
      setValue('finalOffer', offer, { shouldValidate: true });
    },
    [setValue]
  );

  return (
    <Stack gap={4}>
      {fields.map(({ id }, i) => (
        <OfferSelectionPreview<FormData>
          key={id}
          index={i}
          name={`negotiationOffers.instances.${i}`}
          baselineOffer={baselineOffer}
          finalOfferId={finalOfferId}
          setFinalOffer={setFinalOffer}
        />
      ))}
      <Divider />
      <Card p={4}>
        <RadioGroup
          row
          value={status ?? null}
          onChange={(_event, newValue) => setValue('status', newValue as SelectionStatus, { shouldValidate: true })}
        >
          <TaskFormControlLabel
            control={<RadioSwitch value="REPEAT" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} size="L" />}
            label={
              <Text variant="body-bold" size="M">
                {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.CONTINUE_NEGOTIATION')}
              </Text>
            }
            disabled={readOnly || !!finalOfferId}
          />
          {!disableRejection && (
            <TaskFormControlLabel
              control={<RadioSwitch value="DECLINE" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} size="L" />}
              label={
                <Text variant="body-bold" size="M">
                  {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.DECLINED_NEGOTIATION')}
                </Text>
              }
              disabled={readOnly || !!finalOfferId}
            />
          )}
        </RadioGroup>
      </Card>
    </Stack>
  );
};

type OfferSelectionPreviewProps<
  V extends FieldValues,
  P extends FieldPathByValue<V, Offer> = FieldPathByValue<V, Offer>
> = {
  name: P;
  baselineOffer: Offer;
  index: number;
  finalOfferId?: string;
  setFinalOffer: (v?: Offer) => void;
};
const OfferSelectionPreview = <T extends FieldValues>({
  index,
  name: offerName,
  baselineOffer,
  finalOfferId,
  setFinalOffer,
}: OfferSelectionPreviewProps<T>) => {
  const { readOnly } = useTaskFormContext();
  const { t } = useTranslation();

  const offer = useWatch<T, typeof offerName>({ name: offerName });
  const { vendor, products, concessions } = useOfferItemMapping(offer);
  const selected = offer.id === finalOfferId;

  return (
    <OfferDetail
      type="final"
      vendor={vendor}
      products={products}
      concessions={concessions}
      offer={offer}
      baselineOffer={baselineOffer}
      header={
        <Stack flexDirection="row" alignItems="center">
          <Checkbox
            checked={selected}
            onChange={() => setFinalOffer(selected ? undefined : offer)}
            disabled={readOnly}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            size="S"
            testId={`${offer.name}`}
          />
          <Text variant="body-bold" size="XL">{`${t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.OFFER_SUMMARY', {
            count: index + 1,
          })}: ${offer.name}`}</Text>
        </Stack>
      }
    />
  );
};
