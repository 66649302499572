import { ShoppingCartOutlined } from '@mui/icons-material';

import BaseTable from '../components/BaseTable';
import { useShoppingCart } from '../../../providers/ShoppingCartProvider';
import { useColumns } from './columns';
import TableCard from '../components/TableCard';
import { LoadableComponent } from '../components/LoadableComponent';
import TableFooter from '../components/tableFooter/TableFooter';
import { sumBy } from 'lodash';
import TableContextProvider, { useTableContext } from '../components/TableContext';

const T_KEY = 'CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.CUSTOMIZE_PURCHASE_TAB.SHOPPING_CART';

const ShoppingTable = () => {
  const columns = useColumns(T_KEY);
  const { cartItems } = useShoppingCart();
  const { apiRef } = useTableContext();

  const shoppingCartData = cartItems;

  const shoppingCartTotalCost = shoppingCartData ? sumBy(shoppingCartData, ({ totalCost }) => totalCost) : 0;
  const isEmpty = shoppingCartData?.length === 0;

  return (
    <TableCard icon={ShoppingCartOutlined} tKey={T_KEY}>
      <LoadableComponent error={false} isLoading={false} isShoppingCartEmpty={isEmpty}>
        <BaseTable
          data={shoppingCartData ?? []}
          defaultSortModel={{ sort: 'asc', field: 'term' }}
          getRowId={(row) => row.offeringId}
          tableColumns={columns}
          slots={{
            footer: TableFooter,
          }}
          slotProps={{ footer: { totalCost: shoppingCartTotalCost, isEmpty } }}
          hideFooter={isEmpty}
          apiRef={apiRef}
        />
      </LoadableComponent>
    </TableCard>
  );
};

const ShoppingTableWithContext = () => (
  <TableContextProvider isShoppingTable={true}>
    <ShoppingTable />
  </TableContextProvider>
);

export default ShoppingTableWithContext;
