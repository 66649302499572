import React, { useMemo, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { IconButton } from '@verticeone/design-system';
import { Tab } from '@verticeone/design-system';
import { Tabs } from '@verticeone/design-system';
import { useDivergentColorPalette } from '@verticeone/design-system';
import { getColoredItem } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import { regionsCodeToNameMap } from '@vertice/dashboard/src/modules/cloud/regions';

import RiAgentInventoryDonutChart from './RiAgentInventoryDonutChart';
import SizeGraph from './SizeGraph';
import ReservationsTable from './ReservationsTable';
import { InstancesDetailData, useGetInstancesDetailData } from './useGetInstancesDetailData';
import { useTranslation } from 'react-i18next';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useCloudInventory } from '../useCloudInventory';

type RowDetailModalProps = {
  data: Pick<InstancesDetailData, 'region' | 'instanceFamily' | 'product'> | null;
  isOpen: boolean;
  onClose: () => void;
};

enum RowDetailTabs {
  PERFORMANCE = 'performance',
  RESERVED_INSTANCES = 'reserved_instances',
}

const FirstDonutChart = ({ data }: { data: InstancesDetailData }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.INVENTORY.DETAIL' });
  const colors = useDivergentColorPalette();
  const { activeTab } = useCloudInventory();

  switch (activeTab.id) {
    case 'ec2':
      return (
        <RiAgentInventoryDonutChart
          name={t('CHARTS.CLASS.TITLE')}
          categories={[
            {
              name: t('CHARTS.CLASS.LEGEND.STANDARD'),
              y: data.graphData.class.standard,
              ...getColoredItem(colors[1]),
            },
            {
              name: t('CHARTS.CLASS.LEGEND.CONVERTIBLE'),
              y: data.graphData.class.convertible,
              ...getColoredItem(colors[2]),
            },
          ]}
        />
      );
    case 'rds':
      const accountsCategories = Object.entries(data?.graphData.accounts ?? {}).map(([accountId, count], index) => ({
        name: accountId,
        y: count,
        ...getColoredItem(colors[index % colors.length]), // Use a different color for each account
      }));

      return <RiAgentInventoryDonutChart name={t('CHARTS.ACCOUNT.TITLE')} categories={accountsCategories} />;
    default:
      return null;
  }
};

const RowDetailModal = ({ data: rawData, isOpen, onClose }: RowDetailModalProps) => {
  const colors = useDivergentColorPalette();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.INVENTORY.DETAIL' });
  const [activeTab, setActiveTab] = useState(RowDetailTabs.RESERVED_INSTANCES);
  const getInstancesDetailData = useGetInstancesDetailData();
  const data = useMemo(() => (rawData ? getInstancesDetailData(rawData) : null), [getInstancesDetailData, rawData]);

  if (!data) {
    return null;
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Stack padding={8} gap={8} width="min(1440px, 100vw - 300px)">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" gap={2}>
            <Text tag="h2" variant="heading" size="M" color="text1">
              {data.instanceFamily}
            </Text>
            <Text testId="ri-region" variant="body-regular" size="M" color="text2">
              {regionsCodeToNameMap[data.region]}
            </Text>
          </Stack>
          <IconButton variant="ghost" color="neutral" icon={CloseOutlinedIcon} onClick={onClose} />
        </Stack>
        <Stack>
          <Tabs variant="outlined" value={activeTab} onChange={(_, value) => setActiveTab(value)} color="tertiary">
            {/*<Tab value={RowDetailTabs.PERFORMANCE} label={t('TABS.PERFORMANCE')} />*/}
            <Tab value={RowDetailTabs.RESERVED_INSTANCES} label={t('TABS.RESERVED_INSTANCES')} />
          </Tabs>
        </Stack>
        <Stack direction="row" gap={4} width="100%" overflow="auto">
          <FirstDonutChart data={data} />

          <RiAgentInventoryDonutChart
            name={t('CHARTS.TERM.TITLE')}
            categories={[
              {
                name: t('CHARTS.TERM.LEGEND.YEAR', { count: 3 }),
                y: data.graphData.term.threeYears,
                ...getColoredItem(colors[0]),
              },
              {
                name: t('CHARTS.TERM.LEGEND.YEAR', { count: 1 }),
                y: data.graphData.term.oneYear,
                ...getColoredItem(colors[5]),
              },
            ]}
          />

          <RiAgentInventoryDonutChart
            name={t('CHARTS.PAYMENT.TITLE')}
            categories={[
              {
                name: t('CHARTS.PAYMENT.LEGEND.NO_UPFRONT'),
                y: data.graphData.payment.noUpfront,
                ...getColoredItem(colors[4]),
              },
              {
                name: t('CHARTS.PAYMENT.LEGEND.UPFRONT'),
                y: data.graphData.payment.upfront,
                ...getColoredItem(colors[1]),
              },
            ]}
          />
          {/*
          <RiAgentInventoryDonutChart
            name={t('CHARTS.PURCHASED_VIA_VERTICE.TITLE')}
            categories={[
              { name: t('CHARTS.PURCHASED_VIA_VERTICE.LEGEND.WITHOUT_VERTICE'), y: 60, ...getColoredItem(colors[3]) },
              { name: t('CHARTS.PURCHASED_VIA_VERTICE.LEGEND.VIA_VERTICE'), y: 40, ...getColoredItem(colors[2]) },
            ]}
          />
          */}
        </Stack>
        <SizeGraph categories={data.sizeGraphData} />
        <ReservationsTable rows={data.tableData} />
      </Stack>
    </Drawer>
  );
};

export default RowDetailModal;
