import React, { useEffect } from 'react';
import { DashboardCustomizeOutlined } from '@mui/icons-material';

import BaseTable from '../components/BaseTable';
import SelectFilterItem from '../components/filter/SelectFilterItem';
import { useColumns } from './columns';
import TableCard from '../components/TableCard';
import { LoadableComponent } from '../components/LoadableComponent';
import { useOfferings } from '../../../providers/OfferingsProvider';
import { useInstanceData } from '../../../providers/InstanceProvider';
import { useShoppingCart } from '../../../providers/ShoppingCartProvider';

const T_KEY = 'CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.CUSTOMIZE_PURCHASE_TAB.TABLE';

const CustomizeTable = () => {
  const columns = useColumns(T_KEY);
  const { omittedColumns } = useInstanceData();
  const { cartItems } = useShoppingCart();

  const { filter, offerings } = useOfferings({
    filterByShoppingCart: false,
  });

  useEffect(() => {
    offerings.fetch();
  }, [offerings]);

  const filterItems: {
    [key: string]: JSX.Element;
  } = {
    offeringType: (
      <SelectFilterItem
        key="offeringType"
        id="offeringType"
        tKey="CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.CUSTOMIZE_PURCHASE_TAB.FILTER.PAYMENT_OPTION"
        minWidth={160}
      />
    ),
    duration: (
      <SelectFilterItem
        key="duration"
        id="duration"
        tKey="CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.CUSTOMIZE_PURCHASE_TAB.FILTER.DURATION"
      />
    ),
  };

  const filteredSelectFilterItems = Object.keys(filterItems)
    .filter((key) => !omittedColumns?.includes(key))
    .map((key) => filterItems[key]);

  const tableData = (offerings?.data ?? []).map(
    (offering) => cartItems?.find((cartItem) => cartItem.offeringId === offering.offeringId) || offering
  );

  return (
    <TableCard icon={DashboardCustomizeOutlined} tKey={T_KEY} filterComponent={<>{filteredSelectFilterItems}</>}>
      <LoadableComponent
        error={offerings.error}
        isLoading={offerings.isFetching}
        isEmpty={!offerings.data?.length}
        isFilterApplied={filter.isApplied}
      >
        <BaseTable
          data={tableData}
          defaultSortModel={{ sort: 'asc', field: 'term' }}
          getRowId={(row) => row.offeringId}
          tableColumns={columns}
        />
      </LoadableComponent>
    </TableCard>
  );
};

export default CustomizeTable;
