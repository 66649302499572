/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Stack, styled, Typography, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { DataGrid, GridColDef } from '@verticeone/design-system';
import { gridClasses, GridRowModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@verticeone/utils/formatting';
import { TopProductsBySpendData } from './useTopProductsBySpendData';
import Chip from './Chip';
import { AWS_BRAND_COLOR } from '../../constants';

type TopProductsBySpendProps = {
  data: TopProductsBySpendData;
};

const StyledWrapper = styled('div')({
  position: 'relative',
  height: '100%',
});

const ChipContainer = styled('div')({
  flexBasis: '65px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const ROW_COUNT = 10;

const TopProductsBySpendTable = ({ data }: TopProductsBySpendProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.TOP_PRODUCTS_BY_SPEND' });
  const { locale } = useLocaleContext();
  const theme = useTheme();

  const getRowId = (row: GridRowModel) => row.product;

  const columns: GridColDef[] = [
    {
      field: 'product',
      headerName: t('COLUMN.PRODUCT'),
      editable: false,
      renderCell: (params) => <Typography variant="body-regular-m">{params.value}</Typography>,
      flex: 1,
    },
    {
      field: 'value',
      headerName: t('COLUMN.SPEND'),
      align: 'right',
      type: 'number',
      editable: false,
      renderCell: (params) => {
        const formattedValue = formatCurrency(params.value, {
          currency: data.currency,
          locale,
          maximumFractionDigits: 0,
          withSpace: true,
        });

        const currentValue = params.value;
        const previousValue = params.row.previousValue;
        const normalizedPreviousValue = previousValue && Math.abs(previousValue) >= 0.1 ? previousValue : 0;

        return (
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Typography variant="body-bold-m">{formattedValue}</Typography>
            <ChipContainer>
              <Chip value={currentValue} previousValue={normalizedPreviousValue} currency={data.currency} />
            </ChipContainer>
          </Stack>
        );
      },
      flex: 1,
    },
  ];

  return (
    <StyledWrapper>
      <DataGrid
        rows={data.items.slice(0, ROW_COUNT) || []}
        columns={columns}
        getRowId={getRowId}
        sortingMode="client"
        hideFooter
        noBorderRadius
        noBorder
        showCellVerticalBorder
        showColumnVerticalBorder
        disableRowSelectionOnClick
        autoHeight={false}
        scrollbarSize={12}
        hideFooterRowCount
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          [`.${gridClasses.cell}`]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(3),
          },
          [`.${gridClasses.columnHeader}`]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(3),
          },
          [`.${gridClasses.virtualScroller}`]: {
            overflowX: 'hidden',
            overflowY: 'auto',
          },
        }}
        color={AWS_BRAND_COLOR}
      />
    </StyledWrapper>
  );
};

export default TopProductsBySpendTable;
