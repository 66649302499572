import { Text } from '@verticeone/design-system';
import { ViewOnContractForVendorsPage } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

type DepartmentCellProps = {
  contractData: ViewOnContractForVendorsPage;
};

export const DepartmentCell = ({ contractData }: DepartmentCellProps) => {
  const departmentId = contractData.departmentId;
  const departmentLabel = departmentId ? contractData.computed?.labels?.[departmentId] : undefined;

  if (departmentLabel === undefined) {
    return (
      <Text variant="body-regular" size="M" color="inactive2">
        —
      </Text>
    );
  }
  return <Text variant="body-regular">{departmentLabel}</Text>;
};
