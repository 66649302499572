import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { NoData } from '@verticeone/design-system';
import AwsLoader from './Loader';
import { useMonitoringLatestQuery } from '../../dataSources/useMonitoringLatestQuery';
import { useDataSource } from '../../../DataSourceProvider';

type HasDataWrapperProps = {
  children: React.ReactNode;
};

const StyledCancelOutlined = styled(CancelOutlined)(({ theme }) => ({
  color: theme.palette.tertiary.color2,
}));

const DataLoaderComponent = ({ children }: HasDataWrapperProps) => {
  const { datasource } = useDataSource();
  const { data, isFetching } = useMonitoringLatestQuery(datasource);
  const { t } = useTranslation(undefined, { keyPrefix: `CLOUD.RIO_NEW.OVERVIEW` });

  if (isFetching) {
    return <AwsLoader pb={6} />;
  }

  if (!data?.resource_cost) {
    return (
      <Stack pb={6}>
        <NoData icon={<StyledCancelOutlined />} header={t('DATA_UNAVAILABLE')} />
      </Stack>
    );
  }

  return <>{children}</>;
};

export default DataLoaderComponent;
