import React from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Alert } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';

export type NonSaasRenewalAlertProps = {
  contract: Contract;
};

export const NonSaasRenewalAlert = ({ contract }: NonSaasRenewalAlertProps) => {
  const { t } = useTranslation();
  if (contract.classification?.category === 'SAAS') {
    return null;
  }

  return (
    <Alert
      icon={InfoOutlined}
      size="S"
      variant="ghost"
      color="info"
      title={
        <Stack gap={4}>
          <Box>{t('ENTITIES.CONTRACT.ALERTS.NON_SAAS_RENEWAL.LINE_1')}</Box>
          <Box>{t('ENTITIES.CONTRACT.ALERTS.NON_SAAS_RENEWAL.LINE_2')}</Box>
        </Stack>
      }
    />
  );
};
