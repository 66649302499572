import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@verticeone/design-system';

const DefaultColumn = ({ value }: GridRenderCellParams) => (
  <Text variant="body-regular" size="M" title={value} overflow="hidden" textOverflow="ellipsis">
    {value ?? '-'}
  </Text>
);

export default DefaultColumn;
