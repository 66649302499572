import { ListJiraProjectsApiResponse } from '@vertice/slices';

export enum JiraSetupStep {
  CONNECTION = 'connection',
  CONFIGURATION = 'configuration',
  SUCCESS = 'success',
}

export type StepContentProps = {
  activeStep: JiraSetupStep;
  setStep: (step: JiraSetupStep) => void;
};

export type CardEditMode = {
  connection: boolean;
  project: boolean;
};

export type EditCardProps = {
  cardEditMode: CardEditMode;
  setCardEditMode: (newValue: CardEditMode) => void;
};

export type JiraProject = NonNullable<ListJiraProjectsApiResponse['projects']>[number];
