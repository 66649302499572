import React from 'react';
import { Stack } from '@mui/material';
import { SelectColor } from '@verticeone/design-system';
import { CommonFormFieldProps } from '../../types';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import FormNumberField from '../FormNumberField';
import { FormCurrencyField } from '../FormCurrencyField';

export type FormMoneyFieldType<FormDataType extends FieldValues> = (
  props: FormMoneyFieldProps<FormDataType>
) => JSX.Element;

type FormMoneyFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  color?: SelectColor;
  allowsFloat?: boolean;
};

export const FormMoneyField = <FormDataType extends FieldValues = never>({
  id,
  required,
  disabled,
  name,
  placeholder,
  error,
  helperText,
  color,
  allowsFloat = false,
}: FormMoneyFieldProps<FormDataType>) => (
  <Stack direction="row" gap={2}>
    <FormNumberField<any>
      id={`${id}Value`}
      name={`${name}.value`}
      disabled={disabled}
      color={color}
      sx={{ flex: 1 }}
      placeholder={placeholder}
      error={error}
      required={required}
      allowsFloat={allowsFloat}
      helperText={helperText}
    />
    <FormCurrencyField<any>
      id={`${id}Currency`}
      name={`${name}.currency`}
      isClearable={false}
      disabled={disabled}
      color={color}
    />
  </Stack>
);
