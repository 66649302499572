import React from 'react';
import { FormControlLabel } from '@mui/material';
import { useController } from 'react-hook-form';
import { Text, ToggleSwitch } from '@verticeone/design-system';

type FormToggleSwitchFieldProps = React.ComponentProps<typeof ToggleSwitch> & {
  name: string;
  label: string;
  required?: boolean;
};

export type FormToggleSwitchFieldType = (props: FormToggleSwitchFieldProps) => JSX.Element;

export const FormToggleSwitchField = ({ required, name, label, ...otherProps }: FormToggleSwitchFieldProps) => {
  const {
    field: { ref, value, ...field },
  } = useController({ name, rules: { required } });

  return (
    <FormControlLabel
      checked={value}
      control={<ToggleSwitch inputRef={ref} size="S" sx={{ mr: '6px' }} {...field} {...otherProps} />}
      label={
        <Text variant="button-bold" size="S">
          {label}
        </Text>
      }
    />
  );
};
