import { ReactNode, useCallback, useEffect, useState } from 'react';
import { GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import { DataGridProps } from '@verticeone/design-system';

export type UseDetailRowParams = {
  detailRowRenderer: ((params: GridRowParams) => ReactNode) | undefined;
};

export type UseDetailRowReturn = Pick<
  DataGridProps,
  'getDetailPanelContent' | 'getDetailPanelHeight' | 'detailPanelExpandedRowIds' | 'onDetailPanelExpandedRowIdsChange'
>;

export const useDetailRow = ({ detailRowRenderer }: UseDetailRowParams): UseDetailRowReturn => {
  const [expandedRows, setExpandedRows] = useState<GridRowId[]>([]);
  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    // Allow only one expanded row at a time
    setExpandedRows(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds);
  }, []);

  useEffect(() => {
    // collapse rows when switched from view to edit mode
    if (!detailRowRenderer) {
      setExpandedRows([]);
    }
  }, [detailRowRenderer]);

  return {
    getDetailPanelContent: detailRowRenderer,
    getDetailPanelHeight: () => 'auto',
    detailPanelExpandedRowIds: expandedRows,
    onDetailPanelExpandedRowIdsChange: handleDetailPanelExpandedRowIdsChange,
  };
};
