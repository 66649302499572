import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import useHistoricalSpendByEBSGenerationData from './useHistoricalSpendByEBSGenerationData';
import { Card, CardHeader, CardHeaderSubtitle, CardHeaderTitle } from '@verticeone/design-system';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { LoadableContentWrapper } from '@vertice/dashboard/src/modules/cloud/components/LoadableContentWrapper';
import { HistoricalSpendByEbsGenerationGraph } from './HistoricalSpendByEBSGenerationGraph';

const HistoricalSpendByEBSGenerationCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { data, ...loadingStatus } = useHistoricalSpendByEBSGenerationData();

  return (
    <Card flex={1}>
      <CardHeader size="S">
        <CardHeaderTitle text={t('HISTORICAL_SPEND_BY_EBS_GENERATION.TITLE')} tag="h2" />
        <CardHeaderSubtitle text={t('LAST_X_MONTHS', { monthCount: 12 })} icon={CalendarTodayOutlined} />
      </CardHeader>
      <Stack flex={1}>
        <LoadableContentWrapper {...loadingStatus}>
          <HistoricalSpendByEbsGenerationGraph data={data!} />
        </LoadableContentWrapper>
      </Stack>
    </Card>
  );
};

export default HistoricalSpendByEBSGenerationCard;
