import { FC, useMemo } from 'react';
import sumBy from 'lodash/sumBy';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { Divider } from '@verticeone/design-system';
import { Placeholder } from '@verticeone/design-system';
import { DesignSystemSize } from '@verticeone/design-system';

import { dateTimeFormatterOptions, useFormatDate } from '@verticeone/utils/formatting';
import { WorkflowRow } from './types';

type SummaryBlockProps = {
  flex: number;
  header: string;
  value?: string | number;
  size?: DesignSystemSize;
  isLoading?: boolean;
};

const SummaryBlock: FC<SummaryBlockProps> = ({ isLoading, flex, value, header, size = 'S' }) => {
  return (
    <Stack padding={6} flex={flex}>
      <Text variant="caption" size="S" color="text2">
        {header}
      </Text>
      <Text variant="heading" size={size}>
        {isLoading ? (
          <Placeholder style={{ marginTop: 4 }} height={size === 'M' ? 25 : 20} width={100} />
        ) : (
          value ?? '-'
        )}
      </Text>
    </Stack>
  );
};

type ServiceSummaryProps = {
  workflows: WorkflowRow[];
  isLoadingWorkflows?: boolean;
};

export const ServiceSummary: FC<ServiceSummaryProps> = ({ workflows, isLoadingWorkflows }) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const summary = useMemo(() => {
    return {
      totalActiveTasks: sumBy(workflows, (w) => w.activeTasks),
      totalCompletedRequests: sumBy(workflows, (w) => w.completedInstances),
      totalActiveRequests: sumBy(workflows, (w) => w.activeInstances),
      defaultWorkflow: workflows?.find((w) => w.isDefault),
    };
  }, [workflows]);

  return (
    <Card>
      <Stack direction="column" divider={<Divider />}>
        <Stack direction="row" divider={<Divider orientation="vertical" />}>
          <SummaryBlock
            flex={4.5}
            header={t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.SUMMARY.DEFAULT_WORKFLOW')}
            value={summary.defaultWorkflow?.workflowName}
            size="M"
            isLoading={isLoadingWorkflows}
          />
          <SummaryBlock
            flex={1.5}
            header={t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.SUMMARY.VERSION')}
            value={summary.defaultWorkflow?.activeVersionName}
            isLoading={isLoadingWorkflows}
          />
          <SummaryBlock
            flex={3}
            header={t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.SUMMARY.LAST_REQUEST_CREATION_DATE')}
            value={formatDate(summary.defaultWorkflow?.lastInvokedAt, { formatterOptions: dateTimeFormatterOptions })}
            isLoading={isLoadingWorkflows}
          />
        </Stack>
        <Stack direction="row" divider={<Divider orientation="vertical" />}>
          <SummaryBlock
            flex={1}
            header={t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.SUMMARY.TOTAL_ACTIVE_REQUESTS')}
            value={summary.totalActiveRequests}
            isLoading={isLoadingWorkflows}
          />
          <SummaryBlock
            flex={1}
            header={t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.SUMMARY.TOTAL_COMPLETED_REQUESTS')}
            value={summary.totalCompletedRequests}
            isLoading={isLoadingWorkflows}
          />
          <SummaryBlock
            flex={1}
            header={t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.SUMMARY.TOTAL_ACTIVE_TASKS')}
            value={summary.totalActiveTasks}
            isLoading={isLoadingWorkflows}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
