import { useFormatDate } from '@verticeone/utils/formatting';
import { Text } from '@verticeone/design-system';
import AdditionalInfo from '../../Deadline/AdditionalInfo';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import {
  hasCompletedNonCancelledRenewal,
  isCancelledContract,
  isContractLiveOrNotYetLive,
  isContractWorkflowInProgress,
} from '../../../computed';
import { Stack, useTheme } from '@mui/material';
import { t } from 'i18next';
import { styled } from '@mui/system';

export type AutoRenewalValue = {
  date?: string;
  waived: boolean;
};

export const autoRenewalDateValueGetter = ({ contract }: ViewOnContract): AutoRenewalValue | undefined => {
  if (isCancelledContract(contract) || hasCompletedNonCancelledRenewal(contract)) {
    return undefined;
  }

  if (isContractLiveOrNotYetLive(contract)) {
    return {
      date: contract.parts.contractual?.lifecycle?.autoRenewalDeadline,
      waived: !!contract.parts.contractual?.renewal?.autoRenewalWaived,
    };
  }

  if (isContractWorkflowInProgress(contract)) {
    return {
      date: contract.materialized?.prev?.autoRenewalDeadline,
      waived: !!contract.materialized?.prev?.autoRenewalWaived,
    };
  }

  return undefined;
};

const WaivedInfo = () => {
  const { palette } = useTheme();
  return (
    <Text variant="body-bold" size="S" color={palette.mode === 'light' ? 'text3' : 'text2'}>
      {t('CONTRACTS.GRID.WAIVED')}
    </Text>
  );
};

const StyledText = styled(Text)<{ $waived: boolean }>(({ theme, $waived }) => ({
  textDecoration: $waived ? 'line-through' : 'none',
  color: $waived ? theme.palette.inactive.color2 : theme.palette.text.color1,
}));

export type AutoRenewalDateCellProps = {
  date: string;
  autoRenewalWaived: boolean;
};

export const AutoRenewalDateCell = ({ date, autoRenewalWaived }: AutoRenewalDateCellProps) => {
  const formatDate = useFormatDate();
  return date ? (
    <Stack>
      <StyledText variant="body-regular" $waived={autoRenewalWaived}>
        {formatDate(date)}
      </StyledText>
      {autoRenewalWaived ? <WaivedInfo /> : <AdditionalInfo date={date} />}
    </Stack>
  ) : (
    <Text variant="body-regular" size="M" color="inactive2">
      —
    </Text>
  );
};
