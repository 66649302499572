import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';
import { useMonitoringLatestQuery } from '../../../../../dataSources/useMonitoringLatestQuery';
import { Graph, GraphLegend, GraphSeries } from '../../../components/Graph';
import { createSeriesConfig } from '../../../components/Graph/utils';

const useOptimizationSeries = () => {
  const { palette } = useTheme();
  const { data, isFetching } = useMonitoringLatestQuery('Compute');
  const { t } = useTranslation(undefined, {
    keyPrefix: `CLOUD.RIO_NEW.OVERVIEW.WIDGETS.GRAPH`,
  });

  return useMemo(
    () => ({
      isFetching,
      resourceCost: data?.resource_cost ?? 0,
      series: [
        createSeriesConfig({
          id: 'EC2_RI_COVERED_COST',
          value: data?.total_ri_cost_percentage,
          color: palette.visualization.monochromatic.tertiary['80'],
          name: t('EC2_RI_COVERED_COST'),
          hoverColor: palette.visualization.monochromatic.tertiary['50'],
          labelColor: palette.visualization.monochromatic.tertiary['30'],
        }),
        createSeriesConfig({
          id: 'EC2_SP_COVERED_COST',
          value: data?.total_ec2_sp_usage_cost_percentage,
          color: palette.visualization.monochromatic.success['80'],
          name: t('EC2_SP_COVERED_COST'),
          hoverColor: palette.visualization.monochromatic.success['50'],
          labelColor: palette.visualization.monochromatic.success['30'],
        }),
        createSeriesConfig({
          id: 'COMPUTE_SP_COVERED_COST',
          value: data?.total_sp_usage_cost_percentage,
          color: palette.visualization.monochromatic.primary['80'],
          name: t('COMPUTE_SP_COVERED_COST'),
          hoverColor: palette.visualization.monochromatic.primary['50'],
          labelColor: palette.visualization.monochromatic.primary['30'],
        }),
        createSeriesConfig({
          id: 'ON_DEMAND_USAGE',
          value: data?.total_on_demand_cost_percentage,
          color: getColorPattern(palette.text.color3),
          name: t('ON_DEMAND_USAGE'),
          hoverColor: getColorPattern(palette.text.color4),
          labelColor: palette.text.color1,
        }),
      ] as GraphSeries,
    }),
    [data, isFetching, palette, t]
  );
};

const GraphContainer = () => {
  const { series, isFetching, resourceCost } = useOptimizationSeries();

  if (isFetching) {
    return null;
  }

  return (
    <>
      <GraphLegend series={series} />
      <Graph series={series} hasData={resourceCost > 0} />
    </>
  );
};

export default GraphContainer;
