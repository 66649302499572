import { z, ZodObject, ZodRawShape } from 'zod';
import { CheckboxFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';

export function getCheckboxPresenterSchema(field: CheckboxFieldType): ZodObject<ZodRawShape> {
  return z.object({
    [field.name]: field.metadata.required ? z.literal(true) : z.boolean(),
  });
}

export function getCheckboxPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<CheckboxFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: false };
}

export function getCheckboxJsonSchema(field: CheckboxFieldType) {
  const fieldJsonScheme = field.metadata.required ? { enum: [true] } : { type: 'boolean' };

  return {
    required: [field.name],
    properties: {
      [field.name]: fieldJsonScheme,
    },
  };
}
