import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { isNil } from 'lodash';

import { Text, Tooltip, TooltipInfoTrigger } from '@verticeone/design-system';
import { ProgressBar } from './ProgressBar';
import { License } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { getEstimatedUtilization, isLicenseSeatBased } from './helpers';

type UtilizationCellProps = {
  utilization: number;
  license: License;
  activeUsers: string;
  userCount?: number;
};

export const UtilizationCell = ({ utilization, license, activeUsers, userCount }: UtilizationCellProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const hasNoLicense = isNil(license);
  const hasSeatBasedLicense = isLicenseSeatBased(license);
  const hasNoUsers = activeUsers === '0' && userCount === 0;

  return (
    <Stack flexGrow={1} gap={1}>
      {hasNoLicense ? (
        <ProgressBar />
      ) : (
        <ProgressBar
          utilization={hasSeatBasedLicense ? utilization : getEstimatedUtilization(activeUsers, userCount)}
          hasSeatBasedLicense={hasSeatBasedLicense}
        />
      )}
      <Stack direction="row" gap={0.5}>
        <Text variant="body-bold" size="S">
          {hasNoLicense || hasNoUsers ? t('APPLICATIONS.TOOLTIPS.UTILIZATION.NO_DATA') : activeUsers}
        </Text>
        {hasSeatBasedLicense ? (
          <>
            <Text variant="body-bold" size="S" color={palette.text.color3}>
              /
            </Text>
            <Text variant="body-regular" size="S">
              {license.quantity}
            </Text>
          </>
        ) : (
          <Tooltip content={t('APPLICATIONS.TOOLTIPS.UTILIZATION.ESTIMATED_DATA_TOOLTIP_CONTENT')} size="S">
            <TooltipInfoTrigger />
          </Tooltip>
        )}
        {(hasNoLicense || hasNoUsers) && (
          <Tooltip
            title={t('APPLICATIONS.TOOLTIPS.UTILIZATION.NO_DATA_TOOLTIP_TITLE')}
            content={t('APPLICATIONS.TOOLTIPS.UTILIZATION.NO_DATA_TOOLTIP_CONTENT')}
            size="S"
          >
            <TooltipInfoTrigger />
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};
