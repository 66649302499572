import { exchangeRatesAPI as api } from '../../api/exchangeRatesAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExchangeRates: build.query<GetExchangeRatesApiResponse, GetExchangeRatesApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/exchange-rates` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as exchangeRatesAPICodegen };
export type GetExchangeRatesApiResponse = /** status 200 Map of exchange rates
 */ {
  /** Map of exchange rates.
    Key is currency, value is exchange rate to USD
    Value is decimal number
     */
  rates?: {
    [key: string]: any;
  };
};
export type GetExchangeRatesApiArg = {
  accountId: Identifier;
};
export type Identifier = string;
export const { useGetExchangeRatesQuery, useLazyGetExchangeRatesQuery } = injectedRtkApi;
