import { FC } from 'react';
import { styled } from '@mui/material';
import { Text } from '@verticeone/design-system';

import { highlightText } from './HighlightedText';

type TypeBadgeProps = {
  label: string;
  highlightedLabel?: string;
};

export const StyledTypeBadge = styled(Text)(({ theme: { spacing, palette } }) => ({
  padding: spacing(0.5, 1),
  borderRadius: spacing(1),
  color: palette.neutral.color2,
  backgroundColor: palette.neutral.color4,
}));

export const TypeBadge: FC<TypeBadgeProps> = ({ label, highlightedLabel }) => {
  const finalLabel = highlightedLabel ? highlightText(label, highlightedLabel, 'XXS') : label;

  return (
    <StyledTypeBadge variant="button-bold" size="XXS">
      {finalLabel}
    </StyledTypeBadge>
  );
};
