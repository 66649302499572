import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ApplicationWithMeasureReportsListItem } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import { WarningIcon } from '../UsageDetail/components/DataHealth/WarningIcon';
import { InfoIcon } from '../UsageDetail/components/DataHealth/InfoIcon';

export const VendorCell = ({ matches, name, hasWarnings, hasInfo }: ApplicationWithMeasureReportsListItem) => {
  const { t } = useTranslation();
  const vendor = matches?.find((match) => match.entityType === 'Vendor');

  return (
    <Stack direction="row" height="100%" width="100%" alignItems="center" gap={4}>
      <Stack>
        <VendorLogo vendorId={vendor?.entityId} size={30} />
      </Stack>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Text variant="body-regular" size="M">
          {name}
        </Text>
        <Text variant="caption" size="S" color={'text3'}>
          {vendor?.entityName}
        </Text>
      </Stack>
      <Stack direction="row" gap={1}>
        {hasWarnings && (
          <Tooltip title={t('APPLICATIONS.TOOLTIPS.DATA_HEALTH.DATA_WARNING')} placement="top" size="S">
            <WarningIcon size="M" />
          </Tooltip>
        )}
        {!hasWarnings && hasInfo && (
          <Tooltip title={t('APPLICATIONS.TOOLTIPS.DATA_HEALTH.DATA_INFO')} placement="top" size="S">
            <InfoIcon size="M" />
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};
