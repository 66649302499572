import { ServiceCatalogResource, useListServiceCatalogQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useMemo } from 'react';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type GetServiceCatalogProps = {
  resourcesFilter?: (item: ServiceCatalogResource) => boolean;
  skipAccountResources?: boolean;
  skipGlobalResources?: boolean;
};

export const useGetServiceCatalogResources = ({
  resourcesFilter,
  skipAccountResources,
  skipGlobalResources,
}: GetServiceCatalogProps) => {
  const { accountId } = useAccountContext();
  const { data: accountResources, isLoading: isLoadingAccount } = useListServiceCatalogQuery(
    {
      accountId,
    },
    { skip: skipAccountResources }
  );

  const { data: globalResources, isLoading: isLoadingGlobal } = useListServiceCatalogQuery(
    {
      accountId: 'GLOBAL',
    },
    { skip: skipGlobalResources }
  );

  const formResources = useMemo(() => {
    const data = [
      ...((skipAccountResources ? undefined : accountResources?.items) || []),
      ...((skipGlobalResources ? undefined : globalResources?.items) || []),
    ];
    return data?.filter((item) => (resourcesFilter ? resourcesFilter(item) : true)) || [];
  }, [globalResources, accountResources, resourcesFilter, skipAccountResources, skipGlobalResources]);

  const isLoading = isLoadingGlobal || isLoadingAccount;
  return {
    resources: formResources,
    isLoading: isLoading,
  };
};
