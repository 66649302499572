import { z, ZodObject, ZodRawShape } from 'zod';
import { MoneyFieldType } from './types';
import { setRequiredTo } from '@verticeone/utils/validation';
import { GetPresenterDefaultProps } from '../types';

export const getMoneyPresenterSchema = (field: MoneyFieldType): ZodObject<ZodRawShape> => {
  const valueSchema = z.number();
  return z.object({
    [field.name]: z.object({
      value: field.metadata.required ? valueSchema : valueSchema.nullable(),
      currency: setRequiredTo(z.string().min(3).max(3), field.metadata.required),
    }),
  });
};

export const getMoneyPresenterDefault = ({
  field,
  config,
  defaultValues,
}: GetPresenterDefaultProps<MoneyFieldType>) => {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: { value: null, currency: config.defaultCurrency } };
};

export const getMoneyJsonSchema = (field: MoneyFieldType) => ({
  required: [field.name],
  properties: {
    [field.name]: {
      type: 'object',
      properties: {
        value: { type: field.metadata.required ? 'number' : ['number', 'null'] },
        currency: { type: 'string', minLength: 3, maxLength: 3 },
      },
      required: ['value', 'currency'],
    },
  },
});
