import React, { forwardRef } from 'react';
import { Stack, styled } from '@mui/material';
import { getTextVariantStyle } from '@verticeone/design-system';
import { useMainLayout } from '../../LayoutWrapper';

type NavigationDropdownProps = {
  label?: string;
  isVisible?: boolean;
  children?: React.ReactNode;
  icon?: React.ComponentType;
} & Partial<React.DetailedHTMLProps<React.FieldsetHTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement>>;

const StyledFieldset = styled('fieldset')<{ $isOpen: boolean }>(({ theme, $isOpen }) => ({
  border: `1px solid ${theme.palette.core.color2}`,
  borderRadius: '8px',
  padding: 0,
  margin: '0',
  legend: {
    gap: '4px',
    padding: 0,
    margin: $isOpen ? '0 8px' : '0 auto',
    color: theme.palette.text.color3,
    backgroundColor: theme.palette.core.bg,
    ...getTextVariantStyle({ variant: 'caption', size: 'XXS' }),

    '& svg': {
      opacity: 0.4,
    },
  },
}));

const NavigationGroup = forwardRef<HTMLFieldSetElement, NavigationDropdownProps>(
  ({ label, icon: GroupIcon, isVisible = true, children, ...otherProps }, ref) => {
    const { isOpen } = useMainLayout();

    if (!isVisible || !React.Children.toArray(children).length) {
      return null;
    }

    return (
      <StyledFieldset {...otherProps} $isOpen={isOpen} ref={ref}>
        {label && (
          <Stack component="legend" direction="row" gap={1}>
            {GroupIcon && isOpen && <GroupIcon />}
            {label}
          </Stack>
        )}
        <Stack direction="column" padding="6px">
          {children}
        </Stack>
      </StyledFieldset>
    );
  }
);

export default NavigationGroup;
