import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatPercentage } from '@verticeone/utils/formatting';
import { ChipButton } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { Loader } from '@verticeone/design-system';
import { getTextVariantStyle } from '@verticeone/design-system';

type PercentageChipProps = {
  isLoading: boolean;
  percentage?: number;
};

const PercentageChip = forwardRef<HTMLButtonElement, PercentageChipProps>(
  ({ isLoading, percentage, ...otherProps }, ref) => {
    const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.STATS' });
    const formatPercentage = useFormatPercentage();
    const formattedPercentage =
      percentage !== undefined
        ? formatPercentage(percentage, { maximumFractionDigits: 0, signDisplay: 'exceptZero' })
        : t('NA');

    return (
      <ChipButton variant="ghost" size="S" isActive={false} {...otherProps} ref={ref}>
        {isLoading && (
          <Stack position="absolute" left="0" right="0" top="0" bottom="0">
            <Loader
              className="loader-spinner"
              thickness={7}
              color="neutral"
              size={getTextVariantStyle({ variant: 'button-bold', size: 'S' }).fontSize}
            />
          </Stack>
        )}
        <Stack visibility={isLoading ? 'hidden' : 'visible'} width={isLoading ? '40px' : 'auto'}>
          {formattedPercentage}
        </Stack>
      </ChipButton>
    );
  }
);

export default PercentageChip;
