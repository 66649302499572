import React, { forwardRef } from 'react';
import { Timezone, timezones } from './timezones';
import { Select, SelectProps } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { SelectInstance } from 'react-select';

export type TimezoneSelectProps = Omit<SelectProps<Timezone, false>, 'loadOptions' | 'value' | 'onChange'> & {
  value: string | null;
  onChange: (value: string | null) => void;
};

export const TimezoneSelect = forwardRef(
  ({ value, onChange, ...otherProps }: TimezoneSelectProps, ref: React.Ref<SelectInstance<Timezone>>) => {
    const { t } = useTranslation(['timezones']);
    return (
      <Select
        ref={ref}
        options={timezones}
        isMulti={false}
        value={value ? timezones.find((timezone) => timezone.value === value) : null}
        onChange={(data) => onChange(data?.value || null)}
        getOptionLabel={(option) => `(${option.offsetText}) - ${t(`TIMEZONE.${option.value}`)}`}
        {...otherProps}
      />
    );
  }
);
