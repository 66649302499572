import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useCallback, useState } from 'react';
import {
  parseRequestRef,
  formatUserRef,
  parseUserTaskRef,
  isUserRef,
  parseUserRef,
} from '@vertice/core/src/hooks/workflows/refUtils';
import { useCreateRequestMutation } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useWaitForRequestReady } from './useWaitForRequestReady';
import { Vendor } from '@vertice/core/src/modules/vendor/types';
import { useLoggedUser } from '@verticeone/auth/src';
import { useWaitForRequestActiveTask } from './useWaitForRequestActiveTask';

type CreateRequestParams = {
  vendor?: Vendor;
};

export const useCreateNewPurchaseRequest = () => {
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [createdNewPurchaseRequestId, setCreatedNewPurchaseRequestId] = useState<string | undefined>(undefined);
  const [createdNewPurchaseRequestTaskId, setCreatedNewPurchaseRequestTaskId] = useState<string | undefined>(undefined);

  const [createRequestMutation] = useCreateRequestMutation();
  const waitForRequestReady = useWaitForRequestReady();
  const waitForRequestActiveTask = useWaitForRequestActiveTask();

  const createNewPurchaseRequest = useCallback(
    async ({ vendor }: CreateRequestParams): Promise<void> => {
      try {
        const requestInput = {
          vendor,
        };

        setIsRequestPending(true);

        const createRequestResponse = await createRequestMutation({
          accountId: accountId!,
          createRequest: {
            sourceRef: `urn:verticeone:vertice:${accountId}:cfa:page/vendor/${vendor ? vendor.id : ''}`,
            serviceRef: `urn:verticeone:vertice:${accountId}:services:service/saas/purchase/v4`,
            name: `${vendor ? `${vendor.name} ` : ''}New Purchase`,
            input: requestInput,
            owner: formatUserRef(userId),
          },
        });

        const requestReference = 'data' in createRequestResponse ? createRequestResponse.data?.ref : undefined;
        if (requestReference) {
          const requestId = parseRequestRef(requestReference).requestId;

          const waitForRequestAndTask = Promise.all([
            waitForRequestReady(accountId, requestId),
            waitForRequestActiveTask(accountId, requestId),
          ]);

          const wait20Seconds = new Promise<undefined>((resolve) => {
            setTimeout(resolve, 20000);
          });

          const result = await Promise.race([waitForRequestAndTask, wait20Seconds]);
          setCreatedNewPurchaseRequestId(requestId);
          if (result) {
            const [requestReady, task] = result;
            const taskAssignedToLoggedUser = task.assignment.some(
              (assignment) => isUserRef(assignment) && parseUserRef(assignment).userId === userId
            );
            if (requestReady && task.status === 'ACTIVE' && task.type === 'USER' && taskAssignedToLoggedUser) {
              setCreatedNewPurchaseRequestTaskId(parseUserTaskRef(task.ref).taskId);
            }
          }
        }
      } finally {
        setIsRequestPending(false);
      }
    },
    [createRequestMutation, accountId, userId, waitForRequestReady, waitForRequestActiveTask]
  );

  return {
    createNewPurchaseRequest,
    createdNewPurchaseRequestId,
    createdNewPurchaseRequestTaskId,
    isCreatingNewPurchaseRequest: isRequestPending,
  };
};
