import React from 'react';
import { MultiChipContainer } from '@verticeone/design-system';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { User } from '../types';

const UserReadOnlyCell = ({ value }: GridRenderCellParams) => {
  const normalizedValue: User[] | null = !value || Array.isArray(value) ? value : [value];

  return (
    <MultiChipContainer
      size="M"
      isActive={false}
      maxChips={3}
      chips={
        normalizedValue?.map((user: User) => {
          return {
            children: <span>{user.name}</span>,
            label: user.name,
            color: 'primary',
            variant: 'ghost',
            isActive: false,
          };
        }) ?? []
      }
    />
  );
};

export default UserReadOnlyCell;
