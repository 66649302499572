import { PropsWithChildren, useEffect } from 'react';
import { type NavigationTab, useBuySPDrawerTabs } from './BuySPDrawerTabs';
import { useShoppingCart } from '../providers/ShoppingCartProvider';

type BuySPDrawerShoppingTableProps = PropsWithChildren & {
  tabId: NavigationTab;
  filterByShoppingCart?: boolean;
};

const BuySPDrawerTab = ({ tabId, filterByShoppingCart, children }: BuySPDrawerShoppingTableProps) => {
  const { getIsVisible } = useBuySPDrawerTabs();
  const { setFilterByShoppingCart } = useShoppingCart();

  useEffect(() => {
    if (getIsVisible(tabId)) {
      setFilterByShoppingCart(!!filterByShoppingCart);
    }
  }, [getIsVisible, tabId, setFilterByShoppingCart, filterByShoppingCart]);

  if (!getIsVisible(tabId)) {
    return null;
  }

  return children;
};

export default BuySPDrawerTab;
