export const JIRA_PROVIDER_ID = 'Provider:Jira';

export const EDIT_MODE_DISABLED = { connection: false, project: false };

export const FORM_FIELD_WIDTH = 520;

export const DEFAULT_POLLING_INTERVAL = 2000;

export const JIRA_INTEGRATION_GUIDE_URL =
  'https://support.vertice.one/hc/en-us/articles/30461309531025-Jira-Integration-Set-up-guide';
