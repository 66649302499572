import React from 'react';

import GenericTable, {
  IntFormatter,
} from '../../../../pages/Cloud/CloudOptimization/Recommendation/components/GenericTable';
import GenericCard from '../../../../pages/Cloud/CloudOptimization/Recommendation/components/GenericCard';

const ElasticacheBackupCostsCard = () => {
  return (
    <GenericCard translationKey={'CLOUD.CLOUD_RECOMMENDATIONS.RECOMMENDATION.VR-121.TABLE'} show={['title']}>
      <GenericTable
        code="VT-121"
        testId="elasticache-backup-fees-table"
        translationKey="CLOUD.CLOUD_RECOMMENDATIONS.RECOMMENDATION.VR-121.TABLE"
        columns={['region', 'account_id', 'sum_cost'] as const}
        getRowId={(row) => `${row.region}-${row.account_id}`}
        defaultSortColumns={{ field: 'sum_cost', sort: 'desc' }}
        renderCell={{
          sum_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
        }}
      />
    </GenericCard>
  );
};

export default ElasticacheBackupCostsCard;
