import React from 'react';
import { Stack } from '@mui/material';
import DataSourceProvider from '../../../DataSourceProvider';
import {
  Recommendation,
  DataLoaderComponent,
  PurchaseSPButton,
  TotalCostInfo,
  MissingSPBanner,
  MissingRIBanner,
} from '../../components';
import { BuySPDrawerLayout } from '../../components/BuySPDrawer';
import Summary from '../../widgets/Overview/product/Compute/Summary';
import Graph from './Graph';

const Compute = () => (
  <Stack p={6} gap={4}>
    <DataSourceProvider datasource="Compute">
      <DataLoaderComponent>
        <MissingRIBanner />
        <MissingSPBanner />
        <Summary />
        <Graph />
        <TotalCostInfo />
        <Recommendation />
        <PurchaseSPButton />
        <BuySPDrawerLayout graph={Graph} />
      </DataLoaderComponent>
    </DataSourceProvider>
  </Stack>
);

export default Compute;
