import React from 'react';
import { useController } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useSimpleDialogContext } from '@verticeone/design-system';
import { Checkbox } from '@verticeone/design-system';
import { FormControlLabel } from '@verticeone/design-system';

export type AutoRenewalWaivedCheckboxFieldProps = React.ComponentProps<typeof Checkbox> & {
  name: string;
  required?: boolean;
};

export const AutoRenewalWaivedCheckboxField = ({
  required,
  name,
  ...otherProps
}: AutoRenewalWaivedCheckboxFieldProps) => {
  const { t } = useTranslation();
  const { getConfirmation } = useSimpleDialogContext();
  const {
    field: { ref, value, onChange, ...field },
  } = useController({ name, rules: { required } });

  return (
    <FormControlLabel
      checked={value ?? false}
      size="XXS"
      control={
        <Checkbox
          inputRef={ref}
          size="XXS"
          {...field}
          onChange={async (event) => {
            const newValue = event.target.checked;
            if (newValue) {
              // When checkbox is checked we need to get confirmation first
              const confirmed = await getConfirmation({
                title: t('ENTITIES.CONTRACT.DIALOGS.AUTO_RENEWAL_WAIVED.TITLE'),
                description: t('ENTITIES.CONTRACT.DIALOGS.AUTO_RENEWAL_WAIVED.TEXT'),
              });
              onChange(confirmed.status === 'ok');
            } else {
              onChange(newValue);
            }
          }}
          {...otherProps}
        />
      }
      label={
        <Stack direction="column">
          <Text variant="caption" size="XS">
            {t('ENTITIES.CONTRACT.LABELS.AUTO_RENEWAL_DATE_WAIVED')}
          </Text>
        </Stack>
      }
    />
  );
};
