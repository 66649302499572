import { FC } from 'react';
import { NodeProps, Node } from '@xyflow/react';
import { NodeControls } from './NodeControls';
import { WorkflowLabelNode } from '../../model/types';
import { styled } from '@mui/material';
import { ChipButton } from '@verticeone/design-system';
import { LABEL_NODE_HEIGHT } from '../modelToRendererGraph';

type StyledLabelChipProps = {
  $width: number;
  $passed?: boolean;
};

const StyledLabelChip = styled(ChipButton)<StyledLabelChipProps>(({ $width, theme: { palette }, $passed }) => ({
  display: 'block',
  width: $width,
  backgroundColor: palette.core.color1,
  border: `1px solid ${$passed ? palette.success.color2 : palette.core.color5}`,
  textAlign: 'center',
  height: LABEL_NODE_HEIGHT,
}));

export const LabelNodeComponent: FC<NodeProps<Node<WorkflowLabelNode>>> = (props) => {
  const { data } = props;

  const isPassedEdge = data?.state?.passed;

  return (
    <NodeControls
      {...props}
      content={
        <StyledLabelChip
          color={isPassedEdge ? 'success' : 'neutral'}
          isActive={false}
          $passed={isPassedEdge}
          $width={data.width}
          size="M"
        >
          {data.name}
        </StyledLabelChip>
      }
    />
  );
};
