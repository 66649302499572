import { Button, Dialog, DialogActions, DialogContent, DialogHeader, Divider } from '@verticeone/design-system';
import { ComponentType, FormEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDynamicFormBuilderContext } from '../../DynamicFormBuilderContextProvider';
import { getFieldTypeText } from '../../../fields/utils';
import { Stack } from '@mui/material';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { FieldDialogSubmitButton } from './FieldDialogSubmitButton';
import { FieldDialogFormEntry } from './FieldDialogFormEntry';
import { FormToggleSwitchField } from '../../../../fields/FormToggleSwitchField';
import { fieldDefsByType } from '../../../fields/fields';

export const FieldDialog = () => {
  const { t } = useTranslation();
  const { color, dialogState, setDialogState, customFormBuilder } = useDynamicFormBuilderContext();
  const defaultValues = dialogState?.defaultValues ?? {};

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      metadata: {
        label: defaultValues?.metadata?.label ?? '',
        description: defaultValues?.metadata?.description ?? '',
        required: defaultValues?.metadata?.required ?? false,
      },
    },
  });

  const dialogTitlePrefix = useMemo(
    () =>
      dialogState?.mode === 'create'
        ? t('DYNAMIC_FORM_BUILDER.DIALOG.TITLE_ADD_FIELD')
        : t('DYNAMIC_FORM_BUILDER.DIALOG.TITLE_UPDATE_FIELD'),
    [t, dialogState?.mode]
  );

  const dialogTitleType = useMemo(
    () => (dialogState ? getFieldTypeText(t, dialogState.fieldType) : ''),
    [t, dialogState]
  );

  const FormComponentForFieldType = useMemo((): ComponentType | null => {
    if (!dialogState?.fieldType) {
      return null;
    }
    return fieldDefsByType[dialogState.fieldType]?.builderForm ?? null;
  }, [dialogState?.fieldType]);

  const onCloseClick = () => {
    setDialogState(undefined);
  };

  const handleSubmit = (data: FieldValues) => {
    dialogState?.onSave?.(data);
    setDialogState(undefined);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    void formMethods.handleSubmit(handleSubmit)(event);
    event.stopPropagation();
  };

  const formContent = useMemo(
    () =>
      FormComponentForFieldType
        ? customFormBuilder({ fieldType: dialogState?.fieldType!, children: <FormComponentForFieldType /> })
        : null,
    [FormComponentForFieldType, customFormBuilder, dialogState?.fieldType]
  );

  if (!dialogState) {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <Dialog open={true} size="M" width={660}>
        <form id="field-dialog-form" onSubmit={onSubmit}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <DialogHeader>{[dialogTitlePrefix, dialogTitleType].join(': ')}</DialogHeader>
            <FieldDialogFormEntry
              name="metadata.required"
              component={FormToggleSwitchField}
              componentProps={{
                label: t('DYNAMIC_FORM_BUILDER.REQUIRED_FIELD'),
                color: color,
              }}
            />
          </Stack>
          <Divider />
          <DialogContent>{formContent}</DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="outline" onClick={onCloseClick} color={color}>
              {t('DIALOG.BUTTONS.CLOSE')}
            </Button>
            <FieldDialogSubmitButton />
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
};
