import { useGetUserServiceAliasQuery, useRevokeUserAuthorizationMutation } from '@vertice/slices';
import { useLoggedUser } from '@verticeone/auth/src';
import React, { useMemo, useState } from 'react';
import { ChipButton } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { useNavigate } from 'react-router-dom';
import { PREFERENCES_PAGES, ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '@vertice/components/src/ConfirmationDialog/ConfirmationDialog';

import { Card, CardHeader, CardHeaderActions, CardHeaderDescription, CardHeaderTitle } from '@verticeone/design-system';
import { Box } from '@mui/material';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

export const SlackIntegrationPanel: React.FC = () => {
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disconnectConfirmationOpen, setDisconnectConfirmationOpen] = useState(false);

  const {
    data: userAliasData,
    refetch: refetchUserAlias,
    isFetching: isUserAliasFetching,
  } = useGetUserServiceAliasQuery(
    {
      accountId: accountId!,
      userId,
      serviceId: 'slack',
    },
    { skip: !accountId }
  );
  const [revoke, { isLoading: isRevokeLoading }] = useRevokeUserAuthorizationMutation();

  const connected: boolean = useMemo(() => Boolean(userAliasData?.alias), [userAliasData]);

  const goToWizard = () => {
    navigate(`${ROUTES.PREFERENCES}/${PREFERENCES_PAGES.USER_SLACK_WIZARD}`);
  };

  const disconnect = () => {
    setDisconnectConfirmationOpen(false);
    void revoke({ accountId: accountId!, userId, body: {} }).then(() => {
      void refetchUserAlias();
    });
  };

  return (
    <Card>
      <CardHeader size="S" noDivider>
        <CardHeaderTitle
          text={t('PREFERENCES.SLACK_NOTIFICATIONS.TITLE')}
          after={
            connected ? (
              <ChipButton size="S" variant="ghost" color="success" isActive={false}>
                {t('PREFERENCES.INTEGRATIONS.STATUS.CONNECTED')}
              </ChipButton>
            ) : (
              <ChipButton size="S" variant="ghost" color="tertiary" isActive={false}>
                {t('PREFERENCES.INTEGRATIONS.STATUS.NEW')}
              </ChipButton>
            )
          }
        />
        <CardHeaderDescription>
          <Box pr="10em" /* <- Extra padding to not interfere with the action button */>
            {t('PREFERENCES.SLACK_NOTIFICATIONS.DESCRIPTION')}
          </Box>
        </CardHeaderDescription>
        <CardHeaderActions>
          {connected ? (
            <Button
              variant="solid"
              color="neutral"
              size="S"
              onClick={() => setDisconnectConfirmationOpen(true)}
              isLoading={isRevokeLoading || isUserAliasFetching}
            >
              {t('PREFERENCES.INTEGRATIONS.DISCONNECT')}
            </Button>
          ) : (
            <Button variant="solid" color="primary" size="S" onClick={goToWizard}>
              {t('PREFERENCES.INTEGRATIONS.CONNECT')}
            </Button>
          )}
        </CardHeaderActions>
      </CardHeader>
      <ConfirmationDialog
        isOpen={disconnectConfirmationOpen}
        bodyPrimaryText={t('PREFERENCES.SLACK_NOTIFICATIONS.DISCONNECT_CONFIRMATION.TITLE')}
        bodySecondaryText={t('PREFERENCES.SLACK_NOTIFICATIONS.DISCONNECT_CONFIRMATION.DESCRIPTION')}
        secondaryButtonText={t('DIALOG.BUTTONS.CANCEL')}
        secondaryButtonAction={() => setDisconnectConfirmationOpen(false)}
        primaryButtonText={t('PREFERENCES.INTEGRATIONS.DISCONNECT')}
        primaryButtonAction={disconnect}
      />
    </Card>
  );
};
