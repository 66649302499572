import { Property, PropertyType, ServiceUrn } from '../WorkflowEditor/types';
import {
  ServiceCatalogResource,
  ServiceCatalogResourceDefinition,
} from '@vertice/slices/src/openapi/codegen/servicesAPI';

export type Schema = {
  properties: Property[];
};

export type Schemas = {
  input: Schema;
  output: Schema;
};

export type JsonSchema = {
  additionalProperties?: boolean;
  properties?: Record<string, JsonSchema>;
  required?: string[];
  type?: PropertyType | PropertyType[];
  'x-type'?: string;
  items?: JsonSchema;
  format?: string;
  enum?: string[];
  title?: string;
};

export type ServiceSchema = Record<ServiceUrn, Schemas>;

export type ServicesSchema = {
  account: ServiceSchema;
  global: ServiceSchema;
};

export type XTypeOption = {
  id: string;
  title: string;
};

export type XTypeObjectOption = {
  id: Record<string, string>;
  title: string;
};

export type XTypeItems = XTypeOption[] | XTypeObjectOption[];

export type XTypeServiceCatalogResourceDefinition = ServiceCatalogResourceDefinition & {
  Operators: {
    Allowed: string[];
  };
  Description: string;
  Name: string;
  Schema: {
    JsonSchema: JsonSchema;
  };
  Datasource?: {
    Provided?: boolean;
    Items?: XTypeItems;
  };
};

export type XTypeServiceCatalogResource = ServiceCatalogResource & {
  kind: 'Vertice/ServiceCatalog/XType/XTypeDefinition';
  definition: XTypeServiceCatalogResourceDefinition;
};

export const isXTypeOptionItems = (items: XTypeItems): items is XTypeOption[] => {
  return items.every((item) => typeof item.id === 'string');
};
