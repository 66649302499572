/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query EDPEligibleSpend($accountId: String!, $dateRangeFrom: String!) {\n    athenaViewQuery(\n      params: {\n        accountId: $accountId\n        name: "cco_view_edp_eligibility_v1"\n        parameters: ["{accountId}", $dateRangeFrom]\n      }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(\n          columns: [\n            "aws_infra_spend"\n            "aws_marketplace_spend"\n            "eligible_marketplace_spend"\n            "non_eligible_marketplace_spend"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n':
    types.EdpEligibleSpendDocument,
  '\n  query EDPSpendBreakdown($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_commitment_insight_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(\n          columns: [\n            "updated_at"\n            "aws_infra_spend"\n            "aws_marketplace_spend"\n            "eligible_marketplace_spend"\n            "non_eligible_marketplace_spend"\n            "commitment"\n            "total_edp_eligible_spend"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n':
    types.EdpSpendBreakdownDocument,
  '\n  query EDPConfiguration($accountId: String!, $startDate: String!) {\n    listEDPContracts(params: { accountId: $accountId, contractStatus: ACTIVE }) {\n      __typename\n      ... on EDPContractListing {\n        items {\n          ... on EDPContractFacade {\n            costModelContainer {\n              ... on CostModelContainer {\n                costModel {\n                  endDate\n                  costAllocationSpans {\n                    startDate\n                    endDate\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_eligibility_v1", parameters: ["{accountId}", $startDate] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(columns: ["aws_infra_spend", "eligible_marketplace_spend"]) {\n          data\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n':
    types.EdpConfigurationDocument,
  '\n  query EDPTotalEligibleSpend($accountId: String!) {\n    commitment: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_commitment_insight_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(columns: ["aws_infra_spend", "eligible_marketplace_spend", "commitment", "total_edp_eligible_spend"]) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    spend: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(columns: ["edp_month_start", "edp_discount_cumm_spend", "is_forecast"]) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n':
    types.EdpTotalEligibleSpendDocument,
  '\n  mutation EDPContractCreate($accountId: ID!, $costModel: CostModelInput!) {\n    createContract(params: { accountId: $accountId, costModel: $costModel }) {\n      ... on EDPContract {\n        __typename\n        costModel {\n          startDate\n          endDate\n          costAllocationSpans {\n            startDate\n            endDate\n            commitment\n            discount\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n      ... on EDPValidationError {\n        __typename\n        context\n        code\n        fieldName\n      }\n    }\n  }\n':
    types.EdpContractCreateDocument,
  '\n  mutation EDPContractDelete($accountId: ID!, $identifier: ID!) {\n    deleteContract(params: { accountId: $accountId, identifier: $identifier }) {\n      ... on EDPContractMemento {\n        identifier\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n':
    types.EdpContractDeleteDocument,
  '\n  query EDPContractListing($accountId: String!, $contractStatus: ContractStatus) {\n    listEDPContracts(params: { accountId: $accountId, contractStatus: $contractStatus }) {\n      ... on EDPContractListing {\n        __typename\n        items {\n          contractId\n          costModelContainer {\n            id\n            costModel {\n              startDate\n              endDate\n              costAllocationSpans {\n                startDate\n                endDate\n                name\n                commitment\n                discount\n              }\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n':
    types.EdpContractListingDocument,
  '\n  mutation EDPContractUpdate($accountId: ID!, $identifier: ID!, $costModel: CostModelInput!) {\n    updateContract(params: { accountId: $accountId, identifier: $identifier, costModel: $costModel }) {\n      ... on EDPContract {\n        __typename\n        identifier\n        costModel {\n          startDate\n          endDate\n          costAllocationSpans {\n            startDate\n            endDate\n            commitment\n            discount\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n      ... on EDPValidationError {\n        __typename\n        context\n        code\n        fieldName\n      }\n    }\n  }\n':
    types.EdpContractUpdateDocument,
  '\n  query EDPSavingsQuery($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(\n          columns: [\n            "edp_month_start"\n            "total_edp_discount"\n            "edp_discount_cumm_spend"\n            "zero_based_edp_discount_cumm_spend"\n            "is_forecast"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n':
    types.EdpSavingsQueryDocument,
  '\n  query EDPCumulativeSpend($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(\n          columns: [\n            "edp_month_start"\n            "spend_current_month"\n            "forecasted_spend"\n            "cumulative_month_spend"\n            "cumulative_forecasted_spend"\n            "zero_based_cumulative_month_spend"\n            "is_forecast"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n':
    types.EdpCumulativeSpendDocument,
  '\n  query SpendVsCommitmentChart($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(\n          columns: [\n            "edp_month_start"\n            "forecasted_spend"\n            "aws_infra_spend"\n            "eligible_marketplace_spend"\n            "ineligible_marketplace_spend"\n            "cumulative_month_spend"\n            "cumulative_forecasted_spend"\n            "is_forecast"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n':
    types.SpendVsCommitmentChartDocument,
  '\n  mutation UpdateRecommendations($accountId: String!, $recommendations: [RecommendationSettings]) {\n    updateSettings(params: { accountId: $accountId, recommendations: $recommendations }) {\n      ... on PutEventDetails {\n        entries {\n          ... on EventDetails {\n            eventId\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n':
    types.UpdateRecommendationsDocument,
  '\n  query OptimizationsList($accountId: String!) {\n    optimizationsQuery(params: { accountId: $accountId }) {\n      ... on OptimizationsResult {\n        __typename\n        summary {\n          recsBreaching\n          recsCancelled\n          recsNonBreaching\n          recsTotal\n          estimatedSavings {\n            category\n            count\n            saving\n          }\n        }\n        recs {\n          breaching\n          categories\n          isObfuscated\n          code\n          effort\n          products\n          proposedCost\n          resourceCost\n          saving\n          status\n          tags\n          tests {\n            code\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n':
    types.OptimizationsListDocument,
  '\n  query TestResults($accountId: String!, $code: String!) {\n    monitoringLatestQuery(params: { accountId: $accountId, code: $code, monitoringType: TEST }) {\n      ... on MonitoringResult {\n        __typename\n        items {\n          results {\n            ... on ErroredQueryResult {\n              __typename\n            }\n            ... on MulticlassBinnedTestResult {\n              __typename\n              summary {\n                ...TestResultSummaryFragment\n              }\n            }\n            ... on BooleanTestResult {\n              __typename\n              summary {\n                ...TestResultSummaryFragment\n              }\n            }\n            ... on BinnedTestResult {\n              __typename\n              summary {\n                ...TestResultSummaryFragment\n              }\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n      }\n    }\n  }\n\n  fragment TestResultSummaryFragment on TestResultSummary {\n    tooltip {\n      data\n      types\n    }\n    type\n    value\n  }\n':
    types.TestResultsDocument,
  '\n  mutation SPPurchase($accountId: String!, $purchases: [SPPurchaseSavingsPlan!]!) {\n    buySavingsPlan(params: { accountId: $accountId, purchases: $purchases }) {\n      ... on SavingsPlanPurchaseResult {\n        __typename\n        items {\n          offeringId\n          status\n          purchaseOrderId\n          purchaseExecutionTime\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n':
    types.SpPurchaseDocument,
  '\n  mutation CancelSPReservation($accountId: String!, $deletions: [SPDeleteSavingsPlan!]!) {\n    deleteQueuedSavingsPlan(params: { accountId: $accountId, deletions: $deletions }) {\n      ... on SavingsPlanDeleteResult {\n        __typename\n        items {\n          purchaseOrderId\n          status\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n':
    types.CancelSpReservationDocument,
  '\n  query MonitoringLatestQuery($accountId: String!, $checkCode: String!) {\n    monitoringLatestQuery(params: { accountId: $accountId, monitoringType: CHECK, code: $checkCode }) {\n      __typename\n      ... on MonitoringResult {\n        items {\n          code\n          results {\n            __typename\n            ... on MonitoringValueCheckResult {\n              columns\n              data\n              dataTypes\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n':
    types.MonitoringLatestQueryDocument,
  '\n  query SPPermissionCheck($accountId: String!, $action: SPRIAction!) {\n    spPurchasePermissionQuery(params: { accountId: $accountId, action: $action }) {\n      __typename\n      ... on SPPurchasePermissionResult {\n        action\n        allowed\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n':
    types.SpPermissionCheckDocument,
  '\n  query ComputeSPRIODaily($accountId: String!) {\n    summaryQuery: monitoringLatestQuery(\n      params: { accountId: $accountId, monitoringType: CHECK, code: "COMPUTE_SP_COVERAGE_LAST_DAYS" }\n    ) {\n      __typename\n      ... on MonitoringResult {\n        items {\n          code\n          results {\n            __typename\n            ... on MonitoringValueCheckResult {\n              columns\n              data\n              dataTypes\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    dailyUsageQuery: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_sprio_compute_daily_usage_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(\n          columns: [\n            "timestamp"\n            "ec2_covered_cost"\n            "ec2_on_demand_cost"\n            "lambda_sp_covered_cost"\n            "lambda_on_demand_cost"\n            "fargate_sp_covered_cost"\n            "fargate_on_demand_cost"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n    }\n  }\n':
    types.ComputeSprioDailyDocument,
  '\n  query SageMakerSPRIODaily($accountId: String!) {\n    summaryQuery: monitoringLatestQuery(\n      params: { accountId: $accountId, monitoringType: CHECK, code: "SAGE_MAKER_COVERAGE_LAST_DAYS" }\n    ) {\n      __typename\n      ... on MonitoringResult {\n        items {\n          code\n          results {\n            __typename\n            ... on MonitoringValueCheckResult {\n              columns\n              data\n              dataTypes\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    dailyUsageQuery: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_sprio_sage_maker_daily_usage_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(columns: ["timestamp", "sp_covered_cost", "on_demand_cost"]) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n    }\n  }\n':
    types.SageMakerSprioDailyDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EDPEligibleSpend($accountId: String!, $dateRangeFrom: String!) {\n    athenaViewQuery(\n      params: {\n        accountId: $accountId\n        name: "cco_view_edp_eligibility_v1"\n        parameters: ["{accountId}", $dateRangeFrom]\n      }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(\n          columns: [\n            "aws_infra_spend"\n            "aws_marketplace_spend"\n            "eligible_marketplace_spend"\n            "non_eligible_marketplace_spend"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query EDPEligibleSpend($accountId: String!, $dateRangeFrom: String!) {\n    athenaViewQuery(\n      params: {\n        accountId: $accountId\n        name: "cco_view_edp_eligibility_v1"\n        parameters: ["{accountId}", $dateRangeFrom]\n      }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(\n          columns: [\n            "aws_infra_spend"\n            "aws_marketplace_spend"\n            "eligible_marketplace_spend"\n            "non_eligible_marketplace_spend"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EDPSpendBreakdown($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_commitment_insight_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(\n          columns: [\n            "updated_at"\n            "aws_infra_spend"\n            "aws_marketplace_spend"\n            "eligible_marketplace_spend"\n            "non_eligible_marketplace_spend"\n            "commitment"\n            "total_edp_eligible_spend"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query EDPSpendBreakdown($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_commitment_insight_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(\n          columns: [\n            "updated_at"\n            "aws_infra_spend"\n            "aws_marketplace_spend"\n            "eligible_marketplace_spend"\n            "non_eligible_marketplace_spend"\n            "commitment"\n            "total_edp_eligible_spend"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EDPConfiguration($accountId: String!, $startDate: String!) {\n    listEDPContracts(params: { accountId: $accountId, contractStatus: ACTIVE }) {\n      __typename\n      ... on EDPContractListing {\n        items {\n          ... on EDPContractFacade {\n            costModelContainer {\n              ... on CostModelContainer {\n                costModel {\n                  endDate\n                  costAllocationSpans {\n                    startDate\n                    endDate\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_eligibility_v1", parameters: ["{accountId}", $startDate] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(columns: ["aws_infra_spend", "eligible_marketplace_spend"]) {\n          data\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query EDPConfiguration($accountId: String!, $startDate: String!) {\n    listEDPContracts(params: { accountId: $accountId, contractStatus: ACTIVE }) {\n      __typename\n      ... on EDPContractListing {\n        items {\n          ... on EDPContractFacade {\n            costModelContainer {\n              ... on CostModelContainer {\n                costModel {\n                  endDate\n                  costAllocationSpans {\n                    startDate\n                    endDate\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_eligibility_v1", parameters: ["{accountId}", $startDate] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(columns: ["aws_infra_spend", "eligible_marketplace_spend"]) {\n          data\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EDPTotalEligibleSpend($accountId: String!) {\n    commitment: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_commitment_insight_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(columns: ["aws_infra_spend", "eligible_marketplace_spend", "commitment", "total_edp_eligible_spend"]) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    spend: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(columns: ["edp_month_start", "edp_discount_cumm_spend", "is_forecast"]) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query EDPTotalEligibleSpend($accountId: String!) {\n    commitment: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_commitment_insight_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(columns: ["aws_infra_spend", "eligible_marketplace_spend", "commitment", "total_edp_eligible_spend"]) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    spend: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(columns: ["edp_month_start", "edp_discount_cumm_spend", "is_forecast"]) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation EDPContractCreate($accountId: ID!, $costModel: CostModelInput!) {\n    createContract(params: { accountId: $accountId, costModel: $costModel }) {\n      ... on EDPContract {\n        __typename\n        costModel {\n          startDate\n          endDate\n          costAllocationSpans {\n            startDate\n            endDate\n            commitment\n            discount\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n      ... on EDPValidationError {\n        __typename\n        context\n        code\n        fieldName\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation EDPContractCreate($accountId: ID!, $costModel: CostModelInput!) {\n    createContract(params: { accountId: $accountId, costModel: $costModel }) {\n      ... on EDPContract {\n        __typename\n        costModel {\n          startDate\n          endDate\n          costAllocationSpans {\n            startDate\n            endDate\n            commitment\n            discount\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n      ... on EDPValidationError {\n        __typename\n        context\n        code\n        fieldName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation EDPContractDelete($accountId: ID!, $identifier: ID!) {\n    deleteContract(params: { accountId: $accountId, identifier: $identifier }) {\n      ... on EDPContractMemento {\n        identifier\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation EDPContractDelete($accountId: ID!, $identifier: ID!) {\n    deleteContract(params: { accountId: $accountId, identifier: $identifier }) {\n      ... on EDPContractMemento {\n        identifier\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EDPContractListing($accountId: String!, $contractStatus: ContractStatus) {\n    listEDPContracts(params: { accountId: $accountId, contractStatus: $contractStatus }) {\n      ... on EDPContractListing {\n        __typename\n        items {\n          contractId\n          costModelContainer {\n            id\n            costModel {\n              startDate\n              endDate\n              costAllocationSpans {\n                startDate\n                endDate\n                name\n                commitment\n                discount\n              }\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query EDPContractListing($accountId: String!, $contractStatus: ContractStatus) {\n    listEDPContracts(params: { accountId: $accountId, contractStatus: $contractStatus }) {\n      ... on EDPContractListing {\n        __typename\n        items {\n          contractId\n          costModelContainer {\n            id\n            costModel {\n              startDate\n              endDate\n              costAllocationSpans {\n                startDate\n                endDate\n                name\n                commitment\n                discount\n              }\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation EDPContractUpdate($accountId: ID!, $identifier: ID!, $costModel: CostModelInput!) {\n    updateContract(params: { accountId: $accountId, identifier: $identifier, costModel: $costModel }) {\n      ... on EDPContract {\n        __typename\n        identifier\n        costModel {\n          startDate\n          endDate\n          costAllocationSpans {\n            startDate\n            endDate\n            commitment\n            discount\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n      ... on EDPValidationError {\n        __typename\n        context\n        code\n        fieldName\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation EDPContractUpdate($accountId: ID!, $identifier: ID!, $costModel: CostModelInput!) {\n    updateContract(params: { accountId: $accountId, identifier: $identifier, costModel: $costModel }) {\n      ... on EDPContract {\n        __typename\n        identifier\n        costModel {\n          startDate\n          endDate\n          costAllocationSpans {\n            startDate\n            endDate\n            commitment\n            discount\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n      ... on EDPValidationError {\n        __typename\n        context\n        code\n        fieldName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EDPSavingsQuery($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(\n          columns: [\n            "edp_month_start"\n            "total_edp_discount"\n            "edp_discount_cumm_spend"\n            "zero_based_edp_discount_cumm_spend"\n            "is_forecast"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query EDPSavingsQuery($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(\n          columns: [\n            "edp_month_start"\n            "total_edp_discount"\n            "edp_discount_cumm_spend"\n            "zero_based_edp_discount_cumm_spend"\n            "is_forecast"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query EDPCumulativeSpend($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(\n          columns: [\n            "edp_month_start"\n            "spend_current_month"\n            "forecasted_spend"\n            "cumulative_month_spend"\n            "cumulative_forecasted_spend"\n            "zero_based_cumulative_month_spend"\n            "is_forecast"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query EDPCumulativeSpend($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(\n          columns: [\n            "edp_month_start"\n            "spend_current_month"\n            "forecasted_spend"\n            "cumulative_month_spend"\n            "cumulative_forecasted_spend"\n            "zero_based_cumulative_month_spend"\n            "is_forecast"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SpendVsCommitmentChart($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(\n          columns: [\n            "edp_month_start"\n            "forecasted_spend"\n            "aws_infra_spend"\n            "eligible_marketplace_spend"\n            "ineligible_marketplace_spend"\n            "cumulative_month_spend"\n            "cumulative_forecasted_spend"\n            "is_forecast"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query SpendVsCommitmentChart($accountId: String!) {\n    athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }\n    ) {\n      ... on DataTableResult {\n        __typename\n        table(\n          columns: [\n            "edp_month_start"\n            "forecasted_spend"\n            "aws_infra_spend"\n            "eligible_marketplace_spend"\n            "ineligible_marketplace_spend"\n            "cumulative_month_spend"\n            "cumulative_forecasted_spend"\n            "is_forecast"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateRecommendations($accountId: String!, $recommendations: [RecommendationSettings]) {\n    updateSettings(params: { accountId: $accountId, recommendations: $recommendations }) {\n      ... on PutEventDetails {\n        entries {\n          ... on EventDetails {\n            eventId\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation UpdateRecommendations($accountId: String!, $recommendations: [RecommendationSettings]) {\n    updateSettings(params: { accountId: $accountId, recommendations: $recommendations }) {\n      ... on PutEventDetails {\n        entries {\n          ... on EventDetails {\n            eventId\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query OptimizationsList($accountId: String!) {\n    optimizationsQuery(params: { accountId: $accountId }) {\n      ... on OptimizationsResult {\n        __typename\n        summary {\n          recsBreaching\n          recsCancelled\n          recsNonBreaching\n          recsTotal\n          estimatedSavings {\n            category\n            count\n            saving\n          }\n        }\n        recs {\n          breaching\n          categories\n          isObfuscated\n          code\n          effort\n          products\n          proposedCost\n          resourceCost\n          saving\n          status\n          tags\n          tests {\n            code\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query OptimizationsList($accountId: String!) {\n    optimizationsQuery(params: { accountId: $accountId }) {\n      ... on OptimizationsResult {\n        __typename\n        summary {\n          recsBreaching\n          recsCancelled\n          recsNonBreaching\n          recsTotal\n          estimatedSavings {\n            category\n            count\n            saving\n          }\n        }\n        recs {\n          breaching\n          categories\n          isObfuscated\n          code\n          effort\n          products\n          proposedCost\n          resourceCost\n          saving\n          status\n          tags\n          tests {\n            code\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TestResults($accountId: String!, $code: String!) {\n    monitoringLatestQuery(params: { accountId: $accountId, code: $code, monitoringType: TEST }) {\n      ... on MonitoringResult {\n        __typename\n        items {\n          results {\n            ... on ErroredQueryResult {\n              __typename\n            }\n            ... on MulticlassBinnedTestResult {\n              __typename\n              summary {\n                ...TestResultSummaryFragment\n              }\n            }\n            ... on BooleanTestResult {\n              __typename\n              summary {\n                ...TestResultSummaryFragment\n              }\n            }\n            ... on BinnedTestResult {\n              __typename\n              summary {\n                ...TestResultSummaryFragment\n              }\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n      }\n    }\n  }\n\n  fragment TestResultSummaryFragment on TestResultSummary {\n    tooltip {\n      data\n      types\n    }\n    type\n    value\n  }\n'
): (typeof documents)['\n  query TestResults($accountId: String!, $code: String!) {\n    monitoringLatestQuery(params: { accountId: $accountId, code: $code, monitoringType: TEST }) {\n      ... on MonitoringResult {\n        __typename\n        items {\n          results {\n            ... on ErroredQueryResult {\n              __typename\n            }\n            ... on MulticlassBinnedTestResult {\n              __typename\n              summary {\n                ...TestResultSummaryFragment\n              }\n            }\n            ... on BooleanTestResult {\n              __typename\n              summary {\n                ...TestResultSummaryFragment\n              }\n            }\n            ... on BinnedTestResult {\n              __typename\n              summary {\n                ...TestResultSummaryFragment\n              }\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n      }\n    }\n  }\n\n  fragment TestResultSummaryFragment on TestResultSummary {\n    tooltip {\n      data\n      types\n    }\n    type\n    value\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SPPurchase($accountId: String!, $purchases: [SPPurchaseSavingsPlan!]!) {\n    buySavingsPlan(params: { accountId: $accountId, purchases: $purchases }) {\n      ... on SavingsPlanPurchaseResult {\n        __typename\n        items {\n          offeringId\n          status\n          purchaseOrderId\n          purchaseExecutionTime\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation SPPurchase($accountId: String!, $purchases: [SPPurchaseSavingsPlan!]!) {\n    buySavingsPlan(params: { accountId: $accountId, purchases: $purchases }) {\n      ... on SavingsPlanPurchaseResult {\n        __typename\n        items {\n          offeringId\n          status\n          purchaseOrderId\n          purchaseExecutionTime\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CancelSPReservation($accountId: String!, $deletions: [SPDeleteSavingsPlan!]!) {\n    deleteQueuedSavingsPlan(params: { accountId: $accountId, deletions: $deletions }) {\n      ... on SavingsPlanDeleteResult {\n        __typename\n        items {\n          purchaseOrderId\n          status\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation CancelSPReservation($accountId: String!, $deletions: [SPDeleteSavingsPlan!]!) {\n    deleteQueuedSavingsPlan(params: { accountId: $accountId, deletions: $deletions }) {\n      ... on SavingsPlanDeleteResult {\n        __typename\n        items {\n          purchaseOrderId\n          status\n        }\n      }\n      ... on ErroredQueryResult {\n        __typename\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query MonitoringLatestQuery($accountId: String!, $checkCode: String!) {\n    monitoringLatestQuery(params: { accountId: $accountId, monitoringType: CHECK, code: $checkCode }) {\n      __typename\n      ... on MonitoringResult {\n        items {\n          code\n          results {\n            __typename\n            ... on MonitoringValueCheckResult {\n              columns\n              data\n              dataTypes\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query MonitoringLatestQuery($accountId: String!, $checkCode: String!) {\n    monitoringLatestQuery(params: { accountId: $accountId, monitoringType: CHECK, code: $checkCode }) {\n      __typename\n      ... on MonitoringResult {\n        items {\n          code\n          results {\n            __typename\n            ... on MonitoringValueCheckResult {\n              columns\n              data\n              dataTypes\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SPPermissionCheck($accountId: String!, $action: SPRIAction!) {\n    spPurchasePermissionQuery(params: { accountId: $accountId, action: $action }) {\n      __typename\n      ... on SPPurchasePermissionResult {\n        action\n        allowed\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'
): (typeof documents)['\n  query SPPermissionCheck($accountId: String!, $action: SPRIAction!) {\n    spPurchasePermissionQuery(params: { accountId: $accountId, action: $action }) {\n      __typename\n      ... on SPPurchasePermissionResult {\n        action\n        allowed\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ComputeSPRIODaily($accountId: String!) {\n    summaryQuery: monitoringLatestQuery(\n      params: { accountId: $accountId, monitoringType: CHECK, code: "COMPUTE_SP_COVERAGE_LAST_DAYS" }\n    ) {\n      __typename\n      ... on MonitoringResult {\n        items {\n          code\n          results {\n            __typename\n            ... on MonitoringValueCheckResult {\n              columns\n              data\n              dataTypes\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    dailyUsageQuery: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_sprio_compute_daily_usage_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(\n          columns: [\n            "timestamp"\n            "ec2_covered_cost"\n            "ec2_on_demand_cost"\n            "lambda_sp_covered_cost"\n            "lambda_on_demand_cost"\n            "fargate_sp_covered_cost"\n            "fargate_on_demand_cost"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n    }\n  }\n'
): (typeof documents)['\n  query ComputeSPRIODaily($accountId: String!) {\n    summaryQuery: monitoringLatestQuery(\n      params: { accountId: $accountId, monitoringType: CHECK, code: "COMPUTE_SP_COVERAGE_LAST_DAYS" }\n    ) {\n      __typename\n      ... on MonitoringResult {\n        items {\n          code\n          results {\n            __typename\n            ... on MonitoringValueCheckResult {\n              columns\n              data\n              dataTypes\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    dailyUsageQuery: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_sprio_compute_daily_usage_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(\n          columns: [\n            "timestamp"\n            "ec2_covered_cost"\n            "ec2_on_demand_cost"\n            "lambda_sp_covered_cost"\n            "lambda_on_demand_cost"\n            "fargate_sp_covered_cost"\n            "fargate_on_demand_cost"\n          ]\n        ) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SageMakerSPRIODaily($accountId: String!) {\n    summaryQuery: monitoringLatestQuery(\n      params: { accountId: $accountId, monitoringType: CHECK, code: "SAGE_MAKER_COVERAGE_LAST_DAYS" }\n    ) {\n      __typename\n      ... on MonitoringResult {\n        items {\n          code\n          results {\n            __typename\n            ... on MonitoringValueCheckResult {\n              columns\n              data\n              dataTypes\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    dailyUsageQuery: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_sprio_sage_maker_daily_usage_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(columns: ["timestamp", "sp_covered_cost", "on_demand_cost"]) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n    }\n  }\n'
): (typeof documents)['\n  query SageMakerSPRIODaily($accountId: String!) {\n    summaryQuery: monitoringLatestQuery(\n      params: { accountId: $accountId, monitoringType: CHECK, code: "SAGE_MAKER_COVERAGE_LAST_DAYS" }\n    ) {\n      __typename\n      ... on MonitoringResult {\n        items {\n          code\n          results {\n            __typename\n            ... on MonitoringValueCheckResult {\n              columns\n              data\n              dataTypes\n            }\n          }\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n    }\n    dailyUsageQuery: athenaViewQuery(\n      params: { accountId: $accountId, name: "cco_view_sprio_sage_maker_daily_usage_v1", parameters: ["{accountId}"] }\n    ) {\n      __typename\n      ... on DataTableResult {\n        table(columns: ["timestamp", "sp_covered_cost", "on_demand_cost"]) {\n          columns\n          data\n          dataTypes\n        }\n      }\n      ... on ErroredQueryResult {\n        error\n      }\n      ... on DeferredQueryResult {\n        __typename\n      }\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<
  infer TType,
  any
>
  ? TType
  : never;
