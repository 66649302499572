import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Stack } from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useRelevantUsersById } from '@vertice/core/src/hooks/useRelevantUsersById';
import { isAwsResourceRef, parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { Card, Link, Placeholder, Text, TextFieldCaption } from '@verticeone/design-system';
import { dateTimeFormatterOptions, getFullName, useFormatDate } from '@verticeone/utils/formatting';
import { useResolveUsersInfo } from '../../../../../../hooks/useResolveUsersInfo';
import { SlackIconWOBG } from '../../../../../integrations/slack/icons';
import { RequestStatusChip } from '../../../components/RequestStatusChip';
import { parseSlackUser } from '../../../utils/requestorUtils';
import { useRequestContext } from '../../RequestContext';

export const DetailsSection = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { request, workflowVersion } = useRequestContext();

  const createdByUserId =
    request?.createdBy && !isAwsResourceRef(request.createdBy) ? parseUserRef(request.createdBy).userId : undefined;
  const ownedByUserId = request?.owner ? parseUserRef(request.owner).userId : undefined;

  const userIds = useMemo(
    () => [createdByUserId!, ownedByUserId!].filter((v) => !isNil(v)),
    [createdByUserId, ownedByUserId]
  );

  const { usersById: accountUsersById, isLoadingUsers: isLoadingAccountUsers } = useRelevantUsersById();
  const { usersById, isLoading: isLoadingUsers } = useResolveUsersInfo(userIds, accountUsersById);

  const createdByUser = createdByUserId ? usersById[createdByUserId] : undefined;
  const ownedByUser = ownedByUserId ? usersById[ownedByUserId] : undefined;

  const createdByRef = request?.createdBy;
  const isCreatedBySystem = !!createdByRef && isAwsResourceRef(createdByRef);

  const workflowName = workflowVersion?.workflowName;
  const workflowVersionName = workflowVersion?.status !== 'PENDING' ? workflowVersion?.versionName : undefined;

  const slackUser = parseSlackUser(request?.requestorRef, request?.sourceRef);

  return (
    <Card>
      <Stack spacing={6} padding={6}>
        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.SERVICE')} />
          <Text variant="heading" size="XS">
            {request?.serviceName}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.STATUS')} />
          <Text variant="heading" size="XS">
            {request?.status ? <RequestStatusChip status={request?.status} /> : <Placeholder height={19} width={80} />}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.OWNED_BY')} />
          <Text variant="heading" size="XS">
            {ownedByUser ? (
              <>
                {isLoadingUsers || isLoadingAccountUsers ? (
                  <Placeholder height={19} width={80} />
                ) : (
                  getFullName(ownedByUser)
                )}
              </>
            ) : (
              '-'
            )}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.CREATED_BY')} />
          <Text variant="heading" size="XS">
            {isCreatedBySystem ? (
              t('PAGES.CONTRACT_WORKFLOWS.REQUEST_DETAIL.CREATED_BY_SYSTEM')
            ) : (
              <>
                {slackUser ? (
                  <Stack direction="column">
                    <Stack direction="row">
                      <SlackIconWOBG style={{ marginTop: '-4px' }} /> {slackUser.userName}
                    </Stack>
                    {slackUser.linkToMessage && (
                      <Stack direction="row">
                        <Link href={slackUser.linkToMessage} target={'_blank'}>
                          <Stack direction="row" gap={1}>
                            {t('PAGES.CONTRACT_WORKFLOWS.REQUEST_DETAIL.SLACK_LINK_TO_CONVERSATION')}
                            <OpenInNewIcon />
                          </Stack>
                        </Link>
                      </Stack>
                    )}
                  </Stack>
                ) : (
                  <>
                    {isLoadingUsers || isLoadingAccountUsers ? (
                      <Placeholder height={19} width={80} />
                    ) : (
                      getFullName(createdByUser)
                    )}
                  </>
                )}
              </>
            )}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.CREATED_TIME_DATE')} />
          <Text variant="heading" size="XS">
            {formatDate(request?.createdAt, { formatterOptions: dateTimeFormatterOptions })}
          </Text>
        </Stack>

        <Stack>
          <TextFieldCaption size="S" label={t('ENTITIES.WORKFLOW_REQUEST.LABELS.WORKFLOW_VERSION')} />
          <Text variant="heading" size="XS">
            {(workflowName ? workflowName : '') + (workflowVersionName ? ` + ${workflowVersionName}` : '')}
          </Text>
        </Stack>
      </Stack>
    </Card>
  );
};
