import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ZodError, ZodSchema } from 'zod';
import { useFormContext } from 'react-hook-form';
import { PredefinedFormPagesDef } from '../predefinedForms/shared/types';

export const usePagesValidation = (pages?: PredefinedFormPagesDef, schema?: ZodSchema) => {
  // eslint-disable-next-line no-restricted-syntax
  const { watch } = useFormContext();

  const [validPages, setValidPages] = useState<Set<string>>(new Set());
  const [allPagesValid, setAllPagesValid] = useState(false);

  const pageIdsInOrder = useMemo(() => pages?.map((page) => page.id) ?? [], [pages]);

  const validatePagesDebounced = useDebouncedCallback(
    (formValues) => {
      try {
        if (schema !== undefined) {
          schema.parse(formValues);
        }
        setValidPages(new Set(pageIdsInOrder));
        setAllPagesValid(true);
      } catch (e) {
        if (e instanceof ZodError) {
          const failedFields = e.issues.map((issue) => issue.path.join('.'));
          const currentValidPages: Set<string> = new Set();
          pages?.forEach((page) => {
            if (!page.fields.some((field) => failedFields.includes(field))) {
              currentValidPages.add(page.id);
            }
          });
          setValidPages(currentValidPages);
          setAllPagesValid(currentValidPages.size === pageIdsInOrder.length);
        }
      }
    },
    500,
    { maxWait: 1000 }
  );

  useEffect(() => {
    const { unsubscribe } = watch((formValues) => {
      validatePagesDebounced(formValues);
    });
    return () => unsubscribe();
  }, [validatePagesDebounced, watch]);

  useEffect(() => {
    const formValues = watch();
    validatePagesDebounced(formValues);
  }, [validatePagesDebounced, watch]);

  return useMemo(
    () => ({
      validPages,
      allPagesValid,
    }),
    [allPagesValid, validPages]
  );
};
