import { FormDefinition, ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { mapToFormInteraface } from './mapToFormInterface';
import { GenericFormField } from '../types';

export function createNewGenericFormDefinition(
  oldDefinition: ServiceCatalogResource,
  newUrn: string,
  fields: GenericFormField[]
): FormDefinition {
  //We remove extraProperty (mapped variable) from the field layout
  const layoutFields = fields.map((field) => {
    const { variable, ...fieldWithoutVariable } = field;
    return fieldWithoutVariable;
  });

  //TODO use variable map in interface

  return {
    Kind: oldDefinition.kind,
    ResourceUrn: newUrn,
    Version: '2023-10-11',
    Form: {
      ...oldDefinition.definition,
      Name: oldDefinition.definition.Name,
      Interface: mapToFormInteraface({ fields }),
      FormUIModel: {
        Kind: 'Vertice:Form',
        Version: '2023-10-11',
        Layout: { fields: layoutFields },
      },
    },
  };
}
