import React, { FC, useState, ReactElement, cloneElement, SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridValueGetterParams,
  GridRowParams,
  MuiEvent,
  GridEventListener,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@mui/icons-material';
import { GridIconButton } from '@verticeone/design-system';
import { randomId } from '@mui/x-data-grid-generator';
import { useDropdownValueListContext } from './DropdownValueListContextProvider';
import { enhanceRowsWithReorderProperty, normalizeValueLabel } from './utils';
import { CustomContractFieldDropdownOption } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

interface WithActionsProps {
  children: ReactElement;
  excludeDeleteAction?: boolean;
}

const WithActions: FC<WithActionsProps> = ({ children, excludeDeleteAction, ...rest }) => {
  const { t } = useTranslation();
  const { values, addValue, updateValue, setError } = useDropdownValueListContext();
  const [tmpValues, setTmpValues] = useState<CustomContractFieldDropdownOption[]>([]);

  const rows = useMemo(() => {
    const valueIds = values?.map((value: CustomContractFieldDropdownOption) => value.id) || [];
    const valuesToUse = [...(values || []), ...tmpValues.filter((value) => !valueIds.includes(value.id))];
    return enhanceRowsWithReorderProperty(valuesToUse, 'label');
  }, [tmpValues, values]);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const onAddRow = () => {
    const id = randomId();
    setTmpValues((oldRows) => [...oldRows, { id, label: '', order: rows.length, enabled: true, isNew: true }]);
    setRowModesModel((currentModel) => ({
      ...currentModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'label' },
    }));
  };

  const onRowEditStart = (params: GridRowParams, event: MuiEvent<SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const onRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (newRow.label) {
      const valueData = {
        id: newRow.id,
        label: normalizeValueLabel(newRow.label),
        enabled: newRow.enabled,
        order: newRow.order,
      };

      if (newRow.isNew) {
        addValue(valueData);
      } else {
        updateValue(valueData);
      }
    }
    setTmpValues([]);
    return newRow;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setTmpValues([]);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const ActionsCell = (
    params: GridValueGetterParams<CustomContractFieldDropdownOption, CustomContractFieldDropdownOption>
  ) => {
    const apiRef = useGridApiContext();
    const isRowInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
    const save = () => {
      const updatedRow = apiRef.current.getRowWithUpdatedValues(params.row.id, 'label');

      const valueLabel = normalizeValueLabel(updatedRow.label);
      if (!valueLabel) {
        setError(t('ENTITIES.CONTRACT_CUSTOMIZATION.DROPDOWN_VALUE_LIST.LABEL_MISSING'));
        return;
      }

      const labelExists = values?.some(
        (value: CustomContractFieldDropdownOption) => value.label === valueLabel && value.id !== updatedRow.id
      );
      if (labelExists) {
        setError(
          t('ENTITIES.CONTRACT_CUSTOMIZATION.DROPDOWN_VALUE_LIST.LABEL_DUPLICATED', {
            name: valueLabel,
          })
        );
        return;
      }

      setRowModesModel({ ...rowModesModel, [params.row.id]: { mode: GridRowModes.View } });
    };

    return (
      <Stack direction="row" alignItems="center" gap={1}>
        {isRowInEditMode ? (
          <>
            <GridIconButton
              variant="ghost"
              color="success"
              onClick={save}
              icon={CheckOutlined}
              aria-label={t('ENTITIES.CONTRACT_CUSTOMIZATION.DROPDOWN_VALUE_LIST.SAVE_CHANGES')}
            />
            <GridIconButton
              variant="ghost"
              color="error"
              onClick={handleCancelClick(params.row.id)}
              icon={CloseOutlined}
              aria-label={t('ENTITIES.CONTRACT_CUSTOMIZATION.DROPDOWN_VALUE_LIST.DISCARD_CHANGES')}
            />
          </>
        ) : (
          <>
            <GridIconButton
              variant="ghost"
              onClick={handleEditClick(params.row.id)}
              icon={EditOutlined}
              aria-label={t('ENTITIES.CONTRACT_CUSTOMIZATION.DROPDOWN_VALUE_LIST.EDIT_OPTION')}
            />
          </>
        )}
      </Stack>
    );
  };

  const actionsColumn = {
    field: 'actions',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: ActionsCell,
    width: 84,
    align: 'center',
  };

  const addButtonDisabled = !!tmpValues.length;

  return (
    <>
      {cloneElement(children, {
        extraColumns: [actionsColumn],
        dataGridExtraProps: {
          rows,
          hideFooter: false,
          editMode: 'row',
          isCellEditable: () => true,
          rowModesModel,
          onRowEditStart,
          onRowEditStop,
          processRowUpdate,
          slotProps: {
            footer: { disabledButton: addButtonDisabled, onAddClick: onAddRow },
          },
        },
        ...rest,
      })}
    </>
  );
};

export default WithActions;
