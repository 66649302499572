import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Card, CardHeader, CardHeaderDescription, CardHeaderTitle } from '@verticeone/design-system';

import { IntelligentWorkflowsLayout } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';
import {
  GenericTemplateIcon,
  GenericWorkflowIcon,
} from '@vertice/core/src/modules/intelligentWorkflows/components/icons/Icons';
import { ServiceCard } from '@vertice/core/src/modules/intelligentWorkflows/service/ServiceCard';
import { COMING_SOON_TEMPLATES } from './constants';
import { useServiceCards } from '@vertice/core/src/modules/intelligentWorkflows/service/useServiceCards';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { createServiceIdentifier } from '@vertice/core/src/hooks/workflows/refUtils';

export const ServicesPage: FC = () => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();

  const serviceCards = useServiceCards({
    getTargetPath: (serviceRef) => {
      return generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE, {
        serviceId: createServiceIdentifier(serviceRef),
      });
    },
  });

  return (
    <IntelligentWorkflowsLayout>
      <Stack gap={8}>
        <Card>
          <CardHeader size="S">
            <CardHeaderTitle
              text={t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.SCENARIOS.TITLE')}
              icon={GenericWorkflowIcon}
            />
            <CardHeaderDescription>
              {t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.SCENARIOS.DESCRIPTION_1')}
              <br />
              {t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.SCENARIOS.DESCRIPTION_2')}
            </CardHeaderDescription>
          </CardHeader>
          <Stack padding={6} direction="row" flexWrap="wrap" gap={2}>
            {serviceCards}
          </Stack>
        </Card>

        <Card>
          <CardHeader size="S">
            <CardHeaderTitle
              text={t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.TEMPLATES.TITLE')}
              icon={GenericTemplateIcon}
            />
            <CardHeaderDescription>
              {t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.TEMPLATES.DESCRIPTION')}
            </CardHeaderDescription>
          </CardHeader>
          <Stack padding={6} direction="row" flexWrap="wrap" gap={2}>
            {COMING_SOON_TEMPLATES.sort((a, b) => a.name.localeCompare(b.name)).map((resource) => (
              <ServiceCard key={resource.urn} service={resource} inactive />
            ))}
          </Stack>
        </Card>
      </Stack>
    </IntelligentWorkflowsLayout>
  );
};
