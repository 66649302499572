/**
 * @module
 * All computations of savings and convenience-getters related to savings
 */

import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ContractFormData } from '../contract/types';

/**
 * Computes relative savings between two prices. Clamps the result between 0 and 1.
 *
 * @example
 * getSavingsPercentage({ oldPrice: 4, newPrice: 3 }) // 0.25
 * getSavingsPercentage({ oldPrice: 4, newPrice: -1 }) // 1
 * getSavingsPercentage({ oldPrice: 4, newPrice: 5 }) // 0
 */
export const getSavingsPercentage = ({ newPrice, oldPrice }: { newPrice: number; oldPrice: number }): number => {
  const rawPercentage = oldPrice === 0 ? 0 : (oldPrice - newPrice) / oldPrice;
  const clampedNegative = Math.max(rawPercentage, 0);
  return Math.min(clampedNegative, 1);
};

export const getSavingsVsInitial = (contract: Contract | ContractFormData): number =>
  Math.max(contract.parts.overview?.totalCostValueSavings ?? 0, 0);

export const getAnnualSavingsVsInitial = (contract: Contract | ContractFormData): number =>
  Math.max(contract.parts.overview?.annualSavings ?? 0, 0);

export const getAnnualSavingsVsInitialPercentage = (contract: Contract | ContractFormData): number =>
  getSavingsPercentage({
    oldPrice: contract.parts.overview?.annualCostWithoutVertice ?? 0,
    newPrice: contract.parts.overview?.annualCostWithVertice ?? 0,
  });

export const getAdditionalSavings = (contract: Contract | ContractFormData): number =>
  contract.parts.overview?.realizedConcessions ?? 0;

export const getAnnualAdditionalSavings = (contract: Contract | ContractFormData): number =>
  contract.parts.overview?.annualRealizedConcessions ?? 0;

export const getFutureSavings = (contract: Contract | ContractFormData): number =>
  contract.parts.overview?.unrealizedConcessions ?? 0;

export const getAnnualFutureSavings = (contract: Contract | ContractFormData): number =>
  contract.parts.overview?.annualUnrealizedConcessions ?? 0;

export const getScopeReductionSavings = (contract: Contract | ContractFormData): number =>
  contract.parts.overview?.scopeReductionSavings ?? 0;

export const getAnnualScopeReductionSavings = (contract: Contract | ContractFormData): number =>
  contract.parts.overview?.annualScopeReductionSavings ?? 0;

export const getTotalSavings = (contract: Contract | ContractFormData): number =>
  Math.max(contract.parts.overview?.totalSavings ?? 0, 0);

export const getAnnualTotalSavings = (contract: Contract | ContractFormData): number =>
  Math.max(contract.parts.overview?.annualTotalSavings ?? 0, 0);

/**
 * @deprecated Since release v0.51 use getAnnualTotalSavings instead
 */
export const getAnnualTotalSavingsFECalculated = (contract: Contract | ContractFormData): number => {
  //The calculation below should be deprecated and the value should be read from the overview section from release v0.51
  return Math.max(
    // We don't use getSavingsVsInitial because we want to accept negative annualSaving for this computation
    (contract.parts.overview?.annualSavings ?? 0) +
      getAnnualAdditionalSavings(contract) +
      getAnnualFutureSavings(contract) +
      getAnnualScopeReductionSavings(contract),
    0
  );
};

export const getAnnualTotalSavingsPercentage = (contract: Contract | ContractFormData): number => {
  const annualCostBaseline = contract.parts.overview?.annualCostBaseline ?? 0;
  return getSavingsPercentage({
    oldPrice: annualCostBaseline,
    newPrice: annualCostBaseline - getAnnualTotalSavings(contract),
  });
};

/**
 * @deprecated Since release v0.51 use getAnnualTotalSavingsPercentage instead
 */
export const getAnnualTotalSavingsPercentageFECalculated = (contract: Contract | ContractFormData): number => {
  const annualCostWithoutVertice = contract.parts.overview?.annualCostWithoutVertice ?? 0;
  return getSavingsPercentage({
    oldPrice: annualCostWithoutVertice,
    newPrice: annualCostWithoutVertice - getAnnualTotalSavingsFECalculated(contract),
  });
};
