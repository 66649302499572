import { Offer } from '../task/TaskDrawer/TaskPredefinedForm/predefinedForms/shared/schemas';
import { useOfferProductsAnnualisedMutation } from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { useEffect, useState } from 'react';

export const useOfferProductsAnnualCost = (offer: Offer) => {
  const [calculateProductsAnnualCost] = useOfferProductsAnnualisedMutation();
  const [offerProductsAnnualCosts, setOfferProductsAnnualCosts] = useState<number[] | null>(null);

  useEffect(() => {
    void calculateProductsAnnualCost({
      body: [offer],
    })
      .unwrap()
      .then((response) => {
        setOfferProductsAnnualCosts(response[0]);
      });
  }, [calculateProductsAnnualCost, offer]);

  return offerProductsAnnualCosts;
};
