import unique from 'lodash/uniq';
import { useMemo } from 'react';

import type { FlowEdge, ProcessDefinition, Task } from '../../definitionsTypes';
import {
  isFlowEdgeMappingConfiguration,
  isTaskIOMappingConfiguration,
  isVerticeFormConfiguration,
  isVerticeServiceConfiguration,
} from '../../pocWorkflowSchema';
import { getDagreGraphFromWorkflowModel } from '../WorkflowRenderer/getDagreGraphFromWorkflowModel';
import { useWorkflowRendererContext } from '../WorkflowRenderer/WorkflowRendererContext';
import type { Schema, ServicesSchema } from '../WorkflowViewer/types';
import { useServicesSchemas } from '../WorkflowViewer/useServicesSchemas';
import { formatPropertyLabel, formatPropertyType } from '../WorkflowViewer/utils';
import { NodeId, Property, isExtendedProperty } from './types';
import {
  findAllPathsBetweenNodes,
  getProcessDefinitionStartNodeId,
  removeLabelPostfixFromEdgeId,
  replaceFirstSegmentOfPropertyId,
  sortPropertiesAlphabetically,
} from './utils';

const getMappedVariableProperties = ({
  mappedPropertyValue,
  serviceOutputSchema,
}: {
  mappedPropertyValue: string;
  serviceOutputSchema?: Schema;
}) => {
  if (!serviceOutputSchema) {
    return {};
  }

  const property = serviceOutputSchema.properties.find((p) => p.id === mappedPropertyValue);

  if (!property) {
    return {};
  }

  return property;
};

const getPropertiesFromTask = ({
  task,
  servicesSchema,
}: {
  task: Task;
  servicesSchema: ServicesSchema;
}): Property[] => {
  if (!task.configurations) {
    return [];
  }

  const { account: accountServicesSchemas, global: globalServicesSchemas } = servicesSchema;

  const { configurations } = task;

  const iOMappingConfigurationOutputFields = configurations.find(isTaskIOMappingConfiguration)?.mapping.outputFields;
  const verticeFormConfigurationFormUrn = configurations.find(isVerticeFormConfiguration)?.formUrn;
  const verticeServiceConfigurationResourceUrn = configurations.find(isVerticeServiceConfiguration)?.resourceUrn;

  const resourceUrn = verticeFormConfigurationFormUrn || verticeServiceConfigurationResourceUrn;

  /** The Task has no IO Mapping Configuration. In this case, the Output schema of the Vertice Form Configuration of the Task is used */
  if (!iOMappingConfigurationOutputFields?.length) {
    if (!resourceUrn) {
      return [];
    }

    const outputSchema = accountServicesSchemas[resourceUrn]?.output || globalServicesSchemas[resourceUrn]?.output;

    if (!outputSchema?.properties.length) {
      return [];
    }

    return outputSchema.properties.map((property) => {
      return {
        ...property,
        origin: {
          id: task.id,
          label: task.name,
          kind: 'task',
        },
      };
    });
  }

  /** The Task has IO Mapping Configuration. */
  const properties: Property[] = iOMappingConfigurationOutputFields.map((outputField) => {
    if (!resourceUrn) {
      return {
        id: outputField.name,
        label: formatPropertyLabel(outputField.name),
        isVisible: true,
        origin: {
          id: task.id,
          label: task.name,
          kind: 'task',
        },
      };
    }

    const accountServicesSchema = accountServicesSchemas[resourceUrn];
    const globalServicesSchema = globalServicesSchemas[resourceUrn];

    /** The types of the mapped properties can be determined in the Output schema of Account Services or Global Services */
    const mappedProperties = getMappedVariableProperties({
      mappedPropertyValue: outputField.value,
      serviceOutputSchema: (accountServicesSchema || globalServicesSchema)?.output,
    });

    return {
      ...mappedProperties,
      id: outputField.name,
      label: formatPropertyLabel(outputField.name),
      isVisible: true,
      origin: {
        id: task.id,
        label: task.name,
        kind: !accountServicesSchema && globalServicesSchema ? 'vertice task' : 'task',
      },
    };
  });

  return properties;
};

const changeOriginForProperties = ({
  properties,
  origin,
  id,
}: {
  properties: Property[];
  origin: Property['origin'];
  id: Property['id'];
}): Property[] => {
  return properties.map((prop) => {
    const updatedId = replaceFirstSegmentOfPropertyId(prop.id, id);

    const updatedProp = {
      ...prop,
      id: updatedId,
      origin: {
        ...origin,
      },
    };

    if ('properties' in prop && Array.isArray(prop.properties)) {
      return {
        ...updatedProp,
        properties: changeOriginForProperties({
          properties: prop.properties,
          origin,
          id: id,
        }),
      };
    }

    return updatedProp;
  });
};

const getPropertiesFromEdge = ({
  edge,
  servicesSchema,
}: {
  edge: FlowEdge;
  servicesSchema: ServicesSchema;
}): Property[] => {
  if (!edge.configurations) {
    return [];
  }

  const { global: globalServicesSchema } = servicesSchema;

  const edgeMappingConfigurationFields = edge.configurations.find(isFlowEdgeMappingConfiguration)?.mapping.fields;

  if (!edgeMappingConfigurationFields?.length) {
    return [];
  }

  return edgeMappingConfigurationFields.map((field) => {
    const { name, value } = field;

    const propertyId = name;
    const propertyLabel = formatPropertyLabel(propertyId);
    const origin = {
      id: edge.id,
      label: edge.name,
      kind: 'edge',
    } as const;

    if (value.startsWith('`') && value.endsWith('`')) {
      return {
        id: propertyId,
        label: propertyLabel,
        isVisible: true,
        origin,
        type: ['string'] as const,
        typeLabel: formatPropertyType({
          propertyType: ['string'],
        }),
      };
    }

    if (value.startsWith('udf(`urn:verticeone:vertice')) {
      const udfUrn = value.match(/`([^`]+)`/)?.[1];

      if (udfUrn) {
        const udfSchema = Object.entries(globalServicesSchema).find(([serviceUrn]) => serviceUrn.includes(udfUrn));

        if (udfSchema && udfSchema[1].output.properties.length > 0) {
          const udfReturnValueSchema = udfSchema[1].output.properties[0];
          const udfReturnValuePropertiesMappedToEdgeProperty = isExtendedProperty(udfReturnValueSchema)
            ? changeOriginForProperties({
                properties: udfReturnValueSchema.properties,
                origin,
                id: propertyId,
              })
            : [];

          return {
            ...udfReturnValueSchema,
            id: propertyId,
            label: propertyLabel,
            isVisible: true,
            origin,
            properties: udfReturnValuePropertiesMappedToEdgeProperty,
          };
        }
      }
    }

    return {
      id: propertyId,
      label: propertyLabel,
      isVisible: true,
      origin,
    };
  });
};

export type AvailableProperties = {
  requestProperties: Property[];
  globalUDFProperties: Property[];
};

export const useVariablesAvailableInNode = ({
  nodeId,
  processDefinition,
  workflowServiceRef,
  includeNodeVariables = true,
}: {
  nodeId: NodeId;
  processDefinition?: ProcessDefinition;
  workflowServiceRef?: string;
  /*
   * If true, the output properties of the node are included in the result.
   */
  includeNodeVariables?: boolean;
}): AvailableProperties => {
  const { model } = useWorkflowRendererContext();
  const servicesSchema = useServicesSchemas();
  const graph = useMemo(() => getDagreGraphFromWorkflowModel(model), [model]);

  const allProperties = useMemo(() => {
    if (!processDefinition) {
      return {
        globalUDFProperties: [],
        requestProperties: [],
      };
    }

    const allPaths = findAllPathsBetweenNodes({
      graph,
      fromNodeId: getProcessDefinitionStartNodeId(processDefinition),
      toNodeId: nodeId,
    });

    const allNodesIdsFromStartToNode = unique(allPaths.map((path) => path.nodes).flat()).filter(
      (nodeIdInPath) => includeNodeVariables || nodeIdInPath !== nodeId
    );
    const allEdgesIdsFromStartToNode = unique(
      allPaths.map((path) => path.edges.map(removeLabelPostfixFromEdgeId)).flat()
    );

    const allTasksFromStartToNode = (processDefinition.process.tasks ?? []).filter(({ task }) =>
      allNodesIdsFromStartToNode.includes(task.id)
    );

    const allEdgesFromStartToNode = (processDefinition.process.flow?.flow?.edges ?? []).filter(({ edge }) =>
      allEdgesIdsFromStartToNode.includes(edge.id)
    );

    const { account: accountServicesSchemas, global: globalServicesSchema } = servicesSchema;

    /*
     * There are several sources of properties:
     * 1. The Service Provider Input of the Workflow's service
     * 2. IO Mapping Configuration of the Task
     * 3. The Output schema of the Vertice Form Configuration of the Task
     * 4. Edge Mapping Configuration of the Edge
     * 5. User Defined Functions (UDFs)
     */
    const properties: Property[] = [];

    /** 1. The Service Provider Input of the Workflow's service */
    if (workflowServiceRef && accountServicesSchemas[workflowServiceRef]) {
      const workflowServiceInputSchema = accountServicesSchemas[workflowServiceRef].input;
      properties.push(...workflowServiceInputSchema.properties);
    }

    /*
     * 2. IO Mapping Configuration of the Task
     * 3. The Output schema of the Vertice Form Configuration of the Task
     */
    for (const { task } of allTasksFromStartToNode) {
      properties.push(...getPropertiesFromTask({ task, servicesSchema }));
    }

    /** 4. Edge Mapping Configuration of the Edge */
    for (const { edge } of allEdgesFromStartToNode) {
      properties.push(...getPropertiesFromEdge({ edge, servicesSchema }));
    }

    /** 5. User Defined Functions (UDFs) */
    const globalUDFProperties = Object.values(globalServicesSchema)
      .flatMap((schema) => schema.output.properties)
      .filter((property) => property.origin.kind === 'udf');

    return {
      requestProperties: sortPropertiesAlphabetically(properties),
      globalUDFProperties: sortPropertiesAlphabetically(globalUDFProperties),
    };
  }, [nodeId, servicesSchema, processDefinition, graph, workflowServiceRef, includeNodeVariables]);

  return allProperties;
};
