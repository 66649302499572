import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardHeaderTitle } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { Loader } from '@verticeone/design-system';
import { Box, Stack, useTheme } from '@mui/material';
import { Divider } from '@verticeone/design-system';
import useGetMostCommonContractLengthData from './useGetMostCommonContractLengthData';
import MostCommonContractLengthGraph from './MostCommonContractLengthGraph';
import { Placeholder } from '@verticeone/design-system';

export type MostCommonContractLengthCardProps = {
  vendorId: string;
  productId: string;
};

const MostCommonContractLengthCard = ({ vendorId, productId }: MostCommonContractLengthCardProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { data, isLoading } = useGetMostCommonContractLengthData({ vendorId, productId });

  return (
    <Card minWidth={374} height="100%">
      <Stack direction="row">
        <Box flexGrow={1} alignSelf="center">
          <CardHeader size="S" noDivider>
            <CardHeaderTitle text={t('PRODUCT.MOST_COMMON_CONTRACT_LENGTH_CARD.TITLE')} />
          </CardHeader>
        </Box>
        <Divider orientation="vertical" />
        <Stack p={6}>
          <Text variant="body-bold" size="S" color="text2">
            {t('PRODUCT.MOST_COMMON_CONTRACT_LENGTH_CARD.TITLE')}
          </Text>
          {isLoading ? (
            <Placeholder width={150} height={25} sx={{ marginTop: 1 }} />
          ) : data?.mostCommonValue ? (
            <Text variant="heading" size="M">
              {t('PRODUCT.MOST_COMMON_CONTRACT_LENGTH_CARD.X_MONTHS', { count: data?.mostCommonValue })}
            </Text>
          ) : (
            '-'
          )}
        </Stack>
      </Stack>
      <Divider />
      <Box py={6} bgcolor={palette.core.color1} minHeight={280}>
        {isLoading ? <Loader /> : <MostCommonContractLengthGraph data={data!} />}
      </Box>
    </Card>
  );
};

export default MostCommonContractLengthCard;
