import { FC, useEffect, useMemo, useState } from 'react';

import {
  useGetAccountIntegrationQuery,
  useGetJiraWebhookConfigQuery,
  useListEditJiraIssueTypeFieldsQuery,
  useListJiraFieldOptionsQuery,
} from '@vertice/slices';
import { useAccountContext } from '../../../../../../contexts/AccountContext';
import { ProviderIds } from '../../../../../applications/utils';
import { isIOMappingConfiguration } from '../../../../definitions/taskDefinition';
import { useVariablesAvailableInNode } from '../../useVariablesAvailableInNode';
import { getCorrespondingTaskService, ISSUE_TYPE_ID_NAME, workflowToFormVariables } from '../JiraCommon/utils';
import { EditServiceTaskBase, JiraProject } from '../types';
import { JiraSyncTaskFormData } from './formSchema';
import { JiraSyncForm } from './JiraSyncForm';
import { getAvailableCreateJiraTasks, getJiraSyncInputMapping } from './utils';

/**
 * Get and prepare data for Jira create form like available workflow variables and Jira variables
 */
export const JiraSync: FC<EditServiceTaskBase> = (props) => {
  const { task, processDefinition, workflowServiceRef, editorConfig, resources, ...restProps } = props;
  const { accountId } = useAccountContext();
  const taskService = getCorrespondingTaskService(task, resources);

  const syncWebhookQuery = useGetJiraWebhookConfigQuery({ accountId });

  const { data: integrationData, isLoading: isLoadingIntegration } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });
  const isIntegrationActive = integrationData?.status === 'ACTIVE';
  const selectedProject = integrationData?.parameters?.projects?.[0] as JiraProject | undefined;

  const { requestProperties, globalUDFProperties } = useVariablesAvailableInNode({
    nodeId: task.task.id,
    processDefinition,
    workflowServiceRef,
  });
  const workflowVariables = useMemo(
    () => ({ request: requestProperties, global: globalUDFProperties }),
    [requestProperties, globalUDFProperties]
  );

  const allVariables = useMemo(() => [...workflowVariables.global, ...workflowVariables.request], [workflowVariables]);

  const [referencedTaskId, setReferencedTaskId] = useState<string | null | undefined>();
  // Initial referencedTaskId setup
  useEffect(() => {
    if (!task || !taskService || !allVariables.length) {
      setReferencedTaskId(undefined);
    } else {
      const { createJiraTasksByTickets } = getAvailableCreateJiraTasks(allVariables);
      const { ticketId } = getJiraSyncInputMapping(task);
      setReferencedTaskId(ticketId ? createJiraTasksByTickets[ticketId]?.id : null);
    }
  }, [allVariables, task, taskService]);

  /* Field type used in Jira create this Jira Sync ticket references.
   * So we can show its properties to be synced.
   */
  const issueTypeId = useMemo(() => {
    if (referencedTaskId === undefined) return undefined;

    const referencedTask = processDefinition.process.tasks?.filter(
      (processTask) => processTask.task.id === referencedTaskId
    )[0];

    if (referencedTask) {
      const ioMappingConfiguration = referencedTask.task.configurations?.find(isIOMappingConfiguration);
      const inputField = ioMappingConfiguration?.mapping.inputFields.find((input) => input.name === ISSUE_TYPE_ID_NAME);
      return inputField ? inputField.value : null;
    }

    return null;
  }, [processDefinition, referencedTaskId]);

  const filterStatus = useMemo(() => {
    return taskService ? getJiraSyncInputMapping(task).filterStatus : [];
  }, [task, taskService]);

  const { data: issueTypeFieldsData, isFetching: isFetchingIssueTypeFields } = useListEditJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: selectedProject?.id ?? '',
      issueTypeId: issueTypeId ?? '',
    },
    { skip: !selectedProject?.id || !issueTypeId }
  );

  const { data: statuses, isLoading: isLoadingStatuses } = useListJiraFieldOptionsQuery(
    {
      accountId: accountId,
      projectId: selectedProject?.id ?? '',
      issueTypeId: issueTypeId ?? '',
      fieldName: 'status',
    },
    { skip: !selectedProject?.id || !issueTypeId }
  );

  const defaultValues = useMemo<JiraSyncTaskFormData | undefined>(() => {
    if (!task || referencedTaskId === undefined) return undefined;

    const { variables } = getJiraSyncInputMapping(task);
    let initialVariables = workflowToFormVariables(variables);

    return {
      name: task.task.name || '',
      description: task.task.description || '',
      taskId: referencedTaskId || '',
      filterStatus: filterStatus,
      variables: initialVariables,
    };
  }, [task, referencedTaskId, filterStatus]);

  return (
    <>
      {taskService && defaultValues && (
        <JiraSyncForm
          task={task}
          taskService={taskService}
          defaultValues={defaultValues}
          workflowVariables={workflowVariables}
          // projectId={selectedProject?.id ?? ''}
          isLoadingIntegration={isLoadingIntegration}
          isFetchingIssueTypeFields={isFetchingIssueTypeFields}
          {...restProps}
          hasReferencedIssueType={!!issueTypeId}
          noReferenceIssuePlaceholder={'Není jira field'}
          onTaskChange={setReferencedTaskId}
          syncWebhookQuery={syncWebhookQuery}
          completeCriteriaProps={{
            isLoading: isLoadingStatuses,
            availableStatuses: statuses?.fields ?? [],
          }}
          variableMapperProps={{
            isIntegrationActive,
            workflowVariables: {
              global: globalUDFProperties,
              request: requestProperties,
            },
            jiraVariables: issueTypeFieldsData?.fields ?? [],
            fromWorkflow: false,
          }}
        />
      )}
    </>
  );
};
