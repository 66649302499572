import React, { MouseEvent } from 'react';
import { useCloudContext } from '@vertice/dashboard/src/modules/cloud/CloudContext';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import RioBanner from '@vertice/dashboard/src/modules/cloud/cards/shared/RioBanner/RioBanner';
import GenericTable from '../GenericTable/GenericTable';
import { IntFormatter } from '../GenericTable';
import GenericCard from '../GenericCard';

const VR19 = () => {
  const { subFeatures } = useCloudContext();
  const navigate = useNavigate();
  const isSprioEnabled = subFeatures.sprio && subFeatures.sprioEC2RI;
  const isRioBannerVisible = subFeatures.rio || isSprioEnabled;

  const handleClick = (evt: MouseEvent) => {
    const route = isSprioEnabled ? ROUTES.CLOUD_RIO_RESERVED_INSTANCES_DETAIL : ROUTES.CLOUD_RIO_TAB_DETAIL;
    const path = generatePath(route, { activeTab: 'inventory', product: 'ec2' });
    if (evt.ctrlKey || evt.metaKey) {
      // Open in new tab
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <GenericCard translationKey="CLOUD.CLOUD_RECOMMENDATIONS.RECOMMENDATION.VR-19.TABLE" show={['title']}>
        <GenericTable
          code="VT-19"
          testId="over-committed-ris"
          translationKey="CLOUD.CLOUD_RECOMMENDATIONS.RECOMMENDATION.VR-19.TABLE"
          columns={
            [
              'instance_type',
              'region',
              'account_id',
              'discount_arn',
              'resource_cost',
              'utilization',
              'equivalent_od_cost',
              'savings',
            ] as const
          }
          getRowId={(row) => `${row.account_id}-${row.region}-${row.discount_arn}`}
          defaultSortColumns={{ field: 'savings', sort: 'desc' }}
          renderCell={{
            equivalent_od_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
            resource_cost: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
            savings: (value) => <IntFormatter value={value as number} formatStyle="currency" />,
            utilization: (value) => <IntFormatter value={value as number} formatStyle="percent" />,
          }}
          columnSettings={{
            equivalent_od_cost: { minWidth: 200 },
            savings: { minWidth: 150 },
            resource_cost: { minWidth: 160 },
          }}
        />
      </GenericCard>
      {isRioBannerVisible ? <RioBanner onClick={handleClick} /> : null}
    </>
  );
};

export default VR19;
