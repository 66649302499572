import { Alert } from '@verticeone/design-system';
import { Trans } from 'react-i18next';
import { WarningAmberOutlined } from '@mui/icons-material';

import { Text } from '@verticeone/design-system';
import { useAccountTableData } from 'pages/Preferences/Integrations/AWS/hooks/useAccountTableData';

const DisclaimerBanner = () => {
  const { data } = useAccountTableData();

  const accountsExcludedFromCco = data?.accounts?.filter((account) => account.settings?.excludedFromCco === true);
  const hasExcludedAccounts = accountsExcludedFromCco && accountsExcludedFromCco.length > 0;
  const names = accountsExcludedFromCco?.map(({ name }) => name);

  if (!hasExcludedAccounts) {
    return null;
  }

  return (
    <Alert
      size="M"
      variant="ghost"
      color="warning"
      subtitle={
        <Trans
          i18nKey="CLOUD.CLOUD_OPTIMIZATION.ACCOUNT_EXCLUSION_DISCLAIMER"
          components={{
            b: <Text variant="body-bold" />,
          }}
          values={{ names: names?.join(', '), count: accountsExcludedFromCco?.length }}
        />
      }
      icon={WarningAmberOutlined}
    />
  );
};

export default DisclaimerBanner;
