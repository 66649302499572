import React, { FC } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from '@xyflow/react';
import { Stack, styled, useTheme } from '@mui/material';
import { ChipButton } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { isFlowEdgeConditionConfiguration, ProcessEdgeData } from '../types';

const StyledEdgeLabel = styled('div')<{ $labelX: number; $labelY: number }>(({ $labelX, $labelY }) => ({
  position: 'absolute',
  transform: `translate(-50%, -50%) translate(${$labelX}px,${$labelY}px)`,
}));

const StyledEdgeLabelName = styled('div')<{ $selected?: boolean }>(({ theme: { palette }, $selected }) => ({
  background: $selected ? palette.primary.color2 : palette.info.color2,
  borderRadius: 2,
  maxWidth: 100,
  textAlign: 'center',
  display: 'flex',
  padding: '1px 4px',
}));

export const LabelledEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
  style,
  selected,
}) => {
  const { palette } = useTheme();
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const isDefaultFlowEdge = (data as ProcessEdgeData).configurations
    ?.filter(isFlowEdgeConditionConfiguration)
    .some((conditionDefinition) => conditionDefinition.condition.conditionType === 'DefaultFlow');
  const edgeData = data as ProcessEdgeData;

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          ...style,
          ...(selected
            ? {
                stroke: palette.primary.color2,
                filter: `drop-shadow(0px 0px 3px ${palette.primary.color2})`,
              }
            : { stroke: palette.core.color6 }),
        }}
      />
      {(edgeData.name || isDefaultFlowEdge) && (
        <EdgeLabelRenderer>
          <StyledEdgeLabel $labelX={labelX} $labelY={labelY}>
            <Stack alignItems="center" gap="1px">
              {edgeData.name && (
                <StyledEdgeLabelName $selected={selected}>
                  <Text variant="body-regular" size="XXS" color={'text5'} tag="span">
                    {edgeData.name}
                  </Text>
                </StyledEdgeLabelName>
              )}
              {isDefaultFlowEdge && (
                <ChipButton size="XXS" variant="solid" color="warning">
                  Default
                </ChipButton>
              )}
            </Stack>
          </StyledEdgeLabel>
        </EdgeLabelRenderer>
      )}
    </>
  );
};
