import { FC } from 'react';
import { TextField, DatePicker, Select } from '@verticeone/design-system';
import type { FullOption, ValueEditorProps } from 'react-querybuilder';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../constants';
import { XTypeDatasourceAsyncSelect } from './XTypeDatasourceAsyncSelect';

const isValidXTypeUrn = (xTypeUrn: unknown): xTypeUrn is string => {
  return typeof xTypeUrn === 'string';
};

export const ValueEditor: FC<ValueEditorProps> = ({
  value,
  handleOnChange,
  type,
  inputType,
  values: options,
  fieldData,
}) => {
  if (type === 'select') {
    if (inputType === 'async-select' && isValidXTypeUrn(fieldData.xType)) {
      return <XTypeDatasourceAsyncSelect xTypeUrn={fieldData.xType} onChange={handleOnChange} value={value} />;
    }

    if (inputType === 'checkbox') {
      const selectedOption = options?.find((option) => option.value === String(value));
      return (
        <Select<FullOption<string>, false>
          variant="outlined"
          size="S"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          value={selectedOption}
          onChange={(newValue) => handleOnChange(newValue?.value === 'true')}
          options={options}
        />
      );
    }

    const selectedOption = options?.find((option) => option.value === value);

    return (
      <Select<FullOption<string>, false>
        variant="outlined"
        size="S"
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        value={selectedOption}
        onChange={(newValue) => handleOnChange(newValue?.value)}
        options={options}
      />
    );
  }

  if (inputType === 'date') {
    return (
      <DatePicker
        size="S"
        value={value}
        onChange={(date) => handleOnChange(date)}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      />
    );
  }

  return (
    <TextField
      variant="outlined"
      size="S"
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      value={value}
      onChange={(event) => handleOnChange(event.target.value)}
      type={inputType ?? 'text'}
    />
  );
};
