import { Skeleton, Stack, styled } from '@mui/material';

const LoadingSkeleton = styled(Skeleton)(({ theme }) => ({
  height: theme.spacing(12),
}));

export const VariableMapperLoader = () => {
  return (
    <Stack gap={2}>
      <LoadingSkeleton variant="rounded" />
      <LoadingSkeleton variant="rounded" />
      <LoadingSkeleton variant="rounded" />
      <LoadingSkeleton variant="rounded" width="40%" />
    </Stack>
  );
};
