import React, { useState } from 'react';
import { Stack, styled, Typography, useTheme } from '@mui/material';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@verticeone/utils/formatting';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { IconWrapper } from '@verticeone/design-system';
import { HierarchyItem } from './useHierarchyGraphData';
import PercentageTile from './PercentageTile';
import NodeIcon from './NodeIcon';

const StyledNode = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
  padding: '16px',
  backgroundColor: theme.palette.core.bg,
  borderRadius: 'inherit',
}));

type NodeProps = {
  data: HierarchyItem;
  currency: string;
  selected?: boolean;
};

const Node = ({ data, currency, selected }: NodeProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { locale } = useLocaleContext();
  const { palette } = useTheme();

  const [expanded, setExpanded] = useState(true);

  const { id, type, name, value, previousValue } = data;

  const roundedValue = Math.max(value, 1);
  const formattedValue =
    (value < 1 ? '< ' : '') +
    formatCurrency(roundedValue, {
      currency,
      locale,
      maximumFractionDigits: 0,
    });

  const handleExpandOrCollapse = (event: any) => {
    setExpanded(!expanded);
    event.stopPropagation();
  };

  return (
    <StyledNode id={id}>
      <Stack height={24} direction="row" gap={1} alignItems="center" justifyContent="flex-start">
        <NodeIcon type={type} name={name} selected={selected} />
        <Typography flexGrow={1} noWrap variant="heading-xs" color={palette.text.color1}>
          {name}
        </Typography>
        <IconWrapper
          cursor="pointer"
          size="S"
          icon={expanded ? ExpandLessOutlinedIcon : ExpandMoreOutlinedIcon}
          onClick={handleExpandOrCollapse}
        />
      </Stack>
      {expanded && (
        <Stack direction="row" alignItems="flex-end" gap={6} height={60}>
          <Stack>
            <Typography variant="body-bold-s" color={palette.text.color3}>
              {t('HIERARCHY.SPEND')}
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="heading-xs" color={selected ? palette.text.color1 : palette.inactive.color2}>
                {formattedValue}
              </Typography>
              {previousValue !== undefined && (
                <PercentageTile value={value} previousValue={previousValue} currency={currency} selected={selected} />
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </StyledNode>
  );
};

export default Node;
