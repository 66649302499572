import { FC, PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';

type EditorDrawerGroupProps = PropsWithChildren & {
  title: string;
};

export const EditorDrawerGroup: FC<EditorDrawerGroupProps> = ({ title, children }) => {
  return (
    <Stack gap={6}>
      <Text variant="label" size="XXS" color="text3">
        {title}
      </Text>
      <Stack gap={4}>{children}</Stack>
    </Stack>
  );
};
