import React from 'react';
import { styled } from '@mui/material';
import { TimelineTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { NoData } from '@verticeone/design-system';

type NoDataWrapperProps = {
  children: React.ReactNode;
  hasData: boolean;
};

const StyledNoData = styled(NoData)({
  backgroundColor: 'transparent',
});

const StyledTimelineIcon = styled(TimelineTwoTone)(({ theme }) => ({
  color: theme.palette.error.color2,
}));

const NoDataWrapper = ({ children, hasData }: NoDataWrapperProps) => {
  const { t } = useTranslation();

  if (!hasData) {
    return (
      <StyledNoData
        icon={<StyledTimelineIcon />}
        header={t('CLOUD.EMPTY_STATE.NO_DATA_AVAILABLE_TITLE')}
        content={t('CLOUD.EMPTY_STATE.NO_DATA_AVAILABLE_TEXT')}
      />
    );
  }

  return <>{children}</>;
};

export default NoDataWrapper;
