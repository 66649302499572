import React from 'react';
import { ChipButton, ChipButtonProps } from '@verticeone/design-system';

const Chip = ({ color, children }: Pick<ChipButtonProps, 'color' | 'children'>) => (
  <ChipButton variant="ghost" color={color} size="M" isActive={false}>
    {children}
  </ChipButton>
);

export default Chip;
