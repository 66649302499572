import React from 'react';
import { Stack } from '@mui/material';
import type { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-pro';

import type { OptimizationRow } from '../../../dataSource/useOptimizations';
import DefaultColumn from './DefaultColumn';

type CategoryColumnProps = GridRenderCellParams<OptimizationRow, any, any, GridTreeNodeWithRender>;

const CategoryColumn = (params: CategoryColumnProps) => (
  <Stack direction="row" gap={2} flex={1} justifyContent="space-between">
    <DefaultColumn {...params} />
  </Stack>
);

export default CategoryColumn;
