import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadableContentWrapper } from '../../components/LoadableContentWrapper';
import { Card, CardHeader, CardHeaderTitle } from '@verticeone/design-system';
import useExpiringReservedInstancesData from './useExpiringReservedInstancesData';
import ExpiringReservedInstancesTable from './ExpiringReservedInstancesTable';

const ExpiringReservedInstancesCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const { data, ...loadingStatus } = useExpiringReservedInstancesData();
  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('EXPIRING_RESERVED_INSTANCES.TITLE')} tag="h2" />
      </CardHeader>

      <LoadableContentWrapper {...loadingStatus} isEmpty={false}>
        <ExpiringReservedInstancesTable data={data!} />
      </LoadableContentWrapper>
    </Card>
  );
};

export default ExpiringReservedInstancesCard;
