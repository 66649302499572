import { FieldType } from '../types';
import { z } from 'zod';

export type MoneyFieldType = {
  name: string;
  type: FieldType.MONEY;
  metadata: {
    label: string;
    description?: string;
    required: boolean;
  };
};

export const moneyFieldSchema = z.object({
  name: z.string(),
  type: z.literal(FieldType.MONEY),
  metadata: z.object({
    label: z.string(),
    description: z.string().optional(),
    required: z.boolean(),
  }),
});
