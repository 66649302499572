import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectColor } from '@verticeone/design-system';
import { AddFieldButton } from './components/AddFieldButton';
import { FieldTypeSelect } from './components/FieldTypeSelect';
import { CustomFormBuilderProps, FormBuilderMode } from './types';
import { FieldType } from '../fields/types';
import { FieldList } from './components/FieldList';
import { DynamicFormBuilderContextProvider, useDynamicFormBuilderContext } from './DynamicFormBuilderContextProvider';
import { FieldDialog } from './components/FieldDialog/FieldDialog';
import { createNewField } from '../fields/utils';
import { FieldArrayPath, FieldValues } from 'react-hook-form';
import { EditorDrawerGroup } from '../../../intelligentWorkflows/workflowSchema/WorkflowEditor/EditorDrawer';
import { isNil } from 'lodash';
import { DynamicFormField } from '../types';

type DynamicFormBuilderComponentProps = Pick<DynamicFormBuilderProps, 'maxFieldCount'>;

const DynamicFormBuilderComponent = <F extends DynamicFormField>({
  maxFieldCount,
}: DynamicFormBuilderComponentProps) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState(FormBuilderMode.DEFAULT);
  const { fields, addField, dialogState, setDialogState } = useDynamicFormBuilderContext();
  const reachedFieldLimit = useMemo(
    () => !isNil(maxFieldCount) && fields.length >= maxFieldCount,
    [fields, maxFieldCount]
  );
  const isAddButtonVisible = useMemo(
    () => !reachedFieldLimit && mode === FormBuilderMode.DEFAULT,
    [reachedFieldLimit, mode]
  );
  const isFieldSelectVisible = useMemo(
    () => !reachedFieldLimit && mode === FormBuilderMode.FIELD_TYPE_SELECT,
    [reachedFieldLimit, mode]
  );

  const onAddFieldClick = () => {
    setMode(FormBuilderMode.FIELD_TYPE_SELECT);
  };

  const onFieldTypeSelected = (fieldType?: FieldType) => {
    if (fieldType) {
      setDialogState({
        mode: 'create',
        fieldType: fieldType,
        defaultValues: {},
        onSave: (values: F) => {
          addField(createNewField<F>(fieldType, values));
        },
      });
    }
    setMode(FormBuilderMode.DEFAULT);
  };

  return (
    <EditorDrawerGroup title={t('DYNAMIC_FORM_BUILDER.TITLE')}>
      <FieldList />
      {isAddButtonVisible && <AddFieldButton label={t('DYNAMIC_FORM_BUILDER.ADD_FIELD')} onClick={onAddFieldClick} />}
      {isFieldSelectVisible && <FieldTypeSelect onChange={onFieldTypeSelected} />}
      {!!dialogState && <FieldDialog />}
    </EditorDrawerGroup>
  );
};

type DynamicFormBuilderProps<FormDataType extends FieldValues = never> = {
  fieldPath: FieldArrayPath<FormDataType>;
  color?: SelectColor;
  customFormBuilder?: (props: CustomFormBuilderProps) => ReactNode;
  maxFieldCount?: number;
};

export const DynamicFormBuilder = <FormDataType extends FieldValues, F extends DynamicFormField>(
  props: DynamicFormBuilderProps<FormDataType>
) => {
  const { maxFieldCount, ...providerProps } = props;

  return (
    <DynamicFormBuilderContextProvider {...providerProps}>
      <DynamicFormBuilderComponent<F> maxFieldCount={maxFieldCount} />
    </DynamicFormBuilderContextProvider>
  );
};
