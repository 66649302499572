import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '@verticeone/design-system';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import DataSourceProvider from '../../../../../DataSourceProvider';
import { DataLoaderComponent, MissingSPBanner } from '../../../../components';
import { Overview, Body, ActionBar, ButtonGroup, Title } from '../../components';
import Graph from './Graph';
import Summary from './Summary';

const SageMaker = () => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OVERVIEW.WIDGETS.SAGE_MAKER.ACTION_BAR' });

  const toInventory = () => navigate(routes.CLOUD.RIO.SP.INVENTORY.DETAIL, { product: 'sagemaker' });
  const toOptimize = () => navigate(routes.CLOUD.RIO.SP.OPTIMIZE.DETAIL, { product: 'sagemaker' });

  return (
    <DataSourceProvider datasource="SageMaker">
      <Overview testId="sagemaker-overview">
        <Stack p={6} pb={0} gap={6}>
          <Title />
          <MissingSPBanner />
        </Stack>
        <DataLoaderComponent>
          <Body>
            <Graph />
            <Summary />
          </Body>
          <ActionBar>
            <ButtonGroup>
              <Button variant="ghost" size="S" color="neutral" onClick={toInventory}>
                {t('INVENTORY')}
              </Button>
              <Button variant="ghost" size="S" color="tertiary" onClick={toOptimize}>
                {t('OPTIMIZE')}
              </Button>
            </ButtonGroup>
          </ActionBar>
        </DataLoaderComponent>
      </Overview>
    </DataSourceProvider>
  );
};

export default SageMaker;
