import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';
import { Box } from '@mui/material';
import { useDataSource } from '../../DataSourceProvider';
import useAwsSpRioSubFeatures, { SPKeys } from '@vertice/dashboard/src/pages/Cloud/CloudRIO/useAwsSpRioSubFeatures';
import { useSPPurchasePermissionQuery } from '../dataSources/useSPPurchasePermissionQuery';
import { SpriAction } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';

type PurchaseTooltipProps = {
  show: boolean;
  children: React.ReactNode;
};

const PurchaseTooltip = ({ show, children }: PurchaseTooltipProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.ACTIONS_ROW.PURCHASE_SP',
  });

  if (show) {
    return (
      <Tooltip size="S" content={t('COMING_SOON_TOOLTIP')}>
        <span>{children}</span>
      </Tooltip>
    );
  }

  return children;
};

const PurchaseSPButton = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.ACTIONS_ROW.PURCHASE_SP' });
  const { buyDrawer, datasource } = useDataSource();
  const { isFetching: isAwsRioSubFeaturesFetching, data: awsRioSubFeaturesData } = useAwsSpRioSubFeatures();
  const { isFetching: isSPPurchasePermissionFetching, data: isSPPurchaseAllowed } = useSPPurchasePermissionQuery({
    action: SpriAction.Offerings,
  });

  const isDataSourceAllowed = ['SageMaker', 'Compute'].includes(datasource); // temporary only for SageMaker and Compute
  const isBuyingJourneyEnabled =
    isDataSourceAllowed &&
    !isAwsRioSubFeaturesFetching &&
    awsRioSubFeaturesData?.sp[datasource.toLowerCase() as SPKeys]?.buyingJourney;

  const offeringsButtonEnabled = isBuyingJourneyEnabled && isSPPurchaseAllowed;

  return (
    <Box>
      <PurchaseTooltip show={!isDataSourceAllowed}>
        <Button
          testId="purchase-sp"
          variant="ghost"
          size="M"
          color="tertiary"
          disabled={!offeringsButtonEnabled}
          isLoading={isSPPurchasePermissionFetching || isAwsRioSubFeaturesFetching}
          onClick={buyDrawer.open}
        >
          {t('LABEL')}
        </Button>
      </PurchaseTooltip>
    </Box>
  );
};

export default PurchaseSPButton;
