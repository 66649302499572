import { Select } from '@verticeone/design-system';
import React from 'react';
import { useDashboardContext } from '../DashboardContextProvider';
import { ContractCategory } from '../types';
import useContractCategoryOptions, { CategoryOption } from '../hooks/useContractCategoryOptions';

const ContractCategorySelect = () => {
  const { contractCategory, setContractCategory } = useDashboardContext();
  const contractCategories = useContractCategoryOptions();

  const handleCategoryChange = (selectedOption: CategoryOption | null) => {
    setContractCategory(selectedOption ? selectedOption.value : ContractCategory.SAAS);
  };

  return (
    <Select<CategoryOption, false>
      options={contractCategories}
      value={contractCategories.find((option) => option.value === contractCategory)}
      size="S"
      variant="outlined"
      isMulti={false}
      isClearable={false}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      onChange={handleCategoryChange}
      testId="contract-category-select"
    />
  );
};

export default ContractCategorySelect;
