import { Box, Stack, styled, useTheme } from '@mui/material';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { Product } from '@vertice/slices';
import { Button, Card, IconWrapper } from '@verticeone/design-system';
import { Text, EllipsisText } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';
import { createRef, useCallback, useEffect, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/src';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(Link)(() => {
  return {
    display: 'flex',
    textDecoration: 'unset',
    color: 'unset',
    height: '100%',
    width: '100%',
  };
});

const DescriptionText = ({ children }: { children: string }) => {
  const textElementRef = createRef<HTMLElement>();
  const [disableHoverListener, setDisableHoverListener] = useState(true);

  const checkTextLength = useCallback(() => {
    const scrollHeight = textElementRef.current?.scrollHeight;
    const clientHeight = textElementRef.current?.clientHeight;
    const textOverflow = scrollHeight && clientHeight && scrollHeight > clientHeight;
    setDisableHoverListener(!textOverflow);
  }, [textElementRef]);

  // check text lenght on first render and add resize listener
  useEffect(() => {
    checkTextLength();
    window.addEventListener('resize', checkTextLength);

    return () => {
      window.removeEventListener('resize', checkTextLength);
    };
  }, [checkTextLength]);

  return (
    <Tooltip size="S" disableHoverListener={disableHoverListener} content={children}>
      <EllipsisText ref={textElementRef} variant="body-regular" size="S" color="text2" lineClamp={2}>
        {children}
      </EllipsisText>
    </Tooltip>
  );
};

export type SingleProductCardProps = {
  vendorId: string;
  product: Product;
};

const SingleProductCard = ({ vendorId, product }: SingleProductCardProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const routes = useRoutes();

  const pathToProductDetail = generatePath(routes.VENDORS.VENDOR.PRODUCT.ABSOLUTE_PATH, {
    id: vendorId,
    productId: product.id ?? null,
  });

  return (
    <Card height="100%">
      <StyledLink to={pathToProductDetail}>
        <Stack gap={4} p={4}>
          <Stack gap={2} flexGrow={1} maxWidth={1022}>
            <Text variant="body-bold" size="M">
              {product.name}
            </Text>
            {product.category && (
              <Stack direction="row" alignItems="center" gap={1}>
                <IconWrapper icon={CategoryOutlinedIcon} size="XS" htmlColor={palette.neutral.color2} />
                <Text variant="caption" size="XS" color="text2">
                  {product.category}
                </Text>
              </Stack>
            )}
            <DescriptionText>{product.description ?? '-'}</DescriptionText>
          </Stack>
          <Box alignSelf="flex-start">
            {/* This button doesn't need a onClick callback as long as the whole card is clickable */}
            <Button variant="ghost" color="primary" size="S">
              {t('VENDOR.PRODUCTS_PRICING.VIEW_BENCHMARKS')}
            </Button>
          </Box>
        </Stack>
      </StyledLink>
    </Card>
  );
};

export default SingleProductCard;
