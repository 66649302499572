import { Stack, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

export const FieldCard = ({ children }: PropsWithChildren) => {
  const { palette } = useTheme();

  return (
    <Stack p={4} gap={6} borderRadius={3} bgcolor={palette.input.color1}>
      {children}
    </Stack>
  );
};
