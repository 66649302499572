import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

const FEATURE_SPRIO_RDS_RI = FEATURES.AWS_SPRIO_RDS_RI;
const FEATURE_SPRIO_EC2_RI = FEATURES.AWS_SPRIO_EC2_RI;
const FEATURE_SPRIO_OPENSEARCH_RI = FEATURES.AWS_SPRIO_OPENSEARCH_RI;
const FEATURE_SPRIO_ELASTICACHE_RI = FEATURES.AWS_SPRIO_ELASTICACHE_RI;
const FEATURE_SPRIO_REDSHIFT_RI = FEATURES.AWS_SPRIO_REDSHIFT_RI;
const FEATURE_SPRIO_EC2_SP = FEATURES.AWS_SPRIO_EC2_SP;
const FEATURE_SPRIO_SAGEMAKER_SP = FEATURES.AWS_SPRIO_SAGEMAKER_SP;
const FEATURE_SPRIO_COMPUTE_SP = FEATURES.AWS_SPRIO_COMPUTE_SP;

const DEFAULT_RIO_PURCHASE_DELAY = 72;

interface FeatureProperties {
  purchaseUseDryRun: boolean;
  buyingJourney: boolean;
  purchaseDelay: number;
}

export type RIKeys = 'rds' | 'ec2' | 'oss' | 'elc' | 'redshift';
export type SPKeys = 'ec2' | 'sagemaker' | 'compute';

interface AwsSpRioSubFeaturesData {
  ri: Record<RIKeys, FeatureProperties>;
  sp: Record<SPKeys, FeatureProperties>;
}

interface AwsSpRioSubFeatures {
  isFetching: boolean;
  data: AwsSpRioSubFeaturesData | null;
}

const useAwsSpRioSubFeatures = (): AwsSpRioSubFeatures => {
  const { getFeature, isLoading } = useFeatures();

  if (isLoading) {
    return {
      isFetching: true,
      data: null,
    };
  }

  const getFeatureProperties = (featureKey: string) => ({
    purchaseUseDryRun: getFeature(featureKey)?.properties?.purchaseUseDryRun ?? false,
    buyingJourney: getFeature(featureKey)?.properties?.buyingJourney ?? false,
    purchaseDelay: !isNaN(Number(getFeature(featureKey)?.properties?.purchaseDelay))
      ? Number(getFeature(featureKey)?.properties?.purchaseDelay)
      : DEFAULT_RIO_PURCHASE_DELAY,
  });

  return {
    isFetching: false,
    data: {
      ri: {
        rds: getFeatureProperties(FEATURE_SPRIO_RDS_RI),
        ec2: getFeatureProperties(FEATURE_SPRIO_EC2_RI),
        oss: getFeatureProperties(FEATURE_SPRIO_OPENSEARCH_RI),
        elc: getFeatureProperties(FEATURE_SPRIO_ELASTICACHE_RI),
        redshift: getFeatureProperties(FEATURE_SPRIO_REDSHIFT_RI),
      },
      sp: {
        ec2: getFeatureProperties(FEATURE_SPRIO_EC2_SP),
        sagemaker: getFeatureProperties(FEATURE_SPRIO_SAGEMAKER_SP),
        compute: getFeatureProperties(FEATURE_SPRIO_COMPUTE_SP),
      },
    },
  };
};

export default useAwsSpRioSubFeatures;
