import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { TextFieldCaption } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { Vendor } from '@vertice/core/src/modules/vendor/types';
import FormVendorSelectField from '@vertice/core/src/modules/vendor/formFields/FormVendorSelectField';
import { TaskFormEntry } from './TaskFormEntry';
import { Button } from '@verticeone/design-system';
import { useTaskFormContext } from '../TaskFormContext';
import VendorLogo from '../../../../../../../../components/VendorLogo';

type SelectVendorPanelProps<FormDataType extends FieldValues> = {
  vendorName: FieldPath<FormDataType>;
};

export const SelectVendorPanel = <T extends FieldValues>({ vendorName }: SelectVendorPanelProps<T>) => {
  const { readOnly } = useTaskFormContext();

  const { palette } = useTheme();
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);

  const vendor: Vendor | null = useWatch({ name: vendorName });
  const isPreview = vendor && !edit;

  useEffect(() => {
    if (vendor) {
      setEdit((v) => (v ? !vendor : v));
    }
  }, [vendor]);

  return (
    <Stack direction="column" spacing={2}>
      <TextFieldCaption label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.SELECTED_VENDOR')} size="XS" />

      <Stack
        bgcolor={palette.core.color1}
        p={4}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderRadius={4}
        minHeight={76}
      >
        {isPreview ? (
          <Stack direction="row" spacing={2} alignItems="center">
            <Box padding={1} bgcolor={palette.core.bg} borderRadius={2}>
              <VendorLogo vendorId={vendor.id ?? undefined} />
            </Box>
            <Box flexGrow={1}>
              <Stack>
                <Text variant="body-regular" size="M">
                  {vendor.name ? vendor.name : <CircularProgress size={16} />}
                </Text>
              </Stack>
            </Box>
          </Stack>
        ) : (
          <Stack flex={1}>
            <TaskFormEntry<any, typeof FormVendorSelectField>
              name={vendorName}
              component={FormVendorSelectField}
              componentProps={{
                inlineEnabled: true,
              }}
            />
          </Stack>
        )}

        {isPreview && !readOnly && (
          <Button variant="plain" size="S" onClick={() => setEdit((v) => !v)}>
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.ACTIONS.CHANGE_VENDOR')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
