import React, { useCallback, useMemo, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart as HighchartsChart, type SeriesOptionsType } from 'highcharts';
import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import {
  dayInMonthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import {
  AWS_BRAND_COLOR,
  AWS_DEFAULT_CURRENCY,
  AWS_DEFAULT_CURRENCY_DECIMAL_PLACES,
} from '@vertice/dashboard/src/modules/cloud/constants';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { LegendItemData } from '@vertice/core/src/components/charts/components/Legend/types';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { GridData } from './types';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { useGetColoredItems } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import { ifExpression } from '@verticeone/utils/logic';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import EmptyStateError from '../../../../../modules/cloud/components/EmptyStateError';
import { Loader } from '@verticeone/design-system';
import EmptyStateNoData from '../../../../../modules/cloud/components/EmptyStateNoData';
import { sortTooltipPointsFunction } from '@vertice/core/src/components/charts/components/Tooltip/sortTooltipPointsUtils';
import extractTooltipContent, {
  TooltipItem,
} from '@vertice/core/src/components/charts/components/Tooltip/extractTooltipContent';
import { currencyFormatter } from '@vertice/core/src/components/charts/components/Tooltip/valueFormatters';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';

type LoadableContentWrapperProps = {
  error: unknown;
  isEmpty: boolean;
  isLoading: boolean;
  children: React.ReactNode;
};

const LoadableContentWrapper = ({ error, isEmpty, isLoading, children }: LoadableContentWrapperProps) => {
  const { palette } = useTheme();

  return (
    <Stack
      bgcolor={isEmpty || !!error ? 'transparent' : palette.core.color1}
      padding={6}
      justifyContent="center"
      minHeight={350}
    >
      {!!error ? (
        <EmptyStateError />
      ) : isLoading ? (
        <Loader color={AWS_BRAND_COLOR} />
      ) : isEmpty ? (
        <EmptyStateNoData />
      ) : (
        children
      )}
    </Stack>
  );
};

type CloudAccountsGraphProps = Omit<LoadableContentWrapperProps, 'children'> & {
  data?: GridData;
};

type GraphProps = {
  data: GridData;
};

const Graph = ({ data }: GraphProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.GRAPH' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();

  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const getColoredItems = useGetColoredItems();

  const { dates, values, usedCategories } = data;

  const coloredValues = useCallback(() => {
    const coloredItems = getColoredItems(values) as unknown as SeriesOptionsWithData[];

    return coloredItems.map((item) => ({
      ...item,
      ...ifExpression(item.id === 'start_date', {
        name: t('START_DATE'),
      }),
      ...ifExpression(item.id === 'totalCost', {
        name: t('TOTAL_COST'),
      }),
    }));
  }, [getColoredItems, t, values]);

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: { type: 'spline', height: 250, backgroundColor: 'transparent' },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
            },
          },
          yAxis: {
            labels: { formatter: yLabelCurrencyFormatter(palette, locale, AWS_DEFAULT_CURRENCY, 1, 1) },
          },
          xAxis: {
            categories: dates,
            labels: { formatter: dayInMonthFormatter },
          },
          tooltip: { shared: true },
          series: [...(getSeriesWithBorderRadius(coloredValues(), 0) as SeriesOptionsType[])],
        }),
      ]),
    [applyStyledHighcharts, applyXAxisOffset, palette, locale, dates, coloredValues]
  );

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);

  const legendItems = useMemo<LegendItemData[]>(
    () => extractLegendItemsFromSeries(options, usedCategories),
    [options, usedCategories]
  );

  const tooltipSort = sortTooltipPointsFunction(usedCategories, true);
  const tooltipExtractor = extractTooltipContent(
    tooltipSort,
    currencyFormatter(locale, AWS_DEFAULT_CURRENCY, AWS_DEFAULT_CURRENCY_DECIMAL_PLACES)
  );

  return (
    <>
      <Box marginLeft="auto" marginRight={6}>
        {legendItems.length > 0 && <Legend items={legendItems} />}
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {(context) => {
          const tooltipContent = tooltipExtractor(context);
          return (
            <TooltipWrapper>
              {tooltipContent.items?.map((item: TooltipItem, index: number) => (
                <TooltipSeriesValuePair
                  seriesColor={item.color}
                  seriesName={item.name}
                  value={item.value}
                  key={item.name || index}
                />
              ))}
              <TooltipTotal value={tooltipContent.total} />
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </>
  );
};

const ComparisonDrawerGraph = ({ data, error, isEmpty, isLoading }: CloudAccountsGraphProps) => {
  return (
    <LoadableContentWrapper isLoading={isLoading} isEmpty={isEmpty} error={error}>
      <Graph data={data!} />
    </LoadableContentWrapper>
  );
};

export default ComparisonDrawerGraph;
