import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Tooltip } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { useFormatDate } from '@verticeone/design-system';

import { RequestRow } from '../../types';
import { STATUS_FILTER_MAP } from '../filter/types';
import TwoRowCell from './TwoRowCell';
import { ComponentProps } from 'react';

type KeyDateNameProperties =
  | 'targetSignDate'
  | 'autoRenewalDate'
  | 'renewalDate'
  | 'completionDate'
  | 'cancellationDate';

type KeyDate = {
  label?: string;
  name: KeyDateNameProperties;
  value: string;
};

export const getKeyDateLabel = (name: KeyDateNameProperties) => {
  switch (name) {
    case 'targetSignDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.TARGET_SIGN_DATE';
    case 'autoRenewalDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.AUTO_RENEWAL';
    case 'renewalDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.RENEWAL';
    case 'completionDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.COMPLETED';
    case 'cancellationDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.CANCELLED';
    default:
      return '-';
  }
};

const sortKeyDates = (keyDates: RequestRow['keyDates']) => {
  const mappedEntries = Object.entries(keyDates ?? {})
    .map(([name, value]) => (value ? { name, value } : null))
    .filter((item): item is KeyDate => item !== null);

  const renewalEntries = mappedEntries.filter(({ name }) => name === 'renewalDate' || name === 'autoRenewalDate');
  const otherEntries = mappedEntries
    .filter(({ name }) => name !== 'renewalDate' && name !== 'autoRenewalDate')
    .sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime());

  return [...renewalEntries, ...otherEntries];
};

export const getKeyDates = ({ status, closedAt, lastActivityAt, keyDates }: KeyDateCellProps['row']): KeyDate[] => {
  const isCompleted = STATUS_FILTER_MAP['completed'].includes(status);
  const isCancelled = STATUS_FILTER_MAP['cancelled'].includes(status);

  const injectedKeyDates = {
    ...keyDates,
    completionDate: isCompleted ? closedAt ?? lastActivityAt ?? '-' : undefined,
    cancellationDate: isCancelled ? closedAt ?? lastActivityAt ?? '-' : undefined,
  };

  return sortKeyDates(injectedKeyDates).map(({ name, value }) => ({
    label: getKeyDateLabel(name),
    name,
    value,
  }));
};

type KeyDateCellProps = {
  row: Pick<RequestRow, 'status' | 'closedAt' | 'lastActivityAt' | 'keyDates'>;
};

const getColumnKeyDate = (status: RequestRow['status'], keyDates: KeyDate[]) => {
  if (status === 'COMPLETED') return keyDates.find(({ name }) => name === 'completionDate');
  if (status === 'CANCELLED') return keyDates.find(({ name }) => name === 'cancellationDate');

  return keyDates.find(({ name }) => name === 'targetSignDate') ?? keyDates[0];
};

export const KeyDateCell = ({ row }: KeyDateCellProps) => {
  const formatDate = useFormatDate();
  const { t } = useTranslation();
  const keyDates = getKeyDates(row);

  const isEmpty = keyDates.length === 0;
  const columnKeyDate = getColumnKeyDate(row.status, keyDates);
  const formattedDate = !isEmpty ? formatDate(columnKeyDate?.value ?? '') : '-';

  const cell = <TwoRowCell subtitle={!isEmpty ? t(columnKeyDate?.label ?? '') : undefined} title={formattedDate!} />;

  return (
    <Tooltip
      title={t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.KEY_DATES')}
      size="S"
      placement="bottom"
      content={<KeyDateTooltipContent keyDates={keyDates} />}
      disableHoverListener={keyDates.length <= 1}
    >
      <Stack width="100%">{cell}</Stack>
    </Tooltip>
  );
};

type KeyDateTooltipContentProps = {
  keyDates: KeyDate[];
};

const KeyDateTooltipContent = ({ keyDates }: KeyDateTooltipContentProps) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const rowTextProps: ComponentProps<typeof Text> = {
    variant: 'body-regular',
    color: 'text4',
    size: 'S',
  };

  if (!keyDates || keyDates.length === 0) return null;

  return (
    <Stack direction="column">
      {keyDates.map(({ label, value }) =>
        label ? (
          <Stack direction="row" key={[label, value].join()}>
            <Text {...rowTextProps} width="150px">
              {t(label)}
            </Text>
            <Text {...rowTextProps}>{formatDate(value)}</Text>
          </Stack>
        ) : null
      )}
    </Stack>
  );
};
