import { PropsWithChildren } from 'react';
import { Drawer } from '@verticeone/design-system';
import { useDataSource } from '../../../DataSourceProvider';
import { ShoppingCartProvider } from '../providers/ShoppingCartProvider';

const OFFSET = 64;

const BuySPDrawer = ({ children }: PropsWithChildren) => {
  const {
    buyDrawer: { isOpened, close },
  } = useDataSource();

  return (
    <Drawer width={`calc(100% - ${OFFSET}px)`} open={isOpened} onClose={close}>
      <ShoppingCartProvider>{children}</ShoppingCartProvider>
    </Drawer>
  );
};

export default BuySPDrawer;
