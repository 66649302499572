import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { EllipsisText } from '@verticeone/design-system';
import { useWindowDimensions } from '@verticeone/design-system';
import { ComponentPropsWithRef, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TaskDescriptionProps = {
  description: string | null | undefined;
  maxRows?: number;
  defaultOpened?: boolean;
};

export const TaskDescription = ({ description, maxRows = 2, defaultOpened = false }: TaskDescriptionProps) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isExpanded, setIsExpanded] = useState(defaultOpened);
  const contentRef = useRef<HTMLSpanElement>(null);

  const { width } = useWindowDimensions();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK' });

  useLayoutEffect(() => {
    if (!contentRef.current || !description) return;

    setIsTruncated(contentRef.current.scrollHeight > contentRef.current.clientHeight);
  }, [contentRef, width, description]);

  const handleExpandButtonClick = () => setIsExpanded((prev) => !prev);

  if (!description) return null;

  const baseTextProps: ComponentPropsWithRef<typeof Text> = {
    variant: 'body-regular',
    size: 'S',
    color: 'text2',
    whiteSpace: 'break-spaces',
  };

  return (
    <Stack gap={1}>
      {!isExpanded ? (
        <EllipsisText {...baseTextProps} lineClamp={maxRows} ref={contentRef}>
          {description}
        </EllipsisText>
      ) : (
        <Text {...baseTextProps}>{description}</Text>
      )}
      <Stack justifyContent={'start'} alignItems={'start'}>
        {isTruncated && (
          <Button
            onClick={handleExpandButtonClick}
            color="neutral"
            size="S"
            variant="plain"
            sx={{ padding: '0 !important' }} // Figma has ignored that these have any padding, overriding from here
            endIcon={isExpanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
          >
            {isExpanded ? t('COLLAPSE_DESCRIPTION') : t('EXPAND_DESCRIPTION')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
