import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import { NoData } from '@verticeone/design-system';

const NoDataWrapper = styled('div')({
  display: 'flex',
  maxWidth: '30rem',
  height: '100%',
  margin: 'auto',
});

export const NoRows = () => {
  const { t } = useTranslation();

  return (
    <NoDataWrapper>
      <NoData header={t(`APPLICATIONS.USAGE.USERS_GRID.NO_DATA.HEADER`)} />
    </NoDataWrapper>
  );
};
