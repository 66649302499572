import { Card } from '@verticeone/design-system';
import { Skeleton, Stack } from '@mui/material';

const SingleProductCardSkeleton = () => {
  return (
    <Card p={4} gap={4} height="100%">
      <Stack gap={2} flexGrow={1} maxWidth={700}>
        <Skeleton width="30%" height="30px" />
        <Stack>
          <Skeleton width="100%" />
          <Skeleton width="100%" />
          <Skeleton width="100%" />
          <Skeleton width="40%" />
        </Stack>
      </Stack>
      <Skeleton width="80px" height="18px" variant="rounded" />
    </Card>
  );
};

export default SingleProductCardSkeleton;
