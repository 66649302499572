import { Button } from '@verticeone/design-system';
import { FilterView, FilterViewWithCount, PREDEFINED_VIEW_ALL_NON_CANCELLED } from '../types';
import { Text } from '@verticeone/design-system';
import { DesignSystemColor } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { IconWrapper } from '@verticeone/design-system';
import { BaseButtonGroup } from '@verticeone/design-system';

type FilterViewButtonProps = {
  filterViewWithCount: FilterViewWithCount;
  active: boolean;
  onClick: () => void;
  onEditClick?: () => void;
};

const generateColor = (filterView: FilterView) =>
  filterView === PREDEFINED_VIEW_ALL_NON_CANCELLED ? 'primary' : ('neutral' as DesignSystemColor);
const generateVariant = (active: boolean) => (active ? 'solid' : 'ghost');

// ENTITIES.CONTRACT.LABELS.LIST_FILTERS.AWAITING_APPROVAL => filter-button-awaiting-approval
const filterViewToTestId = (filterView: FilterView) =>
  `filter-button-${filterView.type === 'custom' ? `custom-${filterView.id}` : kebabCase(filterView.key)}`;

export const FilterViewButton = ({
  filterViewWithCount: { filterView, count },
  active,
  onClick,
  onEditClick,
}: FilterViewButtonProps) => {
  const { t } = useTranslation();
  const variant = generateVariant(active);
  const color = generateColor(filterView);

  const mainButton = (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      aria-pressed={active}
      testId={filterViewToTestId(filterView)}
    >
      <Text variant="button-bold" size="S">
        {filterView.type === 'custom' ? filterView.label : t(filterView.labelToTranslate)}
      </Text>
      <Text variant="button-bold" size="S" sx={{ opacity: 0.4 }}>
        {count}
      </Text>
    </Button>
  );

  return onEditClick ? (
    <BaseButtonGroup
      role="group"
      $direction="horizontal"
      $container={false}
      $variant={variant}
      $color={color}
      $size="S"
    >
      {mainButton}
      <Button variant={variant} color={color} onClick={onEditClick} testId={`edit-${filterViewToTestId(filterView)}`}>
        <IconWrapper icon={EditOutlined} size="S" />
      </Button>
    </BaseButtonGroup>
  ) : (
    mainButton
  );
};
