import {
  defaultOperators as reactQueryBuilderDefaultOperators,
  type Field,
  FullOperator,
  OptionList,
} from 'react-querybuilder';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Property } from '../../../types';

type FieldSettings = {
  operators: FullOperator[];
  inputType: Field['inputType'];
  valueEditorType: Field['valueEditorType'];
  defaultValue?: Field['defaultValue'];
  values?: OptionList;
  xType?: string;
};

type OperatorKey = 'number' | 'boolean' | 'string' | 'date' | 'default';
type XTypeOperatorKey = 'select';

type OperatorsMap = Record<OperatorKey, FullOperator[]>;
type XTypeOperatorsMap = Record<XTypeOperatorKey, FullOperator[]>;

const arraysHaveIntersection = (array1?: string[], array2?: string[]) => {
  if (!array1 || !array2) {
    return false;
  }

  const set2 = new Set(array2);
  return array1.some((element) => set2.has(element));
};

const getOnlyAllowedOperatorsFromDefault = (defaultOperators: FullOperator[], allowedOperators: string[]) => {
  if (!allowedOperators.length) {
    return defaultOperators;
  }

  return defaultOperators.filter((operator) => {
    /*
     * This exception is needed because allowedOperators are JsonLogic operators and defaultOperators are react-querybuilder operators.
     * All other operators are the same.
     */
    if (operator.value === '=') {
      return allowedOperators.includes('==');
    }

    return allowedOperators.includes(operator.value);
  });
};

const getFieldSettingsForVariable = ({
  variable,
  operators,
  xTypeOperators,
}: {
  variable: Property;
  operators: OperatorsMap;
  xTypeOperators: XTypeOperatorsMap;
}): FieldSettings => {
  const allowedOperators = variable.operators || [];

  if (arraysHaveIntersection(variable.type, ['string'])) {
    if (variable.xType && variable.values === 'ProvidedAsyncByXTypeDataSource') {
      return {
        operators: getOnlyAllowedOperatorsFromDefault(xTypeOperators.select, allowedOperators),
        inputType: 'async-select',
        valueEditorType: 'select',
        values: [],
        xType: variable.xType,
      };
    }

    if (Array.isArray(variable.values)) {
      return {
        operators: getOnlyAllowedOperatorsFromDefault(operators.boolean, allowedOperators),
        inputType: 'text',
        valueEditorType: 'select',
        values: variable.values,
      };
    }

    if (variable.format === 'date') {
      return {
        operators: getOnlyAllowedOperatorsFromDefault(operators.date, allowedOperators),
        inputType: 'date',
        valueEditorType: 'text',
      };
    }

    return {
      operators: getOnlyAllowedOperatorsFromDefault(operators.string, allowedOperators),
      inputType: 'text',
      valueEditorType: 'text',
    };
  }

  if (arraysHaveIntersection(variable.type, ['number'])) {
    return {
      operators: getOnlyAllowedOperatorsFromDefault(operators.number, allowedOperators),
      inputType: 'number',
      valueEditorType: 'text',
    };
  }

  if (arraysHaveIntersection(variable.type, ['boolean'])) {
    return {
      operators: getOnlyAllowedOperatorsFromDefault(operators.boolean, allowedOperators),
      inputType: 'checkbox',
      valueEditorType: 'select',
      defaultValue: true,
      values: [
        { label: 'True', value: 'true', name: 'true' },
        { label: 'False', value: 'false', name: 'false' },
      ],
    };
  }

  return {
    operators: getOnlyAllowedOperatorsFromDefault(operators.default, allowedOperators),
    inputType: 'text',
    valueEditorType: 'text',
  };
};

const createFlatOptionListFromVariables = ({
  variables,
  operators,
  xTypeOperators,
}: {
  variables: Property[];
  operators: OperatorsMap;
  xTypeOperators: XTypeOperatorsMap;
}): Field[] => {
  const optionList: Field[] = [];

  const processVariable = (variable: Property) => {
    optionList.push({
      id: variable.id,
      name: variable.id,
      label: variable.label,
      ...getFieldSettingsForVariable({ variable, operators, xTypeOperators }),
    });

    if ('properties' in variable && Array.isArray(variable.properties)) {
      for (const property of variable.properties) {
        processVariable(property);
      }
    }
  };

  for (const variable of variables) {
    processVariable(variable);
  }

  return optionList;
};

const useLocalizedOperators = (): OperatorsMap => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.OPERATORS',
  });

  return useMemo(
    () => ({
      number: [
        { label: t('NUMBER.EQUAL'), value: '=', name: '=' },
        { label: t('NUMBER.NOT_EQUAL'), value: '!=', name: '!=' },
        { label: t('NUMBER.GREATER_THAN'), value: '>', name: '>' },
        { label: t('NUMBER.LESS_THAN'), value: '<', name: '<' },
        { label: t('NUMBER.GREATER_THAN_OR_EQUAL'), value: '>=', name: '>=' },
        { label: t('NUMBER.LESS_THAN_OR_EQUAL'), value: '<=', name: '<=' },
      ],
      boolean: [
        { label: t('BOOLEAN.EQUAL'), value: '=', name: '=' },
        { label: t('BOOLEAN.NOT_EQUAL'), value: '!=', name: '!=' },
      ],
      date: [
        { label: t('DATE.EQUAL'), value: '=', name: '=' },
        { label: t('DATE.NOT_EQUAL'), value: '!=', name: '!=' },
        { label: t('DATE.GREATER_THAN'), value: '>', name: '>' },
        { label: t('DATE.LESS_THAN'), value: '<', name: '<' },
        { label: t('DATE.GREATER_THAN_OR_EQUAL'), value: '>=', name: '>=' },
        { label: t('DATE.LESS_THAN_OR_EQUAL'), value: '<=', name: '<=' },
      ],
      string: [
        { label: t('STRING.EQUAL'), value: '=', name: '=' },
        { label: t('STRING.NOT_EQUAL'), value: '!=', name: '!=' },
        { label: t('STRING.CONTAINS'), value: 'contains', name: 'contains' },
        { label: t('STRING.DOES_NOT_CONTAIN'), value: 'doesNotContain', name: 'doesNotContain' },
        { label: t('STRING.BEGINS_WITH'), value: 'beginsWith', name: 'beginsWith' },
        { label: t('STRING.DOES_NOT_BEGIN_WITH'), value: 'doesNotBeginWith', name: 'doesNotBeginWith' },
        { label: t('STRING.ENDS_WITH'), value: 'endsWith', name: 'endsWith' },
        { label: t('STRING.DOES_NOT_END_WITH'), value: 'doesNotEndWith', name: 'doesNotEndWith' },
      ],
      default: reactQueryBuilderDefaultOperators,
    }),
    [t]
  );
};

/*
 * XType operators are used for variables that have a special type (xType) that requires a specific set of operators.
 */
const useLocalizedXTypeOperators = (): XTypeOperatorsMap => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EXPRESSION_BUILDER.OPERATORS',
  });

  return useMemo(
    () => ({
      select: [
        { label: t('BOOLEAN.EQUAL'), value: '=', name: '=' },
        { label: t('BOOLEAN.NOT_EQUAL'), value: '!=', name: '!=' },
      ],
    }),
    [t]
  );
};

export const useFieldsFromVariables = (variables: Property[]): Field[] => {
  const localizedOperators = useLocalizedOperators();
  const localizedXTypeOperators = useLocalizedXTypeOperators();

  return useMemo(
    () =>
      createFlatOptionListFromVariables({
        variables,
        operators: localizedOperators,
        xTypeOperators: localizedXTypeOperators,
      }),
    [variables, localizedOperators, localizedXTypeOperators]
  );
};
