import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import {
  Button,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderDescription,
  CardHeaderTitle,
  Text,
} from '@verticeone/design-system';
import { IntegrationSetupDialog } from 'pages/Preferences/Integrations/components/IntegrationSetupDialog';
import { EDIT_MODE_DISABLED, JIRA_PROVIDER_ID } from '../constants';
import { useTestConnection } from '../hooks/useTestConnection';
import { EditCardProps, JiraSetupStep, StepContentProps } from '../types';
import { ConnectionForm } from './ConnectionForm';

type ConnectionCardProps = StepContentProps & EditCardProps;

type ConnectionFormType =
  | {
      domain: string;
      username: string;
    }
  | undefined;

export const ConnectionCard = ({ activeStep, setStep, cardEditMode, setCardEditMode }: ConnectionCardProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();

  const { pollingInterval, setPollingInterval, testConnection } = useTestConnection();
  const isTestingConnection = pollingInterval > 0;
  const { data: integrationData } = useGetAccountIntegrationQuery(
    {
      accountId,
      integrationId: JIRA_PROVIDER_ID,
    },
    { pollingInterval: isTestingConnection ? pollingInterval : undefined }
  );

  useEffect(() => {
    const connectionStatus = integrationData?.connection?.status;

    if (connectionStatus === 'OK' && isTestingConnection) {
      if (cardEditMode.connection) {
        setCardEditMode(EDIT_MODE_DISABLED);
      }
      setPollingInterval(0);
      enqueueSnackbar({
        description: t('PREFERENCES.INTEGRATIONS.JIRA.SNACKBAR.TEST_CONNECTION_SUCCESS_DESC'),
        variant: 'success',
      });
      setStep(JiraSetupStep.CONFIGURATION);
    }

    if (connectionStatus === 'FAILED' && isTestingConnection) {
      setPollingInterval(0);
      enqueueSnackbar({
        message: t('PREFERENCES.INTEGRATIONS.JIRA.SNACKBAR.TEST_CONNECTION_ERROR_MESSAGE'),
        description: t('PREFERENCES.INTEGRATIONS.JIRA.SNACKBAR.TEST_CONNECTION_ERROR_DESC'),
        variant: 'error',
      });
      setStep(JiraSetupStep.CONNECTION);
    }
  }, [
    cardEditMode.connection,
    enqueueSnackbar,
    isTestingConnection,
    integrationData?.connection?.status,
    setCardEditMode,
    setPollingInterval,
    setStep,
    t,
  ]);

  const [isConnectionEditDialogOpened, setIsConnectionEditDialogOpened] = useState(false);

  const editConnection = () => {
    if (isSuccessStep) {
      setIsConnectionEditDialogOpened(true);
      return;
    }

    setStep(JiraSetupStep.CONNECTION);
  };

  const handleEditConnectionSubmit = () => {
    setCardEditMode({ ...EDIT_MODE_DISABLED, connection: true });
    setIsConnectionEditDialogOpened(false);
  };

  const isConnectionStep = activeStep === JiraSetupStep.CONNECTION;
  const isSuccessStep = activeStep === JiraSetupStep.SUCCESS;
  const isFormCompleted = !isConnectionStep && !cardEditMode.connection && !isTestingConnection;

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle icon={SettingsOutlinedIcon} text={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.TITLE')} />
        <CardHeaderDescription>
          <Text variant="body-regular" size="S" color="text2">
            {t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.DESCRIPTION')}
          </Text>
        </CardHeaderDescription>
        {isFormCompleted && (
          <CardHeaderActions>
            <Button variant="outline" size="S" color="primary" onClick={editConnection}>
              {t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_CARD.ACTION_BUTTON')}
            </Button>
            <IntegrationSetupDialog
              header={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_EDIT_DIALOG.HEADER')}
              content={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_EDIT_DIALOG.CONTENT')}
              cancelButtonLabel={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_EDIT_DIALOG.CANCEL_BUTTON')}
              buttonLabel={t('PREFERENCES.INTEGRATIONS.JIRA.CONNECTION_EDIT_DIALOG.SUBMIT_BUTTON')}
              isOpened={isConnectionEditDialogOpened}
              onSubmit={handleEditConnectionSubmit}
              onClose={() => setIsConnectionEditDialogOpened(false)}
            />
          </CardHeaderActions>
        )}
      </CardHeader>
      <Stack gap={1} p={6}>
        <ConnectionForm
          defaultValues={{
            workspaceUrl: (integrationData?.parameters?.connectionForm as ConnectionFormType)?.domain ?? '',
            administratorEmail: (integrationData?.parameters?.connectionForm as ConnectionFormType)?.username ?? '',
            apiKey: '',
          }}
          onSubmit={testConnection}
          activeStep={activeStep}
          isLoading={isTestingConnection}
          isCompleted={isFormCompleted}
          cardEditMode={cardEditMode}
          setCardEditMode={setCardEditMode}
        />
      </Stack>
    </Card>
  );
};
