import { useFormatDate } from '@verticeone/utils/formatting';
import { Text } from '@verticeone/design-system';
import AdditionalInfo from '../../Deadline/AdditionalInfo';
import { Stack } from '@mui/material';

export type DateCellWithAdditionalInfoProps = {
  date: string;
};

export const TargetSignDateCell = ({ date }: DateCellWithAdditionalInfoProps) => {
  const formatDate = useFormatDate();
  return date ? (
    <Stack>
      <Text variant="body-regular">{formatDate(date)}</Text>
      <AdditionalInfo date={date} />
    </Stack>
  ) : (
    <Text variant="body-regular" size="M" color="inactive2">
      —
    </Text>
  );
};
