import { ReactNode } from 'react';
import { styled } from '@mui/material';
import { Text, DesignSystemSize } from '@verticeone/design-system';

const HighlightedText = styled(Text)(({ theme }) => ({
  color: theme.palette.secondary.color1,
  backgroundColor: theme.palette.secondary.color4,
  borderRadius: 1,
}));

export const highlightText = (
  inputString: string,
  searchText: string,
  size: DesignSystemSize = 'S'
): string | ReactNode[] => {
  if (!searchText) {
    return inputString;
  }

  const regex = new RegExp(`(${searchText})`, 'gi');
  const parts = inputString.split(regex);

  return parts.map((part, index) =>
    part.toLowerCase() === searchText.toLowerCase() ? (
      <HighlightedText variant="button-bold" size={size} key={index}>
        {part}
      </HighlightedText>
    ) : (
      part
    )
  );
};
