import React from 'react';
import { FormControl, Grid, Stack } from '@mui/material';
import { TextFieldCaption } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useTaskFormContext } from '../TaskFormContext';

const SIZE = 'S';

export const PurchaseRequirements = () => {
  const { t } = useTranslation();
  const { readOnly } = useTaskFormContext();
  return (
    <Grid item xs={12}>
      <Stack>
        <TextFieldCaption label={t('INTAKE_FORM.PURCHASE_REQUIREMENTS.LABEL')} size="XS" />
        <FormControl variant="outlined">
          <FormChecklistItemField
            name="legalReviewRequired"
            label={t('INTAKE_FORM.PURCHASE_REQUIREMENTS.LEGAL_REVIEW_REQUIRED')}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            size={SIZE}
            disabled={readOnly}
          />
        </FormControl>

        <FormControl variant="outlined">
          <FormChecklistItemField
            name="securityReviewRequired"
            label={t('INTAKE_FORM.PURCHASE_REQUIREMENTS.SECURITY_REVIEW_REQUIRED')}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            size={SIZE}
            disabled={readOnly}
          />
        </FormControl>

        <FormControl variant="outlined">
          <FormChecklistItemField
            name="piiExpectedToBeHeld"
            label={t('INTAKE_FORM.PURCHASE_REQUIREMENTS.PII_TO_BE_HELD')}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            size={SIZE}
            disabled={readOnly}
          />
        </FormControl>
      </Stack>
    </Grid>
  );
};
