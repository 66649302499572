import {
  RiResult,
  SpPriceType,
  SpResult,
  SpriPurchase,
  SpriPurchasesResult,
  useSpRiPurchaseListQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useMemo } from 'react';
import { LoadableAdvanced } from '@verticeone/utils/async';
import getRICost, { PAYMENT_OPTIONS, Price } from '../../utils/getRiCost';
import dayjs from 'dayjs';
import { omitEmptyValues } from '@verticeone/utils/objects';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const SPServiceMap = {
  AWS_SP_COMPUTE: 'Compute SP',
  AWS_SP_EC2: 'EC2 SP',
  AWS_SP_SAGE_MAKER: 'SageMaker SP',
} as const;

const RIServiceMap = {
  AWS_RI_EC2: 'EC2 RI',
  AWS_RI_RDS: 'RDS RI',
};

const RITypeMap = {
  AWS_RI_EC2: 'ec2',
  AWS_RI_RDS: 'rds',
};

const SPTypeMap = {
  AWS_SP_COMPUTE: 'Compute',
  AWS_SP_EC2: 'EC2',
  AWS_SP_SAGE_MAKER: 'SageMaker',
};

export type RIDetails = {
  offeringClass: string;
  tenancy: string;
  familySize: string;
  quantity: number;
};

export type ReservedInstance = {
  id: string;
  type: 'ri' | 'sp';
  region: string;
  paymentOption: string;
  term: number;
  totalCost: number;
  dateOfExecution?: string;
  service: string;
  spType?: string;
  riType?: string;
  purchasePlannedAt?: string;
  scheduledAt?: string;
  purchasedAt?: string;
  details?: RIDetails;
  nextToken?: string;
};

export type ReservedInstancesResult = {
  items?: ReservedInstance[];
  nextToken?: string;
};

const getSpCost = (paymentOption?: string, duration?: number, price?: SpPriceType) => {
  if (!paymentOption || !duration || !price) {
    return 0;
  }

  const calculatedPrice = price.commitment * (duration / 60 / 60);

  const upfrontCost = price.upfrontPaymentAmount || 0;

  if (paymentOption === PAYMENT_OPTIONS.PARTIAL_UPFRONT) {
    return upfrontCost + calculatedPrice;
  }

  if (paymentOption === PAYMENT_OPTIONS.ALL_UPFRONT) {
    return upfrontCost;
  }

  return calculatedPrice;
};

const processRiItem = (item: SpriPurchase) => {
  const details = item.details as RiResult;

  return {
    id: item.id,
    type: 'ri',
    totalCost: getRICost(details?.offering?.type, details?.instance?.count, details?.duration, details?.price as Price),
    region: details?.region,
    scheduledAt: item.createdAt,
    purchasePlannedAt: item.purchasePlannedAt,
    purchasedAt: item.purchasedAt,
    riType: (details?.service && RITypeMap[details?.service as keyof typeof RITypeMap]) ?? details?.service,
    service: (details?.service && RIServiceMap[details?.service as keyof typeof RIServiceMap]) ?? details?.service,
    term: details?.duration ? Math.round(dayjs.duration(details.duration * 1000).asMonths()) : 0,
    paymentOption: details?.offering?.type,
    details: {
      offeringClass: details?.offering?.class,
      tenancy: details?.instance?.tenancy,
      familySize: details?.instance?.type,
      quantity: details?.instance?.count,
    },
  };
};

const processSpItem = (item: SpriPurchase) => {
  const details = item.details as SpResult;

  return {
    id: item.id,
    type: 'sp',
    totalCost: getSpCost(details?.offering?.type, details?.duration, details?.price as SpPriceType),
    region: details?.region,
    scheduledAt: item.createdAt,
    purchasePlannedAt: item.purchasePlannedAt,
    spType: (details?.service && SPTypeMap[details?.service as keyof typeof SPTypeMap]) ?? details?.service,
    purchasedAt: item.purchasedAt,
    service: (details?.service && SPServiceMap[details?.service as keyof typeof SPServiceMap]) ?? details?.service,
    term: details?.duration ? Math.round(dayjs.duration(details.duration * 1000).asMonths()) : 0,
    paymentOption: details?.offering?.type,
  };
};

export const processQueryData = (reservedInstancesItems: SpriPurchasesResult) => {
  const { items, nextToken } = reservedInstancesItems || {};

  const resultItems = items?.map((item) =>
    item.details?.__typename === 'RIResult' ? processRiItem(item) : processSpItem(item)
  ) as ReservedInstance[];

  return { items: resultItems, nextToken };
};

type RiPurchaseDataProps = {
  isPending?: boolean;
  nextToken?: string | null;
};

const useRIPurchaseData = ({ isPending = false, nextToken = null }: RiPurchaseDataProps = {}): LoadableAdvanced<
  ReservedInstancesResult | undefined
> => {
  const { accountId } = useAccountContext();

  const { data, error, isLoading, refetch } = useSpRiPurchaseListQuery(
    omitEmptyValues({
      accountId: accountId!,
      limit: 100,
      status: isPending ? 'QUEUED' : 'FINISHED',
      nextToken,
    })
  );

  const processedData = useMemo(() => {
    if (
      !data ||
      data?.spriPurchasesQuery?.__typename !== 'SPRIPurchasesResult' ||
      !data.spriPurchasesQuery?.items ||
      data.spriPurchasesQuery?.items.length === 0
    )
      return;

    return processQueryData(data.spriPurchasesQuery);
  }, [data]);

  return {
    error: error,
    isEmpty: Boolean(processedData?.items && processedData.items?.length === 0),
    isLoading,
    data: processedData,
    refetch,
  };
};

export default useRIPurchaseData;
