import { isVerticeServiceConfiguration } from '../../../definitions/taskDefinition';
import { Task } from '../../../definitionsTypes';
import { getLastPartDiversioned } from '../../../utils';
import { TaskState } from '../../model/types';

const EDITABLE_SERVICE_CONFIGURATIONS = ['service/jira/createTicket', 'service/jira/syncTicket'];
export const CREATE_JIRA_TICKET_TASK_URN_SUFFIX = 'service/jira/createTicket';

/**
 * Among its configurations, there is some which can be configured via editable drawer
 */
export const checkServiceTaskIsEditable = (task: Task) => {
  return task.configurations
    ? task.configurations.some((conf) => {
        if (isVerticeServiceConfiguration(conf)) {
          const serviceType = getLastPartDiversioned(conf.resourceUrn);
          return serviceType && EDITABLE_SERVICE_CONFIGURATIONS.includes(serviceType);
        }
        return false;
      })
    : false;
};

export const checkServiceTaskHasDetail = (state?: TaskState) => {
  const serviceType = getLastPartDiversioned(state?.resourceUrn);

  if (!serviceType) return false;

  return serviceType === CREATE_JIRA_TICKET_TASK_URN_SUFFIX;
};
