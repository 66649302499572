import { useTranslation } from 'react-i18next';
import { Divider, Stack, useTheme } from '@mui/material';

import {
  DataHealthCheckSeverity,
  DataHealthCheckStatus,
  DataHealthStatus,
} from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import { Card, CardHeader } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { DataHealthItemWrapper } from './DataHealthItemWrapper';
import { DataHealthType } from './types';

type DataHealthProps = {
  application?: ApplicationWithMeasureReports;
};

const DataHealth = ({ application }: DataHealthProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { dataHealth } = application || {};

  const hasHealthIssues = dataHealth?.status === DataHealthStatus.IssuesDetected;
  const warningList = dataHealth?.checks
    ?.filter(
      (check) => check.status === DataHealthCheckStatus.Failed && check.severity === DataHealthCheckSeverity.Warning
    )
    .sort((a, b) => a.priority - b.priority);
  const infoList = dataHealth?.checks
    ?.filter(
      (check) => check.status === DataHealthCheckStatus.Failed && check.severity === DataHealthCheckSeverity.Info
    )
    .sort((a, b) => a.priority - b.priority);
  const successList = dataHealth?.checks
    ?.filter((check) => check.status === DataHealthCheckStatus.Passed || check.status === DataHealthCheckStatus.Ignored)
    .sort((a, b) => a.priority - b.priority);

  const warningCount = warningList?.length || 0;
  const infoCount = infoList?.length || 0;
  const successCount = successList?.length || 0;

  return (
    <Card>
      <CardHeader>
        <Stack spacing={1}>
          <Text variant="heading" size="S">
            {t('APPLICATIONS.TOOLTIPS.DATA_HEALTH.TITLE')}
          </Text>
          {hasHealthIssues && (
            <Text variant="body-regular" size="S" color={palette.text.color2}>
              {t('APPLICATIONS.TOOLTIPS.DATA_HEALTH.SUB_TITLE')}
            </Text>
          )}
        </Stack>
      </CardHeader>
      <Stack spacing={1} paddingTop={1} paddingBottom={1}>
        <DataHealthItemWrapper
          type={DataHealthType.WARNING}
          list={warningList}
          count={warningCount}
          application={application}
        />
        <DataHealthItemWrapper type={DataHealthType.INFO} list={infoList} count={infoCount} application={application} />
        {hasHealthIssues && <Divider />}
        <DataHealthItemWrapper type={DataHealthType.SUCCESS} list={successList} count={successCount} />
      </Stack>
    </Card>
  );
};

export default DataHealth;
