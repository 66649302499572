import React, { useCallback } from 'react';
import { useResizeObserver } from '@verticeone/design-system';
import { UpdateOptionsFn } from '../types';
import { mergeOptions } from '../utils/optionsUtils';

type UseResponsivePieSizeHookProps = {
  containerRef: React.MutableRefObject<Element>;
  maxWidth?: number;
  maxInnerSizePct?: number;
};

const useResponsivePieSize = ({
  containerRef,
  maxWidth = 254,
  maxInnerSizePct = 85,
}: UseResponsivePieSizeHookProps) => {
  const { width: containerWidth } = useResizeObserver(containerRef);
  return useCallback<UpdateOptionsFn>(
    (options) =>
      mergeOptions(
        {
          plotOptions: {
            pie: {
              size: Math.min(containerWidth * 0.3, maxWidth),
              innerSize: Math.min(Math.round(containerWidth / 9), maxInnerSizePct) + '%',
            },
            series: {
              dataLabels: {
                style: {
                  width: containerWidth / 4,
                },
              },
            },
          },
        },
        options
      ),
    [containerWidth, maxInnerSizePct, maxWidth]
  );
};

export default useResponsivePieSize;
