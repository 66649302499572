// import { Stack } from '@mui/material';
import { GridGroupingColDefOverride } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataGridColDef } from '@verticeone/design-system';
// import { Tooltip, VerticeVIcon } from '@verticeone/design-system';
// import { SlackIconWOBG } from '../../../integrations/slack/icons';
// import { isSlackRequestor, isVerticeRequestor } from '../utils/requestorUtils';
import { ActionCells } from './components/cells/ActionCells';
import { AssigneesCell } from './components/cells/AssigneesCell';
import { DateCell } from './components/cells/DateCell';
import { ParentGroupingCell } from './components/cells/GroupCells';
import { getKeyCosts, KeyCostCell } from './components/cells/KeyCostCell';
import { getKeyDates, KeyDateCell } from './components/cells/KeyDateCell';
import { RequestStatusCell } from './components/cells/RequestStatusCell';
import {
  ActionsSkeletonCell,
  AssigneesSkeletonCell,
  BaseSkeletonCell,
  RequestSkeletonCell,
} from './components/cells/SkeletonCells';
import { RequestRow } from './types';

type RequestColumnColDef = DataGridColDef<RequestRow>;

type OrderableDataGridColDef = RequestColumnColDef & {
  order?: number;
};

const useCommonColumns = (): OrderableDataGridColDef[] => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUESTS_LIST' });

  return useMemo(() => {
    const columns: OrderableDataGridColDef[] = [
      {
        field: 'lastActivityAt',
        headerName: t('COLUMNS.LAST_ACTIVITY_AT'),
        valueGetter: ({ row }) => row.lastActivityAt || undefined,
        renderCell: ({ value }) => <DateCell date={value} />,
        renderSkeletonCell: () => <BaseSkeletonCell />,
        align: 'left',
        headerAlign: 'left',
        minWidth: 175,
        disableColumnMenu: true,
      },
      {
        field: 'status',
        headerName: t('COLUMNS.STATUS'),
        renderCell: ({ row }) =>
          row.isPartial ? (
            <BaseSkeletonCell />
          ) : (
            <RequestStatusCell
              status={row.status!}
              customValue={row.resultType}
              activeTasks={row.activeTasks}
              completedTasks={row.completedTasks}
            />
          ),
        renderSkeletonCell: () => <BaseSkeletonCell />,
        align: 'left',
        headerAlign: 'left',
        minWidth: 235,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'assignedTo',
        headerName: t('COLUMNS.ASSIGNED_TO'),
        renderCell: ({ row }) =>
          row.isPartial ? (
            <AssigneesSkeletonCell />
          ) : (
            <AssigneesCell assignees={row.taskAssignees} activeTasks={row.activeTasks} isLegacy={row.isLegacy} />
          ),
        renderSkeletonCell: () => <AssigneesSkeletonCell />,
        minWidth: 140,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'keyDate',
        headerName: t('COLUMNS.KEY_DATE'),
        valueGetter: ({ row }) => {
          const keyDates = getKeyDates(row);
          return keyDates.length > 0 ? keyDates[0].value : '-';
        },
        renderCell: ({ row }) => <KeyDateCell row={row} />,
        renderSkeletonCell: () => <BaseSkeletonCell />,
        align: 'left',
        headerAlign: 'left',
        minWidth: 140,
        disableColumnMenu: true,
        flex: 1,
      },
    ];

    return columns;
  }, [t]);
};

const useKeyCostColumn = (): OrderableDataGridColDef => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUESTS_LIST' });

  return useMemo(() => {
    return {
      field: 'keyCost',
      headerName: t('COLUMNS.KEY_COST'),
      valueGetter: ({ row }) => {
        const keyCosts = getKeyCosts(row.keyCosts);
        return keyCosts.length > 0 ? keyCosts[keyCosts.length - 1].value : '-';
      },
      renderCell: ({ row }) => <KeyCostCell row={row} />,
      renderSkeletonCell: () => <BaseSkeletonCell />,
      align: 'left',
      headerAlign: 'left',
      minWidth: 130,
      disableColumnMenu: true,
      flex: 1,
      order: 1,
    };
  }, [t]);
};

const useActionColumn = (): OrderableDataGridColDef => {
  return useMemo(() => {
    return {
      field: 'actions',
      type: 'actions',
      minWidth: 110,
      renderSkeletonCell: () => <ActionsSkeletonCell />,
      renderCell: ({ row }) => (row.isPartial ? <ActionsSkeletonCell /> : <ActionCells row={row} />),
    };
  }, []);
};

const sortColumnsByOrderProperty = (columns: OrderableDataGridColDef[]) => {
  return columns
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    .reduce(
      (acc, col, index) => acc.toSpliced(col.order ? col.order - 1 : index, 0, col),
      Array.from({ length: columns.length }) as (OrderableDataGridColDef | undefined)[]
    )
    .filter((col): col is RequestColumnColDef => !!col);
};

export const useRequestsColumns = (): RequestColumnColDef[] => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUESTS_LIST' });
  const commonColumns = useCommonColumns();
  const keyCostColumn = useKeyCostColumn();
  const actionColumn = useActionColumn();

  const restColumns = useMemo((): RequestColumnColDef[] => {
    return [
      {
        field: 'started',
        headerName: t('COLUMNS.STARTED'),
        valueGetter: ({ row }) => row.createdAt || undefined,
        renderCell: ({ value }) => <DateCell date={value} />,
        renderSkeletonCell: () => <BaseSkeletonCell />,
        align: 'left',
        headerAlign: 'left',
        minWidth: 120,
        flex: 1,
        disableColumnMenu: true,
      },
      // TODO: Show the column again once the proper design is ready
      // {
      //   field: 'triggeredVia',
      //   headerName: t('COLUMNS.TRIGGERED_VIA'),
      //   valueGetter: ({ row }) => {
      //     if (isSlackRequestor(row.sourceRef)) return 'SLACK';
      //     if (isVerticeRequestor(row.sourceRef)) return 'VERTICE';
      //   },
      //   renderCell: ({ value }) => (
      //     <Tooltip title={t(`REQUESTOR.${value}`)} size={'S'} placement="bottom">
      //       <Stack>{value === 'SLACK' ? <SlackIconWOBG /> : <VerticeVIcon />}</Stack>
      //     </Tooltip>
      //   ),
      //   renderSkeletonCell: () => <BaseSkeletonCell />,
      //   align: 'left',
      //   headerAlign: 'left',
      //   minWidth: 120,
      //   flex: 1,
      //   disableColumnMenu: true,
      // },
    ];
  }, [t]);

  return useMemo(() => {
    const columns: RequestColumnColDef[] = [...commonColumns, keyCostColumn, ...restColumns, actionColumn];

    const sortedColumns = sortColumnsByOrderProperty(columns);

    return sortedColumns;
  }, [commonColumns, keyCostColumn, actionColumn, restColumns]);
};

export const useRequestsDashboardColumns = (): DataGridColDef<RequestRow>[] => {
  const commonColumns = useCommonColumns();
  const keyCostColumn = useKeyCostColumn();
  const actionColumn = useActionColumn();

  return useMemo(() => [keyCostColumn, ...commonColumns, actionColumn], [keyCostColumn, commonColumns, actionColumn]);
};

export const useGroupingColumn = (): GridGroupingColDefOverride<RequestRow> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.REQUESTS_LIST' });

  return useMemo(
    () => ({
      renderCell: (params) => <ParentGroupingCell {...params} />,
      valueGetter: ({ row }) => row.requestName,
      sortable: true,
      minWidth: 300,
      flex: 1,
      headerName: t('COLUMNS.REQUEST'),
      renderSkeletonCell: () => <RequestSkeletonCell />,
      colSpan: ({ row }) => {
        if (row.parentId) {
          return 3;
        }
        return undefined;
      },
    }),
    [t]
  );
};
