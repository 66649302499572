import { createRequiredContext } from '@verticeone/utils/contexts';
import { FC, PropsWithChildren, useMemo } from 'react';
import { PredefinedFormPagesDef } from '../predefinedForms/shared/types';
import { ZodSchema } from 'zod';
import { usePagesValidation } from './usePagesValidation';
import { usePagesNavigation } from './usePagesNavigation';

type PagesContextType = {
  hasPages: boolean;
  pages?: PredefinedFormPagesDef;
  currentPageId?: string;
  validPages: Set<string>;
  allPagesValid: boolean;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  goToPage: (pageId: string) => void;
  isOnLastPage: boolean;
  hasPreviousPage: boolean;
};

export const { PagesContextProvider, usePagesContext } = createRequiredContext<PagesContextType, 'Pages'>('Pages');

type PagesProviderProps = PropsWithChildren & {
  pages?: PredefinedFormPagesDef;
  schema?: ZodSchema;
  onPageVisited?: (pageId: string) => void;
  defaultPageId?: string;
};

export const PagesProvider: FC<PagesProviderProps> = ({ children, pages, schema, onPageVisited, defaultPageId }) => {
  const validationProps = usePagesValidation(pages, schema);
  const navigationProps = usePagesNavigation(pages, onPageVisited, defaultPageId);

  const hasPages = Boolean(pages?.length);

  const contextValue = useMemo(
    () => ({
      pages,
      hasPages,
      ...validationProps,
      ...navigationProps,
    }),
    [hasPages, navigationProps, pages, validationProps]
  );

  return <PagesContextProvider value={contextValue}>{children}</PagesContextProvider>;
};
