import { Divider, Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import RecommendedTable from './BuySPDrawerTable/RecommendedTable';
import {
  BuySPDrawer,
  BuySPDrawerHeader,
  BuySPDrawerGraph,
  BuySPDrawerTabs,
  BuySPDrawerTab,
  CustomizeTable,
  ShoppingTable,
} from './index';
import InstanceProvider from '../providers/InstanceProvider';
import { useMonitoringLatestQuery } from '../../dataSources/useMonitoringLatestQuery';
import { useDataSource } from '../../../DataSourceProvider';
import { useSPListOfferingsQuery } from '../../dataSources/useSPListOfferingsQuery';
import { parseSpOfferings } from '../../dataSources/utils';
import OfferingsProvider from '../providers/OfferingsProvider';
import { GraphLayoutProps } from '../Graph/GraphLayout';

type BuySPDrawerLayoutProps = {
  graph: ({ shoppingCartUnits }: GraphLayoutProps) => ReactNode;
};

const BuySPDrawerLayout = ({ graph }: BuySPDrawerLayoutProps) => {
  const { datasource } = useDataSource();
  const { data } = useMonitoringLatestQuery(datasource);
  const getOfferings = useSPListOfferingsQuery();

  return (
    <InstanceProvider item={data!} getOfferings={getOfferings} offeringsParser={parseSpOfferings}>
      <BuySPDrawer>
        <BuySPDrawerHeader />
        <Stack pb={8} px={8}>
          <OfferingsProvider
            defaultFilter={{
              offeringType: 'No Upfront',
              duration: 31536000,
            }}
          >
            <BuySPDrawerGraph chart={graph} />
          </OfferingsProvider>
        </Stack>
        <Divider />
        <BuySPDrawerTabs>
          <BuySPDrawerTab tabId="RECOMMENDED">
            <OfferingsProvider
              defaultFilter={{
                offeringType: 'No Upfront',
                duration: 31536000,
              }}
            >
              <RecommendedTable />
            </OfferingsProvider>
          </BuySPDrawerTab>
          <BuySPDrawerTab tabId="CUSTOMIZE" filterByShoppingCart>
            <OfferingsProvider>
              <CustomizeTable />
            </OfferingsProvider>
            <OfferingsProvider>
              <ShoppingTable />
            </OfferingsProvider>
          </BuySPDrawerTab>
        </BuySPDrawerTabs>
      </BuySPDrawer>
    </InstanceProvider>
  );
};

export default BuySPDrawerLayout;
