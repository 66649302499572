import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { IntegrationWaiting } from '@vertice/assets';
import { Button } from '@verticeone/design-system';
import { STEPS, ENTRA_INTEGRATION_ID, ConnectionFinishedStepProps } from './common';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import IntegrationStatus from 'pages/Preferences/Integrations/components/IntegrationStatus';

export const ConnectionCheckStep = ({ setStep, accountId, setTestingInProgress }: ConnectionFinishedStepProps) => {
  const { t } = useTranslation();

  const { data: entraIntegration, refetch: refetchAccountIntegration } = useGetAccountIntegrationQuery({
    accountId,
    integrationId: ENTRA_INTEGRATION_ID,
  });

  useEffect(() => {
    if (!['OK', 'FAILED'].includes(entraIntegration?.connection?.status!)) {
      const countTimer = setInterval(() => {
        void refetchAccountIntegration();
      }, 5000);
      return function cleanup() {
        clearInterval(countTimer);
      };
    }

    setStep(entraIntegration?.connection?.status! === 'OK' ? STEPS.CONNECTION_SUCCESS : STEPS.CONNECTION_ERROR);

    return () => {};
  });

  const onBack = () => {
    setTestingInProgress(false);
    setStep(STEPS.COLLECT_API_KEYS);
  };

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus
          icon={<IntegrationWaiting />}
          title={t('PREFERENCES.INTEGRATIONS.CHECKING_YOUR_CONNECTION')}
        />
      }
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption disabled>
          {t('COMMON.FINISH')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
