import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldArea } from '@verticeone/design-system';

import { Button } from '@verticeone/design-system';
import { Dialog, DialogActions, DialogContent, DialogHeader, DialogText } from '@verticeone/design-system';

type ContractDialogProps = {
  open: boolean;
  disabled: boolean;
  onClose: () => void;
  onSuccess: (note: string) => void;
};

const PricingDataRequestDialog = ({ open, onClose, onSuccess, disabled }: ContractDialogProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'VENDOR.BENCHMARK_REQUEST_DIALOG' });
  const [value, setValue] = useState<string>('');
  const [isRequestDisabled, setRequestDisabled] = useState<boolean>(true);

  const onRequest = () => {
    onSuccess(value);
    setValue('');
  };

  const setTextValue = (e: any) => {
    setValue(e.target.value);
    if (e.target.value.length > 0 && isRequestDisabled) {
      setRequestDisabled(false);
    } else if (e.target.value.length === 0) {
      setRequestDisabled(true);
    }
  };

  useEffect(() => {
    if (!open) {
      setValue('');
    }
  }, [open]);

  return (
    <Dialog size="M" open={open}>
      <DialogHeader size="M">{t('HEADER_TITLE')}</DialogHeader>
      <DialogContent>
        <DialogText size="S" variant="body-bold">
          {t('NOTE')}
        </DialogText>
        <TextFieldArea
          size="S"
          variant="solid"
          color="primary"
          textLimit={10000}
          value={value}
          disabled={disabled}
          onChange={setTextValue}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outline">
          {t('BUTTONS.DISCARD')}
        </Button>
        <Button onClick={onRequest} disabled={isRequestDisabled || disabled} variant="solid">
          {t('BUTTONS.REQUEST_DATA')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PricingDataRequestDialog;
