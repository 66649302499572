import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid, Link, Stack, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccountIntegrationQuery, useListCreateJiraIssueTypeFieldsQuery } from '@vertice/slices';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { IconWrapper, Loader, Text } from '@verticeone/design-system';
import { useAccountContext } from '../../../../../../contexts/AccountContext';
import { ProviderIds } from '../../../../../applications/utils';
import { JiraIcon } from '../../../../../integrations/Jira/icons';
import { isProcessDefinition, isTaskIOMappingConfiguration } from '../../../../pocWorkflowSchema';
import { useWorkflowRendererContext } from '../../../WorkflowRenderer/WorkflowRendererContext';
import { useVariablesAvailableInNode } from '../../useVariablesAvailableInNode';
import { findPropertyById } from '../../VariableSelector/utils';
import { VariableChip } from '../../VariableSelector/VariableChip';
import { VariableMapperHeader } from '../JiraCommon/VariableMapper/VariableMapperHeader';

const JIRA_FIELD_PREFIX = 'jiraFields.';

const VariableMapperBox = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  backgroundColor: theme.palette.core.color1,
  borderRadius: 12,
  padding: theme.spacing(4),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.core.color1,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(4),
  textDecoration: 'none',
}));

type ConnectionFormType = Partial<{
  domain: string;
  username: string;
}>;

type CompleteContentProps = {
  taskOverview?: TaskOverview;
};

export const CompleteContent: FC<CompleteContentProps> = ({ taskOverview }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { workflowDefinitions } = useWorkflowRendererContext();

  const { data: integrationData } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });

  const processDefinition = workflowDefinitions?.definitions.find(isProcessDefinition);
  const processTaskDefinition = processDefinition?.process?.tasks?.find((processTask) =>
    taskOverview?.task.taskId?.includes(processTask.task.id)
  );
  const processTaskIOMappingConfiguration =
    processTaskDefinition?.task?.configurations?.find(isTaskIOMappingConfiguration);
  const projectId = processTaskIOMappingConfiguration?.mapping.inputFields.find(
    (field) => field.name === 'projectId'
  )?.value;
  const issueTypeId = processTaskIOMappingConfiguration?.mapping.inputFields.find(
    (field) => field.name === 'issueTypeId'
  )?.value;

  const { data: issueTypeFieldsData, isLoading: isLoadingIssueTypeFields } = useListCreateJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: projectId ?? '',
      issueTypeId: issueTypeId ?? '',
    },
    { skip: !projectId || !issueTypeId }
  );

  const { requestProperties } = useVariablesAvailableInNode({
    nodeId: processTaskDefinition?.task.id ?? '',
    processDefinition,
    workflowServiceRef: taskOverview?.request?.serviceRef,
  });

  const filteredFields = processTaskIOMappingConfiguration?.mapping.inputFields
    .filter((field) => field.name.includes(JIRA_FIELD_PREFIX))
    ?.map((field) => ({ name: field.name, value: field.value }));

  const jiraDomainUrl = (integrationData?.parameters?.connectionForm as ConnectionFormType)?.domain;
  const jiraTicketKey = taskOverview?.task?.result?.ticketKey;
  const jiraTicketSummary = taskOverview?.task?.input?.[`${JIRA_FIELD_PREFIX}description`];

  return (
    <Stack gap={3} p={6}>
      <VariableMapperBox>
        <VariableMapperHeader
          worflowVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER.VERTICE')}
          otherVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER.JIRA')}
          fromWorkflow={true}
        />
        {isLoadingIssueTypeFields ? (
          <Box sx={{ height: 120 }}>
            <Loader size={24} />
          </Box>
        ) : (
          filteredFields?.map((field, index) => {
            const workflowVariable = findPropertyById(requestProperties, field.value);
            const jiraVariable = issueTypeFieldsData?.fields?.find(
              (jiraField) => jiraField.id === field.name.replace(JIRA_FIELD_PREFIX, '')
            );

            return (
              <Grid container sx={{ zIndex: 1 }} key={index}>
                <Grid item xs={6}>
                  <VariableChip
                    label={workflowVariable?.label}
                    typeLabel={workflowVariable?.typeLabel?.[0]}
                    isDisabled={true}
                  />
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <IconWrapper icon={ArrowForwardIcon} />
                </Grid>
                <Grid item xs={4}>
                  <VariableChip label={jiraVariable?.name} typeLabel={jiraVariable?.type} isDisabled={true} />
                </Grid>
              </Grid>
            );
          })
        )}
      </VariableMapperBox>
      <Text variant="caption" size="XS" color="text2">
        {t('INTELLIGENT_WORKFLOWS.JIRA.LINKED_ISSUE_TITLE')}
      </Text>
      <Stack component={StyledLink} href={`${jiraDomainUrl}browse/${jiraTicketKey}`} direction="row" gap={2}>
        <IconWrapper icon={JiraIcon} />
        <Text variant="body-bold" size="S" color="text1">
          {`${jiraTicketKey}: ${jiraTicketSummary}`}
        </Text>
      </Stack>
    </Stack>
  );
};
