import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import unique from 'lodash/uniq';

export type OwnersCellProps = {
  viewOnContract: ViewOnContract;
};

const isValidOwner = (ownerId: string | undefined): ownerId is string => {
  return ownerId !== undefined;
};

const getOwners = (viewOnContract: ViewOnContract): string[] => {
  const { computed } = viewOnContract;
  // contract owner first
  const owners = sortBy(computed?.owners, (owner) => (owner.type === 'CONTRACT' ? 0 : 1));
  const ownerIds = unique(owners ? owners.map((owner) => owner.userId).filter(isValidOwner) : []);
  return ownerIds.map((ownerId) => computed?.labels?.[ownerId] || ownerId);
};

export const OwnersCell = ({ viewOnContract }: OwnersCellProps) => {
  const owners = useMemo(() => getOwners(viewOnContract), [viewOnContract]);

  if (owners.length === 0) {
    return (
      <Text variant="body-regular" size="M" color="inactive2">
        —
      </Text>
    );
  }
  return (
    <Stack direction="column" gap={1}>
      {owners.map((ownerId) => (
        <Text variant="caption" size="S" color="text3" key={ownerId}>
          {ownerId}
        </Text>
      ))}
    </Stack>
  );
};
