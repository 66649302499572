import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';
import type { TestResultTooltip } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';
import { useValueFormatter } from '../utils';

type ResultTooltipProps = {
  tooltip?: TestResultTooltip;
  children: React.ReactElement;
};

const ResultTooltip = ({ tooltip, children }: ResultTooltipProps) => {
  const valueFormatter = useValueFormatter();

  const content = useMemo(
    () => (
      <Stack>
        {tooltip?.data?.map((row, row_index) => (
          <Stack key={row_index} direction="row" gap={4} alignItems="center">
            {row?.map((column, col_index) => (
              <Text key={column} variant="body-regular" flex={col_index === 0 ? 2 : 1}>
                {valueFormatter(column!, tooltip.types?.[col_index] ?? 'string')}
              </Text>
            ))}
          </Stack>
        ))}
      </Stack>
    ),
    [tooltip, valueFormatter]
  );

  if (!tooltip) {
    return <>{children}</>;
  }

  return (
    <Tooltip size="S" content={content}>
      {children}
    </Tooltip>
  );
};

export default ResultTooltip;
