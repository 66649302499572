import { isEmpty, isEqual, isNil, xorWith } from 'lodash';
import { GenericFormField } from '../types';

export const isArrayEqual = (x?: GenericFormField[] | null, y?: GenericFormField[] | null) => {
  if (isNil(x)) {
    return isNil(y);
  }

  if (isNil(y)) {
    return isNil(x);
  }

  return isEmpty(xorWith(x, y, isEqual));
};
