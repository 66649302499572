import React, { FC } from 'react';
import { NodeProps, Node } from '@xyflow/react';
import { NodeControls } from './NodeControls';
import { WorkflowEndNode } from '../../model/types';
import { Text } from '@verticeone/design-system';
import { StyledStartEndNode } from './Shared';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';

export const EndNodeComponent: FC<NodeProps<Node<WorkflowEndNode>>> = (props) => {
  const { t } = useTranslation();
  return (
    <NodeControls
      {...props}
      content={
        <StyledStartEndNode $passed={props.data.state?.passed}>
          <Text variant="button-bold" size="M">
            {props.data.name || t('MODULES.WORKFLOW.NODES.END.DEFAULT_LABEL')}
          </Text>
          {props.data.state?.passed && <Check />}
        </StyledStartEndNode>
      }
    />
  );
};
