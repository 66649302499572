import { IconWrapper } from '@verticeone/design-system';
import { VisoTrustIcon } from '@vertice/assets';
import { useTranslation } from 'react-i18next';
import { IntegrationBreadcrumbIcon } from '../../components/IconWrappers';
import { IntegrationSetup } from '../../components/IntegrationSetup';
import { StepContent } from './components/StepContent';

export const VisoTrustSetup = () => {
  const { t } = useTranslation();

  return (
    <IntegrationSetup
      breadcrumb={{
        label: t('PREFERENCES.INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.BREADCRUMBS.VISO_TRUST'),
        icon: (
          <IntegrationBreadcrumbIcon>
            <IconWrapper icon={VisoTrustIcon} />
          </IntegrationBreadcrumbIcon>
        ),
      }}
      // Implement proper action button: actionButton={<ActionButton activeStep={activeStep} />}
      actionButton={<div />}
      stepContent={<StepContent />}
    />
  );
};
