import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SimpleOption } from '@verticeone/design-system';

export type UsePaymentTermsOptionsReturn = SimpleOption[];

const usePaymentTermsOptions = (): UsePaymentTermsOptionsReturn => {
  const { t } = useTranslation();
  const paymentTermsOptions = useMemo(
    () => [
      {
        value: 'NET_15',
        label: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERM_NET_15'),
      },
      {
        value: 'NET_30',
        label: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERM_NET_30'),
      },
      {
        value: 'NET_45',
        label: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERM_NET_45'),
      },
      {
        value: 'NET_60',
        label: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERM_NET_60'),
      },
      {
        value: 'NET_90',
        label: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERM_NET_90'),
      },
      {
        value: 'OTHER',
        label: t('ENTITIES.CONTRACT.LABELS.OTHER'),
      },
    ],
    [t]
  );

  return paymentTermsOptions;
};

export default usePaymentTermsOptions;
