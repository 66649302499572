import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { Card, CardHeader, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system';
import { LoadableComponent } from '@verticeone/design-system';

import ContractItem, { ContractContextProvider } from './Contract';
import ContractsDataContextProvider, { useContractData } from './ContractsDataContext';
import AddContract from './Contract/buttons/AddContract';

const ContractList = () => {
  const { palette } = useTheme();
  const { data, isFetching, isRefetching } = useContractData();

  return (
    <LoadableComponent isLoading={isFetching && !isRefetching}>
      <Stack bgcolor={palette.core.bg} p={6} pt={0} gap={4}>
        {data?.map((contract, index) => (
          <ContractContextProvider key={contract.id}>
            <ContractItem contract={contract} contractNo={data.length - index} />
          </ContractContextProvider>
        ))}
      </Stack>
    </LoadableComponent>
  );
};

const Layout = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });

  return (
    <ContractsDataContextProvider>
      <Card>
        <CardHeader size="S" noDivider>
          <CardHeaderTitle text={t('EDP_CONTRACT_TITLE')} tag="h2" />
          <CardHeaderActions>
            <AddContract />
          </CardHeaderActions>
        </CardHeader>
        <ContractList />
      </Card>
    </ContractsDataContextProvider>
  );
};

export default Layout;
