import { FieldCard } from '../../DynamicFormBuilder/components/FieldCard/FieldCard';
import FieldCardContent from '../../DynamicFormBuilder/components/FieldCard/FieldCardContent';
import { ShortTextFieldType } from './types';

export type ShortTextFieldBuilderCardProps = {
  field: ShortTextFieldType & { id: string };
};

export const ShortTextFieldBuilderCard = (props: ShortTextFieldBuilderCardProps) => {
  return (
    <FieldCard>
      <FieldCardContent {...props} />
    </FieldCard>
  );
};
