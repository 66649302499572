import { useTranslation } from 'react-i18next';
import type { Duration, OfferingType } from '../../../../../types';

export type FilterDataOptions = {
  offeringType?: OfferingType;
  duration?: Duration;
};

export type DataType = keyof FilterDataOptions;

type FilterOption<Value extends unknown> = {
  label: string;
  value: Value;
};

export const useDataOptions = (tKey: string, dataType: DataType) => {
  const { t } = useTranslation(undefined, { keyPrefix: `${tKey}.OPTIONS` });

  const offeringType: Array<FilterOption<OfferingType>> = [
    { label: t('NO_UPFRONT'), value: 'No Upfront' },
    { label: t('ALL_UPFRONT'), value: 'All Upfront' },
    { label: t('PARTIAL_UPFRONT'), value: 'Partial Upfront' },
  ];

  const duration: Array<FilterOption<Duration>> = [
    { label: t('1_YEAR'), value: 31536000 },
    { label: t('3_YEARS'), value: 94608000 },
  ];

  return {
    offeringType,
    duration,
  }[dataType];
};
