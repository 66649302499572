import { FieldValues } from 'react-hook-form/dist/types/fields';
import { SelectColor, SimpleOption } from '@verticeone/design-system';
import React from 'react';
import { currencies } from '@verticeone/utils/currency';
import FormSelectField2 from '../FormSelectField2';
import { CommonFormFieldProps } from '../../types';

export type FormCurrencyFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  isClearable?: boolean;
  color?: SelectColor;
};

export const FormCurrencyField = <FormDataType extends FieldValues = never>({
  ...otherProps
}: FormCurrencyFieldProps<FormDataType>) => {
  const options = currencies.map((value): SimpleOption => ({ value, label: value }));
  return (
    <FormSelectField2<any, SimpleOption, false>
      isMulti={false}
      options={options}
      boxValue={(value) => (value ? { value, label: value } : null)}
      unboxValue={(selectValue) => (selectValue ? selectValue.value : null)}
      {...otherProps}
    />
  );
};
