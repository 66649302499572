import type { Property } from '../../types';
import { useTranslation } from 'react-i18next';
import { VariableSelectorField } from './VariableSelectorField';
import { GenericFormField } from './types';
import FormEntry from '../../../../../forms/fields/FormEntry';
import React from 'react';

export type VariableSelectorFormEntryProps = {
  requestProperties: Property[];
  globalUDFProperties: Property[];
};

export const VariableSelectorFormEntry = ({
  requestProperties,
  globalUDFProperties,
}: VariableSelectorFormEntryProps) => {
  const { t } = useTranslation();

  return (
    <FormEntry<GenericFormField, typeof VariableSelectorField>
      name="variable"
      component={VariableSelectorField}
      componentProps={{
        globalUDFProperties: globalUDFProperties,
        allRequestProperties: requestProperties,
      }}
      label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.FIELD_VARIABLE')}
      tooltip={{
        title: t('DYNAMIC_FORM_BUILDER.DIALOG.TOOLTIP.VARIABLE.TITLE'),
        content: t('DYNAMIC_FORM_BUILDER.DIALOG.TOOLTIP.VARIABLE.CONTENT'),
      }}
    />
  );
};
