import React from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { TaskRow } from '../../types';
import { useFormatDate } from '@verticeone/utils/formatting';
import { GenericDateIcon } from '../../../components/icons/Icons';

export const TaskClosedAt = ({ taskRow }: { taskRow: TaskRow }) => {
  const formatDate = useFormatDate();

  return (
    <Stack minWidth={0} direction="row" alignItems="center" gap={0.5}>
      <GenericDateIcon />
      <Text variant="body-regular" size="M" color="success1">
        {formatDate(taskRow.closedAt)}
      </Text>
    </Stack>
  );
};
