import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { FORM_CONFIGURATION_KIND } from '../../../constants';
import { PredefinedFormDef } from '../shared/types';
import SaaSApprovalSimpleForm from './ApprovalSimpleForm';
import { FormData, zodSchema } from './schema';

export const SAAS_APPROVAL_FORM_CONFIG = {
  formUrn: 'urn:verticeone:vertice::services:form/saas/approval/legacy',
};

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/approval\/legacy$/,
  component: SaaSApprovalSimpleForm,
  schema: zodSchema,
  getDefaultValues: () => ({
    result: 'OK',
  }),
};

// this form and check function is to detect and handle legacy "back on track" workflow tasks
export function isSaaSApprovalTask(taskOverview: TaskOverview | undefined): boolean {
  const formConfig = taskOverview?.task.configurations?.find(
    (configuration) => configuration.kind === FORM_CONFIGURATION_KIND
  );
  return !formConfig && !taskOverview?.request && taskOverview?.task.input?.stage === 'APPROVAL';
}
