import React from 'react';
import { Stack } from '@mui/material';
import { LabelFieldDialogFormEntry } from '../shared/components/LabelFieldDialogFormEntry';
import { DescriptionFieldDialogFormEntry } from '../shared/components/DescriptionFieldDialogFormEntry';

export const NumberFieldBuilderForm = () => {
  return (
    <Stack gap={4}>
      <LabelFieldDialogFormEntry />
      <DescriptionFieldDialogFormEntry />
    </Stack>
  );
};
