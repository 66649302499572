import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountIntegrationQuery, useTestAccountIntegrationMutation } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Button } from '@verticeone/design-system';
import { IntegrationWaiting } from '@vertice/assets';
import { STEPS, SLACK_PROVIDER_ID } from '../../common';
import IntegrationStatus from 'pages/Preferences/Integrations/components/IntegrationStatus';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import { WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';

type ConnectionCheckStepProps = WizardStepProps & {
  connectionWindow: Window | null;
};

export const ConnectionCheckStep = ({ setStep, connectionWindow }: ConnectionCheckStepProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const [testConnectionExecuted, setTestConnectionExecuted] = useState(false);
  const [testAccountIntegrationMutation] = useTestAccountIntegrationMutation();

  const { data: integrationData, refetch: refetchAccountIntegration } = useGetAccountIntegrationQuery({
    accountId,
    integrationId: SLACK_PROVIDER_ID,
  });

  useEffect(() => {
    const auth2ConnectionStatus = integrationData?.parameters?.OAuth2Status!;
    const connectionStatus = integrationData?.connection?.status!;
    if (auth2ConnectionStatus === 'ERROR' || ['FAILED', 'OK'].includes(connectionStatus)) {
      setStep(connectionStatus === 'OK' ? STEPS.CONNECTION_SUCCESS : STEPS.CONNECTION_ERROR);
      if (connectionWindow) {
        connectionWindow.close();
      }
      return () => {};
    }

    if (auth2ConnectionStatus === 'AUTHORIZED' && !testConnectionExecuted) {
      void testAccountIntegrationMutation({
        accountId,
        integrationId: SLACK_PROVIDER_ID,
        testName: 'connection',
      });
      setTestConnectionExecuted(true);
      if (connectionWindow) {
        connectionWindow.close();
      }
    }

    const countTimer = setInterval(() => {
      void refetchAccountIntegration();
    }, 1000);

    return () => {
      clearInterval(countTimer);
    };
  }, [
    accountId,
    connectionWindow,
    integrationData?.connection?.status,
    integrationData?.parameters?.OAuth2Status,
    refetchAccountIntegration,
    setStep,
    testAccountIntegrationMutation,
    testConnectionExecuted,
  ]);

  const onBack = () => {
    setStep(STEPS.START);
  };

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus
          icon={<IntegrationWaiting />}
          title={t('PREFERENCES.INTEGRATIONS.CHECKING_YOUR_CONNECTION')}
        />
      }
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button variant="ghost" color="primary" size="S" isCaption disabled>
          {t('COMMON.NEXT')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
