import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { gridClasses, GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@verticeone/utils/formatting';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '../../constants';
import { DataGrid } from '@verticeone/design-system';
import { TopFeaturesBySpendData } from './types';

type TopFeaturesBySpendProps = {
  data: TopFeaturesBySpendData;
};

const ROW_COUNT = 100;

const CloudWatchFeaturesBySpendTable = ({ data }: TopFeaturesBySpendProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.TOP_CLOUD_WATCH_FEATURES_BY_SPEND' });
  const { locale } = useLocaleContext();
  const theme = useTheme();
  const getRowId = (row: GridRowModel) => row.path.join('');

  const columns: GridColDef[] = [
    {
      field: 'value',
      headerName: t('COLUMN.SPEND'),
      disableColumnMenu: true,
      align: 'right',
      type: 'number',
      editable: false,
      renderCell: (params) => {
        const formattedValue = formatCurrency(params.value, {
          currency: data.currency ?? AWS_DEFAULT_CURRENCY,
          locale,
          maximumFractionDigits: 0,
          withSpace: true,
        });

        return (
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Typography variant="body-bold-m">{formattedValue}</Typography>
          </Stack>
        );
      },
      flex: 1,
    },
  ];

  return (
    <DataGrid
      groupingColDef={{
        headerName: t('COLUMN.FEATURE'),
        flex: 1,
      }}
      columns={columns}
      treeData
      rows={data.items.slice(0, ROW_COUNT) || []}
      getTreeDataPath={(row) => row.path}
      testId="cloud-watch-cost-by-feature"
      sortingMode="client"
      color={AWS_BRAND_COLOR}
      disableRowSelectionOnClick
      showColumnVerticalBorder
      showCellVerticalBorder
      rowHeight={60}
      size={'M'}
      hideFooter
      getRowId={getRowId}
      noBorder
      noBorderRadius
      scrollbarSize={8}
      sx={{
        flex: 1,
        [`.${gridClasses.cell}`]: {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
        [`.${gridClasses.columnHeader}`]: {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
      }}
    />
  );
};

export default CloudWatchFeaturesBySpendTable;
