import {
  CalendarTodayOutlined,
  PersonTwoTone,
  RequestPageOutlined,
  Settings,
  Update,
  SvgIconComponent,
  QuizTwoTone,
  AccountTreeTwoTone,
  TaskOutlined,
  CloseOutlined,
  MergeOutlined,
  AltRouteOutlined,
} from '@mui/icons-material';
import { FC, FunctionComponent, SVGProps } from 'react';
import { useTheme } from '@mui/material';
import { IconWrapper } from '@verticeone/design-system';
import type { DesignSystemSize, DesignSystemColor } from '@verticeone/design-system';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { WorkflowsTwoTone } from '@vertice/assets';

type IntelligentWorkflowsIconProps = {
  icon: SvgIconComponent | FunctionComponent<SVGProps<SVGSVGElement>>;
  size?: DesignSystemSize;
  color?: DesignSystemColor;
};

export const IntelligentWorkflowsIcon: FC<IntelligentWorkflowsIconProps> = ({ icon: Icon, size = 'M', color }) => {
  const { palette } = useTheme();

  return <IconWrapper sx={{ color: color ? palette[color].color2 : palette.core.color5 }} icon={Icon} size={size} />;
};

export const GenericWorkflowIcon = () => <IntelligentWorkflowsIcon icon={WorkflowsTwoTone} />;
export const GenericTemplateIcon = () => <IntelligentWorkflowsIcon icon={QuizTwoTone} />;
export const GenericUserIcon = () => <IntelligentWorkflowsIcon icon={PersonTwoTone} />;
export const GenericDateIcon = (props: { size?: DesignSystemSize }) => (
  <IntelligentWorkflowsIcon icon={CalendarTodayOutlined} {...props} />
);
export const GenericRequestIcon = (props: { size?: DesignSystemSize; color?: DesignSystemColor }) => (
  <IntelligentWorkflowsIcon icon={AccountTreeTwoTone} {...props} />
);
export const GenericMoneyIcon = (props: { size?: DesignSystemSize }) => (
  <IntelligentWorkflowsIcon icon={RequestPageOutlined} {...props} />
);
export const GenericUpdateIcon = (props: { size?: DesignSystemSize }) => (
  <IntelligentWorkflowsIcon icon={Update} {...props} />
);
export const GenericTaskIcon = () => <IntelligentWorkflowsIcon icon={TaskOutlined} />;
export const GenericServiceIcon = () => <IntelligentWorkflowsIcon icon={Settings} />;

export const GatewayIconMap = {
  Exclusive: CloseOutlined,
  Join: MergeOutlined,
  Fork: AltRouteOutlined,
} as const;
