import { Edge, Node } from '@xyflow/react';
import {
  ActivityHookConfiguration,
  Definition,
  End,
  Event,
  FlowEdge,
  FlowEdgeConditionConfigurationDefinition,
  FlowEdgeConfiguration,
  FlowEdgeMappingConfigurationDefinition,
  Gateway,
  GatewayConfiguration,
  LayoutDefinition,
  Process,
  ProcessDefinition,
  Start,
  Task,
  TaskIOMappingConfiguration,
  VerticeFormConfiguration,
  VerticeServiceConfiguration,
} from '../definitionsTypes';

export type ServiceCatalogNodeType = ProcessNodeType | 'template';
export type ProcessNodeType = 'start' | 'end' | 'task' | 'event' | 'gateway';
export type ProcessNodeDataType = Start | End | Task | Event | Gateway;

export type StartNode = Node<Start, 'start'>;
export type TaskNode = Node<Task, 'task'>;
export type EndNode = Node<End, 'end'>;
export type EventNode = Node<Event, 'event'>;
export type GatewayNode = Node<Gateway, 'gateway'>;
export type ProcessNode = StartNode | TaskNode | EndNode | EventNode | GatewayNode;

export type TemplateNodePosition = {
  type: 'RELATIVE' | 'ABSOLUTE';
  x: number;
  y: number;
};

export type TemplateNode = {
  nodeId?: string;
  type: ProcessNodeType;
  position: TemplateNodePosition;
  data?: {
    [k: string]: any;
  };
};

export type TemplateEdge = {
  id: string;
  to: string;
  from: string;
  data?: {
    [k: string]: any;
  };
};

export const isStartNode = (node: Node): node is StartNode => node.type === 'start';
export const isEndNode = (node: Node): node is EndNode => node.type === 'end';
export const isTaskNode = (node: Node): node is TaskNode => node.type === 'task';
export const isEventNode = (node: Node): node is EventNode => node.type === 'event';
export const isGatewayNode = (node: Node): node is GatewayNode => node.type === 'gateway';

export const isProcessNode = (node: Node): node is ProcessNode => {
  return isStartNode(node) || isEndNode(node) || isTaskNode(node) || isEventNode(node) || isGatewayNode(node);
};

// id, from, to are held in the Edge structure and the rest needs to be stored in edge data
export type ProcessEdgeData = Omit<FlowEdge, 'id' | 'from' | 'to'>;
export type ProcessEdge = Edge<ProcessEdgeData>;

// for data that are not held by the nodes and edges
export type DefinitionsMetadata = Pick<Process, 'id' | 'name'>;

export const isProcessDefinition = (definition: Definition): definition is ProcessDefinition => {
  return definition.kind === 'ProcessEngine:ProcessDefinition';
};

export const isLayoutDefinition = (definition: Definition): definition is LayoutDefinition => {
  return definition.kind === 'ProcessDesigner:Layout';
};

export const isFlowEdgeConditionConfiguration = (config: object): config is FlowEdgeConditionConfigurationDefinition =>
  (config as FlowEdgeConfiguration)?.kind === 'ProcessEngine:FlowEdgeCondition';

export const isFlowEdgeMappingConfiguration = (config: object): config is FlowEdgeMappingConfigurationDefinition =>
  (config as FlowEdgeConfiguration)?.kind === 'ProcessEngine:FlowEdgeMapping';

export const isTaskIOMappingConfiguration = (config: object): config is TaskIOMappingConfiguration =>
  (config as TaskIOMappingConfiguration)?.kind === 'ProcessEngine:TaskIOMapping';

export const isTaskActivityHookConfiguration = (config: object): config is ActivityHookConfiguration =>
  (config as ActivityHookConfiguration)?.kind === 'ProcessEngine:ActivityHook';

export const isVerticeFormConfiguration = (config: object): config is VerticeFormConfiguration =>
  (config as VerticeFormConfiguration)?.kind === 'Vertice:Form';

export const isVerticeServiceConfiguration = (config: object): config is VerticeServiceConfiguration =>
  (config as VerticeServiceConfiguration)?.kind === 'Vertice:Service';

// equivalent to item type of Task['configurations']
// the TaskConfiguration({[k: string]: unknown;}) is replaced with "object" here as it was causing TS troubles
export type TaskConfig = ActivityHookConfiguration | TaskIOMappingConfiguration | object;
export const isConfigurationWithKind = (config: TaskConfig): config is { kind: string } =>
  config.hasOwnProperty('kind') && typeof (config as any).kind === 'string';

export const isGatewayJoinConfiguration = (config: object): config is GatewayConfiguration =>
  (config as GatewayConfiguration)?.configuration.kind === 'ProcessEngine:JoinConfiguration';
