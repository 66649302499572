import { z } from 'zod';
import { FieldType } from '../types';

export type ShortTextFieldType = {
  name: string;
  type: FieldType.SHORT_TEXT;
  metadata: {
    label: string;
    description?: string;
    required: boolean;
  };
};

export const shortTextFieldSchema = z.object({
  name: z.string(),
  type: z.literal(FieldType.SHORT_TEXT),
  metadata: z.object({
    label: z.string(),
    description: z.string().optional(),
    required: z.boolean(),
  }),
});
