import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridFooterContainer } from '@mui/x-data-grid-pro';
import { Button } from '@verticeone/design-system';
import { Box, Stack } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';

interface AddItemFooterProps {
  disabledButton: boolean;
  onAddClick: () => void;
}

const AddItemFooter: FC<AddItemFooterProps> = ({ onAddClick, disabledButton }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT_CUSTOMIZATION' });

  return (
    <GridFooterContainer>
      <Box pl={3}>
        <Button color="primary" variant="plain" onClick={onAddClick} disabled={disabledButton} customized>
          <Stack direction="row" gap={1} alignItems="center">
            <AddOutlined sx={{ width: 16, height: 16 }} />
            {t('ADD_NEW_FIELD')}
          </Stack>
        </Button>
      </Box>
    </GridFooterContainer>
  );
};

export default AddItemFooter;
