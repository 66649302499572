import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Text } from '@verticeone/design-system';
import { useMemo } from 'react';
import { ViewOnContractForVendorsPage } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import AdditionalDetails, {
  AdditionalInfoFlag,
} from '@vertice/core/src/modules/saas/contract/components/StageIndicator/AdditionalDetails';
import WorkflowStageIndicator from '@vertice/core/src/modules/saas/contract/components/StageIndicator/WorkflowStageIndicator';

type StageIndicatorCellProps = {
  contractData: ViewOnContractForVendorsPage;
};

export const StageIndicatorCell = ({ contractData }: StageIndicatorCellProps) => {
  const { t } = useTranslation();

  const additionalDetailsProps = useMemo(
    () => ({
      nextContract: contractData.materialized?.next,
      isPlannedForExpiration: !!contractData.markedForExpiration,
      flags: [AdditionalInfoFlag.PLANNED_FOR_EXPIRATION],
    }),
    [contractData]
  );

  switch (contractData.materialized?.simplifiedStage) {
    case 'DRAFT':
      return (
        <Stack>
          <Text variant="body-regular" color="text3">
            {t(`ENTITIES.CONTRACT.STAGES.DRAFT`)}
          </Text>
          <AdditionalDetails {...additionalDetailsProps} />
        </Stack>
      );
    case 'LIVE':
    case 'NOT_YET_LIVE':
    case 'EXPIRED_NATURAL':
    case 'EXPIRED_UNINTENTIONAL':
    case 'EXPIRED_PLANNED':
    case 'CANCELLED_FIXED_TERM':
    case 'CANCELLED_REQUEST':
    case 'TERMINATED':
    case 'DELETED':
      return (
        <Stack>
          <Text variant="body-regular" color="text1" whiteSpace="nowrap">
            {t(`ENTITIES.CONTRACT.STAGES.${contractData.materialized.simplifiedStage}`)}
          </Text>
          <AdditionalDetails {...additionalDetailsProps} />
        </Stack>
      );
    case 'WORKFLOW_IN_PROGRESS': // purchase
      return <WorkflowStageIndicator stage={contractData.workflowStage} />;
    default:
      return null;
  }
};
