import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent, SelectProps, styled } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { ConcessionType } from '../constants';
import { ConcessionTypeType } from '../types';

const PlainSelect = styled((props: SelectProps<ConcessionTypeType>) => <Select {...props} />)`
  & .MuiInput-input {
    padding-left: 12px;
  }
  & .MuiSelect-select:focus {
    background-color: unset;
  }
`;

const TypeCellEditable = ({ id, value, field }: GridCellParams<any, ConcessionTypeType>) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const typeValues = Object.values(ConcessionType);

  const getTextForType = (type: ConcessionTypeType) => {
    return t(`CONCESSIONS_LIST.TYPE.${type}`);
  };

  const handleValueChange = (event: SelectChangeEvent<any>) => {
    const newValue = event.target.value;
    void apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  const placeholder = (
    <Text variant="body-regular" color="text3">
      {t('CONCESSIONS_LIST.PLACEHOLDER.TYPE')}
    </Text>
  );

  return (
    <FormControl fullWidth>
      <PlainSelect
        value={value || ''}
        onChange={handleValueChange}
        fullWidth
        disableUnderline
        variant="standard"
        displayEmpty
        renderValue={(val: ConcessionTypeType | null): React.ReactNode => (val ? getTextForType(val) : placeholder)}
      >
        {typeValues.map((type) => (
          <MenuItem key={type} value={type}>
            {getTextForType(type)}
          </MenuItem>
        ))}
      </PlainSelect>
    </FormControl>
  );
};

export default TypeCellEditable;
