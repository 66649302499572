import { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { Text } from '@verticeone/design-system';

type EditorDrawerHeaderProps = {
  title: string;
  subtitle?: string;
};

const EditorDrawerHeaderContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  borderBottom: `1px solid ${theme.palette.core.color3}`,
}));

export const EditorDrawerHeader: FC<EditorDrawerHeaderProps> = ({ title, subtitle }) => {
  return (
    <EditorDrawerHeaderContainer>
      <Text variant="heading" size="M" color="text1">
        {title}
      </Text>
      {subtitle && (
        <Text variant="caption" size="XS" color="text3">
          {subtitle}
        </Text>
      )}
    </EditorDrawerHeaderContainer>
  );
};
