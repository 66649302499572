import React from 'react';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';

const ZendeskSSOLogout = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} paddingTop={25}>
      <Text variant="body-bold" size="L">
        {t('ZENDESK_LOGOUT.TITLE')}
      </Text>
      <Text variant="body-bold" size="S">
        {t('ZENDESK_LOGOUT.SUBTITLE')}
      </Text>
    </Stack>
  );
};

export default ZendeskSSOLogout;
