import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@verticeone/design-system';
import React, { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, CheckboxProps } from '@verticeone/design-system';
import { NotificationsScope } from '@vertice/slices/src/openapi/codegen/userAPI';
import { NotificationsSettings, UpdateFn } from './types';
import ContractRenewalLabelCell from './ContractRenewalLabelCell';
import { Tooltip } from '@verticeone/design-system';
import useNotificationsSettings from './useNotificationsSettings';
import useUserSettings from '../../../hooks/useUserSettings';
import NotificationsPreferencesSkeleton from './NotificationsPreferencesSkeleton';

export type LabelCellProps<Field extends keyof NotificationsSettings> = {
  labelTKey: string;
  setting: NotificationsSettings[Field];
  saveSetting: (newSettings: NotificationsSettings[Field]) => Promise<void>;
  isSaving: boolean;
};

type NotificationsPreferencesRowDef<Field extends keyof NotificationsSettings> = {
  field: Field;
  /** Allows optional custom rendering for the first label column */
  renderLabelCell?: ComponentType<LabelCellProps<Field>>;
  labelTKey: string;
};
const notificationsPreferencesRowDef = <Field extends keyof NotificationsSettings>(
  rowDef: NotificationsPreferencesRowDef<Field>
) => rowDef;

const rowsDef: NotificationsPreferencesRowDef<any>[] = [
  notificationsPreferencesRowDef({
    field: 'contractRenewal',
    labelTKey: 'RENEWALS_UPCOMING_IN',
    renderLabelCell: ContractRenewalLabelCell,
  }),
  notificationsPreferencesRowDef({ field: 'proposalsAwaitingApproval', labelTKey: 'PROPOSAL_AWAITING_APPROVAL' }),
  notificationsPreferencesRowDef({ field: 'pipelineUpdates', labelTKey: 'PIPELINE_UPDATE' }),
  notificationsPreferencesRowDef({ field: 'newContractsAdded', labelTKey: 'NEW_CONTRACT_ADDED' }),
];

const TableCheckbox = ({ checked, onChange, disabled }: Pick<CheckboxProps, 'checked' | 'onChange' | 'disabled'>) => (
  <Checkbox
    size="M"
    color="primary"
    sx={{ display: 'inline-flex' }}
    checked={checked}
    onChange={onChange}
    disabled={disabled}
  />
);

type ScopeTransitions = Partial<Record<NotificationsScope, NotificationsScope>>;

export type NotificationsPreferencesProps = {
  userId: string;
  noBorder?: boolean;
};
const NotificationsPreferences = ({ userId, noBorder }: NotificationsPreferencesProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'PREFERENCES.EMAIL_NOTIFICATIONS.TABLE' });

  const userSettingsHook = useUserSettings({ userId });
  const { isLoading, notificationsSettings, saveNotificationsSettings, userCanReceiveAllContractsNotifications } =
    useNotificationsSettings({
      userId,
      userSettingsHook,
    });

  const [isSaving, setIsSaving] = useState(false);

  const updateSetting = async <Field extends keyof NotificationsSettings>(
    field: Field,
    updateFn: UpdateFn<NotificationsSettings[Field]>
  ) => {
    if (!notificationsSettings) return;
    setIsSaving(true);
    await saveNotificationsSettings({ ...notificationsSettings, [field]: updateFn(notificationsSettings[field]) });
    setIsSaving(false);
  };

  const updateScope = (field: keyof NotificationsSettings, scope: NotificationsScope) =>
    updateSetting(field, (s) => ({ ...s, scope }));

  if (isLoading) {
    return <NotificationsPreferencesSkeleton sx={{ minWidth: '816px' }} noBorder={noBorder} />;
  }

  return (
    <TableContainer noBorder={noBorder} noBorderRadius={noBorder}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {[
              'OWNED_CONTRACTS',
              'WATCHED_CONTRACTS',
              ...(userCanReceiveAllContractsNotifications ? ['ALL_CONTRACTS'] : []),
            ].map((scopeTKey) => (
              <TableCell key={scopeTKey} align="center">
                <Tooltip size="M" content={t(`COLUMNS.${scopeTKey}.DESCRIPTION`)}>
                  <span>{t(`COLUMNS.${scopeTKey}.LABEL`)}</span>
                </Tooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsDef.map(
            <Field extends keyof NotificationsSettings>({
              renderLabelCell: CustomCellComponent,
              field,
              labelTKey,
            }: NotificationsPreferencesRowDef<Field>) => {
              const scope = notificationsSettings![field].scope;
              const effectiveScope =
                scope === 'ALL_CONTRACTS' && !userCanReceiveAllContractsNotifications
                  ? 'OWNED_AND_WATCHED_CONTRACTS'
                  : scope;
              return (
                <TableRow key={labelTKey}>
                  <TableCell>
                    {CustomCellComponent ? (
                      <CustomCellComponent
                        isSaving={isSaving}
                        labelTKey={labelTKey}
                        setting={notificationsSettings![field]}
                        saveSetting={(setting) => updateSetting(field, () => setting)}
                      />
                    ) : (
                      t(`LABELS.${labelTKey}`)
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <TableCheckbox
                      disabled={effectiveScope === 'ALL_CONTRACTS' || isSaving}
                      checked={Array<NotificationsScope>(
                        'OWNED_CONTRACTS_ONLY',
                        'OWNED_AND_WATCHED_CONTRACTS',
                        'ALL_CONTRACTS'
                      ).includes(effectiveScope)}
                      onChange={() => {
                        const transitions: ScopeTransitions = {
                          NOTHING: 'OWNED_CONTRACTS_ONLY',
                          WATCHED_CONTRACTS_ONLY: 'OWNED_AND_WATCHED_CONTRACTS',
                          OWNED_CONTRACTS_ONLY: 'NOTHING',
                          OWNED_AND_WATCHED_CONTRACTS: 'WATCHED_CONTRACTS_ONLY',
                        };
                        if (transitions[effectiveScope]) void updateScope(field, transitions[effectiveScope]!);
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TableCheckbox
                      disabled={effectiveScope === 'ALL_CONTRACTS' || isSaving}
                      checked={Array<NotificationsScope>(
                        'WATCHED_CONTRACTS_ONLY',
                        'OWNED_AND_WATCHED_CONTRACTS',
                        'ALL_CONTRACTS'
                      ).includes(effectiveScope)}
                      onChange={() => {
                        const transitions: ScopeTransitions = {
                          NOTHING: 'WATCHED_CONTRACTS_ONLY',
                          OWNED_CONTRACTS_ONLY: 'OWNED_AND_WATCHED_CONTRACTS',
                          WATCHED_CONTRACTS_ONLY: 'NOTHING',
                          OWNED_AND_WATCHED_CONTRACTS: 'OWNED_CONTRACTS_ONLY',
                          ALL_CONTRACTS: 'OWNED_CONTRACTS_ONLY',
                        };
                        if (transitions[effectiveScope]) void updateScope(field, transitions[effectiveScope]!);
                      }}
                    />
                  </TableCell>
                  {userCanReceiveAllContractsNotifications && (
                    <TableCell align="center">
                      <TableCheckbox
                        disabled={isSaving}
                        checked={effectiveScope === 'ALL_CONTRACTS'}
                        onChange={() => {
                          void updateScope(
                            field,
                            effectiveScope === 'ALL_CONTRACTS' ? 'OWNED_AND_WATCHED_CONTRACTS' : 'ALL_CONTRACTS'
                          );
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NotificationsPreferences;
