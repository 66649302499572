import { useCallback } from 'react';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { formatPercentage } from '@verticeone/utils/formatting';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

export const genericValueFormatter = (value: string, t: TFunction) => {
  if (value === 'true') {
    return t('TRUE');
  }

  if (value === 'false') {
    return t('FALSE');
  }

  return value;
};

export const useValueFormatter = () => {
  const { locale } = useLocaleContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.RESULTS' });

  return useCallback(
    (value: string, type: string) => {
      switch (type) {
        case 'percentage':
          return formatPercentage(parseFloat(value), { locale, minimumFractionDigits: 0, maximumFractionDigits: 1 });
        case 'integer':
        case 'string':
        case 'boolean':
          return genericValueFormatter(value, t);
        case 'items':
          return t('N_ITEMS', { count: parseInt(value) });
        default:
          return null;
      }
    },
    [locale, t]
  );
};
