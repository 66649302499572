import { DynamicFormField, DynamicFormSchema } from '../../../../../../forms/dynamicForms';
import { fieldDefsByType } from '../../../../../../forms/dynamicForms/fields/fields';

export function mapToFormInteraface(form: DynamicFormSchema): any {
  const result = mapFormToInputOutput(form);

  return {
    Input: {
      JsonSchema: {
        type: 'object',
        additionalProperties: true,
      },
    },
    Output: result,
  };
}

export function mapFormToInputOutput(form: DynamicFormSchema): any {
  let baseProperties = {};
  let baseRequired: string[] = [];

  form.fields.map(mapFieldToScheme).forEach(({ required, properties }) => {
    baseRequired.push(...required);
    baseProperties = { ...baseProperties, ...properties };
  });

  return {
    JsonSchema: {
      additionalProperties: true,
      type: 'object',
      properties: baseProperties,
      required: baseRequired,
    },
  };
}

export function mapFieldToScheme(field: DynamicFormField): { required: string[]; properties: object } {
  const getJsonSchema = fieldDefsByType[field.type]?.getJsonSchema;
  return getJsonSchema ? getJsonSchema(field as never) : { required: [], properties: {} };
}
