import { ComponentType, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Company } from '@vertice/components';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { toConstantCase } from '@verticeone/utils/strings';
import { PREFERENCES_PAGES, ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { BreadcrumbsV2 } from '@verticeone/design-system';
import { TabsBottomLineWrapper } from '@verticeone/design-system';
import { Tabs } from '@verticeone/design-system';
import { Tab } from '@verticeone/design-system';
import { Loader } from '@verticeone/design-system';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';
import Workflows from './Workflows/Workflows';
import User from './User/User';
import Directory from './Directory/Directory';
import { Integrations } from './Integrations';
import Customization from './Customization/Customization';
import './Preferences.scss';

type PreferencesProps = {
  page: string;
};

type TabDef = {
  path: string;
  component: ComponentType;
};

const getTabLabelKey = (tabId: string) => `PREFERENCES.${toConstantCase(tabId)}.TAB`;

export const Preferences = ({ page }: PreferencesProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isEnabled: isFeatureEnabled, isLoading: isFeaturesLoading } = useFeatures();
  const { isUserAdmin } = useLoggedUserAccountRoles();

  const someIntegrationEnabled =
    !isFeaturesLoading &&
    (isFeatureEnabled(FEATURES.SSO) ||
      isFeatureEnabled(FEATURES.USAGE_ANALYTICS) ||
      isFeatureEnabled(FEATURES.INTEGRATIONS));

  const customizationFeatureEnabled = !isFeaturesLoading && isFeatureEnabled(FEATURES.CONTRACTS_CUSTOMIZATION);

  const availableTabs = useMemo(
    (): TabDef[] => [
      ...(isUserAdmin ? [{ path: PREFERENCES_PAGES.COMPANY, component: Company }] : []),
      ...(isUserAdmin ? [{ path: PREFERENCES_PAGES.DIRECTORY, component: Directory }] : []),
      ...(isUserAdmin && someIntegrationEnabled
        ? [{ path: PREFERENCES_PAGES.INTEGRATIONS, component: Integrations }]
        : []),
      ...(isUserAdmin ? [{ path: PREFERENCES_PAGES.WORKFLOWS, component: Workflows }] : []),
      { path: PREFERENCES_PAGES.USER, component: User },
      ...(isUserAdmin && customizationFeatureEnabled
        ? [{ path: PREFERENCES_PAGES.CUSTOMIZATION, component: Customization }]
        : []),
    ],
    [customizationFeatureEnabled, isUserAdmin, someIntegrationEnabled]
  );
  const activeTab = availableTabs.find(({ path }) => path === page);

  useEffect(() => {
    if (!activeTab && availableTabs.length > 0) {
      navigate(`${ROUTES.PREFERENCES}/${availableTabs[0].path}`, { replace: true });
    }
  }, [activeTab, navigate, availableTabs]);

  return (
    <Stack gap={8} py={10}>
      <Stack gap={1} px={10}>
        <BreadcrumbsV2 items={[{ label: t('PREFERENCES.TITLE') }]} />
      </Stack>
      {activeTab ? (
        <>
          {availableTabs.length > 1 && (
            <TabsBottomLineWrapper paddingX={10}>
              <Tabs
                variant="outlined"
                value={activeTab.path}
                onChange={(_, value) => navigate(`../${value}`, { relative: 'path' })}
              >
                {availableTabs.map(({ path }) => (
                  <Tab key={path} value={path} label={t(getTabLabelKey(path))} />
                ))}
              </Tabs>
            </TabsBottomLineWrapper>
          )}
          <activeTab.component />
        </>
      ) : (
        <Loader />
      )}
    </Stack>
  );
};
