import React, { ReactNode, useMemo } from 'react';
import { CloudSubFeature } from './hooks/useCloudFeature';
import { CloudPermissions } from './hooks/useCloudPermissions';
import { createRequiredContext } from '@verticeone/design-system';

/**
 * Context of a loaded and user-accessible CCO page.
 */
export type CloudContextData = {
  subFeatures: Record<CloudSubFeature, boolean | string>;
  permissions: CloudPermissions;
};

const { CloudContextProvider: RawCloudContextProvider, useCloudContext } = createRequiredContext<
  CloudContextData,
  'Cloud'
>('Cloud');

export { useCloudContext };

export const CloudContextProvider = ({
  subFeatures,
  permissions,
  children,
}: CloudContextData & {
  children: ReactNode;
}) => {
  const memoizedValue = useMemo(() => ({ subFeatures, permissions }), [subFeatures, permissions]);
  return <RawCloudContextProvider value={memoizedValue}>{children}</RawCloudContextProvider>;
};
