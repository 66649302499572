import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@verticeone/design-system';
import { DesignSystemSize } from '@verticeone/design-system';
import PricingDataRequestDialog from './PricingDataRequestDialog';
import { useRequestBenchmarkingData } from '../../hooks/useRequestBenchmarkingData';
import { useGetVendorByIdQuery } from '@vertice/slices';

export type TriggerBenchmarkingRequestButtonProps = {
  vendorId?: string;
  productId?: string;
  buttonSize?: DesignSystemSize;
};

export const RequestBenchmarkingDataButton = ({
  vendorId,
  productId,
  buttonSize = 'S',
}: TriggerBenchmarkingRequestButtonProps) => {
  const { t } = useTranslation();
  const { triggerRequest, isRequestInProgress } = useRequestBenchmarkingData({ vendorId, productId });
  const [openedDialog, setOpenedDialog] = useState<boolean>(false);
  const { data: vendor } = useGetVendorByIdQuery({ vendorId: vendorId! }, { skip: !vendorId });

  const openDialog = () => {
    setOpenedDialog(true);
  };
  const closeDialog = () => {
    setOpenedDialog(false);
  };

  const onSuccess = async (note: string) => {
    await triggerRequest(note);
    closeDialog();
  };
  const requestButton = (
    <Button
      size={buttonSize}
      disabled={vendor?.isLimited}
      variant={productId ? 'solid' : 'ghost'}
      color="primary"
      onClick={openDialog}
    >
      {t('VENDOR.PRODUCTS.REQUEST_BENCHMARKING_BUTTON')}
    </Button>
  );

  return (
    <div>
      {vendor?.isLimited ? (
        <Tooltip size="M" content={t('VENDOR.PRODUCTS.DISABLED_BENCHMARKING_BUTTON_TOOLTIP')}>
          <div>{requestButton}</div>
        </Tooltip>
      ) : (
        requestButton
      )}
      <PricingDataRequestDialog
        open={openedDialog}
        onClose={closeDialog}
        onSuccess={onSuccess}
        disabled={isRequestInProgress}
      />
    </div>
  );
};
