import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardCloseButton, CardHeaderActions } from '@verticeone/design-system';
import { useInstanceData } from '../providers/InstanceProvider';
import OptimizeCardHeaderTitle from '../CardHeaderTitle';
import { DrawerHeader, DrawerHeaderTitle } from '@verticeone/design-system';

const BuyInstanceDrawerHeader = () => {
  const { t } = useTranslation();
  const { buyDrawer } = useInstanceData();

  return (
    <DrawerHeader noDivider>
      <DrawerHeaderTitle tag="h1" text={t('CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.TITLE')} />
      <OptimizeCardHeaderTitle />
      <CardHeaderActions>
        <CardCloseButton onClick={buyDrawer.close} />
      </CardHeaderActions>
    </DrawerHeader>
  );
};

export default BuyInstanceDrawerHeader;
