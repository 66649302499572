import { z } from 'zod';
import { zodSchema as purchaseIntakeSimpleFormSchema } from '../PurchaseIntakeSimpleForm/schema';
import { vendorSchema } from '../shared/schemas';

export const zodSchema = z.object({
  ...purchaseIntakeSimpleFormSchema.shape,

  vendor: vendorSchema,
  department: z.string(),
  targetSignDate: z.string(),

  contractCurrency: z.string(),
  approvedBudget: z.number(),
  verticeNegotiationRequested: z.boolean().optional(),
  internalNegotiationRequested: z.boolean().optional(),

  signingEntity: z.string().optional(),
  desiredContractLength: z.number().optional().nullish(),

  legalReviewRequired: z.boolean().optional(),
  securityReviewRequired: z.boolean().optional(),
  piiExpectedToBeHeld: z.boolean().optional(),

  contactVendorDirectly: z.boolean().optional(),
  discussMultiYearDeals: z.boolean().optional(),
  discussPlannedGrowth: z.boolean().optional(),
  discussCompetitors: z.boolean().optional(),
  discussCaseStudies: z.boolean().optional(),

  parentAccountId: z.string().nullish().optional(),
});

export type FormData = z.infer<typeof zodSchema>;
