import { Stack, StackProps, styled } from '@mui/material';
import { StyledCard } from '@verticeone/design-system';

const StyledStack = styled(Stack)(({ theme }) => ({
  [StyledCard]: {
    borderLeft: 'none',
    borderRadius: 0,
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.core.color3}`,
      borderTopLeftRadius: theme.spacing(4),
      borderBottomLeftRadius: theme.spacing(4),
    },
    '&:last-child': {
      borderTopRightRadius: theme.spacing(4),
      borderBottomRightRadius: theme.spacing(4),
    },
  },
}));

export type CardGroupProps = StackProps;

const CardGroup = ({ children }: CardGroupProps) => {
  return (
    <StyledStack direction="row" alignItems="stretch">
      {children}
    </StyledStack>
  );
};

export default CardGroup;
