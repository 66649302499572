import { FieldMapping, TaskDefinition } from '../../../../definitionsTypes';
import { isIOMappingConfiguration } from '../../../../definitions/taskDefinition';
import { Property, PropertyOrigin } from '../../types';
import { useMemo } from 'react';
import { getLastPartDiversioned } from '../../../../utils';
import { TICKET_ID_NAME } from '../JiraCommon/utils';

export const getJiraSyncInputMapping = (task: TaskDefinition) => {
  const ioMappingConfiguration = task.task.configurations?.find(isIOMappingConfiguration);
  const inputFields = ioMappingConfiguration?.mapping.inputFields;

  const pattern = 'jiraFields.';
  // eslint-disable-next-line no-useless-escape
  const escapedPattern = pattern.replace('.', `\.`); // Escape dot we need to keep

  let ticketId: string | undefined;
  let filterStatus: string[] | undefined;
  let variables: FieldMapping[] = [];

  if (inputFields) {
    inputFields.forEach((field) => {
      switch (field.name) {
        case TICKET_ID_NAME:
          ticketId = field.value;
          break;
        case 'filter.status':
          filterStatus = JSON.parse(field.value.substring(1, field.value.length - 1));
          break;
        default:
          const regex = new RegExp(`${escapedPattern}(.*)`); // Capturing group for the rest of the string
          const match = field.value.match(regex);
          if (match) {
            variables.push({ ...field, value: match[1] });
          } else {
            variables.push(field);
          }
      }
    });
  }

  return { ticketId, filterStatus, variables };
};

const CREATE_JIRA_IDENTIFIER = 'schema/core/jira/ticketId';

export const getAvailableCreateJiraTasks = (workflowVariables: Property[]) => {
  // variables - tickets
  const createJiraTickets = workflowVariables.filter(
    (variable) => getLastPartDiversioned(variable.xType) === CREATE_JIRA_IDENTIFIER
  );

  const createJiraTasks = createJiraTickets.map((variable) => variable.origin);
  const createJiraTicketsByTasks = {} as Record<string, Property>;
  createJiraTickets.forEach((variable) => (createJiraTicketsByTasks[variable.origin.id] = variable));
  const createJiraTasksByTickets = {} as Record<string, PropertyOrigin>;
  createJiraTickets.forEach((variable) => (createJiraTasksByTickets[variable.id] = variable.origin));
  return { createJiraTicketsByTasks, createJiraTasks, createJiraTasksByTickets };
};

export const useAvailableCreateJiraTasks = (workflowVariables: Property[]) => {
  // variables - tickets
  const processedVariables = useMemo(() => {
    const createJiraTickets = workflowVariables.filter(
      (variable) => getLastPartDiversioned(variable.xType) === CREATE_JIRA_IDENTIFIER
    );

    const createJiraTasks = createJiraTickets.map((variable) => variable.origin);
    const createJiraTicketsByTasks = {} as Record<string, Property>;
    createJiraTickets.forEach((variable) => (createJiraTicketsByTasks[variable.origin.id] = variable));
    const createJiraTasksByTickets = {} as Record<string, PropertyOrigin>;
    createJiraTickets.forEach((variable) => (createJiraTasksByTickets[variable.id] = variable.origin));
    return { createJiraTicketsByTasks, createJiraTasks, createJiraTasksByTickets };
  }, [workflowVariables]);

  return processedVariables;
};
