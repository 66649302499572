import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubdirectoryArrowRightOutlined } from '@mui/icons-material';
import { ChipButton, Divider, Text, IconWrapper } from '@verticeone/design-system';
import { Stack } from '@mui/material';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../constants';
import { Property, isExtendedProperty } from './types';

type VariableBaseProps = {
  property: Property;
  isChild?: boolean;
};

const VariableBase: FC<VariableBaseProps> = ({ property, isChild = false }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1}>
        <Text size={isChild ? 'XS' : 'S'} variant="caption">
          {property.label}
        </Text>
        {isExtendedProperty(property) && property.required && (
          <ChipButton variant="outline" color="warning" isActive={false} size="XXS">
            {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_GATEWAY.DIALOG.REQUIRED')}
          </ChipButton>
        )}
      </Stack>
      <Stack direction="row" gap={1} ml={2}>
        {!isChild && property.origin.label && (
          <ChipButton isActive={false} size="XXS">
            {property.origin.label}
          </ChipButton>
        )}
        {!isChild && (
          <ChipButton isActive={false} size="XXS">
            {property.origin.kind}
          </ChipButton>
        )}
        {property.typeLabel?.map((typeLabel) => (
          <ChipButton key={typeLabel} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} isActive={false} size="XXS">
            {typeLabel}
          </ChipButton>
        ))}
      </Stack>
    </Stack>
  );
};

type VariableProps = {
  property: Property;
  isChild?: boolean;
};

const Variable: FC<VariableProps> = ({ property, isChild = false }) => {
  if (!isExtendedProperty(property)) {
    return <VariableBase property={property} isChild={isChild} />;
  }

  return (
    <Stack direction="column" gap={1}>
      <Stack direction="row" gap={1}>
        {isChild && <IconWrapper icon={SubdirectoryArrowRightOutlined} />}
        <VariableBase isChild={isChild} property={property} />
      </Stack>
      {property.properties.length > 0 && (
        <Stack gap={2} pl={4}>
          {property.properties.map((childProperty) => (
            <Variable isChild key={`${childProperty.origin.id}-${childProperty.id}`} property={childProperty} />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

type VariablesListProps = {
  title: string;
  properties: Property[];
};

export const VariablesList: FC<VariablesListProps> = ({ properties, title }) => {
  return (
    <Stack gap={2}>
      <Text align="left" variant="body-bold">
        {title}
      </Text>
      <Divider />
      <Stack gap={2}>
        {properties.map((property) => (
          <Variable key={`${property.origin.id}-${property.id}`} property={property} />
        ))}
      </Stack>
    </Stack>
  );
};
