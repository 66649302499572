import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { Text } from '@verticeone/design-system';
import styles from './StatusBadge.module.scss';

export enum StatusBadgeVariant {
  NEW = 'NEW',
  ERROR = 'ERROR',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PROGRESS = 'PROGRESS',
}

type StatusBadgeProps = {
  variant: StatusBadgeVariant;
};

export const StatusBadge = ({ variant }: StatusBadgeProps) => {
  const { t } = useTranslation();

  return (
    <span className={clsx(styles.badge, styles[`badge--${variant.toLowerCase()}`])}>
      <Text variant="button-bold" size="S">
        {t(`PREFERENCES.INTEGRATIONS.STATUS.${variant}`)}
      </Text>
    </span>
  );
};
