import React from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';

export const MultilineHeaderTooltip = ({ lines }: { lines: string[] }) => {
  return (
    <Stack gap={2}>
      {lines.map((line, index) => (
        <Text key={index} size="S" variant="body-regular" color="text4">
          {line}
        </Text>
      ))}
    </Stack>
  );
};

export default MultilineHeaderTooltip;
