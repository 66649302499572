import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { randomId } from '@mui/x-data-grid-generator';
import { Account, useGetAccountQuery, VendorLegalInfo } from '@vertice/slices';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import AccordionLegal from './AccordionLegal';
import FileItem from './FileItem';
import useMetricsDataModel, { InsightMetrics } from './useMetricsDataModel';
import { InsightLink, useLinksDataModelLegal } from './useLinksDataModel';
import styles from './VendorDiligenceInsights.module.scss';
import { isLegalContractInsightDataAvailable } from './utils';
import BlurredContent from './BlurredContent';
import { Loader } from 'react-feather';
import BeAwareParagraph from './BeAwareParagraph';
import { Text } from '@verticeone/design-system';
import { Divider } from '@verticeone/design-system';

interface VendorLegalProps {
  isVendorLegalDataLoading: boolean;
  accountId: string;
  vendorId: string;
  vendorLegalData?: VendorLegalInfo;
}

const VendorLegal: FC<VendorLegalProps> = ({ isVendorLegalDataLoading, accountId, vendorId, vendorLegalData = {} }) => {
  const { t } = useTranslation();
  const metricsDataModel = useMetricsDataModel(vendorLegalData);
  const linksDataModel = useLinksDataModelLegal(vendorLegalData);
  const contractInsightDataAvailable = isLegalContractInsightDataAvailable(vendorLegalData);
  const { data: accountDetails, isLoading: isCompanyDataLoading } = useGetAccountQuery(
    { accountId: accountId! },
    { skip: !accountId }
  ) as { data: Account; isLoading: boolean };

  const getTooltipContent = (data: InsightMetrics) => (
    <Typography variant="body-regular-m" color="var(--tokens-color-dark-text-2)">
      {data.tooltip}
    </Typography>
  );

  const contractsInsights = ['PAYMENT', 'LIABILITY', 'RENEWAL_USAGE', 'MISC'].filter((item) =>
    metricsDataModel[item].some((data: InsightMetrics) => data.text)
  );

  const contractsInsightLabels = contractsInsights.map((item) => t('LEGAL_INFO.INSIGHTS.' + item));

  const documentsAndLinks = Object.keys(linksDataModel).filter((item) =>
    linksDataModel[item].some((data: InsightLink) => data.link)
  );

  const documentsAndLinkLabels = documentsAndLinks.map((item) => t('LEGAL_INFO.DOCUMENTS_AND_LINKS.' + item));

  const stringToNumberInRange = (str: string, from: number, to: number) => {
    let i;
    let output = 0;
    for (i = 0; i < str.length; i++) {
      output += str.charCodeAt(i);
    }
    output = output > from ? output : from;
    let spread = to + 1 - from;
    return from + (output % spread);
  };

  return (
    <Stack py={6} gap={4}>
      {!contractInsightDataAvailable && <BlurredContent accountId={accountId} vendorId={vendorId} mode="NO_DATA" />}
      <Stack px={6} gap={4}>
        {!isVendorLegalDataLoading && vendorLegalData && !isCompanyDataLoading ? (
          <>
            {contractInsightDataAvailable && !!contractsInsights.length && (
              <>
                {accountDetails.accountType === 'DEMO' && (
                  <>
                    <Stack gap="16px">
                      <Typography variant="caption-s" className={styles['sub-header']}>
                        <DocumentScannerOutlinedIcon fontSize="inherit" />
                        {t('LEGAL_INFO.CONTRACT_TERM_BENCHMARKING')}
                      </Typography>
                      <div className={styles['benchmarking-wrap']}>
                        <Typography variant="body-bold-s" className={styles['benchmarking-content']}>
                          <span className={styles.percentage}>{stringToNumberInRange(vendorId, 15, 20)}%</span>
                          <span>{t('LEGAL_INFO.BENCHMARKING_CONTENT')}</span>
                        </Typography>
                      </div>
                    </Stack>
                    <Divider />
                  </>
                )}
                <Stack gap="16px">
                  <Typography variant="caption-s" className={styles['sub-header']}>
                    <InsightsOutlinedIcon fontSize="inherit" />
                    {t('LEGAL_INFO.CONTRACT_INSIGHTS')}
                  </Typography>
                  <Stack className={styles['accordion-list']}>
                    {contractsInsights.map((item, i) => (
                      <AccordionLegal
                        key={`insight-${i}`}
                        ariaControls={`legal${i}-content`}
                        accordionSummaryId={`legal${i}-header`}
                        title={contractsInsightLabels[i]}
                        accordionDetails={
                          <Stack gap="8px">
                            {metricsDataModel[item]
                              .filter((data: InsightMetrics) => data.text)
                              .map((data) => (
                                <div className={styles['legal-accordion-row']} key={randomId()}>
                                  <div className={styles['legal-accordion-row-left']}>
                                    <Tooltip title={getTooltipContent(data)} placement="top" arrow>
                                      <Typography
                                        variant="body-bold-s"
                                        className={styles['legal-accordion-row-key-word']}
                                      >
                                        {data.prefix}
                                      </Typography>
                                    </Tooltip>{' '}
                                    <Text variant="body-regular" size="S" color="text2">
                                      {data.text}
                                    </Text>
                                  </div>
                                  {data.clause && (
                                    <Typography variant="button-xs" className={styles['legal-accordion-row-clause']}>
                                      {data.clause}
                                    </Typography>
                                  )}
                                </div>
                              ))}
                          </Stack>
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
              </>
            )}
            {vendorLegalData?.otherConsiderations && (
              <>
                <Divider />
                <Stack gap="16px">
                  <Typography variant="caption-s" className={styles['sub-header']}>
                    <InfoOutlinedIcon fontSize="inherit" />
                    {t('LEGAL_INFO.OTHER_CONSIDERATION')}
                  </Typography>
                  <Typography variant="body-bold-s" className={styles['considerations-notes-field']}>
                    {vendorLegalData?.otherConsiderations}
                  </Typography>
                </Stack>
              </>
            )}
            {!!documentsAndLinks.length && (
              <>
                <Divider />
                <Stack gap="16px">
                  <Typography variant="caption-s" className={styles['sub-header']}>
                    <FilePresentOutlinedIcon fontSize="inherit" />
                    {t('LEGAL_INFO.DOCUMENTATION_AND_LINKS')}
                  </Typography>
                  <Stack className={styles['accordion-list']}>
                    {documentsAndLinks.map((item, i) => (
                      <AccordionLegal
                        key={`documentation-and-links-${i}`}
                        ariaControls={`documentation-and-links${i}-content`}
                        accordionSummaryId={`documentation-and-links${i}-header`}
                        title={documentsAndLinkLabels[i]}
                        accordionDetails={
                          <div className={styles['chip-wrap-panel']}>
                            {linksDataModel[item]
                              .filter((data: InsightLink) => data.link)
                              .map((data: InsightLink) => (
                                <FileItem title={data.label} link={data.link!} key={randomId()} />
                              ))}
                          </div>
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
              </>
            )}
            <BeAwareParagraph />
          </>
        ) : (
          <Loader />
        )}
      </Stack>
    </Stack>
  );
};

export default VendorLegal;
