import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardActions, Stack, styled } from '@mui/material';
import { type StackProps } from '@mui/material/Stack/Stack';
import { type ButtonProps } from '@mui/material/Button/Button';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import { Button } from '@verticeone/design-system';
import { cardHeaderPaddingDefinitions } from '@verticeone/design-system';

import { useInstanceData } from './providers/InstanceProvider';

type StyledProps = {
  $expanded?: boolean;
};

type CardActionsRowProps = {
  children: React.ReactNode;
};

const StyledStack = styled(Stack)<StyledProps & StackProps>(({ theme, $expanded }) => ({
  flexDirection: 'row-reverse',
  padding: $expanded
    ? `${cardHeaderPaddingDefinitions.medium}px`
    : `0 ${cardHeaderPaddingDefinitions.medium}px ${cardHeaderPaddingDefinitions.medium}px`,
  borderTop: $expanded ? `1px solid ${theme.palette.core.color3}` : 'none',
}));

const StyledCollapseButton = styled(Button)<StyledProps & ButtonProps>(({ $expanded }) => ({
  ...($expanded
    ? {
        padding: 0,
        '&:hover': {
          padding: 0,
        },
      }
    : {}),
}));

const CardActionsRow = ({ children }: CardActionsRowProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE' });
  const { detail, item } = useInstanceData();
  const collapseButtonLabel = detail.isExpanded ? t('ACTIONS_ROW.HIDE_CONTENT') : t('ACTIONS_ROW.EXPAND_CONTENT');

  if (item.missingRiLookup) {
    return null;
  }

  return (
    <StyledStack
      $expanded={detail.isExpanded}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <StyledCollapseButton
        $expanded={detail.isExpanded}
        aria-expanded={detail.isExpanded}
        aria-label={collapseButtonLabel}
        variant="plain"
        size="S"
        color="neutral"
        onClick={detail.toggle}
      >
        {detail.isExpanded ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        {collapseButtonLabel}
      </StyledCollapseButton>
      {detail.isExpanded && <CardActions>{children}</CardActions>}
    </StyledStack>
  );
};

export default CardActionsRow;
