import { PropsWithChildren } from 'react';
import { Card, testProps } from '@verticeone/design-system';

type OverviewProps = PropsWithChildren & {
  testId?: string;
};

const Overview = ({ children, testId }: OverviewProps) => (
  <Card gap={6} {...testProps(testId)}>
    {children}
  </Card>
);

export default Overview;
