import { AddExistingContractFormData, isAddExistingContractFormData } from '../AddExistingContractWizard/types';
import {
  AdvancedLinearCostModelLine,
  BillingFrequency,
  ContractCreate,
  ContractPartContractual,
  ContractPartContractualFinancial,
  ContractPartContractualLifecycle,
  RollFrequency,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ExternalType, FormDataProductItem } from '../types';
import { keepOnlyLocalDatePart } from '@verticeone/utils/dates';
import { omitEmptyValues } from '@verticeone/utils/objects';
import { NewPurchaseFormData } from '../NewPurchaseWizard/types';
import { isRenewalFormData, RenewalFormData } from '../RenewalWizard/types';
import { CostModelBase } from '@vertice/core/src/modules/saas/contract/costModels/types';
import { isAdvancedLinearApproximationModel } from '@vertice/core/src/modules/saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';
import { v1 as uuid } from 'uuid';
import {
  ANNUAL_COST_IDENTIFIER,
  buildProductIdentifier,
  convertProductFrequencyToItemFrequency,
} from '@vertice/core/src/modules/saas/contract/costModels/AdvancedLinearApproximation/utils';
import { isNil } from 'lodash';
import { CostModelProductItem } from '@vertice/core/src/modules/saas/contract/components/CostModelProductList';
import { ProductItem } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';

export const formDataToExternalContract = (
  formData: AddExistingContractFormData | NewPurchaseFormData | RenewalFormData
) => {
  const externalContract: Partial<ExternalType> = {
    legalReviewRequired: formData.legalReviewRequired,
    securityReviewRequired: formData.securityReviewRequired,
    isPIIHeldExpected: formData.piiExpectedToBeHeld,
    contactVendorDirectly: formData.contactVendorDirectly,
    multiYear: formData.discussMultiYearDeals,
    caseStudies: formData.discussCaseStudies,
    plannedGrowth: formData.discussPlannedGrowth,
    discussCompetitors: formData.discussCompetitors,
    signatoryCustomer: formData.signatoryName,
    signatoryEmail: formData.signatoryEmail,
  };

  if (isAddExistingContractFormData(formData) || isRenewalFormData(formData)) {
    externalContract.vendorAccountManager = formData.vendorContactName;
    externalContract.vendorAccountManagerEmail = formData.vendorContactEmail;
  }

  return externalContract;
};

const existingFormDataToCostModelConfiguration = (
  formData: AddExistingContractFormData,
  getCostModelBase: () => CostModelBase
) => {
  const isAdvancedCostModel = isAdvancedLinearApproximationModel(getCostModelBase());

  if (isAdvancedCostModel) {
    return {
      baseline: getCostModelBase(),
      negotiated: {
        ...getCostModelBase(),
        model: {
          items: [
            ...(!isNil(formData.totalAnnualCost)
              ? [
                  {
                    id: uuid(),
                    lineItemType: 'OVERRIDE',
                    lineItemCategory: 'FORM_OVERRIDE',
                    identifier: ANNUAL_COST_IDENTIFIER,
                    totalPrice: formData.totalAnnualCost,
                  } satisfies AdvancedLinearCostModelLine,
                ]
              : []),
            ...formDataProductsToCostModelProductItems(formData),
          ],
        },
      },
    };
  }

  return {
    baseline: {
      ...getCostModelBase(),
      model: omitEmptyValues({
        annual_cost: formData.totalAnnualCost,
      }),
    },
    negotiated: getCostModelBase(),
  };
};

export const formDataToRawCostModel = (
  formData: NewPurchaseFormData | RenewalFormData,
  getCostModelBase: () => CostModelBase
) => {
  const isAdvancedCostModel = isAdvancedLinearApproximationModel(getCostModelBase());

  if (isAdvancedCostModel) {
    const costModel = {
      baseline: getCostModelBase(),
      negotiated: {
        ...getCostModelBase(),
        model: {
          items: [...formDataProductsToCostModelProductItems(formData)],
        },
      },
    };
    return {
      configuration: {
        negotiated: costModel.negotiated.id,
        baseline: costModel.baseline.id,
      },
      instances: [costModel.baseline, costModel.negotiated],
    };
  }

  return undefined;
};

export const formDataToExistingContractCreate = (
  formData: AddExistingContractFormData,
  getCostModelBase: () => CostModelBase
): ContractCreate => {
  return {
    record: {
      contractCategory: 'SAAS',
      contractOrigin: 'EXISTING',
    },
    parts: {
      model: {
        cost: {
          configuration: existingFormDataToCostModelConfiguration(formData, getCostModelBase),
        },
      },
      contractual: omitEmptyValues({
        vendor: omitEmptyValues({
          vendorType: formData.vendor?.type ?? 'PREDEFINED',
          vendorId: formData.vendor?.id || undefined,
          vendorName: formData.vendor?.name ?? undefined,
          vendorContact: omitEmptyValues({
            name: formData.vendorContactName,
            email: formData.vendorContactEmail,
          }),
        }),
        products: formDataProductsToContractProducts(formData.products),
        financial: omitEmptyValues({
          baseCurrency: formData.contractCurrency,
          billingFrequency: getBillingFrequency(formData.billingFrequency),
        }),
        lifecycle: omitEmptyValues({
          startDate: keepOnlyLocalDatePart(formData.startDate) ?? undefined,
          renewalDate: keepOnlyLocalDatePart(formData.renewalDate) ?? undefined,
          autoRenewalDeadline: keepOnlyLocalDatePart(formData.autoRenewalDate) ?? undefined,
          rollFrequency: getRollingFrequency(formData.rollingFrequency),
        }),
        signatory: omitEmptyValues({
          signingDate: keepOnlyLocalDatePart(formData.signDate || formData.startDate) ?? undefined,
          signer: omitEmptyValues({
            name: formData.signatoryName,
            email: formData.signatoryEmail,
          }),
          signingEntity: omitEmptyValues({
            name: formData.signingEntity,
          }),
        }),
      }),
    },
    classification: omitEmptyValues({
      departmentId: formData?.departmentId ?? undefined,
    }),
  };
};

const formDataProductsToCostModelProductItems = (
  formData: AddExistingContractFormData | NewPurchaseFormData | RenewalFormData
) => {
  if (formData.products.length === 0) {
    return [];
  }

  const isRolling =
    isAddExistingContractFormData(formData) && formData.rollingFrequency && formData.rollingFrequency !== 'NO';

  return formData.products.map(
    (p: CostModelProductItem) =>
      ({
        id: p.productId,
        lineItemType: 'DEBIT',
        lineItemCategory: 'PRODUCT',
        identifier: buildProductIdentifier(p),
        licenseType: p.licenseType,
        unitPrice: p.licenseCost,
        licenseCount: p.numberOfLicences,
        totalPrice: p.totalCost,
        startDate: isRolling ? undefined : p.allocationSpan?.startDate,
        endDate: isRolling ? undefined : p.allocationSpan?.endDate,
        rollingFrequency: isRolling
          ? undefined
          : convertProductFrequencyToItemFrequency(p.allocationSpan?.rollingFrequency),
      } satisfies AdvancedLinearCostModelLine)
  );
};

const formDataProductsToContractProducts = (
  formProducts: FormDataProductItem[]
): ContractPartContractual['products'] | undefined => {
  if (formProducts.length === 0) {
    return undefined;
  }

  return formProducts.map((p: ProductItem) => ({
    licenseCount: p.numberOfLicences ?? undefined,
    productId: p.productId,
    licenseType: p.licenseType ?? undefined,
    annualCost: p.annualCost ?? undefined,
  }));
};

const getBillingFrequency = (
  billingFrequency?: BillingFrequency | null
): ContractPartContractualFinancial['billingFrequency'] => {
  if (!billingFrequency) {
    return undefined;
  }
  return billingFrequency;
};

const getRollingFrequency = (
  rollingFrequency?: RollFrequency | null
): ContractPartContractualLifecycle['rollFrequency'] => {
  if (!rollingFrequency) {
    return undefined;
  }
  return rollingFrequency;
};

export const getProductsWithoutAnnualCost = (prods: ProductItem[]) => {
  return prods.map(({ annualCost, ...productProps }) => ({
    ...productProps,
    annualCost: null,
  }));
};

export const getProductsWithoutLicenseAndTotalCosts = (prods: CostModelProductItem[]) => {
  return prods.map(({ licenseCost, totalCost, allocationSpan, ...productProps }) => ({
    ...productProps,
    licenseCost: null,
    totalCost: null,
    allocationSpan: null,
  }));
};
