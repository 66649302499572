import { FC, useEffect, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { TextField, TextFieldArea, TextFieldCaption } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { EditServiceTaskFormData } from './schema';

type EditServiceTaskFormProps = {
  onDirty: () => void;
};

export const EditServiceTaskForm: FC<EditServiceTaskFormProps> = ({ onDirty }) => {
  const { t } = useTranslation();
  const id = useId();

  const { register, formState } = useFormContext<EditServiceTaskFormData>();

  useEffect(() => {
    if (formState.isDirty) {
      onDirty();
    }
  }, [formState.isDirty, onDirty]);

  return (
    <Stack gap={4} direction={'column'} width={'100%'}>
      <Stack gap={1}>
        <TextFieldCaption
          label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.NAME')}
          size="XS"
          htmlFor={`${id}-name`}
        />
        <TextField
          {...register('name')}
          id={`${id}-name`}
          variant="outlined"
          size="S"
          fullWidth
          autoComplete="off"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        />
      </Stack>
      <Stack gap={1}>
        <TextFieldCaption
          label={t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.DESCRIPTION')}
          size="XS"
          htmlFor={`${id}-description`}
        />
        <TextFieldArea
          {...register('description')}
          id={`${id}-description`}
          variant="outline"
          size="S"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          autoComplete="off"
          maxRows={6}
          style={{ resize: 'none' }}
        />
      </Stack>
    </Stack>
  );
};
