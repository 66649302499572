import React, { useCallback } from 'react';
import { Divider, styled } from '@mui/material';
import { DataGridPro, gridClasses, GridColumnMenuProps } from '@mui/x-data-grid-pro';
import { SortOutlined as SortOutlinedIcon } from '@mui/icons-material';
import type { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import type { GridValidRowModel } from '@mui/x-data-grid';

import { sortingOrder } from './constants';
import StyledGridColumnMenuContainer from './components/GridColumnMenu';
import GridColumnMenuSortItem from './components/SortGridMenuItems';
import FilterGridMenuItem from './components/FilterGridMenuItem';
import { DesignSystemColor } from '@verticeone/design-system';

type StyledColorProps = { $color: DesignSystemColor };

const AscendingIcon = styled(SortOutlinedIcon)<StyledColorProps>(({ $color, theme }) => ({
  transform: 'scale(1, -1)',
  fill: theme.palette[$color].color1,
}));

const DescendingIcon = styled(SortOutlinedIcon)<StyledColorProps>(({ $color, theme }) => ({
  fill: theme.palette[$color].color1,
}));

const ColumnMenu = ({ ...props }: GridColumnMenuProps) => (
  <StyledGridColumnMenuContainer {...props}>
    <GridColumnMenuSortItem onClick={props.hideMenu} colDef={props.colDef} />
    {props.colDef.filterable && <Divider /> && <FilterGridMenuItem onClick={props.hideMenu} colDef={props.colDef} />}
  </StyledGridColumnMenuContainer>
);

export type DataGridProp<R extends GridValidRowModel = any> = DataGridProProps<R> & {
  color?: DesignSystemColor;
};

const StyledDataGridPro = styled(DataGridPro)<StyledColorProps>(({ $color, theme }) => ({
  borderRadius: theme.spacing(3),
  display: 'grid',
  gridTemplateRows: 'auto min-content',
  [`.${gridClasses.main}`]: {
    borderRadius: theme.spacing(3),
  },
  [`.${gridClasses.columnHeaders}`]: {
    backgroundColor: theme.palette.core.color1,
  },
  [`.${gridClasses.columnSeparator}`]: {
    visibility: 'visible',
  },
  "[role='columnheader']": {
    backgroundColor: theme.palette.core.color1,
    [`.${gridClasses.columnHeaderTitle}`]: {
      color: theme.palette[$color].color2,
      fontWeight: 600,
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  "[role='cell']": {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
})) as <R extends GridValidRowModel = any>(
  props: DataGridProProps<R> & StyledColorProps & React.RefAttributes<HTMLDivElement>
) => JSX.Element;

/**
 * @deprecated please use @verticeone/design-system/src/components/DataGrid instead
 */
const DataGrid = <R extends GridValidRowModel = any>({ color = 'primary', ...props }: DataGridProp<R>) => {
  const getAscendingIcon = useCallback(() => <AscendingIcon $color={color} />, [color]);
  const getDescendingIcon = useCallback(() => <DescendingIcon $color={color} />, [color]);

  if (props.disableColumnFilter === undefined) {
    // TODO: overriding the default value - remove it when BE will implement filtering
    props.disableColumnFilter = true;
  }
  return (
    <StyledDataGridPro
      sortingOrder={sortingOrder}
      autoHeight
      $color={color}
      {...props}
      slots={{
        columnUnsortedIcon: getAscendingIcon,
        columnSortedAscendingIcon: getAscendingIcon,
        columnSortedDescendingIcon: getDescendingIcon,
        columnMenu: ColumnMenu,
        ...props.slots,
      }}
    />
  );
};

export default DataGrid;
