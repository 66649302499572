import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import {
  NotificationSettingsItem,
  useListSlackChannelsPaginatedQuery,
  useUpdateSlackIntegrationConfigMutation,
} from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { isValidIntegrationResponse } from '../../../common';
import { Box, styled } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { ChannelSettingsFormData, ChannelSetup, ChannelSetupProps } from './ChannelSetup';
import { SlackPreviewRenewal } from './SlackPreviewRenewal';
import { SlackPreviewCreation } from './SlackPreviewCreation';
import { SlackPreviewRequestPurchase } from './SlackPreviewRequestPurchase';

type ChannelSection = {
  title: string;
  componentProps: Pick<
    ChannelSetupProps,
    'channelSettings' | 'dataSection' | 'hasTimeThreshold' | 'channelLabel' | 'channelTooltip' | 'slackPreviewComponent'
  >;
};

const CardSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.global.color.coolGray[10].main,
  textTransform: 'uppercase',
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
}));

type ChannelSetupWrapperProps = {
  notificationSettings?: { [p: string]: NotificationSettingsItem[] };
};

export type SaveSettingsData = {
  contractRenewal?: ChannelSettingsFormData;
  contractCreation?: ChannelSettingsFormData;
  requestPurchase?: ChannelSettingsFormData;
};

export type SaveSettingsDataSections = keyof SaveSettingsData;

export const DEFAULT_CHANNEL_RENEWAL_SETTINGS = {
  channelId: '',
  timeThreshold: 'P3M',
};
export const DEFAULT_CHANNEL_SETTINGS = {
  channelId: '',
};

export const ChannelSetupWrapper: FC<ChannelSetupWrapperProps> = ({ notificationSettings }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const [loadingSection, setLoadingSection] = useState<SaveSettingsDataSections>();

  const [saveNotificationSettings] = useUpdateSlackIntegrationConfigMutation();

  const { data: slackChannelsData, isLoading: isLoadingSlackChannels } = useListSlackChannelsPaginatedQuery({
    accountId,
  });

  const [channelRenewalSettings, setChannelRenewalSettings] = useState<NotificationSettingsItem | undefined>();
  const [channelCreationSettings, setChannelCreationSettings] = useState<NotificationSettingsItem | undefined>();
  const [channelRequestPurchaseSettings, setChannelRequestPurchaseSettings] = useState<
    NotificationSettingsItem | undefined
  >();

  const stateMapper: Record<
    SaveSettingsDataSections,
    {
      state: NotificationSettingsItem | undefined;
      setState: Dispatch<SetStateAction<NotificationSettingsItem | undefined>>;
    }
  > = useMemo(
    () => ({
      contractRenewal: {
        state: channelRenewalSettings,
        setState: setChannelRenewalSettings,
      },
      contractCreation: {
        state: channelCreationSettings,
        setState: setChannelCreationSettings,
      },
      requestPurchase: {
        state: channelRequestPurchaseSettings,
        setState: setChannelRequestPurchaseSettings,
      },
    }),
    [channelRenewalSettings, channelCreationSettings, channelRequestPurchaseSettings]
  );

  useEffect(() => {
    notificationSettings &&
      Object.keys(notificationSettings).forEach((k) => {
        const key = k as SaveSettingsDataSections;
        stateMapper[key]?.setState(notificationSettings[key][0]);
      });
  }, [stateMapper, notificationSettings]);

  const onSave = useCallback(
    async (formData: SaveSettingsData) => {
      setLoadingSection(Object.keys(formData)[0] as SaveSettingsDataSections);

      const setupNotificationSettings = () => {
        const settings = {} as Record<SaveSettingsDataSections, any>;
        Object.keys(stateMapper).forEach((k) => {
          const key = k as SaveSettingsDataSections;
          const keyData = formData[key]
            ? formData[key]?.channelId
              ? formData[key]
              : undefined
            : stateMapper[key].state;
          settings[key] = keyData ? [keyData] : [];
        });
        return settings;
      };

      const response = await saveNotificationSettings({
        accountId: accountId,
        body: {
          notificationSettings: setupNotificationSettings(),
        },
      });

      if (isValidIntegrationResponse(response) && response.data?.notificationSettings) {
        const settingsResponse = response.data?.notificationSettings;
        Object.keys(settingsResponse).forEach((k) => {
          const key = k as SaveSettingsDataSections;
          // Update only data which were posted
          if (settingsResponse[key][0] && formData[key]) {
            stateMapper[key]?.setState(settingsResponse[key][0]);
          }
        });
      }
      setLoadingSection(undefined);
    },
    [accountId, saveNotificationSettings, stateMapper]
  );

  const sections: ChannelSection[] = [
    {
      title: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_RENEWAL.TITLE'),
      componentProps: {
        dataSection: 'contractRenewal',
        channelSettings: channelRenewalSettings || DEFAULT_CHANNEL_RENEWAL_SETTINGS,
        hasTimeThreshold: true,
        channelLabel: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_RENEWAL.CHANNEL_LABEL'),
        channelTooltip: t(
          'PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_RENEWAL.CHANNEL_SELECTION_TITLE_TOOLTIP'
        ),
        slackPreviewComponent: <SlackPreviewRenewal />,
      },
    },
    {
      title: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_CREATION.TITLE'),
      componentProps: {
        dataSection: 'contractCreation',
        channelSettings: channelCreationSettings || DEFAULT_CHANNEL_SETTINGS,
        hasTimeThreshold: false,
        channelLabel: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_CREATION.CHANNEL_LABEL'),
        channelTooltip: t(
          'PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_CREATION.CHANNEL_SELECTION_TITLE_TOOLTIP'
        ),
        slackPreviewComponent: <SlackPreviewCreation />,
      },
    },
    {
      title: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_REQUEST_PURCHASE.TITLE'),
      componentProps: {
        dataSection: 'requestPurchase',
        channelSettings: channelRequestPurchaseSettings || DEFAULT_CHANNEL_SETTINGS,
        hasTimeThreshold: false,
        channelLabel: t(
          'PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_REQUEST_PURCHASE.CHANNEL_LABEL'
        ),
        channelTooltip: t(
          'PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.SECTION_REQUEST_PURCHASE.CHANNEL_SELECTION_TITLE_TOOLTIP'
        ),
        slackPreviewComponent: <SlackPreviewRequestPurchase />,
      },
    },
  ];

  return (
    <>
      {sections.map((Section, index) => (
        <div key={index}>
          <CardSection>
            <Text color="text2" variant="label" size="XS">
              {Section.title}
            </Text>
          </CardSection>
          <ChannelSetup
            channels={slackChannelsData?.items || []}
            onSave={onSave}
            savingSection={loadingSection}
            loadingChannels={isLoadingSlackChannels}
            {...Section.componentProps}
          />
        </div>
      ))}
    </>
  );
};
