import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { DataGrid, type GridColDef } from '@verticeone/design-system';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  gridDetailPanelExpandedRowIdsSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridEventListener,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '../../../constants';
import { ReservedInstance } from '../useRIPurchaseData';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import DetailPanel from './DetailPanel';
import { Button } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ChevronRightOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { IconButton } from '@verticeone/design-system';
import { GridStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useLocaleDateFormatter } from './utils';
import { Checkbox } from '@verticeone/design-system';
import { Can } from '@verticeone/auth/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import useTermFormatter from '../../../hooks/useTermFormater';

type RIPurchaseTableProps = {
  data: ReservedInstance[];
  visibleRows?: number;
  testId: string;
  onLoadMore?: () => void;
  isPending?: boolean;
  onCancelTransactions?: (rows: Map<GridRowId, any>) => void;
};

const ROW_HEIGHT = 60;
const DEFAULT_VISIBLE_ROWS = 5;

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'row' | 'api'>) {
  const {
    id,
    api: { state },
    row: { scheduledAt, details },
  } = props;
  const apiRef = useGridApiContext();
  const dateFormatter = useLocaleDateFormatter();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_PURCHASE.TOGGLE_DETAIL' });

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  const isExpanded = gridDetailPanelExpandedRowIdsSelector(state as GridStatePro).includes(props.id);

  return (
    <Stack gap={1} direction="row" alignItems="center" data-field="scheduledAt">
      {details && (
        <IconButton
          icon={isExpanded ? ExpandMoreOutlined : ChevronRightOutlined}
          size="S"
          testId="detail-panel-toggle"
          tabIndex={-1}
          variant="plain"
          disabled={!hasDetail}
          aria-label={isExpanded ? t('CLOSE') : t('OPEN')}
        />
      )}
      {scheduledAt ? dateFormatter.format(new Date(scheduledAt)) : null}
    </Stack>
  );
}

const RIPurchaseTable = ({
  data,
  visibleRows = DEFAULT_VISIBLE_ROWS,
  testId,
  onLoadMore,
  isPending,
  onCancelTransactions,
}: RIPurchaseTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_PURCHASE' });
  const formatCurrency = useFormatCurrency();
  const { accountId } = useAccountContext();
  const apiRef = useGridApiRef();
  const [expandedRows, setExpandedRows] = React.useState<GridRowId[]>([]);
  const [selectedRows, setSelectedRows] = useState(apiRef?.current?.getSelectedRows?.() || new Map());
  const dateFormatter = useLocaleDateFormatter();
  const formatTerm = useTermFormatter();

  const getRowId = (row: GridRowModel) => row.id;

  const columns: GridColDef<ReservedInstance>[] = [
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      renderCell: (params) => <CustomDetailPanelToggle {...params} />,
      headerName: t('COLUMN.DATE_OF_PURCHASE'),
      minWidth: 170,
      flex: 1,
    },
    {
      field: isPending ? 'purchasePlannedAt' : 'purchasedAt',
      headerName: isPending ? t('COLUMN.PURCHASE_PLANNED_AT') : t('COLUMN.PURCHASED_AT'),
      renderCell: ({ value }) => (
        <span data-testid="plannedPurchasedDate">{value ? dateFormatter.format(new Date(value)) : null}</span>
      ),
      minWidth: 220,
      flex: 1,
    },
    {
      field: 'region',
      headerName: t('COLUMN.REGION'),
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      maxWidth: 120,
    },
    {
      field: 'service',
      headerName: t('COLUMN.SERVICE'),
      headerAlign: 'left',
      align: 'left',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'term',
      headerName: t('COLUMN.TERM'),
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      valueFormatter: ({ value }) => (value ? formatTerm(value) : t('VALUES.NA')),
    },
    {
      field: 'paymentOption',
      headerName: t('COLUMN.PAYMENT_OPTION'),
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    },
    {
      field: 'totalCost',
      headerName: t('COLUMN.TOTAL_COST'),
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 200,
      valueFormatter: ({ value }) =>
        formatCurrency(value, {
          currency: AWS_DEFAULT_CURRENCY,
          maximumFractionDigits: 2,
        }),
    },
  ];

  if (isPending) {
    columns.unshift({
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      sortable: false,
      width: 70,
    });
  }

  const handleExpandedRowIdsChange = (ids: GridRowId[]) => {
    setExpandedRows(ids);
  };

  const handleScrollEnd: GridEventListener<'rowsScrollEnd'> = (params) => {
    if (params.visibleRowsCount > 0 && onLoadMore) {
      onLoadMore();
    }
  };

  function selectionHandler(rows: Map<GridRowId, any>) {
    setSelectedRows(rows);
  }

  const handleCancelTransactions = () => {
    if (selectedRows.size > 0 && onCancelTransactions) {
      onCancelTransactions(selectedRows);
    }
  };

  const rows = data || [];

  return (
    <Stack
      sx={{
        maxHeight: `${ROW_HEIGHT * (visibleRows + 1)}px`,
      }}
    >
      {isPending && selectedRows.size > 0 && (
        <Stack
          sx={{
            padding: '16px 24px 16px 24px',
          }}
        >
          <Stack direction="row" gap={4} alignItems="center">
            <Text variant="caption" color="text2" size="S">{`${selectedRows.size} Selected`}</Text>
            <Button color="neutral" variant="outline" size="S" onClick={handleCancelTransactions}>
              <CloseOutlinedIcon />
              {t('ACTIONS.CANCEL_TRANSACTION', { count: selectedRows.size })}
            </Button>
          </Stack>
        </Stack>
      )}
      <Can do="rio:CancelPurchase" on={`urn:verticeone:vertice:${accountId}:cco:rio/purchases/*`} passThrough>
        {(isAllowed) => (
          <DataGrid<ReservedInstance>
            checkboxSelection={isAllowed && isPending}
            apiRef={apiRef}
            columns={columns}
            rows={rows}
            testId={testId}
            sortingMode="client"
            color={AWS_BRAND_COLOR}
            size={'M'}
            hideFooter
            getRowId={getRowId}
            noBorder
            noBorderRadius
            autoHeight={!rows.length}
            detailPanelExpandedRowIds={expandedRows}
            onDetailPanelExpandedRowIdsChange={handleExpandedRowIdsChange}
            getDetailPanelContent={({ row }) => <DetailPanel isPending={isPending} row={row} />}
            getDetailPanelHeight={() => 'auto'}
            getRowHeight={() => ROW_HEIGHT}
            onRowsScrollEnd={handleScrollEnd}
            disableRowSelectionOnClick={!isPending}
            onRowSelectionModelChange={() => {
              selectionHandler(apiRef.current.getSelectedRows());
            }}
            slots={{
              detailPanelExpandIcon: ExpandMoreOutlined,
              detailPanelCollapseIcon: ChevronRightOutlined,
              baseCheckbox: (props) => <Checkbox {...props} color="neutral" />,
            }}
          />
        )}
      </Can>
    </Stack>
  );
};

export default RIPurchaseTable;
