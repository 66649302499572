import { zodResolver } from '@hookform/resolvers/zod';
import { DynamicFormSchema, DynamicFormPresenterConfig } from '../../types';
import { FieldValues, useForm } from 'react-hook-form';
import { createDynamicSchema } from '../utils/createDynamicSchema';
import { createDefaultValues } from '../utils/createDefaultValues';

type UseDynamicFormPresenterProps = {
  formSchema: DynamicFormSchema;
  config: DynamicFormPresenterConfig;
  defaultValues?: FieldValues;
};

export const useDynamicFormPresenterForm = (props: UseDynamicFormPresenterProps) => {
  const { formSchema, config, defaultValues } = props;

  const schema = createDynamicSchema(formSchema);
  const mergedDefaultValues = createDefaultValues(formSchema, config, defaultValues);

  return useForm({
    resolver: zodResolver(schema),
    defaultValues: mergedDefaultValues,
  });
};
