import { ComponentType } from 'react';
import { ZodObject, ZodRawShape } from 'zod';
import { FieldValues } from 'react-hook-form';
import { DynamicFormPresenterConfig } from '../types';

export enum FieldType {
  SHORT_TEXT = 'SHORT_TEXT',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  MONEY = 'MONEY',
  NUMBER = 'NUMBER',
}

export type GetPresenterDefaultProps<F extends object> = {
  field: F;
  config: DynamicFormPresenterConfig;
  defaultValues?: FieldValues;
};

export type FieldDef<F extends object> = {
  type: FieldType;
  typeNameI18nKey: string;
  builderCard: ComponentType<{ field: F & { id: string } }>;
  builderForm: ComponentType;
  presenter: ComponentType<{ field: F; config: DynamicFormPresenterConfig }>;
  getPresenterDefault: (props: GetPresenterDefaultProps<F>) => Record<string, unknown>;
  getPresenterSchema: (field: F) => ZodObject<ZodRawShape>;
  getJsonSchema: (field: F) => { required: string[]; properties: object };
};
