import { useMemo } from 'react';
import { SeriesColor } from '../../chart-lib-agnostic/types';
import { useDivergentColorPalette } from '@verticeone/design-system';
import { SeriesOptionsWithData } from '../types';

/**
 * Assigns the colors for each data series.
 *
 * @param series Chart data for the series
 * @param usedCategories All categories used in the chart
 * @param colors An array of colors
 *
 * @deprecated Use useGetColoredItems from ./seriesUtils.ts instead. It is more universal and can be used to color
 * multiple series.
 */
export const getColoredSeries = (series: SeriesOptionsWithData[], usedCategories: string[], colors: SeriesColor[]) => {
  // Assign colors to all categories
  const colorMap = {} as { [category: string]: SeriesColor };
  usedCategories.forEach((category, index) => {
    colorMap[category] = colors[index % colors.length];
  });

  return series.map((item) => ({
    ...item,
    color: item.id ? colorMap[item.id]?.normal : 'transparent',
    states: {
      hover: { color: item.id ? colorMap[item.id]?.dark : 'transparent' },
      inactive: { color: item.id ? colorMap[item.id]?.light : 'transparent' },
    },
  }));
};

export type UseColoredDataProps = {
  values: SeriesOptionsWithData[];
  usedCategories: string[];
};

/**
 * @deprecated Use useGetColoredItems from ./seriesUtils.ts instead. It is more universal and can be used to color
 * multiple series.
 */
const useColoredData = ({ values, usedCategories }: UseColoredDataProps) => {
  const colors = useDivergentColorPalette();

  return useMemo(() => getColoredSeries(values, usedCategories, colors), [values, usedCategories, colors]);
};

export default useColoredData;
