import { Stack, styled } from '@mui/material';
import { TaskColorVariant } from './types';
import { ifExpression } from '@verticeone/design-system';

export const StyledTaskNode = styled(Stack)<{
  $colorVariant: TaskColorVariant;
  $withShadow?: boolean;
  $withColoredBorder?: boolean;
  $selected?: boolean;
  $active?: boolean;
}>(({ theme, $colorVariant, $withShadow, $withColoredBorder, $selected, $active = false }) => ({
  backgroundColor: theme.palette.background.default,
  width: '100%',
  borderRadius: 8,
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  flexGrow: 1,
  border: $withColoredBorder ? 'none' : `1px solid ${theme.palette.core.color4}`,
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  boxShadow: $withShadow
    ? theme.palette.global.getShadow({
        // shadows are not defined for neutral and transparent colors - use core color instead
        color: $colorVariant === 'neutral' || $colorVariant === 'transparent' ? 'core' : $colorVariant,
        type: 'soft',
        depth: '1z',
        distance: '50',
      })
    : 'none',
  ...ifExpression(!$active, {
    borderColor: $withColoredBorder ? theme.palette[$colorVariant].color2 : theme.palette.core.color4,
    borderWidth: 1,
    borderStyle: 'solid',
  }),
  ...ifExpression(!$withColoredBorder, {
    '&:hover': {
      borderColor: $colorVariant === 'neutral' ? theme.palette.core.color5 : theme.palette[$colorVariant].color3,
    },
  }),
  ...ifExpression($selected, {
    outlineWidth: 4,
    outlineStyle: 'solid',
    outlineColor: $colorVariant === 'neutral' ? theme.palette.core.color3 : theme.palette[$colorVariant].color4,
  }),
  ...ifExpression($withShadow && $colorVariant === 'error', {
    animation: 'glow 3s linear alternate infinite',

    '@keyframes glow': {
      '0%': {
        boxShadow: theme.palette.global.getShadow({ color: 'error', depth: '2z', type: 'soft', distance: '50' }),
      },
      '50%': {
        boxShadow: theme.palette.global.getShadow({ color: 'error', depth: '2z', type: 'soft', distance: '70' }),
      },
      '100%': {
        boxShadow: theme.palette.global.getShadow({ color: 'error', depth: '2z', type: 'soft', distance: '100' }),
      },
    },
  }),
}));
