import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import FormFilesDropArea from '@vertice/core/src/modules/forms/fields/FormFilesDropArea';
import FormNumberField from '@vertice/core/src/modules/forms/fields/FormNumberField';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import FormSelectField from '@vertice/core/src/modules/forms/fields/FormSelectField';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { TextFieldCaption } from '@verticeone/design-system';

import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons } from '@vertice/core/src/components/Dialog/Components';
import { NewPurchaseFormData, NewPurchaseFormEntry } from '../../types';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import FilesList from '../../../SharedComponents/FilesList';
import { useFormDepartments } from '../../../sharedHooks/useFormDepartments';
import { useParams } from 'react-router-dom';
import { useSetFormVendor } from '../../../sharedHooks/useSetFormVendor';
import { useSetFormCurrency } from '../../../sharedHooks/useSetFormCurrency';
import dayjs from 'dayjs';
import { SOONEST_TARGET_SIGN_DATE } from '../../../constants';
import { IntakeProductList } from '../../../SharedComponents/IntakeProductList';
import { getCurrencySymbol } from '@verticeone/utils/formatting';
import { currencies } from '@verticeone/utils/currency';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

const currenciesWithEmptyValue = ['', ...currencies];

const useBasicRequirementsWizard = () => {
  const wizard = useWizard();

  return {
    goBack: wizard.goBack,
    goToNext: () => wizard.goToStep('additional_requirements'),
  };
};

const BasicRequirements: React.FC<WizardStepProps> = () => {
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const { id: vendorId } = useParams();
  const { accountId, isFeatureEnabled } = useAccountContext();
  const { t } = useTranslation();
  const { locale } = useLocaleContext();
  const advancedCostModels = isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS);

  const { goToNext } = useBasicRequirementsWizard();

  const { watch, trigger } = useFormContext<NewPurchaseFormData>();
  const { contractCurrency } = watch();

  const departments = useFormDepartments({ onlyOwnedDepartments: false });
  useSetFormVendor(vendorId);
  useSetFormCurrency(accountId);

  const validationFields = watch(['departmentId', 'contractCurrency', 'targetSignDate']);

  useEffect(() => {
    void trigger(['departmentId', 'contractCurrency', 'targetSignDate']).then(setNextButtonEnabled);
  }, [trigger, validationFields]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.NEW_PURCHASE_BASIC_REQUIREMENTS.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.NEW_PURCHASE_BASIC_REQUIREMENTS.HEADING_SUBTITLE')}
      />
      <SelectedVendorPanel />
      <InnerScrollable>
        <FormSection>
          <Grid item xs={12}>
            <IntakeProductList />
          </Grid>
          <NewPurchaseFormEntry
            name="departmentId"
            label={t('ENTITIES.CONTRACT.LABELS.DEPARTMENT')}
            component={FormSelectField}
            required
          >
            {departments ? (
              departments.map((department) => (
                <MenuItem key={department.departmentId} value={department.departmentId}>
                  {department.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem key="loading" value="" />
            )}
          </NewPurchaseFormEntry>
          <NewPurchaseFormEntry
            name="contractCurrency"
            label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_CURRENCY')}
            component={FormSelectField}
            required
          >
            {currenciesWithEmptyValue.map((c) => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
          </NewPurchaseFormEntry>
          <NewPurchaseFormEntry
            name="targetSignDate"
            tooltip={{
              title: t('ENTITIES.CONTRACT.TOOLTIPS.TARGET_SIGN_DATE'),
            }}
            label={t('ENTITIES.CONTRACT.LABELS.TARGET_SIGN_DATE')}
            component={FormDateField}
            componentProps={{
              minDate: SOONEST_TARGET_SIGN_DATE,
              validate: {
                isAfterOrExactly30DaysFromNow: (value) => {
                  if (value && dayjs(value).isBefore(SOONEST_TARGET_SIGN_DATE)) {
                    return t('ENTITIES.CONTRACT.TOOLTIPS.TARGET_SIGN_DATE');
                  }
                  return true;
                },
              },
            }}
            width={advancedCostModels ? 6 : 12}
            required
          />
          <NewPurchaseFormEntry
            name="approvedBudget"
            label={t('ENTITIES.CONTRACT.LABELS.APPROVED_BUDGET')}
            component={FormNumberField}
            componentProps={{
              minValue: 0,
              unit: getCurrencySymbol(locale, contractCurrency || ''),
              unitPosition: 'start',
              placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.APPROVED_BUDGET'),
            }}
            width={12}
          />
          <NewPurchaseFormEntry
            name="additionalNotes"
            label={t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_NOTES')}
            component={FormTextField}
            width={12}
            componentProps={{
              placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.PURCHASE_ADDITIONAL_NOTES'),
              multiline: true,
              rows: 4,
            }}
          />
          <Grid item xs={12}>
            <Stack direction="column" spacing={2}>
              <TextFieldCaption
                label={t('INTAKE_FORM.NEW_PURCHASE_BASIC_REQUIREMENTS.LABELS.ADDITIONAL_FILES')}
                size="XS"
              />
              <FormFilesDropArea name="files" />
              <FilesList />
            </Stack>
          </Grid>
        </FormSection>
      </InnerScrollable>
      <Buttons
        primary={{
          onClick: goToNext,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !nextButtonEnabled,
        }}
      />
    </Content>
  );
};

export default BasicRequirements;
