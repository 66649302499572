import React from 'react';
import { useTranslation } from 'react-i18next';
import FormTextField from '../../../../fields/FormTextField';
import { FieldDialogFormEntry } from '../../../DynamicFormBuilder/components/FieldDialog/FieldDialogFormEntry';
import { useDynamicFormBuilderContext } from '../../../DynamicFormBuilder/DynamicFormBuilderContextProvider';

export const LabelFieldDialogFormEntry = () => {
  const { t } = useTranslation();
  const { color } = useDynamicFormBuilderContext();

  return (
    <FieldDialogFormEntry
      name="metadata.label"
      label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.FIELD_LABEL')}
      required={true}
      component={FormTextField}
      componentProps={{
        color: color,
        inputProps: { maxLength: 40 },
      }}
    />
  );
};
