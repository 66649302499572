import { useMutation } from '@tanstack/react-query';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';

import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';

export type Deletion = {
  savingsPlanType: 'SageMaker' | 'EC2' | 'Compute';
  purchaseOrderId: string;
};

type UseCancelSPReservationMutationProps = Partial<Record<'onSuccess' | 'onError' | 'onSettled', () => void>>;

export const QUERY_KEY = 'CancelSPReservationMutation' as const;

const CancelSPReservationMutation = graphql(`
  mutation CancelSPReservation($accountId: String!, $deletions: [SPDeleteSavingsPlan!]!) {
    deleteQueuedSavingsPlan(params: { accountId: $accountId, deletions: $deletions }) {
      ... on SavingsPlanDeleteResult {
        __typename
        items {
          purchaseOrderId
          status
        }
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

export const useCancelSpReservationMutation = (options?: UseCancelSPReservationMutationProps) => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (deletions: Deletion[]) => {
      return fetchCloudOptimization(CancelSPReservationMutation, {
        accountId,
        deletions,
      });
    },
    onSettled: () => {
      options?.onSettled?.();
    },
    onSuccess: async (response) => {
      if (response?.deleteQueuedSavingsPlan?.__typename === 'ErroredQueryResult') {
        options?.onError?.();
        return;
      }

      options?.onSuccess?.();
    },
    onError: () => {
      options?.onError?.();
    },
  });
};
