import React from 'react';
import { Stack } from '@mui/material';
import { EllipsisText } from '@verticeone/design-system';
import { TaskRow } from '../../types';
import { OnCardHover } from '../CardGrid';
import { GenericRequestIcon } from '../../../components/icons/Icons';

export const TaskRequestName = ({ taskRow }: { taskRow: TaskRow }) => {
  return (
    <Stack minWidth={0}>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <GenericRequestIcon />
        <EllipsisText variant="body-regular" size="M" title={taskRow.requestName}>
          {taskRow.requestName}
        </EllipsisText>
      </Stack>
      <OnCardHover>
        <EllipsisText variant="caption" size="XS" color="text3" title={taskRow.workflowName}>
          {taskRow.workflowName}
        </EllipsisText>
      </OnCardHover>
    </Stack>
  );
};
