import { Alert } from '@verticeone/design-system';
import { FC, ReactNode } from 'react';
import { Account, AccountSettings, useGetAccountQuery, useGetAccountSettingsQuery } from '@vertice/slices';
import { Loader } from '@vertice/components';
import { useTranslation } from 'react-i18next';
import { AccountContextProvider, AccountContextType } from '@vertice/core/src/contexts/AccountContext';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { useAuthentication } from '@verticeone/auth/src';

export const CfaAccountContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const accountId = user?.accountId;

  // load the particular account details
  const { data: accountData, isLoading: isLoadingAccount } = useGetAccountQuery(
    { accountId: accountId! },
    { skip: !accountId, refetchOnMountOrArgChange: false }
  );
  const { data: accountSettings, isLoading: isLoadingAccountSettings } = useGetAccountSettingsQuery(
    { accountId: accountId! },
    { skip: !accountId, refetchOnMountOrArgChange: false }
  );

  const { getFeature, isEnabled: isFeatureEnabled, isLoading: isLoadingFeatures } = useFeatures();

  if (isLoadingAccount || isLoadingAccountSettings || isLoadingFeatures) {
    return <Loader />;
  }

  if (accountId === undefined || accountData === undefined) {
    return <Alert color="error" title={t('AUTH.ERROR.HEADER')} subtitle={t('AUTH.ERROR.DESCRIPTION')} />;
  }

  const contextValue: AccountContextType = {
    accountId,
    accountType: (accountData as Account)?.accountType || 'DEMO',
    accountDetails: accountData as Account,
    accountSettings: accountSettings as AccountSettings,
    getFeature,
    isFeatureEnabled,
  };

  return <AccountContextProvider value={contextValue}>{children}</AccountContextProvider>;
};
