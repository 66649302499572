import * as z from 'zod';

export const variableMapperRowFormSchema = z.object({
  from: z.string(),
  to: z.string(),
  mappingType: z.string(),
});

export type VariableMapperRowFormBase = z.infer<typeof variableMapperRowFormSchema>;
export type VariableMapperRowFormData = VariableMapperRowFormBase & { id: string };
