import { Task } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';

const VISITED_PAGES_KEY = '__visited_steps';
const CURRENT_PAGES_KEY = '__current_step';

const isStringArray = (data: any): data is string[] => {
  return Array.isArray(data) && data.every((item) => typeof item === 'string');
};

export const useTaskVisitorState = (draft: Task['draft']) => {
  const [visitedPages, setVisitedPages] = useState<Set<string>>(new Set());
  const [currentPageId, setCurrentPageId] = useState<string>();

  // get visited pages & current page IDs from draft
  useEffect(() => {
    if (draft !== undefined) {
      const savedVisitedPages = draft[VISITED_PAGES_KEY];
      if (isStringArray(savedVisitedPages) && visitedPages.size === 0) {
        setVisitedPages(new Set(savedVisitedPages));
      }
      const savedCurrentPage = draft[CURRENT_PAGES_KEY];
      if (typeof savedCurrentPage === 'string' && currentPageId === undefined) {
        setCurrentPageId(savedCurrentPage);
      }
    }
  }, [currentPageId, draft, visitedPages.size]);

  // convert visited pages info do draft format
  const getVisitedPagesDataForDraft = useCallback(
    () => ({
      [VISITED_PAGES_KEY]: visitedPages.size ? [...visitedPages] : undefined,
      [CURRENT_PAGES_KEY]: currentPageId,
    }),
    [currentPageId, visitedPages]
  );

  const onPageVisited = useCallback((newVisitedPage: string) => {
    setVisitedPages((currentVisitedPages) => new Set(currentVisitedPages).add(newVisitedPage));
    setCurrentPageId(newVisitedPage);
  }, []);

  return useMemo(
    () => ({
      visitedPages,
      currentPageId,
      onPageVisited,
      getVisitedPagesDataForDraft,
    }),
    [currentPageId, getVisitedPagesDataForDraft, onPageVisited, visitedPages]
  );
};
