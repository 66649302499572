import React, { useCallback, useId, useMemo } from 'react';
import { Card } from '@verticeone/design-system';
import { StatsBody, Title, Value } from '@vertice/core/src/components/StatsBar';
import { CardProps, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SimplifiedStage, useGetContractsStatisticsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useFormatNumber } from '@verticeone/utils/formatting';
import { FullHeightStatsTile } from './shared/FullHeightStatsTile';
import { GrayStatsBody } from './shared/GrayStatsBody';
import { useDashboardContext } from '../../../../pages/Dashboard/DashboardContextProvider';

type ContractCountsCardProps = CardProps;

export const ContractCountsCard = (props: ContractCountsCardProps) => {
  const { accountId } = useAccountContext();
  const { contractCategory } = useDashboardContext();
  const { isLoading, data } = useGetContractsStatisticsQuery({ accountId });
  const statsForCategory = data?.stats.categories[contractCategory];
  const countsPerStage = statsForCategory?.counts.perStage;

  const getNumberOfContractsWithStage = useCallback(
    (stage: SimplifiedStage) => (countsPerStage ?? {})[stage as string] ?? 0,
    [countsPerStage]
  );

  const liveContractsCount = useMemo(() => getNumberOfContractsWithStage('LIVE'), [getNumberOfContractsWithStage]);
  const notYetLiveContractsCount = useMemo(
    () => getNumberOfContractsWithStage('NOT_YET_LIVE'),
    [getNumberOfContractsWithStage]
  );

  const firstLabelId = useId();
  const secondLabelId = useId();

  const formatNumber = useFormatNumber();
  const { t } = useTranslation();
  return (
    <Card {...props}>
      <FullHeightStatsTile>
        <Stack spacing={4} height="100%" p={2}>
          <StatsBody
            sx={{ flexGrow: 1, alignItems: 'flex-start', padding: 4 }}
            isLoading={isLoading}
            title={<Title id={firstLabelId}>{t('DASHBOARD.TOTAL_CONTRACTS_LIVE')}</Title>}
            value={<Value aria-labelledby={firstLabelId}>{formatNumber(liveContractsCount)}</Value>}
          />
          {!!notYetLiveContractsCount && (
            <GrayStatsBody
              isLoading={isLoading}
              title={<Title id={secondLabelId}>{t('DASHBOARD.NOT_LIVE_YET')}</Title>}
              value={<Value aria-labelledby={secondLabelId}>{formatNumber(notYetLiveContractsCount)}</Value>}
            />
          )}
        </Stack>
      </FullHeightStatsTile>
    </Card>
  );
};
