import React from 'react';
import { Box, CircularProgress, Stack, useTheme } from '@mui/material';
import { VendorLogo } from '@vertice/components';
import { Text } from '@verticeone/design-system';
import { Divider } from '@vertice/core/src/components/Dialog/Components';
import { TextFieldCaption } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { CommonIntakeFormFormData } from '../types';

type SelectedVendorPanelProps = {
  showProducts?: boolean;
};

const SelectedVendorPanel: React.FC<SelectedVendorPanelProps> = ({ showProducts = true }) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { watch } = useFormContext<CommonIntakeFormFormData>();
  const vendor = watch('vendor');
  const products = watch('products');

  if (!vendor) {
    return null;
  }

  return (
    <>
      <Box bgcolor={palette.core.color1} px={8} py={4}>
        <Stack direction="column" spacing={1}>
          <TextFieldCaption label={t('INTAKE_FORM.VENDOR_SELECTION.SELECTED_VENDOR')} size="XS" />
          <Stack direction="row" spacing={2} alignItems="center">
            <Box padding={1} bgcolor={palette.core.bg} borderRadius={2}>
              <VendorLogo vendorId={vendor.id ?? undefined} />
            </Box>
            <Box flexGrow={1}>
              <Stack>
                <Text variant="body-regular" size="M">
                  {vendor.name ? vendor.name : <CircularProgress size={16} />}
                </Text>
                {showProducts && products.length > 0 && (
                  <Text variant="caption" size="XS" color="text3">
                    {products.map((product) => product.name).join(', ')}
                  </Text>
                )}
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Divider />
    </>
  );
};

export default SelectedVendorPanel;
