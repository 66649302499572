import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserList } from '@vertice/components';
import useAddEditInviteContactModal from '@vertice/core/src/modules/preferences/useAddEditInviteContactModal';
import { Card, CardHeader, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import useUserAssignments from '@vertice/core/src/hooks/useUserAssignments';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';

const Directory = () => {
  const { t } = useTranslation();
  const { isUserAdmin } = useLoggedUserAccountRoles();
  const { getUserAssignments } = useUserAssignments();

  const { modal, handleAddRequest } = useAddEditInviteContactModal({ adminMode: false });

  return (
    <Stack px={10}>
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle tag="h1" text={t('PREFERENCES.DIRECTORY.TITLE')} />
          <CardHeaderActions>
            <Button variant="solid" color="primary" onClick={handleAddRequest}>
              {t('PREFERENCES.DIRECTORY.ADD_NEW_TEAM_MEMBER')}
            </Button>
          </CardHeaderActions>
        </CardHeader>

        <Stack
          style={{} as React.CSSProperties}
          // Ugly style hacking = will soon disappear with migration of UserList to Design System DataGrid
          // (it has noBorder prop)
          sx={{ '.MuiDataGrid-root': { borderStyle: 'none' } }}
        >
          <UserList
            addLabel={t('PREFERENCES.DIRECTORY.CREATE_NEW_USER')}
            allowedColumns={['userName', 'email', 'jobTitle', 'userRole', 'userStatus']}
            checkAvailableDeletion={getUserAssignments}
            withActions={isUserAdmin}
            dataTestId="User directory"
          />
        </Stack>
        {modal}
      </Card>
    </Stack>
  );
};

export default Directory;
