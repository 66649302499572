import { FC } from 'react';
import { CommentsBox } from '@verticeone/design-system';
import { useConversations } from '../../useConversations';
import { useConversationUsers } from '../../../../../../hooks/useConversationUsers';

type CommentPanelProps = {
  requestId: string;
  accountId: string;
  canEditComents: boolean;
  requestOwner?: string;
  userId: string;
};
export const CommentPanel: FC<CommentPanelProps> = ({ accountId, requestId, requestOwner, canEditComents, userId }) => {
  const { usersById, isLoadingUsers } = useConversationUsers(accountId);
  const {
    messages,
    cursor,
    sendMessage,
    removeMessage,
    updateCursor,
    editMessage,
    isLoading: isLoadingConversation,
    isMessagePending,
  } = useConversations(requestId, accountId, requestOwner);

  return (
    <CommentsBox
      users={usersById}
      currentUser={userId}
      canEditComents={canEditComents}
      comments={messages}
      cursor={cursor}
      onSendComment={sendMessage}
      onRemoveComment={removeMessage}
      onUpdateCursor={updateCursor}
      onEditComment={editMessage}
      isLoading={isLoadingUsers || isLoadingConversation}
      isSending={isMessagePending}
    />
  );
};
