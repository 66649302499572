import React, { useEffect } from 'react';
import { sumBy } from 'lodash';
import { AutoAwesomeOutlined } from '@mui/icons-material';

import { useColumns } from './columns';
import { useOfferings } from '../../../providers/OfferingsProvider';
import { LoadableComponent } from '../components/LoadableComponent';
import TableCard from '../components/TableCard';
import BaseTable from '../components/BaseTable';
import TableFooter from '../components/tableFooter/TableFooter';
import TableContextProvider, { useTableContext } from '../components/TableContext';

const T_KEY = 'CLOUD.RIO_NEW.OPTIMIZE.BUY_SP_DRAWER.RECOMMENDED_TAB.TABLE';

const RecommendedTable = () => {
  const columns = useColumns(T_KEY);
  const { offerings } = useOfferings();
  const { apiRef } = useTableContext();

  useEffect(() => {
    offerings.fetch?.();
  }, [offerings]);

  const isEmpty = !offerings.data?.length;
  const sumOfTotalCosts = offerings.data ? sumBy(offerings.data, ({ totalCost }) => totalCost) : 0;
  const isLoading = offerings.isFetching;
  const error = offerings.error;

  const data = offerings.data ?? [];

  return (
    <TableCard icon={AutoAwesomeOutlined} tKey={T_KEY}>
      <LoadableComponent isLoading={isLoading} error={error} isEmpty={isEmpty}>
        <BaseTable
          data={data}
          defaultSortModel={{ sort: 'desc', field: 'expectedSavings' }}
          getRowId={(row) => row.offeringId}
          tableColumns={columns}
          slots={{
            footer: TableFooter,
          }}
          slotProps={{ footer: { totalCost: sumOfTotalCosts, isEmpty } }}
          hideFooter={isEmpty}
          apiRef={apiRef}
        />
      </LoadableComponent>
    </TableCard>
  );
};

const RecommendedTableWithContext = () => (
  <TableContextProvider>
    <RecommendedTable />
  </TableContextProvider>
);

export default RecommendedTableWithContext;
