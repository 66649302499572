import { useCallback } from 'react';
import { Grid } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { useController } from 'react-hook-form';

import { Button, ButtonLabel } from '@verticeone/design-system';
import type { DesignSystemColor } from '@verticeone/design-system';

type ToggleButtonOption = {
  value: string;
  title: string;
  label?: string;
  icon?: SvgIconComponent;
  color?: DesignSystemColor;
  isLoading?: boolean;
  isActive?: boolean;
};

export type ToggleButtonFieldProps = {
  name: string;
  options: ToggleButtonOption[];
  optionsFilter?: (selected: boolean, disabled?: boolean) => boolean;
  onClick?: (value: string) => void;
  required?: boolean;
  buttonHeight?: number;
  compact?: boolean;
  color?: DesignSystemColor;
  disabled?: boolean;
};

export const FormToggleButtonField = ({
  name,
  options,
  onClick,
  required,
  buttonHeight,
  compact,
  color,
  disabled,
  optionsFilter,
}: ToggleButtonFieldProps) => {
  const {
    field: { onChange, value },
  } = useController({ name, rules: { required } });

  const filter = useCallback(
    (option: ToggleButtonOption) => (optionsFilter ? optionsFilter(value === option.value, disabled) : true),
    [value, disabled, optionsFilter]
  );

  return (
    <Grid container direction="row" gap={2} sx={{ width: '100%' }}>
      {options.filter(filter).map((option) => (
        <Grid item xs key={option.value} alignItems="center">
          <Button
            key={option.value}
            value={option.value}
            variant={value === option.value ? 'ghost' : 'outline'}
            color={option.color ? option.color : color || 'primary'}
            isActive={!disabled || option.isActive}
            isLoading={option.isLoading}
            disabled={disabled}
            fullWidth
            onClick={() => {
              onChange(option.value);
              onClick?.(option.value);
            }}
            direction={compact ? 'horizontal' : 'vertical'}
            sx={{ height: buttonHeight }}
          >
            {option.icon && <option.icon />}
            {option.title}
            {option.label && <ButtonLabel>{option.label}</ButtonLabel>}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
