import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';

import { Text } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';
import { IconWrapper } from '@verticeone/design-system';
import { getTimeFromNow } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import {
  AlarmLastDataUpdate,
  AlarmDay,
  AlarmStatus,
} from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { LastFetchedTooltip } from './LastFetchedTooltip';

type LastFetchedInfoProps = {
  accessLogs: AlarmLastDataUpdate[];
  alarmsPerDay?: AlarmDay[];
  providers?: string[];
};

export const LastFetchedInfo = ({ accessLogs, alarmsPerDay, providers }: LastFetchedInfoProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const filteredAccessLogs =
    providers && providers.length > 0
      ? accessLogs.filter((logItem) => providers.includes(logItem.providerName))
      : accessLogs;

  if (filteredAccessLogs.length === 0) return null;

  const hasFailedAlarm = alarmsPerDay?.some((dailyAlarm) =>
    dailyAlarm.alarms?.some((alarm) => alarm.status === AlarmStatus.Failed && alarm.errorLog)
  );
  const hasFailedAccessLogUpdate = filteredAccessLogs.some((logItem) => logItem.updatedAt === null);
  const containsFailedItems = hasFailedAlarm || hasFailedAccessLogUpdate;

  const latestAccessLogUpdate = filteredAccessLogs
    .map((logItem) => logItem.updatedAt)
    .filter(Boolean)
    .sort()
    .reverse()[0];

  return (
    <Tooltip
      content={
        filteredAccessLogs.length > 0 && (
          <LastFetchedTooltip accessLogs={filteredAccessLogs} alarmsPerDay={alarmsPerDay} providers={providers} />
        )
      }
      size="M"
      maxWidth="320px"
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <IconWrapper
          icon={containsFailedItems ? InfoIcon : InfoIconOutlined}
          size="S"
          htmlColor={containsFailedItems ? palette.error.color1 : palette.text.color3}
        />
        <Text
          variant="link"
          size="S"
          color={containsFailedItems ? palette.error.color1 : palette.text.color3}
          sx={{ cursor: 'default' }}
        >
          {latestAccessLogUpdate
            ? t('APPLICATIONS.USAGE.LAST_UPDATED.TITLE', { timePeriod: getTimeFromNow(latestAccessLogUpdate) })
            : t('APPLICATIONS.USAGE.LAST_UPDATED.NO_DATA')}
        </Text>
      </Stack>
    </Tooltip>
  );
};
