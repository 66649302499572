import React from 'react';
import { ProductItem } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';
import { useTranslation } from 'react-i18next';
import { useHasProductsWithBenchmarkingData } from '@vertice/core/src/hooks/useHasProductsWithBenchmarkingData';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { IconButton } from '@verticeone/design-system';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';

type BenchmarkingProductCellDecoratorProps = {
  vendorId?: string;
  row: ProductItem;
};

export const BenchmarkingProductCellDecorator = ({ vendorId, row }: BenchmarkingProductCellDecoratorProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const numberOfProductsWithBench = useHasProductsWithBenchmarkingData({ vendorId, products: [row.productId] });
  if (!numberOfProductsWithBench) {
    return null;
  }

  return (
    <IconButton
      icon={LeaderboardOutlinedIcon}
      size="XS"
      variant="ghost"
      color="neutral"
      aria-label={t('ENTITIES.CONTRACT.LABELS.BENCHMARK')}
      onClick={() => {
        apiRef.current.setExpandedDetailPanels([row.id]);
      }}
    />
  );
};
