import React, { useCallback, useEffect, useState } from 'react';
import {
  CardCloseButton,
  CardHeader,
  CardHeaderActions,
  CardHeaderDescription,
  CardHeaderSubtitle,
  CardHeaderTitle,
} from '@verticeone/design-system';
import { Dialog, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dialogClasses } from '@mui/material/Dialog';
import { AccountTable } from './AccountTable';
import { useAccountTableData } from '../../hooks/useAccountTableData';
import { Button } from '@verticeone/design-system';
import { useRefreshOrganizationsDataSourceMutation } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { startOfMonth } from 'date-fns';
import format from 'date-fns/format';
import { DATE_FORMAT } from 'modules/cloud/utils/graphDataUtils';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const startDate = startOfMonth(new Date());

type AWSAccountTableDialogProps = {
  open: boolean;
  onClose: () => void;
};

const StyledAccountTableDialog = styled(Dialog)(({ theme }) => ({
  [`.${dialogClasses.paper}`]: {
    width: '1136px',
    maxHeight: '684px',
    maxWidth: 'none',
    padding: 0,
    borderRadius: '16px',
  },
}));

export const AWSAccountTableDialog = ({ open, onClose }: AWSAccountTableDialogProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'PREFERENCES.INTEGRATIONS.AWS.ACCOUNT_TABLE' });
  const { accountId } = useAccountContext();
  const { locale } = useLocaleContext();

  const dateFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  const { data, refetch, isLoading } = useAccountTableData();
  const [refreshDataSource] = useRefreshOrganizationsDataSourceMutation();

  const [refreshingData, setRefreshingData] = useState(false);
  const [accountList, setAccountList] = useState(data?.accounts ?? []);
  const [latestUpdatedAt, setLatestUpdatedAt] = useState(data?.latestUpdatedAt);

  const waitAndFetchAccounts = useCallback(() => {
    // There is a time delay for new account data to be generated after the data source refresh
    // We wait at least 10s and if that is not enough, we keep polling with 10s interval
    setTimeout(() => {
      refetch?.();
    }, 10000);
  }, [refetch]);

  const handleRefresh = async () => {
    //disable refresh button
    setRefreshingData(true);

    //invalidate status
    setAccountList(accountList.map(({ probeStatus, ...rest }) => rest));

    await refreshDataSource({
      accountId: accountId!,
      startDate: format(startDate, DATE_FORMAT),
    });
    waitAndFetchAccounts();
  };

  useEffect(() => {
    if (!isLoading) {
      const receivedLatestUpdatedAt = data?.latestUpdatedAt ?? 0;

      if (!refreshingData) {
        setAccountList(data?.accounts ?? []);
      }

      // The data are fetched for the first time, or we got data which are newer
      if (!latestUpdatedAt || receivedLatestUpdatedAt > latestUpdatedAt) {
        //update account list
        setAccountList(data?.accounts ?? []);
        //update time of last update
        setLatestUpdatedAt(receivedLatestUpdatedAt);
        //enable refresh button
        setRefreshingData(false);
      }

      if (refreshingData) {
        // Fresh data are not ready, try later
        waitAndFetchAccounts();
      }
    }
  }, [data, isLoading, latestUpdatedAt, waitAndFetchAccounts, refreshingData]);

  return (
    <StyledAccountTableDialog open={open}>
      <CardHeader size="S">
        <CardHeaderTitle text={t('TITLE')} />
        {latestUpdatedAt && (
          <CardHeaderSubtitle text={t('LAST_UPDATED_ON', { date: dateFormatter.format(latestUpdatedAt) })} />
        )}
        <CardHeaderDescription>{t('DESCRIPTION')}</CardHeaderDescription>
        <CardHeaderActions>
          <Stack direction="row" gap={2}>
            <Button color="neutral" onClick={handleRefresh} disabled={refreshingData}>
              {t('REFRESH')}
            </Button>
            <CardCloseButton onClick={onClose} />
          </Stack>
        </CardHeaderActions>
      </CardHeader>
      <AccountTable data={accountList!} refetchAccounts={refetch} />
    </StyledAccountTableDialog>
  );
};
