import { Stack } from '@mui/material';
import { useDynamicFormBuilderContext } from '../DynamicFormBuilderContextProvider';
import { fieldDefsByType } from '../../fields/fields';
import { DynamicFormField } from '../../types';

export const FieldList = <T extends DynamicFormField & { id: string }>() => {
  const { fields } = useDynamicFormBuilderContext();

  if (!fields.length) {
    return null;
  }

  return (
    <Stack gap={4}>
      {fields.map((field: T) => {
        const BuilderCard = fieldDefsByType[field.type]?.builderCard;
        return BuilderCard ? <BuilderCard key={field.id} field={field} /> : null;
      })}
    </Stack>
  );
};
