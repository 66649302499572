import { z } from 'zod';
import { checkboxFieldSchema, CheckboxFieldType } from './fields/CheckboxField';
import { shortTextFieldSchema, ShortTextFieldType } from './fields/ShortTextField';
import { dateFieldSchema, DateFieldType } from './fields/DateField';
import { numberFieldSchema, NumberFieldType } from './fields/NumberField';
import { moneyFieldSchema, MoneyFieldType } from './fields/MoneyField';

export type DynamicFormField =
  | ShortTextFieldType
  | CheckboxFieldType
  | DateFieldType
  | NumberFieldType
  | MoneyFieldType;

export type DynamicFormSchema = {
  fields: DynamicFormField[];
};

export const dynamicFormFieldSchema = z.union([
  shortTextFieldSchema,
  checkboxFieldSchema,
  dateFieldSchema,
  numberFieldSchema,
  moneyFieldSchema,
]);

export type DynamicFormPresenterMode = 'readOnly' | 'readWrite';

export type DynamicFormPresenterConfig = {
  mode: DynamicFormPresenterMode;
  defaultCurrency: string;
};
