import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { FieldArrayPath, FieldValues, Path, useController } from 'react-hook-form';

import { IconButton, IconWrapper } from '@verticeone/design-system';
import { WorkflowVariableSelector } from '../../../forms/EditGatewayForm/WorkflowVariableSelector';
import type { Property } from '../../../types';
import { findPropertyById } from '../../../VariableSelector/utils';
import { JiraIssueField } from '../types';
import { VariableMapperRowFormData } from './formSchema';
import { JiraVariableSelector } from './variableSelectors/JiraVariableSelector';

export type VariableMapperRowProps<T extends FieldValues> = {
  index: number;
  field: VariableMapperRowFormData;
  formFieldPrefix: FieldArrayPath<T>;
  onRemove: (index: number) => void;
  canRemove: boolean;
  fromWorkflow: boolean; // To distinguish the direction of mapping
  workflowVariables: {
    global: Property[];
    request: Property[];
  };
  jiraVariables: JiraIssueField[];
};

export const VariableMapperRow = <T extends FieldValues>({
  formFieldPrefix,
  index,
  onRemove,
  canRemove,
  fromWorkflow,
  workflowVariables,
  jiraVariables,
}: VariableMapperRowProps<T>) => {
  const { field: workflowField } = useController({
    name: `${formFieldPrefix}.${index}.${fromWorkflow ? 'from' : 'to'}` as Path<T>,
  });
  const { field: jiraField } = useController({
    name: `${formFieldPrefix}.${index}.${fromWorkflow ? 'to' : 'from'}` as Path<T>,
  });

  const [selectedWFVariable, setSelectedWFVariable] = useState<Property | undefined>(
    findPropertyById([...workflowVariables.global, ...workflowVariables.request], workflowField.value) ?? undefined
  );
  const [selectedJiraVariable, setSelectedJiraVariable] = useState<JiraIssueField | undefined>(
    (findPropertyById(jiraVariables as unknown as Property[], jiraField.value) as JiraIssueField | null) ?? undefined
  );

  const handleVariableChange = (property: Property) => {
    setSelectedWFVariable(property);
    workflowField.onChange(property.id);
  };

  const handleJiraVariableChange = (property?: JiraIssueField) => {
    setSelectedJiraVariable(property);
    jiraField.onChange(property?.id);
  };

  const workflowSelector = (
    <WorkflowVariableSelector
      selectedVariable={selectedWFVariable}
      onSelectedVariableChange={handleVariableChange}
      globalUDFProperties={workflowVariables.global}
      allRequestProperties={workflowVariables.request}
      isDisabled={!fromWorkflow && selectedJiraVariable === undefined}
      typeFilter={selectedJiraVariable?.type}
      // this should come from the left side of the mapping
      // addPropertyDefaults={{
      //   type: ['string'],
      //   typeLabel: ['string'],
      //   origin: { kind: 'task', id: 'thisTaskId', label: 'This task name' },
      // }}
      // this should probably expand a local copy of workflowVariables.request
      // onAddLocalVariable={(property) => {
      //   console.log('Added new local variable', property);
      // }}
    />
  );

  const jiraSelector = (
    <JiraVariableSelector
      selectedVariable={selectedJiraVariable}
      onSelectedVariableChange={handleJiraVariableChange}
      variables={jiraVariables}
      isVariableTypeDisplayed={true}
      isDisabled={fromWorkflow && selectedWFVariable === undefined}
      typeFilter={selectedWFVariable?.type?.[0]}
    />
  );

  const fromSelector = fromWorkflow ? workflowSelector : jiraSelector;
  const toSelector = fromWorkflow ? jiraSelector : workflowSelector;

  return (
    <Grid container sx={{ zIndex: 1 }} alignItems="center">
      <Grid item xs={fromWorkflow ? 6 : 4} display="grid">
        {fromSelector}
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <IconWrapper icon={ArrowForwardIcon} />
      </Grid>
      <Grid item xs={fromWorkflow ? 4 : 6} display={'grid'}>
        {toSelector}
      </Grid>

      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {canRemove && <IconButton icon={DeleteIcon} variant="plain" color="neutral" onClick={() => onRemove(index)} />}
      </Grid>
    </Grid>
  );
};
