import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountIntegrationQuery, useTestAccountIntegrationMutation } from '@vertice/slices';
import { Button } from '@verticeone/design-system';
import { IntegrationWaiting } from '@vertice/assets';
import { STEPS, GOOGLE_INTEGRATION_ID, ConnectionCheckStepsProps } from './common';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';
import IntegrationStatus from 'pages/Preferences/Integrations/components/IntegrationStatus';

export const ConnectionCheckStep = ({
  setStep,
  accountId,
  setTestingInProgress,
  connectionWindow,
}: ConnectionCheckStepsProps) => {
  const { t } = useTranslation();
  const [testConnectionExecuted, setTestConnectionExecuted] = useState(false);
  const [testAccountIntegrationMutation] = useTestAccountIntegrationMutation();

  const { data: googleIntegrationData, refetch: refetchAccountIntegration } = useGetAccountIntegrationQuery({
    accountId,
    integrationId: GOOGLE_INTEGRATION_ID,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const auth2ConnectionStatus = googleIntegrationData?.parameters?.OAuth2Status!;
    const connectionStatus = googleIntegrationData?.connection?.status!;
    if (auth2ConnectionStatus === 'ERROR' || ['FAILED', 'OK'].includes(connectionStatus)) {
      setStep(connectionStatus === 'OK' ? STEPS.CONNECTION_SUCCESS : STEPS.CONNECTION_ERROR);
      return () => {};
    }

    if (auth2ConnectionStatus === 'AUTHORIZED' && !testConnectionExecuted) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      testAccountIntegrationMutation({
        accountId,
        integrationId: GOOGLE_INTEGRATION_ID,
        testName: 'connection',
      });
      setTestConnectionExecuted(true);
      if (connectionWindow) {
        connectionWindow.close();
      }
    }

    const countTimer = setInterval(() => {
      void refetchAccountIntegration();
    }, 3500);

    return () => {
      clearInterval(countTimer);
    };
  });

  const onBack = () => {
    setTestingInProgress(false);
    setStep(STEPS.SELECT_DATA_SOURCE);
  };

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus
          icon={<IntegrationWaiting />}
          title={t('PREFERENCES.INTEGRATIONS.CHECKING_YOUR_CONNECTION')}
        />
      }
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption disabled>
          {t('COMMON.FINISH')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
