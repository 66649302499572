import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@verticeone/design-system';
import { useVendorProductsContext } from '../VendorProductsContextProvider';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { isNil } from 'lodash';

const CostCell = ({ value }: GridCellParams<any, number>) => {
  const { currency } = useVendorProductsContext();
  const formatCurrency = useFormatCurrency();

  return <Text variant="caption">{isNil(value) ? '' : formatCurrency(value, { currency })}</Text>;
};

export default CostCell;
