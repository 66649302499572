import { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { Button } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-hook-form';

type EditorDrawerFooterProps = {
  onDiscard: () => void;
};

const EditorDrawerFooterContainer = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  paddingRight: theme.spacing(6),
  paddingLeft: theme.spacing(6),
  borderTop: `1px solid ${theme.palette.core.color3}`,
}));

export const EditorDrawerFooter: FC<EditorDrawerFooterProps> = ({ onDiscard }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS' });
  const { isSubmitting } = useFormState();

  return (
    <EditorDrawerFooterContainer direction="row" justifyContent="space-between">
      <Button onClick={onDiscard} variant="plain" color="neutral" size="S">
        {t('DISCARD_CHANGES')}
      </Button>
      <Button variant="solid" color="secondary" size="S" type="submit" isLoading={isSubmitting} disabled={isSubmitting}>
        {t('APPLY')}
      </Button>
    </EditorDrawerFooterContainer>
  );
};
