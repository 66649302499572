import React, { FC } from 'react';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { createRequiredContext } from '@verticeone/design-system';
import { ConcessionType } from './constants';

type ConcessionsContextProviderProps = {
  currency?: string | null;
  children: string | JSX.Element | JSX.Element[];
};

type ConcessionsContextType = {
  concessionTypes: string[];
  currency: string;
};

const { ConcessionsContextProvider: RawConcessionsContextProvider, useConcessionsContext } = createRequiredContext<
  ConcessionsContextType,
  'Concessions'
>('Concessions');

export { useConcessionsContext };

const ConcessionsContextProvider: FC<ConcessionsContextProviderProps> = ({ currency, children }) => {
  const context: ConcessionsContextType = {
    concessionTypes: Object.values(ConcessionType),
    currency: currency || DEFAULT_CURRENCY,
  };

  return <RawConcessionsContextProvider value={context}>{children}</RawConcessionsContextProvider>;
};

export default ConcessionsContextProvider;
