import type { RuleGroupType, RuleType } from 'react-querybuilder';

export const DEFAULT_RULE: RuleType = {
  field: '',
  value: '',
  operator: '',
};

export const DEFAULT_QUERY: RuleGroupType = {
  combinator: 'and',
  rules: [DEFAULT_RULE],
};
