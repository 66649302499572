import { useExchangeCurrency } from '@vertice/core/src/modules/currency/useExchangeCurrency';
import { useWatch } from 'react-hook-form';
import { Money } from '../../shared/schemas';
import { useTaskContext } from '../../../../TaskContext';
import { useGetContractQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { FormData } from '../schema';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

type ReturnType =
  | undefined
  | {
      eligibleForVerticeNegotiation?: boolean;
      threshold?: Money;
    };

export const useRenewalVerticeNegotiationEligibility = (): ReturnType => {
  const account = useAccountContext();
  const { exchangeCurrency, isLoading: isLoadingExchangeRates } = useExchangeCurrency();
  const { taskOverview } = useTaskContext();
  const contractId = taskOverview?.task?.input?.contractId;
  const parentAccountId = useWatch<FormData, 'parentAccountId'>({ name: 'parentAccountId' });

  const contractAccountId = parentAccountId || account.accountId;
  const contract = useGetContractQuery(
    { contractId, accountId: contractAccountId },
    { skip: !contractId || !contractAccountId }
  );

  const verticeNegotiationThreshold = taskOverview?.task?.input?.verticeNegotiationThreshold;
  const approvedBudget = useWatch<FormData, 'approvedBudget'>({ name: 'approvedBudget' });
  const budgetCurrency = useWatch<FormData, 'contractCurrency'>({ name: 'contractCurrency' });

  if (!verticeNegotiationThreshold || typeof approvedBudget !== 'number' || !budgetCurrency || isLoadingExchangeRates) {
    return undefined;
  }

  const convertedBudget = exchangeCurrency({
    sourceCurrency: budgetCurrency,
    targetCurrency: verticeNegotiationThreshold.currency,
    value: approvedBudget,
  });

  if (convertedBudget === undefined) {
    return undefined;
  }

  const convertedContractValue = exchangeCurrency({
    sourceCurrency:
      contract.data?.viewOnContract?.contract?.parts?.contractual?.financial?.baseCurrency ??
      verticeNegotiationThreshold.currency,
    targetCurrency: verticeNegotiationThreshold.currency,
    value: contract.data?.viewOnContract?.contract?.parts?.overview?.effectiveAnnualCost,
  });

  return convertedContractValue === undefined
    ? undefined
    : {
        eligibleForVerticeNegotiation:
          convertedBudget > verticeNegotiationThreshold.value ||
          convertedContractValue > verticeNegotiationThreshold.value,
        threshold: verticeNegotiationThreshold,
      };
};
