import { FieldDef, FieldType } from '../types';
import { getMoneyJsonSchema, getMoneyPresenterDefault, getMoneyPresenterSchema } from './utils';
import { MoneyFieldType } from './types';
import { MoneyFieldBuilderCard } from './MoneyFieldBuilderCard';
import { MoneyFieldPresenter } from './MoneyFieldPresenter';
import { MoneyFieldBuilderForm } from './MoneyFieldBuilderForm';

export const fieldDef: FieldDef<MoneyFieldType> = {
  type: FieldType.MONEY,
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.MONEY',
  builderCard: MoneyFieldBuilderCard,
  builderForm: MoneyFieldBuilderForm,
  presenter: MoneyFieldPresenter,
  getPresenterDefault: getMoneyPresenterDefault,
  getPresenterSchema: getMoneyPresenterSchema,
  getJsonSchema: getMoneyJsonSchema,
};
