import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import { ContractFormData } from '../../types';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { SimpleOption } from '@verticeone/design-system';
import ContractFormEntry, { ContractFormEntryPropsWithFixedComponents } from '../../ContractFormEntry';
import React, { useMemo } from 'react';
import { keyBy } from 'lodash';
import { useContractCategoryOptions } from '../../hooks/useContractCategoryOptions';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormSelectFieldComponentType<ContractFormData, SimpleOption, false>;

export const ContractCategoryFormEntry = (
  props: ContractFormEntryPropsWithFixedComponents<ReadComponent, WriteComponent>
) => {
  const contractCategoryOptions = useContractCategoryOptions();
  const contractCategoryByValue = useMemo(() => keyBy(contractCategoryOptions, 'value'), [contractCategoryOptions]);

  return (
    <ContractFormEntry<
      FormReadOnlyTextType<ContractFormData>,
      FormSelectFieldComponentType<ContractFormData, SimpleOption, false>
    >
      {...props}
      component={FormReadOnlyText}
      componentProps={{
        formatter: (value?: string | null) => (value ? contractCategoryByValue[value]?.label : '-'),
        ...props.componentProps,
      }}
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...props.writeComponentProps,
        options: contractCategoryOptions,
        isClearable: false,
        boxValue: (value) => (value ? contractCategoryByValue[value] : undefined),
        unboxValue: (selectValue) => selectValue?.value,
      }}
      width={12}
    />
  );
};
