import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { Alert, Text } from '@verticeone/design-system';
import { formatCurrency } from '@verticeone/utils/formatting';
import { Button } from '@verticeone/design-system';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { SpriAction } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

import MissingPermissionTooltip from './MissingPermissionTooltip';
import useAwsSpRioSubFeatures from '@vertice/dashboard/src/pages/Cloud/CloudRIO/useAwsSpRioSubFeatures';
import BuySPModal from './BuySPModal';
import { useSPPurchasePermissionQuery } from '../../../../../dataSources/useSPPurchasePermissionQuery';
import { calculateShoppingCartUnits } from '../../../utils';
import { useShoppingCart } from '../../../../providers/ShoppingCartProvider';
import { useInstanceData } from '../../../../providers/InstanceProvider';
import { WarningAmberOutlined } from '@mui/icons-material';

type TableFooterProps = {
  totalCost: number;
  isEmpty: boolean;
};

declare module '@mui/x-data-grid-pro' {
  interface FooterPropsOverrides {
    isEmpty: boolean;
    totalCost: number;
  }
}

const TableFooter = ({ totalCost, isEmpty }: TableFooterProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE.BUY_INSTANCE_TABLE.FOOTER' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();
  const awsRioSubFeatures = useAwsSpRioSubFeatures();
  const { cartItems, filterByShoppingCart } = useShoppingCart();
  const { item } = useInstanceData();

  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const { isFetching: isSPPurchasePermissionFetching, data: isSPPurchaseAllowed } = useSPPurchasePermissionQuery({
    action: SpriAction.Purchase,
  });

  const isLoading = isSPPurchasePermissionFetching || awsRioSubFeatures.isFetching;
  const recommendationExceeded =
    filterByShoppingCart && calculateShoppingCartUnits(cartItems) > item.to_cover_hourly_recommendation * 24;

  return (
    <Stack
      sx={{
        borderTop: `1px solid ${palette.core.color2}`,
      }}
    >
      {recommendationExceeded && (
        <Stack pt={4} px={4}>
          <Alert
            size="XS"
            variant="ghost"
            color="warning"
            subtitle={t('RECOMMENDATION_EXCEEDED')}
            icon={WarningAmberOutlined}
          />
        </Stack>
      )}
      <Stack direction="row" py={3} px={6} justifyContent="end" alignItems="center" gap={4}>
        <Stack direction="row" gap={2}>
          <Text variant="body-regular" color="text3">
            {t('COST')}
          </Text>
          <Text variant="body-bold">
            {formatCurrency(totalCost, {
              currency: AWS_DEFAULT_CURRENCY,
              locale,
              maximumFractionDigits: 2,
            })}
          </Text>
        </Stack>
        <Button
          testId="buy"
          variant="solid"
          color="tertiary"
          disabled={isEmpty || !isSPPurchaseAllowed}
          isLoading={isLoading}
          onClick={() => setIsBuyModalOpen(true)}
        >
          {t('BUY')}
        </Button>
        {!isSPPurchaseAllowed && <MissingPermissionTooltip />}
        <BuySPModal open={isBuyModalOpen} setOpen={setIsBuyModalOpen} />
      </Stack>
    </Stack>
  );
};

export default TableFooter;
