import { FC, PropsWithChildren } from 'react';
import { usePagesContext } from './PagesContext';

type FormPageProps = PropsWithChildren & {
  pageId: string;
};

export const FormPage: FC<FormPageProps> = ({ pageId, children }) => {
  const { currentPageId } = usePagesContext();

  if (pageId !== currentPageId) {
    return;
  }

  return children;
};
