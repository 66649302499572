import { API_URLS } from '@vertice/slices';
import { ComponentProps, ComponentType } from 'react';

type BaseVendorLogoProps = {
  vendorId: string;
} & Pick<ComponentProps<ComponentType<HTMLImageElement>>, 'width' | 'height'>;

export const BaseVendorLogo = ({ vendorId, width, height }: BaseVendorLogoProps) => {
  return <img alt="logo" src={`${API_URLS.LOGOS_URL}/${vendorId}.svg`} width={width} height={height} />;
};
