import { FieldType } from '../../fields/types';
import { z } from 'zod';

export type DateFieldType = {
  name: string;
  type: FieldType.DATE;
  metadata: {
    label: string;
    description?: string;
    required: boolean;
  };
};

export const dateFieldSchema = z.object({
  name: z.string(),
  type: z.literal(FieldType.DATE),
  metadata: z.object({
    label: z.string(),
    description: z.string().optional(),
    required: z.boolean(),
  }),
});
