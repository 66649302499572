import { z } from 'zod';

export const zodSchema = z
  .object({
    approved: z.boolean(),
    approvalNotes: z.string().nullish(),
  })
  .refine((data) => data.approved || !!data.approvalNotes, {
    message: 'Notes are required when rejecting',
    path: ['approvalNotes'],
  });

export type FormData = z.infer<typeof zodSchema>;
