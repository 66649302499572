import { useController, FieldValues } from 'react-hook-form';
import { Editor } from '@verticeone/design-system';
import { CommonFormFieldProps } from '../../types';

type FormEditorFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType>;

export type FormEditorFieldComponentType<FormDataType extends FieldValues> = (
  props: FormEditorFieldProps<FormDataType>
) => JSX.Element;

const FormEditorField = <FormDataType extends FieldValues = never>({
  id,
  name,
  required,
  disabled,
  ...otherProps
}: FormEditorFieldProps<FormDataType>) => {
  const {
    field: { ref, value, ...field },
    fieldState: { invalid },
  } = useController<FormDataType>({
    name,
    rules: {
      required,
    },
  });

  return (
    <Editor
      id={id}
      ref={ref}
      value={value as string}
      disabled={disabled}
      hasError={invalid}
      variant="outline"
      size="M"
      color="primary"
      {...otherProps}
      {...field}
    />
  );
};

export default FormEditorField;
