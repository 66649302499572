import { USER_ROLES } from '../../../../../../constants/userRoles';
import { PredefinedWidgetDef } from '../shared/types';
import { LinkedContractsSection } from './LinkedContractsSection';

export const LINKED_CONTRACT_WIDGET_URN_PATTERN = /^widget\/saas\/contracts\/v\d+$/;
export const LINKED_NEW_CONTRACT_WIDGET_URN_PATTERN = /^widget\/saas\/new-contract\/v\d+$/;

export const widgetDef: PredefinedWidgetDef = {
  urnSuffix: LINKED_CONTRACT_WIDGET_URN_PATTERN,
  component: LinkedContractsSection,
  titleTranslationKey: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.RENEWING_CONTRACT',
  restrictedRoles: [USER_ROLES.user],
  renderPredicate: (widgetData) => !!widgetData?.contractId || !!widgetData?.createdContractId,
};

export const newContractWidgetDef: PredefinedWidgetDef = {
  urnSuffix: LINKED_NEW_CONTRACT_WIDGET_URN_PATTERN,
  component: LinkedContractsSection,
  titleTranslationKey: 'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.SECTIONS.NEW_CONTRACT',
  restrictedRoles: [USER_ROLES.user],
  renderPredicate: (widgetData) => !!widgetData?.createdContractId,
};
