import { FC } from 'react';
import { useLoggedUser } from '@verticeone/auth/src';
import { EllipsisText } from '@verticeone/design-system';
import { Stack } from '@mui/material';

import { TaskIcon } from '../../../../../sharedVisualStyle/taskStyle';
import { TasksThumbnail } from '../../../../../definitionsTypes';
import { WorkflowTaskNodeType } from '../../../../../workflowSchema/model/types';

type RequestNameProps = {
  name: string;
  thumbnail: TasksThumbnail;
  type: WorkflowTaskNodeType;
};

export const RequestName: FC<RequestNameProps> = ({ name, thumbnail, type }) => {
  const { userId } = useLoggedUser();

  return (
    <Stack p={4} flex={1.5} direction="row" alignItems="center" gap={4}>
      <TaskIcon
        size="L"
        userId={userId}
        type={thumbnail.type}
        id={thumbnail.id}
        withSubProcessIcon={type !== 'VERTICE_SERVICE'}
        taskType={type}
        status="ACTIVE"
      />
      <EllipsisText variant="heading" size="S">
        {name}
      </EllipsisText>
    </Stack>
  );
};
