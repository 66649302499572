import { RenewalProduct, useCreateRenewalRequest } from './useCreateRenewalRequest';
import { useCallback } from 'react';
import { useAccountContext } from '../../../contexts/AccountContext';
import { Vendor } from '../../vendor/types';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type CreateRenewalRequestFlowParams = {
  vendor: Vendor;
  products?: RenewalProduct[];
  contractId: string;
  department?: string;
  source?: string;
};

export const useCreateRenewalRequestFlow = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { createRenewalRequest, isCreatingRenewalRequest } = useCreateRenewalRequest();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { enqueueSnackbar } = useSnackbar();

  const createRenewalRequestFlow = useCallback(
    async ({ products, ...otherParams }: CreateRenewalRequestFlowParams) => {
      const { requestId } = await createRenewalRequest({
        ...otherParams,
        source:
          otherParams.source ||
          `urn:verticeone:vertice:${accountId}:cfa:page/intake/existingContract/${otherParams.contractId}`,
        products: products,
      });
      if (requestId) {
        enqueueSnackbar(t('ENTITIES.WORKFLOW_REQUEST.SNACKBARS.CREATED'), { variant: 'success' });

        navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
          requestId,
        });
      } else {
        enqueueSnackbar(t('ENTITIES.WORKFLOW_REQUEST.SNACKBARS.FAILED_TO_CREATE'), { variant: 'error' });
      }
    },
    [accountId, createRenewalRequest, enqueueSnackbar, navigate, routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, t]
  );

  return {
    createRenewalRequestFlow,
    isCreatingRenewalRequest,
  };
};
