import * as React from 'react';
import { useController, Validate, FieldValues } from 'react-hook-form';
import { TextFieldVariant } from '@verticeone/design-system';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import { CommonFormFieldProps } from '../../types';
import { DesignSystemColor } from '@verticeone/design-system';
import { DatePicker } from '@verticeone/design-system';

dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

type FormDateFieldPropsBase<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  validate?: Record<any, Validate<Dayjs, FormDataType>>;

  /** Minimal allowed date in the format 'YYYY-MM-DD' */
  minDate?: string;
  /** Maximum allowed date in the format 'YYYY-MM-DD' */
  maxDate?: string;
  label?: string;
  variant?: TextFieldVariant;
  color?: DesignSystemColor;
};

type FormDateFieldPropsWithTime<FormDataType extends FieldValues> = FormDateFieldPropsBase<FormDataType> & {
  mode?: 'withTime';
  timezone: string;
};

type FormDateFieldPropsWithoutTime<FormDataType extends FieldValues> = FormDateFieldPropsBase<FormDataType> & {
  mode?: 'withoutTime';
  timezone?: never;
};

type FormDateFieldProps<FormDataType extends FieldValues> =
  | FormDateFieldPropsWithTime<FormDataType>
  | FormDateFieldPropsWithoutTime<FormDataType>;

export type FormDateFieldType<FormDataType extends FieldValues> = (
  props: FormDateFieldProps<FormDataType>
) => JSX.Element;

const FormDateField = <FormDataType extends Record<string, any>>({
  id,
  name,
  required,
  validate,
  disabled,
  minDate,
  maxDate,
  label,
  variant = 'outlined',
  color = 'primary',
  mode = 'withoutTime',
  timezone,
}: FormDateFieldProps<FormDataType>) => {
  const {
    field: { ref, onChange, value, ...field },
    fieldState: { invalid, error },
  } = useController<FormDataType>({
    name,
    rules: {
      required,
      validate,
    },
  });

  const handleDateChange = (date: string | null) => {
    if (mode === 'withTime' && timezone && date) {
      const startOfDay = dayjs.tz(date, timezone).toISOString();
      onChange(startOfDay);
    } else {
      onChange(date);
    }
  };

  return (
    <DatePicker
      id={id}
      value={value || null}
      minDate={minDate}
      maxDate={maxDate}
      onChange={handleDateChange}
      disabled={disabled}
      name={name}
      label={label}
      variant={variant}
      color={color}
      error={invalid}
      helperText={error?.message}
      hiddenLabel={label === undefined}
      textFieldProps={field}
    />
  );
};

export default FormDateField;
