import { useListVendorProductsQuery } from '@vertice/slices';
import { useMemo } from 'react';
import { useAccountContext } from '../contexts/AccountContext';
import { FEATURES } from '../modules/features/constants';

export type UseHasBenchmarkingDataParams = {
  vendorId?: string;
  products?: string[];
};

export const useHasProductsWithBenchmarkingData = ({ vendorId, products }: UseHasBenchmarkingDataParams): number => {
  const { isFeatureEnabled } = useAccountContext();
  const benchmarkingEnabled = isFeatureEnabled(FEATURES.VENDOR_BENCHMARKING);
  const { data: vendorData } = useListVendorProductsQuery(
    {
      vendorId: vendorId!,
      benchmarkedOnly: true,
    },
    { skip: !vendorId || !benchmarkingEnabled }
  );

  return useMemo(() => {
    if (!products || !products.length || !vendorData) {
      return 0;
    }

    const allProductsWithBenchmark = vendorData?.products?.map((product) => product.id) ?? [];
    return products.filter((product) => allProductsWithBenchmark.includes(product)).length;
  }, [vendorData, products]);
};
