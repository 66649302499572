import { Dropzone, ErrorMessageList, FileList, Fileupload } from '@verticeone/design-system';
import useContractDocuments from './useContractDocuments';
import { Stack } from '@mui/material';
import { Loader } from '@verticeone/design-system';
import { validator, customErrorCodes, maxFileBytesSize, acceptedTypes } from './utils';

const DocumentUpload = () => {
  const { files, isFetching, uploadFiles, deleteFiles, downloadFile, canEditFiles } = useContractDocuments();

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Fileupload
      multiple
      acceptedTypes={acceptedTypes}
      maxFileSize={maxFileBytesSize}
      files={files}
      onAddFiles={uploadFiles}
      onRemoveFiles={deleteFiles}
      validator={validator}
      customErrorCodes={customErrorCodes}
    >
      <Stack gap={4}>
        <FileList
          size="M"
          hideErrorFiles
          dynamicFileNameLength
          onFileNameClick={downloadFile}
          hiddenColumns={canEditFiles ? undefined : ['actions']}
        />
        {canEditFiles && (
          <Stack height={210} alignItems="center">
            <Dropzone size="M" />
          </Stack>
        )}
        <ErrorMessageList size="S" />
      </Stack>
    </Fileupload>
  );
};

export default DocumentUpload;
