import React, { useMemo } from 'react';
import { isNotNil } from '@verticeone/utils/validation';
import { useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { useTranslation } from 'react-i18next';
import { IconButton, TabProps, Tooltip } from '@verticeone/design-system';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import { useHasProductsWithBenchmarkingData } from '@vertice/core/src/hooks/useHasProductsWithBenchmarkingData';
import { Box } from '@mui/material';

export type UseProductTabBenchmarkingDataReturn = TabProps | undefined;

export const useProductTabBenchmarkingProps = (): UseProductTabBenchmarkingDataReturn => {
  const { t } = useTranslation();
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const contract = contractContext.fetchedContract.contract;

  const contractProductIds = useMemo(
    () => contract.parts.contractual?.products?.map((product) => product.productId).filter(isNotNil),
    [contract.parts.contractual?.products]
  );

  const numberOfProductsWithBench = useHasProductsWithBenchmarkingData({
    vendorId: contract.parts.contractual?.vendor?.vendorId,
    products: contractProductIds,
  });

  return useMemo(() => {
    if (!numberOfProductsWithBench) {
      return undefined;
    }

    return {
      icon: (
        <Tooltip
          size="S"
          noArrow
          content={t('ENTITIES.CONTRACT.TOOLTIPS.BENCHMARKING.ICON_TOOLTIP_DESCRIPTION')}
          title={t('ENTITIES.CONTRACT.TOOLTIPS.BENCHMARKING.ICON_TOOLTIP_TITLE', { count: numberOfProductsWithBench })}
        >
          <Box ml={1}>
            <IconButton
              icon={LeaderboardOutlinedIcon}
              size="XS"
              color="primary"
              component="div"
              aria-label={t('ENTITIES.CONTRACT.LABELS.BENCHMARK')}
              sx={{ pointerEvents: 'none' }}
            />
          </Box>
        </Tooltip>
      ),
      iconPosition: 'end',
    };
  }, [numberOfProductsWithBench, t]);
};
