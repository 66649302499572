import { createContext, PropsWithChildren, useContext, useState } from 'react';
import type { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import type { SerializedError } from '@reduxjs/toolkit';
import { RiListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

import { useInstanceData } from './InstanceProvider';
import { useShoppingCart } from './ShoppingCartProvider';
import { useGetOfferingsHandler } from './GetOfferingsHandlerProvider';
import { DataType, FilterDataOptions } from '../BuySPDrawer/BuySPDrawerTable/components/filter/useDataOptions';
import { OfferingData } from '../../types';
import { useDataSource } from '../../../DataSourceProvider';

type optionValue = string | number | boolean | undefined;
export type OfferingsData = OfferingData;

type Offerings = {
  data: RiListOfferingsQuery | undefined;
  error: ErrorResponse | SerializedError | undefined;
  isUninitialized: boolean;
  isFetching: boolean;
};

type OfferingsContextData = {
  filter: {
    data: Partial<FilterDataOptions>;
    isApplied: boolean;
    getValue: (key: DataType) => optionValue;
    updateFilter: (key: DataType, value: optionValue) => void;
  };
  offerings: {
    fetch: () => void;
  } & Offerings;
};

type UseOfferingsProps = {
  filterByShoppingCart?: boolean;
};

type OfferingsProviderProps = PropsWithChildren & {
  defaultFilter?: FilterDataOptions;
  filterByShoppingCard?: boolean;
};

const OfferingsContext = createContext<OfferingsContextData>({} as OfferingsContextData);

const OfferingsProvider = ({ children, defaultFilter, filterByShoppingCard = false }: OfferingsProviderProps) => {
  const { getOfferings } = useGetOfferingsHandler();
  const [filter, setFilter] = useState<Partial<FilterDataOptions>>(defaultFilter ?? {});
  const [offerings, setOfferings] = useState<Offerings>({
    data: undefined,
    error: undefined,
    isUninitialized: true,
    isFetching: false,
  });

  const fetchOfferings = (_filter: Partial<FilterDataOptions>) => {
    setOfferings((prev) => ({ ...prev, isUninitialized: false, isFetching: true }));
    void getOfferings(_filter).then((response) =>
      setOfferings((prev) => ({
        ...prev,
        ...response,
        isFetching: false,
      }))
    );
  };

  const getValue = (key: DataType) => filter[key] ?? undefined;

  const updateFilter = (key: DataType, value: optionValue) => {
    setFilter((prev) => {
      const newFilter = { ...prev, [key]: value };
      fetchOfferings(newFilter);

      return newFilter;
    });
  };

  const fetch = () => {
    if (offerings.isUninitialized) {
      fetchOfferings(filter);
    }
  };

  return (
    <OfferingsContext.Provider
      value={{
        filter: {
          data: filter,
          isApplied: Object.values(filter).some((value) => value !== undefined),
          getValue,
          updateFilter,
        },
        offerings: {
          fetch,
          error: offerings.error,
          isUninitialized: offerings.isUninitialized,
          isFetching: offerings.isFetching,
          data: offerings.data,
        },
      }}
    >
      {children}
    </OfferingsContext.Provider>
  );
};

export const useOfferings = (props?: UseOfferingsProps) => {
  const { item } = useInstanceData();
  const { effectiveDate } = useDataSource();
  const { cartItems, filterByShoppingCart } = useShoppingCart();
  const { offeringsParser } = useGetOfferingsHandler();
  const context = useContext(OfferingsContext);

  return {
    ...context,
    offerings: {
      ...context.offerings,
      data: offeringsParser(
        context?.offerings?.data,
        item,
        props?.filterByShoppingCart ?? filterByShoppingCart ? cartItems : null,
        effectiveDate
      ),
    },
  };
};

export default OfferingsProvider;
