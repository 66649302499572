import { useId, FC } from 'react';
import { Stack } from '@mui/material';
import { TextField, TextFieldArea, TextFieldCaption } from '@verticeone/design-system';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { EditorDrawerGroup } from '../../EditorDrawer';
import { EditUserTaskFormData } from './schema';

type TaskFieldsProps = {
  taskName?: string;
};

export const TaskFields: FC<TaskFieldsProps> = ({ taskName }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK' });
  const id = useId();

  const { register } = useFormContext<EditUserTaskFormData>();

  return (
    <EditorDrawerGroup title={t('TITLES.TASK_FIELDS')}>
      <Stack gap={1}>
        <TextFieldCaption
          id={`${id}-formHeading-caption`}
          label={t('FORM_HEADING')}
          size="XS"
          htmlFor={`${id}-formHeading`}
        />
        <TextFieldArea
          {...register('formHeading')}
          id={`${id}-formHeading`}
          placeholder={t('WHAT_IS_THE_FINAL_DECISION', { taskName })}
          size="S"
          variant="outline"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          maxRows={3}
          minHeight={16}
          style={{ resize: 'none' }}
        />
      </Stack>
      <Stack direction={'row'} gap={4} alignItems={'center'} width={'100%'}>
        <Stack gap={1} width="100%">
          <TextFieldCaption
            id={`${id}-buttonLabels.approve-caption`}
            label={t('BUTTON_LABELS.TITLE_APPROVE')}
            size="XS"
            htmlFor={`${id}-buttonLabels.approve`}
          />
          <TextField
            {...register('buttonLabels.approve')}
            id={`${id}-buttonLabels.approve`}
            placeholder={t('BUTTON_LABELS.APPROVE')}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          />
        </Stack>
        <Stack gap={1} width="100%">
          <TextFieldCaption
            id={`${id}-buttonLabels.reject-caption`}
            label={t('BUTTON_LABELS.TITLE_DENY')}
            size="XS"
            htmlFor={`${id}-buttonLabels.reject`}
          />
          <TextField
            {...register('buttonLabels.reject')}
            id={`${id}-buttonLabels.reject`}
            placeholder={t('BUTTON_LABELS.DENY')}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          />
        </Stack>
      </Stack>
    </EditorDrawerGroup>
  );
};
