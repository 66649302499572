import {
  ActivityHookConfiguration,
  Task,
  TaskAssignmentConfiguration,
  TaskConfiguration,
  TaskDefinition,
  TaskIOMappingConfiguration,
  VerticeFormConfiguration,
  VerticeServiceConfiguration,
} from '../definitionsTypes';
import { isVerticeFormConfiguration } from '../pocWorkflowSchema';

type NonNullableTaskConfiguration = Exclude<Task['configurations'], undefined>[number];

export const replaceConfigurationInTaskDefinition = (
  taskDefinition: TaskDefinition,
  configuration: NonNullableTaskConfiguration
) => {
  return {
    ...taskDefinition,
    task: {
      ...taskDefinition.task,
      configurations: [
        ...(taskDefinition.task.configurations ?? []).filter(
          (c): c is NonNullableTaskConfiguration => c.kind !== configuration.kind
        ),
        configuration,
      ],
    },
  };
};

export const isVerticeServiceConfiguration = (
  configuration:
    | TaskConfiguration
    | ActivityHookConfiguration
    | TaskIOMappingConfiguration
    | TaskAssignmentConfiguration
    | VerticeServiceConfiguration
): configuration is VerticeServiceConfiguration => {
  return configuration.kind === 'Vertice:Service';
};

export const isAssignmentConfiguration = (
  configuration:
    | TaskConfiguration
    | ActivityHookConfiguration
    | TaskIOMappingConfiguration
    | TaskAssignmentConfiguration
): configuration is TaskAssignmentConfiguration => {
  return configuration.kind === 'Tasks:Assignment';
};

export const getTaskAssignmentConfigurations = (task: TaskDefinition): TaskAssignmentConfiguration[] => {
  return task.task.configurations?.filter(isAssignmentConfiguration) ?? [];
};

export const getTaskServiceConfiguration = (task: TaskDefinition): VerticeServiceConfiguration | undefined => {
  return task.task.configurations?.find(isVerticeServiceConfiguration);
};

export const getTaskFormConfiguration = (task: TaskDefinition): VerticeFormConfiguration | undefined => {
  return task.task.configurations?.find(isVerticeFormConfiguration);
};

export const isIOMappingConfiguration = (
  configuration:
    | TaskConfiguration
    | ActivityHookConfiguration
    | TaskIOMappingConfiguration
    | TaskAssignmentConfiguration
): configuration is TaskIOMappingConfiguration => configuration.kind === 'ProcessEngine:TaskIOMapping';

export const updateTaskIOMapping = (
  taskDefinition: TaskDefinition,
  {
    name,
    description,
    ioMappingConfigurations,
  }: { name: string; description?: string; ioMappingConfigurations: TaskIOMappingConfiguration[] }
): TaskDefinition => {
  const newConfigurations = [
    ...(taskDefinition.task.configurations ?? []).filter((v) => !isIOMappingConfiguration(v)),
    ...ioMappingConfigurations,
  ];

  return {
    ...taskDefinition,
    task: {
      ...taskDefinition.task,
      name,
      description,
      configurations: newConfigurations,
    },
  };
};

export const updateTaskDefinition = (
  taskDefinition: TaskDefinition,
  {
    name,
    description,
    assignmentConfigurations,
    formConfiguration,
  }: {
    name: string;
    description?: string;
    assignmentConfigurations: TaskAssignmentConfiguration[];
    formConfiguration?: VerticeFormConfiguration;
  }
): TaskDefinition => {
  const newConfigurations = [
    ...(taskDefinition.task.configurations ?? []).filter(
      (v) => !isAssignmentConfiguration(v) && !isVerticeFormConfiguration(v)
    ),
    ...assignmentConfigurations,
    ...(formConfiguration ? [formConfiguration] : []),
  ];

  return {
    ...taskDefinition,
    task: {
      ...taskDefinition.task,
      name,
      description,
      configurations: newConfigurations,
    },
  };
};
