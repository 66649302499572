import { useCallback, useEffect, useMemo, useState } from 'react';
import { PredefinedFormPagesDef } from '../predefinedForms/shared/types';

export const usePagesNavigation = (
  pages?: PredefinedFormPagesDef,
  onPageVisited?: (pageIId: string) => void,
  defaultCurrentPageId?: string
) => {
  const pageIdsInOrder = useMemo(() => pages?.map((page) => page.id) ?? [], [pages]);

  const [currentPageId, setCurrentPageId] = useState<string>();
  useEffect(() => {
    if (defaultCurrentPageId) {
      setCurrentPageId(defaultCurrentPageId);
      return;
    }
    const initialPageId = pages?.[0]?.id;
    if (initialPageId) {
      setCurrentPageId(initialPageId);
      onPageVisited?.(initialPageId);
    }
  }, [defaultCurrentPageId, onPageVisited, pages]);

  const goToPage = useCallback(
    (pageId: string) => {
      setCurrentPageId(pageId);
      onPageVisited?.(pageId);
    },
    [onPageVisited]
  );

  const isOnLastPage = pageIdsInOrder[pageIdsInOrder.length - 1] === currentPageId;

  const currentPageIndex = currentPageId ? pageIdsInOrder.indexOf(currentPageId) : -1;

  const goToNextPage = useCallback(() => {
    if (currentPageIndex < pageIdsInOrder.length - 1) {
      const nextPageId = pageIdsInOrder[currentPageIndex + 1];
      setCurrentPageId(nextPageId);
      onPageVisited?.(nextPageId);
    }
  }, [currentPageIndex, pageIdsInOrder, onPageVisited]);

  const hasPreviousPage = currentPageIndex > 0;

  const goToPreviousPage = useCallback(() => {
    const prevPageId = pageIdsInOrder[currentPageIndex - 1];
    setCurrentPageId(prevPageId);
    onPageVisited?.(prevPageId);
  }, [currentPageIndex, onPageVisited, pageIdsInOrder]);

  return useMemo(
    () => ({
      currentPageId,
      goToNextPage,
      goToPreviousPage,
      hasPreviousPage,
      goToPage,
      isOnLastPage,
    }),
    [currentPageId, goToNextPage, goToPreviousPage, hasPreviousPage, goToPage, isOnLastPage]
  );
};
