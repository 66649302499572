import { useMutation } from '@tanstack/react-query';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';

export type Purchase = {
  savingsPlanOfferingId: string;
  savingsPlanType: string;
  commitment: number;
  upfrontPaymentAmount: number;
  purchaseExecutionTime: string;
};

type UseSPPurchaseMutationProps = Partial<Record<'onSuccess' | 'onError' | 'onSettled', (value?: string) => void>>;

export const QUERY_KEY = 'SPPurchaseMutation' as const;

const SPPurchaseMutation = graphql(`
  mutation SPPurchase($accountId: String!, $purchases: [SPPurchaseSavingsPlan!]!) {
    buySavingsPlan(params: { accountId: $accountId, purchases: $purchases }) {
      ... on SavingsPlanPurchaseResult {
        __typename
        items {
          offeringId
          status
          purchaseOrderId
          purchaseExecutionTime
        }
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

export const useBuySavingsPlanMutation = (options?: UseSPPurchaseMutationProps) => {
  const { accountId } = useAccountContext();
  // const { enqueueSnackbar } = useSnackbar();
  const { fetchCloudOptimization } = useCloudClient();

  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (purchases: Purchase[]) => {
      return fetchCloudOptimization(SPPurchaseMutation, {
        accountId,
        purchases,
      });
    },
    onSettled: () => {
      options?.onSettled?.();
    },
    onSuccess: async (response) => {
      if (response?.buySavingsPlan?.__typename === 'ErroredQueryResult') {
        options?.onError?.(response.buySavingsPlan?.error);
        return;
      }

      /*
      @todo invalidate queries
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['sprioPurchasesQuery'] }),
      ]);
       */

      options?.onSuccess?.();
    },
    onError: (errors: Array<Error>) => {
      /*
      @todo test error handling
      errors.forEach((e) =>
        enqueueSnackbar({
          variant: 'error',
          message: e.message,
        })
      );
       */
      options?.onError?.();
    },
  });
};
