import { z } from 'zod';
import { formDataProductItemSchema, vendorSchema } from '../shared/schemas';
import { t } from 'i18next';
import { emailOrEmpty } from '@verticeone/utils/validation';

export const zodSchema = z
  .object({
    renewalType: z.enum(['UPGRADE', 'FLAT_RENEWAL', 'DOWNGRADE']).nullable(),
    vendor: vendorSchema,
    products: z.array(formDataProductItemSchema.omit({ annualCost: true })),
    targetSignDate: z.string().nullable(),

    contractCurrency: z.string().nullable(),
    approvedBudget: z.number().nullable(),
    verticeNegotiationRequested: z.boolean().optional(),
    internalNegotiationRequested: z.boolean().optional(),

    desiredContractLength: z.number().optional(),

    legalReviewRequired: z.boolean().optional(),
    securityReviewRequired: z.boolean().optional(),
    piiExpectedToBeHeld: z.boolean().optional(),

    additionalNotes: z.string().optional().nullish(),

    mainVendorContact: z.boolean().optional(),
    mainVendorContactName: z.string().optional(),
    mainVendorContactEmail: emailOrEmpty(t),

    contactVendorDirectly: z.boolean().optional(),
    discussMultiYearDeals: z.boolean().optional(),
    discussPlannedGrowth: z.boolean().optional(),
    discussCompetitors: z.boolean().optional(),
    discussCaseStudies: z.boolean().optional(),

    parentAccountId: z.string().nullish().optional(),
  })
  .refine((data) => !(data.mainVendorContact && !data.mainVendorContactName), {
    path: ['mainVendorContactName'],
  })
  .refine((data) => !(data.mainVendorContact && !data.mainVendorContactEmail), {
    path: ['mainVendorContactEmail'],
  })
  /*
   * The check for null must be done here because we want the validation of mainVendorContactName and mainVendorContactEmail
   * to always run, even if the properties bellow are null and zod does not allow this with "approvedBudget: z.number()" or "targetSignDate: z.string(), or ...".
   */
  .refine((data) => data.approvedBudget !== null, {
    path: ['approvedBudget'],
  })
  .refine((data) => data.targetSignDate !== null, {
    path: ['targetSignDate'],
  })
  .refine((data) => data.renewalType !== null, {
    path: ['renewalType'],
  })
  .refine((data) => data.contractCurrency !== null, {
    path: ['contractCurrency'],
  });

export type FormData = z.infer<typeof zodSchema>;
