import React, { useId } from 'react';
import { Stack } from '@mui/material';
import { TextFieldCaption } from '@verticeone/design-system';
import FormControl from '@mui/material/FormControl';
import { useIsContractFieldVisible } from '../../hooks/fieldsRulesHooks';
import { useTranslation } from 'react-i18next';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import LargeValue from '../LargeValue';
import useContractFormDataSelector from '../../hooks/useContractFormDataSelector';
import { getAnnualTotalSavings, getAnnualTotalSavingsPercentage, getTotalSavings } from '../../../savings/computed';
import useFormatSavingsPercentage from '../../../savings/useFormatSavingsPercentage';
import { AnnualSavingsTooltip } from '../AnnualSavingsTooltip';

const AdvancedAnnualSavingsContractFormEntry = () => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();
  const formatSavingsPercentage = useFormatSavingsPercentage();
  const id = useId();

  const totalSavings = useContractFormDataSelector(getTotalSavings);
  const annualTotalSavings = useContractFormDataSelector(getAnnualTotalSavings);
  const annualTotalSavingsPct = useContractFormDataSelector(getAnnualTotalSavingsPercentage);

  const isAnnualTotalSavingsVisible = useIsContractFieldVisible('parts.overview.totalSavings');

  if (!isAnnualTotalSavingsVisible) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption
          htmlFor={id}
          label={t('ENTITIES.CONTRACT.LABELS.ANNUAL_SAVINGS')}
          size="XS"
          tooltip={{ content: <AnnualSavingsTooltip />, maxWidth: '580px' }}
        />
        <LargeValue
          id={id}
          primaryValue={formatContractCurrency(annualTotalSavings)}
          primaryTestId="annualTotalSavingsValue"
          primaryColor={annualTotalSavings > 0 ? 'success' : 'text1'}
          chipValue={annualTotalSavingsPct > 0 ? formatSavingsPercentage(annualTotalSavingsPct) : undefined}
          chipTestId="annualTotalSavingsPercentage"
          secondaryValue={t('ENTITIES.CONTRACT.LABELS.N_TSV', { value: formatContractCurrency(totalSavings) })}
          secondaryTestId="totalSavingsValue"
        />
      </Stack>
    </FormControl>
  );
};

export default AdvancedAnnualSavingsContractFormEntry;
