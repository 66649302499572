import { FC, PropsWithChildren, useMemo } from 'react';

import { Definitions } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { isProcessDefinition } from '../../pocWorkflowSchema';
import { processDefinitionToModel } from '../model/processDefinitionToModel';
import { WorkflowRenderer } from '../WorkflowRenderer/WorkflowRenderer';
import { useRequestState } from './useRequestState';
import { useServicesThumbnails } from './useServicesThumbnails';

type WorkflowViewerProps = PropsWithChildren<{
  definitions?: Definitions;
  requestId?: string;
  requestRoute?: string;
  allowVerticeServiceNavigation?: boolean;
  isEditor?: boolean;
}>;

export const WorkflowViewer: FC<WorkflowViewerProps> = ({
  definitions,
  requestId,
  requestRoute,
  allowVerticeServiceNavigation,
  isEditor = false,
  children,
}) => {
  const workflowState = useRequestState(requestId, requestRoute);
  const servicesThumbnails = useServicesThumbnails();

  const { model, key } = useMemo(() => {
    if (!definitions) {
      return { model: undefined, key: undefined };
    }

    const processDefinition = definitions.definitions.find(isProcessDefinition);
    if (!processDefinition) {
      throw new Error('No process definition found');
    }

    return {
      model: processDefinitionToModel({ processDefinition, workflowState, servicesThumbnails }),
      key: processDefinition.process.id,
    };
  }, [definitions, workflowState, servicesThumbnails]);

  if (!model) {
    return null;
  }

  return (
    <WorkflowRenderer
      key={key}
      model={model}
      requestId={requestId!}
      workflowDefinitions={definitions}
      allowVerticeServiceNavigation={allowVerticeServiceNavigation}
      isEditor={isEditor}
    >
      {children}
    </WorkflowRenderer>
  );
};
