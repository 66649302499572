import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { TextVariants } from '@verticeone/design-system';
import { DesignSystemSize } from '@verticeone/design-system';
import { ShowOnRowHover } from '../ShowOnRowHover';
import { ComponentProps } from 'react';

type TwoRowCellProps = {
  title: string;
  subtitle?: string;
  hovering?: boolean;
  variant?: Exclude<TextVariants, 'display' | 'code'>;
  size?: DesignSystemSize;
} & Pick<ComponentProps<typeof Text>, 'color'>;

const TwoRowCell = ({ title, subtitle, variant = 'body-regular', size = 'S', color = 'text1' }: TwoRowCellProps) => {
  return (
    <Stack direction="column" justifyContent="center" gap={1}>
      <Text variant={variant} size={size} color={color}>
        {title}
      </Text>
      <ShowOnRowHover>
        {subtitle && (
          <Text color="text3" variant="caption" size="XS">
            {subtitle}
          </Text>
        )}
      </ShowOnRowHover>
    </Stack>
  );
};

export default TwoRowCell;
