import { ReservedInstance } from '../useRIPurchaseData';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@verticeone/design-system';
import React from 'react';
import DetailPanelCell from './DetailPanelCell';

type DetailPanelProps = {
  row: ReservedInstance;
  isPending?: boolean;
};

const DetailPanel = ({ row: { details }, isPending }: DetailPanelProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_PURCHASE.DETAILS' });
  const { palette } = useTheme();
  const getValueOrDefault = (value: string) => {
    return !value ? t('VALUES.NA') : value;
  };

  if (!details) {
    return null;
  }

  return (
    <TableContainer
      sx={{
        background: palette.core.color2,
      }}
      noBorder
      noBorderRadius
    >
      <Table size="XL" color="primary">
        <TableBody>
          <TableRow>
            <TableCell
              width={isPending ? 120 : 50}
              sx={{
                borderRight: `1px solid ${palette.core.color4} !important`,
              }}
            ></TableCell>
            <DetailPanelCell testId="offering-class" label={t('LABELS.OFFERING_CLASS')}>
              {getValueOrDefault(details.offeringClass)}
            </DetailPanelCell>
            <DetailPanelCell testId="family-size" label={t('LABELS.FAMILY_SIZE')}>
              {details.familySize}
            </DetailPanelCell>
            <DetailPanelCell testId="quantity" label={t('LABELS.QUANTITY')}>
              {details.quantity}
            </DetailPanelCell>
            <DetailPanelCell testId="tenancy" label={t('LABELS.TENANCY')}>
              {getValueOrDefault(details.tenancy)}
            </DetailPanelCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailPanel;
