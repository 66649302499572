import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Grid } from '@verticeone/design-system';
import DateContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/DateContractFormEntry';
import { ContractLengthComputedField } from '@vertice/core/src/modules/saas/contract/components/ContractLengthComputedField';
import RollFrequencyFormEntry from '@vertice/core/src/modules/saas/contract/components/form/RollFrequencyFormEntry';
import BillingFrequencyFormEntry from '@vertice/core/src/modules/saas/contract/components/form/BillingFrequencyFormEntry';
import ContractFormEntry from '@vertice/core/src/modules/saas/contract/ContractFormEntry';
import FormReadOnlyText from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { WorkflowStage } from '@vertice/core/src/modules/saas/contract/types';
import CheckboxContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/CheckboxContractFormEntry';
import RenewalDateFormEntry from '@vertice/core/src/modules/saas/contract/components/form/RenewalDateFormEntry';
import NextRollDateComputedField from '@vertice/core/src/modules/saas/contract/components/NextRollDateComputedField/NextRollDateComputedField';
import FinancialSection from '@vertice/core/src/modules/saas/contract/components/FinancialSection';
import PaymentTermsFormEntry from '@vertice/core/src/modules/saas/contract/components/form/PaymentTermsFormEntry';

export const shouldShowOfferSection = (contract?: Contract) =>
  contract?.workflow?.stage &&
  Array<WorkflowStage>('NEGOTIATING', 'APPROVAL', 'CONTRACTING').includes(contract.workflow.stage as WorkflowStage);

const PurchaseOfferSection = () => {
  const { t } = useTranslation();
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const shouldBeVisible = shouldShowOfferSection(contractContext.fetchedContract.contract);
  if (!shouldBeVisible) {
    return null;
  }

  return (
    <Stack gap={6}>
      <FinancialSection />

      <Grid container direction="row" spacing={8} px={6}>
        <Grid item xs={12} md={6} lg={4}>
          <RollFrequencyFormEntry
            name="parts.contractual.lifecycle.rollFrequency"
            label={t('ENTITIES.CONTRACT.LABELS.ROLLING_FREQUENCY')}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BillingFrequencyFormEntry
            name="parts.contractual.financial.billingFrequency"
            label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY')}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.financial.billingFrequencyOther"
            label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY_OTHER')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={8} px={6}>
        <Grid item xs={12} md={6} lg={4}>
          <PaymentTermsFormEntry
            name="parts.contractual.financial.paymentTerms"
            label={t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERMS')}
            width={12}
          />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.financial.paymentTermsOther"
            label={t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERMS_OTHER')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={8} px={6}>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="parts.contractual.vendor.purchasedFromReseller"
            label={t('ENTITIES.CONTRACT.LABELS.FROM_RESELLER')}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.vendor.resellerName"
            label={t('ENTITIES.CONTRACT.LABELS.RESELLER')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={8} px={6}>
        <Grid item xs={12} md={6} lg={4}>
          <DateContractFormEntry
            label={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
            name="parts.contractual.lifecycle.startDate"
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <RenewalDateFormEntry />
          <NextRollDateComputedField />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DateContractFormEntry
            label={t('ENTITIES.CONTRACT.LABELS.AUTO_RENEWAL_DATE')}
            name="parts.contractual.lifecycle.autoRenewalDeadline"
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractLengthComputedField />
        </Grid>
      </Grid>
    </Stack>
  );
};
export default PurchaseOfferSection;
