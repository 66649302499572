import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-hook-form';
import { usePagesContext } from './pages/PagesContext';
import { useTaskFormContext } from './predefinedForms/shared/TaskFormContext';
import getPropsForSubmitButton from '../../../../forms/utils/getPropsForSubmitButton';
import { Stack } from '@mui/material';
import { Button, Tooltip } from '@verticeone/design-system';
import { COMMON_BUTTON_PROPS, INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import React from 'react';

export const TaskPredefinedFormButtons = () => {
  const { t } = useTranslation();
  const formState = useFormState();
  const { hasPages, isOnLastPage, goToNextPage, goToPreviousPage, hasPreviousPage } = usePagesContext();
  const { isProcessing } = useTaskFormContext();
  const { disabled, isLoading } = getPropsForSubmitButton(formState, { disableWhenFormUntouched: false });

  // buttons for paginated form
  if (hasPages) {
    return (
      <Stack direction="row" padding={6} gap={2} justifyContent="space-between">
        <Stack>
          {hasPreviousPage && (
            <Button type="button" variant="plain" size="S" onClick={() => goToPreviousPage()}>
              {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.GO_TO_PREVIOUS_PAGE')}
            </Button>
          )}
        </Stack>

        {isOnLastPage ? (
          <Tooltip
            title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.COMPLETE_TOOLTIP')}
            size="S"
            disableHoverListener={!disabled || isProcessing || isLoading}
          >
            <Stack>
              <Button
                type="submit"
                variant="solid"
                size="S"
                color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                disabled={disabled || isProcessing}
                isLoading={isLoading || isProcessing}
              >
                {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.COMPLETE_TASK')}
              </Button>
            </Stack>
          </Tooltip>
        ) : (
          <Button
            type="button"
            variant="solid"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            onClick={() => goToNextPage()}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.CONTINUE_TO_NEXT_PAGE')}
          </Button>
        )}
      </Stack>
    );
  }

  // button for non-paginated form
  return (
    <Stack direction="row" padding={6} gap={2}>
      <Tooltip
        title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.COMPLETE_TOOLTIP')}
        size="S"
        disableHoverListener={!disabled || isProcessing || isLoading}
      >
        <Stack width="100%">
          <Button
            type="submit"
            variant="solid"
            {...COMMON_BUTTON_PROPS}
            disabled={disabled || isProcessing}
            isLoading={isLoading || isProcessing}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.SUBMIT_AND_COMPLETE')}
          </Button>
        </Stack>
      </Tooltip>
    </Stack>
  );
};
