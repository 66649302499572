import useSavingsStatsData from './useSavingsStatsData';
import { StatsBar, StatsBody, StatsContainer, StatsTile, Title, Value } from '@vertice/core/src/components/StatsBar';
import SolidStatsTile from '@vertice/core/src/components/StatsBar/StatsTile/SolidStatsTile';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { InvertedThemeProvider } from '@verticeone/design-system';
import { ChipButton } from '@verticeone/design-system';
import useFormatSavingsPercentage from '@vertice/core/src/modules/saas/savings/useFormatSavingsPercentage';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

const AnnualizedValue = ({ value, invertedTheme = false }: { value: string; invertedTheme?: boolean }) => {
  const { t } = useTranslation();
  const content = (
    <Text variant="caption" size="XS" color={invertedTheme ? 'transparent1' : 'text2'}>
      {t('SAAS.SAVINGS.SAVINGS_STATS_CARD.LABELS.N_ANNUALIZED', { value })}
    </Text>
  );

  return invertedTheme ? <InvertedThemeProvider>{content}</InvertedThemeProvider> : content;
};

const SavingsStatsCard = () => {
  const statsData = useSavingsStatsData();
  const formatCurrency = useFormatCurrency();
  const formatSavingsPercentage = useFormatSavingsPercentage();
  const { t } = useTranslation();

  const formatStatCurrency = (value: number) =>
    formatCurrency(value, { currency: statsData?.currency ?? DEFAULT_CURRENCY, maximumFractionDigits: 0 });

  return (
    <StatsBar>
      <StatsContainer flex={1}>
        <SolidStatsTile color="success">
          <StatsBody
            isLoading={!statsData}
            title={
              <Title
                tooltip={{
                  title: t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS'),
                  content: t('SAAS.SAVINGS.SAVINGS_STATS_CARD.TOOLTIPS.TOTAL_SAVINGS'),
                }}
              >
                {t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS')}
              </Title>
            }
            value={
              statsData && (
                <Stack gap={1}>
                  <Stack direction="row" gap={4} alignItems="center">
                    <Value>{formatStatCurrency(statsData.totalSavings)}</Value>
                    {statsData?.totalSavingsPercentage && statsData.totalSavingsPercentage > 0 ? (
                      <ChipButton isActive={false} color="success" variant="ghost" size="S">
                        {formatSavingsPercentage(statsData?.totalSavingsPercentage, { maximumFractionDigits: 0 })}
                      </ChipButton>
                    ) : undefined}
                  </Stack>
                  <AnnualizedValue invertedTheme value={formatStatCurrency(statsData.annualTotalSavings)} />
                </Stack>
              )
            }
          />
        </SolidStatsTile>
      </StatsContainer>
      <StatsContainer flex={3}>
        <StatsTile>
          <Stack direction="row" spacing={6}>
            <StatsBody
              flex={1.2}
              isLoading={!statsData}
              title={
                <Title
                  tooltip={{
                    title: t('ENTITIES.CONTRACT.LABELS.SAVINGS_VS_INITIAL'),
                    content: t('SAAS.SAVINGS.SAVINGS_STATS_CARD.TOOLTIPS.SAVINGS_VS_INITIAL'),
                  }}
                >
                  {t('ENTITIES.CONTRACT.LABELS.SAVINGS_VS_INITIAL')}
                </Title>
              }
              value={
                statsData && (
                  <Stack gap={1}>
                    <Value>{formatStatCurrency(statsData.savingsVsInitial)}</Value>
                    <AnnualizedValue value={formatStatCurrency(statsData.annualSavingsVsInitial)} />
                  </Stack>
                )
              }
            />
            <StatsBody
              flex={1}
              isLoading={!statsData}
              title={
                <Title
                  tooltip={{
                    title: t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_SAVINGS'),
                    content: t('SAAS.SAVINGS.SAVINGS_STATS_CARD.TOOLTIPS.ADDITIONAL_SAVINGS'),
                  }}
                >
                  {t('ENTITIES.CONTRACT.LABELS.ADDITIONAL_SAVINGS')}
                </Title>
              }
              value={
                statsData && (
                  <Stack gap={1}>
                    <Value>{formatStatCurrency(statsData.additionalSavings)}</Value>
                    <AnnualizedValue value={formatStatCurrency(statsData.annualAdditionalSavings)} />
                  </Stack>
                )
              }
            />
            <StatsBody
              flex={0.8}
              isLoading={!statsData}
              title={
                <Title
                  tooltip={{
                    title: t('ENTITIES.CONTRACT.LABELS.FUTURE_SAVINGS'),
                    content: t('SAAS.SAVINGS.SAVINGS_STATS_CARD.TOOLTIPS.FUTURE_SAVINGS'),
                  }}
                >
                  {t('ENTITIES.CONTRACT.LABELS.FUTURE_SAVINGS')}
                </Title>
              }
              value={
                statsData && (
                  <Stack gap={1}>
                    <Value>{formatStatCurrency(statsData.futureSavings)}</Value>
                    <AnnualizedValue value={formatStatCurrency(statsData.annualFutureSavings)} />
                  </Stack>
                )
              }
            />
          </Stack>
        </StatsTile>
      </StatsContainer>
      <StatsContainer flex={1}>
        <StatsTile>
          <StatsBody
            isLoading={!statsData}
            title={
              <Title
                tooltip={{
                  title: t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION'),
                  content: t('SAAS.SAVINGS.SAVINGS_STATS_CARD.TOOLTIPS.SCOPE_REDUCTION'),
                }}
              >
                {t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION')}
              </Title>
            }
            value={
              statsData && (
                <Stack gap={1}>
                  <Value>{formatStatCurrency(statsData.scopeReduction)}</Value>
                  <AnnualizedValue value={formatStatCurrency(statsData.annualScopeReduction)} />
                </Stack>
              )
            }
          />
        </StatsTile>
      </StatsContainer>
    </StatsBar>
  );
};

export default SavingsStatsCard;
