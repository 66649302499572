import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractsTableItem } from './types';
import { DataGrid, DataGridColDef } from '@verticeone/design-system';
import { GridCellParams, gridClasses, GridRenderCellParams, GridRowModel, GridRowParams } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material';
import ProductsCell from './cells/ProductsCell';
import { DepartmentCell } from './cells/DepartmentCell';
import { OwnersCell } from './cells/OwnersCell';
import { StageIndicatorCell } from './cells/StageIndicatorCell';
import NavigationCell from './cells/NavigationCell';
import { ViewOnContractForVendorsPage } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

const ROW_HEIGHT = 60;
const CATEGORY_ROW_HEIGHT = 48;
const HEADER_HEIGHT = 48;

const StyledDataGrid = styled(DataGrid<ContractsTableItem>)(({ theme }) => ({
  [`.${gridClasses.row}`]: {
    '&, &:hover, &.Mui-hovered': {
      backgroundColor: theme.palette.input.bg,
    },
  },

  '.group-row': {
    '&, &:hover, &.Mui-hovered': {
      backgroundColor: theme.palette.input.color1,
    },
  },
}));

export type VendorContractsTableProps = {
  rows?: ContractsTableItem[];
};

const VendorContractsTable = ({ rows }: VendorContractsTableProps) => {
  const { t } = useTranslation();

  const getRowId = (row: GridRowModel) => `${row.lineageId}_${row.contractId ? row.contractId : 'main'}`;
  const getTreeDataPath = (row: GridRowModel) => {
    return [row.lineageId ?? row.id, ...(row.contractId ? [row.contractId!] : [])];
  };

  const hasOwner = useMemo(
    () => rows?.some((row) => (row as ViewOnContractForVendorsPage).computed?.owners?.length) ?? true,
    [rows]
  );

  const isAnyVisible = useMemo(
    () => rows?.some((row) => (row as ViewOnContractForVendorsPage).visibility) ?? true,
    [rows]
  );

  const columns = useMemo((): DataGridColDef<ContractsTableItem>[] => {
    return [
      {
        field: 'department',
        headerName: t('VENDOR.CONTRACTS.LABELS.DEPARTMENT'),
        renderCell: (params: GridRenderCellParams) => <DepartmentCell contractData={params.row} />,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 0.6,
      },
      {
        field: 'owners',
        headerName: t('VENDOR.CONTRACTS.LABELS.OWNER'),
        renderCell: (params: GridRenderCellParams) => <OwnersCell contractData={params.row} />,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 0.6,
      },
      {
        field: 'stage',
        headerName: t('VENDOR.CONTRACTS.LABELS.STAGE'),
        renderCell: (params: GridRenderCellParams) => <StageIndicatorCell contractData={params.row} />,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 0.6,
        minWidth: 190,
      },
      {
        field: 'action',
        headerName: '',
        renderCell: (params: GridRenderCellParams) => <NavigationCell contractData={params.row} />,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        width: 100,
      },
    ];
  }, [t]);

  const groupingColumn = useMemo(
    () => ({
      field: 'products',
      headerName: t('VENDOR.CONTRACTS.LABELS.PRODUCTS'),
      renderCell: (params: GridRenderCellParams) => <ProductsCell {...params} />,
      colSpan: ({ row }: GridCellParams) => (row.contractId ? 1 : columns.length + 1),
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    }),
    [columns.length, t]
  );

  return (
    <StyledDataGrid
      treeData
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColumn}
      defaultGroupingExpansionDepth={-1}
      getRowClassName={(params: GridRowParams) => (params.row.ord ? 'group-row' : '')}
      getRowId={getRowId}
      rows={rows ?? []}
      loading={!rows}
      loadingStyle="skeleton"
      loadingRowCount={3}
      columns={columns}
      sortingMode="client"
      disableRowSelectionOnClick
      getRowHeight={(row) => (row.model.ord ? CATEGORY_ROW_HEIGHT : ROW_HEIGHT)}
      columnHeaderHeight={HEADER_HEIGHT}
      autoHeight={true}
      noBorder
      noBorderRadius
      disableVirtualization
      columnVisibilityModel={{
        owners: hasOwner,
        action: isAnyVisible,
      }}
    />
  );
};

export default VendorContractsTable;
