import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useGetAccountIntegrationQuery, useListJiraProjectsQuery } from '@vertice/slices';
import {
  Button,
  Card,
  CardHeader,
  CardHeaderActions,
  CardHeaderDescription,
  CardHeaderTitle,
  Text,
} from '@verticeone/design-system';
import { EDIT_MODE_DISABLED, JIRA_PROVIDER_ID } from '../constants';
import { useActivateIntegration } from '../hooks/useActivateIntegration';
import { EditCardProps, JiraSetupStep, StepContentProps } from '../types';
import { ProjectForm } from './ProjectForm';

type ProjectCardProps = StepContentProps & EditCardProps;

type JiraProjectType =
  | {
      id: string;
      name: string;
      key: string;
    }
  | undefined;

export const ProjectCard = ({ activeStep, setStep, cardEditMode, setCardEditMode }: ProjectCardProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const { data: jiraIntegrationData } = useGetAccountIntegrationQuery({
    accountId,
    integrationId: JIRA_PROVIDER_ID,
  });
  const {
    data: jiraProjectsData,
    refetch: refetchJiraProjects,
    isLoading: isLoadingJiraProjects,
  } = useListJiraProjectsQuery({ accountId });

  const { activateIntegration, isLoadingActivation, isLoadingConfiguration } = useActivateIntegration(
    setStep,
    setCardEditMode,
    jiraIntegrationData,
    jiraProjectsData
  );

  useEffect(() => {
    void refetchJiraProjects();
  }, [activeStep, refetchJiraProjects]);

  const isConfigurationStep = activeStep === JiraSetupStep.CONFIGURATION;
  const isSuccessStep = activeStep === JiraSetupStep.SUCCESS;

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle icon={DescriptionOutlinedIcon} text={t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_CARD.TITLE')} />
        <CardHeaderDescription>
          <Text variant="body-regular" size="S" color="text2">
            {t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_CARD.DESCRIPTION')}
          </Text>
        </CardHeaderDescription>
        {isSuccessStep && !cardEditMode.connection && (
          <CardHeaderActions>
            <Button
              variant="outline"
              size="S"
              color="primary"
              onClick={() => setCardEditMode({ ...EDIT_MODE_DISABLED, project: true })}
            >
              {t('PREFERENCES.INTEGRATIONS.JIRA.PROJECT_CARD.ACTION_BUTTON')}
            </Button>
          </CardHeaderActions>
        )}
      </CardHeader>
      <Stack gap={1} p={6}>
        <ProjectForm
          defaultValues={{
            jiraProject: (jiraIntegrationData?.parameters?.projects?.[0] as JiraProjectType)?.id ?? '',
          }}
          onSubmit={activateIntegration}
          activeStep={activeStep}
          projects={jiraProjectsData?.projects ?? []}
          isLoadingProjects={isLoadingJiraProjects}
          isLoading={isLoadingActivation || isLoadingConfiguration}
          cardEditMode={cardEditMode}
          setCardEditMode={setCardEditMode}
          isDisabled={(!isConfigurationStep && !cardEditMode.project) || cardEditMode.connection}
        />
      </Stack>
    </Card>
  );
};
