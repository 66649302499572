import { TFunction } from 'i18next';
import { DynamicFormField } from '../types';
import { fieldDefsByType } from './fields';
import { v1 as uuid } from 'uuid';
import { omitEmptyValues } from '@verticeone/utils/objects';
import { FieldType } from './types';

export const getFieldTypeText = (t: TFunction, type: FieldType) => {
  const fieldDef = fieldDefsByType[type];
  return fieldDef ? t(fieldDef.typeNameI18nKey) : t('DYNAMIC_FORM_BUILDER.FIELD.UNKNOWN');
};

export const createNewField = <F extends DynamicFormField>(type: FieldType, field: F): F =>
  omitEmptyValues({
    ...field,
    name: uuid(),
    type: type,
    metadata: omitEmptyValues({
      ...field.metadata,
      required: field.metadata?.required ?? false,
    }),
  });

export const createUpdatedField = <F extends DynamicFormField>(oldField: F, updatedField: F): F =>
  omitEmptyValues({
    ...updatedField,
    name: oldField.name, // We do not expect the field to change its name
    type: oldField.type, // We do not expect the field to change its type
    metadata: omitEmptyValues({
      ...updatedField.metadata,
      required: updatedField.metadata?.required ?? false,
    }),
  });
