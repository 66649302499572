import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { Wizard } from '@vertice/core/src/components/Wizard';
import SelectVendor from './Steps/SelectVendor/SelectVendor';
import BasicRequirements from './Steps/BasicRequirements/BasicRequirements';
import AdditionalRequirements from './Steps/AdditionalRequirements/AdditionalRequirements';
import PurchaseStarted from './Steps/PurchaseStarted/PurchaseStarted';
import { NewPurchaseFormData } from './types';
import { Box } from '@mui/material';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const defaultValues: NewPurchaseFormData = {
  type: 'new_purchase',
  files: [],
  vendor: null,
  products: [],
  departmentId: null,
  contractCurrency: DEFAULT_CURRENCY,
  approvedBudget: null,
  signingEntity: '',
  desiredContractLength: null,
  legalReviewRequired: false,
  securityReviewRequired: false,
  piiExpectedToBeHeld: false,
  contactVendorDirectly: true,
  discussMultiYearDeals: true,
  discussPlannedGrowth: true,
  discussCompetitors: true,
  discussCaseStudies: false,
  signatoryName: '',
  signatoryEmail: '',
};

const NewPurchaseWizard = () => {
  const { isFeatureEnabled } = useAccountContext();
  const formMethods = useForm<NewPurchaseFormData>({ defaultValues, mode: 'onChange' });
  const advancedCostModels = isFeatureEnabled(FEATURES.CM_ADVANCED_COST_MODELS);

  return (
    <FormProvider {...formMethods}>
      <Box component="form" onSubmit={() => formMethods.handleSubmit} p={10}>
        <Wizard initialStep="basic_requirements">
          <SelectVendor stepId="select_vendor" />
          <BasicRequirements stepId="basic_requirements" fullWidth={advancedCostModels} />
          <AdditionalRequirements stepId="additional_requirements" />
          <PurchaseStarted stepId="purchase_started" />
        </Wizard>
      </Box>
    </FormProvider>
  );
};

export default NewPurchaseWizard;
