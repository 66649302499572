import React, { ReactNode } from 'react';
import { Stack, StackProps, styled } from '@mui/material';
import { getTextVariantStyle } from '@verticeone/design-system';

export type StatsTileProps = {
  label?: ReactNode;
  flex?: number;
  children: ReactNode;
} & Omit<StackProps, 'flex' | 'children'>;

export const StyledFieldset = styled('fieldset')(({ theme }) => ({
  margin: '0',
  borderRadius: '8px',
  borderWidth: '1px',
  borderColor: 'transparent',
  paddingLeft: '12px',
  paddingRight: '12px',
  paddingBottom: '12px',
  paddingTop: '12px',
  height: '100%',

  '&.with-legend': {
    paddingTop: '3px',
    borderColor: theme.palette.core.color3,
  },

  legend: {
    marginLeft: '-3px',
    padding: '0 3px',
    backgroundColor: 'transparent',
    color: theme.palette.text.color3,
    ...getTextVariantStyle({ variant: 'caption', size: 'XXS' }),
  },
}));

const BaseStatsTile = ({ label, flex = 1, children, ...otherProps }: StatsTileProps) => (
  <Stack flex={flex} {...otherProps}>
    {label ? (
      <StyledFieldset className="with-legend">
        <legend>{label}</legend>
        {children}
      </StyledFieldset>
    ) : (
      <StyledFieldset>{children}</StyledFieldset>
    )}
  </Stack>
);

export const StatsTile = styled(BaseStatsTile)(({ theme }) => ({
  padding: '12px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.core.bg,
  position: 'relative',
  height: '100%',
}));
