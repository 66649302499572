import React from 'react';
import { Grid, Stack, styled, useTheme } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { StatsBody, Title, Value } from '@vertice/core/src/components/StatsBar';
import { Text } from '@verticeone/design-system';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { useTranslation } from 'react-i18next';
import { useCloudAnalytics } from '../../CloudAnalyticsContext';
import { IconWrapper } from '@verticeone/design-system';
import LoadingOrValue from '../LoadingOrValue';
import { useDateRangeFormatter } from '../../tabs/BaseCloudTab/utils';
import { StatsData } from './types';
import { testProps } from '@verticeone/design-system';

interface StatsProps {
  data?: StatsData;
  isLoading?: boolean;
}

const StatsContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.core.bg,
}));

const ComparisonDrawerStats = ({ data, isLoading = false }: StatsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.STATS' });
  const { palette } = useTheme();
  const formatCurrency = useFormatCurrency();
  const formatDateRange = useDateRangeFormatter();
  const {
    period: { periodOneStart, periodOneEnd },
  } = useCloudAnalytics();

  const getFormattedCurrency = (value?: number) => {
    if (value === undefined) {
      return t('NA');
    }

    return formatCurrency(value, {
      maximumFractionDigits: 2,
      currency: AWS_DEFAULT_CURRENCY,
    });
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing="1px"
      sx={{
        backgroundColor: palette.core.color3,
      }}
    >
      <Grid item md={6} xs={12}>
        <StatsContainer padding={6}>
          <StatsBody
            gap={4}
            isLoading={false}
            title={null}
            value={
              <Stack gap={2}>
                <Value>{t('COSTS')}</Value>
                <Stack direction="row" gap={2} alignItems="center">
                  <IconWrapper icon={CalendarTodayOutlined} size="S" htmlColor={palette.text.color2} />
                  <Text variant="body-bold" size="S" color="text2" testId="stats-bar-current-period">
                    {formatDateRange(periodOneStart, periodOneEnd)}
                  </Text>
                </Stack>
              </Stack>
            }
          />
        </StatsContainer>
      </Grid>
      <Grid item md={6} xs={12}>
        <StatsContainer padding={6} {...testProps('current-cost')}>
          <StatsBody
            gap={4}
            isLoading={isLoading}
            title={<Title tooltip={{ content: t('CURRENT_COST.TOOLTIP_INFO') }}>{t('CURRENT_COST.TITLE')}</Title>}
            value={
              <Stack gap={2}>
                <Stack direction="row" gap={2} alignItems="center">
                  <LoadingOrValue isLoading={isLoading} value={getFormattedCurrency(data?.currentCost)} />
                </Stack>
              </Stack>
            }
          />
        </StatsContainer>
      </Grid>
    </Grid>
  );
};

export default ComparisonDrawerStats;
