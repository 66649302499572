import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const exchangeRatesAPI = createApi({
  reducerPath: 'exchangeRatesAPI',
  baseQuery: retry(prepareBaseQuery(API_URLS.BASE_URLS.EXCHANGE_RATES), { maxRetries: 0 }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({}),
});
