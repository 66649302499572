import * as z from 'zod';
import { nonEmptyString } from '@verticeone/utils/validation';
import { genericFormFieldSchema } from '../GenericForm/types';

const conditionalAssigneesSchema = z.object({
  assignees: z.array(z.string()),
  condition: z.string(),
});

export const editUserTaskFormSchema = z.object({
  id: z.string().optional(),
  name: nonEmptyString,
  defaultAssignees: z.array(z.string()),
  conditionalAssignees: z.array(conditionalAssigneesSchema),
  description: z.string().optional(),
  buttonLabels: z
    .object({
      approve: z.string(),
      reject: z.string(),
    })
    .nullable()
    .optional(),
  formHeading: z.string().nullable().optional(),
  genericFormFields: z.array(genericFormFieldSchema).nullable().optional(),
});

export type EditUserTaskFormData = z.infer<typeof editUserTaskFormSchema>;
