import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

import GetOfferingsHandlerProvider, {
  GetOfferingsHandler,
  OfferingsParserHandler,
} from './GetOfferingsHandlerProvider';
import { ComputeDataColumn, GenericDataColumn } from '../../dataSources/useMonitoringLatestQuery';

export type InstanceData = Record<ComputeDataColumn, number> | Record<GenericDataColumn, number>;

type InstanceItemProviderProps<Item extends InstanceData> = {
  item: Item;
  omittedColumns?: Array<string>;
} & PropsWithChildren;

type InstanceProviderProps<Item extends InstanceData> = {
  item: Item;
  getOfferings: GetOfferingsHandler;
  offeringsParser: OfferingsParserHandler<Item>;
  omittedColumns?: Array<string>;
} & PropsWithChildren;

type InstanceItemContextData = {
  item: InstanceData;
  managedByVertice: {
    isActive: boolean;
    toggle: () => void;
  };
  omittedColumns?: Array<string>;
};

const InstanceItemContext = createContext<InstanceItemContextData>({} as InstanceItemContextData);

const InstanceItemProvider = <Item extends InstanceData>({
  item,
  omittedColumns,
  children,
}: InstanceItemProviderProps<Item>) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <InstanceItemContext.Provider
      value={{
        item,
        managedByVertice: {
          isActive,
          toggle: () => setIsActive((prev) => !prev),
        },
        omittedColumns: omittedColumns,
      }}
    >
      {children}
    </InstanceItemContext.Provider>
  );
};

const InstanceProvider = <Item extends InstanceData>({
  item,
  getOfferings,
  offeringsParser,
  omittedColumns,
  children,
}: InstanceProviderProps<Item>) => {
  return (
    <GetOfferingsHandlerProvider
      getOfferings={getOfferings as GetOfferingsHandler}
      offeringsParser={offeringsParser as OfferingsParserHandler<InstanceData>}
    >
      <InstanceItemProvider item={item} omittedColumns={omittedColumns}>
        {children}
      </InstanceItemProvider>
    </GetOfferingsHandlerProvider>
  );
};

export const useInstanceData = () => useContext(InstanceItemContext);

export default InstanceProvider;
