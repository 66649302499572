import { userApi as api } from '../../api/userAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    adminGetUser: build.query<AdminGetUserApiResponse, AdminGetUserApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}` }),
    }),
    adminUpdateUser: build.mutation<AdminUpdateUserApiResponse, AdminUpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    adminGetUserSettings: build.query<AdminGetUserSettingsApiResponse, AdminGetUserSettingsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}/settings` }),
    }),
    adminUpdateUserSettings: build.mutation<AdminUpdateUserSettingsApiResponse, AdminUpdateUserSettingsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}/settings`,
        method: 'PUT',
        body: queryArg.userSettings,
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}` }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}`, method: 'POST', body: queryArg.body }),
    }),
    getUserSettings: build.query<GetUserSettingsApiResponse, GetUserSettingsApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/settings` }),
    }),
    updateUserSettings: build.mutation<UpdateUserSettingsApiResponse, UpdateUserSettingsApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/settings`, method: 'PUT', body: queryArg.userSettings }),
    }),
    getUserPublicInformation: build.query<GetUserPublicInformationApiResponse, GetUserPublicInformationApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/public` }),
    }),
    getUsersPublicInformation: build.mutation<GetUsersPublicInformationApiResponse, GetUsersPublicInformationApiArg>({
      query: (queryArg) => ({ url: `/batch/users/public`, method: 'POST', body: queryArg.body }),
    }),
    invitationStatus: build.query<InvitationStatusApiResponse, InvitationStatusApiArg>({
      query: (queryArg) => ({ url: `/invitations/${queryArg.token}/status` }),
    }),
    signinVerify: build.mutation<SigninVerifyApiResponse, SigninVerifyApiArg>({
      query: (queryArg) => ({ url: `/signin/verify`, method: 'POST', body: queryArg.body }),
    }),
    grantRoleToDomainUser: build.mutation<GrantRoleToDomainUserApiResponse, GrantRoleToDomainUserApiArg>({
      query: (queryArg) => ({
        url: `/domains/${queryArg.domain}/users/${queryArg.userId}/roles/${queryArg.roleId}`,
        method: 'POST',
      }),
    }),
    revokeRoleFromDomainUser: build.mutation<RevokeRoleFromDomainUserApiResponse, RevokeRoleFromDomainUserApiArg>({
      query: (queryArg) => ({
        url: `/domains/${queryArg.domain}/users/${queryArg.userId}/roles/${queryArg.roleId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as userAPICodegen };
export type AdminGetUserApiResponse = /** status 200 Provides information about user.
 */ User;
export type AdminGetUserApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
};
export type AdminUpdateUserApiResponse = /** status 200 User reference
 */ {
  userId?: Identifier;
};
export type AdminUpdateUserApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
  body: {
    email?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    phoneNumber?: PhoneNumber;
    retentionDate?: Timestamp;
    /** Optional arbitrary data */
    userProperties?: {
      calendlyUrl?: string;
    };
  };
};
export type AdminGetUserSettingsApiResponse = /** status 200 User preferences and settings */ UserSettings;
export type AdminGetUserSettingsApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
};
export type AdminUpdateUserSettingsApiResponse = /** status 201 Empty */ void;
export type AdminUpdateUserSettingsApiArg = {
  accountId: UuidIdentifier;
  userId: Identifier;
  userSettings: UserSettings;
};
export type GetUserApiResponse = /** status 200 Provides information about user.
 */ User;
export type GetUserApiArg = {
  userId: Identifier;
};
export type UpdateUserApiResponse = /** status 200 User reference
 */ {
  userId?: Identifier;
};
export type UpdateUserApiArg = {
  userId: Identifier;
  body: {
    email?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    phoneNumber?: PhoneNumber;
    retentionDate?: Timestamp;
    /** Optional arbitrary data */
    userProperties?: {
      calendlyUrl?: string;
    };
  };
};
export type GetUserSettingsApiResponse = /** status 200 User preferences and settings */ UserSettings;
export type GetUserSettingsApiArg = {
  userId: Identifier;
};
export type UpdateUserSettingsApiResponse = /** status 201 User preferences and settings */ UserSettings;
export type UpdateUserSettingsApiArg = {
  userId: Identifier;
  userSettings: UserSettings;
};
export type GetUserPublicInformationApiResponse = /** status 200 Provides publicly accessible information about user.
 */ UserPublicInformation;
export type GetUserPublicInformationApiArg = {
  userId: Identifier;
};
export type GetUsersPublicInformationApiResponse = /** status 200 Provides publicly accessible information about users.
 */ UsersPublicInformation;
export type GetUsersPublicInformationApiArg = {
  body: string[];
};
export type InvitationStatusApiResponse = /** status 200 Invitation status
 */ {
  status?: InvitationStatus;
};
export type InvitationStatusApiArg = {
  token: Token;
};
export type SigninVerifyApiResponse = /** status 200 User identity
 */ {
  status: 'NOT_FOUND' | 'MUST_ACCEPT_INVITATION' | 'PASSWORD_NOT_MANAGED' | 'PASSWORD_MANAGED';
};
export type SigninVerifyApiArg = {
  body: {
    email: string;
  };
};
export type GrantRoleToDomainUserApiResponse = /** status 200 Role granted */ object;
export type GrantRoleToDomainUserApiArg = {
  domain: Domain;
  userId: Identifier;
  roleId: RoleName;
};
export type RevokeRoleFromDomainUserApiResponse = /** status 200 Role revoked */ object;
export type RevokeRoleFromDomainUserApiArg = {
  domain: Domain;
  userId: Identifier;
  roleId: RoleName;
};
export type Identifier = string;
export type PhoneNumber = string;
export type User = {
  userId: Identifier;
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: PhoneNumber;
  userProperties?: {
    calendlyUrl?: string;
  };
};
export type UuidIdentifier = string;
export type Timestamp = string | null;
export type TimeZone = string;
export type NotificationsScope =
  | 'NOTHING'
  | 'OWNED_AND_WATCHED_CONTRACTS'
  | 'OWNED_CONTRACTS_ONLY'
  | 'WATCHED_CONTRACTS_ONLY'
  | 'ALL_CONTRACTS';
export type UserNotificationWorkflowSettings = {
  role: 'owner' | 'watcher';
  contractRenewal: {
    enabled?: boolean;
    timeThreshold?: string;
  };
  pipelineUpdates: {
    enabled?: boolean;
  };
  proposalsAwaitingApproval: {
    enabled?: boolean;
  };
  newContractsAdded: {
    enabled?: boolean;
  };
};
export type UserSettings = {
  preferredCurrency?: string;
  timeZone?: TimeZone;
  /** Notification preferences for the notifications (CONTRACTS_V2)
   */
  notificationsSettings?: {
    contractRenewal: {
      scope: NotificationsScope;
      timeThreshold: string;
    };
    pipelineUpdates: {
      scope: NotificationsScope;
    };
    proposalsAwaitingApproval: {
      scope: NotificationsScope;
    };
    newContractsAdded: {
      scope: NotificationsScope;
    };
  };
  /** Notification preferences per role when the custom workflow is enabled
   */
  workflowNotificationSettings?: {
    subscriptions?: UserNotificationWorkflowSettings[];
  };
  /** Notification preferences when the custom workflow is disabled
   */
  notifications?: {
    role?: string;
    contractRenewal?: {
      enabled?: boolean;
      timeThreshold?: string;
    };
    pipelineUpdates?: {
      enabled?: boolean;
    };
    proposalsAwaitingApproval?: {
      enabled?: boolean;
    };
    newContractsAdded?: {
      enabled?: boolean;
    };
  };
};
export type UserPublicInformation = {
  userId?: Identifier;
  email?: string | null;
  phoneNumber?: PhoneNumber;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  userProperties?: {
    calendlyUrl?: string;
  };
};
export type UsersPublicInformation = UserPublicInformation[];
export type InvitationStatus = 'ACCEPTED' | 'EXPIRED' | 'VALID';
export type Token = string;
export type Domain = string;
export type RoleName =
  | 'role:accountmanager:power'
  | 'role:admin:superuser'
  | 'role:buyer:power'
  | 'role:dataops'
  | 'role:support:iw';
export const {
  useAdminGetUserQuery,
  useLazyAdminGetUserQuery,
  useAdminUpdateUserMutation,
  useAdminGetUserSettingsQuery,
  useLazyAdminGetUserSettingsQuery,
  useAdminUpdateUserSettingsMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useGetUserSettingsQuery,
  useLazyGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
  useGetUserPublicInformationQuery,
  useLazyGetUserPublicInformationQuery,
  useGetUsersPublicInformationMutation,
  useInvitationStatusQuery,
  useLazyInvitationStatusQuery,
  useSigninVerifyMutation,
  useGrantRoleToDomainUserMutation,
  useRevokeRoleFromDomainUserMutation,
} = injectedRtkApi;
