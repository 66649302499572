import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { VerticeEntraIntegration } from '@vertice/assets';
import { Button } from '@verticeone/design-system';
import { STEPS } from './common';
import styles from './Step.module.scss';
import { WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';
import WizardStepTemplate from 'pages/Preferences/Integrations/components/WizardStepTemplate';

export const StartStep = ({ setStep }: WizardStepProps) => {
  const { t } = useTranslation();

  const header = (
    <Stack gap="8px">
      <Stack direction="row" gap="6px">
        <Text variant="heading" size="M">
          {t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.SETUP_ENTRA')}
        </Text>
        <Text variant="heading" size="M" color="text1" sx={{ opacity: '0.4' }}>
          {t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.FOR_USAGE_STATS')}
        </Text>
      </Stack>
      <Text variant="body-regular" size="M" color="text2">
        {t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.INTRO')}
      </Text>
    </Stack>
  );

  const content = (
    <Stack gap="8px">
      <Text variant="caption" size="XS" color="text2">
        {t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.PREREQUISITES')}
      </Text>
      <ul className={styles['bullet-list']}>
        <li>
          <Text variant="body-regular" size="M">
            {t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.PREREQUISITE_1')}
          </Text>
        </li>
        <li>
          <Text variant="body-regular" size="M">
            {t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.PREREQUISITE_2')}
          </Text>
        </li>
        <li>
          <Text variant="body-regular" size="M">
            {t('PREFERENCES.INTEGRATIONS.ENTRA.USAGE.PREREQUISITE_3')}
          </Text>
        </li>
      </ul>
    </Stack>
  );

  return (
    <WizardStepTemplate
      headerImage={<VerticeEntraIntegration />}
      header={header}
      content={content}
      buttons={[
        <Button variant="solid" color="primary" size="S" isCaption onClick={() => setStep(STEPS.DATA_SOURCES)}>
          {t('COMMON.NEXT')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
