import React, { useCallback, useMemo, useState } from 'react';
import { Stack, styled, useTheme } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import { CancelOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Highcharts, { Chart as HighchartsChart, type OptionsStackingValue, type SeriesOptionsType } from 'highcharts';
import { Text } from '@verticeone/design-system';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useFormatPercentage } from '@verticeone/utils/formatting';
import { SeriesOptionsWithStates } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { LABEL_VISIBILITY_THRESHOLD } from './constants';

export type GraphSeries = Array<
  {
    isVisible?: boolean;
    states: {
      hover: {
        enabled?: boolean;
        color: string;
      };
    };
    data: Array<number>;
  } & Highcharts.SeriesOptionsType
>;

type GraphProps = {
  series: GraphSeries;
  hasData: boolean;
};

const StyledCancelOutlined = styled(CancelOutlined)(({ theme }) => ({
  color: theme.palette.tertiary.color2,
}));

const Graph = ({ series, hasData }: GraphProps) => {
  const { palette } = useTheme();
  const applyStyledHighcharts = useStyledHighcharts();
  const applyXAxisOffset = useXAxisOffset();
  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);
  const { t } = useTranslation(undefined, { keyPrefix: `CLOUD.RIO_NEW.OVERVIEW` });
  const formatPercentage = useFormatPercentage();

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: {
            type: 'bar',
            height: 54,
            spacing: [0, 0, 0, 0],
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: 'transparent',
            plotBackgroundColor: 'transparent',
          },
          plotOptions: {
            bar: {
              borderRadius: 8,
            },
            series: {
              stacking: 'normal' as OptionsStackingValue,
              dataLabels: {
                enabled: true,
                inside: true,
                format: '{y}%',
              },
              borderWidth: 2,
              borderColor: palette.core.bg,
            },
          },
          xAxis: {
            visible: false,
          },
          yAxis: {
            visible: false,
            tickInterval: 100,
          },
          series: series.filter(({ isVisible }) => isVisible).toReversed() as Array<SeriesOptionsType>,
          tooltip: {
            outside: true,
            shared: true,
            enabled: true,
            animation: false,
            positioner: function (w, h, point) {
              return {
                x: (point.plotX - w) / 2,
                y: -h,
              };
            },
          },
        }),
      ]),
    [applyStyledHighcharts, applyXAxisOffset, palette, series]
  );

  if (!hasData) {
    return (
      <Stack
        py={1}
        my={2}
        gap={1}
        borderRadius={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
        bgcolor="core.bg"
      >
        <StyledCancelOutlined />
        <Text tag="h3" variant="body-bold" size="M" textAlign="center" color="text1">
          {t('DATA_UNAVAILABLE')}
        </Text>
      </Stack>
    );
  }

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {({ points }) => {
          const visiblePoints = points?.filter((point) => {
            const value = point.series.userOptions.custom?.originalValue ?? 0;
            return value <= LABEL_VISIBILITY_THRESHOLD;
          });

          if (!!visiblePoints?.length) {
            return (
              <TooltipWrapper>
                {points?.map((point) => (
                  <TooltipSeriesValuePair
                    seriesName={point.series.name}
                    key={point.series.name}
                    seriesColor={(point.series.options as SeriesOptionsWithStates)?.states?.hover?.color}
                    value={formatPercentage(point.series.userOptions.custom?.originalValue ?? 0, {
                      maximumFractionDigits: 2,
                    })}
                  />
                ))}
              </TooltipWrapper>
            );
          }
        }}
      </HighchartTooltip>
    </>
  );
};

export default Graph;
