import { RecommendationEffort } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';

export const codeComparator = (v1: string, v2: string) =>
  parseInt(v1.split('-')[1] ?? 0) - parseInt(v2.split('-')[1] ?? 0);

export const alphabetComparator = (v1: string, v2: string) => v1.localeCompare(v2);
export const arrayComparator = (v1: Array<string>, v2: Array<string>) => v1[0].localeCompare(v2[0]);
export const numberComparator = (v1: number, v2: number) => v1 - v2;
export const effortComparator = (v1: RecommendationEffort, v2: RecommendationEffort) => {
  const PRIORITY: Record<RecommendationEffort, number> = {
    [RecommendationEffort.Low]: 1,
    [RecommendationEffort.Medium]: 2,
    [RecommendationEffort.High]: 3,
  };

  return PRIORITY[v1] - PRIORITY[v2];
};
