import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, CardActions, CardContent, CardHeader, Stack, useTheme } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

import { Button } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { useGetExistingScimTokenStatusQuery } from '@vertice/slices/src/openapi/codegen/scimAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { IntegrationSSOIcon } from '@vertice/assets/icons';
import { SCIMSetupType } from './common';

type SCIMSetupCardProps = {
  type: SCIMSetupType;
};

export const SCIMSetupCard = ({ type }: SCIMSetupCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { accountId } = useAccountContext();

  const { data: existingSCIMTokenStatus } = useGetExistingScimTokenStatusQuery({ accountId });

  return (
    <Card variant="outlined" sx={{ width: 400, borderRadius: '8px' }}>
      <Stack direction="column" sx={{ height: 1 }}>
        <CardHeader avatar={<IntegrationSSOIcon />}></CardHeader>
        <CardContent sx={{ flex: 1 }}>
          <Text variant="heading" size="S">
            {t(`PREFERENCES.INTEGRATIONS.SCIM.${type}.TITLE`)}
            <Brightness1Icon
              sx={{
                height: 15,
                fill: existingSCIMTokenStatus?.status === 'ACTIVE' ? palette.success.color2 : palette.grey[300],
              }}
            />
          </Text>
        </CardContent>
        <CardActions sx={{ padding: 4, borderTop: `1px solid ${palette.grey[300]}` }}>
          <Button
            size="S"
            sx={{ height: 30 /*to match it with the non-design system buttons*/ }}
            onClick={() => navigate(type === SCIMSetupType.ENTRA ? ROUTES.SCIM_ENTRA : ROUTES.SCIM_OKTA)}
          >
            {t('PREFERENCES.INTEGRATIONS.SCIM.SETUP_BUTTON')} <ArrowForwardIos sx={{ opacity: 0.6 }} />
          </Button>
        </CardActions>
      </Stack>
    </Card>
  );
};
