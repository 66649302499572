import { nonEmptyString } from '@verticeone/utils/validation';
import * as z from 'zod';
import { variableMapperRowFormSchema } from '../JiraCommon/VariableMapper/formSchema';

export const jiraCreateTaskFormSchema = z.object({
  id: z.string().optional(),
  name: nonEmptyString,
  description: z.string().optional(),
  issueType: z.string(),
  variables: z.array(variableMapperRowFormSchema),
  // TODO: Add attachments feature once it's fully supported on WF side
  // includeAttachments: z.boolean(),
});

export type JiraCreateTaskFormData = z.infer<typeof jiraCreateTaskFormSchema>;
