import { FC, useId } from 'react';
import { Stack } from '@mui/material';
import { TextFieldCaption } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';

import type { NodeId } from '../../../types';
import type { ProcessDefinition } from '../../../../../definitionsTypes';
import { EditorDrawerGroup } from '../../../EditorDrawer';
import { ConditionalAssignment } from './ConditionalAssignment';
import { useVariablesAvailableInNode } from '../../../useVariablesAvailableInNode';
import { TaskAssigneeSelect } from './TaskAssigneeSelect';

type TaskAssigneeProps = {
  nodeId: NodeId;
  processDefinition: ProcessDefinition;
  workflowServiceRef?: string;
  allowContractOwnerAssignment?: boolean;
  onAddUser: () => void;
  isConditionalAssignmentEnabled?: boolean;
};

export const TaskAssignee: FC<TaskAssigneeProps> = ({
  nodeId,
  processDefinition,
  workflowServiceRef,
  allowContractOwnerAssignment,
  onAddUser,
  isConditionalAssignmentEnabled = false,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK' });
  const id = useId();

  const { requestProperties, globalUDFProperties } = useVariablesAvailableInNode({
    nodeId,
    processDefinition,
    workflowServiceRef,
    includeNodeVariables: false,
  });

  const isConditionalAssignmentDisplayed =
    isConditionalAssignmentEnabled && !!requestProperties.length && !!globalUDFProperties.length;

  return (
    <EditorDrawerGroup title={t('TITLES.TASK_ASSIGNEE')}>
      <Stack gap={1}>
        <TextFieldCaption
          tooltip={{
            content: t('CONDITIONAL_ASSIGNEES.HELP.DEFAULT'),
          }}
          id={`${id}-defaultAssignees-caption`}
          label={t('ASSIGNEES')}
          size="XS"
          htmlFor={`${id}-defaultAssignees`}
        />
        <TaskAssigneeSelect
          id={`${id}-defaultAssignees`}
          name="defaultAssignees"
          onAddUser={onAddUser}
          allowContractOwnerAssignment={allowContractOwnerAssignment}
        />
      </Stack>
      {isConditionalAssignmentDisplayed && (
        <ConditionalAssignment
          onAddUser={onAddUser}
          allowContractOwnerAssignment={allowContractOwnerAssignment}
          globalUDFProperties={globalUDFProperties}
          requestProperties={requestProperties}
        />
      )}
    </EditorDrawerGroup>
  );
};
