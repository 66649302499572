import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { EventNoteTwoTone, EventRepeatTwoTone } from '@mui/icons-material';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import { FormToggleButtonField } from '@vertice/core/src/modules/forms/fields/FormToggleButtonField';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons } from '@vertice/core/src/components/Dialog/Components';
import { AddExistingContractFormData } from '../../types';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import { RollingContractFields } from './RollingContractFields';
import { FixedTermContractFields } from './FixedTermContractFields';

const useContractTypeWizard = () => {
  const wizard = useWizard();

  return {
    goBack: wizard.goBack,
    goToNext: () => wizard.goToStep('upload_existing_contract'),
  };
};

const ContractType: React.FC<WizardStepProps> = () => {
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const { t } = useTranslation();

  const { goBack, goToNext } = useContractTypeWizard();

  const { trigger, watch, setValue } = useFormContext<AddExistingContractFormData>();
  const { contractType, rollingFrequency } = watch();
  const otherMandatoryFields = watch(['startDate', 'contractLength', 'renewalDate']);

  useEffect(() => {
    if (contractType === 'fixed') {
      void trigger(['startDate', 'contractLength', 'renewalDate']).then(setNextButtonEnabled);
    }
    if (contractType === 'rolling') {
      void trigger(['startDate']).then(setNextButtonEnabled);
    }
  }, [contractType, trigger, otherMandatoryFields]);

  // set default rolling frequency
  useEffect(() => {
    if (!rollingFrequency) {
      setValue('rollingFrequency', 'MONTHLY');
    }
  }, [contractType, rollingFrequency, setValue]);

  // clear props that are not applicable to the selected contract type
  const proceed = useCallback(() => {
    if (contractType === 'fixed') {
      setValue('rollingFrequency', 'NO');
    }
    if (contractType === 'rolling') {
      setValue('contractLength', null);
      setValue('renewalDate', undefined);
      setValue('autoRenewalDate', undefined);
    }
    goToNext();
  }, [contractType, setValue, goToNext]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.EXISTING_CONTRACT_CONTRACT_TYPE.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_CONTRACT_TYPE.HEADING_SUBTITLE')}
      />
      <SelectedVendorPanel />
      <InnerScrollable>
        <FormSection>
          <Grid item xs={12}>
            <FormToggleButtonField
              name="contractType"
              buttonHeight={140}
              options={[
                {
                  title: t('ENTITIES.CONTRACT.LABELS.FIXED'),
                  label: t('ENTITIES.CONTRACT.LABELS.FIXED_DESCRIPTION'),
                  value: 'fixed',
                  icon: EventNoteTwoTone,
                },
                {
                  title: t('ENTITIES.CONTRACT.LABELS.ROLLING'),
                  label: t('ENTITIES.CONTRACT.LABELS.ROLLING_DESCRIPTION'),
                  value: 'rolling',
                  icon: EventRepeatTwoTone,
                },
              ]}
            />
          </Grid>
          {contractType === 'rolling' && <RollingContractFields />}
          {contractType === 'fixed' && <FixedTermContractFields />}
        </FormSection>
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: proceed,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !nextButtonEnabled,
        }}
      />
    </Content>
  );
};

export default ContractType;
