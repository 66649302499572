import { EllipsisText, Text } from '@verticeone/design-system';
import { getFieldTypeText } from '../../../fields/utils';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DynamicFormField } from '../../../types';

type FieldCardInfoProps<F> = {
  field: F & { id: string };
};

export const FieldCardInfo = <F extends DynamicFormField>({ field }: FieldCardInfoProps<F>) => {
  const { t } = useTranslation();
  const label = field.metadata.label;

  return (
    <Stack gap={1} minWidth={0}>
      <Text variant="label" size="XXS" color="text3">
        {getFieldTypeText(t, field.type)}
      </Text>
      {label && (
        <Stack direction="row" gap={0.5}>
          <EllipsisText variant="body-bold" size="S" color="text2">
            {label}
          </EllipsisText>
          {field.metadata.required && (
            <Text variant="body-bold" size="S" color="error2">
              {'*'}
            </Text>
          )}
        </Stack>
      )}
    </Stack>
  );
};
