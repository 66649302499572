import { useController } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import type { CommonFormFieldProps } from '../../../../../forms/types';
import { WorkflowVariableSelector } from '../EditGatewayForm/WorkflowVariableSelector';
import { Property } from '../../types';

export type VariableSelectorFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> &
  React.ComponentProps<typeof WorkflowVariableSelector>;

export const VariableSelectorField = <FormDataType extends FieldValues = never>({
  required,
  name,
  ...otherProps
}: VariableSelectorFieldProps<FormDataType>) => {
  const {
    field: { onChange, value, ref, ...field },
  } = useController({ name, rules: { required } });

  return (
    <WorkflowVariableSelector
      {...field}
      {...otherProps}
      selectedVariable={value}
      onSelectedVariableChange={(property: Property) => {
        onChange(property);
      }}
    />
  );
};
