import React from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { DataGrid } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { toConstantCase } from '@verticeone/utils/strings';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import type { InstancesDetailData } from './useGetInstancesDetailData';
import { useCloudInventory } from '../useCloudInventory';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { Card } from '@verticeone/design-system';
import { AWS_DEFAULT_CURRENCY } from '../../../constants';

type ReservationsTableProps = {
  rows: InstancesDetailData['tableData'];
};

const ROW_HEIGHT = 48;

const useColumns = (): GridColDef<InstancesDetailData['tableData'][0]>[] => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.INVENTORY.DETAIL' });
  const formatCurrency = useFormatCurrency();
  const { locale } = useLocaleContext();
  const { activeTab } = useCloudInventory();

  if (activeTab.id === 'rds') {
    return [
      {
        field: 'id',
        headerName: t('INSTANCES_TABLE.COLUMN.ID'),
        disableColumnMenu: true,
        editable: false,
        flex: 2,
      },
      {
        field: 'size',
        headerName: t('INSTANCES_TABLE.COLUMN.SIZE'),
        disableColumnMenu: true,
        editable: false,
        flex: 2,
        valueFormatter: ({ value }) => t(`CHARTS.SIZE.LEGEND.${value.toUpperCase()}`),
      },
      {
        field: 'quantity',
        headerName: t('INSTANCES_TABLE.COLUMN.QUANTITY'),
        disableColumnMenu: true,
        editable: false,
        flex: 2,
        headerAlign: 'right',
        align: 'right',
        valueFormatter: ({ value }) => value,
      },
      {
        field: 'term',
        headerName: t('INSTANCES_TABLE.COLUMN.TERM'),
        disableColumnMenu: true,
        editable: false,
        flex: 2,
        valueFormatter: ({ value }) => t('CHARTS.TERM.LEGEND.YEAR', { count: value }),
      },
      {
        field: 'paymentOptions',
        headerName: t('INSTANCES_TABLE.COLUMN.PAYMENT_OPTIONS'),
        disableColumnMenu: true,
        editable: false,
        flex: 2,
        valueFormatter: ({ value }) => t(`CHARTS.PAYMENT.LEGEND.${toConstantCase(value)}`),
      },
      {
        field: 'riCost',
        headerName: t('INSTANCES_TABLE.COLUMN.RI_COST'),
        disableColumnMenu: true,
        editable: false,
        flex: 2,
        headerAlign: 'right',
        align: 'right',
        valueFormatter: ({ value }) => formatCurrency(value, { currency: AWS_DEFAULT_CURRENCY }),
      },
      {
        field: 'retentionDate',
        headerName: t('INSTANCES_TABLE.COLUMN.EXPIRY_DATE'),
        disableColumnMenu: true,
        editable: false,
        flex: 2,
        valueFormatter: ({ value }) => {
          return new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(
            value * 1000
          );
        },
      },
    ];
  } else {
    return [
      {
        field: 'id',
        headerName: t('INSTANCES_TABLE.COLUMN.ID'),
        disableColumnMenu: true,
        editable: false,
        width: 40,
      },
      {
        field: 'size',
        headerName: t('INSTANCES_TABLE.COLUMN.SIZE'),
        disableColumnMenu: true,
        editable: false,
        width: 100,
        valueFormatter: ({ value }) => t(`CHARTS.SIZE.LEGEND.${value.toUpperCase()}`),
      },
      {
        field: 'tenancy',
        headerName: t('INSTANCES_TABLE.COLUMN.TENANCY'),
        disableColumnMenu: true,
        editable: false,
        width: 100,
      },
      {
        field: 'operatingSystem',
        headerName: t('INSTANCES_TABLE.COLUMN.OPERATING_SYSTEM'),
        disableColumnMenu: true,
        editable: false,
        flex: 1,
        minWidth: 160,
      },
      {
        field: 'quantity',
        headerName: t('INSTANCES_TABLE.COLUMN.QUANTITY'),
        disableColumnMenu: true,
        editable: false,
        width: 100,
        headerAlign: 'right',
        align: 'right',
        valueFormatter: ({ value }) => value,
      },
      {
        field: 'term',
        headerName: t('INSTANCES_TABLE.COLUMN.TERM'),
        disableColumnMenu: true,
        editable: false,
        width: 90,
        valueFormatter: ({ value }) => t('CHARTS.TERM.LEGEND.YEAR', { count: value }),
      },
      {
        field: 'paymentOptions',
        headerName: t('INSTANCES_TABLE.COLUMN.PAYMENT_OPTIONS'),
        disableColumnMenu: true,
        editable: false,
        width: 150,
        valueFormatter: ({ value }) => t(`CHARTS.PAYMENT.LEGEND.${toConstantCase(value)}`),
      },
      {
        field: 'offeringClass',
        headerName: t('INSTANCES_TABLE.COLUMN.OFFERING_CLASS'),
        disableColumnMenu: true,
        editable: false,
        width: 140,
        valueFormatter: ({ value }) => t(`CHARTS.CLASS.LEGEND.${value.toUpperCase()}`),
      },
      {
        field: 'riCost',
        headerName: t('INSTANCES_TABLE.COLUMN.RI_COST'),
        disableColumnMenu: true,
        editable: false,
        flex: 1,
        headerAlign: 'right',
        align: 'right',
        valueFormatter: ({ value }) => formatCurrency(value, { currency: AWS_DEFAULT_CURRENCY }),
      },
      {
        field: 'retentionDate',
        headerName: t('INSTANCES_TABLE.COLUMN.EXPIRY_DATE'),
        disableColumnMenu: true,
        editable: false,
        width: 140,
        valueFormatter: ({ value }) => {
          return new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(
            value * 1000
          );
        },
      },
    ];
  }
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '.expired-row': {
    backgroundColor: theme.palette.input.color1,
  },
})) as typeof DataGrid;

const ReservationsTable = ({ rows }: ReservationsTableProps) => {
  const columns = useColumns();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.INVENTORY.DETAIL.INSTANCES_TABLE' });
  const { showExpired } = useCloudInventory();

  return (
    <Card>
      <Stack maxHeight={ROW_HEIGHT * 16 + 72}>
        <Stack padding={6}>
          <Text tag="h2" variant="heading" size="S" color="text1">
            {t('TITLE')}
          </Text>
        </Stack>
        <StyledDataGrid<InstancesDetailData['tableData'][0]>
          color="tertiary"
          noBorder
          noBorderRadius
          columns={columns}
          size="M"
          headerSize="XS"
          rows={rows}
          autoHeight={!rows?.length}
          rowHeight={ROW_HEIGHT}
          getRowClassName={(params) => (params.row.isExpired ? 'expired-row' : '')}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'retentionDate',
                  sort: showExpired ? 'desc' : 'asc',
                },
              ],
            },
          }}
        />{' '}
      </Stack>
    </Card>
  );
};

export default ReservationsTable;
