import { FieldDef, FieldType } from '../types';
import { getDateJsonSchema, getDatePresenterDefault, getDatePresenterSchema } from './utils';
import { DateFieldType } from './types';
import { DateFieldBuilderCard } from './DateFieldBuilderCard';
import { DateFieldPresenter } from './DateFieldPresenter';
import { DateFieldBuilderForm } from './DateFieldBuilderForm';

export const fieldDef: FieldDef<DateFieldType> = {
  type: FieldType.DATE,
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.DATE',
  builderCard: DateFieldBuilderCard,
  builderForm: DateFieldBuilderForm,
  presenter: DateFieldPresenter,
  getPresenterDefault: getDatePresenterDefault,
  getPresenterSchema: getDatePresenterSchema,
  getJsonSchema: getDateJsonSchema,
};
