import React from 'react';
import { useController, type Validate } from 'react-hook-form';
import { TextFieldArea } from '@verticeone/design-system';
import type { CommonFormFieldProps } from '../../types';
import { FieldValues } from 'react-hook-form/dist/types/fields';

type FormTextFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> &
  React.ComponentProps<typeof TextFieldArea> & {
    validate?: Validate<string, FormDataType> | Record<string, Validate<string, FormDataType>>;
  };

const FormTextArea = <FormDataType extends FieldValues = never>({
  id,
  validate,
  required,
  name,
  variant,
  color,
  placeholder,
  ...otherProps
}: FormTextFieldProps<FormDataType>) => {
  const {
    field: { ref, value, ...field },
    fieldState: { invalid },
  } = useController<FormDataType>({
    name,
    rules: {
      required,
      validate,
    },
  });

  return (
    <TextFieldArea
      ref={ref}
      id={id}
      value={value === null ? '' : (value as string)}
      hasError={invalid}
      color={color ?? 'primary'}
      variant={variant ?? 'outline'}
      placeholder={placeholder ?? ''}
      {...field}
      {...otherProps}
    />
  );
};

export default FormTextArea;
