import { FC } from 'react';
import { type FullOperator, OperatorSelectorProps, toFlatOptionArray } from 'react-querybuilder';
import { Select } from '@verticeone/design-system';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../constants';

export const OperatorSelector: FC<OperatorSelectorProps> = ({ handleOnChange, options, value }) => {
  const operatorOptions = toFlatOptionArray(options);
  const selectedOperator = operatorOptions?.find((operator) => operator.value === value);

  return (
    <Select<FullOperator<string>, false>
      variant="outlined"
      size="S"
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      value={selectedOperator}
      onChange={(newOperator) => handleOnChange(newOperator?.value)}
      options={operatorOptions}
    />
  );
};
