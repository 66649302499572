import { Select, SelectProps } from '@verticeone/design-system';
import React, { useCallback, useMemo } from 'react';
import { UserOption } from './types';
import { getFullName } from '@verticeone/utils/formatting';
import { GroupBase } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useLoggedUser } from '@verticeone/auth/src';

export type UserSelectProps<IsMulti extends boolean> = Pick<
  SelectProps<UserOption, IsMulti, GroupBase<UserOption>>,
  | 'color'
  | 'error'
  | 'id'
  | 'isClearable'
  | 'isDisabled'
  | 'isLoading'
  | 'isMulti'
  | 'onBlur'
  | 'onChange'
  | 'placeholder'
  | 'ref'
  | 'size'
  | 'variant'
> & {
  oneLine?: boolean;
  availableUsers: UserOption[];
  selectedUsers: UserOption[];
  onAddUserClick?: () => void;
};

/**
 * Component for selecting single or multiple users
 *
 * Features:
 *  - unified user formatting
 *  - unified sorting
 *
 * Planned features:
 *  - avatars
 *  - "+ Add user" button
 */
const UserSelect = <IsMulti extends boolean>({
  availableUsers,
  selectedUsers,
  oneLine = false,
  ...otherProps
}: UserSelectProps<IsMulti>) => {
  const { t } = useTranslation();
  const loggedUser = useLoggedUser();
  const formatUserLabel = useCallback(
    (user: UserOption) => {
      const name = getFullName(user) || user.email;
      if (user.id === loggedUser.userId) {
        return t('ENTITIES.ACCOUNT_USER.NAME_WITH_YOU_SUFFIX', { userName: name });
      }
      return name;
    },
    [loggedUser.userId, t]
  );

  const sortedOptions = useMemo(
    () =>
      availableUsers.sort((u1, u2) => {
        if (u1.isVerticeUser !== u2.isVerticeUser) {
          return u1.isVerticeUser ? -1 : 1;
        }
        return formatUserLabel(u1).localeCompare(formatUserLabel(u2));
      }),
    [availableUsers, formatUserLabel]
  );

  return (
    <Select<UserOption, IsMulti>
      options={sortedOptions}
      value={selectedUsers}
      getOptionLabel={formatUserLabel}
      getOptionValue={(o) => o.id}
      maxMultiChips={oneLine ? 2 : undefined}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      menuPosition="absolute"
      sx={{ minWidth: '250px' }}
      {...otherProps}
    />
  );
};

export default UserSelect;
