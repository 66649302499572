import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { Button } from '@verticeone/design-system';
import { Link } from 'react-router-dom';
import { CreateRequestDialog } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/components/CreateRequestDialog/CreateRequestDialog';
import { Vendor } from '@vertice/core/src/modules/vendor/types';

type RequestPurchaseButtonProps = { vendorId: string; vendorName?: string };

export const RequestPurchaseButton: FC<RequestPurchaseButtonProps> = (props) => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const [newRequestDialogOpen, setNewRequestDialogOpen] = useState(false);

  const autoStartPurchaseWithVendor = useMemo<Vendor>(
    () => ({ type: 'PREDEFINED', id: props.vendorId, name: props.vendorName }),
    [props.vendorId, props.vendorName]
  );

  return (
    <>
      {isEnabled(FEATURES.INTELLIGENT_WORKFLOWS) ? (
        <>
          <Button variant="solid" color="primary" onClick={() => setNewRequestDialogOpen(true)}>
            {t('VENDOR.REQUEST')}
          </Button>
          {newRequestDialogOpen && (
            <CreateRequestDialog
              open
              setOpen={setNewRequestDialogOpen}
              defaultVendor={autoStartPurchaseWithVendor}
              autoStart
            />
          )}
        </>
      ) : (
        <Button
          component={Link}
          to={generatePath(routes.VENDORS.VENDOR.NEW_PURCHASE.ABSOLUTE_PATH, { id: props.vendorId })}
          variant="solid"
          color="primary"
        >
          {t('VENDOR.REQUEST')}
        </Button>
      )}
    </>
  );
};
