import { Card } from '@verticeone/design-system';
import Stack from '@mui/material/Stack';
import { TabsBottomLineWrapper } from '@verticeone/design-system';
import { Tabs } from '@verticeone/design-system';
import { Tab } from '@verticeone/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useCloudOptimize } from '../useCloudOptimize';

const CloudOptimizeContent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE' });
  const { activeTab, setActiveTab, tabs } = useCloudOptimize();

  const getTabLabelKey = (tabId: string) => `TABS.${tabId.toUpperCase()}`;

  return (
    <Card>
      <TabsBottomLineWrapper paddingX={6} sx={{ paddingTop: 6 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
          <Tabs variant="outlined" value={activeTab?.id} color={AWS_BRAND_COLOR}>
            {tabs.map(({ id }) => (
              <Tab key={id} value={id} label={t(getTabLabelKey(id))} onClick={() => setActiveTab(id)} />
            ))}
          </Tabs>
        </Stack>
      </TabsBottomLineWrapper>
      {activeTab?.component ? <activeTab.component /> : null}
    </Card>
  );
};

export default CloudOptimizeContent;
