import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { API_URLS, useGenerateNotificationWebSocketOtpMutation } from '@vertice/slices';
import { createRequiredContext } from '@verticeone/design-system';
import { useAccountContext } from '../AccountContext';
import { ConnectionState, Subscriber, SubscriberFunctionSignature } from './types';
import { SHOULD_CONNECT_STATES, createWSConnection, notifySubscribers, parseWebSocketIncomingMessage } from './utils';

type NotificationWebSocketContextType = {
  connectionState: ConnectionState;
  subscribe: SubscriberFunctionSignature;
  unsubscribe: (id: string) => void;
};

const { useNotificationWebSocketContext, NotificationWebSocketContextProvider: BaseContextProvider } =
  createRequiredContext<NotificationWebSocketContextType, 'NotificationWebSocket'>('NotificationWebSocket');

const NotificationWebSocketContextProvider = ({ children }: PropsWithChildren) => {
  const [connectionState, setConnectionState] = useState<ConnectionState>('init');

  const { accountId } = useAccountContext();
  const [generateNotificationOTP] = useGenerateNotificationWebSocketOtpMutation();

  const subscribers = useRef<Array<Subscriber>>([]);
  const webSocket = useRef<WebSocket>();

  const openWebSocket = useCallback(async () => {
    const notificationOTP = await generateNotificationOTP({
      accountId,
      body: {},
    });

    if (!('data' in notificationOTP && 'ticket' in notificationOTP.data)) return setConnectionState('failed');

    webSocket.current = createWSConnection(API_URLS.NOTIF_WEBSOCKETS_WSS, notificationOTP.data.ticket!, {
      onOpen: () => setConnectionState('ready'),
      onClose: () => setConnectionState('disconnected'),
      onMessage: (message) => {
        const parsedMessage = parseWebSocketIncomingMessage(message);

        if (parsedMessage) {
          notifySubscribers(subscribers.current, parsedMessage);
        }
      },
    });
  }, [generateNotificationOTP, accountId]);

  useEffect(() => {
    async function connect() {
      if (SHOULD_CONNECT_STATES.includes(connectionState)) {
        setConnectionState('connecting');
        await openWebSocket();
      }
    }

    void connect();

    return () => {
      if (connectionState === 'ready') webSocket.current?.close();
    };
  }, [openWebSocket, connectionState]);

  useEffect(() => {
    if (connectionState === 'failed') {
      setTimeout(async () => {
        setConnectionState('disconnected');
      }, 500);
    }
  }, [connectionState]);

  const subscribe: SubscriberFunctionSignature = useCallback(({ id, callback }) => {
    subscribers.current = [...subscribers.current.filter((subscriber) => subscriber.id !== id), { id, callback }];
  }, []);

  const unsubscribe = useCallback((id: string) => {
    subscribers.current = [...subscribers.current.filter((subscriber) => subscriber.id !== id)];
  }, []);

  return (
    <BaseContextProvider
      value={{
        connectionState,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </BaseContextProvider>
  );
};

export { useNotificationWebSocketContext, NotificationWebSocketContextProvider };
