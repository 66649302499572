import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';

import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import LicenseDialog from '../LicenseDialog/LicenseDialog';
import ContractDialog from '../ContractDialog/ContractDialog';
import AuthEventsDialog from '../AuthEventsDialog';
import { SuccessIcon } from './SuccessIcon';
import { WarningIcon } from './WarningIcon';
import { InfoIcon } from './InfoIcon';
import { DataHealthType, DataHealthCode } from './types';
import { useSnackbar } from 'notistack';
import { DataHealthCheckStatus } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';

type DataHealthItemProps = {
  type: DataHealthType;
  code: string;
  application?: ApplicationWithMeasureReports;
  status: DataHealthCheckStatus;
};

export const DataHealthItem = ({ type, code, status, application }: DataHealthItemProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const onSuccessfulDataHealthCorrection = () => {
    enqueueSnackbar(t('APPLICATIONS.USAGE.DATA_HEALTH.SNACKBAR_SUCCESS'), { variant: 'success' });
  };

  const [openedDialog, setOpenedDialog] = useState<string | null>(null);
  const closeDialog = () => setOpenedDialog(null);

  const isWarning = type === DataHealthType.WARNING;

  if (status === DataHealthCheckStatus.Passed || status === DataHealthCheckStatus.Ignored) {
    return (
      <Stack direction="row" alignItems="center" gap={2}>
        <SuccessIcon />
        <Text variant="body-bold" size="S" color={palette.text.color2}>
          {status === DataHealthCheckStatus.Passed
            ? t(`APPLICATIONS.USAGE.DATA_HEALTH.PASSED.${code}`)
            : t(`APPLICATIONS.USAGE.DATA_HEALTH.IGNORED.${code}`)}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack>
        <Stack direction="row" gap={2}>
          {isWarning ? <WarningIcon /> : <InfoIcon />}
          <Text variant="body-bold" size="S" color="text1">
            {t(`APPLICATIONS.USAGE.DATA_HEALTH.${code}.HEADER`)}
          </Text>
        </Stack>
        <Text variant="body-regular" size="S" color="text2">
          {t(`APPLICATIONS.USAGE.DATA_HEALTH.${code}.CONTENT`)}
        </Text>
      </Stack>
      <Button size="XS" variant="ghost" color="primary" onClick={() => setOpenedDialog(code)}>
        {t(`APPLICATIONS.USAGE.DATA_HEALTH.${code}.BUTTON`)}
      </Button>
      <LicenseDialog
        application={application}
        open={openedDialog === DataHealthCode.LICENSE_COUNT_MISSING}
        onClose={closeDialog}
        onSuccess={onSuccessfulDataHealthCorrection}
      />
      <ContractDialog
        application={application}
        open={openedDialog === DataHealthCode.CONTRACT_LINK_MISSING}
        onClose={closeDialog}
        onSuccess={onSuccessfulDataHealthCorrection}
      />
      <AuthEventsDialog open={openedDialog === DataHealthCode.AUTH_EVENTS_ONLY} onClose={closeDialog} />
    </Stack>
  );
};
